import api from './api'

const apinotes = {
    async getNotes(axios, entityType, entityId, related) {
        const body = {
            entityType,
            entityId,
            related,
        }
        return axios
            .post(`/notes`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async addNote(axios, entityType, entityId, text) {
        const body = {
            note: {
                text,
            },
        }
        return axios
            .post(`/notes/${entityType}/${entityId}`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async updateNote(axios, noteId, text) {
        const body = {
            note: {
                text,
            },
        }
        return axios
            .put(`/notes/${noteId}`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async deleteNote(axios, noteId) {
        return axios
            .delete(`/notes/${noteId}`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apinotes
