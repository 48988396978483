<template>
    <!-- Modal backdrop -->
    <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-out duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-show="modalOpen"
            class="fixed inset-0 bg-slate-900 transition-opacity"
            :class="bgClasses"
            aria-hidden="true"
        />
    </transition>

    <!-- Modal dialog -->
    <transition
        enter-active-class="transition ease-in-out duration-200"
        enter-from-class="opacity-0 translate-y-4"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in-out duration-200"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-4"
    >
        <div
            v-show="modalOpen"
            :id="id"
            class="fixed inset-0 z-50 transform overflow-hidden px-4 sm:px-6"
            :class="fgClasses"
            role="dialog"
            aria-modal="true"
        >
            <div ref="modalContent" class="max-h-full w-full max-w-lg overflow-auto rounded bg-white shadow-lg">
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'

export default {
    name: 'ModalBlank',
    props: {
        id: {
            type: String,
        },
        modalOpen: {
            type: Boolean,
            default: false,
        },
        bgClasses: {
            type: String,
            default: 'bg-opacity-30 z-50',
        },
        fgClasses: {
            type: String,
            default: 'flex items-center my-4 justify-center',
        },
    },
    emits: ['close-modal'],
    setup(props, { emit }) {
        const modalContent = ref(null)

        // close on click outside
        const clickHandler = ({ target }) => {
            if (!props.modalOpen || modalContent.value.contains(target)) return
            emit('close-modal')
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (!props.modalOpen || keyCode !== 27) return
            emit('close-modal')
        }

        onMounted(() => {
            document.addEventListener('click', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        return {
            modalContent,
        }
    },
}
</script>
