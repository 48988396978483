<script setup>
const props = defineProps({
    selectedPlatformId: {
        type: String,
        required: true,
    },
    averageViewers: {
        type: Object,
        required: true,
    },
})
</script>

<template>
    <div class="text-sm">
        <div v-if="props.selectedPlatformId === 'twitch' && props.averageViewers.avg_viewers" class="flex">
            <div class="my-auto leading-none">
                {{ $filters.numeral(props.averageViewers.avg_viewers).format('0,0') }}
            </div>
        </div>
        <div v-else-if="props.selectedPlatformId === 'youtube' && props.averageViewers.count" class="flex">
            <div class="my-auto leading-none">
                {{ $filters.numeral(props.averageViewers.avg_views).format('0,0') }}
            </div>
        </div>
        <div v-else>---</div>
    </div>
</template>
