<template>
    <div v-for="index in 10" :key="index">
        <div class="rounded-sm px-2 py-2">
            <div class="my-[1px] h-14 w-full animate-pulse rounded bg-slate-200" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkeletonUserLists',
}
</script>
