<template>
    <div class="relative">
        <input
            ref="trigger"
            class="form-input w-full rounded-b-none rounded-t"
            placeholder="Start typing the name of your game..."
            aria-haspopup="true"
            :aria-expanded="dropdownOpen"
            type="text"
            :class="{
                'rounded-bl rounded-br': !dropdownOpen,
            }"
            @input="debouncedHandler"
            @click.prevent="dropdownOpen = true"
        />

        <transition
            enter-active-class="transition ease-out duration-100 transform"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="dropdownOpen"
                class="absolute left-0 top-full z-10 w-full rounded-bl rounded-br border-b border-l border-r border-slate-200 bg-white"
            >
                <div ref="dropdown" class="max-h-[400px] overflow-y-auto shadow-lg">
                    <SkeletonGameSearch v-if="state.isLoading" />

                    <div v-else class="divide-y border-slate-200">
                        <div v-for="game in games" :key="game._id" class="cursor-pointer hover:bg-slate-200">
                            <div class="flex items-center px-3 py-2" @click="onGameSelect(game)">
                                <div class="h-10 w-16 shrink-0 rounded">
                                    <div
                                        v-if="!game.cover_url_thumb.match('nocover_qhhlj6.jpg')"
                                        class="h-full w-full rounded border border-slate-100 bg-cover bg-center"
                                        :style="{
                                            'background-image': `url(${game.cover_url_thumb})`,
                                        }"
                                    />
                                    <div v-else class="flex h-full w-full rounded bg-neutral-200">
                                        <span class="m-auto block text-center text-xs leading-4">No<br />Box Art</span>
                                    </div>
                                </div>
                                <div class="my-auto ml-3 text-sm leading-none">
                                    <div>
                                        <span class="font-medium">{{ game.name }}</span>
                                        <span v-if="game.first_release_date">
                                            ({{ $moment(game.first_release_date).format('YYYY') }})
                                        </span>
                                    </div>
                                    <div v-if="game.involved_companies" class="mt-0.5 text-xs">
                                        {{ $lodash.map(game.involved_companies, 'company.name').reverse().join(', ') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import _ from 'lodash'
import SkeletonGameSearch from '../partials/skeletons/SkeletonGameSearch.vue'

export default {
    name: 'DropdownGameSelect',

    emit: ['game-select'],

    components: {
        SkeletonGameSearch,
    },

    setup() {
        const dropdownOpen = ref(false)
        const dropdown = ref(null)
        const trigger = ref(null)

        // close on click outside
        const clickHandler = ({ target }) => {
            if (!dropdownOpen.value || dropdown.value.contains(target) || trigger.value.contains(target)) return
            dropdownOpen.value = false
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen.value || keyCode !== 27) return
            dropdownOpen.value = false
        }

        onMounted(() => {
            document.addEventListener('click', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        return {
            dropdownOpen,
            dropdown,
            trigger,
        }
    },

    data() {
        return {
            games: [],
            debouncedHandler: '',
            state: {
                isLoading: false,
            },
            selectedGame: [],
        }
    },

    created() {
        this.gameSearchProvider('', false)

        this.debouncedHandler = _.debounce((e) => {
            this.gameSearchProvider(e.target.value, true)
        }, 500)
    },

    methods: {
        async gameSearchProvider(searchText, isOpenedAfter) {
            this.games = []
            this.state.isLoading = true

            let endpoint = `/games/igdb/search`
            if (!searchText) endpoint = `/games/igdb/search/local`

            return this.$axios
                .post(endpoint, {
                    text: searchText.toLowerCase(),
                })
                .then(({ data }) => {
                    this.state.isLoading = false
                    this.games = data.payload.games

                    if (isOpenedAfter) {
                        this.dropdownOpen = true
                    }
                })
                .catch((error) => {
                    console.error(`Error searching for game`, error)
                    return []
                })
        },

        onGameSelect(game) {
            this.$emit('game-select', {
                game,
            })

            this.games = []
            this.selectedGame = game
            this.debouncedHandler = ''
            this.dropdownOpen = false
        },
    },
}
</script>
