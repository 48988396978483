<template>
    <!-- Skeleton card -->
    <div
        class="col-span-full rounded-sm border border-slate-200 bg-white shadow-lg @container sm:col-span-6 xl:col-span-6"
    >
        <div class="flex h-full flex-col @lg:flex-row">
            <div class="relative block h-48 w-full shrink-0 bg-slate-200 @lg:h-auto @lg:w-40">
                <div class="absolute h-full w-full animate-pulse bg-slate-200 object-cover object-center" />
            </div>

            <div class="flex grow flex-col p-5 @container">
                <div class="flex items-start justify-between text-slate-800 hover:text-slate-900">
                    <div class="h-5 w-36 animate-pulse rounded bg-slate-200" />
                    <div class="ml-1 inline-flex h-6 w-20 animate-pulse rounded-full bg-slate-200" />
                </div>

                <div class="grow">
                    <div class="mt-5">
                        <div class="h-3 w-full animate-pulse rounded bg-slate-200" />
                        <div class="mt-2 h-3 w-3/4 animate-pulse rounded bg-slate-200" />
                        <div class="mt-2 h-3 w-1/2 animate-pulse rounded bg-slate-200" />
                    </div>
                </div>

                <div class="mt-5 space-y-6">
                    <div class="h-6 w-6 animate-pulse rounded-full bg-slate-200 @sm:h-5 @sm:w-24" />
                    <div class="flex items-center justify-between">
                        <div class="flex h-3 w-1/4 animate-pulse rounded bg-slate-200" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkeletonSupercampaignCard',
}
</script>
