<script setup>
import _ from 'lodash'
import numeral from 'numeral'
import moment from 'moment'
import { h, inject, watch, ref, computed } from 'vue'
import DataTable from '@/components/ui/data-table/DataTable.vue'
import DataTableSortableColumnHeader from '@/components/ui/data-table/DataTableSortableColumnHeader.vue'
import HashtagInfoCell from './creator-hashtags/HashtagInfoCell.vue'

const props = defineProps({
    platformId: {
        type: String,
        required: true,
    },
    platformUserId: {
        type: String,
        required: true,
    },
    contentType: {
        type: String,
    },
    dayRange: {
        type: String,
    },
})

const columns = computed(() => {
    switch (props.platformId) {
        case 'twitch':
            return [
                {
                    accessorKey: 'tag',
                    meta: {
                        title: 'Tag',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row }) => {
                        return h(HashtagInfoCell, { data: row.original, platformId: props.platformId })
                    },
                },
                {
                    accessorKey: 'types',
                    meta: {
                        title: 'Types',
                    },
                    accessorFn: (row) => row.types.join(', '),
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row }) => {
                        return h('div', { class: 'leading-4 capitalize' }, row.getValue('types'))
                    },
                },
                {
                    accessorKey: 'avg_viewers',
                    meta: {
                        title: 'Avg Viewers',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'peak_viewers',
                    meta: {
                        title: 'Peak Viewers',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'hours_watched',
                    accessorFn: (row) => row.total_minutes_watched / 60 / 60,
                    meta: {
                        title: 'Hours Watched',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'total_views',
                    meta: {
                        title: 'Total Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'avg_chatters',
                    meta: {
                        title: 'Avg Chatters',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'engagement_rate',
                    meta: {
                        title: 'Engagement Rate',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0.0%'))
                    },
                },
                {
                    accessorKey: 'first_content_at',
                    meta: {
                        title: 'First Content At',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            { class: 'whitespace-nowrap' },
                            moment(row.getValue(column.id)).format('MMM Do, YY @ h:mm A'),
                        )
                    },
                },
                {
                    accessorKey: 'last_content_at',
                    meta: {
                        title: 'Last Content At',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            { class: 'whitespace-nowrap' },
                            moment(row.getValue(column.id)).format('MMM Do, YY @ h:mm A'),
                        )
                    },
                },
                {
                    accessorKey: 'sponsored_count',
                    meta: {
                        title: 'Sponsored Streams',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'count',
                    meta: {
                        title: 'Total Streams',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
            ]
        case 'youtube':
            return [
                {
                    accessorKey: 'tag',
                    meta: {
                        title: 'Tag',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row }) => {
                        return h(HashtagInfoCell, {
                            data: row.original,
                            platformId: props.platformId,
                            class: 'whitespace-nowrap',
                        })
                    },
                },
                {
                    accessorKey: 'types',
                    meta: {
                        title: 'Types',
                    },
                    accessorFn: (row) => row.types.join(', '),
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row }) => {
                        return h('div', { class: 'leading-4 capitalize' }, row.getValue('types'))
                    },
                },
                {
                    accessorKey: 'avg_views',
                    meta: {
                        title: 'Avg Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'avg_likes',
                    meta: {
                        title: 'Avg Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'avg_comments',
                    meta: {
                        title: 'Avg Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'total_views',
                    meta: {
                        title: 'Total Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'total_likes',
                    meta: {
                        title: 'Total Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'total_comments',
                    meta: {
                        title: 'Total Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'max_views',
                    meta: {
                        title: 'Max Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'min_views',
                    meta: {
                        title: 'Min Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'max_likes',
                    meta: {
                        title: 'Max Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'min_likes',
                    meta: {
                        title: 'Min Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'max_comments',
                    meta: {
                        title: 'Max Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'min_comments',
                    meta: {
                        title: 'Min Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'engagement_rate',
                    meta: {
                        title: 'Engagement Rate',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0.0%'))
                    },
                },
                {
                    accessorKey: 'count_sponsored',
                    meta: {
                        title: 'Sponsored Videos',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'count',
                    meta: {
                        title: 'Total Videos',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
            ]
        case 'tiktok':
            return [
                {
                    accessorKey: 'tag',
                    meta: {
                        title: 'Tag',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row }) => {
                        return h(HashtagInfoCell, { data: row.original, class: 'whitespace-nowrap' })
                    },
                },
                {
                    accessorKey: 'types',
                    meta: {
                        title: 'Types',
                    },
                    accessorFn: (row) => row.types.join(', '),
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row }) => {
                        return h('div', { class: 'leading-4 capitalize' }, row.getValue('types'))
                    },
                },
                {
                    accessorKey: 'avg_views',
                    meta: {
                        title: 'Avg Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'avg_likes',
                    meta: {
                        title: 'Avg Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'avg_comments',
                    meta: {
                        title: 'Avg Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'total_views',
                    meta: {
                        title: 'Total Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'total_likes',
                    meta: {
                        title: 'Total Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'total_comments',
                    meta: {
                        title: 'Total Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'max_views',
                    meta: {
                        title: 'Max Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'min_views',
                    meta: {
                        title: 'Min Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'max_likes',
                    meta: {
                        title: 'Max Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'min_likes',
                    meta: {
                        title: 'Min Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'max_comments',
                    meta: {
                        title: 'Max Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'min_comments',
                    meta: {
                        title: 'Min Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'engagement_rate',
                    meta: {
                        title: 'Engagement Rate',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0.0%'))
                    },
                },
                {
                    accessorKey: 'count_sponsored',
                    meta: {
                        title: 'Sponsored Video Count',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'count',
                    meta: {
                        title: 'Video Count',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
            ]
        case 'twitter':
            return [
                {
                    accessorKey: 'tag',
                    meta: {
                        title: 'Tag',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row }) => {
                        return h(HashtagInfoCell, { data: row.original, class: 'whitespace-nowrap' })
                    },
                },
                {
                    accessorKey: 'types',
                    meta: {
                        title: 'Types',
                    },
                    accessorFn: (row) => row.types.join(', '),
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row }) => {
                        return h('div', { class: 'leading-4 capitalize' }, row.getValue('types'))
                    },
                },
                {
                    accessorKey: 'avg_views',
                    meta: {
                        title: 'Average Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'avg_likes',
                    meta: {
                        title: 'Average Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'avg_replies',
                    meta: {
                        title: 'Average Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'max_views',
                    meta: {
                        title: 'Max Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'min_views',
                    meta: {
                        title: 'Min Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'max_likes',
                    meta: {
                        title: 'Max Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'min_likes',
                    meta: {
                        title: 'Min Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'max_replies',
                    meta: {
                        title: 'Max Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'min_replies',
                    meta: {
                        title: 'Min Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'total_views',
                    meta: {
                        title: 'Total Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'total_likes',
                    meta: {
                        title: 'Total Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'total_replies',
                    meta: {
                        title: 'Total Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'engagement_rate',
                    meta: {
                        title: 'Engagement Rate',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0.0%'))
                    },
                },
                {
                    accessorKey: 'sponsored_count',
                    meta: {
                        title: 'Sponsored Posts',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'count',
                    meta: {
                        title: 'Total Posts',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
            ]
        case 'instagram':
            return [
                {
                    accessorKey: 'tag',
                    meta: {
                        title: 'Tag',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row }) => {
                        return h(HashtagInfoCell, { data: row.original, class: 'whitespace-nowrap' })
                    },
                },
                {
                    accessorKey: 'avg_views',
                    meta: {
                        title: 'Average Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'avg_likes',
                    meta: {
                        title: 'Average Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'avg_comments',
                    meta: {
                        title: 'Average Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'min_views',
                    meta: {
                        title: 'Min Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'max_views',
                    meta: {
                        title: 'Max Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'min_likes',
                    meta: {
                        title: 'Min Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'max_likes',
                    meta: {
                        title: 'Max Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'min_comments',
                    meta: {
                        title: 'Min Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'max_comments',
                    meta: {
                        title: 'Max Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'total_views',
                    meta: {
                        title: 'Total Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'total_likes',
                    meta: {
                        title: 'Total Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'total_comments',
                    meta: {
                        title: 'Total Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'engagement_rate',
                    meta: {
                        title: 'Engagement Rate',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0.0%'))
                    },
                },
                {
                    accessorKey: 'sponsored_count',
                    meta: {
                        title: 'Sponsored Posts',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'count',
                    meta: {
                        title: 'Total Posts',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
            ]
        default:
            return []
    }
})

const toolbar = {
    placeholder: 'Filter tags...',
    columnToFilter: 'tag', // Assuming 'tag' is the main field to filter on
}

const axios = inject('axios')

const data = ref([])

const isLoaded = ref(false)

const fetchData = async () => {
    isLoaded.value = false
    const endpoint = `/platforms/${props.platformId}/${props.platformUserId}/tags`

    try {
        const response = await axios.get(endpoint, {
            params: {
                content_type: props.contentType,
                day_range: props.dayRange,
            },
        })
        data.value = _.filter(response.data.payload.tags, (item) => item._id)
        isLoaded.value = true
    } catch (error) {
        console.error('Error fetching tags data:', error)
        data.value = []
        isLoaded.value = true
    }
}

watch(
    () => [props.platformId, props.platformUserId],
    () => {
        fetchData()
    },
    {
        immediate: true, // This ensures fetchData is called on initial render too
    },
)

watch(
    () => [props.contentType, props.dayRange],
    () => {
        fetchData()
    },
    {
        immediate: false,
    },
)
</script>

<template>
    <DataTable
        :columns="columns"
        :data="data"
        :toolbar="toolbar"
        :is-loaded="isLoaded"
        table-cell-class="py-1.5 px-3 text-sm"
        table-row-class="group"
        preferences-id="creator-hashtags"
    >
        <template #loading-skeleton>
            <tr v-for="rowIndex in 10" :key="rowIndex">
                <td v-for="colIndex in columns?.length" :key="colIndex">
                    <div class="my-0.5 h-7 w-full animate-pulse rounded bg-slate-100" />
                </td>
            </tr>
        </template>
    </DataTable>
</template>
