<template>
    <ModalBlank
        id="confirm-budget-modal"
        :modal-open="localModals.budget.is_open"
        @close-modal="localModals.budget.is_open = false"
    >
        <div class="flex space-x-4 p-5">
            <!-- Icon -->
            <div class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-emerald-100">
                <svg class="h-4 w-4 shrink-0 fill-current text-emerald-500" viewBox="0 0 16 16">
                    <path
                        d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"
                    ></path>
                </svg>
            </div>
            <!-- Content -->
            <div>
                <!-- Modal header -->
                <div class="mb-2">
                    <div class="text-lg font-semibold text-slate-800">Increase campaign budget?</div>
                </div>
                <!-- Modal content -->
                <div class="mb-10 text-sm">
                    <div class="space-y-2">
                        <p>
                            This will increase your campaign's budget to meet the currently allocated
                            <span class="text-emerald-500"
                                >${{ $filters.numeral(budget.totalAllocatedBudget).format('0,0') }}</span
                            >
                            and include all your approved creators in the campaign.
                        </p>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="flex flex-wrap justify-end space-x-2">
                    <button class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300">Cancel</button>
                    <button class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600">Yes, increase it</button>
                </div>
            </div>
        </div>
    </ModalBlank>

    <div
        v-if="isEnabled"
        class="mb-6 grid grid-cols-12 gap-8 rounded border border-slate-200 bg-white px-5 py-4 shadow-lg"
    >
        <div
            class="col-span-9 flex-col"
            :class="{
                '!col-span-8': isSfStaff,
            }"
        >
            <div class="mb-3">
                <!-- <h1 class="text-xl font-semibold">{{ getFromDictionary(`campaign.type.${campaign.type}`, 'label') }} Budget</h1> -->
                <h1 class="text-xl font-semibold">Campaign Budget</h1>
            </div>

            <div class="relative flex h-6 w-full overflow-hidden rounded bg-slate-100">
                <!-- Steam -->
                <div
                    class="h-full bg-sky-300"
                    :style="{
                        width: `${Math.min(budget.steamCampaignBudget / budget.maxSupercampaignBudget, 1) * 100}%`,
                    }"
                ></div>
                <!-- Allocated -->
                <div
                    class="h-full bg-emerald-300"
                    :style="{
                        width: `${Math.min(budget.allocatedCreatorsBudget / budget.maxSupercampaignBudget, 1) * 100}%`,
                    }"
                ></div>
                <!-- Allocated with operational costs -->
                <div
                    class="h-full rounded-br rounded-tr bg-amber-200"
                    :style="{
                        width: `${Math.min(budget.allocatedOperationalCost / budget.maxSupercampaignBudget, 1) * 100}%`,
                    }"
                ></div>
            </div>
            <div class="mt-2 flex justify-between text-sm">
                <p>
                    {{ $filters.numeral(budget.totalAllocatedBudget / budget.maxSupercampaignBudget).format('0%') }}
                    complete
                </p>
                <p>
                    <span class="text-emerald-500"
                        >${{ $filters.numeral(budget.totalAllocatedBudget).format('0,0') }}</span
                    >
                    allocated of
                    <span class="text-emerald-500"
                        >${{ $filters.numeral(budget.maxSupercampaignBudget).format('0,0') }}</span
                    >
                </p>
            </div>
        </div>

        <div
            class="col-span-3 flex grow justify-between text-sm"
            :class="{
                '!col-span-4': isSfStaff,
            }"
        >
            <div class="my-auto grow flex-col">
                <div v-if="budget.steamCampaignBudget" class="flex">
                    <div class="flex">
                        <div class="my-auto h-3 w-3 rounded bg-sky-300" />
                        <div class="ml-2">Steam Broadcast</div>
                    </div>
                    <div v-if="isSfStaff" class="ml-auto">
                        ${{ $filters.numeral(budget.steamCampaignBudget).format('0,0') }}
                    </div>
                </div>

                <div class="flex">
                    <div class="flex">
                        <div class="my-auto h-3 w-3 rounded bg-emerald-300" />
                        <div class="ml-2">Creator payouts</div>
                    </div>
                    <div v-if="isSfStaff" class="ml-auto">
                        ${{ $filters.numeral(budget.allocatedCreatorsBudget).format('0,0') }}
                    </div>
                </div>
                <div class="flex">
                    <div class="flex">
                        <div class="my-auto h-3 w-3 rounded bg-amber-300" />
                        <div class="ml-2">Operational costs</div>
                    </div>
                    <div v-if="isSfStaff" class="ml-auto">
                        ${{ $filters.numeral(budget.allocatedOperationalCost).format('0,0') }}
                    </div>
                    <!-- <div v-if="isSfStaff" class="ml-auto">25%</div> -->
                </div>

                <div v-if="isSfStaff" class="mt-1 border-t border-slate-200 pt-1 text-right font-semibold">
                    ${{ $filters.numeral(budget.totalAllocatedBudget).format('0,0') }}
                </div>

                <!-- <div class="mt-3">
                    <button class="btn btn-sm bg-emerald-500 hover:bg-emerald-600 text-white" @click.stop="localModals.budget.is_open = true">
                        Increase Budget
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import moment from 'moment'
import ModalBlank from '@/components/ModalBlank.vue'

export default {
    name: 'CampaignBudgetSectionBids',

    components: {
        ModalBlank,
    },
    props: ['creators', 'campaign', 'campaignsById'],

    emits: ['on-budget-increase'],

    data() {
        return {
            localModals: {
                budget: {
                    is_open: false,
                },
            },
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'isSfStaff']),

        isEnabled() {
            if (!this.campaign) return false

            return moment.utc(this.campaign.started_at).isAfter(moment.utc('2023-02-10'))
        },

        budget() {
            const allocatedCreatorsBudget = _.sumBy(this.creators, (creator) => {
                let sum = 0

                if (creator.status === 'accepted') {
                    sum += creator.payout_amount
                }

                return sum
            })

            const foundSteamCampaign = _.find(this.campaignsById, (campaign) => campaign.type === 'steam-broadcast')

            const campaignBudget = this.campaign.budget_amount

            let maxSupercampaignBudget = campaignBudget
            let steamCampaignBudget = 0

            if (foundSteamCampaign) {
                maxSupercampaignBudget += foundSteamCampaign.budget_amount
                steamCampaignBudget = foundSteamCampaign.budget_amount
            }

            let maxCampaignBudgetOperationalCost
            let maxAllocatedCreatorsBudget
            let allocatedOperationalCost

            if (this.campaign.id === '47abc662-2dd0-4c4a-9615-f9f5a575dfd8') {
                allocatedOperationalCost = allocatedCreatorsBudget * this.campaign.commission_rate
            } else {
                maxCampaignBudgetOperationalCost = campaignBudget * this.campaign.commission_rate
                maxAllocatedCreatorsBudget = campaignBudget - maxCampaignBudgetOperationalCost

                // Scale the current campaign operational cost with allocated budget
                allocatedOperationalCost =
                    maxCampaignBudgetOperationalCost * (allocatedCreatorsBudget / maxAllocatedCreatorsBudget)
            }

            const totalAllocatedBudget = allocatedCreatorsBudget + allocatedOperationalCost + steamCampaignBudget

            return {
                campaignBudget,
                maxCampaignBudgetOperationalCost,
                allocatedOperationalCost,
                allocatedCreatorsBudget,
                totalAllocatedBudget,
                maxAllocatedCreatorsBudget,
                maxSupercampaignBudget,
                steamCampaignBudget,
            }
        },
    },
}
</script>
