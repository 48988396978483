<template>
    <div class="m-0 flex h-12 w-full p-0">
        <div
            ref="trigger"
            class="flex h-full w-full"
            aria-label="Lists"
            aria-haspopup="true"
            :aria-expanded="dropdownOpen"
            @click.prevent="dropdownOpen = !dropdownOpen"
        >
            <slot />
        </div>

        <transition
            v-if="isSortable !== false || isHideable !== false"
            enter-active-class="transition ease-out duration-100 transform"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="dropdownOpen"
                class="no-scrollbar absolute right-0 top-full z-50 mt-1 max-h-96 overflow-y-auto rounded border border-slate-200 bg-white shadow-xl"
            >
                <div ref="dropdown">
                    <div
                        v-for="action in actions"
                        :key="action.innerText"
                        class="flex cursor-pointer border-b border-slate-200 px-3 py-2 text-sm hover:bg-slate-50"
                        @click="onAction(action.id, action.data)"
                    >
                        <font-awesome-icon :icon="action.icon" class="my-auto mr-2 h-4 w-4 shrink-0" />
                        <span class="my-auto block text-sm font-medium">{{ action.innerText }}</span>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'

export default {
    name: 'DropdownTableHeader',

    props: {
        columnId: {
            type: String,
        },

        sortDirection: {},

        isSortable: {
            type: Boolean,
            default: false,
        },

        isHideable: {
            type: Boolean,
            default: true,
        },
    },

    emits: ['action'],

    setup() {
        const dropdownOpen = ref(false)
        const dropdown = ref(null)
        const trigger = ref(null)

        // close on click outside
        const clickHandler = ({ target }) => {
            if (!dropdownOpen.value || dropdown?.value?.contains(target) || trigger?.value?.contains(target)) return
            dropdownOpen.value = false
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen.value || keyCode !== 27) return
            dropdownOpen.value = false
        }

        onMounted(() => {
            document.addEventListener('click', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        return {
            dropdownOpen,
            dropdown,
            trigger,
        }
    },

    data() {
        return {}
    },

    computed: {
        actions() {
            const result = []

            if (this.isSortable === true) {
                if (this.sortDirection !== 'asc') {
                    result.push({
                        id: 'sort-asc',
                        icon: ['fas', 'arrow-up-short-wide'],
                        innerText: 'Sort Ascending',
                        data: 'asc',
                    })
                }

                if (this.sortDirection !== 'desc') {
                    result.push({
                        id: 'sort-desc',
                        icon: ['fas', 'arrow-down-wide-short'],
                        innerText: 'Sort Descending',
                        data: 'desc',
                    })
                }

                if (this.sortDirection) {
                    result.push({
                        id: 'sort-clear',
                        icon: ['fas', 'xmark'],
                        innerText: 'Clear Sort',
                        data: false,
                    })
                }
            }

            if (this.isHideable !== false) {
                result.push({
                    id: 'column-hide',
                    icon: ['fas', 'eye-slash'],
                    innerText: 'Hide Column',
                    direction: false,
                })
            }

            return result
        },
    },

    created() {},

    methods: {
        onAction(actionId, actionData) {
            this.$emit('action', this.columnId, actionId, actionData)
        },
    },
}
</script>
