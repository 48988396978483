import api from './api'

const apicampaigncontracts = {
    async createContract(axios, participantId, contractDetails) {
        const body = contractDetails

        return axios
            .put(`/contracts/${participantId}/create`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async sendContract(axios, participantId) {
        return axios
            .put(`/contracts/${participantId}/send`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async deleteContract(axios, participantId) {
        return axios
            .delete(`/contracts/${participantId}`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async downloadContract(axios, participantId) {
        return axios
            .get(`/contracts/${participantId}/download`, {
                responseType: 'arraybuffer',
            })
            .then((response) => api.createSuccessResponse(response))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apicampaigncontracts
