<template>
    <div class="col-span-full divide-y divide-slate-200 rounded bg-white shadow-lg">
        <!-- Skeleton search body -->
        <div v-for="index in 7" :key="index" class="flex h-[50px] flex-col text-sm">
            <div class="flex justify-between px-4 py-2">
                <div class="flex">
                    <div class="shrink-0 sm:mr-3">
                        <div class="h-8 w-8 animate-pulse rounded-full bg-slate-200" />
                    </div>

                    <div class="flex">
                        <div class="my-auto h-3 w-36 animate-pulse rounded bg-slate-200" />
                    </div>
                </div>

                <div class="flex">
                    <div class="my-auto h-3 w-6 animate-pulse rounded bg-slate-200" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkeletonListSearch',
}
</script>
