import api from './api'

const apidocuments = {
    async getDocuments(axios, organizationId, entity, entityId, documentType) {
        const body = {
            entity,
            entityid: entityId,
            documenttype: documentType,
        }

        return axios
            .post(`documents/organization/${organizationId}/list`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async uploadDocument(axios, organizationId, entity, entityId, documentType, file, filename) {
        const bodyFormData = new FormData()

        bodyFormData.append('entity', entity)
        bodyFormData.append('entityid', entityId)
        bodyFormData.append('documenttype', documentType)
        bodyFormData.append('file', file)
        bodyFormData.append('filename', filename)

        return axios({
            method: 'post',
            url: `documents/organization/${organizationId}`,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
        })
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async deleteDocument(axios, organizationId, entity, entityId, documentType, filename) {
        const body = {
            entity,
            entityid: entityId,
            documenttype: documentType,
            filename,
        }

        return axios
            .delete(`documents/organization/${organizationId}`, { data: body })
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apidocuments
