const AUTO_CLOSE_DELAY = 15000 // 15 seconds

export default {
    state: {
        actions: [],
    },

    mutations: {
        'backgroundActions:add': function (state, action) {
            state.actions.unshift(action)
        },
        'backgroundActions:update': function (state, { id, updates }) {
            console.log('Updating action:', id, updates)
            const index = state.actions.findIndex((action) => action.id === id)

            if (index !== -1) {
                state.actions[index] = {
                    ...state.actions[index],
                    ...updates,
                }
            }

            console.log('Updated state:', state.actions)
        },
        'backgroundActions:remove': function (state, id) {
            state.actions = state.actions.filter((action) => action.id !== id)
        },
    },

    getters: {
        backgroundActions(state) {
            return state.actions
        },
    },

    actions: {
        addBackgroundAction({ commit }, updates) {
            const id = Date.now()
            const action = { id, status: 'in-progress', ...updates }
            commit('backgroundActions:add', action)
            return id
        },
        updateBackgroundAction({ commit }, { id, updates }) {
            console.log('Dispatching updateBackgroundAction:', id, updates)
            commit('backgroundActions:update', { id, updates })
        },
        completeBackgroundAction({ dispatch }, id) {
            dispatch('updateBackgroundAction', {
                id,
                updates: { status: 'completed' },
            })

            // Set up auto-close
            setTimeout(() => {
                dispatch('removeBackgroundAction', id)
            }, AUTO_CLOSE_DELAY)
        },
        removeBackgroundAction({ commit }, id) {
            commit('backgroundActions:remove', id)
        },
    },
}
