<script setup>
import { computed } from 'vue'
import AffinityPlate from '@/components/plates/AffinityPlate.vue'
import Tooltip from '@/components/Tooltip.vue'

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
})

// Define the scalePercent function
function scalePercent(value) {
    return value * 0.75 + 0.25
}

// Compute the scaled affinity value
const scaledAffinity = computed(() => scalePercent(props.data.affinity))
</script>

<template>
    <Tooltip placement="top" max-width="400" class="h-full w-full cursor-pointer">
        <template #trigger>
            <AffinityPlate :affinity-percent="scalePercent(scaledAffinity)" />
        </template>
        <template #default>
            <div class="text-xs">
                <div class="font-semibold text-white">Affinity</div>
                <p>
                    {{ props.data.reason }}
                </p>
            </div>
        </template>
    </Tooltip>
</template>
