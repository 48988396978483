<template>
    <div ref="avatarWrapper" class="vue-avatar--wrapper relative" :style="[style, customStyle]" aria-hidden="true">
        <div class="absolute inset-0 z-10 h-full w-full" :style="[bgStyle, customStyle]" />

        <div class="absolute inset-0 -z-10 h-full w-full rounded-full bg-white" />

        <!-- This img is not displayed; it is used to detect failure-to-load of div background image -->
        <img
            v-if="isImage && isIntersecting"
            class="vue-avatar--image"
            style="display: none"
            :src="currentSrc"
            @error="onImgError"
        />

        <span v-show="!isImage">{{ userInitial }}</span>
    </div>
</template>

<script>
import _ from 'lodash'

const getInitials = (username) => {
    if (!username) return 'X'
    const parts = username.split(/[ -]/)
    let initials = ''
    for (let i = 0; i < _.size(parts); i += 1) {
        initials += parts[i].charAt(0)
    }
    if (_.size(initials) > 3 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, '')
    }
    initials = initials.substr(0, 3).toUpperCase()
    return initials
}

export default {
    name: 'Avatar',
    props: {
        username: {
            type: String,
        },
        initials: {
            type: String,
        },
        backgroundColor: {
            type: String,
        },
        color: {
            type: String,
        },
        customStyle: {
            type: Object,
        },
        inline: {
            type: Boolean,
        },
        size: {
            type: Number,
            default: 50,
        },
        rounded: {
            type: Boolean,
            default: true,
        },
        lighten: {
            type: Number,
            default: 80,
        },
        parser: {
            type: Function,
            default: getInitials,
            validator: (parser) => typeof parser('John', getInitials) === 'string',
        },
        border: {
            type: Object,
        },
        srcs: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            backgroundColors: [
                '#F44336',
                '#FF4081',
                '#9C27B0',
                '#673AB7',
                '#3F51B5',
                '#2196F3',
                '#03A9F4',
                '#00BCD4',
                '#009688',
                '#4CAF50',
                '#8BC34A',
                '#CDDC39',
                /* '#FFEB3B' , */ '#FFC107',
                '#FF9800',
                '#FF5722',
                '#795548',
                '#9E9E9E',
                '#607D8B',
            ],

            currentUrlIndex: 0,
            retryCount: 0,
            maxRetries: 1, // Allows one retry per URL
            imgError: false,
            lastErrorEvent: null,
            isIntersecting: false, // Controls if the image is in the viewport
        }
    },
    computed: {
        background() {
            if (!this.isImage && this.username) {
                return this.backgroundColor || this.randomBackgroundColor(_.size(this.username), this.backgroundColors)
            }
            return undefined
        },
        bgStyle() {
            const style = {
                display: this.inline ? 'inline-flex' : 'flex',
                width: `${this.size}px`,
                height: `${this.size}px`,
                borderRadius: this.rounded ? '50%' : 0,
                lineHeight: `${this.size + Math.floor(this.size / 20)}px`,
                fontWeight: 'bold',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                userSelect: 'none',
            }
            if (this.isImage && this.isIntersecting) {
                style.background = `transparent url('${this.currentSrc}') no-repeat scroll center / ${this.size}px ${this.size}px content-box border-box`
            }
            return style
        },
        style() {
            const style = {
                display: this.inline ? 'inline-flex' : 'flex',
                width: `${this.size}px`,
                height: `${this.size}px`,
                borderRadius: this.rounded ? '50%' : 0,
                lineHeight: `${this.size + Math.floor(this.size / 20)}px`,
                fontWeight: 'bold',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                userSelect: 'none',
                font: `${Math.floor(this.size / 2.5)}px/${this.size}px Helvetica, Arial, sans-serif`,
            }
            return style
        },
        userInitial() {
            if (!this.isImage) {
                const initials = this.initials || this.parser(this.username, getInitials)
                return initials
            }
            return ''
        },
        currentSrc() {
            return this.srcs[this.currentUrlIndex]
        },
        isImage() {
            return !this.imgError && Boolean(this.currentSrc)
        },
    },
    watch: {
        // Reset image error when src changes
        src(newValue, oldValue) {
            if (newValue !== oldValue) this.imgError = false
        },
        srcs: {
            immediate: false,
            handler() {
                if (this.srcs.length > 0) {
                    this.loadImage()
                }
            },
        },
    },
    mounted() {
        if (!this.isImage) {
            this.$emit('avatar-initials', this.username, this.userInitial)
        }

        this.createObserver()
    },
    beforeUnmount() {
        if (this.observer) {
            this.observer.disconnect()
        }
    },
    methods: {
        initial: getInitials,

        createObserver() {
            const options = {
                root: null, // relative to the viewport
                threshold: 0.1, // 10% of the item must be visible
            }

            this.observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.isIntersecting = true
                        this.loadImage()
                        observer.unobserve(this.$refs.avatarWrapper)
                    }
                })
            }, options)

            this.observer.observe(this.$refs.avatarWrapper)
        },

        randomBackgroundColor(seed, colors) {
            return colors[seed % _.size(colors)]
        },

        onImgError(event) {
            if (event && event.target && event.target.status === 403 && this.retryCount < this.maxRetries) {
                this.retryCount += 1
                this.loadImage()
            } else {
                this.retryCount = 0 // Reset retry count for next URL
                if (this.currentUrlIndex < this.srcs.length - 1) {
                    this.currentUrlIndex += 1
                    this.loadImage()
                } else {
                    this.imgError = true // No more URLs to try
                }
            }
        },

        loadImage() {
            const img = new Image()
            img.onload = () => {
                this.imgError = false // Image loaded successfully
            }
            img.onerror = this.onImgError
            img.src = this.currentSrc
        },
    },
}
</script>
