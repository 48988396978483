import _ from 'lodash'
import api from './api'

const apicampaignparticipantstages = {
    async getStages(axios, participantId) {
        return axios
            .get(`/participants/${participantId}/stages`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async updateProspectStage(axios, participantId, changes) {
        return this.updateStage(axios, participantId, 'prospect', changes)
    },

    async updateStage(axios, participantId, stageId, changes) {
        const body = {
            stage: {
                id: stageId,
            },
        }

        _.assign(body.stage, changes)

        return axios
            .put(`/participants/${participantId}/stage`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apicampaignparticipantstages
