<script setup>
/* global YT */
import { useStore } from 'vuex'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import moment from 'moment'
import _, { compact } from 'lodash'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import qs from 'qs'
import { axios } from '@/utils/axios'
import ModalVideo from '@/components/ModalVideo.vue'
import UserQuestion from '@/partials/modals/CreatorAskAIModal/UserQuestion.vue'
import ExampleQuestion from '@/partials/modals/CreatorAskAIModal/ExampleQuestion.vue'
import { timestampToSeconds, youtubeDurationToReadable } from '@/partials/modals/CreatorAskAIModal/helpers'
import { QUESTIONS } from '@/partials/modals/CreatorAskAIModal/questions'
import Question from './Question.vue'

const store = useStore()

const modal = computed(() => store.state.modals.creator_ask_ai)

const input = ref('')
const currentAskedQuestion = ref('')
const selectedArchivedVideo = ref(undefined)

const { isLoading, data, refetch, remove } = useQuery(
    [
        'qnas',
        {
            supercampaign_id: modal.value.supercampaign_id,
            participant_id: modal.value.creator?.participant?.id,
        },
    ],
    async () => {
        const response = await axios.get(
            `/supercampaigns/${modal.value.supercampaign_id}/campaigns/${modal.value.campaign_id}/participants/${modal.value.creator.participant.id}/qna`,
        )
        return response.data.payload
    },
    { enabled: Boolean(modal.value.creator && modal.value.supercampaign_id) },
)

const { data: supercampaignQueryData, refetch: supercampaignQueryRefetch } = useQuery(
    [
        'supercampaigns',
        {
            supercampaign_id: modal.value.supercampaign_id,
        },
    ],
    async () => {
        const response = await axios.get(`/supercampaigns/${modal.value.supercampaign_id}`)
        return response.data.payload.supercampaign
    },
    { enabled: Boolean(modal.value.creator && modal.value.supercampaign_id) },
)

const queryClient = useQueryClient()

const {
    isLoading: askQuestionLoading,
    error: askQuestionError,
    isSuccess: askQuestionSuccess,
    data: askQuestionData,
    mutate: askQuestion,
    reset: resetAskQuestion,
} = useMutation({
    mutationFn: async (userQuestion) => {
        const response = await axios.post(
            `/analysis/${modal.value.supercampaign_id}/${
                modal.value.creator.participant.platform_user_id
            }/ask-question${qs.stringify(
                { chapterId: selectedArchivedVideo?.value?.chapter_id },
                { addQueryPrefix: true },
            )}`,
            {
                question: userQuestion,
            },
        )
        return response.data.payload.summary
    },
    onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['qnas'] })
    },
})

const textarea = ref(null)
const height = ref(0) // initial height

const updateHeight = () => {
    if (textarea.value) {
        textarea.value.style.height = 'auto'
        height.value = textarea.value.scrollHeight
        textarea.value.style.height = `${height.value}px`
    }
}

const handleFormSubmit = () => {
    if (input.value) {
        currentAskedQuestion.value = input.value
        askQuestion(input.value)
        input.value = ''
        nextTick(() => {
            updateHeight()
        })
    }
}

onMounted(updateHeight)

// Step 1: Create a ref for your YouTube player
const player = ref(null)
const isLoaded = ref(false)

// Step 2: Define the onYouTubeIframeAPIReady function
onMounted(() => {
    const tag = document.createElement('script')

    tag.src = 'https://www.youtube.com/iframe_api'
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

    window.onYouTubeIframeAPIReady = function () {
        isLoaded.value = true
    }
})

const isModalOpen = computed(() => modal.value.is_open)

// watch for external value changes and update height accordingly
watch(input, updateHeight)

watch([isModalOpen, isLoaded, player], () => {
    if (modal.value.is_open && isLoaded.value && !player.value) {
        player.value = new YT.Player('player')
    }
})

watch(isModalOpen, (newValue) => {
    if (newValue) {
        refetch()
        supercampaignQueryRefetch()
    }
})

async function seekTo({ archivePlatformId, timestamp }) {
    // Setting to 0 doesn't work for whatever reason
    const startSeconds = Math.max(1, timestampToSeconds(timestamp))
    const videoUrl = player.value.getVideoUrl()
    const videoId = videoUrl.split('v=')[1]
    if (archivePlatformId === videoId && player.value.getPlayerState() === 1) {
        player.value.seekTo(seekTo)
    } else {
        player.value.loadVideoById({ videoId: archivePlatformId, startSeconds })
    }
    player.value.playVideo()
}

const handleModalClose = () => {
    player.value?.stopVideo?.()
    player.value?.destroy?.()
    player.value = null
    // Have to wait for the modal to close before removing it from the DOM
    setTimeout(() => {
        remove()
        selectedArchivedVideo.value = undefined
    }, 200)
    store.commit('modal:setData', {
        modal_id: 'creator_ask_ai',
        data: {
            is_open: false,
            campaign_id: null,
            supercampaign_id: null,
            creator: null,
        },
    })
    resetAskQuestion()
}

const handleVideoClose = () => {
    selectedArchivedVideo.value = undefined
    player.value?.stopVideo?.()
    resetAskQuestion()
}

const getArchiveStatus = (archivedVideo) => {
    if (archivedVideo?.upload_status === 'processed') {
        if (archivedVideo?.captions_retrieved_at) {
            return {
                text: 'Archived',
                color: 'green',
            }
        }
        return {
            text: 'Waiting for captions',
            color: 'yellow',
        }
    }
    if (archivedVideo?.upload_status === 'uploaded') {
        return {
            text: 'Processing',
            color: 'yellow',
        }
    }
    if (!archivedVideo) {
        return {
            text: 'Queued for upload',
            color: 'blue',
        }
    }
    return {
        text: 'Unknown',
        color: 'red',
    }
}

const qnasForChapter = computed(() => {
    if (!selectedArchivedVideo.value) {
        return data.value?.qnas?.filter((qna) => !qna.chapter_id)
    }

    return data.value?.qnas?.filter((qna) => qna.chapter_id === selectedArchivedVideo.value.chapter_id)
})

const handleExampleQuestionClick = (question) => {
    input.value = question
    handleFormSubmit()
}

const hoverQuote = ref(null)
const sampleQuestions = ref(_.shuffle(QUESTIONS).slice(0, 3))

const handleShuffleQuestionsClick = () => {
    sampleQuestions.value = _.shuffle(QUESTIONS).slice(0, 3)
}

const chaptersByChapterId = computed(() => _.keyBy(data.value?.chapters, '_id'))

const archivedVideos = computed(() => compact(data.value?.chapters?.flatMap((chapter) => chapter.archivedVideos)))

watch(chaptersByChapterId, () => {
    console.log('chaptersByChapterId', chaptersByChapterId.value)
})
</script>

<template>
    <ModalVideo :modal-open="modal.is_open" @close-modal="handleModalClose()">
        <div class="flex h-full">
            <div class="h-full md:w-[24rem] lg:w-[36rem] xl:w-[48rem] 2xl:w-[72rem]">
                <div :class="selectedArchivedVideo ? 'block' : 'hidden'" class="h-full border-r">
                    <div class="flex h-[50px] items-center p-4">
                        <button
                            class="whitespace-nowrap text-sm font-medium text-indigo-500 hover:cursor-pointer hover:text-indigo-600"
                            @click="handleVideoClose"
                        >
                            &lt;- Back to chapters
                        </button>
                    </div>
                    <div id="player" class="h-[calc(100%-50px)] w-full"></div>
                </div>
                <div :class="selectedArchivedVideo ? 'hidden' : 'block'" class="h-full border-r">
                    <div class="flex h-[50px] items-center border-b px-4">
                        <div class="font-semibold text-gray-500">
                            Archive of {{ modal.creator?.participant?.platform_user_name }}'s created content for
                            {{ supercampaignQueryData?.title }}
                        </div>
                    </div>
                    <div class="h-[calc(100%-50px)] overflow-y-auto">
                        <div class="grid grid-cols-[repeat(auto-fill,minmax(theme(width.48),1fr))] gap-2 p-2">
                            <template v-if="isLoading">
                                <div
                                    v-for="index in 6"
                                    :key="index"
                                    class="relative flex animate-pulse flex-col overflow-hidden rounded-md border p-2"
                                >
                                    <div class="rounded">
                                        <div class="h-32 w-full animate-pulse rounded-md bg-slate-300" />
                                    </div>
                                    <div class="flex grow flex-col justify-between space-y-4 pt-2">
                                        <div class="h-6 w-36 bg-slate-300 p-2"></div>
                                        <div class="space-y-1">
                                            <div class="h-4 w-12 bg-slate-300"></div>
                                            <div class="h-4 w-16 bg-slate-300"></div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div
                                v-for="archivedVideo in archivedVideos"
                                :key="archivedVideo.id"
                                class="relative flex flex-col overflow-hidden rounded-md border p-2 hover:cursor-pointer hover:bg-slate-100"
                                :class="
                                    hoverQuote &&
                                    hoverQuote.archivePlatformId === archivedVideo?.archive_platform_content_id
                                        ? 'bg-indigo-100'
                                        : ''
                                "
                                @click="
                                    () => {
                                        selectedArchivedVideo = archivedVideo
                                        seekTo({
                                            archivePlatformId: archivedVideo.archive_platform_content_id,
                                            timestamp: '00:00:00',
                                        })
                                    }
                                "
                            >
                                <div class="relative rounded">
                                    <img
                                        :src="
                                            archivedVideo.thumbnail ||
                                            'https://static-cdn.jtvnw.net/ttv-boxart/90388814_IGDB-288x384.jpg'
                                        "
                                        class="h-32 w-full rounded-md object-cover"
                                        :class="archivedVideo.thumbnail ? 'opacity-90' : 'opacity-30'"
                                        alt=""
                                    />
                                    <span
                                        class="absolute bottom-0 right-0 rounded-br bg-black bg-opacity-50 px-0.5 text-xs text-white"
                                        >{{ youtubeDurationToReadable(archivedVideo.duration) }}</span
                                    >
                                </div>
                                <div class="flex grow flex-col justify-between space-y-2 pt-2">
                                    <div class="text-xs">
                                        <span class="line-clamp-4 font-semibold">
                                            {{ archivedVideo.title }}
                                        </span>
                                    </div>
                                    <div class="text-xs">
                                        <div>
                                            {{
                                                moment(
                                                    chaptersByChapterId[archivedVideo.chapter_id]?.started_at ||
                                                        chaptersByChapterId[archivedVideo.platform_content_id]?.snippet
                                                            ?.publishedAt,
                                                ).fromNow()
                                            }}
                                        </div>
                                        <div
                                            class="inline-flex items-center rounded-md text-xs font-medium uppercase"
                                            :class="
                                                (() => {
                                                    const { color } = getArchiveStatus(archivedVideo)
                                                    if (color === 'green') {
                                                        return 'text-green-700'
                                                    } else if (color === 'yellow') {
                                                        return 'text-yellow-800'
                                                    } else if (color === 'red') {
                                                        return 'text-red-700'
                                                    } else if (color === 'blue') {
                                                        return 'text-blue-700'
                                                    }
                                                })()
                                            "
                                        >
                                            {{ getArchiveStatus(archivedVideo).text }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="relative h-full w-96 flex-col rounded">
                <div
                    class="pointer-events-none absolute bottom-0 z-[0] h-32 w-full bg-gradient-to-t from-slate-50"
                ></div>
                <div class="flex h-full w-96 flex-col rounded p-4">
                    <div class="font-semibold text-gray-400">Your AI Assistant</div>
                    <div class="my-3 w-full border-gray-300" />
                    <div
                        class="flex h-full grow flex-col justify-between overflow-y-auto pb-12 scrollbar scrollbar-thin"
                    >
                        <div
                            v-if="!askQuestionLoading && !askQuestionSuccess && qnasForChapter?.length > 0"
                            class="mb-2 space-y-2"
                        >
                            <div class="text-sm text-gray-400">Pre-generated Q&A</div>
                            <Question
                                v-for="qna in qnasForChapter"
                                :key="qna._id"
                                :question="qna.question"
                                :answer="qna.answer"
                                :on-quote-click="
                                    (quote) => {
                                        // Find the chapter in data and assign it
                                        selectedArchivedVideo = archivedVideos.find(
                                            (archivedVideo) =>
                                                archivedVideo.archive_platform_content_id === quote.archivePlatformId,
                                        )
                                        seekTo(quote)
                                    }
                                "
                                :on-quote-mouse-enter="
                                    (quote) => {
                                        hoverQuote = quote
                                    }
                                "
                                :on-quote-mouse-leave="
                                    () => {
                                        hoverQuote = undefined
                                    }
                                "
                            />
                        </div>
                        <div
                            v-if="
                                qnasForChapter?.length === 0 &&
                                !askQuestionLoading &&
                                !askQuestionData &&
                                !askQuestionError
                            "
                            class="mb-2 space-y-2 text-xs"
                        >
                            <div class="px-4 py-8 text-center">
                                <div
                                    class="mb-4 inline-flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-t from-slate-200 to-slate-100"
                                >
                                    <svg class="h-6 w-5 fill-current" viewBox="0 0 20 24">
                                        <path
                                            class="text-slate-500"
                                            d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"
                                        />
                                        <path
                                            class="text-slate-300"
                                            d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"
                                        />
                                        <path
                                            class="text-slate-400"
                                            d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                                        />
                                    </svg>
                                </div>
                                <h2 class="mb-2 text-2xl font-bold text-slate-800">Ask a question</h2>
                                <div class="mb-6">
                                    Gain insights on how well the creator performed in this campaign by asking a
                                    question about their content.
                                </div>
                            </div>
                            <div>
                                <div class="flex w-full justify-between justify-items-start text-gray-500">
                                    <div>Try these:</div>
                                    <button @click="handleShuffleQuestionsClick">
                                        <font-awesome-icon
                                            :icon="['fas', 'arrows-rotate']"
                                            class="my-auto h-3 w-3 text-indigo-700 hover:text-indigo-800"
                                        />
                                    </button>
                                </div>
                                <div class="space-y-1">
                                    <ExampleQuestion
                                        v-for="question in sampleQuestions"
                                        :key="question"
                                        :question="question"
                                        :on-click="handleExampleQuestionClick"
                                    />
                                </div>
                            </div>
                        </div>
                        <UserQuestion
                            v-if="askQuestionLoading || askQuestionSuccess || askQuestionError"
                            :question="currentAskedQuestion"
                            :answer="askQuestionData"
                            :loading="askQuestionLoading"
                            :error="askQuestionError"
                            :on-quote-click="
                                (quote) => {
                                    // Find the chapter in data and assign it
                                    selectedArchivedVideo = archivedVideos.find(
                                        (archivedVideo) =>
                                            archivedVideo.archive_platform_content_id === quote.archivePlatformId,
                                    )
                                    seekTo(quote)
                                }
                            "
                            :on-quote-mouse-enter="
                                (quote) => {
                                    hoverQuote = quote
                                }
                            "
                            :on-quote-mouse-leave="
                                () => {
                                    hoverQuote = undefined
                                }
                            "
                            :on-clear="
                                () => {
                                    resetAskQuestion()
                                    currentAskedQuestion = ''
                                }
                            "
                        />
                    </div>

                    <form
                        class="z-10 flex w-full items-center space-x-1 rounded-md pt-2"
                        @submit.prevent="handleFormSubmit"
                    >
                        <div class="relative w-full rounded-md bg-slate-100 py-1 text-slate-500 shadow-sm">
                            <textarea
                                ref="textarea"
                                v-model="input"
                                :style="{ height: height ? `${height}px` : 'auto' }"
                                rows="1"
                                class="block h-auto max-h-[200px] w-full resize-none overflow-auto rounded-md border-0 bg-slate-100 py-1.5 pr-12 text-sm text-gray-900 transition-[height] duration-200 placeholder:text-gray-400 focus:placeholder-gray-500 focus:outline-none focus:ring-0"
                                placeholder="Ask StreamforgeGPT anything"
                                @keydown.enter.prevent="handleFormSubmit"
                            />
                            <button
                                class="absolute bottom-1 right-2 rounded-md p-2 text-white transition-colors enabled:bg-indigo-700 disabled:text-gray-400 disabled:opacity-40"
                                :disabled="!input"
                                type="submit"
                            >
                                <font-awesome-icon class="my-auto h-4 w-4" :icon="['fas', 'arrow-up']" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </ModalVideo>
</template>

<style scoped lang="scss"></style>
