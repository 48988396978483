<template>
    <ModalBasic id="save-filters-modal" :modal-open="props.isOpen" title="Edit Saved Search" @close-modal="handleClose">
        <div class="relative p-4 font-normal text-gray-600">
            <div></div>
            <div>
                <label class="block text-sm font-medium text-slate-600">Saved Search Name</label>
                <div class="mt-1">
                    <input
                        ref="searchInput"
                        v-model="title"
                        placeholder='e.g. "MMORPG Players"'
                        type="text"
                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-100 sm:text-sm"
                        :class="{ 'border-red-500 ring-red-500': editSavedSearch.error?.value?.response?.data }"
                        @input="editSavedSearch.reset()"
                    />
                    <div v-if="editSavedSearch.error?.value?.response?.data" class="p-1 text-xs text-red-700">
                        {{ editSavedSearch.error?.value?.response?.data?.message }}
                    </div>
                </div>
                <AppliedFilters
                    v-if="props.savedSearch"
                    :filters="
                        targetGroupTools.mapTargetGroupFromFilters(props.savedSearch, props.codes, {
                            twitch: targetGroupTools.createTwitchOptions().default,
                            youtube: targetGroupTools.createYoutubeOptions().default,
                            tiktok: targetGroupTools.createTiktokOptions().default,
                        })
                    "
                />

                <div class="flex items-center justify-between pt-4">
                    <button
                        type="button"
                        class="btn border-red-700 text-red-700 hover:border-red-800"
                        @click="isDeleteConfirmationOpen = true"
                    >
                        Delete
                    </button>
                    <div class="flex justify-end space-x-2">
                        <button
                            type="button"
                            class="btn border-slate-200 text-slate-600 hover:border-slate-300"
                            @click="handleCancel"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            class="btn bg-indigo-500 text-white enabled:hover:bg-indigo-600 disabled:pointer-events-none disabled:bg-indigo-300 disabled:text-white"
                            :disabled="!title || editSavedSearch.isLoading.value"
                            @click="handleSave()"
                        >
                            {{ editSavedSearch.isLoading.value ? 'Saving...' : 'Save' }}
                        </button>
                    </div>
                </div>
                <div v-if="isDeleteConfirmationOpen">
                    <div class="border-b py-2"></div>
                    <div class="flex items-center justify-between pt-2">
                        <div class="text-sm">Are you sure?</div>

                        <div class="space-x-2">
                            <button
                                type="button"
                                class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                                @click="isDeleteConfirmationOpen = false"
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                class="btn-sm bg-red-700 text-white"
                                :disabled="!title || editSavedSearch.isLoading.value"
                                @click="handleDelete()"
                            >
                                {{ editSavedSearch.isLoading.value ? 'Saving...' : 'Confirm Deletion' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ModalBasic>
</template>

<script setup>
import { computed, nextTick, ref, watch } from 'vue'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { notify } from '@kyvg/vue3-notification'
import { useStore } from 'vuex'
import ModalBasic from '@/components/ModalBasic.vue'
import { axios } from '@/utils/axios'
import AppliedFilters from '@/partials/modals/CreateSavedSearchModal/AppliedFilters.vue'
import targetGroupTools from '@/utils/searching/targetgroups'

const props = defineProps({
    isOpen: {
        type: Boolean,
        required: true,
    },
    onClose: {
        type: Function,
        required: true,
    },
    onSave: {
        type: Function,
        required: true,
    },
    onCancel: {
        type: Function,
        required: true,
    },
    savedSearch: {
        type: Object,
        required: true,
    },
    codes: {
        type: Array,
        required: true,
    },
})

const isDeleteConfirmationOpen = ref(false)
const searchInput = ref(null)

const store = useStore()
const selectedOrganization = computed(() => store.getters.selfActiveOrganization)

const queryClient = useQueryClient()

const editSavedSearch = useMutation({
    mutationFn: (body) =>
        axios.put(
            `/targeting/organization/${selectedOrganization.value.id}/${props.savedSearch.platform}/${props.savedSearch.id}`,
            body,
        ),
    onSuccess: async () => {
        await queryClient.invalidateQueries('target-group-by-organization-platform')
        notify({
            title: 'Saved Search Updated',
            text: `Your saved search has been updated.`,
            type: 'success',
        })
    },
})

const deleteSavedSearch = useMutation({
    mutationFn: () =>
        axios.delete(
            `/targeting/organization/${selectedOrganization.value.id}/${props.savedSearch.platform}/${props.savedSearch.id}`,
        ),
    onSuccess: async () => {
        await queryClient.invalidateQueries('target-group-by-organization-platform')
        notify({
            title: 'Saved Search Deleted',
            text: `Your saved search has been deleted.`,
            type: 'success',
        })
    },
})

const title = ref(props.savedSearch.title)

const handleClose = () => {
    title.value = ''
    editSavedSearch.reset()
    props.onClose()
}

const handleSave = async () => {
    await editSavedSearch.mutateAsync({
        title: title.value,
    })
    props.onSave()
    handleClose()
}

const handleCancel = () => {
    handleClose()
}

const handleDelete = async () => {
    await deleteSavedSearch.mutateAsync()
    handleClose()
}

watch(
    () => props.isOpen,
    async (newValue) => {
        if (newValue) {
            await nextTick()
            searchInput.value.focus()
        }
    },
    { immediate: true },
)
</script>
