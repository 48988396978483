import api from './api'

const apiinquiries = {
    async getInquiries(axios, campaignId, tab, page, pageSize, sortBy, sortDir) {
        const filters = []

        if (campaignId) {
            filters.push({
                id: 'campaign-id',
                value: campaignId,
            })
        }

        if (tab) {
            if (tab === 'interested') {
                filters.push({
                    id: 'is_interested',
                    value: 'true',
                })
            } else if (tab === 'uninterested') {
                filters.push({
                    id: 'is_interested',
                    value: 'false',
                })
            }
        }

        const body = {
            filters,
            paging: {
                perPage: pageSize,
                currentPage: page,
                sortby: sortBy,
                sortdir: sortDir,
            },
        }

        return axios
            .post(`/inquiry/find`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getInquiryPreview(axios, inquiryId) {
        return axios
            .get(`inquiry/${inquiryId}/preview`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apiinquiries
