<script setup>
import { EyeOff, ArrowUp, ArrowDown, ChevronsUpDown } from 'lucide-vue-next'
import Button from '@/components/ui/button/Button.vue'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    column: {
        type: Object,
        required: true,
    },
})
</script>
<template>
    <div class="flex items-center space-x-2">
        <DropdownMenu>
            <DropdownMenuTrigger as-child>
                <Button
                    variant="ghost"
                    size="sm"
                    class="text-semibold h-8 text-left text-xs uppercase data-[state=open]:bg-accent"
                >
                    <span>{{ props.title }}</span>
                    <template v-if="props.column.getCanSort()">
                        <ArrowDown v-if="column.getIsSorted() === 'desc'" class="ml-2 h-4 w-4" />
                        <ArrowUp v-else-if="column.getIsSorted() === 'asc'" class="ml-2 h-4 w-4" />
                        <ChevronsUpDown v-else class="ml-2 h-4 w-4" />
                    </template>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
                <DropdownMenuItem v-if="props.column.getCanSort()" @click="props.column.toggleSorting(false)">
                    <ArrowUp class="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                    Asc
                </DropdownMenuItem>
                <DropdownMenuItem v-if="props.column.getCanSort()" @click="props.column.toggleSorting(true)">
                    <ArrowDown class="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                    Desc
                </DropdownMenuItem>
                <DropdownMenuItem @click="props.column.toggleVisibility()">
                    <EyeOff class="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                    {{ column.getIsVisible() ? 'Hide' : 'Show' }}
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    </div>
</template>
