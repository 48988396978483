<template>
    <ModalBasic title="Bid submission" :modal-open="modals.editBid.isOpen" width="medium" @close-modal="onCloseEditBid">
        <div class="p-6">
            <PricingPage
                v-if="modals.editBid.isOpen"
                class="my-auto"
                :campaign="modals.editBid.campaign"
                :participant="modals.editBid.participant"
                :on-rate-submit="onBidSubmit"
                mode="roster"
            />
        </div>
        <div class="border-t border-slate-200 px-5 py-4">
            <div class="flex flex-wrap justify-end space-x-2">
                <button class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300" @click="onCloseEditBid()">
                    Close
                </button>
            </div>
        </div>
    </ModalBasic>

    <div>
        <div class="col-span-full rounded-sm border border-slate-200 bg-white shadow-lg">
            <div class="p-3">
                <div class="">
                    <table class="w-full table-auto">
                        <thead class="rounded-sm bg-slate-50 text-left text-xs uppercase text-slate-400">
                            <tr>
                                <th class="w-7 whitespace-nowrap p-2 pl-3 pl-5">
                                    <div class="flex"></div>
                                </th>

                                <th class="cursor-pointer px-2 py-3 hover:bg-slate-200" @click="onChangeSort(`name`)">
                                    <div class="flex">
                                        <span class="font-semibold">Creator</span>
                                        <ColumnSort
                                            :sort-by="paging.sort.sortBy"
                                            :sort-dir="paging.sort.sortDir"
                                            column="name"
                                        />
                                    </div>
                                </th>

                                <th
                                    class="cursor-pointer px-2 py-3 hover:bg-slate-200 md:w-1/6"
                                    @click="onChangeSort(`campaign-game`)"
                                >
                                    <div class="flex">
                                        <span class="font-semibold">Game</span>
                                        <ColumnSort
                                            :sort-by="paging.sort.sortBy"
                                            :sort-dir="paging.sort.sortDir"
                                            column="campaign-game"
                                        />
                                    </div>
                                </th>

                                <th
                                    class="cursor-pointer px-2 py-3 hover:bg-slate-200 md:w-1/6"
                                    @click="onChangeSort(`campaign-start`)"
                                >
                                    <div class="flex">
                                        <span class="font-semibold">Starts</span>
                                        <ColumnSort
                                            :sort-by="paging.sort.sortBy"
                                            :sort-dir="paging.sort.sortDir"
                                            column="campaign-start"
                                        />
                                    </div>
                                </th>

                                <th
                                    class="cursor-pointer px-2 py-3 hover:bg-slate-200 md:w-1/6"
                                    @click="onChangeSort(`campaign-end`)"
                                >
                                    <div class="flex">
                                        <span class="font-semibold">Ends</span>
                                        <ColumnSort
                                            :sort-by="paging.sort.sortBy"
                                            :sort-dir="paging.sort.sortDir"
                                            column="campaign-end"
                                        />
                                    </div>
                                </th>

                                <th
                                    v-if="showBids"
                                    class="cursor-pointer px-2 py-3 hover:bg-slate-200 md:w-1/6"
                                    @click="onChangeSort(`bid-status`)"
                                >
                                    <div class="flex">
                                        <span class="font-semibold">Status</span>
                                        <ColumnSort
                                            :sort-by="paging.sort.sortBy"
                                            :sort-dir="paging.sort.sortDir"
                                            column="bid-status"
                                        />
                                    </div>
                                </th>

                                <th class="px-2 py-3 md:w-1/12">
                                    <div
                                        class="flex w-full rounded bg-slate-100 text-slate-600 transition duration-200 hover:bg-slate-200"
                                    ></div>
                                </th>
                            </tr>
                        </thead>

                        <tbody class="divide-y divide-slate-200 border-b border-slate-200 text-sm">
                            <tr v-for="creator in creators" :key="creator.participant.id" class="group">
                                <td class="whitespace-nowrap px-2 py-3.5 pl-5"></td>

                                <td class="whitespace-nowrap px-2 py-3.5">
                                    <div class="relative flex items-center">
                                        <NamePlate
                                            v-if="creator.platform_id == 'twitch'"
                                            class="my-auto"
                                            :logo="creator?.superview?.channel?.logo"
                                            :name="creator?.superview?.channel?.name"
                                            :display-name="creator?.superview?.channel?.display_name"
                                            :platform-id="creator?.platform_id"
                                            :platform-user-id="creator?.superview?._id"
                                            :is-drawer-enabled="true"
                                            :is-limited-mode="true"
                                        />

                                        <NamePlate
                                            v-if="creator.platform_id == 'youtube'"
                                            class="my-auto"
                                            :logo="creator?.superview?.channel?.snippet?.thumbnails?.default?.url"
                                            :display-name="creator?.superview?.channel?.snippet?.title"
                                            platform-id="youtube"
                                            :platform-user-id="creator?.superview?._id"
                                            :is-drawer-enabled="true"
                                            :is-limited-mode="true"
                                        />
                                    </div>
                                </td>

                                <td class="whitespace-nowrap px-2 pr-5">
                                    {{ creator?.campaign?.game?.name }}
                                </td>

                                <td class="whitespace-nowrap px-2 pr-5">
                                    {{ $filters.formatDateAndTime(creator?.campaign?.started_at) }}
                                </td>

                                <td class="whitespace-nowrap px-2 pr-5">
                                    {{ $filters.formatDateAndTime(creator?.campaign?.ended_at) }}
                                </td>

                                <td v-if="showBids" class="whitespace-nowrap px-2 pr-5">
                                    <div class="w-full rounded">
                                        {{ getFromDictionary(`campaign.bid.status.${creator?.bid?.status}`, 'label') }}
                                    </div>
                                </td>

                                <td class="whitespace-nowrap px-2 pr-5">
                                    <div class="flex w-full rounded">
                                        <button
                                            v-if="!creator.bid"
                                            class="mr-4 cursor-pointer font-medium text-indigo-500 hover:text-indigo-600"
                                            @click="openBid(creator)"
                                        >
                                            Submit bid
                                        </button>
                                        <button
                                            v-if="creator.bid"
                                            class="mr-4 cursor-pointer font-medium text-indigo-500 hover:text-indigo-600"
                                            @click="openBid(creator)"
                                        >
                                            Edit bid
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="!creators || !creators.length" class="mb-4 mt-6 text-center text-sm">
                        <p v-if="!searchText && showBids" class="text-sm font-medium">No bids to show</p>
                        <p v-if="!searchText && !showBids" class="text-sm font-medium">No creators to show</p>
                        <p v-if="searchText" class="text-sm font-medium">
                            No creators found matching "{{ searchText }}"
                        </p>
                    </div>
                    <div v-else-if="paging && paging.totalRecords > 0" class="w-full p-4">
                        <DiscoveryPagination :paging="paging" @paging-changed="onPagingChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NamePlate from '@/components/plates/NamePlate.vue'
import ColumnSort from '@/components/ColumnSort.vue'
import DiscoveryPagination from '@/partials/tables/discovery/DiscoveryPagination.vue'
import PricingPage from '@/pages/PricingPage.vue'
import ModalBasic from '@/components/ModalBasic.vue'
import rosterAPI from '../../utils/api/api.organization.rosters'

export default {
    name: 'RosterTable',

    components: {
        NamePlate,
        ColumnSort,
        DiscoveryPagination,
        PricingPage,
        ModalBasic,
    },

    props: {
        creators: {
            type: Array,
            default: null,
        },
        paging: {
            type: Object,
        },
        showBids: {
            type: Boolean,
            default: false,
        },
        searchText: {
            type: String,
            default: '',
        },
    },

    emits: ['paging-changed', 'bid-submitted'],

    data() {
        return {
            modals: {
                editBid: {
                    isOpen: false,
                    campaign: {},
                    participant: {},
                },
            },
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'getLanguageFromCode', 'getCountryFromCode', 'selfActiveOrganizationId']),
    },

    methods: {
        onChangeSort(column) {
            let direction = 'asc'
            if (column === this.paging.sort.sortBy && this.paging.sort.sortDir === 'asc') {
                direction = 'desc'
            }

            this.$emit('paging-changed', {
                sort: {
                    sortBy: column,
                    sortDir: direction,
                },
            })
        },

        onPagingChange(newPagingProperties) {
            this.$emit('paging-changed', newPagingProperties)
        },

        async openBid(creator) {
            await this.getParticipant(creator)
        },

        onCloseEditBid() {
            this.modals.editBid.isOpen = false
        },

        onBidSubmit() {
            this.modals.editBid.isOpen = false
            this.$emit('bid-submitted')
        },

        async getParticipant(creator) {
            this.modals.editBid.participant = null
            this.modals.editBid.campaign = null

            const res = await rosterAPI.getCreatorParticipant(
                this.$axios,
                this.selfActiveOrganizationId,
                creator.roster_id,
                creator.id,
                creator.participant.id,
            )

            if (res.success) {
                this.modals.editBid.participant = res.value.participant
                this.modals.editBid.campaign = res.value.campaign
                this.modals.editBid.isOpen = true
            }
        },
    },
}
</script>
