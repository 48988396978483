import api from './api'

const apicampaignbids = {
    async findBids(axios, campaignId, paging) {
        return axios
            .get(`/campaigns/${campaignId}/bids`, paging)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getBid(axios, campaignId, participantId, bidId, paging) {
        return axios
            .get(`/campaigns/${campaignId}/participants/${participantId}/bids/${bidId}`, paging)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getCurrentParticipantBid(axios, participantId) {
        return axios
            .get(`/participants/${participantId}/bids`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getRosterBids(axios, organizationId, paging, filters, isExport) {
        const tPaging = paging
        tPaging.filters = filters
        const body = {
            paging: tPaging,
            export: isExport,
        }
        return axios
            .post(`/organizations/${organizationId}/roster/default/bids`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async sendBid(axios, participantId, body) {
        return axios
            .post(`/participants/${participantId}/bids/tasks`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async acceptBid(axios, participantId, bidId) {
        return axios
            .put(`/participants/${participantId}/bids/${bidId}/accept`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apicampaignbids
