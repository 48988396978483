<template>
    <div class="relative inline-flex">
        <div
            ref="trigger"
            aria-label="Lists"
            aria-haspopup="true"
            :aria-expanded="dropdownOpen"
            @click.prevent="dropdownOpen = !dropdownOpen"
        >
            <slot />
        </div>

        <transition
            enter-active-class="transition ease-out duration-100 transform"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="dropdownOpen"
                ref="dropdown"
                class="absolute left-0 top-full z-50 mt-1 rounded border border-slate-200 bg-white py-1.5 shadow-lg"
            >
                <div
                    class="text-sm font-medium text-slate-600"
                    @focusin="dropdownOpen = true"
                    @focusout="dropdownOpen = false"
                >
                    <button
                        v-for="option in options"
                        :key="$lodash.get(option, idField)"
                        class="flex w-full cursor-pointer items-center whitespace-nowrap px-3 py-1.5 pr-4 hover:bg-slate-50"
                        @click="onSelect(option)"
                    >
                        <svg
                            class="mr-2 shrink-0 fill-current text-slate-500"
                            :class="!selectedIds.includes($lodash.get(option, idField)) && 'invisible'"
                            width="12"
                            height="9"
                            viewBox="0 0 12 9"
                        >
                            <path
                                d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z"
                            />
                        </svg>
                        <span class="ml-1 text-left">{{ $lodash.get(option, textField) }}</span>
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import _ from 'lodash'
import { ref, onMounted, onUnmounted } from 'vue'

export default {
    name: 'DropdownMultiSelect',

    props: {
        options: {
            type: Array,
        },

        selectedIds: {
            type: Array,
            default: () => [],
        },

        textField: {
            type: String,
            default: 'text',
        },

        idField: {
            type: String,
            default: 'id',
        },

        initialSelectedIds: {
            type: String,
        },
    },

    emits: ['selection-change'],

    setup() {
        const dropdownOpen = ref(false)
        const trigger = ref(null)
        const dropdown = ref(null)

        // close on click outside
        const clickHandler = ({ target }) => {
            if (!dropdownOpen.value || dropdown.value.contains(target) || trigger.value.contains(target)) return
            dropdownOpen.value = false
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen.value || keyCode !== 27) return
            dropdownOpen.value = false
        }

        onMounted(() => {
            document.addEventListener('click', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        return {
            dropdownOpen,
            trigger,
            dropdown,
        }
    },

    data() {
        return {
            locallySelectedIds: [],
        }
    },

    watch: {
        selectedIds: {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.locallySelectedIds = newValue
                }
            },
            immediate: true,
        },
    },

    methods: {
        onSelect(option) {
            const toggledId = _.get(option, this.idField)

            if (this.selectedIds.includes(toggledId)) {
                this.locallySelectedIds = _.filter(this.selectedIds, (item) => item !== toggledId)
            } else {
                this.locallySelectedIds.push(toggledId)
            }

            this.$emit('selection-change', this.locallySelectedIds)
        },
    },
}
</script>
