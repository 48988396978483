<template>
    <!-- Skeleton body -->
    <div class="col-span-full bg-white">
        <!-- Skeleton table body -->
        <div class="p-3">
            <table class="w-full table-auto">
                <!-- Table Head-->
                <thead class="rounded-sm bg-slate-50 text-xs uppercase text-slate-400">
                    <tr>
                        <th class="whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5 md:w-1/6">
                            <div class="h-3 w-8 animate-pulse rounded bg-slate-200" />
                        </th>
                        <th class="whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5">
                            <div class="h-3 w-8 animate-pulse rounded bg-slate-200" />
                        </th>
                        <th class="whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5">
                            <div class="h-3 w-8 animate-pulse rounded bg-slate-200" />
                        </th>
                        <th class="whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5">
                            <div class="h-3 w-8 animate-pulse rounded bg-slate-200" />
                        </th>
                        <th class="whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5">
                            <div class="h-3 w-8 animate-pulse rounded bg-slate-200" />
                        </th>
                        <th class="whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5">
                            <div class="h-3 w-8 animate-pulse rounded bg-slate-200" />
                        </th>
                    </tr>
                </thead>

                <tbody class="divide-y divide-slate-200 border-b border-slate-200 text-sm">
                    <tr v-for="index in 10" :key="index">
                        <td class="whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5 md:w-1/6">
                            <div class="h-3 w-8 animate-pulse rounded bg-slate-200" />
                        </td>

                        <td class="whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5 md:w-1/6">
                            <div class="h-3 w-8 animate-pulse rounded bg-slate-200" />
                        </td>

                        <td class="whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5 md:w-1/6">
                            <div class="h-3 w-8 animate-pulse rounded bg-slate-200" />
                        </td>

                        <td class="whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5 md:w-1/6">
                            <div class="h-3 w-8 animate-pulse rounded bg-slate-200" />
                        </td>

                        <td class="whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5 md:w-1/6">
                            <div class="h-3 w-8 animate-pulse rounded bg-slate-200" />
                        </td>

                        <td class="whitespace-nowrap px-2 py-4 first:pl-5 last:pr-5 md:w-1/6">
                            <div class="h-3 w-16 animate-pulse rounded bg-slate-200" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkeletonGameKeyTable',
}
</script>
