import api from './api'

const apicodes = {
    async findCodes(axios, codeType, text, platform, sortBy, orderDir, currentFiltersContext) {
        if (!sortBy) {
            sortBy = ''
        }

        if (!orderDir) {
            orderDir = ''
        }

        const filters = [
            { id: 'text', value: text },
            { id: 'type', value: codeType },
            { id: 'platform', value: platform },
        ]

        if (['industry_category', 'industry_niche'].includes(codeType)) {
            filters.push({ id: 'industry', value: currentFiltersContext.industry })
        }

        return axios
            .post(`/metadata/search/${codeType}`, {
                paging: {
                    perPage: 50,
                    currentPage: 1,
                    sort: {
                        sortby: sortBy,
                        sortdir: orderDir,
                    },
                    filters,
                },
            })
            .then(({ data }) => api.createSuccessResponse(this.mapCodes(data.payload.items)))
    },

    mapCodes(codes) {
        if (codes) {
            // return _.map(codes, (item) => ({
            //     id: item.id,
            //     name: item.name,
            //     logo: item.logo,
            //     slug: item.slug,
            // }))

            return codes
        }

        return []
    },
}

export default apicodes
