import _ from 'lodash'

export default {
    state: {
        recentCampaignOrListAdds: [],
        maxRecents: 25,
    },

    /* eslint-disable no-param-reassign */
    mutations: {
        'recentCampaignOrListAdds:add': function (state, payload) {
            if (!state.recentCampaignOrListAdds.some((item) => item.id === payload.item.id)) {
                if (state.recentCampaignOrListAdds.length === state.maxRecents) {
                    state.recentCampaignOrListAdds.pop()
                }

                state.recentCampaignOrListAdds.unshift(payload.item)
            }
        },
        'recentCampaignOrListAdds:remove': function (state, item) {
            state.recentCampaignOrListAdds = _.filter(
                state.recentCampaignOrListAdds,
                (recentAdd) => recentAdd.id !== item.id,
            )
        },
        'recentCampaignOrListAdds:clear': function (state) {
            state.recentCampaignOrListAdds = []
        },
    },
    /* eslint-enable no-param-reassign */

    getters: {
        recentCampaignOrListAdds: (state) => state.recentCampaignOrListAdds,
    },
}
