export const QUESTIONS = [
    'What was the overall sentiment of the streamer toward the game? Were they positive, negative, or neutral in their commentary?',
    'Did the streamer highlight any specific features or aspects of the game that stood out to them, either positively or negatively?',
    'How much time was spent playing/showcasing the game versus other content on the stream?',
    'Did the streamer call out that the coverage was paid for by the developer/publisher? Was our sponsorship disclosed properly?',
    'Did the streamer encourage viewers to wishlist, follow, or purchase the game? Was there a call to action?',
    'Did the streamer encounter any noticeable bugs, glitches, or issues during their gameplay session?',
    'Did the streamer compare the game to other titles in the genre either positively or negatively?',
    'What parts of the game did the streamer praise or criticize the most?',
    "Did the streamer engage with the game's chat during the sponsored segment? What were viewers saying?",
    'Did the streamer giveaway any game keys or related swag during or after the sponsored segment?',
    'Did the streamer provide their subjective rating or review of the game on stream?',
    'Was the streamer already familiar with the game or playing it for the first time?',
    "Did the streamer suggest ideas for improvement or changes they'd like to see in the game?",
    'How confident did the streamer seem in their critique and commentary on the game?',
    'Did the streamer compare the game to similar titles made by the same developer?',
    "What was the streamer's background or experience with this game genre or franchise?",
    'Did the streamer engage with any in-game story or characters in a noticeable way?',
    "Did the streamer highlight the game's audio, visuals, controls, UI, or any other technical aspects?",
    'Did the streamer check out any multiplayer, co-op, or online features included in the game?',
    'How long was the sponsored gameplay session compared to agreement length?',
    'Did the streamer commit to playing the game again on future streams?',
    'Did the streamer shoutout or thank any developer/publisher representatives by name?',
]
