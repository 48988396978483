<!-- BackgroundActionNotifications.vue -->
<template>
    <div class="fixed bottom-5 right-10 z-50">
        <TransitionGroup name="notification-list" tag="div" class="space-y-2">
            <div
                v-for="action in backgroundActions"
                :key="action.id"
                class="flex items-center justify-between rounded-md border border-slate-200 bg-white px-4 py-2 shadow-sm"
            >
                <div class="mr-4">
                    <span v-if="action.status === 'completed'" class="text-emerald-500">
                        <font-awesome-icon :icon="['fas', 'check']" class="h-6 w-6" />
                    </span>
                    <span v-else class="text-indigo-500">
                        <font-awesome-icon :icon="['fas', 'spinner-third']" class="h-6 w-6 animate-spin" />
                    </span>
                </div>
                <div class="flex flex-col">
                    <span class="w-80 text-sm text-gray-700">{{ action.description }}</span>
                    <span class="text-xs text-gray-500">{{ getActionDuration(action) }}</span>
                    <span v-if="action.status === 'completed'" class="text-xs text-gray-400">
                        Closing in {{ getAutoCloseCountdown(action) }}s
                    </span>
                </div>
                <div v-if="action.status === 'completed'" class="ml-8 flex items-center">
                    <button
                        class="inline rounded px-1.5 text-gray-500 transition duration-200 hover:bg-slate-100 hover:text-gray-700"
                        @click="removeBackgroundAction(action.id)"
                    >
                        <font-awesome-icon icon="times" />
                    </button>
                </div>
            </div>
        </TransitionGroup>
    </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import moment from 'moment'
import { useBackgroundActions } from '@/composables/useBackgroundActions'

export default {
    name: 'BackgroundAction',
    components: {
        FontAwesomeIcon,
    },
    setup() {
        const store = useStore()
        const { removeBackgroundAction } = useBackgroundActions()
        const backgroundActions = computed(() => store.getters.backgroundActions)
        const autoCloseCountdowns = ref({})

        const getActionDuration = (action) => {
            const start = moment(action.startTime)
            const end = action.endTime ? moment(action.endTime) : moment()
            return moment.duration(end.diff(start)).humanize()
        }

        const getAutoCloseCountdown = (action) => {
            if (action.status !== 'completed') return ''
            if (!autoCloseCountdowns.value[action.id]) {
                autoCloseCountdowns.value[action.id] = 15
                const interval = setInterval(() => {
                    if (autoCloseCountdowns.value[action.id] > 0) {
                        autoCloseCountdowns.value[action.id] -= 1
                    } else {
                        clearInterval(interval)
                    }
                }, 1000)
            }
            return autoCloseCountdowns.value[action.id]
        }

        return {
            backgroundActions,
            getActionDuration,
            getAutoCloseCountdown,
            removeBackgroundAction,
        }
    },
}
</script>

<style>
.notification-list-enter-active,
.notification-list-leave-active {
    transition: all 0.5s ease;
}

.notification-list-enter-from,
.notification-list-leave-to {
    opacity: 0;
    transform: translateY(30px);
}
</style>
