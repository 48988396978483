<template>
    <ModalBasic
        title="Remove creator from roster"
        :modal-open="modals.removeCreator.isOpen"
        @close-modal="onCloseRemoveCreator"
    >
        <div class="p-6">
            <div class="text-sm">
                <div>
                    <p>Are you sure you want to remove this creator?</p>
                </div>
            </div>
        </div>
        <div class="border-t border-slate-200 px-5 py-4">
            <div class="flex flex-wrap justify-end space-x-2">
                <button
                    class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                    @click="onCloseRemoveCreator()"
                >
                    Cancel
                </button>

                <button class="btn-sm bg-rose-500 text-white hover:bg-rose-600" @click="onRemoveCreator()">
                    Remove
                </button>
            </div>
        </div>
    </ModalBasic>

    <ModalBasic title="Edit creator rates" :modal-open="modals.editCreator.isOpen" @close-modal="onCloseEditCreator">
        <div class="p-6">
            <div class="text-sm">
                <div>
                    <div v-for="rateType in rateTypes[platformId]" :key="rateType">
                        <div class="col-span-12 mb-2">
                            <label class="mb-2 block text-sm font-medium">{{
                                getFromDictionary(`finance.rate_types.${rateType}`, 'title')
                            }}</label>
                            <input
                                id="normal"
                                v-model="modals.editCreator.values[rateType]"
                                class="form-input w-50"
                                type="number"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="border-t border-slate-200 px-5 py-4">
            <div class="flex flex-wrap justify-end space-x-2">
                <button
                    class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                    @click="onCloseEditCreator()"
                >
                    Cancel
                </button>

                <button class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600" @click="onSaveEditCreator()">
                    Save
                </button>
            </div>
        </div>
    </ModalBasic>

    <CreatorSearchModal
        :modal-open="modals.addCreator.isOpen"
        :default-platform-id="platformId"
        action-text="Add to List"
        @close-modal="modals.addCreator.isOpen = false"
        @submit="onCreatorAddSubmit"
    />

    <BulkImportCreatorsModal
        :modal-open="modals.bulkAddCreators.isOpen"
        :submit-externally="true"
        @close-modal="modals.bulkAddCreators.isOpen = false"
        @submit="onBulkImportSubmit"
    />

    <div>
        <header class="relative border-b border-slate-100 py-2 shadow-sm hover:shadow-sm focus:shadow-sm">
            <div class="flex items-center justify-between">
                <div
                    class="inline-flex rounded bg-slate-200 p-1 shadow-sm hover:shadow-sm focus:shadow-sm"
                    role="group"
                >
                    <button
                        class="flex rounded px-5 py-2.5 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                        :class="{
                            'bg-white': platformId === 'twitch',
                        }"
                        @click.prevent="onSwitchPlatform('twitch')"
                    >
                        <font-awesome-icon class="mr-2 h-4 w-4 text-twitch" :icon="['fab', 'twitch']" />
                        <span>Twitch</span>
                    </button>

                    <button
                        class="flex rounded px-5 py-2.5 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                        :class="{
                            'bg-white': platformId === 'youtube',
                        }"
                        @click.prevent="onSwitchPlatform('youtube')"
                    >
                        <font-awesome-icon class="mr-2 h-4 w-4 text-youtube" :icon="['fab', 'youtube']" />
                        <span>YouTube</span>
                    </button>
                </div>

                <div class="hidden items-center sm:flex">
                    <button
                        class="btn mx-2 bg-indigo-500 text-white hover:bg-indigo-600"
                        @click="modals.addCreator.isOpen = true"
                    >
                        <svg class="h-4 w-4 shrink-0 fill-current opacity-50" viewBox="0 0 16 16">
                            <path
                                d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"
                            />
                        </svg>
                        <span class="ml-2 lg:block">Add Creators</span>
                    </button>

                    <!-- Right: Actions  -->
                    <div class="relative grid grid-flow-col justify-start gap-2 sm:auto-cols-max sm:justify-end">
                        <form class="relative my-auto" @submit.prevent>
                            <label for="action-search" class="sr-only">Search</label>
                            <input
                                id="action-search"
                                v-model="searchText"
                                class="form-input pl-9 focus:border-slate-300"
                                type="search"
                                placeholder="Search..."
                                @input="onSearch"
                            />
                            <div class="group absolute inset-0 right-auto" aria-label="Search">
                                <svg
                                    class="ml-3 mr-2 mt-2.5 h-4 w-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                                    />
                                    <path
                                        d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                                    />
                                </svg>
                            </div>
                        </form>

                        <div class="relative">
                            <button
                                class="btn bg-indigo-500 text-white hover:bg-indigo-600"
                                @click="modals.bulkAddCreators.isOpen = true"
                            >
                                Import Creators
                            </button>
                        </div>

                        <button
                            v-if="isSfStaff"
                            class="btn bg-indigo-500 text-white hover:bg-indigo-600"
                            @click="onExportCreators"
                        >
                            Export Creators
                        </button>
                    </div>
                </div>
            </div>
        </header>

        <div class="col-span-full rounded-sm border border-slate-200 bg-white shadow-lg">
            <div class="p-3">
                <div class="">
                    <table class="w-full table-auto">
                        <thead class="rounded-sm bg-slate-50 text-left text-xs uppercase text-slate-400">
                            <tr>
                                <th
                                    v-if="platformId === 'twitch'"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 hover:bg-slate-200"
                                    @click="onChangeSort(`channel.name`)"
                                >
                                    <div class="flex">
                                        <span class="font-semibold">Creator</span>
                                        <ColumnSort
                                            :sort-by="paging.sortby"
                                            :sort-dir="paging.sortdir"
                                            column="channel.name"
                                        />
                                    </div>
                                </th>

                                <th
                                    v-if="platformId === 'youtube'"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 hover:bg-slate-200"
                                    @click="onChangeSort(`channel.snippet.title`)"
                                >
                                    <div class="flex">
                                        <span class="font-semibold">Creator</span>
                                        <ColumnSort
                                            :sort-by="paging.sortby"
                                            :sort-dir="paging.sortdir"
                                            column="channel.snippet.title"
                                        />
                                    </div>
                                </th>

                                <th
                                    v-if="platformId === 'twitch'"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 hover:bg-slate-200"
                                    @click="onChangeSort(`stream.day_90.aggregation.viewers.avg`)"
                                >
                                    <div class="flex text-left">
                                        <span class="mr-2 font-semibold">Avg CCV</span>

                                        <Tooltip class="box-content">
                                            <template #trigger>
                                                <font-awesome-icon
                                                    class="h-3.5 w-3.5 hover:text-slate-500"
                                                    :icon="['fas', 'circle-info']"
                                                />
                                            </template>
                                            <template #default>
                                                <div class="p-2">
                                                    <h5 class="text-xl font-bold text-slate-200">
                                                        Average Concurrent Viewers
                                                    </h5>
                                                    <p class="text-xs font-medium text-slate-300">
                                                        The average number of viewers you would expect to see in this
                                                        creator's stream at any point in the last 90 days.
                                                    </p>
                                                </div>
                                            </template>
                                        </Tooltip>
                                        <ColumnSort
                                            :sort-by="paging.sortby"
                                            :sort-dir="paging.sortdir"
                                            column="stream.day_90.aggregation.viewers.avg"
                                        />
                                    </div>
                                </th>

                                <th
                                    v-if="platformId === 'youtube'"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 hover:bg-slate-200"
                                    @click="onChangeSort(`video.day_90.aggregation.views.avg`)"
                                >
                                    <div class="flex text-left">
                                        <span class="mr-2 font-semibold">Avg Video Views</span>

                                        <Tooltip>
                                            <template #trigger>
                                                <font-awesome-icon
                                                    class="h-3.5 w-3.5 hover:text-slate-500"
                                                    :icon="['fas', 'circle-info']"
                                                />
                                            </template>
                                            <template #default>
                                                <div class="p-2">
                                                    <h5 class="text-xl font-bold text-slate-200">
                                                        Average Video Views
                                                    </h5>
                                                    <p class="text-xs font-medium text-slate-300">
                                                        The average number of views a YouTube video from the last 90
                                                        days made by this creator would get.
                                                    </p>
                                                </div>
                                            </template>
                                        </Tooltip>
                                        <ColumnSort
                                            :sort-by="paging.sortby"
                                            :sort-dir="paging.sortdir"
                                            column="video.day_90.aggregation.views.avg"
                                        />
                                    </div>
                                </th>

                                <th
                                    v-if="platformId === 'youtube'"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 hover:bg-slate-200"
                                    @click="onChangeSort(`channel.statistics.subscriberCount`)"
                                >
                                    <div class="flex text-left">
                                        <span class="mr-2 font-semibold">Subscribers</span>

                                        <Tooltip>
                                            <template #trigger>
                                                <font-awesome-icon
                                                    class="h-3.5 w-3.5 hover:text-slate-500"
                                                    :icon="['fas', 'circle-info']"
                                                />
                                            </template>
                                            <template #default>
                                                <div class="p-2">
                                                    <h5 class="text-xl font-bold text-slate-200">Subscriber Count</h5>
                                                    <p class="text-xs font-medium text-slate-300">
                                                        The total number of users on YouTube that have chosen to
                                                        subscribe to this creator's channel.
                                                    </p>
                                                </div>
                                            </template>
                                        </Tooltip>
                                        <ColumnSort
                                            :sort-by="paging.sortby"
                                            :sort-dir="paging.sortdir"
                                            column="channel.statistics.subscriberCount"
                                        />
                                    </div>
                                </th>

                                <th
                                    v-if="platformId === 'twitch'"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 hover:bg-slate-200"
                                    @click="onChangeSort(`channel.followers`)"
                                >
                                    <div class="flex text-left">
                                        <span class="mr-2 font-semibold">Followers</span>

                                        <Tooltip>
                                            <template #trigger>
                                                <font-awesome-icon
                                                    class="h-3.5 w-3.5 hover:text-slate-500"
                                                    :icon="['fas', 'circle-info']"
                                                />
                                            </template>
                                            <template #default>
                                                <div class="p-2">
                                                    <h5 class="text-xl font-bold text-slate-200">Follower Count</h5>
                                                    <p class="text-xs font-medium text-slate-300">
                                                        The total number of users on Twitch that have chosen to follow
                                                        this creator.
                                                    </p>
                                                </div>
                                            </template>
                                        </Tooltip>
                                        <ColumnSort
                                            :sort-by="paging.sortby"
                                            :sort-dir="paging.sortdir"
                                            column="channel.followers"
                                        />
                                    </div>
                                </th>

                                <th
                                    v-for="rateType in rateTypes[platformId]"
                                    :key="rateType"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 hover:bg-slate-200"
                                >
                                    <div class="flex">
                                        <span class="font-semibold">{{
                                            getFromDictionary(`finance.rate_types.${rateType}`, 'title_short')
                                        }}</span>
                                    </div>
                                </th>

                                <th class="w-7 pl-2 pr-5">
                                    <div class="flex">
                                        <span class="font-semibold">Actions</span>
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <tbody v-if="!isLoading" class="divide-y divide-slate-200 border-b border-slate-200 text-sm">
                            <tr v-for="creator in creators" :key="creator.id" class="group">
                                <td class="whitespace-nowrap px-2 py-3.5 pl-5">
                                    <div class="relative flex items-center">
                                        <NamePlate
                                            v-if="platformId == 'twitch'"
                                            class="my-auto"
                                            :logo="creator?.superview?.channel?.logo"
                                            :name="creator?.superview?.channel?.name"
                                            :display-name="creator?.superview?.channel?.display_name"
                                            :platform-id="creator?.platform_id"
                                            :platform-user-id="creator?.superview?._id"
                                            :is-drawer-enabled="true"
                                            :is-limited-mode="true"
                                        />

                                        <NamePlate
                                            v-if="platformId == 'youtube'"
                                            class="my-auto"
                                            :logo="creator?.superview?.channel?.snippet?.thumbnails?.default?.url"
                                            :display-name="creator?.superview?.channel?.snippet?.title"
                                            platform-id="youtube"
                                            :platform-user-id="creator?.superview?._id"
                                            :is-drawer-enabled="true"
                                            :is-limited-mode="true"
                                        />
                                    </div>
                                </td>

                                <td
                                    v-if="platformId === 'twitch'"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6"
                                >
                                    <div
                                        v-if="creator.superview?.stream?.day_90?.aggregation?.viewers?.avg"
                                        class="text-left"
                                    >
                                        {{
                                            $filters
                                                .numeral(creator.superview?.stream?.day_90?.aggregation?.viewers?.avg)
                                                .format('0,0')
                                        }}
                                    </div>
                                    <span v-else class="text-left">---</span>
                                </td>

                                <td
                                    v-if="platformId === 'youtube'"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6"
                                >
                                    <div
                                        v-if="creator?.superview?.video?.day_90?.aggregation?.views?.avg"
                                        class="text-left"
                                    >
                                        {{
                                            $filters
                                                .numeral(creator?.superview?.video?.day_90?.aggregation?.views?.avg)
                                                .format('0,0')
                                        }}
                                    </div>
                                    <span v-else class="text-left">---</span>
                                </td>

                                <td
                                    v-if="platformId === 'twitch'"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6"
                                >
                                    <div class="text-left">
                                        {{ $filters.formatWholeNumberWithUnit(creator?.superview?.channel?.followers) }}
                                    </div>
                                </td>

                                <td
                                    v-if="platformId === 'youtube'"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6"
                                >
                                    <span
                                        v-if="creator?.superview?.channel?.statistics?.subscriberCount"
                                        class="text-left"
                                    >
                                        {{
                                            $filters.formatWholeNumberWithUnit(
                                                creator?.superview?.channel?.statistics?.subscriberCount,
                                            )
                                        }}
                                    </span>
                                    <span v-else class="text-left">---</span>
                                </td>

                                <td
                                    v-for="(value, key) in creatorRates(creator.ratecard)"
                                    :key="`creator-rate-${key}`"
                                    class="w-7 whitespace-nowrap px-2 pr-5"
                                >
                                    <span v-if="value">
                                        {{ $filters.formatCurrency(value) }}
                                    </span>
                                    <span v-else> --- </span>
                                </td>

                                <td class="w-7 whitespace-nowrap px-2 pr-5">
                                    <div class="flex w-full rounded">
                                        <button
                                            class="mr-4 cursor-pointer font-medium text-indigo-500 hover:text-indigo-600"
                                            @click="onOpenEditCreator(creator)"
                                        >
                                            Edit rates
                                        </button>
                                        <button
                                            class="cursor-pointer font-medium text-red-500 hover:text-red-600"
                                            @click="onOpenRemoveCreator(creator.id)"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="!creators || (!creators.length && !isLoading)" class="mb-4 mt-6 text-center text-sm">
                        <p v-if="!searchText" class="text-sm font-medium">No creators to show</p>
                        <p v-if="searchText" class="text-sm font-medium">
                            No creators found matching "{{ searchText }}"
                        </p>
                    </div>

                    <div class="w-full p-4">
                        <DiscoveryPagination v-if="!isLoading" :paging="paging" @paging-changed="onPagingChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import NamePlate from '@/components/plates/NamePlate.vue'
import CreatorSearchModal from '@/partials/modals/CreatorSearchModal.vue'
import Tooltip from '@/components/Tooltip.vue'
import ColumnSort from '@/components/ColumnSort.vue'
import DiscoveryPagination from '@/partials/tables/discovery/DiscoveryPagination.vue'
import ModalBasic from '@/components/ModalBasic.vue'
import BulkImportCreatorsModal from '../modals/BulkImportCreatorsModal.vue'

export default {
    name: 'RosterTable',

    components: {
        NamePlate,
        CreatorSearchModal,
        Tooltip,
        ColumnSort,
        DiscoveryPagination,
        ModalBasic,
        BulkImportCreatorsModal,
    },

    props: {
        creators: {
            type: Array,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: true,
        },
        paging: {
            type: Object,
        },
        platformId: {
            type: String,
            default: '',
        },
    },

    emits: [
        'add-to-roster',
        'switch-platform',
        'paging-change',
        'perform-search',
        'remove-creator',
        'edit-creator-rates',
        'export-creators',
    ],

    data() {
        return {
            currentList: {},
            creatorsTest: [],
            searchText: '',

            modals: {
                addCreator: {
                    isOpen: false,
                },
                bulkAddCreators: {
                    isOpen: false,
                },
                removeCreator: {
                    isOpen: false,
                    rosterCreatorId: null,
                },
                editCreator: {
                    isOpen: false,
                    rosterCreatorId: null,
                    values: {},
                    current: [],
                },
            },

            rateTypes: {
                twitch: ['twitch-cpavph', 'twitch-cpavph2', 'twitch-cpavph4'],
                youtube: ['youtube-cpm'],
            },
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'getLanguageFromCode', 'getCountryFromCode', 'isSfStaff']),
    },

    methods: {
        creatorItemsWithValue(creator) {
            if (creator && creator.ratecard && creator.ratecard.items) {
                const items = _.filter(creator.ratecard.items, (item) => item.value)
                return _.sortBy(items, (item) => this.getFromDictionary(`finance.rate_types.${item.type_id}`, 'order'))
            }

            return []
        },

        creatorRates(ratecard) {
            const results = _.reduce(
                this.rateTypes[this.platformId],
                (result, rateType) => {
                    result[rateType] = null
                    return result
                },
                {},
            )

            _.forEach(ratecard.items, (item) => {
                results[item.type_id] = item.value
            })

            return results
        },

        onCreatorAddSubmit(creators) {
            const creatorsToAdd = _.map(creators, (creator) => ({
                platform_id: creator.superview.platform_id,
                /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
                platform_user_id: creator.superview._id,
            }))

            this.$emit('add-to-roster', creatorsToAdd)
        },

        onSwitchPlatform(platform) {
            this.$emit('switch-platform', platform)
        },

        onChangeSort(column) {
            let direction = 'asc'
            if (column === this.paging.sortby && this.paging.sortdir === 'asc') {
                direction = 'desc'
            }

            this.$emit('paging-change', {
                sortby: column,
                sortdir: direction,
            })
        },

        onPagingChange(newPagingProperties) {
            this.$emit('paging-change', newPagingProperties)
        },

        onSearch() {
            this.$emit('perform-search', this.searchText)
        },

        onOpenRemoveCreator(rosterCreatorId) {
            this.modals.removeCreator.rosterCreatorId = rosterCreatorId
            this.modals.removeCreator.isOpen = true
        },

        onCloseRemoveCreator() {
            this.modals.removeCreator.rosterCreatorId = null
            this.modals.removeCreator.isOpen = false
        },

        onRemoveCreator() {
            this.$emit('remove-creator', this.modals.removeCreator.rosterCreatorId)
            this.onCloseRemoveCreator()
        },

        onOpenEditCreator(creator) {
            let rates = []

            if (creator && creator.ratecard && creator.ratecard.items) {
                rates = creator.ratecard.items
            }

            this.modals.editCreator.rosterCreatorId = creator.id
            this.modals.editCreator.platformId = this.platformId
            this.modals.editCreator.current = rates

            this.modals.editCreator.values = {}
            _.forEach(rates, (rate) => {
                this.modals.editCreator.values[rate.type_id] = rate.value
            })

            this.modals.editCreator.isOpen = true
        },

        onCloseEditCreator() {
            this.modals.editCreator.rosterCreatorId = null
            this.modals.editCreator.values = []
            this.modals.editCreator.isOpen = false
        },

        onSaveEditCreator() {
            let currentRates = this.modals.editCreator.current

            if (!currentRates) {
                currentRates = []
            }

            _.forOwn(this.modals.editCreator.values, (value, key) => {
                const existing = _.find(currentRates, (rate) => rate.type_id === key)

                if (existing) {
                    existing.value = value
                } else {
                    currentRates.push({ type_id: key, value })
                }
            })

            this.$emit('edit-creator-rates', this.modals.editCreator.rosterCreatorId, currentRates)
            this.onCloseEditCreator()
        },

        onBulkImportSubmit(creatorsToAdd) {
            this.$emit('add-to-roster', creatorsToAdd)
        },

        onExportCreators() {
            this.$emit('export-creators')
        },
    },
}
</script>
