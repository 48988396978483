<script setup>
import AffinityPlate from '@/components/plates/AffinityPlate.vue'
import Tooltip from '@/components/Tooltip.vue'

const props = defineProps({
    selectedPlatformId: {
        type: String,
        required: true,
    },
    affinity: {
        type: Object,
        required: true,
    },
})
</script>

<template>
    <Tooltip placement="top" max-width="400" class="h-full w-full cursor-pointer">
        <template #trigger>
            <AffinityPlate :affinity-percent="props.affinity.affinity" />
        </template>
        <template #default>
            <div class="text-xs">
                <div class="font-semibold text-white">Affinity</div>
                <p>
                    Affinity is a representation of how good a game is for a creator's channel. The formula is a
                    combination of the creator and their audience's preferences.
                </p>

                <div class="mt-3 font-semibold text-white">Creator Preference</div>
                <p v-if="props.selectedPlatformId === 'twitch'">
                    The creator streamed
                    <span class="font-semibold text-white">{{ props.affinity.name }}</span>
                    for
                    <span class="font-semibold text-white"
                        >{{ $numeral(props.affinity.duration_seconds / (60 * 60)).format('0,0') }}
                        hours
                    </span>
                    on their channel. This is
                    <span class="font-semibold text-white"
                        >{{ $numeral(Math.abs(props.affinity.raw_difference_percent)).format('0,0%') }}
                        {{ props.affinity.raw_difference_percent >= 0 ? 'more' : 'less' }}</span
                    >
                    than an average creator would stream it for.
                </p>
                <p v-else-if="props.selectedPlatformId === 'youtube'">
                    The creator made
                    <span class="font-semibold text-white">{{ props.affinity.count }}</span>
                    videos showcasing
                    <span class="font-semibold text-white">{{ props.affinity.name }}</span>
                    on their channel. This is
                    <span class="font-semibold text-white"
                        >{{ $numeral(Math.abs(props.affinity.raw_difference_percent)).format('0,0%') }}
                        {{ props.affinity.raw_difference_percent >= 0 ? 'more' : 'less' }}</span
                    >
                    videos than an average creator would make for this game.
                </p>

                <div class="mt-3 font-semibold text-white">Audience Preference</div>
                <p>
                    Compared to their main niche of content, when the creator makes content about
                    <span class="font-semibold text-white">{{ props.affinity.name }}</span>
                    their viewership
                    <span class="font-semibold text-white"
                        >{{ props.affinity.audience_reception > 0 ? 'increases' : 'decreases' }}
                        by
                        {{ $numeral(Math.abs(props.affinity.audience_reception)).format('0,0%') }}</span
                    >.
                </p>
            </div>
        </template>
    </Tooltip>
</template>
