// composables/useBackgroundActions.js
import { computed } from 'vue'
import { useStore } from 'vuex'

export function useBackgroundActions() {
    const store = useStore()

    const backgroundActions = computed(() => store.getters.backgroundActions)

    const addBackgroundAction = (updates) => {
        return store.dispatch('addBackgroundAction', updates)
    }

    const updateBackgroundAction = (id, updates) => {
        store.dispatch('updateBackgroundAction', { id, updates })
    }

    const completeBackgroundAction = (id) => {
        store.dispatch('completeBackgroundAction', id)
    }

    const removeBackgroundAction = (id) => {
        store.dispatch('removeBackgroundAction', id)
    }

    return {
        backgroundActions,
        addBackgroundAction,
        updateBackgroundAction,
        completeBackgroundAction,
        removeBackgroundAction,
    }
}
