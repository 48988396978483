<template>
    <div class="w-full overflow-x-auto">
        <table class="w-full table-auto">
            <thead class="rounded-sm bg-slate-100 text-xs uppercase text-slate-400">
                <tr>
                    <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                        <div class="text-left font-semibold">Permission</div>
                    </th>
                    <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                        <div class="text-left font-semibold"></div>
                    </th>
                    <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                        <div class="text-right font-semibold">Actions</div>
                    </th>
                </tr>
            </thead>

            <tbody v-if="isLoading === true" class="divide-y divide-slate-200 text-sm">
                <tr v-for="index in 5" :key="index">
                    <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/2">
                        <div class="h-3 w-full animate-pulse rounded bg-slate-200" />
                    </td>

                    <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/4">
                        <div class="h-3 w-full animate-pulse rounded bg-slate-200" />
                    </td>

                    <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/4">
                        <div class="h-3 w-full animate-pulse rounded bg-slate-200" />
                    </td>
                </tr>
            </tbody>

            <tbody v-else class="divide-y divide-slate-200 text-sm">
                <tr v-for="permissionId in availablePermissions" :key="permissionId">
                    <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/3">
                        <div class="flex grow items-center">
                            <div class="flex items-center">
                                {{ getFromDictionary(`permissions.['${permissionId}']`, 'label') }}
                            </div>
                        </div>
                    </td>
                    <td class="px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/2">
                        <div class="text-left text-xs">
                            {{ getFromDictionary(`permissions.['${permissionId}']`, 'description') }}
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/4">
                        <div class="flex items-center justify-end">
                            <div class="my-auto flex items-center">
                                <div class="form-switch">
                                    <input
                                        :id="permissionId"
                                        v-model="permissions[permissionId]"
                                        type="checkbox"
                                        class="sr-only"
                                        @click="onChangePermission(permissionId)"
                                    />
                                    <label class="bg-slate-400" :for="permissionId">
                                        <span class="bg-white shadow-sm" aria-hidden="true"></span>
                                        <span class="sr-only">Toggle</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    name: 'PermissionsTable',
    props: {
        availablePermissions: {
            type: Array,
        },
        grantedPermissions: {
            type: Array,
        },
        isLoading: {
            type: Boolean,
        },
    },

    data() {
        return {
            permissions: {},
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary']),
    },

    watch: {
        grantedPermissions: {
            handler() {
                this.permissions = {}

                _.forEach(this.grantedPermissions, (permissionId) => {
                    this.permissions[permissionId] = true
                })
            },
        },
    },

    methods: {
        onChangePermission(permissionId) {
            let change = 'grant'
            if (this.grantedPermissions.includes(permissionId)) {
                change = 'deny'
            }
            this.$emit('change-permission', permissionId, change)
        },
    },

    emits: ['change-permission'],
}
</script>
