<template>
    <div ref="bannerWrapper" class="h-full w-full bg-cover bg-center" :style="[bgStyle]" aria-hidden="true">
        <!-- This img is not displayed; it is used to detect failure-to-load of div background image -->
        <img
            v-if="isImage && isIntersecting"
            class="vue-avatar--image"
            style="display: none"
            :src="currentSrc"
            @error="onImgError"
        />
    </div>
</template>

<script>
export default {
    name: 'CreatorBanner',
    props: {
        srcs: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            currentUrlIndex: 0,
            retryCount: 0,
            maxRetries: 1, // Allows one retry per URL
            imgError: false,
            lastErrorEvent: null,
            isIntersecting: false, // Controls if the image is in the viewport
        }
    },
    computed: {
        currentSrc() {
            return this.srcs[this.currentUrlIndex]
        },
        isImage() {
            return !this.imgError && Boolean(this.currentSrc)
        },
        bgStyle() {
            const style = {}

            if (this.isImage && this.isIntersecting) {
                style['background-image'] = `url(${this.currentSrc})`
            }

            return style
        },
    },
    watch: {
        srcs: {
            immediate: false,
            handler() {
                if (this.srcs.length > 0) {
                    this.loadImage()
                }
            },
        },
    },
    mounted() {
        this.createObserver()
    },
    beforeUnmount() {
        if (this.observer) {
            this.observer.disconnect()
        }
    },
    methods: {
        createObserver() {
            const options = {
                root: null, // relative to the viewport
                threshold: 0.1, // 10% of the item must be visible
            }

            this.observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.isIntersecting = true
                        this.loadImage()
                        observer.unobserve(this.$refs.bannerWrapper)
                    }
                })
            }, options)

            this.observer.observe(this.$refs.bannerWrapper)
        },

        onImgError(event) {
            if (event && event.target && event.target.status === 403 && this.retryCount < this.maxRetries) {
                this.retryCount += 1
                this.loadImage()
            } else {
                this.retryCount = 0 // Reset retry count for next URL
                if (this.currentUrlIndex < this.srcs.length - 1) {
                    this.currentUrlIndex += 1
                    this.loadImage()
                } else {
                    this.imgError = true // No more URLs to try
                }
            }
        },

        loadImage() {
            const img = new Image()
            img.onload = () => {
                this.imgError = false // Image loaded successfully
            }
            img.onerror = this.onImgError
            img.src = this.currentSrc
        },
    },
}
</script>
