<script setup>
import { cn } from '@/lib/utils'

const props = defineProps({
    class: { type: null, required: false },
})
</script>

<template>
    <th :class="cn('py-3 text-left font-semibold uppercase', props.class)">
        <slot />
    </th>
</template>
