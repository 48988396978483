<template>
    <Tooltip :hash="`${code?.codetype}-${code?.code}`" placement="top" max-width="400" class="cursor-pointer">
        <template #trigger>
            <div v-if="code" class="inline-block rounded bg-slate-100 px-2 py-1 text-xs font-semibold">
                <span v-if="code?.emoji" class="mr-1">
                    {{ code?.emoji }}
                </span>
                <span>
                    {{ code?.name }}
                </span>
            </div>
            <div v-else></div>
        </template>
        <template #default>
            <div v-if="code">
                <div class="text-md font-semibold leading-none text-white">{{ code.name }}</div>
                <div class="mt-0.5 text-xs font-semibold capitalize leading-none text-slate-300">
                    {{ code.codetype.replaceAll('-', ' ').replaceAll('_', ' ') }}
                </div>

                <table v-if="metrics?.length > 0" class="mt-2 table-auto text-left text-xs">
                    <thead>
                        <tr class="text-slate-200">
                            <th class="pr-4">Platform</th>
                            <th class="pr-4">Content Type</th>
                            <th class="pr-4">Creator Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="metric in metrics" :key="metric">
                            <td class="pr-4 capitalize">{{ metric[0] }}</td>
                            <td class="pr-4 capitalize">{{ metric[1] }}</td>
                            <td class="pr-4">{{ $numeral(metric[2]).format('0,0') }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </Tooltip>
</template>

<script>
import _ from 'lodash'
import Tooltip from '@/components/Tooltip.vue'

export default {
    name: 'CodePlate',

    components: {
        Tooltip,
    },

    props: {
        code: { type: Object },
    },

    computed: {
        metrics() {
            if (!this.code) return []

            const results = []

            _.forEach(this.code?.platforms, ({ metrics }, platformId) => {
                _.forEach(metrics, (metricItem, contentType) => {
                    if (metricItem.channels) results.push([platformId, contentType, metricItem.channels])
                })
            })

            return _.orderBy(results, [(item) => item[2]], ['desc'])
        },
    },
}
</script>
