import api from './api'

const apicampaignkeys = {
    async getSuperCampaignKeyGroups(axios, supercampaignId, includeKeys) {
        let url = `/keys/groups/supercampaigns/${supercampaignId}`

        if (includeKeys) {
            url = `${url}?includekeys=true`
        }

        return axios
            .get(url)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async addKeyGroup(axios, supercampaignId, title, description, type, creatorTitle, orgGameId) {
        return axios
            .post(`/keys/groups/supercampaigns/${supercampaignId}`, {
                organization_game_id: orgGameId,
                title,
                description,
                type,
                creator_title: creatorTitle,
            })
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async editKeyGroup(axios, supercampaignId, keyGroupId, title, description, type, creatorTitle) {
        return axios
            .put(`/keys/groups/supercampaigns/${supercampaignId}/${keyGroupId}`, {
                title,
                description,
                type,
                creator_title: creatorTitle,
            })
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async addGroupKeys(axios, supercampaignId, keyGroupId, keys, groupType, platform, marketplace, region, orgGameId) {
        return axios
            .put(`/keys/groups/supercampaigns/${supercampaignId}/${keyGroupId}/keys`, {
                keys,
                type: groupType,
                console_id: platform,
                marketplace_id: marketplace,
                region_id: region,
                organization_game_id: orgGameId,
            })
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async deleteKeyGroup(axios, supercampaignId, groupId) {
        return axios
            .delete(`/keys/groups/supercampaigns/${supercampaignId}/${groupId}`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async deleteGroupKeys(axios, supercampaignId, groupId, keys) {
        return axios
            .delete(`/keys/groups/supercampaigns/${supercampaignId}/${groupId}/keys`, {
                data: {
                    keys,
                },
            })
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async retrieveKeys(axios, supercampaignId, keyGroupId, keys, reason) {
        return axios
            .patch(`/keys/groups/supercampaigns/${supercampaignId}/${keyGroupId}/keys`, {
                keys,
                reason,
            })
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apicampaignkeys
