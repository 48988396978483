<template v-if="participant && campaign">
    <div class="grid gap-4">
        <!-- Task Input UI -->
        <div class="col-span-12 flex-col rounded border border-slate-200 bg-white px-4 py-6 lg:col-span-6">
            <!-- Campaign isn't configured (Roster & Manager) -->
            <div v-if="!isCampaignConfigured" class="space-y-4">
                <!-- Campaign manager, unable to submit bids -->
                <template v-if="mode === 'campaign'">
                    <p>Bids unavailable as campaign deliverables have not been defined.</p>
                </template>

                <!-- Roster, unable to submit bids -->
                <template v-if="mode === 'roster'">
                    <p>Hello,</p>
                    <p>
                        Thank you for your interest in participating in this campaign. We're currently in the process of
                        finalizing the task constraints and deliverables for the campaign to ensure clarity and success
                        for all parties involved.
                    </p>
                    <p>
                        Before you can submit a bid, these details need to be in place. We appreciate your patience and
                        understanding. Once everything is set up, you'll be promptly notified so you can proceed with
                        your bid submission.
                    </p>
                    <p>
                        We value your time and collaboration. If you have any immediate questions or concerns, please
                        don't hesitate to reach out to our support team.
                    </p>
                    <p>Thank you for being a valued member of our community!</p>
                </template>
            </div>

            <!-- Campaign is configured -->
            <div v-else>
                <!-- Bid declined (Roster & Campaign) -->
                <div v-if="lastBid?.status === 'declined'" class="mb-5 rounded-md bg-red-50 p-4">
                    <!-- Campaign -->
                    <div v-if="mode === 'campaign'" class="flex">
                        Bid has been declined, if this is in error, use the bids page to resolve.
                    </div>

                    <!-- Roster manager -->
                    <div v-if="mode === 'roster'" class="flex">
                        <div class="flex-shrink-0">
                            <font-awesome-icon
                                :icon="['fa', 'exclamation-circle']"
                                class="text-red-400"
                                aria-hidden="true"
                            />
                        </div>
                        <div class="ml-3">
                            <h3 class="text-sm font-medium text-red-800">Submission Not Accepted</h3>

                            <div class="mt-2 text-sm text-red-800">
                                <ul role="list" class="list-disc space-y-1 pl-5">
                                    <li>
                                        Your submitted rate does not align with the budget allocated for this campaign.
                                        We encourage you to review and adjust your rate, then resubmit a new bid that
                                        could better match the campaign’s budget.
                                    </li>
                                    <li>
                                        While this bid was not accepted, we highly value your interest and enthusiasm,
                                        and we look forward to receiving your updated submission or seeing your
                                        application for future opportunities.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Bid is sent and being considered (Roster) -->
                <div
                    v-if="
                        mode === 'roster' &&
                        stages?.pricing?.status === 'price-received' &&
                        ['sent', 'considered'].includes(lastBid?.status)
                    "
                    class="rounded-md bg-indigo-50 p-4"
                >
                    <div class="flex">
                        <font-awesome-icon
                            :icon="['fad', 'thumbs-up']"
                            class="h-6 w-6 text-indigo-600"
                            aria-hidden="true"
                        />
                        <div class="ml-3 text-sm text-indigo-900">
                            <h3 class="text-sm font-medium text-indigo-800">Thank you for submitting your rate!</h3>

                            <div>
                                <p class="mb-2">
                                    We greatly appreciate your interest in participating in our upcoming campaign.
                                </p>
                                <p class="mb-2">
                                    Please be advised that our team will review all submissions carefully to ensure the
                                    best fit for our campaign. We will be reaching out to selected content creators in
                                    due time before the campaign kick-off.
                                </p>
                                <p>
                                    Whether you're selected for this particular campaign or not, we're looking forward
                                    to potential collaborations in the future.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Bid has been accepted (Roster) -->
                <div
                    v-else-if="
                        mode === 'roster' &&
                        ['contracting', 'preparation', 'delivery'].includes(participant?.stage) &&
                        ['accepted'].includes(latestSentBid?.status)
                    "
                    class="rounded bg-green-50 px-4 py-6"
                >
                    <template v-if="!campaign?.stage_settings?.contracting?.is_disabled">
                        <div class="flex">
                            <font-awesome-icon
                                :icon="['fad', 'thumbs-up']"
                                class="h-6 w-6 text-green-600"
                                aria-hidden="true"
                            />
                            <div class="ml-3 text-sm text-green-900">
                                <h3 class="text-sm font-medium text-green-800">Congratulations!</h3>

                                <div>
                                    <p class="mb-2">
                                        You've been selected! The next step is to sign a contract to ink the deal.
                                    </p>
                                    <p class="mb-2">
                                        You should receive an email from Streamforge to electronically sign the contract
                                        in the coming weeks before the campaign starts.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-if="campaign?.stage_settings?.contracting?.is_disabled">
                        <h1 class="text-2xl font-bold text-slate-700">Congratulations!</h1>
                        <p class="mt-2">You've been selected! The next step is to retrieve your assets.</p>
                    </template>
                </div>

                <!-- Deliverable Header (Roster) -->
                <template v-if="mode === 'roster'">
                    <div class="flex flex-1 pt-2">
                        <div class="mx-auto inline-flex rounded-lg border border-slate-200 p-4">
                            <font-awesome-icon
                                class="flex h-6 w-6 shrink-0 fill-current"
                                :class="getFromDictionary(`task.${activeDeliverable.type}`, 'textColor')"
                                :icon="getFromDictionary(`task.${activeDeliverable.type}`, 'icon')"
                            />
                        </div>
                    </div>

                    <div class="mt-5 text-center">
                        <h3 class="text-2xl font-bold text-slate-700">
                            {{ getFromDictionary(`task.${activeDeliverable.type}`, 'title') }}
                        </h3>
                        <div class="text-sm">
                            {{ getFromDictionary(`task.${activeDeliverable.type}`, 'description') }}
                        </div>
                    </div>
                </template>

                <!-- Deliverable Header (Campaign) -->
                <template v-if="mode === 'campaign'">
                    <div class="flex justify-center">
                        <div class="h-14 w-14 rounded-lg border border-slate-200 p-4">
                            <font-awesome-icon
                                class="flex h-6 w-6 shrink-0 fill-current"
                                :class="getFromDictionary(`task.${activeDeliverable.type}`, 'textColor')"
                                :icon="getFromDictionary(`task.${activeDeliverable.type}`, 'icon')"
                            />
                        </div>
                        <div class="ml-8 mt-3 text-center">
                            <h3 class="text-2xl font-bold text-slate-700">
                                {{ getFromDictionary(`task.${activeDeliverable.type}`, 'title') }}
                            </h3>
                        </div>
                    </div>
                </template>

                <!-- Deliverable details -->
                <div class="mt-5">
                    <div class="mb-2 text-xs font-semibold uppercase text-slate-800">What's required</div>

                    <ul>
                        <li
                            v-for="({ state: _state, values }, key) in activeDeliverable.constraint.fields"
                            :key="key"
                            class="flex items-center py-1"
                        >
                            <svg class="mr-2 h-3 w-3 shrink-0 fill-current text-emerald-500" viewBox="0 0 12 12">
                                <path
                                    d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z"
                                ></path>
                            </svg>
                            <div v-if="key === 'started_at'" class="text-sm">
                                <span v-if="activeDeliverable.type === 'twitch-stream'">go live date</span>
                                <span v-if="['youtube-video', 'tiktok-video'].includes(activeDeliverable.type)"
                                    >video release date</span
                                >
                                <span v-if="['twitter-post', 'instagram-post'].includes(activeDeliverable.type)"
                                    >post date</span
                                >
                                <span v-if="_state.operator === 'eq'">
                                    of
                                    <span class="inline-flex rounded bg-slate-100 px-1.5 py-0.5 font-semibold">
                                        <font-awesome-icon
                                            :icon="['fas', 'clock']"
                                            class="my-auto mr-1.5 h-3.5 w-3.5"
                                        />
                                        {{ $moment(values.eq).format('MMM Do, YYYY h:mm A') }}
                                        {{ guessedTimezone }}
                                    </span>
                                </span>
                                <span v-if="_state.operator === 'btwn'">
                                    <span v-if="$moment(values.min).isSame($moment(values.max), 'day')">
                                        between
                                        <span class="inline-flex rounded bg-slate-100 px-1.5 py-0.5 font-semibold">
                                            <font-awesome-icon
                                                :icon="['fas', 'clock']"
                                                class="my-auto mr-1.5 h-3.5 w-3.5"
                                            />
                                            {{ $moment(values.min).format('h:mm A') }}
                                            {{ guessedTimezone }}
                                        </span>
                                        and
                                        <span class="inline-flex rounded bg-slate-100 px-1.5 py-0.5 font-semibold">
                                            <font-awesome-icon
                                                :icon="['fas', 'clock']"
                                                class="my-auto mr-1.5 h-3.5 w-3.5"
                                            />
                                            {{ $moment(values.max).format('h:mm A') }}
                                            {{ guessedTimezone }}
                                        </span>
                                        on
                                        <span class="inline-flex rounded bg-slate-100 px-1.5 py-0.5 font-semibold">
                                            <font-awesome-icon
                                                :icon="['fas', 'calendar']"
                                                class="my-auto mr-1.5 h-3.5 w-3.5"
                                            />
                                            {{ $moment(values.min).format('MMM Do, YYYY') }}
                                        </span>
                                    </span>
                                    <span v-else>
                                        between
                                        <span class="inline-flex rounded bg-slate-100 px-1.5 py-0.5 font-semibold">
                                            <font-awesome-icon
                                                :icon="['fas', 'calendar']"
                                                class="my-auto mr-1.5 h-3.5 w-3.5"
                                            />
                                            {{ $moment(values.min).format('MMM Do, YYYY h:mm A') }}
                                            {{ guessedTimezone }}
                                        </span>
                                        and
                                        <span class="inline-flex rounded bg-slate-100 px-1.5 py-0.5 font-semibold">
                                            <font-awesome-icon
                                                :icon="['fas', 'calendar']"
                                                class="my-auto mr-1.5 h-3.5 w-3.5"
                                            />
                                            {{ $moment(values.max).format('MMM Do, YYYY h:mm A') }}
                                            {{ guessedTimezone }}
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div v-if="key === 'duration_hours'" class="text-sm">
                                A continuous live stream
                                <span v-if="_state.operator === 'eq'">
                                    for
                                    <span class="font-semibold"
                                        >{{ values.eq }} hour{{ values.eq > 1 ? 's' : '' }}</span
                                    >
                                </span>
                                <span v-if="_state.operator === 'btwn'">
                                    between
                                    <span class="font-semibold">{{ values.min }} and {{ values.max }} hours</span>
                                </span>
                            </div>
                            <div v-if="key === 'video_count'" class="text-sm">
                                <span v-if="_state.operator === 'eq'">
                                    Post exactly
                                    <span class="font-semibold"
                                        >{{ values.eq }} videos{{ values.eq > 1 ? 's' : '' }}</span
                                    >
                                    for the sponsorship
                                </span>
                                <span v-if="_state.operator === 'btwn'">
                                    Provide the number of videos you'd like to make for this sponsorship between
                                    <span class="font-semibold">{{ values.min }} and {{ values.max }} videos</span>
                                </span>
                            </div>
                        </li>
                        <li class="flex items-center py-1">
                            <svg class="mr-2 h-3 w-3 shrink-0 fill-current text-emerald-500" viewBox="0 0 12 12">
                                <path
                                    d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z"
                                ></path>
                            </svg>
                            <div v-if="activeDeliverable.type === 'twitch-stream'" class="text-sm">
                                A "call to action" panel below your stream with a tracking link that viewers can click
                                to go to the game's Steam page
                            </div>
                            <div v-if="activeDeliverable.type === 'youtube-video'" class="text-sm">
                                Tracking link in video description
                            </div>
                            <div v-if="activeDeliverable.type === 'tiktok-video'" class="text-sm">
                                Tracking link as your profile link
                            </div>
                            <div v-if="activeDeliverable.type === 'twitter-post'" class="text-sm">
                                Tracking link in the post
                            </div>
                            <div v-if="activeDeliverable.type === 'instagram-post'" class="text-sm">
                                Tracking link in the post
                            </div>
                        </li>
                    </ul>
                </div>

                <!-- Deliverable inputs-->
                <div v-if="canSubmitBid" class="mt-10 grid gap-4">
                    <div
                        v-for="inputField in activeDeliverable.input_fields"
                        :key="inputField.key"
                        :class="inputField.classes.join(' ')"
                    >
                        <template v-if="inputField.key === 'started_at'">
                            <label class="mb-2 block text-sm font-medium"
                                >When you can
                                {{
                                    activeDeliverable.type === 'twitch-stream' ? 'start your stream' : 'post your video'
                                }}</label
                            >
                            <div v-if="canSubmitBid" class="relative">
                                <flat-pickr
                                    v-model="taskInput.fields[inputField.key]"
                                    readonly
                                    class="form-input w-full pl-9 font-medium text-slate-500 hover:text-slate-600 focus:border-slate-300"
                                    :config="inputField.configuration"
                                    :class="{
                                        '!border-rose-200 !bg-rose-100 placeholder-slate-600':
                                            !validation.byId[activeDeliverable.id].fields[inputField.key].is_valid,
                                    }"
                                />

                                <div class="pointer-events-none absolute inset-0 right-auto flex items-center">
                                    <svg class="ml-3 h-4 w-4 fill-current text-slate-500" viewBox="0 0 16 16">
                                        <path
                                            d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div v-else class="relative">
                                <input
                                    :value="$moment.utc(taskInput.fields[inputField.key]).format('MMM Do, YYYY h:mm A')"
                                    readonly
                                    disabled
                                    class="form-input w-full pl-9 font-medium text-slate-500 hover:border-slate-200"
                                />

                                <div class="pointer-events-none absolute inset-0 right-auto flex items-center">
                                    <svg class="ml-3 h-4 w-4 fill-current text-slate-500" viewBox="0 0 16 16">
                                        <path
                                            d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <template v-if="inputField.key === 'duration_hours'">
                            <label class="mb-2 block text-sm font-medium">How many hours</label>
                            <input
                                v-model="taskInput.fields[inputField.key]"
                                class="form-input w-full"
                                type="number"
                                :min="inputField.configuration.min"
                                :max="inputField.configuration.max"
                                :class="{
                                    '!border-rose-200 !bg-rose-100 placeholder-slate-600':
                                        !validation.byId[activeDeliverable.id].fields[inputField.key].is_valid,
                                }"
                            />
                        </template>
                        <template v-if="inputField.key === 'video_count'">
                            <label class="mb-2 block text-sm font-medium"
                                >How many videos you want to be sponsored for</label
                            >
                            <input
                                v-model="taskInput.fields[inputField.key]"
                                class="form-input w-full"
                                type="number"
                                :min="inputField.configuration.min"
                                :max="inputField.configuration.max"
                                :class="{
                                    '!border-rose-200 !bg-rose-100 placeholder-slate-600':
                                        !validation.byId[activeDeliverable.id].fields[inputField.key].is_valid,
                                }"
                            />
                        </template>
                    </div>
                </div>

                <!-- Declined bids (Roster)-->
                <div
                    v-if="mode === 'roster' && sortedBids.filter((bid) => bid.status === 'declined').length > 0"
                    class="pt-2"
                >
                    <div class="w-full max-w-md">
                        <div class="mx-auto w-full rounded-2xl bg-white py-2">
                            <Disclosure v-slot="{ open }">
                                <DisclosureButton
                                    :class="open ? 'rounded-t-lg' : 'rounded-lg'"
                                    class="flex w-full items-center justify-between bg-red-100 px-4 py-2 text-left text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75"
                                >
                                    <span
                                        >Declined Bids
                                        <span class="text-xs text-red-500"
                                            >({{ sortedBids.filter((bid) => bid.status === 'declined').length }})</span
                                        >
                                    </span>
                                    <font-awesome-icon
                                        :icon="['fas', 'chevron-up']"
                                        :class="open ? 'rotate-180 transform' : ''"
                                        class="h-3 w-3 text-red-500"
                                    />
                                </DisclosureButton>
                                <DisclosurePanel
                                    class="rounded-b-lg border-b border-l border-r border-red-200 px-4 pb-4 pt-4 text-sm text-gray-500"
                                >
                                    <div
                                        v-for="(pastBid, index) in pastBids"
                                        :key="pastBid.id"
                                        class="items-center gap-2 opacity-70"
                                    >
                                        <span class="text-sm">Bid {{ index + 1 }}</span>
                                        <div class="grid grid-cols-1 @sm:grid-cols-2 @sm:gap-2 @sm:divide-x">
                                            <div class="flex-col">
                                                <div class="pb-1 text-xs font-medium">
                                                    <span>Rate</span>
                                                </div>

                                                <div class="relative">
                                                    <CurrencyInput
                                                        :model-value="
                                                            (() => {
                                                                if (activeDeliverable.type === 'twitch-stream') {
                                                                    return (
                                                                        pastBid.items[0].payout_amount /
                                                                        Math.round(taskInput.fields.duration_hours)
                                                                    )
                                                                }

                                                                return pastBid.items[0].payout_amount
                                                            })()
                                                        "
                                                        :options="{
                                                            currency: 'USD',
                                                        }"
                                                        class="py-1 text-lg font-semibold"
                                                        disabled
                                                    />
                                                    <div
                                                        v-if="activeDeliverable.type === 'twitch-stream'"
                                                        class="pointer-events-none absolute inset-0 left-auto flex items-center"
                                                    >
                                                        <span class="px-4 text-sm font-medium">per hour</span>
                                                    </div>
                                                    <div
                                                        v-if="activeDeliverable.type === 'tiktok-video'"
                                                        class="pointer-events-none absolute inset-0 left-auto flex items-center"
                                                    >
                                                        <span class="px-4 text-sm font-medium">per video</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                v-if="activeDeliverable.type === 'twitch-stream'"
                                                class="pt-2 @sm:pl-2 @sm:pt-0"
                                            >
                                                <div class="pb-1 text-xs font-medium">Total payout</div>

                                                <div class="text-lg font-semibold">
                                                    <div class="rounded border p-1">
                                                        ${{ $numeral(pastBid.items[0].payout_amount, '0,0').format() }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DisclosurePanel>
                            </Disclosure>
                        </div>
                    </div>
                </div>

                <!-- Submitted bids (Roster, Campaign)-->
                <div v-if="latestSentBid && !editBid && canEditBid" class="pt-4">
                    <div class="pl-1 text-xs text-indigo-700">Submitted Bid</div>
                    <div class="rounded-lg border border-indigo-400 bg-indigo-50 p-4">
                        <div class="flex flex-col divide-indigo-200">
                            <div v-if="sentBidTask?.due_at" class="mb-2 flex font-medium text-indigo-900">
                                <div class="w-24 text-sm">Date</div>
                                <div class="ml-8">
                                    {{ $moment.utc(sentBidTask?.due_at).local().format('MMM Do, YYYY h:mm A') }}
                                    {{ guessedTimezone }}
                                </div>
                            </div>
                            <div class="mb-2 flex font-medium text-indigo-900">
                                <div class="w-24 text-sm">Rate</div>
                                <div class="ml-8 min-w-48 text-xl font-semibold text-slate-800">
                                    <div
                                        class="flex rounded border border-indigo-200 bg-indigo-100 px-2 py-1 text-indigo-900"
                                    >
                                        ${{
                                            $numeral(
                                                (() => {
                                                    if (activeDeliverable.type === 'twitch-stream') {
                                                        return (
                                                            latestSentBid.items[0].payout_amount /
                                                            Math.round(taskInput.fields.duration_hours)
                                                        )
                                                    }

                                                    return latestSentBid.items[0].payout_amount
                                                })(),
                                                '0,0',
                                            ).format()
                                        }}
                                        <div v-if="sentBidTask?.type === 'twitch-stream'" class="ml-auto">
                                            <span class="text-sm font-medium text-indigo-700">per hour</span>
                                        </div>
                                        <div v-if="sentBidTask?.type === 'tiktok-video'" class="ml-auto">
                                            <span class="text-sm font-medium text-indigo-700">per video</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2 flex font-medium text-indigo-900">
                                <div class="w-24 text-sm">Total payout</div>
                                <div class="ml-8 min-w-48 text-xl font-semibold text-slate-800">
                                    <div
                                        class="rounded border border-indigo-200 bg-indigo-100 px-2 py-1 text-indigo-900"
                                    >
                                        ${{ $numeral(latestSentBid.items[0].payout_amount, '0,0').format() }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            type="button"
                            class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            @click="editBid = true"
                        >
                            Edit Bid
                        </button>
                    </div>
                </div>

                <!-- Accepted bid, pricing completed (Roster, Campaign)-->
                <div v-if="latestSentBid && latestSentBid.status === 'accepted' && !canEditBid">
                    <div class="pl-1 text-xs text-green-700">Accepted Bid</div>
                    <div class="rounded-lg border border-green-400 bg-green-50 p-4">
                        <div class="grid grid-cols-1 divide-green-200 @sm:grid-cols-2 @sm:gap-2 @sm:divide-x">
                            <div class="flex-col">
                                <div class="mb-2 text-sm font-medium text-green-900">
                                    <span>Rate</span>
                                </div>

                                <div class="relative">
                                    <CurrencyInput
                                        :model-value="
                                            (() => {
                                                if (activeDeliverable.type === 'twitch-stream') {
                                                    return (
                                                        latestSentBid.items[0].payout_amount /
                                                        Math.round(taskInput.fields.duration_hours)
                                                    )
                                                }

                                                return latestSentBid.items[0].payout_amount
                                            })()
                                        "
                                        :options="{
                                            currency: 'USD',
                                        }"
                                        class="border-green-200 bg-green-100 py-4 text-xl font-semibold text-green-900 hover:border-green-200"
                                        disabled
                                    />
                                    <div
                                        v-if="activeDeliverable.type === 'twitch-stream'"
                                        class="pointer-events-none absolute inset-0 left-auto flex items-center"
                                    >
                                        <span class="px-4 text-sm font-medium text-green-700">per hour</span>
                                    </div>
                                    <div
                                        v-if="activeDeliverable.type === 'tiktok-video'"
                                        class="pointer-events-none absolute inset-0 left-auto flex items-center"
                                    >
                                        <span class="px-4 text-sm font-medium text-green-700">per video</span>
                                    </div>
                                </div>
                            </div>

                            <div v-if="activeDeliverable.type === 'twitch-stream'" class="pt-2 @sm:pl-2 @sm:pt-0">
                                <div class="mb-2 text-sm font-medium text-green-900">Total payout</div>

                                <div class="text-xl font-semibold text-slate-800">
                                    <div class="rounded border border-green-200 bg-green-100 px-4 py-4 text-green-900">
                                        ${{ $numeral(latestSentBid.items[0].payout_amount, '0,0').format() }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Bid currency input (Roster, Campaign)-->
                <div v-if="canSubmitBid || editBid" class="pt-2">
                    <div class="grid grid-cols-1 divide-slate-200 @sm:grid-cols-2 @sm:gap-2 @sm:divide-x">
                        <div class="flex-col">
                            <!-- (Roster)-->
                            <div v-if="mode === 'roster'" class="mb-2 text-sm font-medium">
                                <span>Give us your best rate</span>
                            </div>

                            <div class="relative">
                                <CurrencyInput
                                    v-model="taskInputsByTaskConstraintId[activeDeliverable.id].bid_amount"
                                    :options="{
                                        currency: 'USD',
                                    }"
                                    class="py-4 text-xl font-semibold"
                                    :class="{
                                        '!border-rose-200 !bg-rose-100 placeholder-slate-600':
                                            !validation.byId[activeDeliverable.id].bid.is_valid &&
                                            taskInputsByTaskConstraintId[activeDeliverable.id].bid_amount,
                                    }"
                                />
                                <div
                                    v-if="activeDeliverable.type === 'twitch-stream'"
                                    class="pointer-events-none absolute inset-0 left-auto flex items-center"
                                >
                                    <span class="px-4 text-sm font-medium">per hour</span>
                                </div>
                                <div
                                    v-if="activeDeliverable.type === 'tiktok-video'"
                                    class="pointer-events-none absolute inset-0 left-auto flex items-center"
                                >
                                    <span class="px-4 text-sm font-medium">per video</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="activeDeliverable.type === 'twitch-stream'" class="pt-2 @sm:pl-2 @sm:pt-0">
                            <div class="mb-2 text-sm font-medium">Total payout</div>

                            <div class="text-xl font-semibold text-slate-800">
                                <div class="rounded border border-slate-200 bg-slate-100 px-4 py-4">
                                    ${{
                                        $numeral(
                                            taskInput.bid_amount * Math.round(taskInput.fields.duration_hours),
                                            '0,0',
                                        ).format()
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="validation.byId[activeDeliverable.id].bid.invalidReason && taskInput.bid_amount"
                        class="pt-1 text-xs text-red-700"
                    >
                        {{ validation.byId[activeDeliverable.id].bid.invalidReason }}
                    </div>
                    <div class="p-1"></div>
                </div>

                <!-- Selection chance (Roster)-->
                <div v-if="trueprice && mode === 'roster'" class="mt-5 grid grid-cols-2 gap-y-1.5 text-sm">
                    <div class="flex font-medium text-slate-900">
                        <span>Selection Chance</span>

                        <Tooltip>
                            <template #trigger>
                                <font-awesome-icon
                                    :icon="['fas', 'circle-question']"
                                    class="ml-1 inline h-3.5 w-3.5 text-slate-400"
                                />
                            </template>
                            <template #default>
                                <div class="whitespace-wrap w-64 text-xs">
                                    Your chances of being selected for the campaign scale with the rate you provide.
                                </div>
                            </template>
                        </Tooltip>
                    </div>

                    <div class="text-right">
                        <span
                            v-if="
                                validation.byId[activeDeliverable.id].bid.quality < 0.1 &&
                                validation.byId[activeDeliverable.id].bid.quality > -0.1
                            "
                            class="text-indigo-500"
                            >Normal</span
                        >
                        <span
                            v-else-if="validation.byId[activeDeliverable.id].bid.quality < -0.5"
                            class="text-emerald-500"
                            >Very High</span
                        >
                        <span
                            v-else-if="validation.byId[activeDeliverable.id].bid.quality < -0.25"
                            class="text-emerald-500"
                            >High</span
                        >
                        <span
                            v-else-if="validation.byId[activeDeliverable.id].bid.quality < -0.1"
                            class="text-emerald-500"
                            >Above Average</span
                        >
                        <span v-else-if="validation.byId[activeDeliverable.id].bid.quality > 0.5" class="text-rose-500"
                            >Very Low</span
                        >
                        <span v-else-if="validation.byId[activeDeliverable.id].bid.quality > 0.25" class="text-rose-500"
                            >Low</span
                        >
                        <span v-else-if="validation.byId[activeDeliverable.id].bid.quality > 0.1" class="text-amber-500"
                            >Below Average</span
                        >
                    </div>
                </div>

                <div
                    v-if="submitRate.isError"
                    class="mt-5 rounded border border-rose-200 bg-rose-100 p-3 text-xs text-rose-600"
                >
                    <p>
                        It seems there was an error with our system when receiving your rate. We are very sorry for the
                        inconvenience, please notify us by replying to the message that brought you here or emailing
                        "hello@streamforge.com".
                    </p>
                </div>

                <div
                    v-if="!submitRate.isError && mode === 'roster'"
                    class="mt-5 rounded border border-slate-200 p-3 text-xs"
                >
                    <p>
                        As you submit your rate, please keep in mind that while it plays a significant role in the
                        process, it doesn't guarantee selection for the campaign due to there being limited spots. The
                        final decision is made by the sponsor.
                    </p>
                </div>

                <div v-if="!submitRate.isError && (editBid || canSubmitBid)" class="mt-5 flex">
                    <button
                        v-if="editBid"
                        class="btn mr-5 basis-1/3 border-slate-200 bg-white text-slate-700 hover:border-slate-300"
                        :disabled="submitRate.isLoading || isSubmitting"
                        @click="editBid = null"
                    >
                        Cancel
                    </button>
                    <button
                        class="btn w-full enabled:bg-indigo-500 enabled:text-white enabled:hover:bg-indigo-600 disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                        :disabled="
                            !validation.is_all_valid ||
                            submitRate.isLoading ||
                            isSubmitting ||
                            (!canSubmitBid && !editBid) ||
                            (latestSentBid?.items?.[0]?.payout_amount / taskInput.fields.duration_hours ===
                                taskInput.bid_amount &&
                                sentBidTask?.due_at === taskInput.fields.started_at)
                        "
                        @click="validation.is_all_valid ? submitRate() : null"
                    >
                        <template v-if="isSubmitting">
                            <span>Submitting...</span>
                        </template>
                        <template v-if="!isSubmitting">
                            <span v-if="mode === 'roster'">Submit Rate</span>
                            <span v-if="mode === 'campaign' && !editBid">Submit Bid</span>
                            <span v-if="mode === 'campaign' && editBid">Update Bid</span>
                        </template>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import 'moment-timezone'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import flatPickr from 'vue-flatpickr-component'
import { axios } from '@/utils/axios'
import CurrencyInput from '@/components/CurrencyInput.vue'
import Tooltip from '@/components/Tooltip.vue'
import campaignBidsAPI from '@/utils/api/api.campaign.bids'

export default {
    name: 'PricingPage',

    components: {
        flatPickr,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        Tooltip,
        CurrencyInput,
    },

    props: {
        campaign: { type: Object, required: true },
        participant: { type: Object, required: true },
        onRateSubmit: { type: Function, required: true },
        mode: { type: String, required: true },
    },

    data() {
        return {
            guessedTimezone: null,
            taskInputsByTaskConstraintId: null,
            editBid: false,
            biddableTypes: ['twitch-stream', 'youtube-video', 'tiktok-video', 'twitter-post', 'instagram-post'],
            flatpickrConfig: {
                enableTime: true,
                dateFormat: 'Z',
                altFormat: 'F j, Y h:i K',
                altInput: true,
                static: true,
            },
            isSubmitting: false,
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary']),

        isCampaignConfigured() {
            return this.activeDeliverable
        },

        taskConstraints() {
            return this.campaign.settings.task_constraints[this.participant.platform_id] || []
        },

        canEditBid() {
            return (
                this.participant &&
                this.participant.bids &&
                ((['sent', 'considered'].includes(this.lastBid?.status) &&
                    this.stages?.pricing?.status !== 'complete') ||
                    (['sent', 'considered', 'accepted'].includes(this.lastBid?.status) && this.mode === 'campaign')) &&
                this.participant.stage !== 'delivery'
            )
        },

        canSubmitBid() {
            return (
                !this.participant?.bids?.length &&
                (this.editBid || !this.latestSentBid || this.lastBid?.status === 'declined') &&
                this.participant.stageStatus !== 'removed' &&
                this.participant.stage !== 'delivery'
            )
        },

        stages() {
            if (this.participant?.stages) {
                return this.participant?.stages
            }
            return this.participant
        },

        validation() {
            let isAllDeliverablesValid = true

            const mappedDeliverables = _.map(this.deliverables, (deliverable) => {
                const result = {
                    id: deliverable.id,
                    bid: {},
                    fields: {},
                    is_all_valid: true,
                }

                let totalPayout

                if (deliverable.type === 'twitch-stream') {
                    totalPayout =
                        this.taskInputsByTaskConstraintId[deliverable.id].bid_amount *
                        Math.round(this.taskInputsByTaskConstraintId[deliverable.id].fields.duration_hours)
                }
                const bidAmount = this.taskInputsByTaskConstraintId[deliverable.id]?.bid_amount

                let bidMin = 1
                let bidMax = 1000000

                if (this.trueprice) {
                    bidMin = this.trueprice / 5
                    bidMax = this.trueprice * 10
                }
                const isBelowMin = bidAmount < bidMin
                const lastDeclinedBid = _.last(this.sortedBids.filter((bid) => bid.status === 'declined'))
                const lastDeclinedBidPayout = lastDeclinedBid?.items[0].payout_amount
                const lastDeclinedBidAmount =
                    deliverable.type === 'twitch-stream'
                        ? lastDeclinedBidPayout / deliverable.constraint.fields.duration_hours.values.eq
                        : lastDeclinedBidPayout
                const isAbovePreviouslyDeclinedBid = lastDeclinedBid
                    ? totalPayout >= lastDeclinedBid.items[0].payout_amount
                    : false

                const getInvalidReason = () => {
                    if (isBelowMin) {
                        return `The bid is below the minimum ($${bidMin.toLocaleString()})`
                    }
                    if (isAbovePreviouslyDeclinedBid) {
                        return `The bid is above or equal to the previously declined bid ($${lastDeclinedBidAmount.toLocaleString()})`
                    }
                    return ''
                }

                result.bid = {
                    min: bidMin,
                    max: bidMax,
                    is_valid: !isBelowMin && !isAbovePreviouslyDeclinedBid,
                    invalidReason: getInvalidReason(),
                }

                // Everything related to pricing
                result.bid.quality = bidAmount / this.trueprice - 1

                // If the bid is invalid, trigger global invalid to prevent form submission
                if (result.bid.is_valid === false) result.is_all_valid = false

                // Everything related to inputs
                _.forEach(deliverable.input_fields, (field) => {
                    const inputValue = this.taskInputsByTaskConstraintId[deliverable.id].fields[field.key]

                    result.fields[field.key] = {
                        is_valid: true,
                    }

                    if (field.configuration) {
                        if (field.configuration.type === 'integer') {
                            if (inputValue > field.configuration.max || inputValue < field.configuration.min) {
                                result.fields[field.key].is_valid = false
                            }
                        }

                        if (field.configuration.type === 'datetime') {
                            // Disabled for now
                            // result.fields[field.key].is_valid = moment(inputValue).isBetween(
                            //     field.configuration.minDate,
                            //     field.configuration.maxDate,
                            //     undefined,
                            //     '[]'
                            // )

                            result.fields[field.key].is_valid = true
                        }
                    }

                    // If any fields are invalid, trigger global invalid to prevent form submission
                    if (result.fields[field.key].is_valid === false) result.is_all_valid = false
                })

                if (result.is_all_valid === false) isAllDeliverablesValid = false

                return result
            })

            return {
                is_all_valid: isAllDeliverablesValid,
                byId: _.keyBy(mappedDeliverables, 'id'),
            }
        },

        taskInput() {
            return this.taskInputsByTaskConstraintId[this.activeDeliverable?.id]
        },

        trueprice() {
            return Math.round(
                this.participant?.superview?.trueprice?.hourly_rate ||
                    this.participant?.superview?.trueprice?.rate_per_video ||
                    this.participant?.superview?.trueprice ||
                    1,
            )
        },

        deliverables() {
            if (!this.participant) return null
            return _.reduce(
                this.taskConstraints,
                (results, constraint) => {
                    // Skip anything that is not a biddable task type
                    if (!this.biddableTypes.includes(constraint.type)) return results

                    const result = {
                        id: constraint.id,
                        type: constraint.type,
                        constraint,
                        input_fields: [],
                    }

                    if (constraint.type === 'twitch-stream') {
                        // Set all defaults to the exacts
                        _.forEach(constraint.fields, (constraintField, key) => {
                            if (constraintField.state.operator === 'btwn') {
                                const inputField = {
                                    key,
                                    classes: [],
                                    configuration: {},
                                }

                                // Handle numeric values
                                if (['duration_hours'].includes(key)) {
                                    inputField.classes.push('lg:col-span-4')

                                    inputField.configuration = {
                                        type: 'integer',
                                        min: constraintField.values.min,
                                        max: constraintField.values.max,
                                    }
                                }

                                // Handle datetime values
                                if (['started_at'].includes(key)) {
                                    inputField.classes.push('lg:col-span-8')

                                    const inputDurationHours = _.get(
                                        this.taskInputs[constraint.id],
                                        'fields.duration_hours',
                                    )
                                    const maxDate = moment(constraintField.values.max)

                                    if (inputDurationHours) {
                                        maxDate.subtract(inputDurationHours, 'hours')
                                    }

                                    _.assign(inputField.configuration, this.flatpickrConfig)
                                    _.assign(inputField.configuration, {
                                        type: 'datetime',
                                        minDate: moment(constraintField.values.min).toDate(),
                                        maxDate: maxDate.toDate(),
                                    })
                                }

                                result.input_fields.push(inputField)
                            }
                        })
                    }

                    if (constraint.type === 'youtube-video') {
                        // Set all defaults to the exacts
                        _.forEach(constraint.fields, (constraintField, key) => {
                            if (constraintField.state.operator === 'btwn') {
                                const inputField = {
                                    key,
                                    classes: [],
                                    configuration: {},
                                }

                                // Handle datetime values
                                if (['started_at'].includes(key)) {
                                    inputField.classes.push('lg:col-span-8')

                                    const inputDurationHours = _.get(
                                        this.taskInputs[constraint.id],
                                        'fields.duration_hours',
                                    )
                                    const maxDate = moment(constraintField.values.max)

                                    if (inputDurationHours) {
                                        maxDate.subtract(inputDurationHours, 'hours')
                                    }

                                    _.assign(inputField.configuration, this.flatpickrConfig)
                                    _.assign(inputField.configuration, {
                                        type: 'datetime',
                                        minDate: moment(constraintField.values.min).toDate(),
                                        maxDate: maxDate.toDate(),
                                    })
                                }

                                result.input_fields.push(inputField)
                            }
                        })
                    }

                    if (constraint.type === 'tiktok-video') {
                        // Set all defaults to the exacts
                        _.forEach(constraint.fields, (constraintField, key) => {
                            if (constraintField.state.operator === 'btwn') {
                                const inputField = {
                                    key,
                                    classes: [],
                                    configuration: {},
                                }

                                // Handle datetime values
                                if (['started_at'].includes(key)) {
                                    inputField.classes.push('lg:col-span-8')

                                    const inputDurationHours = _.get(
                                        this.taskInputs[constraint.id],
                                        'fields.duration_hours',
                                    )
                                    const maxDate = moment(constraintField.values.max)

                                    if (inputDurationHours) {
                                        maxDate.subtract(inputDurationHours, 'hours')
                                    }

                                    _.assign(inputField.configuration, this.flatpickrConfig)
                                    _.assign(inputField.configuration, {
                                        type: 'datetime',
                                        minDate: moment(constraintField.values.min).toDate(),
                                        maxDate: maxDate.toDate(),
                                    })
                                }

                                // Handle numeric values
                                if (['video_count'].includes(key)) {
                                    inputField.classes.push('lg:col-span-4')

                                    inputField.configuration = {
                                        type: 'integer',
                                        min: constraintField.values.min,
                                        max: constraintField.values.max,
                                    }
                                }

                                result.input_fields.push(inputField)
                            }
                        })
                    }

                    results.push(result)
                    return results
                },
                [],
            )
        },

        sortedBids() {
            if (this.participant.bids) {
                return _.orderBy(this.participant.bids, ['created_at'], ['asc'])
            }
            return []
        },

        pastBids() {
            return this.sortedBids.filter((bid) => bid.status === 'declined')
        },

        lastBid() {
            return _.last(this.sortedBids)
        },

        latestSentBid() {
            return _.last(this.sortedBids.filter((bid) => ['sent', 'considered', 'accepted'].includes(bid.status)))
        },

        sentBidTask() {
            const sentBid = this.latestSentBid
            if (sentBid?.items?.length > 0 && sentBid.items[0]?.tasks?.length > 0) {
                return sentBid.items[0].tasks[0]
            }
            return null
        },

        activeDeliverable() {
            return this.deliverables[0]
        },

        taskInputs() {
            const tInputs = {}
            _.forEach(this.taskConstraints, (constraint) => {
                if (
                    ['twitch-stream', 'youtube-video', 'tiktok-video', 'twitter-post', 'instagram-post'].includes(
                        constraint.type,
                    )
                ) {
                    tInputs[constraint.id] = {
                        bid_amount: this.trueprice || 1,
                        fields: {},
                    }

                    _.forEach(constraint.fields, (field, key) => {
                        if (field.state.operator === 'eq') {
                            tInputs[constraint.id].fields[key] = field.values.eq
                        } else if (field.state.operator === 'btwn') {
                            tInputs[constraint.id].fields[key] = field.values.min
                        }
                    })
                }
            })
            return tInputs
        },
    },

    watch: {
        latestSentBid() {
            this.taskInputsByTaskConstraintId = _.keyBy(this.getTaskInputsInitialValue(), 'id')
        },
    },

    created() {
        this.guessedTimezone = moment.tz(moment.tz.guess()).zoneAbbr()
        this.taskInputsByTaskConstraintId = _.keyBy(this.getTaskInputsInitialValue(), 'id')
    },

    methods: {
        async submitRate() {
            this.isSubmitting = true
            const body = {
                inquiry_id: null,
                items: _.map(this.taskConstraints, (constraint) => {
                    const deliverable = _.find(this.deliverables, ({ id }) => id === constraint.id)

                    let taskBody = {
                        type: constraint.type,
                    }

                    if (deliverable) {
                        const payload = _.cloneDeep(this.taskInputsByTaskConstraintId[deliverable.id].fields)
                        const durationHours = payload.duration_hours

                        if (durationHours) {
                            payload.duration_seconds = 60 * 60 * durationHours
                            delete payload.duration_hours
                        }

                        let dueAt = null

                        if (payload.started_at) {
                            dueAt = moment(payload.started_at).utc().format()
                            delete payload.started_at
                        }

                        let taskPayout = this.taskInputsByTaskConstraintId[deliverable.id].bid_amount

                        if (taskPayout && durationHours && this.participant.platform_id === 'twitch') {
                            taskPayout *= durationHours
                        }

                        taskBody = {
                            type: deliverable.type,
                            platform_id: this.participant.platform_id,
                            payload,
                            due_at: dueAt,
                            payout_amount: taskPayout,
                        }
                    }

                    return {
                        type: 'task',
                        payout_amount: _.get(taskBody, 'payout_amount'),
                        tasks: [taskBody],
                    }
                }),
            }

            const reponse = await campaignBidsAPI.sendBid(axios, this.participant.id, body)

            if (reponse.success) {
                // eslint-disable-next-line vue/no-mutating-props
                this.participant.bids = [reponse.value.bid]

                if (this.mode === 'campaign') {
                    if (!this.editBid) {
                        await this.acceptBid(reponse.value.bid)
                    }
                    await this.onRateSubmit(reponse.value.bid)
                } else {
                    await this.onRateSubmit(reponse.value.bid)
                }
            }

            this.editBid = false
            this.isSubmitting = false
        },

        async acceptBid(bid) {
            const reponse = await campaignBidsAPI.acceptBid(axios, this.participant.id, bid.id)
            if (reponse.success) {
                // eslint-disable-next-line vue/no-mutating-props
                this.participant.bids = [reponse.value.bid]
            }
        },

        getTaskInputsInitialValue() {
            return this.taskConstraints.map((constraint) => {
                let taskInput = {}
                let startAt = null

                if (
                    ['twitch-stream', 'youtube-video', 'tiktok-video', 'twitter-post', 'instagram-post'].includes(
                        constraint.type,
                    )
                ) {
                    let initialRate

                    if (this.sortedBids.length > 0) {
                        if (constraint.type === 'twitch-stream') {
                            initialRate =
                                this.lastBid.items[0].payout_amount / constraint.fields.duration_hours.values.eq
                        } else {
                            initialRate = this.lastBid.items[0].payout_amount
                        }
                        if (this.lastBid.status === 'declined') {
                            initialRate *= 0.9
                        }

                        if (constraint?.fields?.started_at && this.sentBidTask?.due_at) {
                            startAt = this.sentBidTask?.due_at
                        }
                    } else {
                        initialRate = this.trueprice
                    }
                    taskInput = {
                        id: constraint.id,
                        bid_amount: Math.round(initialRate),
                        fields: {},
                    }

                    for (const [key, value] of Object.entries(constraint.fields)) {
                        if (value.state.operator === 'eq') {
                            taskInput.fields[key] = value.values.eq
                        } else if (value.state.operator === 'btwn') {
                            taskInput.fields[key] = value.values.min
                        }
                    }

                    if (startAt) {
                        taskInput.fields.started_at = startAt
                    }
                }
                return taskInput
            })
        },
    },
}
</script>
