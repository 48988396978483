<template>
    <div class="my-2 space-y-10">
        <div v-for="(groupFields, groupKey) in visibleFieldsByGroup" :key="groupKey">
            <div class="mb-4 mt-2 rounded bg-slate-200 px-3 py-1.5">
                <span class="text-sm font-semibold text-slate-600">
                    {{ getFromDictionary(`discovery.groups.${groupKey}`, 'label') }}
                </span>
            </div>

            <div v-for="field in groupFields" :key="`${fields[field.key].type}-${field.key}`" :title="field.title">
                <div
                    v-if="fields[field.key].type !== 'checkbox'"
                    class="mb-2.5 text-left text-sm font-medium leading-5 text-slate-500"
                >
                    <div
                        v-if="
                            fields[field.key].type === 'select-code-multiple' &&
                            fields[field.key].model &&
                            fields[field.key].model.length > 0
                        "
                        class="flex justify-between"
                    >
                        <div class="my-auto flex leading-none">
                            <div class="flex">
                                <Tooltip v-if="fields[field.key].tooltip" class="my-auto mr-2 box-content">
                                    <template #trigger>
                                        <font-awesome-icon
                                            class="h-3.5 w-3.5 text-slate-400 transition duration-200 hover:text-slate-500"
                                            :icon="['fas', 'circle-info']"
                                        />
                                    </template>
                                    <template #default>
                                        <div class="font-bold">
                                            <h5 class="text-lg">{{ fields[field.key].tooltip.title }}</h5>
                                            <div class="space-y-2">
                                                <p
                                                    v-for="(paragraph, index) in fields[field.key].tooltip.content"
                                                    :key="`paragraph-${index}`"
                                                    class="text-xs text-slate-300"
                                                    :class="{
                                                        [paragraph.class]: !!paragraph.class,
                                                    }"
                                                >
                                                    {{ paragraph.text }}
                                                </p>
                                            </div>
                                        </div>
                                    </template>
                                </Tooltip>
                                <div class="capitalize">{{ fields[field.key].label }}</div>
                            </div>

                            <span class="ml-1">({{ fields[field.key].model.length }})</span>
                        </div>

                        <div
                            v-if="fields[field.key].conjunction"
                            class="flex rounded bg-white px-0.5 py-0.5 text-xs font-semibold text-slate-600"
                        >
                            <div
                                v-for="conjunctionOption in fields[field.key].conjunction.options"
                                :key="conjunctionOption"
                                class="cursor-pointer rounded px-2 uppercase"
                                :class="{
                                    'bg-slate-200':
                                        conjunctionOption ===
                                        $lodash.get(filtering.current, fields[field.key].conjunction.key),
                                }"
                                @click="setSingleValue(fields[field.key].conjunction.key, conjunctionOption)"
                            >
                                {{ getFromDictionary(`codes.conjunction.${conjunctionOption}`, 'label') }}
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="flex">
                            <Tooltip v-if="fields[field.key].tooltip" class="my-auto mr-2 box-content">
                                <template #trigger>
                                    <font-awesome-icon
                                        class="h-3.5 w-3.5 text-slate-400 transition duration-200 hover:text-slate-500"
                                        :icon="['fas', 'circle-info']"
                                    />
                                </template>
                                <template #default>
                                    <div class="font-bold">
                                        <h5 class="text-lg">{{ fields[field.key].tooltip.title }}</h5>
                                        <div class="space-y-2">
                                            <p
                                                v-for="(paragraph, index) in fields[field.key].tooltip.content"
                                                :key="`paragraph-${index}`"
                                                class="text-xs text-slate-300"
                                                :class="{
                                                    [paragraph.class]: !!paragraph.class,
                                                }"
                                            >
                                                {{ paragraph.text }}
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </Tooltip>

                            <span class="capitalize">{{ fields[field.key].label }}</span>
                        </div>
                    </div>
                </div>

                <div :class="{ 'pointer-events-none opacity-30': !field.is_enabled }">
                    <div v-if="fields[field.key].type === 'slider-range'" class="mb-12 ml-2 mr-2">
                        <vue-slider
                            v-model="fields[field.key].model"
                            :data="fields[field.key].data"
                            :marks="fields[field.key].marks"
                            :enable-cross="false"
                            :lazy="true"
                            :tooltip="compareDragValues(fields[field.key].key) ? 'none' : 'always'"
                            tooltip-placement="bottom"
                            :tooltip-formatter="
                                (value) =>
                                    fields[field.key].tooltipFormatter(value, {
                                        max: filtering.options[field.key].max,
                                        displayMax: fields[field.key].maxDisplayValue,
                                    })
                            "
                            :clickable="false"
                            :interval="1"
                            :min-range="1"
                            :fixed="false"
                            :contained="false"
                            @change="changeListeners[field.key]"
                            @dragging="(values) => (fields[field.key].dragValues = [...values])"
                        >
                            <template #process="{ style }">
                                <div class="vue-slider-process" :style="style">
                                    <div
                                        v-if="compareDragValues(field.key)"
                                        :class="[
                                            'merge-tooltip-bottom',
                                            'vue-slider-dot-tooltip-inner',
                                            'vue-slider-dot-tooltip-inner-bottom',
                                        ]"
                                    >
                                        <span>
                                            {{
                                                formatters.withMax(fields[field.key].dragValues[0], {
                                                    max: filtering.options[field.key].max,
                                                    displayMax: fields[field.key].maxDisplayValue,
                                                })
                                            }}
                                        </span>
                                        <span> - </span>
                                        <span>
                                            {{
                                                formatters.withMax(fields[field.key].dragValues[1], {
                                                    max: filtering.options[field.key].max,
                                                    displayMax: fields[field.key].maxDisplayValue,
                                                })
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </template>
                        </vue-slider>
                    </div>

                    <div v-else-if="fields[field.key].type === 'slider-single'" class="mb-12">
                        <vue-slider
                            v-model="fields[field.key].model"
                            :min="$lodash.get(filtering.options, `${field.key}.min`)"
                            :max="
                                fields[field.key]?.slider?.max
                                    ? fields[field.key].slider.max
                                    : $lodash.get(filtering.options, `${field.key}.max`)
                            "
                            :enable-cross="false"
                            :tooltip="'always'"
                            :tooltip-placement="'bottom'"
                            :tooltip-formatter="formatters[fields[field.key].numberType]"
                            :interval="fields[field.key].numberType === 'percent' ? 0.01 : 1"
                            :contained="false"
                            :clickable="false"
                            @change="changeListeners[field.key]"
                        />
                    </div>

                    <div v-else-if="fields[field.key].type === 'select-code-multiple'" class="mb-8">
                        <CodeSelect
                            :id-field="fields[field.key].idField"
                            :input-type="fields[field.key].type"
                            :selected-options="fields[field.key].model"
                            :title="fields[field.key].label"
                            :code-type="fields[field.key].codeType"
                            :platform="fields[field.key].platformId || platformId"
                            :preload="true"
                            :max-items="5000"
                            :search-placeholder="fields[field.key].placeholder"
                            :no-results-text="fields[field.key].noResultsText"
                            :slider-options="fields[field.key].sliderOptions"
                            :display-options="
                                fields[field.key].displayOptions ? fields[field.key].displayOptions() : null
                            "
                            :current-filters-context="filtering.current"
                            @selection-change="changeListeners[field.key]"
                        />
                    </div>

                    <div v-else-if="fields[field.key].type === 'checkbox'" class="mb-2">
                        <div class="flex items-center">
                            <input
                                v-model="fields[field.key].model"
                                type="checkbox"
                                @change="changeListeners[field.key]"
                            />
                            <label class="ml-2 text-sm text-slate-600">{{ fields[field.key].label }}</label>
                        </div>
                    </div>

                    <div v-else-if="fields[field.key].type === 'radio-list'" class="mb-8 flex flex-col">
                        <label
                            v-for="option in fields[field.key].options"
                            :key="option.id"
                            class="mr-3 text-sm text-slate-600"
                        >
                            <input
                                v-model="fields[field.key].model"
                                type="radio"
                                :name="field.key"
                                :value="option.id"
                                :checked="option.id === fields[field.key].model"
                                @change="changeListeners[field.key]"
                            />
                            <span class="ml-1.5">{{ option.title }}</span>
                        </label>
                    </div>

                    <div v-else-if="fields[field.key].type === 'checkbox-affinity'" class="mb-8 flex space-x-2">
                        <label v-for="option in fields[field.key].options" :key="option.id" class="text-sm">
                            <div
                                class="cursor-pointer rounded p-2 px-2 py-0.5 uppercase"
                                :class="[
                                    `${option.affinity.bgSecondaryColor} ${option.affinity.textColor}`,
                                    {
                                        checked: option.id == fields[field.key].model,
                                    },
                                ]"
                                @click="setSingleValue(field.key, option.value)"
                            >
                                {{ option.affinity.letter }}+
                            </div>
                        </label>
                    </div>

                    <div v-else-if="fields[field.key].type === 'slider-affinity'" class="mb-12">
                        <vue-slider
                            v-model="$lodash.get(fields, field.key).model"
                            :data="$lodash.get(fields, field.key).data"
                            :marks="true"
                            :enable-cross="false"
                            :lazy="true"
                            :tooltip="'none'"
                            :absorb="true"
                            :clickable="false"
                            :interval="0.1"
                            :min-range="0.1"
                            :fixed="false"
                            :contained="false"
                            @change="changeListeners[field.key]"
                        >
                            <template #mark="{ pos, label, active }">
                                <div
                                    class="absolute top-4 -translate-x-3 whitespace-nowrap text-xs"
                                    :style="{
                                        left: `${pos}%`,
                                    }"
                                >
                                    <div
                                        class="cursor-pointer rounded p-2 px-2 py-0.5 uppercase"
                                        :class="[
                                            getGradeAndColorsFromPercent(label).textColor,
                                            getGradeAndColorsFromPercent(label).bgPrimaryColor,
                                            {
                                                'opacity-25': !active,
                                            },
                                        ]"
                                    >
                                        {{ getGradeAndColorsFromPercent(label).letter }}
                                    </div>
                                </div>
                            </template>
                        </vue-slider>
                    </div>

                    <div v-else-if="fields[field.key].type === 'dropdown-code-single'" class="mb-8 flex space-x-2">
                        <CodeSelect
                            class="w-full"
                            :id-field="fields[field.key].idField"
                            :input-type="fields[field.key].type"
                            :selected-options="fields[field.key].model"
                            :title="fields[field.key].label"
                            :code-type="fields[field.key].codeType"
                            :platform="fields[field.key].platformId || platformId"
                            :preload="true"
                            :max-items="5000"
                            :search-placeholder="fields[field.key].placeholder"
                            :no-results-text="fields[field.key].noResultsText"
                            :slider-options="fields[field.key].sliderOptions"
                            :display-options="
                                fields[field.key].displayOptions ? fields[field.key].displayOptions() : null
                            "
                            :current-filters-context="filtering.current"
                            @selection-change="changeListeners[field.key]"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import _ from 'lodash'
import VueSlider from 'vue-slider-component'
import CodeSelect from '@/components/CodeSelect.vue'
import { getGradeAndColorsFromPercent, affinityGrades } from '@/utils/affinity'
import Tooltip from '@/components/Tooltip.vue'

const formatters = {
    withMax: (value, { max, displayMax }) => {
        if (value >= max) return `${numeral(displayMax).format('0,0')}+`
        return numeral(value).format('0,0')
    },
    default: (value) => numeral(value).format('0,0'),
    thousandsWithMax: (value, { max, displayMax }) => {
        if (value >= max) return `${numeral(displayMax).format('0a')}+`
        return numeral(value).format('0a')
    },
    percent: (value, { max }) => {
        if (value >= max && value !== 1) return `${numeral(value).format('0%')}+`
        return numeral(value).format('0%')
    },
    percentFromInteger: (value) => numeral(value / 100).format('0%'),
    integer: (value) => numeral(value).format('0,0'),
}

export default {
    name: 'TargetingFilters',

    components: {
        CodeSelect,
        VueSlider,
        Tooltip,
    },

    props: {
        platformId: {
            type: String,
        },

        filtering: {
            type: Object,
        },
    },

    emits: ['filters-change', 'filter-change-single', 'filter-change-multiple'],

    data() {
        return {
            formatters,

            getGradeAndColorsFromPercent,

            fields: {
                industry: {
                    idField: 'code',
                    label: 'Industry',
                    type: 'dropdown-code-single',
                    key: 'industry',
                    codeType: 'industry',
                    tooltip: {
                        title: 'Industry',
                        content: [
                            {
                                text: `Select the industry to confine your creator discovery to.`,
                            },
                        ],
                    },
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: 'channels',
                        sort_by: `platforms.${this.platformId}.metrics.${this.filtering.current.content_type}.channels`,
                    }),
                    model: null,
                    group: 'content',
                },
                industry_category: {
                    idField: 'code',
                    label: 'Industry Category',
                    type: 'dropdown-code-single',
                    key: 'industry_category',
                    codeType: 'industry_category',
                    tooltip: {
                        title: 'Industry Category',
                        content: [
                            {
                                text: `Select the industry category to confine your creator discovery to.`,
                            },
                        ],
                    },
                    conditions: () => this.filtering.current.industry !== null,
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: 'channels',
                        sort_by: `platforms.${this.platformId}.metrics.${this.filtering.current.content_type}.channels`,
                    }),
                    model: null,
                    group: 'content',
                },
                day_range: {
                    label: 'Aggregation Window',
                    type: 'radio-list',
                    key: 'day_range',
                    model: null,
                    options: [
                        { id: 30, title: '30 day view' },
                        { id: 90, title: '90 day view' },
                        { id: 365, title: '365 day view' },
                    ],
                    tooltip: {
                        title: 'Day Range',
                        content: [
                            {
                                text: `Adjust the time window for aggregating statistics. This setting determines which creators are highlighted based on their recent engagement with specific games or hashtags within the selected timeframe.`,
                            },
                        ],
                    },
                    group: 'content',
                },
                content_type: {
                    label: 'Content Type',
                    type: 'radio-list',
                    key: 'content_type',
                    model: null,
                    options: [
                        { id: 'video', title: 'Video' },
                        { id: 'short', title: 'Short' },
                        { id: 'stream', title: 'Stream' },
                    ],
                    tooltip: {
                        title: 'Content Type',
                        content: [
                            {
                                text: `Differentiate between the different types of content that can be posted to the platform.`,
                            },
                        ],
                    },
                    group: 'content',
                },
                'content_count.min': {
                    label: 'Minimum Content',
                    type: 'slider-single',
                    key: 'content_count.min',
                    dragValues: null,
                    model: null,
                    slider: {
                        max: 30,
                    },
                    numberType: 'integer',
                    tooltipFormatter: formatters.withMax,
                    tooltip: {
                        title: 'Minimum Content',
                        content: [
                            {
                                text: `This is the minimum pieces of content (videos, posts, streams) produced by the creator within the time window.`,
                            },
                        ],
                    },
                    group: 'content',
                },
                'games.values': {
                    label: 'Games Played',
                    type: 'select-code-multiple',
                    key: 'games.values',
                    model: null,
                    codeType: 'game',
                    placeholder: 'Search for a game',
                    multiplePlaceholder: 'Type to add more games',
                    noResultsText: 'All matches selected',
                    conjunction: {
                        key: 'games.conjunction',
                        options: ['$and', '$or'],
                    },

                    // Only show this field if at least 1 game has been selected
                    conditions: () => ['twitch', 'youtube'].includes(this.platformId),
                    tooltip: {
                        title: 'Games Played',
                        content: [
                            {
                                text: `Filter creators to ones that have played the games you have specified in this list. If you select "ALL", then creators in the result list have made content about every game you specified. If you select "ANY", the creator will have made content about at least 1 game you have specified.`,
                            },
                        ],
                    },
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: 'channels',
                    }),
                    group: 'games',
                },
                'affinity.game': {
                    label: 'Affinity To Games',
                    type: 'slider-affinity',
                    key: 'affinity.game',
                    model: null,

                    points: _.map(affinityGrades, (grade) => ({
                        value: grade.min,
                        label: grade.label,
                    })),

                    dragValues: null,
                    maxDisplayValue: 1,
                    tooltipFormatter: formatters.percent,

                    // Only show this field if at least 1 game has been selected
                    conditions: () => {
                        const gamesSelected = _.get(this.fields['games.values'], 'model')
                        const gamesConjunction = _.get(this.filtering.current, 'games.conjunction')

                        if (_.size(gamesSelected) > 0 && (gamesConjunction === '$and' || _.size(gamesSelected) === 1))
                            return true
                        return false
                    },

                    tooltip: {
                        title: 'Affinity to games in list',
                        content: [
                            {
                                text: 'If the game search is set to "all", this will filter the creators returned so their "affinity" is between the range you specified.',
                            },
                            {
                                text: `Affinity is Streamforge's algorithm that determines how good a video game matches to a content creator, based on a multi-factor analysis of how much the content creator liked the games, and how much their audience liked the game when it was showcased on their channel.`,
                                class: `italic`,
                            },
                        ],
                    },
                    group: 'games',
                },
                // 'affinity.game': {
                //     label: 'Min Affinity',
                //     type: 'slider-single',
                //     key: 'affinity.game',
                //     model: null,
                //     numberType: 'percent',

                //     // Only show this field if at least 1 game has been selected
                //     conditions: () => {
                //         const gamesSelected = _.get(this.fields['games.values'], 'model')
                //         if (_.size(gamesSelected) > 0) return true
                //         return false
                //     },
                // },
                'matched_games.min': {
                    label: 'Min Games Played From Filters',
                    type: 'slider-single',
                    key: 'matched_games.min',
                    model: null,
                    numberType: 'integer',
                    tooltip: {
                        title: 'Minimum games played from provided game list',
                        content: [
                            {
                                text: 'Sets the minimum number of provided games that must be matched. Only available when the "Any" option is selected under games.',
                            },
                        ],
                    },

                    slider: {
                        max: 10,
                    },

                    conditions: () => {
                        const gamesSelected = _.get(this.fields['games.values'], 'model')
                        const gamesConjunction = _.get(
                            this.filtering.current,
                            this.fields['games.values'].conjunction.key,
                        )
                        if (_.size(gamesSelected) > 1 && gamesConjunction === '$or') return true
                        return false
                    },
                    group: 'games',
                },
                'not_games.values': {
                    label: 'Games Not Played',
                    type: 'select-code-multiple',
                    key: 'not_games.values',
                    model: null,
                    codeType: 'game',
                    placeholder: 'Search for a game',
                    multiplePlaceholder: 'Type to add more games',
                    noResultsText: 'All matches selected',
                    conjunction: {
                        key: 'not_games.conjunction',
                        options: ['$nin', '$notall'],
                    },
                    tooltip: {
                        title: 'Has not played games from list',
                        content: [
                            {
                                text: 'This will remove creators from the result that have played games that you have specified in this list.',
                            },
                        ],
                    },
                    conditions: () => this.platformId !== 'tiktok',
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: 'channels',
                    }),
                    group: 'games',
                },
                'games.min': {
                    label: 'Min Total Games Played',
                    type: 'slider-single',
                    key: 'games.min',
                    model: null,
                    numberType: 'integer',

                    slider: {
                        max: 15,
                    },

                    tooltip: {
                        title: 'Minimum Total Games Played',
                        content: [
                            {
                                text: `This is the total number of games that the creator has showcased on their channel in the selected time period.`,
                            },
                        ],
                    },
                    group: 'games',
                },
                // 'categories_affinity.values': {
                //     label: 'Genre Affinity',
                //     type: 'select-code-multiple',
                //     key: 'categories_affinity.values',
                //     model: null,
                //     codeType: 'category',
                //     placeholder: 'Search for a category',
                //     multiplePlaceholder: 'Type to add more categories',
                //     noResultsText: 'All matches selected',
                //     sectionHeader: 'Genre',
                //     isSectionStart: true,
                //     tooltip: {
                //         title: 'Content categories',
                //         content: [
                //             {
                //                 text: `This allows you to search for creators based on the affinity of their content in categories.`,
                //             },
                //         ],
                //     },
                //     sliderOptions: {
                //         label: '',
                //         type: 'slider-affinity',
                //         data: _.map(affinityGrades, (grade) => ({
                //             value: grade.min,
                //             label: grade.label,
                //         })),
                //         marks: (val) => ({
                //             label: formatters.percentFromInteger(val),
                //         }),
                //         tooltipFormatter: (val) => {
                //             return getGradeAndColorsFromPercent(val).letter
                //         },
                //         defaultValue: 1,
                //     },
                //     conjunction: {
                //         key: 'categories_affinity.conjunction',
                //         options: ['$and', '$or'],
                //     },
                //     displayOptions: () => ({
                //         platform_id: this.platformId,
                //         content_type: this.filtering.current.content_type,
                //         metrics_type: 'channels',
                //         sort_by:
                //             this.platformId === 'youtube'
                //                 ? `platforms.${this.platformId}.metrics.${this.filtering.current.content_type}.channels`
                //                 : `platforms.${this.platformId}.metrics.channels`,
                //     }),
                // },

                'interests.values': {
                    label: 'Interests',
                    type: 'select-code-multiple',
                    key: 'interests.values',
                    model: null,
                    codeType: 'interest',
                    placeholder: 'Search for an interest',
                    multiplePlaceholder: 'Type to add more interests',
                    noResultsText: 'All matches selected',
                    conjunction: {
                        key: 'interests.conjunction',
                        options: ['$and', '$or'],
                    },
                    tooltip: {
                        title: 'Interests',
                        content: [
                            {
                                text: `These are topics that an AI determined the creator is interested in after reviewing the creators content across all platforms. A creator's interest on YouTube will be the same as their Twitter as all content is taken into account for the analysis. This filter is particularly useful for discovering things about a creator that may not be obvious at first glance.`,
                            },
                        ],
                    },
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: 'channels',
                        sort_by: `platforms.${this.platformId}.metrics.${this.filtering.current.content_type}.channels`,
                    }),
                    group: 'channel',
                },
                'categories.values': {
                    label: 'Steam Game Tags',
                    type: 'select-code-multiple',
                    key: 'categories.values',
                    model: null,
                    codeType: 'category',
                    placeholder: 'Search for a category',
                    multiplePlaceholder: 'Type to add more categories',
                    noResultsText: 'All matches selected',
                    sectionHeader: 'Genre',
                    tooltip: {
                        title: 'Genre',
                        content: [
                            {
                                text: `Search for creators based on what percentage of their content falls within games of each genre.`,
                            },
                        ],
                    },
                    sliderOptions: {
                        label: '',
                        type: 'slider-range',
                        defaultValue: 30,
                        data: [5, 10, 30, 50, 70, 90],
                        marks: (val) => ({
                            label: formatters.percentFromInteger(val),
                        }),
                        maxDisplayValue: 100,
                        tooltipFormatter: formatters.percentFromInteger,
                    },
                    conjunction: {
                        key: 'categories.conjunction',
                        options: ['$and', '$or'],
                    },
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: 'channels',
                        sort_by: `platforms.${this.platformId}.metrics.${this.filtering.current.content_type}.channels`,
                    }),
                    group: 'games',
                },
                'not_categories.values': {
                    label: 'Excluded Steam Game Tags',
                    type: 'select-code-multiple',
                    key: 'not_categories.values',
                    model: null,
                    codeType: 'category',
                    placeholder: 'Search for a category',
                    multiplePlaceholder: 'Type to add more categories',
                    noResultsText: 'All matches selected',
                    tooltip: {
                        title: 'Content categories',
                        content: [
                            {
                                text: `This allows you to exclude creators based on the genre of games they make content for. For example, choosing 30% will exclude any creator whose content is 30% or more in the genre`,
                            },
                        ],
                    },
                    sliderOptions: {
                        label: '',
                        type: 'slider-range',
                        defaultValue: 30,
                        data: [5, 10, 30, 50, 70, 90],
                        marks: (val) => ({
                            label: formatters.percentFromInteger(val),
                        }),
                        maxDisplayValue: 100,
                        tooltipFormatter: formatters.percentFromInteger,
                    },
                    conjunction: {
                        key: 'not_categories.conjunction',
                        options: ['$nin', '$notall'],
                    },
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: 'channels',
                        sort_by: `platforms.${this.platformId}.metrics.${this.filtering.current.content_type}.channels`,
                    }),
                    group: 'games',
                },
                'tags.values': {
                    label: `Content Tags`,
                    type: 'select-code-multiple',
                    key: 'tags.values',
                    model: null,
                    codeType: 'tag',
                    placeholder: 'Search for a tag',
                    multiplePlaceholder: 'Type to add more tags',
                    noResultsText: 'All matches selected',
                    tooltip: {
                        title: 'Content Tags',
                        content: [
                            {
                                text: `This allows you to search for creators based on the tags they use on their content. The available tags will vary from platform to platform.`,
                            },
                        ],
                    },
                    conjunction: {
                        key: 'tags.conjunction',
                        options: ['$and', '$or'],
                    },
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: 'channels',
                        sort_by: `platforms.${this.platformId}.metrics.${this.filtering.current.content_type}.channels`,
                    }),
                    group: 'channel',
                },
                avg_viewers: {
                    label: 'AVG Viewers',
                    type: 'slider-range',
                    key: 'avg_viewers',
                    dragValues: null,
                    model: null,
                    points: [
                        {
                            value: 0,
                            step: 1,
                        },
                        {
                            value: 5,
                            step: 5,
                        },
                        {
                            value: 200,
                            step: 25,
                        },
                        {
                            value: 1000,
                            step: 100,
                        },
                        {
                            value: 5000,
                            step: 500,
                        },
                    ],
                    maxDisplayValue: 5_000,
                    tooltipFormatter: formatters.withMax,

                    tooltip: {
                        title: 'Average Concurrent Viewers (ACV)',
                        content: [
                            {
                                text: `This is on average how many viewers are actively watching a live stream at once. How many people are watching a live stream is checked on a regular interval and averaged together afterwards. We then aggregate this concurrent viewership data together to arrive at a total average for the creator.`,
                            },
                            {
                                text: `Also known as ACV, this metric is the primary KPI for influencer marketing campaigns on live streaming platforms.`,
                            },
                        ],
                    },
                    group: 'channel',
                },
                avg_views: {
                    label: 'AVG Views',
                    type: 'slider-range',
                    key: 'avg_views',
                    dragValues: null,
                    model: null,
                    points: [
                        {
                            value: 0,
                            step: 1000,
                        },
                        {
                            value: 25000,
                            step: 5000,
                        },
                        {
                            value: 250000,
                            step: 25000,
                        },
                        {
                            value: 1000000,
                            step: 100000,
                        },
                    ],
                    maxDisplayValue: 1_000_000,
                    tooltipFormatter: formatters.thousandsWithMax,

                    tooltip: {
                        title: 'Average Views per Video (AVV)',
                        content: [
                            {
                                text: `This is on average how many views a video released by this creator gets after 28 days. We aggregate together the views each video gets that the creator posted recently to arrive at a total average for the creator.`,
                            },
                            {
                                text: `Also known as AVV, this metric is the primary KPI for influencer marketing campaigns on video on demand platforms.`,
                            },
                        ],
                    },
                    group: 'channel',
                },
                avg_likes: {
                    label: 'AVG Likes',
                    type: 'slider-range',
                    key: 'avg_likes',
                    dragValues: null,
                    model: null,
                    points: [
                        {
                            value: 0,
                            step: 5,
                        },
                        {
                            value: 250,
                            step: 50,
                        },
                        {
                            value: 500,
                            step: 100,
                        },
                        {
                            value: 5000,
                            step: 500,
                        },
                    ],
                    maxDisplayValue: 1_000_000,
                    tooltipFormatter: formatters.thousandsWithMax,
                    tooltip: {
                        title: 'Average Likes per Post (ALPP)',
                        content: [
                            {
                                text: `This is on average how many likes a post by this creator gets. We aggregate together the likes each video gets that the creator posted recently to arrive at a total average for the creator.`,
                            },
                            {
                                text: `Also known as ALPP, this metric is the primary KPI for influencer marketing campaigns on post based platforms.`,
                            },
                        ],
                    },
                    group: 'channel',
                },
                engagement_rate: {
                    label: 'Engagement Rate',
                    type: 'slider-range',
                    key: 'engagement_rate',
                    dragValues: null,
                    model: null,
                    points: [
                        {
                            value: 0,
                            step: 0.01,
                        },
                        {
                            value: 0.1,
                            step: 0.01,
                        },
                        {
                            value: 0.25,
                            step: 1,
                        },
                    ],
                    maxDisplayValue: 0.25,
                    tooltipFormatter: formatters.percent,

                    tooltip: {
                        title: 'Engagement Rate',
                        content: [
                            {
                                text: `This is on average how many viewers are actively talking a live stream at once.`,
                            },
                        ],
                    },
                    group: 'channel',
                },
                followers: {
                    label: 'Followers',
                    type: 'slider-range',
                    key: 'followers',
                    dragValues: null,
                    model: null,
                    points: [
                        {
                            value: 0,
                            step: 100,
                        },
                        {
                            value: 1000,
                            step: 1000,
                        },
                        {
                            value: 25000,
                            step: 5000,
                        },
                        {
                            value: 250000,
                            step: 25000,
                        },
                        {
                            value: 1_000_000,
                            step: 100_000,
                        },
                        {
                            value: 10_000_000,
                            step: 1_000_000,
                        },
                    ],
                    maxDisplayValue: 10_000_000,
                    tooltipFormatter: formatters.thousandsWithMax,
                    tooltip: {
                        title: 'Total Follower Count',
                        content: [
                            {
                                text: `This is the total number of followers that the creator has accumulated since they started their channel. While it indicates the maturity of a channel, it is not used a primary KPI for influencer marketing campaigns.`,
                            },
                        ],
                    },
                    group: 'channel',
                },
                subscribers: {
                    label: 'Subscribers',
                    type: 'slider-range',
                    key: 'subscribers',
                    dragValues: null,
                    model: null,
                    points: [
                        {
                            value: 1000,
                            step: 1000,
                        },
                        {
                            value: 25000,
                            step: 5000,
                        },
                        {
                            value: 250000,
                            step: 25000,
                        },
                        {
                            value: 1_000_000,
                            step: 100_000,
                        },
                        {
                            value: 10_000_000,
                            step: 1_000_000,
                        },
                    ],
                    maxDisplayValue: 10_000_000,
                    tooltipFormatter: formatters.thousandsWithMax,
                    tooltip: {
                        title: 'Total Subscriber Count',
                        content: [
                            {
                                text: `This is the total number of subscribers that the creator has accumulated since they started their channel. While it indicates the maturity of a channel, it is not used a primary KPI for influencer marketing campaigns.`,
                            },
                        ],
                    },
                    group: 'channel',
                },

                languages: {
                    label: 'Language',
                    type: 'select-code-multiple',
                    key: 'languages',
                    model: null,
                    codeType: 'language',
                    placeholder: 'Search for a language',
                    multiplePlaceholder: 'Type to add more languages',
                    noResultsText: 'All matches selected',
                    tooltip: {
                        title: 'Content language',
                        content: [
                            {
                                text: `This is the language setting the creator has chosen on the platform. In certain cases where a creator makes multi-lingual content, it will be set to the language that they make content in the most.`,
                            },
                        ],
                    },
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: 'channels',
                        sort_by:
                            this.platformId === 'youtube'
                                ? `platforms.${this.platformId}.metrics.${this.filtering.current.content_type}.channels`
                                : `platforms.${this.platformId}.metrics.channels`,
                    }),
                    group: 'channel',
                },
                countries: {
                    label: 'Country',
                    type: 'select-code-multiple',
                    key: 'countries',
                    model: null,
                    codeType: 'country',
                    placeholder: 'Search for a country',
                    multiplePlaceholder: 'Type to add more countries',
                    noResultsText: 'All matches selected',
                    tooltip: {
                        title: `Creator's Country of Residence`,
                        content: [
                            {
                                text: `This is the country that the creator currently lives in. On many platforms it is provided as a data point, but on Twitch it has to be inferred by using algorithms to analyze their content.`,
                            },
                        ],
                    },
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: 'channels',
                        sort_by:
                            this.platformId === 'youtube'
                                ? `platforms.${this.platformId}.metrics.${this.filtering.current.content_type}.channels`
                                : `platforms.${this.platformId}.metrics.channels`,
                    }),
                    group: 'channel',
                },

                genders: {
                    label: 'Gender',
                    type: 'select-code-multiple',
                    key: 'genders',
                    model: null,
                    codeType: 'gender',
                    placeholder: 'Search for a gender',
                    multiplePlaceholder: 'Type to add more genders',
                    noResultsText: 'All matches selected',
                    tooltip: {
                        title: 'Gender',
                        content: [
                            {
                                text: `The creator's gender is inferred by an AI.`,
                            },
                        ],
                    },
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: 'channels',
                        sort_by: `platforms.${this.platformId}.metrics.${this.filtering.current.content_type}.channels`,
                    }),
                    group: 'channel',
                },

                contentRatings: {
                    label: 'Content Rating',
                    type: 'select-code-multiple',
                    key: 'contentRatings',
                    model: null,
                    codeType: 'content_rating',
                    placeholder: 'Search for a content rating',
                    multiplePlaceholder: 'Type to add more content ratings',
                    noResultsText: 'All matches selected',
                    tooltip: {
                        title: 'Content Rating',
                        content: [
                            {
                                text: `The creator's content rating is inferred by an AI.`,
                            },
                        ],
                    },
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: 'channels',
                        sort_by: `platforms.${this.platformId}.metrics.${this.filtering.current.content_type}.channels`,
                    }),
                    group: 'channel',
                },
                ageRanges: {
                    label: 'Age Range',
                    type: 'select-code-multiple',
                    key: 'ageRanges',
                    model: null,
                    codeType: 'age-range',
                    placeholder: 'Search for an age range',
                    multiplePlaceholder: 'Type to add more age ranges',
                    noResultsText: 'All matches selected',
                    tooltip: {
                        title: 'Age Range',
                        content: [
                            {
                                text: `The creator's age range is inferred by an AI.`,
                            },
                        ],
                    },
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: `channels`,
                        sort_by: `name`,
                        sort_dir: 'asc',
                    }),
                    group: 'channel',
                },
                'metadata.type': {
                    label: 'Channel Type',
                    type: 'select-code-multiple',
                    key: 'metadata.type',
                    model: null,
                    codeType: 'channelType',
                    placeholder: 'Search for a channel type',
                    multiplePlaceholder: 'Type to add more channel types',
                    noResultsText: 'All matches selected',
                    tooltip: {
                        title: 'Channel Type',
                        content: [
                            {
                                text: `This is used to differentiate organizations from individuals.`,
                            },
                        ],
                    },
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: 'channels',
                        sort_by: `platforms.${this.platformId}.metrics.${this.filtering.current.content_type}.channels`,
                    }),
                    group: 'channel',
                },
                'metadata.email': {
                    label: 'Has email address',
                    type: 'checkbox',
                    key: 'metadata.email',
                    sub_key: 'email',
                    model: null,
                    group: 'channel',
                },
                // 'metadata.location': {
                //     label: 'Has location',
                //     type: 'checkbox',
                //     key: 'metadata.location',
                //     sub_key: 'location',
                //     model: null,
                // },
                'demographics.language.values': {
                    label: 'Language',
                    type: 'select-code-multiple',
                    key: 'demographics.language.values',
                    model: null,
                    codeType: 'language',
                    platformId: 'web',
                    placeholder: 'Search for language',
                    multiplePlaceholder: 'Type to add more language',
                    noResultsText: 'All matches selected',
                    sliderOptions: {
                        label: '',
                        type: 'slider-range',
                        defaultValue: 30,
                        data: [5, 10, 30, 50, 75, 90],
                        marks: (val) => ({
                            label: formatters.percentFromInteger(val),
                        }),
                        maxDisplayValue: 100,
                        tooltipFormatter: formatters.percentFromInteger,
                    },
                    conjunction: {
                        key: 'demographics.language.conjunction',
                        options: ['$and', '$or'],
                    },
                    isSectionStart: true,
                    sectionHeader: 'Audience',
                    tooltip: {
                        title: 'Audience Language',
                        content: [
                            {
                                text: `This is used to filter creators based upon the language(s) of its viewers.`,
                            },
                        ],
                    },
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: 'audience_channels',
                        sort_by: `platforms.${this.platformId}.metrics.audience_channels`,
                    }),
                    group: 'audience-demographics',
                },
                'demographics.country.values': {
                    label: 'Country',
                    type: 'select-code-multiple',
                    key: 'demographics.country.values',
                    model: null,
                    codeType: 'country',
                    platformId: 'web',
                    placeholder: 'Search for country',
                    multiplePlaceholder: 'Type to add more country',
                    noResultsText: 'All matches selected',
                    sliderOptions: {
                        label: '',
                        type: 'slider-range',
                        defaultValue: 30,
                        data: [5, 10, 30, 50, 75, 90],
                        marks: (val) => ({
                            label: formatters.percentFromInteger(val),
                        }),
                        maxDisplayValue: 100,
                        tooltipFormatter: formatters.percentFromInteger,
                    },
                    conjunction: {
                        key: 'demographics.country.conjunction',
                        options: ['$and', '$or'],
                    },
                    tooltip: {
                        title: 'Audience Country',
                        content: [
                            {
                                text: `This is used to filter creators based upon the countries of its viewers.`,
                            },
                        ],
                    },
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: 'audience_channels',
                        sort_by: `platforms.${this.platformId}.metrics.audience_channels`,
                    }),
                    group: 'audience-demographics',
                },
                'demographics.os.values': {
                    label: 'Operating System',
                    type: 'select-code-multiple',
                    key: 'demographics.os.values',
                    model: null,
                    codeType: 'operating-system',
                    platformId: 'web',
                    placeholder: 'Search for an operating system',
                    multiplePlaceholder: 'Type to add more systems',
                    noResultsText: 'All matches selected',
                    sliderOptions: {
                        label: '',
                        type: 'slider-range',
                        defaultValue: 30,
                        data: [5, 10, 30, 50, 75, 90],
                        marks: (val) => ({
                            label: formatters.percentFromInteger(val),
                        }),
                        maxDisplayValue: 100,
                        tooltipFormatter: formatters.percentFromInteger,
                    },
                    conjunction: {
                        key: 'demographics.os.conjunction',
                        options: ['$and', '$or'],
                    },
                    tooltip: {
                        title: 'Audience Operating System',
                        content: [
                            {
                                text: `This is used to filter creators based upon the operating system(s) used by its viewers.`,
                            },
                        ],
                    },
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: 'audience_channels',
                        sort_by: `platforms.${this.platformId}.metrics.audience_channels`,
                    }),
                    group: 'audience-demographics',
                },
                'demographics.device.values': {
                    label: 'Device',
                    type: 'select-code-multiple',
                    key: 'demographics.device.values',
                    model: null,
                    codeType: 'device-type',
                    platformId: 'web',
                    placeholder: 'Search for device',
                    multiplePlaceholder: 'Type to add more devices',
                    noResultsText: 'All matches selected',
                    sliderOptions: {
                        label: '',
                        type: 'slider-range',
                        defaultValue: 30,
                        data: [5, 10, 30, 50, 75, 90],
                        marks: (val) => ({
                            label: formatters.percentFromInteger(val),
                        }),
                        maxDisplayValue: 100,
                        tooltipFormatter: formatters.percentFromInteger,
                    },
                    conjunction: {
                        key: 'demographics.device.conjunction',
                        options: ['$and', '$or'],
                    },
                    tooltip: {
                        title: 'Audience Device',
                        content: [
                            {
                                text: `This is used to filter creators based upon the device(s) used by its viewers.`,
                            },
                        ],
                    },
                    displayOptions: () => ({
                        platform_id: this.platformId,
                        content_type: this.filtering.current.content_type,
                        metrics_type: 'audience_channels',
                        sort_by: `platforms.${this.platformId}.metrics.audience_channels`,
                    }),
                    group: 'audience-demographics',
                },
            },

            // Populated on component creation
            changeListeners: {},

            state: {
                groups: {
                    is_open: {
                        content: true,
                        channel: true,
                        games: true,
                        'audience-demographics': true,
                    },
                },
            },
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary']),

        // We only want to display fields that are enabled for the platform
        visibleFieldsByGroup() {
            const fieldGroups = _.reduce(
                this.fields,
                (results, field) => {
                    // Check if the fields custom visibility conditions have been met
                    const isConditionsMet = field.conditions ? field.conditions() : true
                    const isPlatformFieldEnabled = _.has(this.filtering.options, field.key)
                    const title = isConditionsMet ? field.title : field.hiddenTitle

                    if (isPlatformFieldEnabled) {
                        const item = {
                            key: field.key,
                            type: field.type,
                            label: field.label,
                            is_enabled: isConditionsMet,
                            title,
                        }

                        if (results[field.group]) {
                            results[field.group].push(item)
                        } else {
                            results[field.group] = [item]
                        }
                    }

                    return results
                },
                {},
            )

            // console.log(`Group conditions`)
            // console.log('platform', `"${this.platformId}"`, !['twitch', 'youtube'].includes(this.platformId))
            // console.log('industry', this.filtering.current.industry, this.filtering.current.industry !== 'gaming')

            // if (
            //     !['twitch', 'youtube'].includes(this.platformId) ||
            //     !['66c574e00d9e695dd239b466', null].includes(this.filtering.current.industry)
            // ) {
            //     fieldGroups = _.omit(fieldGroups, ['games'])
            // }

            return fieldGroups
        },
    },

    watch: {
        platformId(newValue, oldValue) {
            if (newValue === oldValue) return
            this.initializeRangeSliders()
            this.initializeChangeListeners()
            this.setAvailableMetricsWindows()
        },

        filtering: {
            handler() {
                this.setFilterLabels()
                // this.fields['content_count.min'].label = this.currentContentType()
                // console.log(`Filtering changed`, _.cloneDeep({ newValue, oldValue }))

                _.forEach(this.fields, (field) => {
                    const isPlatformFieldEnabled = _.has(this.filtering.options, field.key)

                    // Only update values for sliders that are shown
                    /* eslint-disable no-param-reassign */
                    if (isPlatformFieldEnabled) {
                        if (['slider-range', 'slider-affinity'].includes(field.type)) {
                            field.model = [
                                _.get(this.filtering.current, field.key).min,
                                _.get(this.filtering.current, field.key).max,
                            ]
                        }

                        if (field.type === 'slider-single') {
                            field.model = _.get(this.filtering.current, field.key)
                        }

                        if (field.type === 'select-code-multiple') {
                            field.model = _.get(this.filtering.current, field.key)
                        }

                        if (field.type === 'dropdown-code-single') {
                            field.model = _.get(this.filtering.current, field.key)
                        }

                        if (field.type === 'checkbox') {
                            field.model = _.get(this.filtering.current, field.key)
                        }

                        if (field.type === 'radio-list') {
                            field.model = _.get(this.filtering.current, field.key)
                        }

                        if (field.type === 'checkbox-affinity') {
                            field.model = _.get(this.filtering.current, field.key)
                        }
                    }
                    /* eslint-enable no-param-reassign */
                })
            },
            deep: true,
        },
    },

    created() {
        this.initializeRangeSliders()
        this.initializeChangeListeners()
        this.setFilterLabels()
        this.setAvailableMetricsWindows()
    },

    methods: {
        setSingleValue(key, value) {
            _.set(this.filtering.current, key, value)

            this.$emit('filter-change-single', {
                key,
                value,
            })
        },

        initializeChangeListeners() {
            // For each field, create a unique debouncer. We have to do it this way
            // because of the way debouncers have to be created
            _.forEach(this.fields, (field, key) => {
                this.changeListeners[key] = _.debounce((newValue) => {
                    const emittedEvent = {
                        key,
                        value: null,
                    }

                    if (['slider-range'].includes(field.type)) {
                        emittedEvent.value = {
                            min: newValue[0],
                            max: newValue[1],
                        }
                    }

                    if (['slider-affinity'].includes(field.type)) {
                        if (newValue[0] === newValue[1]) {
                            if (newValue[0] === 0) {
                                emittedEvent.value = {
                                    min: Math.round(newValue[0] * 100) / 100,
                                    max: Math.round((newValue[1] + 0.6) * 100) / 100,
                                }
                                field.model = [emittedEvent.value.min, emittedEvent.value.max]
                                // console.log(`Increasing upper bounds`, emittedEvent.value)
                            } else {
                                emittedEvent.value = {
                                    min: Math.round((newValue[0] - 0.1) * 100) / 100,
                                    max: Math.round(newValue[1] * 100) / 100,
                                }
                                // console.log(`Lowering lower bounds`, emittedEvent.value)
                            }
                        } else {
                            emittedEvent.value = {
                                min: Math.round(newValue[0] * 100) / 100,
                                max: Math.round(newValue[1] * 100) / 100,
                            }
                            // console.log(`No bounds collision`, emittedEvent.value)
                        }
                    }

                    if (field.type === 'select-code-multiple') {
                        emittedEvent.value = newValue
                    }

                    if (field.type === 'slider-single') {
                        if (field.sub_key) {
                            emittedEvent.key = `${field.key}.${field.sub_key}`
                        }

                        emittedEvent.value = newValue
                    }

                    // We are binding to the DOM @change, so just use the model value as it's updated anyways
                    if (field.type === 'checkbox') {
                        emittedEvent.value = this.fields[field.key].model
                    }

                    if (field.type === 'radio-list') {
                        emittedEvent.value = this.fields[field.key].model
                    }

                    if (field.type === 'checkbox-affinity') {
                        emittedEvent.value = this.fields[field.key].model
                    }

                    if (field.type === 'dropdown-code-single') {
                        emittedEvent.value = newValue
                    }

                    // We rely on the prop to bring this data all the way back down instead of setting it here
                    // const fieldToUpdateLocally = _.get(this.fields, emittedEvent.key)
                    // _.set(fieldToUpdateLocally, `model`, emittedEvent.value)
                    this.$emit('filter-change-single', emittedEvent)
                }, 250)

                // I added this afterwards, so the filters serialization where you can reload the page works
                if (!['slider-range', 'slider-affinity'].includes(field.type)) {
                    // eslint-disable-next-line no-param-reassign
                    field.model = _.get(this.filtering.current, field.key)
                }
            })
        },

        initializeRangeSliders() {
            _.forEach(this.fields, (field, key) => {
                const isPlatformFieldEnabled = _.has(this.filtering.options, field.key)

                // Field keys can be provided like.this which means we need the lodash _.get helper
                const currentFieldValue = _.get(this.filtering.current, key)
                const currentFieldOptions = _.get(this.filtering.options, key)

                if (['slider-range', 'slider-affinity'].includes(field.type) && isPlatformFieldEnabled) {
                    const minValue = currentFieldValue.min
                    const maxValue = currentFieldValue.max
                    this.fields[key].model = [minValue, maxValue]
                    this.fields[key].dragValues = [minValue, maxValue]

                    const { points } = _.get(this.fields, key)
                    const result = []

                    if (points && points.length) {
                        points.forEach((point, idx) => {
                            const lastPointValue = points[points.length - 1].value

                            if (point.value !== lastPointValue) {
                                const nextPoint = points[idx + 1]

                                for (let i = point.value; i <= nextPoint.value; i += point.step) {
                                    result.push(i)
                                }
                            }
                        })
                    }

                    const uniqueValues = new Set(result)

                    if (!uniqueValues.has(currentFieldOptions.max)) {
                        uniqueValues.add(currentFieldOptions.max)
                    }

                    const markValues = _.map(points, 'value')

                    this.fields[key].data = [...uniqueValues]

                    if (this.fields[key].marks) {
                        this.fields[key].marks = (value) => {
                            if (markValues.includes(value)) {
                                return {
                                    label: this.$filters.formatWholeNumberWithUnit(value),
                                    // labelActiveStyle: {
                                    //     color: '#3498db',
                                    // },
                                }
                            }

                            return false
                        }
                    }
                }
            })
        },

        compareDragValues(key) {
            const { dragValues } = this.fields[key]

            if (dragValues && dragValues.length === 2) return false

            if (key === 'avg_views') {
                // Cover start of slider
                if (dragValues[0] + dragValues[1] <= this.filtering.options[key].max * 0.001) return true

                // Cover end of slider
                if (dragValues[1] >= this.filtering.options[key].max) return dragValues[0] / dragValues[1] >= 0.05
            }

            if (key === 'subscribers') {
                // Cover start of slider
                if (dragValues[0] + dragValues[1] <= this.filtering.options[key].max * 0.001) return true

                // Cover end of slider
                if (dragValues[1] >= this.filtering.options[key].max) return dragValues[0] / dragValues[1] >= 0.05
            }

            if (key === 'avg_viewers') {
                if (dragValues[0] + dragValues[1] <= this.filtering.options[key].max * 0.0001) return true
                if (dragValues[1] >= this.filtering.options[key].max) return dragValues[0] / dragValues[1] >= 0.003
            }

            return dragValues[0] / dragValues[1] >= 0.2
        },
        setFilterLabels() {
            let name = 'Content'
            switch (this.platformId) {
                case 'twitch':
                    name = 'Streams'
                    break
                case 'youtube':
                    switch (this.filtering.current.content_type) {
                        case 'video':
                            name = 'Videos'
                            break
                        case 'short':
                            name = 'Shorts'
                            break
                        case 'stream':
                            name = 'Streams'
                            break
                        default:
                    }
                    break

                case 'tiktok':
                    name = 'Videos'
                    break
                case 'twitter':
                    name = 'Posts'
                    break
                case 'instagram':
                    name = 'Posts'
                    break
                default:
            }
            this.fields['content_count.min'].label = `Minimum ${name}`
        },
        setAvailableMetricsWindows() {
            if (['twitch', 'youtube'].includes(this.platformId)) {
                _.remove(this.fields.day_range.options, { id: 1095 })
                this.fields.day_range.options.push({ id: 1095, title: '3 year view' })
            } else {
                _.remove(this.fields.day_range.options, { id: 1095 })
            }
        },
    },
}
</script>

<style lang="scss">
.merge-tooltip-bottom {
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 15px);
    z-index: 50;
}

/* This is so that merge tooltips show above marks */
.vue-slider-marks {
    .vue-slider-mark {
        z-index: -1;
    }
}
</style>
