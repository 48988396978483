<template>
    <main class="bg-white">
        <div class="relative flex">
            <!-- Content -->
            <div class="w-full md:w-1/2">
                <div class="flex h-full min-h-screen flex-col after:flex-1">
                    <div class="flex-1">
                        <div class="flex h-16 items-center justify-between px-4 sm:px-6 lg:px-8">
                            <!-- Logo -->
                            <router-link :to="{ path: '/' }">
                                <Logo variant="dark" class="w-44" :has-text="true" />
                            </router-link>
                        </div>
                    </div>

                    <div class="mx-auto max-w-sm px-4 py-8">
                        <h1 class="mb-6 text-3xl font-bold text-slate-800">Forgot your password?</h1>

                        <!-- Form -->
                        <form v-if="!submitted">
                            <div class="space-y-4">
                                <div>
                                    <label class="mb-1 block text-sm font-medium" for="email"
                                        >Email Address <span class="text-rose-500">*</span></label
                                    >
                                    <input id="email" v-model="email" class="form-input w-full" type="email" />
                                </div>
                            </div>
                            <div class="mt-6 flex justify-end">
                                <button
                                    :disabled="email ? false : true"
                                    class="btn whitespace-nowrap bg-indigo-500 text-white disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 hover:bg-indigo-600"
                                    @click.prevent="send"
                                >
                                    Send Reset Link
                                </button>
                            </div>
                        </form>

                        <div v-else>
                            <p>
                                We have sent password reset instructions to that email if there is an associated
                                account. Click
                                <router-link class="font-semibold text-indigo-500" :to="{ name: 'login' }"
                                    >here</router-link
                                >
                                to return to the login page.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Image -->
            <div class="absolute bottom-0 right-0 top-0 hidden md:block md:w-1/2" aria-hidden="true">
                <img
                    class="h-full w-full object-cover object-center"
                    src="@/images/auth-image.png"
                    width="760"
                    height="1024"
                    alt="Authentication"
                />
                <img
                    class="absolute left-0 top-1/4 ml-8 hidden -translate-x-1/2 transform lg:block"
                    src="@/images/auth-decoration.png"
                    width="218"
                    height="224"
                    alt="Authentication decoration"
                />
            </div>
        </div>
    </main>
</template>

<script>
import Logo from '@/components/Logo.vue'

export default {
    name: 'ForgotPassword',

    components: {
        Logo,
    },

    data() {
        return {
            email: '',
            submitted: false,
        }
    },

    methods: {
        send() {
            if (!this.email) return

            this.$axios
                .post(`/operators/password/forgot`, {
                    email: this.email,
                })
                .then(() => {
                    this.submitted = true
                })
        },
    },
}
</script>
