<template>
    <div class="grid grid-cols-12 gap-6"></div>
</template>

<script>
export default {
    name: 'OrganizationFeedSubpage',

    components: {},

    data() {
        return {}
    },

    computed: {},

    created() {},

    methods: {},
}
</script>
