/* eslint-disable */
const toQueryString = (ob) => {
    const toReturn = {}

    for (const i in ob) {
        if (!ob.hasOwnProperty(i)) continue

        if (typeof ob[i] === 'object' && ob[i] !== null) {
            const flatObject = toQueryString(ob[i])

            for (const x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue
                if (!x) continue

                toReturn[encodeURIComponent(`${i}.${x}`)] = flatObject[x]
            }
        } else {
            toReturn[encodeURIComponent(i)] = ob[i]
        }
    }

    return toReturn
}

// https://stackoverflow.com/a/16776395
function convertStringToType(str) {
    if (typeof str !== 'string') return str

    var nan = isNaN(Number(str))
    var isfloat = /^\d*(\.|,)\d*$/
    var commaFloat = /^(\d{0,3}(,)?)+\.\d*$/
    var dotFloat = /^(\d{0,3}(\.)?)+,\d*$/
    var date = /^\d{0,4}(\.|\/)\d{0,4}(\.|\/)\d{0,4}$/

    if (str === 'null') return null
    if (str === 'true') return true
    if (str === 'false') return false

    if (!nan) {
        if (parseFloat(str) === parseInt(str)) return Number(str) // 'integer'
        else return Number(str) // 'float'
    } else if (isfloat.test(str) || commaFloat.test(str) || dotFloat.test(str)) return Number(str) // 'float'
    else if (date.test(str)) return String(str) // 'date'
    else {
        return String(str) // 'string'
    }
}
/* eslint-enable */

const api = {
    handleError(error) {
        console.log(error)
    },

    createSuccessResponse(value) {
        return { success: true, value }
    },

    createErrorResponse(error) {
        return { success: false, error }
    },

    // From ETL-Twitch
    toQueryString,

    // https://stackoverflow.com/a/42696154
    /* eslint-disable */
    fromQueryString(data) {
        var result = {}

        for (var i in data) {
            var keys = i.split('.')
            keys.reduce(function (r, e, j) {
                return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? data[i] : {}) : [])
            }, result)
        }

        return result
    },
    /* eslint-enable */

    convertStringToType,
}

export default api
