<template>
    <div>
        <h2 class="text-1xl font-bold text-slate-800 md:text-2xl">Organization Datasources</h2>

        <div class="mt-4 grid grid-cols-2 gap-6">
            <SteamworksDatasourceCard />
        </div>
    </div>
</template>

<script>
import SteamworksDatasourceCard from '@/partials/cards/SteamworksDatasourceCard.vue'

export default {
    name: 'OrganizationDatasourcesSubpage',

    components: {
        SteamworksDatasourceCard,
    },

    data() {
        return {}
    },

    created() {},

    methods: {},
}
</script>
