<script setup>
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'

defineEmits(['paging-changed'])

const props = defineProps({
    paging: {
        type: Object,
        required: true,
    },
    recordName: {
        type: String,
        required: false,
    },
})

const onPageSizeChange = (value) => {
    const perPage = Number(value)
    const totalPages = Math.ceil(props.paging.totalRecords / perPage)
    let currentPage = props.paging.currentPage

    if (currentPage > totalPages) {
        currentPage = totalPages
    }

    return {
        perPage,
        totalPages,
        currentPage,
    }
}
</script>

<template>
    <div class="flex w-full items-center justify-between">
        <div class="flex items-center justify-start space-x-6 lg:space-x-8">
            <div v-if="props.paging.totalRecords > 0" class="text-sm font-medium">
                {{ $numeral(props.paging.totalRecords).format('0,0') }}
                {{ recordName == null ? 'active creators' : recordName == null ? 'records' : recordName }}
            </div>
        </div>

        <div class="flex items-center justify-end space-x-6 lg:space-x-8">
            <div class="flex items-center space-x-2">
                <p class="text-sm font-medium">Rows per page</p>
                <Select
                    :model-value="String(props.paging.perPage)"
                    @update:model-value="(value) => $emit('paging-changed', onPageSizeChange(value))"
                >
                    <SelectTrigger class="h-8 w-[70px]">
                        <SelectValue :placeholder="String(props.paging.perPage)" />
                    </SelectTrigger>
                    <SelectContent side="top">
                        <SelectItem
                            v-for="pageSize in ['10', '15', '25', '50', '100']"
                            :key="pageSize"
                            :value="String(pageSize)"
                        >
                            {{ pageSize }}
                        </SelectItem>
                    </SelectContent>
                </Select>
            </div>
            <div class="flex items-center text-sm font-medium">
                Page {{ props.paging.currentPage }} of
                {{ props.paging.totalPages > 0 ? $numeral(props.paging.totalPages).format('0,0') : 'all pages' }}
            </div>
            <div class="flex items-center space-x-2">
                <Button
                    variant="outline"
                    class="hidden h-8 w-8 p-0 lg:flex"
                    :disabled="props.paging.currentPage <= 1"
                    @click="$emit('paging-changed', { currentPage: 1 })"
                >
                    <span class="sr-only">Go to first page</span>
                    <ChevronsLeft class="h-4 w-4" />
                </Button>
                <Button
                    variant="outline"
                    class="h-8 w-8 p-0"
                    :disabled="props.paging.currentPage <= 1"
                    @click="$emit('paging-changed', { currentPage: props.paging.currentPage - 1 })"
                >
                    <span class="sr-only">Go to previous page</span>
                    <ChevronLeft class="h-4 w-4" />
                </Button>
                <Button
                    variant="outline"
                    class="h-8 w-8 p-0"
                    :disabled="props.paging.currentPage === props.paging.totalPages"
                    @click="$emit('paging-changed', { currentPage: props.paging.currentPage + 1 })"
                >
                    <span class="sr-only">Go to next page</span>
                    <ChevronRight class="h-4 w-4" />
                </Button>
                <Button
                    variant="outline"
                    class="hidden h-8 w-8 p-0 lg:flex"
                    :disabled="!(props.paging.totalPages > 0) || props.paging.currentPage === props.paging.totalPages"
                    @click="$emit('paging-changed', { currentPage: props.paging.totalPages })"
                >
                    <span class="sr-only">Go to last page</span>
                    <ChevronsRight class="h-4 w-4" />
                </Button>
            </div>
        </div>
    </div>
</template>
