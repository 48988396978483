<script setup>
import { ref } from 'vue'

const props = defineProps({
    question: {
        type: String,
        required: true,
    },
    answer: {
        type: Array,
        required: false,
    },
    onQuoteMouseEnter: {
        type: Function,
        required: true,
    },
    onQuoteMouseLeave: {
        type: Function,
        required: true,
    },
    onQuoteClick: {
        type: Function,
        required: true,
    },
})

const open = ref(false)

const handleClick = () => {
    open.value = !open.value
}
</script>

<template>
    <div class="flex rounded border p-1 hover:cursor-pointer hover:bg-slate-100" @click="handleClick">
        <div><font-awesome-icon :icon="['fas', 'wand-magic-sparkles']" class="m-1 h-4 w-4 text-indigo-700" /></div>
        <div class="space-y-2">
            <div class="text-sm">{{ props.question }}</div>
            <div v-if="open" class="rounded bg-indigo-100 px-1.5 py-1 text-xs text-indigo-800">
                <b>AI: </b>
                <div v-for="(section, index) in answer" :key="index">
                    <div>{{ section.explanation }}</div>
                    <div
                        v-if="section.quote"
                        class="rounded border-l-4 border-indigo-300 bg-indigo-200 p-0.5 hover:cursor-pointer"
                        @click="props.onQuoteClick(section.quote)"
                        @mouseenter="props.onQuoteMouseEnter(section.quote)"
                        @mouseleave="props.onQuoteMouseLeave(section.quote)"
                    >
                        <div class="italic text-blue-700 hover:underline">
                            "{{ section.quote.text }}" - {{ section.quote.timestamp }}
                        </div>
                        <div></div>
                    </div>
                    <br v-if="index !== answer.length - 1" />
                </div>
            </div>
        </div>
    </div>
</template>
