<template>
    <div>
        <CreatorActionModal
            ref="actionModal"
            EntityType="Participant"
            @reload="onReload"
            @perform-action-start="onPerformActionStart"
            @perform-action-complete="onPerformActionComplete"
        />

        <div class="col-span-full rounded-sm border border-slate-200 bg-white shadow-lg">
            <div class="p-3">
                <div class="">
                    <table v-if="!isLoading" class="w-full table-auto">
                        <thead class="rounded-sm bg-slate-50 text-left text-xs uppercase text-slate-400">
                            <tr>
                                <th class="w-7 whitespace-nowrap p-2 pl-3 pl-5">
                                    <div class="flex w-full">
                                        <input
                                            type="checkbox"
                                            class="form-checkbox my-auto"
                                            :checked="isAllPageCheckboxSelected"
                                            @click="onAllPageCheckboxClick"
                                        />
                                    </div>
                                </th>

                                <th class="whitespace-nowrap p-2" @click="sortByField('creator')">
                                    <div class="flex">
                                        <span class="font-semibold">Creator</span>
                                        <ColumnSort
                                            :sort-by="paging.sortby"
                                            :sort-dir="paging.sortdir"
                                            column="creator"
                                        />
                                    </div>
                                </th>

                                <th
                                    v-if="activeTab && activeTab.key === 'everyone'"
                                    class="whitespace-nowrap p-2"
                                    @click="sortByField('stage')"
                                >
                                    <div class="flex">
                                        <span class="font-semibold">Stage</span>
                                        <ColumnSort
                                            :sort-by="paging.sortby"
                                            :sort-dir="paging.sortdir"
                                            column="stage"
                                        />
                                    </div>
                                </th>

                                <th class="whitespace-nowrap p-2" @click="sortByField('status')">
                                    <div class="flex">
                                        <span class="font-semibold">Status</span>
                                        <ColumnSort
                                            :sort-by="paging.sortby"
                                            :sort-dir="paging.sortdir"
                                            column="status"
                                        />
                                    </div>
                                </th>

                                <!-- <th class="p-2 whitespace-nowrap" @click="sortByField('locale')">
                                <div class="flex">
                                    <span class="font-semibold">Locale/Country</span>
                                </div>
                            </th> -->

                                <!-- <th class="p-2 hitespace-nowrap" @click="sortByField('avg_viewers')">
                                <div class="flex">
                                    <span class="font-semibold">ACV/Views</span>
                                </div>
                            </th> -->

                                <th class="whitespace-nowrap p-2" @click="sortByField('followers')">
                                    <div class="flex">
                                        <span class="font-semibold">Platform<br />Followers</span>
                                        <ColumnSort
                                            :sort-by="paging.sortby"
                                            :sort-dir="paging.sortdir"
                                            column="followers"
                                        />
                                    </div>
                                </th>

                                <th
                                    v-if="activeTab && activeTab.key !== 'everyone'"
                                    class="whitespace-nowrap p-2"
                                    @click="sortByField('payout')"
                                >
                                    <div v-if="shouldShowMarketRateColumn" class="flex">
                                        <span class="font-semibold">Market Rate</span>
                                        <ColumnSort
                                            :sort-by="paging.sortby"
                                            :sort-dir="paging.sortdir"
                                            column="payout"
                                        />
                                    </div>
                                    <div v-if="!shouldShowMarketRateColumn" class="flex">
                                        <span class="font-semibold">Price</span>
                                        <ColumnSort
                                            :sort-by="paging.sortby"
                                            :sort-dir="paging.sortdir"
                                            column="payout"
                                        />
                                    </div>
                                </th>

                                <th class="whitespace-nowrap p-2">
                                    <div class="flex">
                                        <span class="font-semibold">Creator Page</span>
                                    </div>
                                </th>

                                <th
                                    v-if="activeTab.key === 'prospect.started' || activeTab.key === 'prospect.approved'"
                                    class="whitespace-nowrap p-2"
                                >
                                    <div class="flex">
                                        <span class="font-semibold">Has email</span>
                                    </div>
                                </th>

                                <th class="w-7 pl-2 pr-5">
                                    <div
                                        class="flex w-full rounded bg-slate-100 text-slate-600 transition duration-200 hover:bg-slate-200"
                                    >
                                        <ParticipantAction
                                            :actions="actions"
                                            :creators="$lodash.values(selectedCreators)"
                                            :show-count="true"
                                            @perform-action="onActionModalOpen"
                                        />
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <tbody
                            class="divide-y divide-slate-200 border-b border-slate-200 text-sm"
                            :class="{ 'pointer-events-none animate-pulse rounded bg-slate-100': isBusy === true }"
                        >
                            <tr v-for="creator in creators" :key="creator.superview._id" class="group">
                                <td class="whitespace-nowrap px-2 py-3.5 pl-5">
                                    <input
                                        :checked="selectedCreators[$lodash.get(creator, idProp)]"
                                        type="checkbox"
                                        class="form-checkbox"
                                        :value="creator"
                                        @input="onSelectCreator(creator, $event)"
                                    />
                                </td>

                                <td class="whitespace-nowrap px-2 py-3.5">
                                    <div class="relative flex items-center">
                                        <NamePlate
                                            class="my-auto"
                                            :logo="creator.participant.platform_user_logo"
                                            :name="creator.participant.platform_user_name"
                                            :display-name="creator.participant.platform_user_name"
                                            :platform-id="creator.participant.platform_id"
                                            :platform-user-id="creator.participant.platform_user_id"
                                            :is-drawer-enabled="true"
                                            :participant="creator.participant"
                                            :campaign="campaign"
                                            :is-in-workflow="true"
                                        />

                                        <div
                                            class="absolute right-0 top-0 hidden h-full cursor-pointer rounded bg-slate-100 px-2 text-xs font-semibold transition duration-200 group-hover:flex hover:bg-slate-200"
                                            @click="
                                                $store.commit('modal:setData', {
                                                    modal_id: 'creator',
                                                    data: {
                                                        is_open: true,
                                                        platform_id: creator.participant.platform_id,
                                                        platform_user_id: creator.superview._id,
                                                        supercampaign_id: campaign.parent_id,
                                                        campaign_id: campaign.id,
                                                        campaign: campaign,
                                                        participant_id: creator.participant.id,
                                                        participant: creator.participant,
                                                        is_in_workflow: true,
                                                    },
                                                })
                                            "
                                        >
                                            <span class="my-auto flex uppercase">
                                                <font-awesome-icon
                                                    :icon="['fas', 'rectangle-list']"
                                                    class="my-auto mr-1 h-4 w-4"
                                                />
                                                <span class="my-auto leading-none">Open</span>
                                            </span>
                                        </div>
                                    </div>
                                </td>

                                <td
                                    v-if="activeTab && activeTab.key === 'everyone'"
                                    class="whitespace-nowrap px-2 py-3"
                                >
                                    <div class="flex">
                                        <div class="flex flex-col">
                                            <div v-if="creator.participant.stageStatus" class="my-auto flex space-x-1">
                                                <div
                                                    v-for="(tab, tabKey, index) in tabs"
                                                    :key="tabKey"
                                                    class="flex h-2 w-9 rounded-sm text-white"
                                                    :class="
                                                        index <=
                                                        getFromDictionary(
                                                            `campaign.stages.${creator.participant.stage}`,
                                                            'index',
                                                        )
                                                            ? `${getFromDictionary(
                                                                  `campaign.stages.${creator.participant.stage}`,
                                                                  'bgClass',
                                                              )}`
                                                            : 'bg-slate-100'
                                                    "
                                                ></div>
                                            </div>
                                            <div class="mt-2 flex justify-between text-xs leading-none">
                                                <div>
                                                    <span class="mr-1 font-light text-slate-500">Stage:</span>
                                                    <span class="font-medium text-slate-700">{{
                                                        getFromDictionary(
                                                            `campaign.stages.${creator.participant.stage}`,
                                                            'label',
                                                        )
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td class="whitespace-nowrap px-2 py-3">
                                    <div class="flex">
                                        <div
                                            v-if="creator.participant.stageStatus"
                                            class="my-auto flex rounded bg-slate-100"
                                        >
                                            <span
                                                class="my-auto block flex h-7 rounded px-2.5 text-center text-white"
                                                :class="
                                                    getFromDictionary(
                                                        `campaign.stages.${creator.participant.stage}`,
                                                        'bgClass',
                                                    )
                                                "
                                            >
                                            </span>
                                            <span class="my-auto px-2 text-sm font-medium leading-none">
                                                {{
                                                    getFromDictionary(
                                                        `campaign.stages.${creator.participant.stage}.status.${creator.participant.stageStatus}`,
                                                        'label',
                                                    )
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </td>

                                <td class="whitespace-nowrap px-2 text-left">
                                    <div v-if="creator.superview?.channel?.followers">
                                        {{ $filters.formatWholeNumberWithUnit(creator.superview.channel.followers) }}
                                    </div>
                                    <div v-else-if="creator.superview?.normalized?.followers">
                                        {{
                                            $filters.formatWholeNumberWithUnit(creator?.superview?.normalized.followers)
                                        }}
                                    </div>
                                </td>

                                <td v-if="activeTab && activeTab.key !== 'everyone'" class="whitespace-nowrap px-2">
                                    <div
                                        v-if="shouldShowMarketRateColumn && creator.superview.trueprice"
                                        class="text-left"
                                    >
                                        ${{ $numeral(creator.superview.trueprice.hourly_rate).format('0,0') }}
                                    </div>
                                    <div
                                        v-else-if="
                                            !shouldShowMarketRateColumn &&
                                            creator.participant.tasks &&
                                            creator.participant.tasks.length > 0
                                        "
                                        class="text-left"
                                    >
                                        ${{ $numeral(getTotalTaskPayout(creator.participant.tasks)).format('0,0') }}
                                    </div>
                                    <div v-else class="text-left">---</div>
                                </td>

                                <th class="whitespace-nowrap p-2">
                                    <div class="flex text-xs font-medium">
                                        <a
                                            class="flex cursor-pointer rounded bg-slate-100 px-3 py-2 transition duration-200 hover:bg-slate-200"
                                            :href="`${bidPageBaseUrl}/bids/${creator.participant.access_key}`"
                                            target="blank"
                                        >
                                            <div class="mr-1.5 flex">
                                                <font-awesome-icon
                                                    :icon="['fas', 'arrow-up-right-from-square']"
                                                    class="my-auto h-3 w-3 text-slate-500"
                                                />
                                            </div>
                                            <div class="my-auto leading-none">View As Creator</div>
                                        </a>
                                    </div>
                                </th>

                                <th
                                    v-if="activeTab.key === 'prospect.started' || activeTab.key === 'prospect.approved'"
                                    class="whitespace-nowrap p-2"
                                >
                                    <div class="flex text-xs font-medium">
                                        <div v-if="creator?.superview?.normalized?.email" class="my-auto leading-none">
                                            Yes
                                        </div>
                                        <div v-else class="my-auto leading-none">No</div>
                                    </div>
                                </th>

                                <td class="w-7 whitespace-nowrap px-2 pr-5">
                                    <div
                                        class="flex w-full rounded bg-slate-50 transition duration-200 hover:bg-slate-200"
                                    >
                                        <ParticipantAction
                                            :actions="actions"
                                            :creators="[creator]"
                                            :show-count="false"
                                            @perform-action="onActionModalOpen"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="!creators || (!creators.length && !isLoading)" class="w-full p-4 text-center">
                        <p class="text-sm font-medium">No creators to show</p>
                    </div>
                </div>
            </div>

            <div class="mb-4 ml-4 mr-4 mt-2">
                <DiscoveryPagination :paging="paging" @paging-changed="onPagingChange" />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import NamePlate from '@/components/plates/NamePlate.vue'
import DiscoveryPagination from '@/partials/tables/discovery/DiscoveryPagination.vue'
import ParticipantAction from '@/components/ParticipantAction.vue'
import CreatorActionModal from '@/partials/modals/CreatorActionModal.vue'
import ColumnSort from '@/components/ColumnSort.vue'

export default {
    name: 'WorkflowTable',

    components: {
        NamePlate,
        DiscoveryPagination,
        ParticipantAction,
        CreatorActionModal,
        ColumnSort,
    },

    props: {
        creators: {
            type: Array,
        },
        isLoading: {
            type: Boolean,
        },
        paging: {
            type: Object,
        },
        actions: {
            type: Array,
        },
        campaign: {
            type: Object,
        },
        activeParentTab: {
            type: Object,
        },
        activeChildTab: {
            type: Object,
        },
        activeTab: {
            type: Object,
        },
        tabs: {
            type: Object,
        },
    },

    emits: ['paging-change', 'reload'],

    data() {
        return {
            idProp: 'participant.id',

            selectedCreators: {},
            isBusy: false,

            bidPageBaseUrl: import.meta.env.VITE_APP_CREATOR_DASHBOARD_URL,
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'getLanguageFromCode', 'getCountryFromCode']),
        shouldShowMarketRateColumn() {
            return this.activeTab && this.activeTab.key !== 'prospect'
        },

        isAllPageCheckboxSelected() {
            if (this?.creators?.length === 0) return false

            return _.every(this.creators, (creator) => {
                return this.selectedCreators[_.get(creator, this.idProp)]
            })
        },
    },

    watch: {
        activeTab() {
            // unselect everything
            this.selectedCreators = {}
        },
    },

    methods: {
        onSelectCreator(creator) {
            const idValue = _.get(creator, this.idProp)

            if (!this.selectedCreators[idValue]) {
                this.selectedCreators[idValue] = creator
            } else {
                this.selectedCreators = _.omitBy(this.selectedCreators, (selectedCreator) => {
                    return _.get(selectedCreator, this.idProp) === idValue
                })
            }
        },

        onAllPageCheckboxClick() {
            const visibleCreatorsLookup = _.keyBy(this.creators, this.idProp)
            if (this.isAllPageCheckboxSelected === true) {
                this.selectedCreators = _.omitBy(this.selectedCreators, (selectedCreator) => {
                    const matchingCreator = visibleCreatorsLookup[_.get(selectedCreator, this.idProp)]
                    return matchingCreator
                })
            } else {
                _.forEach(this.creators, (creator) => {
                    const matchingCreator = this.selectedCreators[_.get(creator, this.idProp)]

                    if (!matchingCreator) {
                        this.selectedCreators[_.get(creator, this.idProp)] = creator
                    }
                })
            }
        },

        getTotalTaskPayout(creatorTasks) {
            let totalPayout = 0

            _.forEach(creatorTasks, (task) => {
                if (task.payout_amount) {
                    totalPayout += task.payout_amount
                }
            })

            return totalPayout
        },

        onActionModalOpen(action, creators) {
            this.$refs.actionModal.onActionModalOpen(action, creators)
        },

        onPerformActionStart() {
            this.isBusy = true
        },

        onPerformActionComplete() {
            this.isBusy = false
        },

        onReload() {
            this.$emit('reload')

            // unselect everything
            this.selectedCreators = {}
        },

        sortByField(column) {
            const updatedPaging = {}

            if (this.paging.sortby === column && this.paging.sortdir === 'desc') {
                updatedPaging.sortdir = 'asc'
            } else {
                updatedPaging.sortdir = 'desc'
            }

            updatedPaging.sortby = column
            this.$emit('paging-change', updatedPaging)
        },

        onPagingChange(newPagingProperties) {
            this.$emit('paging-change', newPagingProperties)
        },
    },
}
</script>
