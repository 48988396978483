import _ from 'lodash'

export default {
    state: {
        default: '#eeeeee',
        inactive: '#909090',

        ranks: {
            bronze: '#d7995b',
            silver: '#aaaaaa',
            gold: '#d7b740',
            platinum: '#b1d9df',
            ruby: '#ff7e79',
            emerald: '#74f695',
            sapphire: '#4499ff',
            diamond: '#98c2ff',
        },

        socials: {
            adobe: '#ff0000',
            airbnb: '#fd5c63',
            algolia: '#050f2c',
            amazon: '#ff9900',
            android: '#a4c639',
            atlassian: '#003366',
            behance: '#1769ff',
            codepen: '#0ebeff',
            dailymotion: '#00aaff',
            deviantart: '#05cc47',
            discord: '#5865F2',
            dribbble: '#ea4c89',
            dropbox: '#007ee5',
            duolingo: '#7ac70c',
            etsy: '#d5641c',
            evernote: '#2dbe60',
            facebook: '#3b5998',
            feedly: '#2bb24c',
            github: '#333',
            gitlab: '#fc6d26',
            google: '#4285f4',
            instagram: '#e1306c',
            linkedin: '#0077b5',
            messenger: '#0084ff',
            microsoft: '#f65314',
            netflix: '#e50914',
            pinterest: '#e60023',
            pocket: '#ef4056',
            react: '#00d8ff',
            reddit: '#ff4500',
            shopify: '#96bf48',
            skype: '#00aff0',
            slack: '#611f69',
            snapchat: '#fffc00',
            spotify: '#1db954',
            stripe: '#6772e5',
            telegram: '#0088cc',
            tiktok: '#FF0050',
            trello: '#0079bf',
            tumblr: '#35465c',
            twitch: '#6441a5',
            twitter: '#1da1f2',
            uber: '#09091a',
            ubuntu: '#dd4814',
            vimeo: '#162221',
            vue: '#42b883',
            whatsapp: '#075e54',
            wikipedia: '#000000',
            youtube: '#ff0000',
            x: '#000000',
        },
    },

    getters: {
        getColor: (state) => (type, item) => {
            if (!state[type] || !state[type][item]) return state.default
            return state[type][item]
        },

        getColors: (state) => state,

        colors: (state) => state,

        ranks: (state) => {
            let index = 1

            return _.map(state.ranks, (color, key) => {
                const result = {
                    name: key,
                    index: _.size(state.ranks) - index,
                    color,
                }

                index += 1
                return result
            }).reverse()
        },
    },
}
