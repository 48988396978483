<script setup>
import _ from 'lodash'
import { h, computed } from 'vue'
import DataTable from '@/components/ui/data-table/DataTable.vue'
import DataTableSortableColumnHeader from '@/components/ui/data-table/DataTableSortableColumnHeader.vue'
import InterestInfoCell from './creator-interests/InterestInfoCell.vue'
import AffinityCell from './creator-interests/AffinityCell.vue'

const props = defineProps({
    data: {
        type: Array,
        required: true,
    },
})

const interestsList = computed(() => {
    const items = _.reduce(
        props.data,
        (results, item) => {
            results.push({
                interest: item.interest,
                type: 'primary',
                icon: item.emoji,
                affinity: item.strength_rating,
                reason: item.reason,
            })

            if (item?.sub_interests.length) {
                _.forEach(item.sub_interests, (subItem) => {
                    results.push({
                        interest: subItem.sub_interest,
                        type: 'sub',
                        parent: item.interest,
                        icon: subItem.emoji,
                        affinity: subItem.strength_rating,
                        reason: subItem.reason,
                    })
                })
            }

            return results
        },
        [],
    )

    return _.orderBy(items, ['affinity'], ['desc'])
})

const toolbar = {
    placeholder: 'Filter interests...',
    columnToFilter: 'interest',
}

const columns = [
    {
        accessorKey: 'interest',
        meta: {
            title: 'Interest',
        },
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
        cell: ({ row }) => {
            return h(InterestInfoCell, { data: row.original })
        },
        class: 'w-2/3',
    },
    {
        accessorKey: 'type',
        accessorFn: (row) => `${row.type} Interest`,
        meta: {
            title: 'Type',
        },
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
        cell: ({ row }) => {
            return h('div', { class: 'capitalize text-sm' }, row.original.type)
        },
    },
    {
        accessorKey: 'affinity',
        meta: {
            title: 'Affinity',
        },
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
        cell: ({ row }) => {
            return h(AffinityCell, { data: row.original })
        },
        class: `w-0`,
    },
]
</script>

<template>
    <DataTable
        :columns="columns"
        :data="interestsList"
        :toolbar="toolbar"
        table-cell-class="py-3 px-3 text-sm"
        preferences-id="creator-interests"
    />
</template>
