<template>
    <ModalBasic
        id="feedback-modal"
        :modal-open="modals.create_campaign_module.is_open"
        title="Add Campaign Module"
        @close-modal="
            $store.commit('modal:setData', {
                modal_id: 'create_campaign_module',
                data: {
                    is_open: false,
                    supercampaign_id: null,
                    selected_campaign_type: null,
                },
            })
        "
    >
        <!-- Modal content -->
        <div class="px-5 pb-1 pt-4">
            <div class="text-sm">
                <div class="mb-4">
                    <p>
                        Modules are independent pieces of a campaign that are executed as part of a greater strategy
                        around an event like a launch, announcement or update.
                    </p>
                </div>
                <!-- Options -->
                <ul class="mb-4 space-y-2">
                    <li
                        v-for="campaignTypeOption in createCampaignModuleOptions"
                        :key="campaignTypeOption"
                        class="group"
                        @click="
                            $store.commit('modal:setData', {
                                modal_id: 'create_campaign_module',
                                data: {
                                    selected_campaign_type: campaignTypeOption,
                                },
                            })
                        "
                    >
                        <button
                            class="h-full w-full rounded border-2 bg-white px-4 py-3 text-left shadow-sm duration-150 ease-in-out"
                            :class="
                                modals.create_campaign_module.selected_campaign_type === campaignTypeOption
                                    ? 'border-indigo-400'
                                    : 'border-slate-200 group-hover:border-slate-300'
                            "
                        >
                            <div class="flex">
                                <div class="mr-3">
                                    <!-- <div
                                    class="w-4 h-4 border-4 rounded-full duration-150 ease-in-out"
                                    :class="modals.create_campaign_module.selected_campaign_type === campaignTypeOption
                                        ? 'border-indigo-500'
                                        : 'border-slate-300 group-hover:border-slate-400'
                                    "
                                /> -->

                                    <font-awesome-icon
                                        :icon="getFromDictionary(`campaign.type.${campaignTypeOption}`, 'icon')"
                                        class="mt-3 h-7 w-7 duration-150 ease-in-out"
                                        :class="
                                            modals.create_campaign_module.selected_campaign_type === campaignTypeOption
                                                ? 'text-indigo-500'
                                                : 'text-slate-400 group-hover:text-slate-500'
                                        "
                                    />
                                </div>

                                <div class="grow">
                                    <div class="items-*center mb-0.5 flex flex-wrap justify-between">
                                        <span class="font-medium text-slate-800">{{
                                            getFromDictionary(`campaign.type.${campaignTypeOption}`, 'label')
                                        }}</span>
                                    </div>
                                    <div class="text-sm">
                                        {{ getFromDictionary(`campaign.type.${campaignTypeOption}`, 'description') }}
                                    </div>
                                </div>
                            </div>
                        </button>
                    </li>

                    <li v-if="!createCampaignModuleOptions.length">
                        <p
                            class="h-full w-full rounded border-2 bg-white px-4 py-3 text-left text-center shadow-sm duration-150 ease-in-out"
                        >
                            You've added every available type of campaign module!
                        </p>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Modal footer -->
        <div class="px-5 py-4">
            <div class="flex flex-wrap justify-end space-x-2">
                <button
                    class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                    @click="
                        $store.commit('modal:setData', {
                            modal_id: 'create_campaign_module',
                            data: {
                                is_open: false,
                                supercampaign_id: null,
                                selected_campaign_type: null,
                            },
                        })
                    "
                >
                    Cancel
                </button>
                <button
                    class="btn-sm text-white enabled:bg-indigo-500 enabled:hover:bg-indigo-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                    :disabled="!modals.create_campaign_module.selected_campaign_type"
                    @click="createCampaign(modals.create_campaign_module.selected_campaign_type)"
                >
                    Add Module
                </button>
            </div>
        </div>
    </ModalBasic>

    <div v-if="supercampaign" class="mx-auto w-full max-w-9xl p-3 md:p-4 lg:p-8">
        <div class="grid grid-cols-[285px,1fr] gap-1" style="grid-template-columns: 285px 1fr">
            <div
                class="col-span-12 -ml-4 overflow-y-auto overflow-x-hidden px-4 scrollbar scrollbar-thumb-slate-200 scrollbar-w-2 scrollbar-h-2 hover:scrollbar-thumb-slate-300 active:scrollbar-thumb-slate-400 lg:col-span-1"
            >
                <CampaignSidebar :supercampaign="supercampaign" :campaigns-by-id="campaignsById" />
            </div>

            <div class="col-span-12 overflow-hidden lg:col-span-1">
                <router-view
                    v-if="supercampaign && campaignsById"
                    :key="`${supercampaign.id}-${$route.params.campaign_id ? $route.params.campaign_id : 'root'}`"
                    :supercampaign="supercampaign"
                    :campaigns-by-id="campaignsById"
                    @onSupercampaignEdit="onSupercampaignEdit"
                />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

import CampaignSidebar from '@/partials/sidebars/CampaignSidebar.vue'
import ModalBasic from '@/components/ModalBasic.vue'

import campaignAPI from '@/utils/api/api.campaign'

export default {
    name: 'SupercampaignPage',

    components: {
        CampaignSidebar,
        ModalBasic,
    },

    beforeRouteUpdate(to, from, next) {
        if (to.params.supercampaign_id !== from.params.supercampaign_id) {
            this.supercampaign = null
            this.campaignsById = {}
            this.getSupercampaign(to.params.supercampaign_id)
        }
        next()
    },

    data() {
        return {
            supercampaign: null,
            campaignsById: {},
        }
    },

    computed: {
        ...mapGetters(['modals', 'isSfStaff', 'getFromDictionary', 'checkPermission']),

        createCampaignModuleOptions() {
            const allCampaignTypes = []

            if (this.isSfStaff) {
                allCampaignTypes.push('game-sponsorship')
                allCampaignTypes.push('steam-broadcast')
            }

            if (this.checkPermission('feature.campaigns.manage')) {
                allCampaignTypes.push('key-distribution')
            }

            if (!this.supercampaign || !_.size(this.campaignsById)) return allCampaignTypes

            const existingCampaignTypes = _.map(this.campaignsById, 'type')

            return _.filter(allCampaignTypes, (campaignType) => !existingCampaignTypes.includes(campaignType))
        },
    },

    created() {
        this.getSupercampaign(this.$route.params.supercampaign_id)
    },

    methods: {
        getSupercampaign(supercampaignId) {
            this.$axios
                .get(`/supercampaigns/${supercampaignId}`, {
                    params: {
                        relations: ['game', 'campaigns', 'organization'],
                    },
                })
                .then(({ data }) => {
                    this.supercampaign = data.payload.supercampaign
                    const { campaigns } = data.payload.supercampaign
                    // eslint-disable-next-line no-param-reassign
                    delete data.payload.supercampaign.campaigns

                    this.campaignsById = _.keyBy(campaigns, 'id')

                    if (data?.payload?.supercampaign?.title) {
                        document.title = `Streamforge - ${data.payload.supercampaign.title}`
                    } else {
                        document.title = `Streamforge`
                    }

                    // Redirect to the home page if accessing directly
                    if (this.$route.name === 'supercampaign') {
                        this.$router.push({
                            name: 'supercampaign-home',
                            params: {
                                supercampaignId,
                            },
                        })
                    }
                })
                .catch((error) => {
                    console.error(`Error fetching campaign`, error)

                    this.$router.push({
                        name: 'campaigns',
                    })
                })
        },

        onSupercampaignEdit(supercampaign) {
            this.supercampaign = _.assign(this.supercampaign, supercampaign)
        },

        async createCampaign(type) {
            if (!this.modals.create_campaign_module.selected_campaign_type) return

            const response = await campaignAPI.createCampaign(
                this.$axios,
                this.supercampaign.id,
                this.supercampaign.organization_id,
                type,
            )

            if (response.success) {
                this.campaignsById[response.value.campaign.id] = response.value.campaign

                // Clear the modal
                this.$store.commit('modal:setData', {
                    modal_id: 'create_campaign_module',
                    data: {
                        is_open: false,
                        supercampaign_id: null,
                        selected_campaign_type: null,
                    },
                })

                this.$router.push({
                    name: 'supercampaign-settings',
                    params: {
                        supercampaign_id: this.supercampaign.id,
                    },
                    query: {
                        tab: type.id,
                        tabId: response.value.campaign.id,
                    },
                })
            }
        },
    },
}
</script>
