<script setup>
import numeral from 'numeral'
import { h } from 'vue'
import DataTable from '@/components/ui/data-table/DataTable.vue'
import AudienceReceptionPlate from '@/components/plates/AudienceReceptionPlate.vue'
import DataTableSortableColumnHeader from '@/components/ui/data-table/DataTableSortableColumnHeader.vue'
import GameInfoCell from './creator-games/GameInfoCell.vue'
import CreatorStreamedCell from './creator-games/CreatorStreamedCell.vue'
import AverageViewersCell from './creator-games/AverageViewersCell.vue'
import AffinityCell from './creator-games/AffinityCell.vue'

const props = defineProps({
    platformId: {
        type: String,
        required: true,
        validator: (value) => ['twitch', 'youtube'].includes(value),
    },
    data: {
        type: Array,
        required: true,
    },
})

const toolbar = {
    placeholder: 'Filter games...',
    columnToFilter: 'name',
}

const twitchColumns = [
    {
        accessorKey: 'name',
        accessorFn: (row) => row.creator_accumulation.name,
        meta: {
            title: 'Game',
        },
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
        cell: ({ row }) => {
            const gameInfo = {
                name: row.original.creator_accumulation.name,
                game_id: row.original.game_id,
                sponsored_count: row.original.creator_accumulation.sponsored_count,
                last_stream_ended_at: row.original.creator_accumulation.last_stream_ended_at || '',
                last_video_at: row.original.creator_accumulation.last_video_at || '',
            }
            return h(GameInfoCell, { gameInfo, selectedPlatformId: 'twitch' })
        },
    },
    {
        accessorKey: 'Airtime',
        accessorFn: (row) => row.creator_accumulation.duration_seconds,
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: 'Airtime' }),
        cell: ({ row }) => {
            const creatorStreamed = {
                duration_seconds: row.original.creator_accumulation.duration_seconds,
                raw_difference_percent: row.original.raw_difference_percent,
                count: row.original.creator_accumulation.count,
            }
            return h(CreatorStreamedCell, { creatorStreamed, selectedPlatformId: 'twitch' })
        },
    },
    {
        accessorKey: 'Avg Viewers',
        accessorFn: (row) => row.creator_accumulation.avg_viewers,
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: 'Avg Viewers' }),
        cell: ({ row }) => {
            const averageViewers = {
                avg_viewers: row.original.creator_accumulation.avg_viewers,
                avg_views: '',
            }
            return h(AverageViewersCell, { averageViewers, selectedPlatformId: 'twitch' })
        },
    },
    {
        accessorKey: 'Hours Watched',
        accessorFn: (row) => numeral(row.creator_accumulation.minutes_watched / 60).format('0,0'),
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: 'Hours Watched' }),
    },
    {
        accessorKey: 'Airtime Dominance',
        accessorFn: (row) => numeral(row.creator_accumulation.duration_percent).format('0.0%'),
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: 'Airtime Dominance' }),
    },
    {
        accessorKey: 'Total Streams',
        accessorFn: (row) => numeral(row.creator_accumulation.count).format('0,0'),
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: 'Total Streams' }),
    },
    {
        accessorKey: 'Sponsored Streams',
        accessorFn: (row) => numeral(row.creator_accumulation.sponsored_count).format('0,0'),
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: 'Sponsored Streams' }),
    },
    {
        accessorKey: 'Viewership Stability',
        accessorFn: (row) => numeral(Math.min(1 + row.creator_accumulation.avg_acv_performance, 1)).format('0%'),
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: 'Viewership Stability' }),
    },
    {
        accessorKey: 'Audience Reception',
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: 'Audience Reception' }),
        cell: ({ row }) => {
            return h(AudienceReceptionPlate, { viewershipChange: row.original.audience_reception })
        },
    },
    {
        accessorKey: 'Affinity',
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: 'Affinity' }),
        cell: ({ row }) => {
            const affinity = {
                affinity: row.original.affinity,
                name: row.original.creator_accumulation.name,
                duration_seconds: row.original.creator_accumulation.duration_seconds,
                raw_difference_percent: row.original.raw_difference_percent,
                count: row.original.creator_accumulation.count,
                audience_reception: row.original.audience_reception,
            }
            return h(AffinityCell, { affinity, selectedPlatformId: 'twitch' })
        },
    },
]

const youtubeColumns = [
    {
        accessorKey: 'name',
        accessorFn: (row) => row.creator_accumulation.name,
        meta: {
            title: 'Game',
        },
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
        cell: ({ row }) => {
            const gameInfo = {
                name: row.original.creator_accumulation.name,
                game_id: row.original.game_id,
                sponsored_count: row.original.creator_accumulation.sponsored_count,
                last_stream_ended_at: row.original.creator_accumulation.last_stream_ended_at || '',
                last_video_at: row.original.creator_accumulation.last_video_at || '',
            }
            return h(GameInfoCell, { gameInfo, selectedPlatformId: 'youtube' })
        },
    },
    {
        accessorKey: 'Videos Created',
        accessorFn: (row) => row.creator_accumulation.count,
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: 'Videos Created' }),
        cell: ({ row }) => {
            const creatorStreamed = {
                duration_seconds: row.original.creator_accumulation.duration_seconds,
                raw_difference_percent: row.original.raw_difference_percent,
                count: row.original.creator_accumulation.count,
            }
            return h(CreatorStreamedCell, { creatorStreamed, selectedPlatformId: 'youtube' })
        },
    },
    {
        accessorKey: 'Avg Views',
        accessorFn: (row) => row.creator_accumulation.avg_views,
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: 'Avg Views' }),
        cell: ({ row }) => {
            const averageViewers = {
                avg_views: row.original.creator_accumulation.avg_views,
                count: row.original.creator_accumulation.count,
            }
            return h(AverageViewersCell, { averageViewers, selectedPlatformId: 'youtube' })
        },
    },
    {
        accessorKey: 'Audience Reception',
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: 'Audience Reception' }),
        cell: ({ row }) => {
            return h(AudienceReceptionPlate, { viewershipChange: row.original.audience_reception })
        },
    },
    {
        accessorKey: 'Affinity',
        header: ({ column }) => h(DataTableSortableColumnHeader, { column, title: 'Affinity' }),
        cell: ({ row }) => {
            const affinity = {
                affinity: row.original.affinity,
                name: row.original.creator_accumulation.name,
                duration_seconds: row.original.creator_accumulation.duration_seconds,
                raw_difference_percent: row.original.raw_difference_percent,
                count: row.original.creator_accumulation.count,
                audience_reception: row.original.audience_reception,
            }
            return h(AffinityCell, { affinity, selectedPlatformId: 'youtube' })
        },
    },
]

const getColumns = (selectedPlatformId) => {
    const validPlatforms = ['twitch', 'youtube']
    if (!validPlatforms.includes(selectedPlatformId)) {
        throw new Error('Invalid platform id')
    }
    return selectedPlatformId === 'twitch' ? twitchColumns : youtubeColumns
}
</script>

<template>
    <DataTable
        :columns="getColumns(platformId)"
        :data="props.data"
        :toolbar="toolbar"
        table-cell-class="py-2 px-3 text-sm"
        table-row-class="group"
        preferences-id="creator-games"
    ></DataTable>
</template>
