<template>
    <!-- Skeleton table body -->
    <tr v-for="index in 20" :key="index">
        <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/3">
            <div class="flex items-center">
                <div class="mr-2 shrink-0 sm:mr-3">
                    <div class="h-7 w-7 animate-pulse rounded-full bg-slate-200" />
                </div>

                <div class="h-3 w-20 animate-pulse rounded bg-slate-200" />
            </div>
        </td>

        <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
            <div class="h-3 w-6 animate-pulse rounded bg-slate-200" />
        </td>

        <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
            <div class="h-3 w-12 animate-pulse rounded bg-slate-200" />
        </td>

        <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
            <div class="h-3 w-10 animate-pulse rounded bg-slate-200" />
        </td>

        <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/5">
            <div class="flex justify-end text-right">
                <div class="flex border-r-2 pr-1.5">
                    <div class="flex h-5 w-5 animate-pulse rounded-full bg-slate-200" />
                </div>

                <div class="ml-2 h-5 w-5 animate-pulse rounded-full bg-slate-200" />
            </div>
        </td>
    </tr>
</template>

<script>
export default {
    name: 'SkeletonCreatorsSubpage',
}
</script>
