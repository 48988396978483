import moment from 'moment'

export function timestampToSeconds(timestamp) {
    // Split the timestamp into [hours, minutes, seconds]
    const parts = timestamp.split(':')

    // Parse hours, minutes, and seconds
    const hours = parseInt(parts[0], 10)
    const minutes = parseInt(parts[1], 10)
    const seconds = parseInt(parts[2], 10)

    // Calculate total in seconds
    return hours * 3600 + minutes * 60 + seconds
}

export function youtubeDurationToReadable(duration) {
    // Parse the duration string
    const regex = /P(?:(\d+)D)?T?(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/
    const matches = duration.match(regex)

    // Extract days, hours, minutes, and seconds
    const days = parseInt(matches[1], 10) || 0
    const hours = parseInt(matches[2], 10) || 0
    const minutes = parseInt(matches[3], 10) || 0
    const seconds = parseInt(matches[4], 10) || 0

    // Convert the extracted values to a moment duration
    const totalHours = days * 24 + hours
    const durationObject = moment.duration({
        hours: totalHours,
        minutes,
        seconds,
    })

    // Format the duration as a string
    return `${Math.floor(durationObject.asHours())}:${moment.utc(durationObject.asMilliseconds()).format('mm:ss')}`
}
