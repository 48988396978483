<template>
    <div class="col-span-12 md:col-span-8">
        <div v-if="!loading">
            <PricingPage
                v-if="!loading"
                class="my-auto"
                :campaign="campaign"
                :participant="participant"
                :on-rate-submit="onBidSubmit"
                mode="campaign"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import campaignSettingsAPI from '../../utils/api/api.campaign.settings'
import campaignBidsAPI from '../../utils/api/api.campaign.bids'
import PricingPage from '../../pages/PricingPage.vue'

export default {
    name: 'ManageBids',

    components: {
        PricingPage,
    },

    props: {
        supercampaignId: String,
        campaign: Object,
        participant: Object,
        onBidChange: { type: Function, required: true },
    },

    data() {
        return {
            modals: {},
            loading: true,
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary']),
    },

    watch: {
        participant() {
            this.reset()
        },
    },

    created() {
        this.reset()
    },

    methods: {
        async reset() {
            this.loading = true
            this.bid = null

            if (this.campaign !== null && !this.campaign.settings) {
                const campaignSettingsResponse = await campaignSettingsAPI.getCampaignSettings(
                    this.$axios,
                    this.campaign.id,
                )
                if (campaignSettingsResponse.success && campaignSettingsResponse.value) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.campaign.settings = campaignSettingsResponse.value.settings
                    // eslint-disable-next-line vue/no-mutating-props
                    this.campaign.stage_settings = campaignSettingsResponse.value.stage_settings
                }
            }

            if (this.participant !== null && !this.participant.bids) {
                const participantBidsResponse = await campaignBidsAPI.getCurrentParticipantBid(
                    this.$axios,
                    this.participant.id,
                )
                if (participantBidsResponse.success && participantBidsResponse.value) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.participant.bids = [participantBidsResponse.value.bid]
                } else {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.participant.bids = []
                }
            }

            this.loading = false
        },

        onBidSubmit(bid) {
            this.onBidChange(bid)
        },
    },
}
</script>
