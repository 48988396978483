<script setup>
const props = defineProps({
    selectedPlatformId: {
        type: String,
        required: true,
    },
    creatorStreamed: {
        type: Object,
        required: true,
    },
})
</script>
<template>
    <div class="text-sm">
        <div v-if="props.selectedPlatformId === 'twitch' && props.creatorStreamed.duration_seconds" class="flex">
            <div class="my-auto leading-none">
                {{ $filters.numeral(props.creatorStreamed.duration_seconds / (60 * 60)).format('0,0') }}h
            </div>
            <div
                class="my-auto ml-1 text-xs leading-none"
                :class="{
                    'text-rose-500': props.creatorStreamed.raw_difference_percent < 0,
                    'text-emerald-500': props.creatorStreamed.raw_difference_percent > 0,
                }"
            >
                <template v-if="props.creatorStreamed.raw_difference_percent > 1">
                    ({{ $filters.numeral(props.creatorStreamed.raw_difference_percent).format('0.0') }}x)
                </template>
                <template v-else-if="Math.round(props.creatorStreamed.raw_difference_percent * 100) !== 0">
                    ({{ $filters.numeral(props.creatorStreamed.raw_difference_percent).format('+0,0%') }})
                </template>
            </div>
        </div>
        <div v-else-if="props.selectedPlatformId === 'youtube' && props.creatorStreamed.count" class="flex">
            <div class="my-auto leading-none">
                {{ $filters.numeral(props.creatorStreamed.count).format('0,0') }}
            </div>
            <div
                v-if="props.creatorStreamed.raw_difference_percent !== 0"
                class="my-auto ml-1 text-xs leading-none"
                :class="{
                    'text-rose-500': props.creatorStreamed.raw_difference_percent < 0,
                    'text-emerald-500': props.creatorStreamed.raw_difference_percent > 0,
                }"
            >
                ({{ $numeral(props.creatorStreamed.raw_difference_percent).format('+0,0%') }})
            </div>
        </div>
        <div v-else>---</div>
    </div>
</template>
