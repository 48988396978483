<template>
    <div class="col-span-full flex flex-col rounded-sm border border-slate-200 bg-white shadow-lg xl:col-span-8">
        <header class="flex items-center border-b border-slate-100 px-5 py-4">
            <h2 class="font-semibold text-slate-800">{{ broadcast ? broadcast.name : 'Streamforge Broadcast' }}</h2>
        </header>

        <div v-if="broadcast" class="px-5 py-1">
            <div class="flex flex-wrap">
                <!-- Unique Visitors -->
                <div class="flex items-center py-2">
                    <div class="mr-5">
                        <div class="flex items-center">
                            <div class="mr-2 text-3xl font-bold text-slate-800">
                                {{ $filters.numeral(broadcast.unique_viewers).format('0,0') }}
                            </div>
                        </div>
                        <div class="text-sm text-slate-500">Unique Viewers</div>
                    </div>
                    <div class="mr-5 hidden h-8 w-px bg-slate-200 md:block" aria-hidden="true"></div>
                </div>
                <!-- Total Pageviews -->
                <div class="flex items-center py-2">
                    <div class="mr-5">
                        <div class="flex items-center">
                            <div class="mr-2 text-3xl font-bold text-slate-800">
                                {{ $filters.numeral(broadcast.peak_viewers).format('0,0') }}
                            </div>
                        </div>
                        <div class="text-sm text-slate-500">Peak Viewers</div>
                    </div>
                    <div class="mr-5 hidden h-8 w-px bg-slate-200 md:block" aria-hidden="true"></div>
                </div>
                <!-- Bounce Rate -->
                <div class="flex items-center py-2">
                    <div class="mr-5">
                        <div class="flex items-center">
                            <div class="mr-2 text-3xl font-bold text-slate-800">
                                {{ $filters.humanizeDurationShort(broadcast.airtime_seconds * 1000) }}
                            </div>
                        </div>
                        <div class="text-sm text-slate-500">Airtime</div>
                    </div>
                    <div class="mr-5 hidden h-8 w-px bg-slate-200 md:block" aria-hidden="true"></div>
                </div>
                <!-- Visit Duration-->
                <div class="flex items-center">
                    <div>
                        <div class="flex items-center">
                            <div class="mr-2 text-3xl font-bold text-slate-800">
                                {{ $filters.numeral(broadcast.seconds_watched / (60 * 60)).format('0,0') }}
                            </div>
                        </div>
                        <div class="text-sm text-slate-500">Hours Watched</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Chart built with Chart.js 3 -->
        <div v-if="chartData" class="grow">
            <!-- Change the height attribute to adjust the chart height -->
            <SteamBroadcastChart :data="chartData" width="800" height="300" />
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import SteamBroadcastChart from '@/partials/charts/SteamBroadcastChart.vue'

// Import utilities
import { tailwindConfig, hexToRGB } from '@/utils/Utils'

export default {
    name: 'SteamBroadcastCard',
    components: {
        SteamBroadcastChart,
    },

    props: ['broadcast', 'timeseries'],

    data() {
        return {
            chartData: null,
        }
    },

    watch: {
        broadcast(newValue, oldValue) {
            console.log(newValue)

            if (newValue && newValue.timeseries && newValue.timeseries.length && newValue !== oldValue) {
                this.fillChart(newValue.timeseries)
            }
        },
    },

    created() {
        this.fillChart(this.broadcast.timeseries)
    },

    methods: {
        fillChart(timeseries) {
            // console.log(`Filling chart`, timeseries)

            this.chartData = {
                labels: _.map(timeseries, (item) => moment(item.ts)),
                datasets: [
                    // Indigo line
                    {
                        label: 'Current',
                        data: _.map(timeseries, 'viewers'),
                        fill: true,
                        backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
                        borderColor: tailwindConfig().theme.colors.indigo[500],
                        borderWidth: 2,
                        tension: 0,
                        pointRadius: 0,
                        pointHoverRadius: 3,
                        pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
                        clip: 20,
                        lineTension: 0.8,
                    },
                    // Gray line
                    // {
                    //     label: 'Previous',
                    //     data: [
                    //         8000, 5000, 6500, 5000, 6500, 12000, 8000,
                    //         9000, 8000, 8000, 12500, 10000, 10000, 12000,
                    //         11000, 16000, 12000, 10000, 10000, 14000, 9000,
                    //         10000, 15000, 12500, 14000, 11000,
                    //     ],
                    //     borderColor: tailwindConfig().theme.colors.slate[300],
                    //     fill: false,
                    //     borderWidth: 2,
                    //     tension: 0,
                    //     pointRadius: 0,
                    //     pointHoverRadius: 3,
                    //     pointBackgroundColor: tailwindConfig().theme.colors.slate[300],
                    //     clip: 20,
                    // },
                ],
            }
        },
    },
}
</script>
