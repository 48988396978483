<script setup>
import { computed } from 'vue'
import { ComboboxEmpty } from 'radix-vue'
import { cn } from '@/lib/utils'

const props = defineProps({
    asChild: { type: Boolean, required: false },
    as: { type: null, required: false },
    class: { type: null, required: false },
})

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
})
</script>

<template>
    <ComboboxEmpty v-bind="delegatedProps" :class="cn('py-6 text-center text-sm', props.class)">
        <slot />
    </ComboboxEmpty>
</template>
