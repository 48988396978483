<template>
    <ModalBasic v-if="action" :title="action.label" :modal-open="isOpen" @close-modal="onActionModalClose">
        <template v-if="action.validated && validation === null">
            <div class="p-4">
                <span>Validating...</span>
            </div>
        </template>

        <template v-if="isProcessing === true">
            <div class="p-4">
                <span>Performing actions, this may take a moment...</span>
            </div>
        </template>

        <template v-if="action.validated && validation !== null && validation.isValid === false">
            <div class="px-5 py-6">
                <div v-for="group in invalidResponses()" :key="group.id" class="mb-2">
                    <div class="flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                        <div>
                            <span class="font-semibold text-slate-700">{{
                                action.validationMessages[group.id].title
                            }}</span>
                        </div>
                    </div>

                    <p class="mt-1 p-1">{{ action.validationMessages[group.id].message }}</p>

                    <ul class="p-1">
                        <li v-for="id in group.checked_ids" :key="id" class="ml-7 list-disc">
                            {{ findEntityName(creators, id) }}
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <template v-if="(!action.validated || (validation !== null && validation.isValid === true)) && !isProcessing">
            <div class="px-5 py-6">
                <div>
                    <template v-if="EntityType === 'Participant'">
                        <div v-if="creators.length > 1">
                            Performing action on {{ creators.length }} creators ({{
                                createParticipantNameSummary(creators, 5)
                            }})
                        </div>
                        <div v-if="creators.length === 1">
                            Performing action on {{ creators[0].participant.platform_user_name }}
                        </div>
                    </template>

                    <template v-if="EntityType === 'Payment'">
                        <div v-if="creators.length > 1 && EntityType === 'Payment'">
                            Performing action on {{ creators.length }} payments for ({{
                                createParticipantNameSummary(creators, 5)
                            }})
                        </div>
                        <div v-if="creators.length === 1">
                            Performing action on payment for {{ creators[0].participant.platform_user_name }}
                        </div>
                    </template>

                    <template v-if="EntityType === 'Message'">
                        <div v-if="creators.length > 1 && EntityType === 'Message'">
                            Performing action on {{ creators.length }} messages for ({{
                                createParticipantNameSummary(creators, 5)
                            }})
                        </div>
                        <div v-if="creators.length === 1">
                            Performing action on message for {{ creators[0].recipient_name }}
                        </div>
                    </template>
                </div>

                <div v-if="action.instructions" class="py-2 text-sm">
                    <p>{{ action.instructions }}</p>
                </div>

                <div v-if="action.options && action.options.length > 0" class="mt-4">
                    <div v-for="option in action.options" :key="option.label">
                        <div v-if="option.type === 'datetime'">
                            <p v-if="option.label">{{ option.label }}</p>
                            <div class="relative">
                                <flat-pickr
                                    v-model="option.value"
                                    class="form-input pl-9 font-medium text-slate-500 hover:text-slate-600 focus:border-slate-300"
                                    :config="options.datetime"
                                />
                                <div class="pointer-events-none absolute inset-0 right-auto flex items-center">
                                    <svg class="ml-3 h-4 w-4 fill-current text-slate-500" viewBox="0 0 16 16">
                                        <path
                                            d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div v-if="option.type === 'date'">
                            <p v-if="option.label">{{ option.label }}</p>
                            <div class="relative">
                                <flat-pickr
                                    v-model="option.value"
                                    class="form-input pl-9 font-medium text-slate-500 hover:text-slate-600 focus:border-slate-300"
                                    :config="options.date"
                                />
                                <div class="pointer-events-none absolute inset-0 right-auto flex items-center">
                                    <svg class="ml-3 h-4 w-4 fill-current text-slate-500" viewBox="0 0 16 16">
                                        <path
                                            d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex border-t border-slate-200 px-5 py-4">
                <div v-if="action.isUpdate" class="">
                    <button
                        class="btn-sm bg-red-500 text-white hover:bg-red-700"
                        @click="onActionModalPerformAction(true)"
                    >
                        Undo
                    </button>
                </div>

                <div class="ml-auto">
                    <button
                        class="btn-sm mr-2 border-slate-200 text-slate-600 hover:border-slate-300"
                        @click="onActionModalClose"
                    >
                        Cancel
                    </button>

                    <button
                        v-if="!action.showWarning"
                        class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600"
                        @click="onActionModalPerformAction(false)"
                    >
                        Save
                    </button>

                    <button
                        v-if="action.showWarning"
                        class="btn-sm bg-red-500 text-white hover:bg-red-700"
                        @click="onActionModalPerformAction(false)"
                    >
                        Save
                    </button>
                </div>
            </div>
        </template>

        <template v-else>
            <div v-if="!isProcessing" class="flex border-t border-slate-200 px-5 py-4">
                <div class="ml-auto">
                    <button
                        class="btn-sm mr-2 border-slate-200 text-slate-600 hover:border-slate-300"
                        @click="onActionModalClose"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </template>
    </ModalBasic>
</template>

<script>
import _ from 'lodash'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import ModalBasic from '@/components/ModalBasic.vue'
import actionAPI from '@/utils/api/api.campaign.participant'
import organizationActionAPI from '@/utils/api/api.organization'
import { mapGetters } from 'vuex'

export default {
    name: 'CreatorActionModal',

    components: {
        flatPickr,
        ModalBasic,
    },

    props: {
        EntityType: {
            type: String,
        },
    },

    emits: ['reload', 'perform-action-start', 'perform-action-complete'],

    data() {
        return {
            isOpen: false,
            validation: null,
            isProcessing: false,
            options: {
                datetime: {
                    enableTime: true,
                    dateFormat: 'Z',
                    altFormat: 'F j, Y h:i K',
                    altInput: true,
                },
                date: {
                    enableTime: false,
                    dateFormat: 'Z',
                    altFormat: 'F j, Y',
                    altInput: true,
                },
            },
            action: null,
            creators: null,
        }
    },

    computed: {
        ...mapGetters(['selfActiveOrganizationId']),
    },

    methods: {
        getActionIds() {
            return _.map(this.creators, (entity) => {
                return this.getActionId(entity)
            })
        },

        getActionId(actionEntity) {
            if (this.EntityType === 'Participant') {
                return actionEntity.participant.id
            }
            if (this.EntityType === 'Payment') {
                return actionEntity.payment.id
            }
            if (this.EntityType === 'Message') {
                return actionEntity.id
            }
            return null
        },

        getEntityName(actionEntity) {
            if (!actionEntity) {
                return null
            }
            if (this.EntityType === 'Participant') {
                return actionEntity.participant?.platform_user_name
            }
            if (this.EntityType === 'Payment') {
                return actionEntity.participant?.platform_user_name
            }
            if (this.EntityType === 'Message') {
                return actionEntity.recipient_name
            }
            return null
        },

        findEntityName(creators, id) {
            if (this.EntityType === 'Participant') {
                return this.getEntityName(_.find(creators, (creator) => creator.participant.id === id))
            }
            if (this.EntityType === 'Payment') {
                return this.getEntityName(_.find(creators, (creator) => creator.payment.id === id))
            }
            if (this.EntityType === 'Message') {
                return this.getEntityName(_.find(creators, (message) => message.id === id))
            }
            return ''
        },

        createParticipantNameSummary(creators, maxNames) {
            let filteredCreators = []

            if (this.EntityType === 'Participant' || this.EntityType === 'Message') {
                filteredCreators = creators
            } else if (this.EntityType === 'Payment') {
                const added = []
                _.forEach(creators, (creator) => {
                    if (!_.includes(added, creator.participant.id)) {
                        added.push(creator.participant.id)
                        filteredCreators.push(creator)
                    }
                })
            }

            let names = ''
            let addedNames = maxNames

            if (maxNames > filteredCreators.length) {
                addedNames = filteredCreators.length
            }

            for (let i = 0; i < addedNames; i += 1) {
                names = `${names + this.getEntityName(filteredCreators[i])}, `
            }

            names = names.substring(0, names.length - 2)

            if (addedNames < filteredCreators.length) {
                names += '...'
            }

            return names
        },

        onActionModalClose() {
            this.isOpen = false
            this.action = null
        },

        onActionModalOpen(action, creators) {
            this.validation = null
            this.action = action

            if (this.action.options) {
                this.action.options = _.forEach(this.action.options, (option) => {
                    // eslint-disable-next-line no-param-reassign
                    option.value = null

                    if (option.type === 'date') {
                        // eslint-disable-next-line no-param-reassign
                        option.value = moment().startOf('day').format()
                    }

                    if (option.type === 'datetime') {
                        // eslint-disable-next-line no-param-reassign
                        option.value = moment().format()
                    }
                })
            }

            this.creators = creators

            if (this.action.validated === true) {
                if (this.action.validateLocally) {
                    this.onActionModalValidateLocally(this.action.validateLocally)
                } else {
                    this.onActionModalValidate()
                }
            }

            this.isOpen = true
        },

        invalidResponses() {
            if (this.validation && this.validation.responses) {
                return _.filter(this.validation.responses, (response) => !response.isValid)
            }

            return []
        },

        countInvalid() {
            let invalidCount = 0

            if (this.validation && this.validation.responses) {
                const invalidGroups = this.invalidResponses()
                _.forEach(invalidGroups, (group) => {
                    invalidCount += group.checked_ids.length
                })
            }

            return invalidCount
        },

        onActionModalValidateLocally(validation) {
            if (validation.ensurePropHasValue) {
                let areAllValid = true
                const validEntities = []
                const invalidEntities = []

                _.forEach(this.creators, (creator) => {
                    const val = _.get(creator, validation.ensurePropHasValue, null)
                    if (val) {
                        validEntities.push(this.getActionId(creator))
                    } else {
                        areAllValid = false
                        invalidEntities.push(this.getActionId(creator))
                    }
                })

                const result = {
                    isValid: areAllValid,
                    responses: [],
                }

                if (validEntities.length > 0) {
                    result.responses.push({
                        id: this.EntityType === 'Participant' ? 'valid-participants' : 'valid-payments',
                        isValid: true,
                        checked_ids: validEntities,
                    })
                }

                if (invalidEntities.length > 0) {
                    result.responses.push({
                        id: this.EntityType === 'Participant' ? 'invalid-participants' : 'invalid-payments',
                        isValid: false,
                        checked_ids: invalidEntities,
                    })
                }

                this.validation = result
            }
        },

        async onActionModalValidate() {
            const actionIds = this.getActionIds()

            if (this.EntityType === 'Message') {
                const validationResponse = await organizationActionAPI.validateOrganizationAction(
                    this.$axios,
                    this.selfActiveOrganizationId,
                    this.action.id,
                    actionIds,
                    null,
                    this.EntityType,
                )
                this.validation = validationResponse.value.validation
            } else {
                const validationResponse = await actionAPI.validateAction(
                    this.$axios,
                    this.$route.params.supercampaign_id,
                    this.$route.params.campaign_id,
                    this.action.id,
                    actionIds,
                    null,
                    this.EntityType,
                )
                this.validation = validationResponse.value.validation
            }
        },

        async onActionModalPerformAction(isUndo) {
            this.$emit('perform-action-start')

            let options = null

            if (this.action.options && !isUndo) {
                options = _.map(this.action.options, (option) => ({
                    id: option.id,
                    value: option.value,
                }))
            }

            const actionIds = this.getActionIds()

            this.isProcessing = true

            if (this.EntityType === 'Message') {
                await organizationActionAPI.performOrganizationAction(
                    this.$axios,
                    this.selfActiveOrganizationId,
                    this.action.id,
                    actionIds,
                    options,
                    this.EntityType,
                )
            } else {
                await actionAPI.performAction(
                    this.$axios,
                    this.$route.params.supercampaign_id,
                    this.$route.params.campaign_id,
                    this.action.id,
                    actionIds,
                    options,
                    this.EntityType,
                )
            }

            this.isProcessing = false

            this.isOpen = false
            this.$emit('reload')
            this.action = null
            this.$emit('perform-action-complete')
        },
    },
}
</script>
