<script setup>
import { cn } from '@/lib/utils'
import { ScrollAreaScrollbar, ScrollAreaThumb } from 'radix-vue'
import { computed } from 'vue'

const props = defineProps({
    orientation: { type: String, required: false, default: 'vertical' },
    forceMount: { type: Boolean, required: false },
    asChild: { type: Boolean, required: false },
    as: { type: null, required: false },
    class: { type: null, required: false },
})

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
})
</script>

<template>
    <ScrollAreaScrollbar
        v-bind="delegatedProps"
        :class="
            cn(
                'flex touch-none select-none transition-colors',
                orientation === 'vertical' && 'h-full w-2.5 border-l border-l-transparent p-px',
                orientation === 'horizontal' && 'h-2.5 flex-col border-t border-t-transparent p-px',
                props.class,
            )
        "
    >
        <ScrollAreaThumb class="relative flex-1 rounded-full bg-border" />
    </ScrollAreaScrollbar>
</template>
