<script setup>
import { z } from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import { notify } from '@kyvg/vue3-notification'
import { watch, computed } from 'vue'

import { axios } from '@/utils/axios'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { Separator } from '@/components/ui/separator'
import campaignKeysAPI from '@/utils/api/api.campaign.keys'

const props = defineProps({
    supercampaignId: {
        type: String,
        required: true,
    },
    organizationGameId: {
        type: String,
        required: true,
    },
    mode: {
        type: String,
        required: true,
    },
    editGroup: {
        type: Object,
        required: false,
    },
})

const emit = defineEmits(['keyGroupCreated', 'keyGroupEdited'])

const formSchema = z.object({
    title: z.string().min(1, { message: 'Title is required' }).max(250),
    type: z.enum(['game', 'dlc']),
    description: z.string().min(1, { message: 'Description is required' }),
    creator_title: z.string().max(250),
})

const validationSchema = toTypedSchema(formSchema)

const form = useForm({
    validationSchema,
})

const currentMode = computed(() => props.mode)
const currentEditGroup = computed(() => props.editGroup)

const setDefaultValues = () => {
    if (currentMode.value === 'edit' && currentEditGroup.value) {
        form.resetForm()
        form.setFieldValue('title', currentEditGroup.value.title ? currentEditGroup.value.title : '')
        form.setFieldValue('description', currentEditGroup.value.description ? currentEditGroup.value.description : '')
        form.setFieldValue('type', currentEditGroup.value.type ? currentEditGroup.value.type : '')
        form.setFieldValue(
            'creator_title',
            currentEditGroup.value.creator_title ? currentEditGroup.value.creator_title : '',
        )
    } else {
        form.resetForm()
        form.setFieldValue('title', '')
        form.setFieldValue('description', '')
        form.setFieldValue('type', '')
        form.setFieldValue('creator_title', '')
    }
}

watch(currentMode, () => {
    setDefaultValues()
})

watch(currentEditGroup, () => {
    setDefaultValues()
})

const onSubmit = form.handleSubmit((values) => {
    if (currentMode.value === 'add') {
        campaignKeysAPI
            .addKeyGroup(
                axios,
                props.supercampaignId,
                values.title,
                values.description,
                values.type,
                values.creator_title,
                props.organizationGameId,
            )
            .then((addGroupResponse) => {
                if (addGroupResponse.success) {
                    const newGroup = addGroupResponse.value.group
                    newGroup.keys = []

                    emit('keyGroupCreated', newGroup)
                    notify({
                        title: 'Game Key Group Added',
                        text: `Your changes have been saved.`,
                        type: 'success',
                    })
                }
            })
    } else if (currentMode.value === 'edit') {
        campaignKeysAPI
            .editKeyGroup(
                axios,
                props.supercampaignId,
                currentEditGroup.value.id,
                values.title,
                values.description,
                values.type,
                values.creator_title,
            )
            .then((editGroupResponse) => {
                if (editGroupResponse.success) {
                    emit('keyGroupEdited', {
                        title: values.title,
                        description: values.description,
                        type: values.type,
                        creator_title: values.creator_title,
                    })
                    notify({
                        title: 'Game Key Group Edited',
                        text: `Your changes have been saved.`,
                        type: 'success',
                    })
                }
            })
    }
})
</script>

<template>
    <Dialog>
        <DialogContent>
            <DialogHeader>
                <DialogTitle v-if="props.mode == 'add'" class="pb-3">Add game key group</DialogTitle>
                <DialogTitle v-if="props.mode == 'edit'" class="pb-3">Edit game key group</DialogTitle>
                <Separator class="mb-2" />
                <DialogDescription>
                    Key pools are used to group together sets of keys, making it possible to differentiate between
                    versions of a game, or DLC packs.
                </DialogDescription>
            </DialogHeader>
            <form @submit="onSubmit">
                <FormField v-slot="{ componentField }" name="type">
                    <FormItem class="pb-4">
                        <FormLabel class="font-bold">Type of game key</FormLabel>
                        <Select v-bind="componentField">
                            <FormControl>
                                <SelectTrigger>
                                    <SelectValue placeholder="Select type..." />
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectItem value="game">Game</SelectItem>
                                    <SelectItem value="dlc">DLC</SelectItem>
                                </SelectGroup>
                            </SelectContent>
                            <FormMessage />
                        </Select>
                    </FormItem>
                </FormField>
                <FormField v-slot="{ componentField }" name="title">
                    <FormItem class="pb-4">
                        <FormLabel class="font-bold">Title</FormLabel>
                        <FormControl>
                            <Input type="text" v-bind="componentField"></Input>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                </FormField>

                <FormField v-slot="{ componentField }" name="description">
                    <FormItem class="pb-8">
                        <FormLabel class="font-bold">Description</FormLabel>
                        <FormControl>
                            <Input type="text" v-bind="componentField"></Input>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                </FormField>
                <FormField v-slot="{ componentField }" name="creator_title">
                    <FormItem class="pb-8">
                        <FormLabel class="font-bold">Creator Title</FormLabel>
                        <FormControl>
                            <Input
                                type="text"
                                v-bind="componentField"
                                placeholder="Displayed to the creator when retrieving key (optional)"
                            ></Input>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                </FormField>
                <Separator class="mb-3" />
                <DialogFooter class="sm:justify-end">
                    <DialogClose as-child>
                        <Button type="button" variant="secondary">Close</Button>
                    </DialogClose>
                    <Button v-if="props.mode == 'add'" type="submit">Add key group</Button>
                    <Button v-if="props.mode == 'edit'" type="submit">Edit key group</Button>
                </DialogFooter>
            </form>
        </DialogContent>
    </Dialog>
</template>
