export default {
    strict: true,
    state: {
        cancelTokens: [],
    },
    getters: {
        cancelTokens(state) {
            return state.cancelTokens
        },
    },
    /* eslint-disable no-param-reassign */
    mutations: {
        ADD_CANCEL_TOKEN(state, { endpoint, token }) {
            state.cancelTokens.push({ endpoint, token })
        },
        CLEAR_CANCEL_TOKENS(state) {
            state.cancelTokens = []
        },
        CLEAR_CANCEL_TOKENS_BY_ENDPOINT(state, inputEndpoint) {
            state.cancelTokens = state.cancelTokens.filter(({ endpoint }) => endpoint !== inputEndpoint)
        },
        CLEAR_CANCEL_TOKENS_BY_PARTIAL_ENDPOINT(state, partialEndpoint) {
            state.cancelTokens = state.cancelTokens.filter(({ endpoint }) => !endpoint.includes(partialEndpoint))
        },
        CLEAR_CANCEL_TOKENS_BY_REGEX(state, regex) {
            state.cancelTokens = state.cancelTokens.filter(({ endpoint }) => !regex.test(endpoint))
        },
    },
    /* eslint-enable no-param-reassign */
    actions: {
        CANCEL_PENDING_REQUESTS(context) {
            // Cancel all request where a token exists
            context.state.cancelTokens.forEach(({ token }) => {
                if (token.cancel) {
                    token.cancel()
                }
            })

            // Reset the cancelTokens store
            context.commit('CLEAR_CANCEL_TOKENS')
        },

        CANCEL_PENDING_REQUESTS_BY_ENDPOINT(context, inputEndpoint) {
            // Cancel all request where a token exists
            context.state.cancelTokens.forEach(({ endpoint, token }) => {
                if (inputEndpoint === endpoint) {
                    if (token.cancel) {
                        token.cancel()
                    }
                }
            })

            // Reset the cancelTokens store
            context.commit('CLEAR_CANCEL_TOKENS_BY_ENDPOINT', inputEndpoint)
        },

        // Cancel a request by given a partial endpoint
        CANCEL_PENDING_REQUESTS_BY_PARTIAL_ENDPOINT(context, partialEndpoint) {
            // Cancel all request where a token exists
            context.state.cancelTokens.forEach(({ endpoint, token }) => {
                if (endpoint.includes(partialEndpoint)) {
                    if (token.cancel) {
                        token.cancel()
                    }
                }
            })

            // Reset the cancelTokens store
            context.commit('CLEAR_CANCEL_TOKENS_BY_PARTIAL_ENDPOINT', partialEndpoint)
        },

        // New action: Cancel requests by regex match
        CANCEL_PENDING_REQUESTS_BY_REGEX(context, regexPattern) {
            const regex = new RegExp(regexPattern)

            // Cancel all requests where the endpoint matches the regex pattern
            context.state.cancelTokens.forEach(({ endpoint, token }) => {
                if (regex.test(endpoint)) {
                    if (token.cancel) {
                        token.cancel()
                    }
                }
            })

            // Reset the cancelTokens store
            context.commit('CLEAR_CANCEL_TOKENS_BY_REGEX', regex)
        },
    },
}
