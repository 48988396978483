<script setup>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { axios } from '@/utils/axios'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs'
import ReportTable from '@/partials/tables/ReportTable.vue'
import ContentTable from '@/partials/tables/ContentTable.vue'
import ModalBlankWide from '@/components/ModalBlankWide.vue'
import reportsAPI from '@/utils/api/api.reports'
import CreatorAggregationChart from '@/partials/charts/CreatorAggregationChart.vue'

const route = useRoute()
const store = useStore()
const activePlatformId = ref('twitch')
const activeTab = ref('creators')

const props = defineProps({
    supercampaign: {
        type: Object,
        default: null,
    },
    campaignsById: {
        type: Object,
        default: null,
    },
})

// State
const state = ref({
    isLoaded: false,
    has_utm_data: false,
    has_mention_data: false,
    searchText: '',
    modals: {
        clicksList: {
            is_open: false,
            participant: null,
            participant_id: null,
            campaign_id: null,
            supercampaign_id: null,
            paging: {
                perPage: 10,
                totalPages: 0,
                currentPage: 1,
                hasNext: false,
                hasPrevious: false,
            },
            clicks: null,
        },
        mentionsList: {
            is_open: false,
            participant: null,
            participant_id: null,
            campaign_id: null,
            supercampaign_id: null,
            paging: {
                perPage: 10,
                totalPages: 0,
                currentPage: 1,
                hasNext: false,
                hasPrevious: false,
            },
            mentions: null,
        },
    },
})

const report = ref(null)
const creators = ref([])
const metrics = ref(null)
const keywords = ref(null)

// Table state
const defaultPaging = {
    perPage: 25,
    currentPage: 1,
    totalPages: 1,
    sort: [
        {
            field: 'summary.content_count',
            direction: 'desc',
            source: 'default',
        },
    ],
    totalRecords: 0,
}
const paging = ref({ ...defaultPaging })

// Computed
const campaign = computed(() => props.campaignsById[route.params.campaign_id])

async function getReport(isReset) {
    // Cancel any pending requests
    store.dispatch('CANCEL_PENDING_REQUESTS_BY_REGEX', '^/supercampaigns/[^/]+/campaigns/[^/]+/report$')

    if (isReset) {
        paging.value = { ...defaultPaging }
        state.value.searchText = ''
    }

    const campaignId = route.params.campaign_id
    state.value.isLoaded = !isReset
    state.value.has_utm_data = false
    state.value.has_mention_data = false

    const pagingParams = {
        sort: paging.value.sort,
        sortby: paging.value.sort[0]?.field,
        sortdir: paging.value.sort[0]?.direction,
        perPage: paging.value.perPage,
        currentPage: paging.value.currentPage,
        filters: state.value.searchText ? [{ id: 'name', value: state.value.searchText }] : [],
    }

    if (props.supercampaign.payload.is_demo !== true) {
        if (campaign.value.type === 'key-distribution' && activePlatformId.value === 'twitch') {
            pagingParams.filters.push({ id: 'min-duration', value: 60 * 60 })
        }
        if (campaign.value.type === 'key-distribution' && activePlatformId.value === 'youtube') {
            pagingParams.filters.push({ id: 'min-views', value: 1 })
        }
    }

    const reportResult = await reportsAPI.getCampaignReport(
        axios,
        props.supercampaign.id,
        campaignId,
        activePlatformId.value,
        pagingParams,
    )

    if (reportResult.success) {
        report.value = reportResult.value
        metrics.value = report.value.data.aggregates

        creators.value = report.value.items
        state.value.has_utm_data = report.value.data.has_utm_data
        state.value.has_mention_data = report.value.data.has_mention_data
        keywords.value = report.value.data.keywords

        paging.value = {
            ...paging.value,
            totalRecords: report.value.paging.totalRecords,
            totalPages: Math.ceil(report.value.paging.totalRecords / paging.value.perPage),
        }

        // Handle special case for game sponsorship overdelivery
        if (campaign.value.type === 'game-sponsorship' && activePlatformId.value === 'twitch') {
            creators.value = creators.value.map((creator) => {
                if (creator.summary.over_delivery < 0 && creator.summary.over_delivery > -0.1) {
                    return {
                        ...creator,
                        summary: {
                            ...creator.summary,
                            sum_duration_seconds: creator.summary.target_duration_seconds,
                        },
                    }
                }
                return creator
            })
        }

        state.value.isLoaded = true
    }
}

function onPlatformClick(newPlatformId) {
    activePlatformId.value = newPlatformId
    getReport(true)
}

function onSearch(value) {
    state.value.searchText = value
    paging.value.currentPage = 1
    getReport(false)
}

function onPagingChange(newPaging) {
    paging.value = { ...paging.value, ...newPaging }
    getReport(false)
}

// Lifecycle
watch(
    () => route.params.campaign_id,
    () => {
        getReport(true)
    },
    { immediate: true },
)

const platformIds = ref(['twitch', 'youtube', 'tiktok', 'twitter', 'instagram'])
</script>

<template>
    <div>
        <ModalBlankWide
            id="clicks-modal"
            :modal-open="state.modals.clicksList.is_open"
            @close-modal="state.modals.clicksList.is_open = false"
        >
            <div class="relative">
                <div v-if="activePlatformId === 'twitch'">
                    <!-- Clickthru chart -->
                </div>

                <table class="w-full table-auto">
                    <!-- Table header -->
                    <thead class="rounded-sm bg-slate-50 text-xs uppercase text-slate-400">
                        <tr>
                            <!-- <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-1/5">
                            <div class="font-semibold text-left">Identifier</div>
                        </th> -->
                            <th class="w-1/3 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="flex text-left">
                                    <span class="mr-2 font-semibold">Referrer</span>
                                </div>
                            </th>
                            <th class="w-1/3 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="flex text-left">
                                    <span class="mr-2 font-semibold">Location</span>
                                </div>
                            </th>

                            <th class="w-1/4 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left font-semibold">Hits</div>
                            </th>
                            <th class="w-1/4 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="flex text-left">
                                    <span class="mr-2 font-semibold">Time</span>
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <!-- Table body -->
                    <tbody class="divide-y divide-slate-200 border-b border-slate-200 text-sm">
                        <tr v-if="!state.modals.clicksList.clicks || !state.modals.clicksList.clicks.length">
                            <td class="px-5 py-3 text-center" colspan="10">
                                <p>There are no clicks to display</p>
                            </td>
                        </tr>

                        <!-- Real table -->
                        <tr v-for="clickthrough in state.modals.clicksList.clicks" v-else :key="clickthrough.referrer">
                            <!-- <td class="px-2 first:pl-5 last:pr-5 py-3.5 whitespace-nowrap md:w-1/3 flex">
                            {{ clickthrough.fingerprint }}
                        </td> -->

                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
                                <div class="text-left">
                                    {{ clickthrough.referrer }}
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
                                <div class="text-left">
                                    {{ getLocation(clickthrough.geolocation) }}
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
                                <div class="text-left">
                                    {{ clickthrough.hits }}
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
                                <div class="text-left">
                                    {{ $moment.utc(clickthrough.created_at).format('MM/DD/YYYY hh:mm A') }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="flex justify-between px-5 py-3">
                    <div>
                        <button
                            :disabled="state.modals.clicksList.paging.hasPrevious === false"
                            class="btn text-white enabled:bg-indigo-500 enabled:hover:bg-indigo-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                            @click="changePage('clicksList', 'previous')"
                        >
                            <font-awesome-icon :icon="['fas', 'arrow-left']" class="mr-2 h-3 w-3" />
                            <span>Previous</span>
                        </button>
                    </div>
                    <div>
                        <button
                            :disabled="state.modals.clicksList.paging.hasNext === false"
                            class="btn text-white enabled:bg-indigo-500 enabled:hover:bg-indigo-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                            @click="changePage('clicksList', 'next')"
                        >
                            <span>Next</span>
                            <font-awesome-icon :icon="['fas', 'arrow-right']" class="ml-2 h-3 w-3" />
                        </button>
                    </div>
                </div>
            </div>
        </ModalBlankWide>

        <ModalBlankWide
            id="mentions-modal"
            :modal-open="state.modals.mentionsList.is_open"
            @close-modal="state.modals.mentionsList.is_open = false"
        >
            <div class="relative">
                <table class="w-full table-auto">
                    <!-- Table header -->
                    <thead class="rounded-sm bg-slate-50 text-xs uppercase text-slate-400">
                        <tr>
                            <th class="w-1/6 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="flex text-left">
                                    <span class="mr-2 font-semibold">Time Offset</span>
                                </div>
                            </th>
                            <th class="w-1/3 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="flex text-left">
                                    <span class="mr-2 font-semibold">What they said</span>
                                </div>
                            </th>
                            <th class="w-1/4 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left font-semibold">Link</div>
                            </th>
                        </tr>
                    </thead>

                    <!-- Table body -->
                    <tbody class="divide-y divide-slate-200 border-b border-slate-200 text-sm">
                        <tr v-if="!state.modals.mentionsList.mentions || !state.modals.mentionsList.mentions.length">
                            <td class="px-5 py-3 text-center" colspan="10">
                                <p>There are no mentions to display</p>
                            </td>
                        </tr>

                        <!-- Real table -->
                        <tr v-for="mention in filteredMentions" v-else :key="mention.link">
                            <td class="w-1/6 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left">
                                    {{
                                        $filters.humanizeDurationShort(Math.round(mention.captions.start / 1000) * 1000)
                                    }}
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left">
                                    {{ mention.captions.text }}
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
                                <div class="text-left">
                                    <a class="font-medium text-indigo-500" :href="mention.link" target="blank">{{
                                        mention.link
                                    }}</a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="flex justify-between px-5 py-3">
                    <div>
                        <button
                            :disabled="state.modals.mentionsList.paging.currentPage === 0"
                            class="btn text-white enabled:bg-indigo-500 enabled:hover:bg-indigo-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                            @click="changePage('mentionsList', 'previous')"
                        >
                            <font-awesome-icon :icon="['fas', 'arrow-left']" class="mr-2 h-3 w-3" />
                            <span>Previous</span>
                        </button>
                    </div>
                    <div>
                        <button
                            :disabled="
                                state.modals.mentionsList.paging.currentPage + 1 >=
                                state.modals.mentionsList.paging.totalPages
                            "
                            class="btn text-white enabled:bg-indigo-500 enabled:hover:bg-indigo-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                            @click="changePage('mentionsList', 'next')"
                        >
                            <span>Next</span>
                            <font-awesome-icon :icon="['fas', 'arrow-right']" class="ml-2 h-3 w-3" />
                        </button>
                    </div>
                </div>
            </div>
        </ModalBlankWide>

        <div class="col-span-full">
            <Tabs v-model="activeTab">
                <div class="mb-4 flex space-x-2">
                    <div class="flex items-center justify-center">
                        <div
                            class="inline-flex rounded bg-slate-200 p-1 shadow-sm hover:shadow-sm focus:shadow-sm"
                            role="group"
                        >
                            <button
                                v-for="platformId in platformIds"
                                :key="platformId"
                                class="flex rounded px-4 py-2.5 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                :class="{ 'bg-white': activePlatformId === platformId }"
                                @click.prevent="onPlatformClick(platformId)"
                            >
                                <font-awesome-icon
                                    class="h-4 w-4"
                                    :class="`text-${platformId}`"
                                    :icon="['fab', platformId]"
                                />
                            </button>
                        </div>
                    </div>

                    <TabsList class="rounded border border-slate-200 bg-slate-200">
                        <TabsTrigger value="overview" class="h-full px-5 py-2.5 leading-none">Overview</TabsTrigger>
                        <TabsTrigger value="creators" class="h-full px-5 py-2.5 leading-none">Creators</TabsTrigger>
                        <TabsTrigger value="content" class="h-full px-5 py-2.5 leading-none">Content</TabsTrigger>
                    </TabsList>

                    <div class="flex grow justify-end"></div>
                </div>

                <TabsContent value="overview">
                    <div>
                        <CreatorAggregationChart
                            :aggregation-data="report?.summary?.demographics"
                            :timeseries-data="report?.summary?.timeseries"
                            :summary="report?.summary?.aggregates"
                            :is-loading="!report"
                        />
                    </div>
                </TabsContent>

                <TabsContent value="creators">
                    <ReportTable
                        class="!mt-0"
                        :platform-id="activePlatformId"
                        :creators="creators"
                        :is-loading="!state.isLoaded"
                        :paging="paging"
                        type="campaign"
                        preferences-namespace="campaignReport"
                        @paging-change="onPagingChange"
                        @search="onSearch"
                    />
                </TabsContent>

                <TabsContent value="content">
                    <div class="rounded border border-slate-200 bg-white p-2">
                        <ContentTable
                            class="mt-0"
                            :report-id="report?._id"
                            :platform-id="activePlatformId"
                            :content-type="activePlatformId === 'twitch' ? 'stream' : 'video'"
                            day-range="day_30"
                        />
                    </div>
                </TabsContent>
            </Tabs>
        </div>
    </div>
</template>
