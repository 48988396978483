<template>
    <div v-if="isLoading" class="grid grid-cols-1 gap-4">
        <div class="col-span-1 md:col-span-3">
            <div class="flex flex-col rounded-sm border border-slate-200 bg-white p-4">
                <div class="mb-4 h-4 w-1/2 animate-pulse bg-gray-200"></div>
                <div class="h-[400px] w-full animate-pulse bg-gray-200"></div>
            </div>
        </div>
        <div
            v-for="chart in pieCharts"
            :key="chart.key"
            class="flex flex-col rounded-sm border border-slate-200 bg-white p-4"
        >
            <div class="mb-4 h-4 w-1/2 animate-pulse bg-gray-200"></div>
            <div class="m-auto h-[280px] w-[280px] animate-pulse rounded-full bg-gray-200"></div>
        </div>
    </div>

    <div v-else-if="!aggregationData" class="text-center">
        <!-- <p class="text-lg">No data available</p> -->
    </div>

    <div v-else>
        <div class="grid grid-cols-6 gap-3">
            <div v-for="field in summaryMetrics" :key="field.key">
                <div class="rounded rounded border border-slate-200 bg-white px-4 py-3">
                    <div class="text-xs font-medium text-slate-500">{{ field.label }}</div>
                    <div class="text-xl font-semibold text-slate-700">{{ field.value }}</div>
                </div>
            </div>
        </div>

        <!-- <div class="mt-4 flex justify-end">
            <Tabs v-model="activeTab" default-value="hourly">
                <TabsList class="bg-slate-200">
                    <TabsTrigger class="py-1.5 leading-none" value="hourly">Hourly</TabsTrigger>
                    <TabsTrigger class="py-1.5 leading-none" value="daily">Daily</TabsTrigger>
                </TabsList>
            </Tabs>
        </div> -->

        <!-- Modified Combined Chart -->
        <div v-if="timeseriesData" class="mt-2">
            <div class="rounded-sm border border-slate-200 bg-white p-4">
                <h2 class="mb-4 text-sm font-semibold">Content Timeline</h2>
                <div ref="chartContainer" class="chart-container h-[400px] w-full">
                    <Line :data="timeseriesChartData" :options="timeseriesOptions" />
                </div>
            </div>
        </div>

        <div class="mt-4 grid grid-cols-3 gap-4">
            <!-- Pie charts section remains the same -->
            <div v-for="chart in pieCharts" :key="chart.key" class="rounded-sm border border-slate-200 bg-white p-4">
                <h2 class="mb-4 text-sm font-semibold">{{ chart.title }}</h2>
                <div v-if="hasData(chart.key)">
                    <div class="chart-container h-[280px] w-full p-5">
                        <Pie :data="getChartData(chart)" :options="pieOptions" />
                    </div>

                    <div class="mt-4 flex flex-col space-y-1 text-sm">
                        <div
                            v-for="(value, index) in props.aggregationData[chart.key].values"
                            :key="value.key"
                            class="group relative flex h-7"
                        >
                            <div
                                class="absolute inset-0 mx-2 rounded-[2px]"
                                :style="`width: ${value.percentage}%`"
                                :class="`bg-${tailwindColors[index]}-100`"
                            />
                            <div class="relative flex w-full justify-between">
                                <div class="flex">
                                    <div
                                        class="mr-2 h-full w-2.5 rounded-[2px]"
                                        :class="`bg-${tailwindColors[index]}-400`"
                                    />
                                    <div class="my-auto leading-none">
                                        {{ chart.label(value.key) }}
                                    </div>
                                </div>
                                <div class="my-auto leading-none">
                                    {{ numeral(value.percentage / 100).format('0.0%') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <p v-else class="py-4 text-center text-gray-500">No data available for this chart</p>
            </div>
        </div>
    </div>

    <!-- We do this because Tailwind won't load in colors that are dynamically referenced -->
    <div
        class="bg-blue-100 bg-cyan-100 bg-green-100 bg-lime-100 bg-orange-100 bg-pink-100 bg-purple-100 bg-red-100 bg-teal-100 bg-yellow-100"
    ></div>
    <div
        class="bg-blue-400 bg-cyan-400 bg-green-400 bg-lime-400 bg-orange-400 bg-pink-400 bg-purple-400 bg-red-400 bg-teal-400 bg-yellow-400"
    ></div>
</template>

<script setup>
import _ from 'lodash'
import voca from 'voca'
import moment from 'moment'
import numeral from 'numeral'
import { computed, ref } from 'vue'
import {
    Chart,
    ArcElement,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
    TimeScale,
} from 'chart.js'
import { Pie, Line } from 'vue-chartjs'
import { tailwindConfig, hexToRGB } from '@/utils/Utils'
// import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'

Chart.register(ArcElement, Tooltip, Legend, LineElement, LinearScale, PointElement, CategoryScale, TimeScale)

const props = defineProps({
    aggregationData: {
        type: Object,
        required: false,
    },
    timeseriesData: {
        type: Object,
        required: false,
    },
    summary: {
        type: Object,
        required: false,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
})

const summaryMetrics = computed(() => {
    const fields = [
        {
            key: 'sum_creator_count',
            label: 'Creators',
            format: (value) => numeral(value).format('0,0'),
        },
        {
            key: 'sum_creator_delivered_count',
            label: 'Creators Delivered',
            format: (value) =>
                `${numeral(value).format('0,0')} (${numeral(
                    props.summary.sum_creator_delivered_count / props.summary.sum_creator_count,
                ).format('0%')})`,
        },
        {
            key: 'sum_duration_seconds',
            label: 'Hours Streamed',
            format: (value) => numeral(value / 60).format('0,0'),
        },
        {
            key: 'sum_minutes_watched',
            label: 'Hours Watched',
            format: (value) => numeral(value / 60).format('0,0'),
        },
        {
            key: 'sum_views',
            label: 'Views',
            format: (value) => numeral(value).format('0,0'),
        },
        {
            key: 'sum_unique_clicks',
            label: 'CTA Clicks',
            format: (value) => numeral(value).format('0,0'),
        },
    ]

    _.forEach(fields, (field) => {
        field.source_value = props.summary[field.key]

        if (_.has(props.summary, field.key)) {
            field.value = field.format(field.source_value)
            field.is_enabled = true
        }
    })

    return _.filter(fields, (item) => item.is_enabled)
})

const chartContainer = ref(null)
const activeTab = ref('daily')

const tailwindColors = ref([
    // 'indigo',
    'blue',
    'green',
    'red',
    'purple',
    'pink',
    'yellow',
    'orange',
    'cyan',
    'teal',
    'lime',
    'emerald',
    'sky',
    'violet',
    'fuchsia',
])

function createLinearGradient(color1, color2) {
    const canvas = document.querySelector('canvas')
    if (!canvas) return color1
    const ctx = canvas.getContext('2d')
    const gradient = ctx.createLinearGradient(0, 0, 0, canvas.clientHeight)
    gradient.addColorStop(0, color1)
    gradient.addColorStop(1, color2)
    return gradient
}

const countryLookup = new Intl.DisplayNames(undefined, { type: 'region' })
const languageLookup = new Intl.DisplayNames(undefined, { type: 'language' })

const pieCharts = [
    {
        title: 'Language Distribution',
        key: 'language',
        label: (value) => {
            try {
                return languageLookup?.of(value)
            } catch (error) {
                console.error(`Error doing language lookup`, error)
                return value
            }
        },
    },
    {
        title: 'Country Distribution',
        key: 'country',
        label: (value) => {
            try {
                return countryLookup?.of(value)
            } catch (error) {
                console.error(`Error doing country lookup`, error)
                return value
            }
        },
    },
    {
        title: 'Gender Distribution',
        key: 'gender',
        label: (value) => voca.capitalize(value),
    },
    {
        title: 'Age Distribution',
        key: 'age_range',
        label: (value) => voca.capitalize(value),
    },
    {
        title: 'Follower Distribution',
        key: 'followers',
        label: (value) => {
            switch (value) {
                case 'less_than_1000':
                    return '<1K'
                case '1000_plus':
                    return '1K-10K'
                case '10000_plus':
                    return '10K-100K'
                case '100000_plus':
                    return '100K-1M'
                case '1000000_plus':
                    return '1M+'
                default:
                    return value
            }
        },
    },
    {
        title: 'Channel Type Distribution',
        key: 'channel_type',
        label: (value) => voca.capitalize(value),
    },
]

const hasData = (key) => {
    return props.aggregationData[key]?.values?.length > 0
}

const pieOptions = computed(() => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
            position: 'bottom',
        },
        tooltip: {
            callbacks: {
                label: (context) => {
                    const label = context.label || ''
                    const value = context.formattedValue
                    const percentage = context.parsed
                    return `${label}: ${value} (${percentage.toFixed(2)}%)`
                },
            },
        },
    },
}))

const getChartData = (chart) => {
    if (!props.aggregationData || !props.aggregationData[chart.key]) {
        return { labels: [], datasets: [] }
    }

    const fieldData = props.aggregationData[chart.key]
    const data = fieldData.values.map((item) => item.percentage)
    const labels = fieldData.values.map((item) => (chart.label ? chart.label(item.key) : item.key))

    return {
        labels,
        datasets: [
            {
                data,
                backgroundColor: _(tailwindColors.value)
                    // .shuffle()
                    .map((color) => tailwindConfig().theme.colors[color][400])
                    .value(),
            },
        ],
    }
}

const formatMetric = (value, type) => {
    switch (type) {
        case 'percentage':
            return `${numeral(value).format('0.00')}%`
        case 'decimal':
            return numeral(value).format('0.00')
        case 'large':
            return numeral(value).format('0.0a').toUpperCase()
        default:
            return numeral(value).format('0,0')
    }
}

const dataSeries = computed(() => {
    return props.timeseriesData?.[activeTab.value]?.timeseries
})

const timeseriesOptions = computed(() => ({
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
        intersect: false,
        mode: 'index',
    },
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                usePointStyle: true,
            },
        },
        tooltip: {
            mode: 'index',
            intersect: false,
            callbacks: {
                title: (context) => {
                    if (context.length > 0) {
                        const timestamp = context[0].parsed.x
                        const nextTimestamp =
                            props.timeseriesData.interval === '1h'
                                ? moment(timestamp).add(1, 'hour')
                                : moment(timestamp).add(1, 'day')
                        return `${moment(timestamp).format('MMM D YYYY')} - ${nextTimestamp.format('MMM D YYYY')}`
                    }
                    return ''
                },
                label: (context) => {
                    const label = context.dataset.label || ''
                    const value = context.parsed.y

                    if (label === 'Views') {
                        return `${label}: ${formatMetric(value, 'large')}`
                    }
                    if (label === 'Content Count') {
                        return `${label}: ${formatMetric(value)}`
                    }
                    return `${label}: ${value}`
                },
            },
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                unit: activeTab.value === 'hourly' ? 'hour' : 'day',
                tooltipFormat: 'MMM D YYYY',
                displayFormats: {
                    hour: 'MMM D, h:mm A',
                    day: 'MMM D',
                },
            },
            grid: {
                display: true,
                drawOnChartArea: false,
                tickLength: 10,
                color: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[200])}, 0.75)`,
                tickColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[300])}, 1)`,
            },
            ticks: {
                maxRotation: 45,
            },
        },
        y: {
            type: 'linear',
            display: true,
            position: 'left',
            title: {
                display: false,
                text: 'Views',
            },
            grid: {
                tickLength: 10,
                color: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[200])}, 0.75)`,
                tickColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[300])}, 1)`,
            },
            ticks: {
                callback: (value) => formatMetric(value, 'large'),
                autoSkip: true,
                autoSkipPadding: 10,
            },
            min: 0,
        },
        y1: {
            type: 'linear',
            display: true,
            position: 'right',
            title: {
                display: false,
                text: 'Content Count',
            },
            suggestedMax: Math.round(
                _.get(_.maxBy(dataSeries.value, 'metrics.total_content'), 'metrics.total_content') * 5,
            ),
            grid: {
                drawOnChartArea: false,
            },
            ticks: {
                callback: (value) => formatMetric(value),
                autoSkip: true,
                autoSkipPadding: 10,
            },
            min: 0,
        },
    },
}))

const timeseriesChartData = computed(() => {
    if (!dataSeries.value) return { datasets: [] }

    const timestamps = dataSeries.value.map((entry) => new Date(entry.timestamp))

    return {
        labels: timestamps,
        datasets: [
            {
                label: 'Views',
                data: dataSeries.value.map((entry) => ({
                    x: new Date(entry.timestamp),
                    y: entry.metrics.total_views,
                })),
                type: 'line',
                borderColor: tailwindConfig().theme.colors.indigo[300],
                backgroundColor: () => {
                    return createLinearGradient(
                        `rgba(${hexToRGB(tailwindConfig().theme.colors.indigo[300])}, 0.5)`,
                        `rgba(${hexToRGB(tailwindConfig().theme.colors.indigo[100])}, 0.1)`,
                    )
                },
                fill: 'origin',
                borderWidth: 2,
                tension: 0.4,
                pointRadius: 0,
                yAxisID: 'y',
                order: 2,
            },
            {
                label: 'Content Count',
                data: dataSeries.value.map((entry) => ({
                    x: new Date(entry.timestamp),
                    y: entry.metrics.total_content,
                })),
                type: 'bar',
                backgroundColor: () => {
                    return createLinearGradient(
                        `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[700])}, 1)`,
                        `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[300])}, 1)`,
                    )
                },
                borderWidth: 0,
                yAxisID: 'y1',
                barThickness: 10,
                maxBarThickness: 16,
                borderRadius: 4,
                order: 1,
            },
        ],
    }
})
</script>
