<template>
    <AddGameKeyGroupModal
        :open="addGameKeyGroupModalOpen"
        @update:open="onAddGameKeyGroupModalClose"
        @key-group-created="refreshKeyGroup"
    ></AddGameKeyGroupModal>
    <AddGameKeysModal
        v-if="groups.length && consoles.length && marketplaces.length && regions.length"
        :groups="groups"
        :platforms="consoles"
        :marketplaces="marketplaces"
        :regions="regions"
        :open="addGameKeysModalOpen"
        @keys-added="refreshKeys"
        @update:open="onAddGameKeysModalClose"
    ></AddGameKeysModal>

    <ModalBasic
        :title="getFromDictionary(`keys.modal.type.${modal.type}`, 'title')"
        :modal-open="modal.isOpen"
        @close-modal="modal.isOpen = false"
    >
        <div v-if="modal.type === 'remove-keys'">
            <div class="px-5 py-4">
                <div class="my-4">
                    <p class="text-sm">
                        Are you sure you want to remove
                        <span class="font-semibold">
                            {{ removedKeys.length }}
                        </span>
                        {{ $pluralize('key', removedKeys.length) }} from this group?
                    </p>

                    <FormKit id="remove-keys" type="form" :actions="false" @submit="onKeyModalSubmit"> </FormKit>
                </div>
            </div>
        </div>

        <div v-if="modal.type === 'retrieve-keys'">
            <div class="px-5 py-4">
                <div class="my-4">
                    <div v-if="modal.state === 'fetch'">
                        <p class="my-5 text-sm">
                            Are you sure you want to retrieve
                            <span class="font-semibold">
                                {{ retrievedKeys.length }}
                            </span>
                            {{ $pluralize('key', retrievedKeys.length) }} from the system? Please provide a reason
                            below.
                        </p>
                        <FormKit id="retrieve-keys" type="form" :actions="false" @submit="onKeyModalSubmit">
                            <FormKit
                                v-model="retrieval_reason"
                                type="textarea"
                                name="retrieval_reason"
                                label="Reason for retrieving keys"
                                validation="required"
                            >
                            </FormKit>
                        </FormKit>
                    </div>
                    <div v-if="modal.state === 'view'">
                        <p class="'text-sm'">Here are your claimed key codes:</p>
                        <div class="my-3 flex">
                            <ul class="my-auto w-full rounded bg-slate-100 py-3 text-center">
                                <li
                                    v-for="key in keys.filter((key) => retrievedKeys.includes(key.id))"
                                    :key="key"
                                    class="font-medium"
                                >
                                    {{ key.code }}
                                    <!-- {{ JSON.stringify(key) }} -->
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="modal.type === 'remove-group'">
            <div class="px-5 py-4">
                <div class="my-4">
                    <p class="text-sm">Are you sure you want to remove this key group?</p>

                    <FormKit id="remove-group" type="form" :actions="false" @submit="onKeyModalSubmit"> </FormKit>
                </div>
            </div>
        </div>

        <div v-if="modal.type === 'view-keys'">
            <div class="px-5 py-4">
                <div v-if="viewedKey.retrieval_reason" class="mb-5">
                    <div class="text-xs font-semibold">Retrieval reason</div>
                    <p class="text-sm">{{ viewedKey.retrieval_reason }}</p>
                </div>

                <div class="my-3 flex">
                    <div class="my-auto w-full rounded border border-slate-200 bg-slate-100 py-3 text-center">
                        <p class="font-medium">
                            {{ viewedKey.code }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="modal.type !== 'view-keys' && !(modal.type === 'retrieve-keys' && modal.state === 'view')"
            class="border-t border-slate-200 px-5 py-4"
        >
            <div class="flex flex-wrap justify-end space-x-2">
                <button
                    class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                    @click="onModalClose(modal.type)"
                >
                    Cancel
                </button>

                <button
                    :form="modal.type"
                    :class="
                        ['remove-keys', 'remove-group'].includes(modal.type)
                            ? 'bg-red-500 text-white hover:bg-red-600'
                            : modal.type === 'retrieve-keys' && retrieval_reason === ''
                            ? 'pointer-events-none bg-slate-50 text-slate-400'
                            : 'bg-indigo-500 text-white hover:bg-indigo-600'
                    "
                    class="btn-sm"
                    :disabled="modal.type === 'retrieve-keys' && retrieval_reason === ''"
                >
                    {{ getFromDictionary(`keys.modal.type.${modal.type}`, 'actionLabel') }}
                </button>
            </div>
        </div>
    </ModalBasic>

    <div class="flex-1 md:flex">
        <GameSidebar :game="game">
            <div class="flex">
                <button
                    v-if="groups.length !== 0"
                    class="btn w-full bg-indigo-500 text-white hover:bg-indigo-600"
                    @click="() => (addGameKeysModalOpen = true)"
                >
                    <svg class="h-4 w-4 shrink-0 fill-current opacity-50" viewBox="0 0 16 16">
                        <path
                            d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"
                        />
                    </svg>
                    <span class="ml-2">Add Keys</span>
                </button>
            </div>

            <div class="mt-6">
                <div>
                    <div class="relative mb-3 flex h-8 justify-between border-b border-slate-200 pb-1.5">
                        <h4 class="my-auto text-sm font-semibold leading-none">Key Groups ({{ groups.length }})</h4>

                        <div class="flex">
                            <div
                                class="flex cursor-pointer rounded-sm bg-slate-200 p-1.5 text-slate-500 transition duration-200 hover:bg-slate-300 hover:text-slate-700"
                                @click="() => (addGameKeyGroupModalOpen = true)"
                            >
                                <font-awesome-icon :icon="['fas', 'plus']" class="m-auto h-3 w-3" />
                            </div>
                        </div>
                    </div>

                    <!-- <div @click="onSelectKeyGroup({ id: 'all' })" class="px-4 py-4  cursor-pointer" :class="menuItemSelected.id === 'all'? 'bg-white rounded-lg' : null">
                        <span class="font-semibold">All</span>
                        <span class="text-sm block">
                            {{ this.getUnclaimedCount(keys) }}/{{ keysByStatus['all'].length }} keys allocated
                        </span>
                    </div> -->
                </div>

                <template v-if="groups.length !== 0 && keysByStatus">
                    <div
                        v-for="group in groups"
                        :key="group.id"
                        class="cursor-pointer px-4 py-4"
                        :class="menuItemSelected.id === group.id ? 'rounded-lg bg-white' : null"
                        @click="onSelectKeyGroup(group)"
                    >
                        <span class="font-semibold">{{ group.title }}</span>
                        <span class="block text-sm"
                            >{{ getUnclaimedCount(group.keys) }}/{{ group.keys ? group.keys.length : 0 }} keys
                            available</span
                        >

                        <div class="mt-2 text-sm" :class="menuItemSelected.id === group.id ? 'block' : 'hidden'">
                            <!-- <button class="mr-4 font-medium text-indigo-500 hover:text-indigo-600">Edit</button> -->
                            <button
                                class="font-medium text-red-500 hover:text-red-600"
                                @click="removeKeyGroupModal(group.id)"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </template>
            </div>
        </GameSidebar>

        <div class="ml-8 grid flex-1 grid-cols-12 gap-6">
            <SkeletonGameKeyTable v-if="state.isLoading" />
            <KeyTable
                v-if="!state.isLoading"
                :keys="keysByStatus[menuItemSelected.id]"
                :keygroupid="menuItemSelected.id"
                :game="game"
                @remove-keys="removeKeyModal"
                @remove-group="removeKeyGroupModal"
                @retrieve-keys="retrieveKeyModal"
                @view-keys="viewKeyModal"
            >
            </KeyTable>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

import ModalBasic from '@/components/ModalBasic.vue'
import GameSidebar from '@/partials/sidebars/GameSidebar.vue'
import KeyTable from '@/partials/tables/KeyTable.vue'
import SkeletonGameKeyTable from '@/partials/skeletons/SkeletonGameKeyTable.vue'
import AddGameKeysModal from '@/partials/modals/GameKeyModal/AddGameKeysModal.vue'
import AddGameKeyGroupModal from '@/partials/modals/GameKeyModal/AddGameKeyGroupModal.vue'

export default {
    name: 'Key Management',

    components: {
        GameSidebar,
        KeyTable,
        SkeletonGameKeyTable,
        ModalBasic,
        AddGameKeysModal,
        AddGameKeyGroupModal,
    },
    props: {
        game: {
            type: Object,
        },
    },
    data() {
        return {
            keys: [],
            marketplaces: [],
            consoles: [],
            regions: [],
            groups: [],
            lookup: {},
            menuItemSelected: { id: 'all' },
            removedKeys: [],
            retrievedKeys: [],
            viewedKey: {},
            removedGroup: null,
            state: {
                isLoading: false,
            },
            modal: {
                isOpen: false,
                type: 'add-keys',
                state: '',
            },
            groupKeyValues: {
                group_id: null,
                keys: '',
            },
            groupValues: {},
            fromSchemaAddKeys: [],
            retrieval_reason: '',
            formSchemaKeyGroup: [
                {
                    $formkit: 'select',
                    name: 'type',
                    label: 'Type',
                    placeholder: 'Select type',
                    options: [
                        { label: 'Game', value: 'Game' },
                        { label: 'DLC', value: 'DLC' },
                    ],
                    validation: 'required',
                },
                {
                    $formkit: 'text',
                    name: 'title',
                    label: 'Title',
                    validation: 'required',
                },
                {
                    $formkit: 'text',
                    name: 'description',
                    label: 'Description',
                },
            ],
            addGameKeyGroupModalOpen: false,
            addGameKeysModalOpen: false,
        }
    },
    created() {
        this.state.isLoading = true
        this.getGameKeyGroups()
        this.state.isLoading = false
    },
    computed: {
        ...mapGetters(['getFromDictionary', 'self']),
        keysByStatus() {
            const keysByStatus = {
                all: this.keys,
            }
            _.forEach(this.groups, (group) => {
                keysByStatus[group.id] = group.keys
            })
            return keysByStatus
        },
        groupsById() {
            return _.keyBy(this.groups, 'id')
        },
    },
    methods: {
        getGameKeyGroups() {
            this.$axios
                .get(`/games/${this.$route.params.game_id}/keys/groups`)
                .then(({ data }) => {
                    this.consoles = data.payload.consoles
                    this.marketplaces = data.payload.marketplaces
                    this.regions = data.payload.regions
                    this.groups = data.payload.groups
                    this.keys = _.reduce(
                        data.payload.groups,
                        (results, group) => {
                            results.push(...group.keys)
                            return results
                        },
                        [],
                    )
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        getUnclaimedCount(keys) {
            return _.size(_.filter(keys, (key) => !key.claimed_at))
        },
        onSelectKeyGroup(group) {
            this.menuItemSelected = group
        },
        openKeyModal(type) {
            this.modal.type = type
            this.modal.isOpen = true
        },
        removeKeyModal({ selected }) {
            this.modal.type = 'remove-keys'
            this.removedKeys = selected
            this.modal.isOpen = true
        },
        retrieveKeyModal({ selected }) {
            this.modal.type = 'retrieve-keys'
            this.modal.state = 'fetch'
            this.retrievedKeys = selected
            this.modal.isOpen = true
        },
        removeKeyGroupModal(groupId) {
            if (groupId !== 'all') {
                this.modal.type = 'remove-group'
                this.removedGroup = groupId
                this.modal.isOpen = true
            }
        },
        viewKeyModal(key) {
            this.modal.type = 'view-keys'
            this.viewedKey = key
            this.modal.isOpen = true
        },
        onKeyModalSubmit() {
            if (this.modal.type === 'remove-keys') {
                this.$axios
                    .delete(`/games/${this.$route.params.game_id}/keys`, {
                        data: {
                            game_keys: this.removedKeys,
                        },
                    })
                    .then(() => {
                        this.keys = _.filter(this.keys, (key) => !_.includes(this.removedKeys, key.id))

                        _.forEach(this.groups, (group) => {
                            // eslint-disable-next-line no-param-reassign
                            group.keys = _.filter(group.keys, (key) => !_.includes(this.removedKeys, key.id))
                        })

                        this.removedKeys = []
                        this.modal.isOpen = false
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }

            if (this.modal.type === 'retrieve-keys') {
                this.$axios
                    .patch(`/games/${this.$route.params.game_id}/keys`, {
                        game_keys: this.retrievedKeys,
                        retrieval_reason: this.retrieval_reason,
                        operator_id: this.self.operator.id,
                    })
                    .then(() => {
                        this.getGameKeyGroups()

                        this.retrieval_reason = ''
                        this.modal.state = 'view'
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }

            if (this.modal.type === 'remove-group') {
                this.$axios
                    .delete(`/games/${this.$route.params.game_id}/keys/groups/${this.removedGroup}`)
                    .then(() => {
                        this.menuItemSelected = { id: 'all' }
                        this.modal.isOpen = false
                        this.groups = _.filter(this.groups, (group) => group.id !== this.removedGroup)
                        this.removedGroup = null
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
        onModalClose(modalType) {
            this.modal.isOpen = false

            if (modalType === 'add-keys') {
                this.groupKeyValues.group_id = null
                this.groupKeyValues.keys = ''
                this.$formkit.reset('add-keys')
            } else if (modalType === 'key-group') {
                this.groupValues = {}
                this.$formkit.reset('key-group')
            } else if (modalType === 'retrieve-keys') {
                this.retrieval_reason = ''
                this.$formkit.reset('retrieve-keys')
                this.modal.state = ''
            } else if (modalType === 'view-keys') {
                this.retrievedKeys = []
                this.viewedKey = {}
            }
        },

        refreshKeys(updatedKeys) {
            const keyGroup = _.find(this.groups, (item) => item.id === updatedKeys[0].group_id)
            if (!keyGroup) {
                console.log('No key group found')
            }
            this.keys.push(...updatedKeys)
            keyGroup.keys.push(...updatedKeys)
        },

        refreshKeyGroup(newGroup) {
            this.groups.push(newGroup)
        },

        onAddGameKeyGroupModalClose() {
            this.addGameKeyGroupModalOpen = false
        },

        onAddGameKeysModalClose() {
            this.addGameKeysModalOpen = false
        },
    },
}
</script>
