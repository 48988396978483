<template>
    <div class="mb-auto ml-auto mt-auto">
        <font-awesome-icon
            :icon="['fas', 'chevron-up']"
            class="-mb-1 h-2.5 w-2.5 opacity-25"
            :class="{
                'text-indigo-600 !opacity-100': column === sortBy && sortDir === 'asc',
                'group-hover:text-indigo-600 group-hover:!opacity-100': column === sortBy && sortDir === 'desc',
            }"
        />

        <font-awesome-icon
            :icon="['fas', 'chevron-down']"
            class="h-2.5 w-2.5 opacity-25 group-hover:text-slate-600 group-hover:!opacity-100"
            :class="{
                'text-indigo-600 !opacity-100': column === sortBy && sortDir === 'desc',
            }"
        />
    </div>
</template>

<script>
export default {
    name: 'ColumnSort',

    components: {},

    props: {
        sortBy: {
            type: String,
            default: '',
        },
        sortDir: {
            type: String,
            default: '',
        },
        column: {
            type: String,
            default: '',
        },
    },
}
</script>
