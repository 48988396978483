<script setup>
import _ from 'lodash'
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import CodeSelect from '@/components/CodeSelect.vue'

import {
    Select as SelectRoot,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'

const store = useStore()

const props = defineProps({
    platformId: {
        type: String,
        required: true,
    },
    isLoading: {
        type: Boolean,
        required: false,
    },
})

const emit = defineEmits(['filter-change', 'platform-change'])

const platformValues = _.map(['youtube', 'twitch', 'tiktok', 'twitter', 'instagram'], (platformValueId) => {
    return {
        id: platformValueId,
        label: store.getters.getFromDictionary(`platforms.toProper.${platformValueId}`),
    }
})

const platforms = ref(platformValues)

const filtersDefault = {
    game: null,
    tags: null,
    startAt: moment().subtract(1, 'days').startOf('day').toDate(),
    endAt: moment().subtract(1, 'days').endOf('day').toDate(),
}

const filters = ref(_.cloneDeep(filtersDefault))

const pickerConfig = {
    enableTime: true,
    dateFormat: 'Z',
    altFormat: 'F j, Y h:i K',
    altInput: true,
    maxDate: moment().toDate(),
}

const localPlatformId = ref(props.platformId)

const platformContentTypes = {
    twitch: [{ id: 'stream', label: 'Stream', isDefault: true }],
    youtube: [
        { id: 'video', label: 'Video', isDefault: true },
        { id: 'short', label: 'Short' },
        { id: 'stream', label: 'Stream' },
    ],
    tiktok: [{ id: 'video', label: 'Short', isDefault: true }],
    twitter: [{ id: 'post', label: 'Post', isDefault: true }],
    instagram: [{ id: 'post', label: 'Post', isDefault: true }],
}

const canGenerateReport = computed(() => {
    return (
        props.isLoading === false &&
        (filters.value.game || filters.value.tags?.length > 0) &&
        filters.value.startAt &&
        filters.value.endAt
        // && Math.abs(moment(filters.value.startAt).diff(moment(filters.value.endAt), 'days')) <= 7
    )
})

watch(localPlatformId, () => {
    filters.value.game = null
})

function reset() {
    const platformContentType = platformContentTypes[props.platformId]
    const defaultContentType = _.find(platformContentType, (contentType) => contentType.isDefault)

    filters.value = {
        ...filtersDefault,
        contentType: defaultContentType?.id,
    }
}

const onPlatformChange = (newPlatformId) => {
    const platformContentType = platformContentTypes[newPlatformId]
    const defaultContentType = _.find(platformContentType, (contentType) => contentType.isDefault)

    filters.value.contentType = defaultContentType?.id

    emit('platform-change', newPlatformId)
}

// Initialize contentType
reset()
</script>

<template>
    <div class="mb-4 rounded border border-slate-200 bg-white p-4">
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            <div>
                <label class="mb-1 block text-sm font-semibold">Platform</label>
                <SelectRoot v-model="localPlatformId" @update:model-value="onPlatformChange">
                    <SelectTrigger>
                        <SelectValue placeholder="Select a platform" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectItem v-for="platform in platforms" :key="platform.id" :value="platform.id">
                                {{ platform.label }}
                            </SelectItem>
                        </SelectGroup>
                    </SelectContent>
                </SelectRoot>
            </div>

            <div>
                <template v-if="['youtube', 'twitch'].includes(props.platformId)">
                    <label class="mb-1 block text-sm font-semibold">Game</label>
                    <CodeSelect
                        :key="platformId"
                        v-model="filters.game"
                        title=""
                        code-type="game"
                        :preload="true"
                        select-mode="single"
                        :max-items="5000"
                        search-placeholder="Search for a game"
                        :is-caret-enabled="true"
                        :platform="platformId"
                        :display-options="{
                            platform_id: platformId,
                            content_type: filters.contentType,
                            metrics_type: 'channels',
                        }"
                        no-results-text="All matches selected"
                        @selection-change="(item) => (filters.game = $lodash.first(item))"
                    />
                </template>
            </div>

            <div>
                <label class="mb-1 block text-sm font-semibold">Hashtag</label>
                <CodeSelect
                    v-model="filters.tags"
                    title=""
                    code-type="tag"
                    :preload="true"
                    select-mode="single"
                    :max-items="5000"
                    search-placeholder="Search for a tag"
                    :is-caret-enabled="true"
                    :platform="platformId"
                    :display-options="{
                        platform_id: platformId,
                        content_type: filters.contentType,
                        metrics_type: 'channels',
                        sort_by: `platforms.${props.platformId}.metrics.${filters.contentType}.channels`,
                        sort_dir: 'desc',
                    }"
                    id-field="name"
                    no-results-text="All matches selected"
                    @selection-change="(item) => (filters.tags = item)"
                />
            </div>

            <div>
                <label class="mb-1 block text-sm font-semibold">Start Date</label>
                <flatPickr
                    v-model="filters.startAt"
                    :config="pickerConfig"
                    placeholder="Start Date"
                    class="h-10 w-full rounded border border-slate-200 px-3.5 py-1 text-sm leading-none"
                />
            </div>

            <div>
                <label class="mb-1 block text-sm font-semibold">End Date</label>
                <flatPickr
                    v-model="filters.endAt"
                    :config="pickerConfig"
                    placeholder="End Date"
                    class="h-10 w-full rounded border border-slate-200 px-3.5 py-1 text-sm leading-none"
                />
            </div>

            <div>
                <label class="mb-1 block text-sm font-semibold">Content Type</label>
                <select
                    v-model="filters.contentType"
                    class="h-10 w-full rounded border border-slate-200 px-3.5 py-1 text-sm leading-none"
                >
                    <option v-for="type in platformContentTypes[platformId]" :key="type.id" :value="type.id">
                        {{ type.label }}
                    </option>
                </select>
            </div>

            <div></div>
            <div></div>

            <button
                :disabled="!canGenerateReport"
                class="btn mt-auto h-10 rounded bg-indigo-500 p-2 text-white enabled:hover:bg-indigo-600 disabled:opacity-50"
                @click="$emit('filter-change', filters)"
            >
                <font-awesome-icon
                    v-if="props.isLoading === true"
                    :icon="['fas', 'spinner-third']"
                    class="mr-1.5 h-3.5 w-3.5 animate-spin"
                />
                <span>Generate Report</span>
            </button>
        </div>
    </div>
</template>
