<template>
    <div class="h-full w-full">
        <canvas :id="canvasId" ref="canvas" :style="{ width: canvasWidth, height: 'auto' }" />
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watchEffect } from 'vue'
import _ from 'lodash'
import {
    Chart,
    BarController,
    BarElement,
    CategoryScale,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Tooltip,
    Legend,
} from 'chart.js'
import 'chartjs-adapter-moment'
import moment from 'moment'

// Import utilities
import { tailwindConfig, hexToRGB } from '@/utils/Utils'

Chart.register(
    BarController,
    BarElement,
    CategoryScale,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Tooltip,
    Legend,
)

export default {
    name: 'ContentTimeSeriesChart',

    props: {
        seriesData: {
            type: Array,
        },
        primary: {
            type: Object,
            key: 'views',
            label: 'Avg. Views',
        },
        secondary: {
            type: Object,
            key: 'count',
            label: 'Videos Posted',
        },
    },

    setup(props) {
        const canvas = ref(null)
        const canvasId = `content-timeseries-${Math.random().toString(36).substr(2, 9)}` // Unique ID for canvas element
        const canvasWidth = ref('100%')
        const legend = ref(null)

        let chart = null

        const handleResize = () => {
            if (chart) {
                chart.resize()
            }
        }

        onMounted(() => {
            const dates = []

            const primarySeries = {
                label: props.primary.label,
                data: [],
                fill: 'origin',
                // eslint-disable-next-line no-use-before-define
                backgroundColor: createLinearGradient(
                    `rgba(${hexToRGB(tailwindConfig().theme.colors.indigo[300])}, 0.5)`,
                    `rgba(${hexToRGB(tailwindConfig().theme.colors.indigo[100])}, 0.1)`,
                ),
                borderColor: tailwindConfig().theme.colors.indigo[300],
                pointBackgroundColor: tailwindConfig().theme.colors.indigo[300],
                tension: 0.4,
                pointRadius: 0,
                yAxisID: 'y',
                order: 2,
            }

            const secondarySeries = {
                label: props.secondary.label,
                data: [],
                // eslint-disable-next-line no-use-before-define
                backgroundColor: createLinearGradient(
                    `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[700])}, 1)`,
                    `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[300])}, 1)`,
                ),
                borderWidth: 0,
                yAxisID: 'y1',
                type: 'bar',
                barThickness: 10,
                maxBarThickness: 16,
                borderRadius: 4,
                order: 1,
            }

            let maxPrimaryMetric = 0
            let maxSecondaryMetric = 0

            _.forEach(props.seriesData, (data) => {
                dates.push(moment(data.date).format())

                // Find primary max
                if (data[props.primary.key] > maxPrimaryMetric) {
                    maxPrimaryMetric = data[props.primary.key]
                }

                primarySeries.data.push(Math.round(data[props.primary.key]))

                // Find secondary max
                if (data[props.secondary.key] > maxSecondaryMetric) {
                    maxSecondaryMetric = data[props.secondary.key]
                }

                secondarySeries.data.push(Math.round(data[props.secondary.key]))
            })

            const ctx = canvas.value

            chart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: dates,
                    datasets: [primarySeries, secondarySeries],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'month',
                                tooltipFormat: 'MMM D YYYY',
                                displayFormats: {
                                    month: 'MMM YY',
                                },
                            },
                            offset: false,
                            grid: {
                                tickLength: 10,
                                drawOnChartArea: false,
                                color: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[200])}, 0.75)`,
                                tickColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[300])}, 1)`,
                            },
                            // bounds: 'ticks',
                            // min: moment(firstItem.date).subtract(7, 'days').format(),
                            // max: moment(lastItem.date).add(7, 'days').format(),
                        },
                        y: {
                            position: 'left',
                            title: {
                                display: false,
                                text: props.primary.label,
                            },
                            // suggestedMax: Math.round((maxPrimaryMetric * 1.5)),
                            // suggestedMax: Math.round((maxPrimaryMetric * 1.5)),
                            grid: {
                                tickLength: 10,
                                color: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[200])}, 0.75)`,
                                tickColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[300])}, 1)`,
                            },
                            ticks: {
                                autoSkip: true,
                                autoSkipPadding: 10,
                            },
                            min: 0,
                        },
                        y1: {
                            position: 'right',
                            title: {
                                display: false,
                                text: props.secondary.label,
                            },
                            suggestedMax: Math.round(maxSecondaryMetric * 5),
                            grid: {
                                drawOnChartArea: false,
                            },
                            ticks: {
                                autoSkip: true,
                                autoSkipPadding: 10,
                            },
                            min: 0,
                        },
                    },
                    plugins: {
                        legend: {
                            display: true,
                            position: 'bottom',
                            // onClick: (e) => e.stopPropagation(),
                        },
                        tooltip: {
                            enabled: true,
                            mode: 'index',
                            intersect: false,
                            position: 'nearest',
                            callbacks: {
                                title: (context) => {
                                    if (context.length > 0) {
                                        const { dataIndex } = context[0]
                                        const currentDataPoint = props.seriesData[dataIndex]

                                        if (dataIndex < props.seriesData.length - 1) {
                                            const nextDataPoint = props.seriesData[dataIndex + 1]
                                            // eslint-disable-next-line no-use-before-define
                                            return formatDateRange(currentDataPoint.date, nextDataPoint.date)
                                        }
                                        const futureDate = moment(currentDataPoint.date).add(7, 'days').toDate()
                                        // eslint-disable-next-line no-use-before-define
                                        return formatDateRange(currentDataPoint.date, futureDate)
                                    }
                                    return ''
                                },
                                label: (context) => {
                                    const datasetLabel = context.dataset.label || ''
                                    const value = context.formattedValue
                                    return ` ${datasetLabel}: ${value}`
                                },
                            },
                        },
                    },
                },
            })

            window.addEventListener('resize', handleResize)

            watchEffect(() => {
                const element = document.getElementById(canvasId)
                if (element) {
                    canvasWidth.value = `${element.clientWidth}px`
                }
            })
        })

        onUnmounted(() => {
            window.removeEventListener('resize', handleResize)
            chart?.destroy()
        })

        function createLinearGradient(color1, color2) {
            const ctx = canvas.value.getContext('2d')
            const gradient = ctx.createLinearGradient(0, 0, 0, canvas.value.clientHeight)
            gradient.addColorStop(0, color1)
            gradient.addColorStop(1, color2)
            return gradient
        }

        // Format date range
        function formatDateRange(startDate, endDate) {
            return `${moment(startDate).format('MMM D YYYY')} - ${moment(endDate).format('MMM D YYYY')}`
        }

        return {
            canvas,
            legend,
            canvasId,
            canvasWidth,
        }
    },
}
</script>
