import _ from 'lodash'

export default {
    state: {
        tables: {
            discovery: {
                // rowsPerPage
                // platform
                // columns
            },
            creatorContent: {},
        },
    },

    mutations: {
        'preferences:set': (state, { key, value }) => {
            _.set(state, key, value)
        },
    },

    getters: {
        preferences(state) {
            return state
        },
    },
}
