import axios from 'axios'
import store from '../store'

const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_APP_ROOT_API,
    headers: {
        'Content-Type': 'application/json',
    },
    validateStatus: (status) => (status >= 200 && status < 300) || status === 401,
})

axiosInstance.interceptors.request.use(
    (config) => {
        if (!config.noCancel) {
            //  Generate cancel token source
            const source = axios.CancelToken.source()

            // Set cancel token on this request
            // eslint-disable-next-line no-param-reassign
            config.cancelToken = source.token

            // Add to vuex to make cancellation available from anywhere
            store.commit('ADD_CANCEL_TOKEN', {
                token: source,
                endpoint: config.url,
            })
        }

        return config
    },
    (error) => Promise.reject(error),
)

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (axios.isCancel(error)) {
            // eslint-disable-next-line no-param-reassign
            error.is_cancelled = true
            return Promise.reject(error)
        }
        return Promise.reject(error)
    },
)

export { axiosInstance as axios }
