import api from './api'

const apicampaignparticipants = {
    async performAction(axios, supercampaignId, campaignId, actionId, participants, options, entityType) {
        const body = {
            action: {
                id: actionId,
                entity: entityType,
                entity_ids: participants,
                options,
            },
        }

        const url = `/supercampaigns/${supercampaignId}/campaigns/${campaignId}/action`

        return axios
            .post(url, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async validateAction(axios, supercampaignId, campaignId, actionId, participants, options, entityType) {
        const body = {
            action: {
                id: actionId,
                entity: entityType,
                entity_ids: participants,
                options,
            },
        }

        const url = `/supercampaigns/${supercampaignId}/campaigns/${campaignId}/action/validate`

        return axios
            .post(url, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getLinkableUsers(axios, supercampaignId, campaignId, participantId) {
        const url = `/supercampaigns/${supercampaignId}/campaigns/${campaignId}/participants/${participantId}/linkuser`

        return axios
            .get(url)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async linkUserToParticipant(axios, supercampaignId, campaignId, participantId, userId) {
        const url = `/supercampaigns/${supercampaignId}/campaigns/${campaignId}/participants/${participantId}/linkuser`

        const body = {
            user_id: userId,
        }

        return axios
            .put(url, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async unlinkUserToParticipant(axios, supercampaignId, campaignId, participantId) {
        const url = `/supercampaigns/${supercampaignId}/campaigns/${campaignId}/participants/${participantId}/linkuser`

        return axios
            .delete(url)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apicampaignparticipants
