<template>
    <div class="flex">
        <ModalBasic
            id="confirm-pending-modal"
            title="Confirm Approval"
            :modal-open="modals.confirm_pending_modal.is_open"
            @close-modal="$store.commit('modal:close', 'confirm_pending_modal')"
        >
            <div class="relative">
                <div class="px-5 py-4">
                    <div class="text-sm">
                        <div class="mb-3 font-medium text-slate-800">
                            Are you sure you want to approve all pending creators?
                        </div>
                    </div>
                </div>

                <div class="border-t border-slate-200 px-5 py-4">
                    <div class="flex flex-wrap justify-end space-x-2">
                        <button
                            class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                            @click.stop="$store.commit('modal:close', 'confirm_pending_modal')"
                        >
                            Cancel
                        </button>

                        <button
                            class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600"
                            @click="
                                () => {
                                    $store.commit('modal:close', 'confirm_pending_modal')
                                    onApprovePending()
                                }
                            "
                        >
                            Approve
                        </button>
                    </div>
                </div>
            </div>
        </ModalBasic>

        <div class="flex-1 md:mx-8 xl:mx-4 2xl:mx-8">
            <CampaignBudgetSection
                v-if="campaign && creators"
                :campaign="campaign"
                :creators="creators"
                :campaigns-by-id="campaignsById"
            />

            <!-- Skeleton for profile card -->
            <SkeletonApprovalSubpage v-if="creators === null && !selectedParticipantId" />

            <div v-else-if="creators && creators.length === 0" class="rounded bg-white px-5 py-10">
                <div class="px-4 text-center">
                    <div
                        class="mb-4 inline-flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-t from-slate-200 to-slate-100"
                    >
                        <svg class="h-6 w-5 fill-current" viewBox="0 0 20 24">
                            <path class="text-slate-500" d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z" />
                            <path class="text-slate-300" d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z" />
                            <path
                                class="text-slate-400"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                            />
                        </svg>
                    </div>
                    <h2 class="mb-2 text-2xl font-bold text-slate-800">No Creators Pending Approval</h2>
                    <div class="mb-6">
                        Looks like there aren't any creators that need your approval yet! We'll let you know when there
                        is.
                    </div>
                </div>
            </div>

            <div
                v-else
                class="flex grow translate-x-0 transform flex-col rounded border border-slate-200 shadow-lg transition-transform duration-300 ease-in-out md:translate-x-0"
            >
                <!-- Content -->
                <div v-if="selectedCreator">
                    <CreatorProfile
                        class="rounded"
                        :platform-id="selectedCreator.participant.platform_id"
                        :platform-user-id="selectedCreator.participant.platform_user_id"
                        :targeting-groups="targetingGroups"
                        :participant="selectedCreator.participant"
                        :campaign="campaign"
                    />

                    <div class="flex justify-end space-x-2 border-t border-slate-200 bg-white px-4 py-4">
                        <button
                            :disabled="selectedCreator.participant.approved_status === 'denied'"
                            class="btn text-white enabled:bg-rose-500 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 enabled:hover:bg-rose-600"
                            @click="onTriageStatusClick(selectedCreator, 'denied')"
                        >
                            <font-awesome-icon :icon="['fas', 'xmark']" class="h-4 w-4 shrink-0 fill-current" />
                            <span class="ml-2">Deny</span>
                        </button>

                        <button
                            :disabled="selectedCreator.participant.approved_status === 'approved'"
                            class="btn text-white enabled:bg-emerald-500 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 enabled:hover:bg-emerald-600"
                            @click="onTriageStatusClick(selectedCreator, 'approved')"
                        >
                            <font-awesome-icon :icon="['fas', 'check']" class="h-4 w-4 shrink-0 fill-current" />
                            <span class="ml-2">Approve</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="hidden w-full xl:block xl:w-72">
            <div
                class="pr-4 md:sticky md:top-16 md:h-[calc(100vh-128px)] md:overflow-y-auto md:overflow-x-hidden md:scrollbar md:scrollbar-thin md:scrollbar-track-transparent md:scrollbar-thumb-slate-200"
            >
                <div class="">
                    <!-- Search form -->
                    <div class="relative">
                        <label class="sr-only">Search</label>
                        <input
                            v-model="searchText"
                            class="form-input w-full pl-9 focus:border-slate-300"
                            type="search"
                            placeholder="Search…"
                        />
                        <button class="group absolute inset-0 right-auto" type="submit" aria-label="Search">
                            <svg
                                class="ml-3 mr-2 h-4 w-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                                ></path>
                                <path
                                    d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                                ></path>
                            </svg>
                        </button>
                    </div>

                    <!-- Skeleton for triage sidebar -->
                    <SkeletonTriageSidebar v-if="!creators || !selectedParticipantId" />

                    <div
                        v-for="triageGroup in creatorsByTriageStatus"
                        v-else
                        :key="triageGroup.name"
                        class="group mt-4"
                    >
                        <div class="mb-3 flex justify-between text-xs font-semibold uppercase">
                            <div class="text-slate-400">
                                {{ getFromDictionary(`participant.triage_status.${triageGroup.name}`, 'label') }} ({{
                                    triageGroup.size
                                }})
                            </div>

                            <div
                                v-if="triageGroup.name === 'pending'"
                                class="ml-2 cursor-pointer text-emerald-500 hover:text-emerald-600"
                                @click.prevent="$store.commit('modal:open', 'confirm_pending_modal')"
                            >
                                Approve all
                            </div>
                        </div>

                        <ul class="mb-6">
                            <li
                                v-for="creator in triageGroup.creators"
                                :key="creator.participant.id"
                                @click="onCreatorClick(creator)"
                            >
                                <button
                                    class="flex w-full justify-between rounded p-2"
                                    :class="{ 'bg-indigo-100': selectedParticipantId === creator.participant.id }"
                                >
                                    <div class="flex grow items-center">
                                        <div class="relative mr-2.5">
                                            <Avatar
                                                class="bg-slate-700 text-slate-50"
                                                :src="creator.participant.platform_user_logo"
                                                :username="creator.participant.platform_user_name"
                                                :size="32"
                                            />

                                            <div
                                                v-if="creator.participant.platform_id === 'twitch'"
                                                class="absolute -bottom-2 -right-1.5 z-10 flex rounded-full"
                                            >
                                                <svg
                                                    width="22"
                                                    height="22"
                                                    fill="none"
                                                    class="flex-shrink-0 fill-current"
                                                >
                                                    <path
                                                        fill="#fff"
                                                        d="M7.95243 3.33337 4.3999 6.8859v11.3999h2.85715v5.2093l5.20935-5.2093h1.9048l5.4573-5.4573V3.33337H7.95243Z"
                                                    />
                                                    <path
                                                        fill="#9147FF"
                                                        d="M8.78086 5.33337 6.3999 7.71433v8.57147h2.85714v2.3809l2.38096-2.3809h1.9048L17.8285 12V5.33337H8.78086Z"
                                                    />
                                                    <path
                                                        fill="#fff"
                                                        d="m14.9711 13.4286 1.9048-1.9048V6.28574H9.25684v7.14286h1.90476v1.9048l1.9048-1.9048h1.9047Z"
                                                    />
                                                    <path
                                                        fill="#9147FF"
                                                        d="M12.1143 8.19037h.9523v2.85713h-.9523V8.19037Zm3.3333 0v2.85713h-.9524V8.19037h.9524Z"
                                                    />
                                                </svg>
                                            </div>

                                            <div
                                                v-if="creator.participant.platform_id === 'youtube'"
                                                class="absolute -bottom-0.5 -right-1 z-10 flex rounded-full"
                                            >
                                                <svg height="16" width="16" viewBox="0 0 490 490">
                                                    <g>
                                                        <g>
                                                            <path
                                                                fill="#EA4640"
                                                                d="M480,180v130c0,55.195-44.805,100-100,100H110c-55.195,0-100-44.805-100-100V180
                                                            c0-55.199,44.805-100,100-100h270C435.195,80,480,124.801,480,180z"
                                                            />
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <polygon
                                                                    style="fill: #ffffff"
                                                                    points="320,245 200,295 200,195 "
                                                                />
                                                            </g>
                                                            <g>
                                                                <path
                                                                    fill="white"
                                                                    d="M380,70H110C49.346,70,0,119.346,0,180v130c0,60.654,49.346,110,110,110h270
                                                                c60.654,0,110-49.346,110-110V180C490,119.346,440.654,70,380,70z M470,310c0,49.626-40.374,90-90,90H110
                                                                c-49.626,0-90-40.374-90-90V180c0-49.626,40.374-90,90-90h270c49.626,0,90,40.374,90,90V310z"
                                                                />
                                                                <path
                                                                    fill="white"
                                                                    d="M323.846,235.769l-120-50c-3.085-1.286-6.611-0.945-9.393,0.911
                                                                c-2.782,1.854-4.453,4.977-4.453,8.32v100c0,3.344,1.671,6.466,4.453,8.32c1.667,1.112,3.601,1.68,5.548,1.68
                                                                c1.301,0,2.608-0.254,3.845-0.769l120-50c3.727-1.553,6.154-5.194,6.154-9.231S327.572,237.322,323.846,235.769z M210,280v-70
                                                                l84,35L210,280z"
                                                                />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>

                                        <div class="flex truncate">
                                            <span class="text-sm font-medium text-slate-800">{{
                                                creator.participant.platform_user_name
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="self-center">
                                        <font-awesome-icon
                                            v-if="creator.participant.approved_status === 'approved'"
                                            :icon="['fas', 'check']"
                                            class="h-4 w-4 shrink-0 text-emerald-500"
                                        />
                                        <font-awesome-icon
                                            v-if="creator.participant.approved_status === 'denied'"
                                            :icon="['fas', 'xmark']"
                                            class="h-4 w-4 shrink-0 text-rose-500"
                                        />
                                    </div>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div
                    v-if="searchText && creators && creatorsByTriageStatus.length === 0"
                    class="mt-12 text-center font-semibold"
                >
                    <p>No creators found matching "{{ searchText }}"</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { nextTick } from 'vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import targetGroupAPI from '@/utils/api/api.targeting'
import targetGroupTools from '@/utils/searching/targetgroups'
import Avatar from '@/components/Avatar.vue'
import ModalBasic from '@/components/ModalBasic.vue'
import SkeletonApprovalSubpage from '@/partials/skeletons/SkeletonApprovalSubpage.vue'
import SkeletonTriageSidebar from '@/partials/skeletons/SkeletonTriageSidebar.vue'
import CreatorProfile from '@/partials/profiles/CreatorProfile/CreatorProfile.vue'
import CampaignBudgetSection from '@/partials/widgets/CampaignBudgetSection.vue'

export default {
    name: 'SupercampaignApprovalSubpage',

    components: {
        Avatar,
        SkeletonApprovalSubpage,
        SkeletonTriageSidebar,
        ModalBasic,
        CreatorProfile,
        CampaignBudgetSection,
    },

    props: {
        supercampaign: Object,
        campaignsById: Object,
    },

    data() {
        return {
            creators: null,
            creatorsByParticipantId: {},

            targetingGroups: [],

            searchText: '',

            selectedParticipantId: null,

            triageStatuses: ['pending', 'approved', 'denied'],

            regionNames: new Intl.DisplayNames(['en'], {
                type: 'region',
            }),
        }
    },

    created() {
        let defaultSelectedParticipantId = null

        if (window.location.hash) {
            defaultSelectedParticipantId = window.location.hash.replace(/^#/, '')
        }

        this.getParticipants(this.$route.params.campaign_id, defaultSelectedParticipantId)
        this.getTargetingGroups()
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'getLanguageFromCode', 'getColor', 'modals']),

        campaign() {
            if (!this.campaignsById) return null
            return this.campaignsById[this.$route.params.campaign_id]
        },

        selectedCreator() {
            if (!_.size(this.creators) || !this.selectedParticipantId) return null
            const creator = this.creatorsByParticipantId[this.selectedParticipantId]
            return creator
        },

        creatorsByTriageStatus() {
            const customSort = (item) => _.findIndex(this.triageStatuses, (needle) => needle === item.name)

            let filteredCreators = this.creators

            if (this.searchText) {
                filteredCreators = _.filter(this.creators, (creator) => {
                    const name = _.get(creator, 'participant.platform_user_name')
                    return name.includes(this.searchText)
                })
            }

            return _(filteredCreators)
                .groupBy('participant.approved_status')
                .map((creators, status) => ({
                    name: status,
                    size: _.size(creators),
                    creators: _.orderBy(
                        creators,
                        ['participant.approved_at', 'participant.platform_user_name'],
                        ['desc', 'asc'],
                    ),
                }))
                .orderBy([customSort], ['asc'])
                .value()
        },
    },

    methods: {
        onCreatorClick(creator) {
            this.selectedParticipantId = creator.participant.id
            window.history.replaceState('', '', `#${_.get(creator, 'participant.id')}`)
        },

        onTriageStatusClick(creator, newStatus) {
            const participants = [creator.participant.id]
            this.updateParticipantStatus(participants, newStatus)
        },

        updateParticipantStatus(participants, newStatus) {
            this.$axios
                .put(
                    `/supercampaigns/${this.supercampaign.id}/campaigns/${this.campaign.id}/participants/${newStatus}`,
                    {
                        participants,
                    },
                )
                .then(({ data }) => {
                    _.forEach(data.payload.participants, (participant) => {
                        _.assign(this.creatorsByParticipantId[participant.id].participant, participant)
                    })

                    this.resetCreatorSelection()
                })
                .catch(_.noop)
        },

        async getTargetingGroups() {
            const filtering = {
                twitch: targetGroupTools.createTwitchOptions(),
                youtube: targetGroupTools.createYoutubeOptions(),
            }

            const defaults = {
                twitch: filtering.twitch.default,
                youtube: filtering.youtube.default,
            }

            const result = await targetGroupAPI.getEntityTargetGroups(
                this.$axios,
                'campaign',
                this.$route.params.campaign_id,
                defaults,
            )

            console.log(result)

            this.targetingGroups = _.map(result.value, (filters) => ({
                name: filters.title,
                filters,
            }))
        },

        getParticipants(campaignId, selectedParticipantId) {
            this.$axios
                .get(`/supercampaigns/${this.supercampaign.id}/campaigns/${campaignId}/participants`, {
                    params: {
                        // platform_id: 'twitch',
                        relations: ['campaign_task'],
                    },
                })
                .then(({ data }) => {
                    _.forEach(data.payload.creators, (creator) => {
                        // We do this so we don't have to make a computed variable on the CreatorProfile
                        // TODO: Don't do this
                        // eslint-disable-next-line no-param-reassign
                        creator.participant.tasks = _.filter(creator.participant.tasks, (task) =>
                            ['twitch-stream', 'youtube-video'].includes(task.type),
                        )
                    })

                    this.creators = _.orderBy(data.payload.creators, ['participant.platform_user_name'], ['asc'])
                    this.creatorsByParticipantId = _.keyBy(data.payload.creators, 'participant.id')

                    nextTick(() => {
                        this.resetCreatorSelection(selectedParticipantId)
                    })
                })
                .catch(_.noop)
        },

        resetCreatorSelection(selectedParticipantId) {
            if (_.size(this.creators) > 0) {
                if (selectedParticipantId) {
                    const matchedCreator = this.creatorsByParticipantId[selectedParticipantId]

                    if (matchedCreator) {
                        this.onCreatorClick(matchedCreator)
                        return
                    }
                }

                const pendingCreatorsGroup = _.find(this.creatorsByTriageStatus, (item) => item.name === 'pending')

                if (pendingCreatorsGroup && _.size(pendingCreatorsGroup.creators) > 0) {
                    this.onCreatorClick(_.first(pendingCreatorsGroup.creators))
                } else {
                    const firstGroupFound = _.first(this.creatorsByTriageStatus)
                    this.onCreatorClick(_.first(firstGroupFound.creators))
                }
            }
        },

        onApprovePending() {
            const participants = _(_.find(this.creatorsByTriageStatus, (item) => item.name === 'pending').creators)
                .map((creator) => creator.participant.id)
                .value()

            this.updateParticipantStatus(participants, 'approved')
        },
    },
}
</script>
