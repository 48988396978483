<!--
    Customized version of DataTable component w/ ShadCn aimed at being resuable and flexible within the app.
    See: https://www.shadcn-vue.com/docs/components/data-table
 -->

<script setup>
import {
    FlexRender,
    getCoreRowModel,
    useVueTable,
    getSortedRowModel,
    getPaginationRowModel,
    getFilteredRowModel,
} from '@tanstack/vue-table'
import { ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { valueUpdater } from '@/lib/utils'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import DataTableToolbar from './DataTableToolbar.vue'
import DataTablePagination from './DataTablePagination.vue'
import _ from 'lodash'

const props = defineProps({
    columns: {
        type: Array,
        default: () => [],
    },
    data: {
        type: Array,
        default: () => [],
    },
    toolbar: {
        type: Object,
        required: false,
        validator(toolbar) {
            if (typeof toolbar.placeholder !== 'string') {
                return false
            }
            if (typeof toolbar.columnToFilter !== 'string') {
                return false
            }
            return true
        },
    },
    tableCellClass: {
        type: String,
        required: false,
    },
    tableRowClass: {
        type: String,
        required: false,
    },
    isLoaded: {
        type: Boolean,
        default: () => true,
    },
    preferencesId: {
        type: String,
        required: false,
    },
})

const store = useStore()

const getPreferences = () => {
    if (props.preferencesId) {
        return store?.state?.preferences?.tables[props.preferencesId]
    }
    return null
}

const sorting = ref(
    (() => {
        return getPreferences()?.sorting ? getPreferences().sorting : []
    })(),
)
const columnFilters = ref([])
const pagination = ref(
    (() => {
        return {
            pageIndex: 0,
            pageSize: getPreferences()?.paging?.pageSize ? getPreferences().paging.pageSize : 10,
        }
    })(),
)

const savePerferences = (propType, change) => {
    if (!propType || !props.preferencesId) {
        return
    }
    if (propType === 'sorting') {
        savePeference(
            'sorting',
            _.map(change.value, (column) => {
                return { id: column.id, desc: column.desc }
            }),
        )
    } else if (propType === 'paging') {
        savePeference('paging.pageSize', change.value.pageSize)
    } else if (propType === 'columns') {
        let columnOptions = {}
        _.forEach(change.value, (column) => {
            columnOptions[column.id] = column.visible
        })

        savePeference(
            'columns',
            _.map(change.value, (column) => {
                return {
                    id: column.id,
                    visible: column.visible,
                }
            }),
        )
    }
}

const savePeference = (key, value) => {
    store.commit('preferences:set', {
        key: `tables.${props.preferencesId}.${key}`,
        value: value,
    })
}

const table = useVueTable({
    get data() {
        return props.data
    },
    get columns() {
        return props.columns
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(sorting),
    onSortingChange: (updaterOrValue) => {
        valueUpdater(updaterOrValue, sorting)
        savePerferences('sorting', sorting)
    },
    onColumnFiltersChange: (updaterOrValue) => {
        valueUpdater(updaterOrValue, columnFilters)
    },
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: (updaterOrValue) => {
        valueUpdater(updaterOrValue, pagination)
        savePerferences('paging', pagination)
    },
    getFilteredRowModel: getFilteredRowModel(),
    state: {
        get sorting() {
            return sorting.value
        },
        get columnFilters() {
            return columnFilters.value
        },
        get pagination() {
            return pagination.value
        },
    },
    initialState: {
        columnVisibility: (() => {
            const defaultVisible = getPreferences()?.columns ? getPreferences().columns : []
            let visibilityState = {}
            _.forEach(defaultVisible, (col) => {
                visibilityState[col.id] = col.visible
            })
            return visibilityState
        })(),
    },
})

const columnVisibility = computed(() => {
    return _.map(table.getAllColumns(), (col) => {
        return {
            id: col.id,
            visible: col.getIsVisible(),
        }
    })
})

watch(
    () => columnVisibility,
    () => {
        savePerferences('columns', columnVisibility)
    },
    { deep: true },
)
</script>

<template>
    <div>
        <div v-if="props.toolbar" class="pb-3">
            <DataTableToolbar
                :table="table"
                :placeholder="props.toolbar.placeholder"
                :columnToFilter="props.toolbar.columnToFilter"
                :is-server-side="false"
            />
        </div>
        <div>
            <Table>
                <TableHeader>
                    <TableRow v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id">
                        <TableHead
                            v-for="header in headerGroup.headers"
                            :key="header.id"
                            :class="header.column.columnDef.class"
                        >
                            <FlexRender
                                v-if="!header.isPlaceholder"
                                :render="header.column.columnDef.header"
                                :props="header.getContext()"
                            />
                        </TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <template v-if="isLoaded === false">
                        <slot name="loading-skeleton"></slot>
                    </template>
                    <template v-else>
                        <template v-if="table.getRowModel().rows?.length">
                            <TableRow
                                v-for="row in table.getRowModel().rows"
                                :key="row.id"
                                :data-state="row.getIsSelected() ? 'selected' : undefined"
                                :class="props.tableRowClass"
                            >
                                <TableCell :class="tableCellClass" v-for="cell in row.getVisibleCells()" :key="cell.id">
                                    <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
                                </TableCell>
                            </TableRow>
                        </template>
                        <template v-else>
                            <TableRow>
                                <TableCell :colspan="columns.length" class="h-24 text-center text-sm"
                                    >No results to show</TableCell
                                >
                            </TableRow>
                        </template>
                    </template>
                </TableBody>
            </Table>
        </div>
        <div class="flex justify-center py-3">
            <DataTablePagination :table="table" />
        </div>
    </div>
</template>
