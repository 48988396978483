<script setup>
const props = defineProps({
    question: {
        type: String,
        required: true,
    },
    answer: {
        type: Array,
        required: false,
    },
    loading: {
        type: Boolean,
        default: false,
        required: true,
    },
    error: {
        type: Object,
    },
    onClear: {
        type: Function,
        required: true,
    },
    onQuoteMouseEnter: {
        type: Function,
        required: true,
    },
    onQuoteMouseLeave: {
        type: Function,
        required: true,
    },
    onQuoteClick: {
        type: Function,
        required: true,
    },
})
</script>

<template>
    <div class="flex p-1">
        <div><font-awesome-icon :icon="['fas', 'wand-magic-sparkles']" class="m-1 h-4 w-4 text-indigo-700" /></div>
        <div class="space-y-2">
            <div class="text-sm">{{ props.question }}</div>
            <div v-if="props.error" class="rounded bg-red-100 px-1.5 py-1 text-xs text-red-800">
                <div>An error occurred when processing your question. Please try again later.</div>
            </div>
            <div v-if="!props.error" class="rounded bg-indigo-100 px-1.5 py-1 text-xs text-indigo-800">
                <div v-if="props.loading" class="flex items-center">
                    <div>Thinking...</div>
                    <font-awesome-icon
                        :icon="['fas', 'spinner']"
                        class="animate m-1 h-4 w-4 animate-spin text-indigo-700"
                    />
                </div>
                <div v-else>
                    <b>AI: </b>
                    <div v-for="(section, index) in answer" :key="index">
                        <div>{{ section.explanation }}</div>
                        <br v-if="section.quote" />
                        <div
                            v-if="section.quote"
                            class="rounded border-l-4 border-indigo-300 bg-indigo-200 p-0.5 hover:cursor-pointer"
                            @click="props.onQuoteClick(section.quote)"
                            @mouseenter="props.onQuoteMouseEnter(section.quote)"
                            @mouseleave="props.onQuoteMouseLeave(section.quote)"
                        >
                            <div class="italic text-blue-700 hover:underline">
                                "{{ section.quote.text }}" - {{ section.quote.timestamp }}
                            </div>
                        </div>
                        <br v-if="index !== answer.length - 1" />
                    </div>
                </div>
            </div>
            <button
                type="button"
                class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                @click="props.onClear"
            >
                {{ props.loading ? 'Cancel' : 'Clear' }}
            </button>
        </div>
    </div>
</template>
