<template>
    <div class="rounded border border-slate-200 bg-white p-4">
        <div class="mb-2 flex grow items-center truncate">
            <div class="mr-2 flex h-7 w-7 shrink-0 items-center justify-center rounded-full bg-slate-700">
                <font-awesome-icon :icon="['fab', 'steam-symbol']" class="h-4 w-4 text-slate-50" />
            </div>
            <div class="truncate">
                <span class="text-md font-medium text-slate-800">Steamworks</span>
            </div>
        </div>

        <div>
            <p class="mb-3 text-sm">
                Connecting your Steamworks account will allow us to collect important data for use in your campaign
                reports.
            </p>

            <div v-if="state === 'connected'">
                <div v-if="steamworksOrganization" class="mt-4">
                    <div>
                        <span>Name:</span>
                        <span class="ml-1 font-medium">{{ steamworksOrganization.organization_name }}</span>
                    </div>

                    <div>
                        <span>Partner ID:</span>
                        <span class="ml-1 font-medium">{{ steamworksOrganization.partner_id }}</span>
                    </div>
                </div>

                <ul class="my-3 list-none space-y-1">
                    <li v-for="{ key, title, description } in permissionOptions" :key="key" class="flex">
                        <div v-if="permissions[key]?.disabled.length" class="flex">
                            <font-awesome-icon
                                :icon="['fas', 'xmark']"
                                class="my-auto mr-1 inline h-4 w-4 shrink-0 text-rose-500"
                            />
                            <span class="mr-1 font-medium text-rose-500"
                                >({{ permissions[key]?.enabled.length }}/{{ $filters.lodash.size(appsById) }})</span
                            >
                        </div>

                        <font-awesome-icon
                            v-else
                            :icon="['fas', 'check']"
                            class="my-auto mr-1.5 inline h-4 w-4 shrink-0 text-emerald-500"
                        />

                        <span>{{ title }}</span>

                        <Tooltip class="my-auto ml-1.5 box-content">
                            <template #trigger>
                                <font-awesome-icon
                                    class="h-3 w-3 transition duration-200 hover:text-slate-500"
                                    :icon="['fas', 'circle-info']"
                                />
                            </template>
                            <template #default>
                                <div class="normal-case">
                                    <h5 class="text-xl text-slate-200">{{ title }}</h5>
                                    <p class="text-xs text-slate-300">{{ description }}</p>
                                </div>
                            </template>
                        </Tooltip>
                    </li>
                </ul>
            </div>

            <div v-else-if="state === 'unconnected'">
                <div class="mt-10 flex">
                    <button class="btn btn-sm ml-auto mt-auto bg-indigo-500 text-white hover:bg-indigo-600">
                        Connect
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Tooltip from '@/components/Tooltip.vue'

export default {
    name: 'OrganizationDatasourcesSubpage',

    components: {
        Tooltip,
    },

    data() {
        return {
            steamworksOrganization: null,
            appsById: {},

            // These are the permissions we are expecting from the Steamworks org
            permissionOptions: [
                {
                    key: 'view_marketing_traffic_data',
                    title: 'View Marketing Traffic Data',
                    description: `Can view Steam Store, Community, and Platform navigation traffic data for your title.`,
                },
                {
                    key: 'generate_steam_keys',
                    title: 'Generate Steam Keys',
                    description: `Can request CD Key batches for your application or upload third-party keys.`,
                },
                {
                    key: 'view_financial_info',
                    title: 'View Financial Info',
                    description: `Can view sales and download data for your applications and packages.`,
                },
                {
                    key: 'edit_app_marketing_data',
                    title: 'Edit App Marketing Data',
                    description:
                        'Grants permission to edit and publish store page data such as game description, branding images, and videos. Also includes access to marketing tools like visibility rounds.',
                },
            ],

            permissions: {},

            state: 'unconnected',
        }
    },

    created() {
        this.getSteamworksOrganization()
    },

    methods: {
        getSteamworksOrganization() {
            this.$axios
                .get(`/organizations/${this.$route.params.organization_id}/steamworks`, {
                    params: {
                        relations: ['apps'],
                    },
                })
                .then(({ data }) => {
                    this.steamworksOrganization = data.payload.steamworksOrganization

                    if (1 === 2 && data.payload.steamworksOrganization) {
                        this.state = 'connected'

                        if (data.payload.steamworksOrganization?.apps) {
                            this.appsById = _.keyBy(data.payload.steamworksOrganization.apps, '_id')

                            this.permissions = _.reduce(
                                data.payload.steamworksOrganization.apps,
                                (permissions, app) => {
                                    _.forEach(this.permissionOptions, ({ key }) => {
                                        // eslint-disable-next-line no-param-reassign
                                        permissions[key] = {
                                            enabled: [],
                                            disabled: [],
                                        }

                                        if (
                                            app.permissions &&
                                            app.permissions[key] &&
                                            app.permissions[key].is_enabled
                                        ) {
                                            // eslint-disable-next-line no-underscore-dangle
                                            permissions[key].enabled.push(app._id)
                                        } else {
                                            // eslint-disable-next-line no-underscore-dangle
                                            permissions[key].disabled.push(app._id)
                                        }
                                    })

                                    return permissions
                                },
                                {},
                            )
                        }
                    } else {
                        this.state = 'unconnected'
                    }
                })
                .catch((error) => {
                    console.error(`Error finding steamworks organization`, error)
                })
        },

        async beginAssociation() {
            this.$axios
                .post(`/organizations/${this.$route.params.organization_id}/steamworks`)
                .then(({ data }) => {
                    this.steamworksOrganization = data.payload.steamworksOrganization
                })
                .catch(() => {})
        },
    },
}
</script>
