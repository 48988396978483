<template>
    <UIDialog
        v-if="modals.send_messages.is_open"
        :open="modals.send_messages.is_open"
        @update:open="$store.commit('modal:close', 'send_messages')"
    >
        <DialogScrollContent :class="'max-w-4xl border-none p-0'">
            <DialogHeader>
                <DialogTitle>
                    <div class="mx-5 mt-5 font-bold">
                        <template v-if="step === 'no-email-warning'">
                            Some recipients do not have email addresses
                        </template>
                        <template v-if="step === 'editor'">
                            Compose messages for {{ options?.recipients?.length }} recipients
                        </template>
                        <template v-if="step === 'preview'"> Preview </template>
                        <template v-if="step === 'confirm'"> Confirm </template>
                    </div>
                </DialogTitle>
            </DialogHeader>

            <div id="tippyContainer" class="mx-5 mb-5">
                <template v-if="!loading && step === 'no-email-warning'">
                    <div class="text-sm">
                        {{ recipientsWithoutEmail.length }} of the recipients you have selected do not have an email
                        address set. If you continue anyway, these creators will be removed from your recipient list.
                    </div>

                    <div class="mb-3 mt-3 flex max-h-64 flex-wrap overflow-auto rounded border border-slate-200 p-3">
                        <div v-for="recipient in recipientsWithoutEmail" :key="recipient.key">
                            <NamePlate
                                :logo="recipient.platform_user_logo"
                                :name="recipient.platform_user_name"
                                :display-name="recipient.platform_user_display_name"
                                :platform-id="recipient.platform_id"
                                :platform-user-id="recipient.platform_user_Id"
                                :is-drawer-enabled="false"
                            />
                        </div>
                    </div>

                    <div class="flex justify-end space-x-2">
                        <button
                            class="btn border border-slate-900 text-black"
                            @click="$store.commit('modal:close', 'send_messages')"
                        >
                            Close
                        </button>

                        <button class="btn bg-indigo-600 text-white hover:bg-indigo-700" @click="onOpen">
                            Continue
                        </button>
                    </div>
                </template>

                <template v-if="!loading && step === 'editor'">
                    <div v-if="1 === 2" class="mb-2">
                        <label class="text-sm font-semibold">Template</label>

                        <div class="mt-1">
                            <select
                                v-model="templateId"
                                class="form-select w-full"
                                @change="onTemplateChange(template)"
                            >
                                <option v-for="template in availableTemplates" :key="template.id" :value="template.id">
                                    {{ template.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="mb-4 grid grid-cols-2 gap-4">
                        <div>
                            <label class="text-sm font-semibold">Sender name</label>

                            <div class="mt-1">
                                <input
                                    v-model="form.senderName"
                                    class="form-input w-full rounded-b-none rounded-t rounded-bl rounded-br"
                                    placeholder="Please enter the senders name"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    type="text"
                                />
                            </div>
                        </div>

                        <div>
                            <label class="text-sm font-semibold">Send from email</label>

                            <div class="mt-1">
                                <Tooltip placement="bottom" max-width="400" class="cursor-pointer">
                                    <template #trigger>
                                        <div
                                            class="form-input mt-1 bg-slate-100 hover:cursor-default hover:border-slate-200"
                                        >
                                            campaigns@streamforge.gg
                                        </div>
                                    </template>
                                    <template #default>
                                        <p class="text-sm">Customizing this is currently not available.</p>
                                    </template>
                                </Tooltip>
                            </div>
                        </div>

                        <div>
                            <label class="text-sm font-semibold">Reply-to name</label>

                            <div class="mt-1">
                                <input
                                    v-model="form.replyToName"
                                    class="form-input w-full rounded-b-none rounded-t rounded-bl rounded-br"
                                    placeholder="Please enter the reply to name"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    type="text"
                                />
                            </div>
                        </div>

                        <div>
                            <label class="text-sm font-semibold">Reply-to email</label>

                            <div class="mt-1">
                                <input
                                    v-model="form.replyToEmail"
                                    class="form-input w-full rounded-b-none rounded-t rounded-bl rounded-br"
                                    placeholder="Please enter the reply to email address"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="mb-4">
                        <label class="text-sm font-semibold">Subject</label>

                        <div class="mt-1">
                            <input
                                v-model="form.subject"
                                class="form-input w-full rounded-b-none rounded-t rounded-bl rounded-br"
                                placeholder="Please enter subject line for the email"
                                aria-haspopup="true"
                                aria-expanded="false"
                                type="text"
                                maxlength="300"
                            />
                        </div>
                    </div>

                    <div class="mb-4 min-h-40">
                        <div class="space-x-1 text-sm">
                            <button
                                class="mb-2 rounded bg-slate-900 px-2.5 py-0.5 text-white hover:bg-slate-800"
                                :disabled="!editor?.can().chain().focus().toggleBold().run()"
                                :class="{ 'is-active': editor?.isActive('bold') }"
                                @click="editor.chain().focus().toggleBold().run()"
                            >
                                Bold
                            </button>
                            <button
                                class="mb-2 rounded bg-slate-900 px-2.5 py-0.5 text-white hover:bg-slate-800"
                                :disabled="!editor?.can().chain().focus().toggleItalic().run()"
                                :class="{ 'is-active': editor?.isActive('italic') }"
                                @click="editor.chain().focus().toggleItalic().run()"
                            >
                                Italic
                            </button>
                            <!-- <button
                                class="mb-2 rounded bg-slate-900 px-2.5 py-0.5 text-white hover:bg-slate-800"
                                :class="{ 'is-active': editor?.isActive('paragraph') }"
                                @click="editor.chain().focus().setParagraph().run()"
                            >
                                Paragraph
                            </button> -->
                            <button
                                class="mb-2 rounded bg-slate-900 px-2.5 py-0.5 text-white hover:bg-slate-800"
                                :class="{ 'is-active': editor?.isActive('bulletList') }"
                                @click="editor.chain().focus().toggleBulletList().run()"
                            >
                                Bullet List
                            </button>
                            <button
                                class="mb-2 rounded bg-slate-900 px-2.5 py-0.5 text-white hover:bg-slate-800"
                                :class="{ 'is-active': editor?.isActive('orderedList') }"
                                @click="editor.chain().focus().toggleOrderedList().run()"
                            >
                                Numbered List
                            </button>
                        </div>

                        <editor-content :editor="editor" />
                        <div>
                            <p class="px-1 py-2 text-sm">
                                You can use @ symbol to insert variables that will change depending on who the email is
                                being sent to.
                            </p>
                        </div>
                    </div>

                    <div class="">
                        <div class="flex justify-end space-x-2">
                            <button
                                class="btn border-slate-200 text-slate-500 hover:border-slate-300 hover:text-slate-700"
                                @click="onCancel()"
                            >
                                Cancel
                            </button>
                            <button
                                class="inline-flex h-10 items-center justify-center whitespace-nowrap rounded bg-primary px-4 py-2 text-sm font-medium text-primary-foreground ring-offset-background transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                                :disabled="!canPreview"
                                :title="!canPreview ? 'Please enter a subject line' : ''"
                                @click="onPreview()"
                            >
                                Preview
                            </button>
                        </div>
                    </div>
                </template>

                <template v-if="!loading && step === 'preview'">
                    <div class="mb-3">
                        <label class="text-sm font-semibold">Recipient</label>

                        <div class="mt-1">
                            <DropdownSelect v-model="previewRecipient" @update:model-value="onPreviewRecipientChange">
                                <SelectTrigger>
                                    <SelectValue placeholder="">{{ selectedRecipient.platform_user_name }}</SelectValue>
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem
                                        v-for="recipient in options?.recipients"
                                        :key="recipient.key"
                                        :value="recipient.key"
                                    >
                                        <div class="flex">
                                            {{ recipient.platform_user_name }}
                                        </div>
                                    </SelectItem>
                                </SelectContent>
                            </DropdownSelect>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label class="text-sm font-semibold">Subject</label>

                        <div class="form-input mt-1 hover:cursor-default hover:border-slate-200">
                            {{ form.subject }}
                        </div>
                    </div>

                    <div v-if="form.replyToName" class="mb-3">
                        <label class="text-sm font-semibold">Reply to name</label>

                        <div class="form-input mt-1 hover:cursor-default hover:border-slate-200">
                            {{ form.replyToName }}
                        </div>
                    </div>

                    <div v-if="form.replyToEmail" class="mb-3">
                        <label class="text-sm font-semibold">Reply to email</label>

                        <div class="form-input mt-1 hover:cursor-default hover:border-slate-200">
                            {{ form.replyToEmail }}
                        </div>
                    </div>

                    <div class="mb-3">
                        <label class="text-sm font-semibold">Message</label>

                        <div
                            class="form-input mt-1 min-h-40 hover:cursor-default hover:border-slate-200"
                            v-html="previewHTML"
                        />
                    </div>

                    <div class="flex justify-end space-x-2">
                        <button
                            class="btn border-slate-200 text-slate-500 hover:border-slate-300 hover:text-slate-700"
                            @click="onBack()"
                        >
                            Back
                        </button>
                        <button class="btn bg-indigo-500 text-white hover:bg-indigo-600" @click="onNext()">Next</button>
                    </div>
                </template>

                <template v-if="!loading && step === 'confirm'">
                    <template v-if="recipientsWithRecentMessages.length === 0">
                        <div>
                            You are about you send emails to {{ options?.recipients?.length }} recipients, are you sure?
                        </div>
                    </template>
                    <template v-else>
                        <div>You are about you send emails to {{ options?.recipients?.length }} recipients.</div>
                        <div>
                            {{ recipientsWithRecentMessages.length }} of these recipient(s) have recieved messages
                            within the past {{ warnLastMessageHours }} hours, are you sure?
                        </div>

                        <div class="w-128 mb-3 mt-3 max-h-64 overflow-auto rounded border border-slate-200 p-3">
                            <div class="flex">
                                <div class="w-64 font-bold">Recipient</div>
                                <div class="font-bold">Last message</div>
                            </div>
                            <div v-for="recipient in recipientsWithRecentMessages" :key="recipient.key" class="flex">
                                <div class="w-64">{{ recipient.platform_user_name }}</div>
                                <div>{{ $filters.formatDateAndTime(recipient?.summary?.last_messaged_at) }}</div>
                            </div>
                        </div>
                    </template>

                    <div class="flex justify-end space-x-2">
                        <button
                            class="btn border-slate-200 text-slate-500 hover:border-slate-300 hover:text-slate-700"
                            @click="onBack()"
                        >
                            Back
                        </button>
                        <button class="btn bg-indigo-500 text-white hover:bg-indigo-600" @click="onSend()">Send</button>
                    </div>
                </template>

                <template v-if="!loading && step === 'sending'">
                    <div class="mb-3">
                        Sending {{ options?.recipients?.length }} messages, this make take a few minutes.
                    </div>

                    <div v-if="sending.batches > 1" class="">Batch {{ sending.batch }} of {{ sending.batches }}</div>
                </template>

                <template v-if="!loading && step === 'sent'">
                    <div>Messages have been sent</div>
                    <div>
                        <div class="flex justify-end space-x-2 px-4">
                            <button class="btn bg-indigo-500 text-white hover:bg-indigo-600" @click="onClose()">
                                Close
                            </button>
                        </div>
                    </div>
                </template>

                <template v-if="loading">
                    <SkeletonSendMessagesModal />
                </template>
            </div>
        </DialogScrollContent>
    </UIDialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Mention from '@tiptap/extension-mention'
import _ from 'lodash'
import moment from 'moment'
import NamePlate from '@/components/plates/NamePlate.vue'
import { Dialog, DialogHeader, DialogTitle, DialogScrollContent } from '@/components/ui/dialog'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import organizationAPI from '@/utils/api/api.organization'
import verificationAPI from '@/utils/api/api.verification'
import Tooltip from '@/components/Tooltip.vue'
import SkeletonSendMessagesModal from '@/partials/skeletons/SkeletonSendMessagesModal.vue'
import suggestion from './suggestion'
import messageAPI from '../../utils/api/api.messages'

export default {
    name: 'SendMessagesModal',

    components: {
        NamePlate,
        Tooltip,
        UIDialog: Dialog,
        DialogHeader,
        DialogTitle,
        DialogScrollContent,
        EditorContent,
        SelectContent,
        SelectItem,
        SelectTrigger,
        SelectValue,
        DropdownSelect: Select,
        SkeletonSendMessagesModal,
    },

    data() {
        return {
            warnLastMessageHours: 48,
            loading: true,
            editor: null,
            templateId: null,
            step: 'editor',
            previewRecipient: null,
            options: {},
            form: {
                senderName: '',
                replyToName: '',
                replyToEmail: '',
                subject: '',
            },
            mergeFields: [
                // label cannot be used twice for an entity
                { prop: 'form.senderName', label: 'Sender name', entityTypes: ['campaign', 'list'] },
                { prop: 'form.replyToName', label: 'Reply name', entityTypes: ['campaign', 'list'] },
                { prop: 'form.replyToEmail', label: 'Reply email', entityTypes: ['campaign', 'list'] },
                { prop: 'recipient.platform_user_name', label: 'Creator Name', entityTypes: ['campaign', 'list'] },
                { prop: 'game.name', label: 'Game Name', entityTypes: ['campaign'] },
                { prop: 'game.publisher', label: 'Game Publisher', entityTypes: ['campaign'] },
                { prop: 'campaign.start', label: 'Campaign Start Date', entityTypes: ['campaign'] },
                { prop: 'campaign.end', label: 'Campaign End Date', entityTypes: ['campaign'] },
            ],
            templates: [
                /* {
                    id: 'test1',
                    name: 'Example Template 1',
                    entityTypes: ['campaign', 'list'],
                    content:
                        'This is example one <br/><p>Greetings <span class="mention" data-type="mention" data-id="Creator Name" contenteditable="false">@Creator Name</span>, <br class="ProseMirror-trailingBreak"><br></p><p>We would like to invite you to stream our game <span class="mention" data-type="mention" data-id="Game Name" contenteditable="false">@Game Name</span>, published by <span class="mention" data-type="mention" data-id="Game Publisher" contenteditable="false">@Game Publisher</span> between <span class="mention" data-type="mention" data-id="Campaign Start Date" contenteditable="false">@Campaign Start Date</span> and <span class="mention" data-type="mention" data-id="Campaign End Date" contenteditable="false">@Campaign End Date</span> </p><br/> <br/> <span class="mention" data-type="mention" data-id="Sender name" contenteditable="false">@Sender name</span>',
                },
                {
                    id: 'test2',
                    name: 'Example Template 2',
                    entityTypes: ['campaign'],
                    content:
                        'This is example two <br/><p>Greetings <span class="mention" data-type="mention" data-id="Creator Name" contenteditable="false">@Creator Name</span>, <br class="ProseMirror-trailingBreak"><br></p><p>We would like to invite you to stream our game <span class="mention" data-type="mention" data-id="Game Name" contenteditable="false">@Game Name</span>, published by <span class="mention" data-type="mention" data-id="Game Publisher" contenteditable="false">@Game Publisher</span> between <span class="mention" data-type="mention" data-id="Campaign Start Date" contenteditable="false">@Campaign Start Date</span> and <span class="mention" data-type="mention" data-id="Campaign End Date" contenteditable="false">@Campaign End Date</span> </p> <br/> <br/> <span class="mention" data-type="mention" data-id="Sender name" contenteditable="false">@Sender name</span>',
                }, */
            ],
            renderMode: null,
            previewHTML: '',
            sending: {
                batch: 1,
                batches: 1,
            },
            organizationSettings: null,
            validatedEmails: [],
        }
    },

    computed: {
        ...mapGetters(['modals', 'isSfStaff', 'selfOrganizations', 'selfActiveOrganizationId']),
        availableTemplates() {
            return _.filter(this.templates, (template) => {
                return template?.entityTypes?.includes(this.options.entityType)
            })
        },
        availableMergeFields() {
            return _.filter(this.mergeFields, (field) => {
                let isAvailable = false
                if (field.entityTypes.includes(this.options.entityType)) {
                    isAvailable = true
                }
                return isAvailable
            })
        },
        currentTemplate() {
            return _.find(this.templates, (template) => {
                return template.id === this.templateId
            })
        },
        canPreview() {
            return this.form.subject
        },
        selectedRecipient() {
            return _.find(this.options.recipients, (recipient) => {
                return recipient.key === this.previewRecipient
            })
        },
        recipientsWithRecentMessages() {
            const now = moment.utc()
            return _.filter(this.options.recipients, (recipient) => {
                if (recipient?.summary?.last_messaged_at) {
                    const lastMessage = moment.utc(recipient?.summary?.last_messaged_at)
                    return moment.duration(now.diff(lastMessage)).asHours() <= this.warnLastMessageHours
                }

                return false
            })
        },
    },

    watch: {
        'modals.send_messages.is_open': {
            handler() {
                if (this.modals?.send_messages?.is_open === true) {
                    this.onOpen()
                } else {
                    this.options = {}
                }
            },
        },
    },

    mounted() {
        this.editor = new Editor({
            content: '',
            mergeItems: [],
            extensions: [
                StarterKit.configure({
                    bulletList: true,
                    listItem: true,
                    orderedList: true,
                }),
                Mention.configure({
                    HTMLAttributes: {
                        class: 'mention border-slate-300',
                    },
                    suggestion,
                    renderHTML: this.renderHTML,
                }),
            ],
            editorProps: {
                attributes: {
                    class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none focus:border-indigo-400 px-3 py-2 form-input',
                },
            },
        })
    },

    beforeUnmount() {
        this.editor?.destroy()
    },

    methods: {
        async onOpen() {
            this.loading = true
            this.step = 'editor'

            this.options = this.modals?.send_messages?.options
            this.previewRecipient = null

            this.recipientsWithoutEmail = []

            if (this.options && this.options.recipients) {
                this.recipientsWithoutEmail = _.filter(this.options.recipients, (recipient) => {
                    return !recipient.email
                })

                this.options.recipients = _.filter(this.options.recipients, (recipient) => {
                    return recipient.email
                })

                this.options.recipients = _.map(this.options.recipients, (recipient) => {
                    recipient.key = `${recipient.platform_id}_${recipient.platform_user_id}`
                    return recipient
                })
                this.previewRecipient = this.options.recipients?.[0]?.key ?? []
            }

            const result = await organizationAPI.getOrganizationSettings(this.$axios, this.selfActiveOrganizationId)

            if (result.success) {
                this.organizationSettings = result.value.settings
                this.form.senderName = this.organizationSettings?.sender_name
                this.form.replyToName = this.organizationSettings?.reply_name
                this.form.replyToEmail = this.organizationSettings?.reply_address
            }

            if (this.recipientsWithoutEmail.length > 0) {
                this.step = 'no-email-warning'
            } else {
                this.templateId = this.availableTemplates.length > 0 ? this.availableTemplates[0].id : null
                this.onTemplateChange()
                this.onFetchMessageSummary()
            }

            this.loading = false
        },

        renderHTML({ options, node }) {
            if (this.renderMode === 'preview') {
                return [
                    'span',
                    { 'data-id': node.attrs.id, 'data-type': 'mention' },
                    `${node.attrs.label ?? node.attrs.id}`,
                ]
            }
            return [
                'span',
                {
                    class: 'mention',
                    'data-type': 'mention',
                    'data-id': node.attrs.id,
                },
                `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`,
            ]
        },

        onTemplateChange() {
            this.editor.options.mergeItems = _.map(this.availableMergeFields, (field) => {
                return field.label
            })

            if (this.currentTemplate) {
                this.editor.commands.setContent(this.currentTemplate.content)
            } else {
                this.editor.commands.setContent('')
            }
        },

        onBack() {
            this.step = 'editor'
        },

        async onPreview() {
            if (
                this.form.replyToEmail &&
                this.form.replyToEmail !== _.get(this.organizationSettings, 'reply_address') &&
                !this.validatedEmails.includes(this.form.replyToEmail)
            ) {
                const result = await verificationAPI.validateEmail(this.$axios, this.form.replyToEmail)
                if (!result.success || !result.value.valid) {
                    const message = `Please enter a valid reply email address`
                    this.$errorHandler(message, { title: 'Unable to preview', message })
                    return
                }
                this.validatedEmails.push(this.form.replyToEmail)
            }

            this.step = 'preview'
            this.buildPreview()
        },

        buildPreview() {
            const mergeData = this.createMergeData(this.selectedRecipient)
            this.previewHTML = this.createMergedHTML(mergeData)
            this.onFetchMessageSummary()
        },

        createMergeData(recipient) {
            const mergeData = {
                recipient,
                /* game: {
                    name: 'My awesome game',
                    publisher: 'Best publisher',
                },
                campaign: {
                    start: '24/09/2024',
                    end: '27/09/2024',
                }, */
                form: {
                    senderName: this.form.senderName,
                    replyToName: this.form.replyToName,
                    replyToEmail: this.form.replyToEmail,
                },
            }

            return mergeData
        },

        createMergedHTML(mergeData) {
            this.renderMode = 'preview'
            let html = this.editor.getHTML()
            html = html.replaceAll('<p></p>', '<br>')

            const parser = new DOMParser()
            const doc = parser.parseFromString(html, 'text/html')
            const mergeSpans = doc.querySelectorAll(`span[data-type='mention']`)

            if (mergeSpans) {
                const mergeFields = this.availableMergeFields

                _.forEach(mergeSpans, (span) => {
                    const mergeField = _.find(mergeFields, (field) => {
                        return field.label === span.getAttribute('data-id')
                    })

                    if (mergeField.prop) {
                        span.textContent = _.get(mergeData, mergeField.prop)
                    }
                })
            }

            this.renderMode = null

            return doc.documentElement.outerHTML
        },

        onPreviewCancel() {
            this.step = 'editor'
        },

        onNext() {
            this.step = 'confirm'
        },

        async onSend() {
            this.step = 'sending'

            const messages = []

            _.forEach(this.options.recipients, (recipient) => {
                const mergeData = this.createMergeData(recipient)
                const mergedHTML = this.createMergedHTML(mergeData)
                const message = {
                    recipient: {
                        email: recipient.email,
                        name: recipient.platform_user_name,
                        platform_id: recipient.platform_id,
                        platform_user_id: recipient.platform_user_id,
                    },
                    sender: {
                        name: this.form.senderName,
                    },
                    replyTo: {
                        name: this.form.replyToName,
                        email: this.form.replyToEmail,
                    },
                    html: mergedHTML,
                    subject: this.form.subject,
                    entityType: this.options.entityType,
                    entityId: this.options.entityId,
                }

                messages.push(message)
            })

            const chunkedMessages = _.chunk(messages, 1)

            this.sending.batch = 1
            this.sending.batches = chunkedMessages.length

            /* eslint-disable no-await-in-loop */
            for (let index = 0; index < chunkedMessages.length; index += 1) {
                await messageAPI.sendMessages(this.$axios, this.selfActiveOrganizationId, chunkedMessages[index])
                this.sending.batch += 1
            }
            /* eslint-enable no-await-in-loop */

            this.step = 'sent'
        },

        onCancel() {
            this.onTemplateChange()
            this.step = 'editor'
            this.modals.send_messages.is_open = false
        },

        onClose() {
            this.modals.send_messages.is_open = false
        },

        onPreviewRecipientChange() {
            this.buildPreview()
        },

        async onFetchMessageSummary() {
            const creatorsByPlatform = {}
            _.forEach(this.options.recipients, (recipient) => {
                if (!creatorsByPlatform[recipient.platform_id]) {
                    creatorsByPlatform[recipient.platform_id] = {
                        platform_id: recipient.platform_id,
                        platform_user_ids: [],
                    }
                }
                creatorsByPlatform[recipient.platform_id].platform_user_ids.push(recipient.platform_user_id)
            })

            const creatorsByPlatformFlattend = []

            _.forEach(creatorsByPlatform, (platform) => {
                creatorsByPlatformFlattend.push(platform)
            })

            const result = await messageAPI.getRecipientMessageSummary(
                this.$axios,
                this.selfActiveOrganizationId,
                creatorsByPlatformFlattend,
            )

            if (result.success) {
                const summaryLookup = {}

                _.forEach(result.value.recipients, (recipient) => {
                    if (!summaryLookup[recipient.platform_id]) {
                        summaryLookup[recipient.platform_id] = {}
                    }
                    summaryLookup[recipient.platform_id][recipient.platform_user_id] = recipient
                })

                _.forEach(this.options.recipients, (recipient) => {
                    if (
                        summaryLookup[recipient.platform_id] &&
                        summaryLookup[recipient.platform_id][recipient.platform_user_id]
                    ) {
                        recipient.summary = summaryLookup[recipient.platform_id][recipient.platform_user_id]
                    }
                })
            }
        },
    },
}
</script>
<style>
.ProseMirror {
    @apply rounded border border-slate-100;
    min-height: 200px;
    padding: 4px 4px 4px 4px;
    line-height: 28px;
}

.mention {
    @apply cursor-pointer rounded border border-slate-200 bg-slate-100 px-2 py-1 text-xs font-semibold leading-none;
    box-decoration-break: clone;
    cursor: pointer;
    z-index: 1000;
}
</style>
