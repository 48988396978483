<template>
    <div class="px-5 pt-4">
        <p class="text-sm">Where do you want to add these creators to?</p>

        <div class="mt-3 flex flex-wrap">
            <div v-for="index in 7" :key="index" class="mb-1 mr-2">
                <div class="h-7 w-7 animate-pulse rounded-full bg-slate-200" />
            </div>
        </div>
    </div>

    <div class="px-5 py-4">
        <div class="inline-flex rounded bg-slate-200 p-1 shadow-sm focus:shadow-sm hover:shadow-sm" role="group">
            <div
                v-for="tab in 3"
                :key="tab"
                class="flex rounded px-5 py-2.5 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
            >
                <div class="h-3 w-7 animate-pulse rounded-full bg-slate-200" />
            </div>
        </div>

        <div class="mt-2 border-slate-200">
            <div class="relative">
                <label for="action-search" class="sr-only">Search</label>

                <input
                    id="action-search"
                    class="form-input w-full animate-pulse pl-9 shadow-none focus:border-slate-300"
                    type="search"
                    placeholder="`Search for...`"
                    autocomplete="off"
                />

                <div class="group absolute inset-0 right-auto" aria-label="Search">
                    <svg
                        class="ml-3 mr-2 mt-2.5 h-4 w-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                    >
                        <path
                            d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                        />
                        <path
                            d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                        />
                    </svg>
                </div>
            </div>
        </div>

        <!-- Recent -->
        <div class="mt-2 overflow-hidden overflow-y-scroll border border-slate-200">
            <div class="h-96 text-sm">
                <div v-for="index in 7" :key="index" class="border-b border-slate-200">
                    <div class="px-5 py-3">
                        <div class="flex items-center justify-between">
                            <div class="flex">
                                <div class="my-auto">
                                    <div class="line-clamp-2 font-semibold">
                                        <div class="h-3 w-20 animate-pulse rounded-full bg-slate-200" />
                                    </div>
                                    <div class="mt-2 text-xs">
                                        <div class="h-3 w-10 animate-pulse rounded-full bg-slate-200" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkeletonAddToListOrCampaign',
}
</script>
