import { plugin, defaultConfig } from '@formkit/vue'
import { generateClasses } from '@formkit/tailwindcss'
import theme from './theme'
import autocomplete from './autocomplete'

export default {
    install(app) {
        app.use(
            plugin,
            defaultConfig({
                config: {
                    classes: generateClasses(theme),
                },
                inputs: {
                    autocomplete,
                },
                icons: {
                    twitter:
                        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16"><path d="M14.7747 3.77066C14.2657 3.99579 13.726 4.14365 13.1734 4.20933C13.7559 3.86095 14.1918 3.31267 14.4 2.66666C13.8534 2.992 13.254 3.22 12.6294 3.34333C12.2098 2.89438 11.6536 2.59664 11.0473 2.49639C10.4411 2.39613 9.81866 2.49899 9.27688 2.78897C8.7351 3.07894 8.30429 3.53979 8.05144 4.09986C7.7986 4.65993 7.73787 5.28785 7.8787 5.886C6.77011 5.83043 5.68559 5.54235 4.69556 5.04044C3.70553 4.53853 2.83212 3.83403 2.13204 2.97266C1.88423 3.3983 1.75401 3.88215 1.7547 4.37466C1.7547 5.34133 2.2467 6.19533 2.9947 6.69533C2.55204 6.6814 2.11912 6.56185 1.73204 6.34666V6.38133C1.73217 7.02513 1.95495 7.64908 2.3626 8.14737C2.77025 8.64567 3.33769 8.98765 3.9687 9.11533C3.55778 9.22669 3.12691 9.2431 2.7087 9.16333C2.88661 9.71749 3.23337 10.2021 3.70043 10.5494C4.16749 10.8967 4.73145 11.0892 5.31337 11.1C4.73502 11.5542 4.07282 11.89 3.36462 12.0881C2.65642 12.2862 1.91612 12.3428 1.18604 12.2547C2.4605 13.0743 3.9441 13.5094 5.45937 13.508C10.588 13.508 13.3927 9.25933 13.3927 5.57466C13.3927 5.45466 13.3894 5.33333 13.384 5.21466C13.9299 4.82011 14.4011 4.33134 14.7754 3.77133L14.7747 3.77066Z" fill="#4AA1EC"></path></svg>',
                    facebook:
                        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16"><path d="M1.33301 8C1.33301 4.31795 4.31789 1.33307 7.99994 1.33307C11.682 1.33307 14.6669 4.31795 14.6669 8C14.6669 11.682 11.682 14.6669 7.99994 14.6669C4.31789 14.6669 1.33301 11.682 1.33301 8Z" fill="#3479EA"></path><path d="M6.95896 14.5859V9.92658H5.26562V7.99991H6.95896V6.53125C6.95896 4.86058 7.95362 3.93791 9.47696 3.93791C10.2063 3.93791 10.969 4.06791 10.969 4.06791V5.70791H10.129C9.30029 5.70791 9.04229 6.22191 9.04229 6.74925V7.99991H10.891L10.5956 9.92658H9.04229V14.5859H6.95896Z" fill="white"></path></svg>',
                    youtube:
                        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16"><path d="M14.3922 4.84012C14.2384 4.25101 13.7881 3.78653 13.217 3.62793C12.1736 3.33337 8.00013 3.33337 8.00013 3.33337C8.00013 3.33337 3.82662 3.33337 2.78324 3.6166C2.22311 3.7752 1.76183 4.25101 1.60807 4.84012C1.3335 5.91636 1.3335 8.14815 1.3335 8.14815C1.3335 8.14815 1.3335 10.3913 1.60807 11.4562C1.76183 12.0453 2.21213 12.5098 2.78324 12.6684C3.8376 12.9629 8.00013 12.9629 8.00013 12.9629C8.00013 12.9629 12.1736 12.9629 13.217 12.6797C13.7881 12.5211 14.2384 12.0566 14.3922 11.4675C14.6668 10.3913 14.6668 8.15948 14.6668 8.15948C14.6668 8.15948 14.6778 5.91636 14.3922 4.84012Z" fill="#FF0000" stroke-width="2"></path><path d="M6.67139 10.2101L10.142 8.14822L6.67139 6.08636L6.67139 10.2101Z" stroke-width="0" fill="white"></path></svg>',
                    twitch: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="4 3 16 16"><path d="M8.78086 5.33337L6.3999 7.71433L6.3999 16.2858L9.25704 16.2858L9.25704 18.6667L11.638 16.2858L13.5428 16.2858L17.8285 12L17.8285 5.33337L8.78086 5.33337Z" fill="#9147FF"></path><path d="M14.9711 13.4286L16.8759 11.5238L16.8759 6.28574L9.25684 6.28574L9.25684 13.4286L11.1616 13.4286L11.1616 15.3334L13.0664 13.4286L14.9711 13.4286Z" fill="white"></path><path d="M12.1143 8.19037L13.0666 8.19037L13.0666 11.0475L12.1143 11.0475L12.1143 8.19037ZM15.4476 8.19037L15.4476 11.0475L14.4952 11.0475L14.4952 8.19037L15.4476 8.19037Z" fill="#9147FF"></path></svg>',
                },
            }),
        )
    },
}
