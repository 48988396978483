import _ from 'lodash'

const codes = {
    getCodesFromLookup(ids, platform, codeType, lookup) {
        const matchedCodes = []

        if (ids) {
            _.forEach(ids, (id) => {
                const matched = _.find(lookup, (c) => c.codetype === codeType && c.id === id)

                if (matched) {
                    matchedCodes.push({ id: matched.id, name: matched.name })
                }
            })
        }

        return matchedCodes
    },
}

export default codes
