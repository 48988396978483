<template>
    <div class="relative inline-flex">
        <button
            ref="trigger"
            class="btn justify-between border-slate-200 bg-white text-slate-500 hover:border-slate-300 hover:text-slate-600"
            :class="btnClasses"
            aria-label="Select date range"
            aria-haspopup="true"
            :aria-expanded="dropdownOpen"
            @click.prevent="dropdownOpen = !dropdownOpen"
        >
            <span v-if="selectedOption" class="flex items-center">
                <span>{{ get(selectedOption, textField) }}</span>
            </span>
            <svg class="ml-1 ml-2 shrink-0 fill-current text-slate-400" width="11" height="7" viewBox="0 0 11 7">
                <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
            </svg>
        </button>
        <transition
            enter-active-class="transition ease-out duration-100 transform"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="dropdownOpen"
                class="absolute left-0 top-full z-10 mt-1 rounded border border-slate-200 bg-white py-1.5 shadow-lg"
            >
                <div
                    ref="dropdown"
                    class="text-sm font-medium text-slate-600"
                    @focusin="dropdownOpen = true"
                    @focusout="dropdownOpen = false"
                >
                    <button
                        v-for="option in options"
                        :key="get(option, idField)"
                        class="flex w-full cursor-pointer items-center whitespace-nowrap px-3 py-1 pr-4 hover:bg-slate-50"
                        :class="get(option, idField) === selectedId && 'text-indigo-500'"
                        @click="onSelect(option)"
                    >
                        <svg
                            class="mr-2 shrink-0 fill-current text-indigo-500"
                            :class="get(option, idField) !== selectedId && 'invisible'"
                            width="12"
                            height="9"
                            viewBox="0 0 12 9"
                        >
                            <path
                                d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z"
                            />
                        </svg>
                        <span class="ml-1 text-left">{{ get(option, textField) }}</span>
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import _ from 'lodash'
import { ref, onMounted, onUnmounted } from 'vue'

export default {
    name: 'DropdownClassic',

    props: {
        options: {
            type: Array,
        },

        textField: {
            type: String,
            default: 'text',
        },

        idField: {
            type: String,
            default: 'id',
        },

        initialSelectedId: {
            type: String,
        },

        btnClasses: {
            type: String,
        },
    },

    emits: ['selected'],

    setup() {
        const dropdownOpen = ref(false)
        const trigger = ref(null)
        const dropdown = ref(null)

        // close on click outside
        const clickHandler = ({ target }) => {
            if (!dropdownOpen.value || dropdown.value.contains(target) || trigger.value.contains(target)) return
            dropdownOpen.value = false
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen.value || keyCode !== 27) return
            dropdownOpen.value = false
        }

        onMounted(() => {
            document.addEventListener('click', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        return {
            dropdownOpen,
            trigger,
            dropdown,
        }
    },

    data() {
        return {
            selectedId: null,
        }
    },

    computed: {
        selectedOption() {
            return _.find(this.options, (item) => item[this.idField] === this.selectedId)
        },
    },

    watch: {
        initialSelectedId(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.selectedId = newValue
            }
        },
    },

    created() {
        if (this.initialSelectedId) {
            this.selectedId = this.initialSelectedId
        }
    },

    methods: {
        onSelect(option) {
            this.selectedId = option[this.idField]
            this.dropdownOpen = false
            this.$emit('selected', this.selectedId)
        },

        get(item, field) {
            return _.get(item, field)
        },
    },
}
</script>
