<template>
    <div class="w-full">
        <div class="no-scrollbar md:sticky md:top-16 md:overflow-y-auto md:overflow-x-hidden lg:h-[calc(100vh-64px)]">
            <div>
                <!-- Title -->
                <!-- <div class="flex mb-8">
                <div class="w-16">
                    <img src="https://static-cdn.jtvnw.net/ttv-boxart/1847851872_IGDB-288x384.jpg" class="rounded w-full" alt="">
                </div>

                <div class="ml-3 flex items-center">
                    <header class="">
                        <h1 class="text-2xl text-slate-800 font-bold" v-if="supercampaign.game">{{ supercampaign.game.name }}</h1>
                        <h1 class="text-2xl text-slate-800 font-bold" v-else>{{ supercampaign.title }}</h1>
                        <div class="text-xs inline-flex font-medium bg-slate-700 text-slate-100 rounded-full text-center px-2.5 py-1">{{ supercampaign.stage }}</div>
                    </header>
                </div>
            </div> -->

                <header class="mb-8">
                    <h1 class="text-3xl font-bold text-slate-800">
                        <span v-if="supercampaign.game">{{ supercampaign.game.name }}</span>
                        <span v-else-if="supercampaign.intake_form && supercampaign.intake_form.game_name">{{
                            supercampaign.intake_form.game_name
                        }}</span>
                        <span v-else>{{ supercampaign.title }}</span>
                    </h1>
                    <h2 v-if="isSfStaff" class="font-semibold">{{ supercampaign.organization.name }}</h2>
                    <div
                        class="inline-flex rounded-full bg-slate-700 px-2.5 py-1 text-center text-xs font-medium text-slate-100"
                    >
                        {{ supercampaign.stage }}
                    </div>
                </header>

                <!-- Links -->
                <div class="-mx-4 flex flex-col flex-nowrap px-4 md:block md:flex-row">
                    <!-- Group 1 -->
                    <div class="mb-6">
                        <!-- <div class="text-xs font-semibold text-slate-400 uppercase mb-3 md:sr-only">Menu</div> -->

                        <div class="relative mb-2 flex h-8 justify-between border-b border-slate-200 pb-1.5">
                            <h4 class="my-auto text-sm font-semibold leading-none">Menu</h4>
                        </div>

                        <ul class="flex flex-col flex-nowrap">
                            <router-link
                                v-slot="{ href, navigate, isExactActive }"
                                :to="{
                                    name: 'supercampaign-home',
                                }"
                                custom
                            >
                                <li class="mr-0.5 md:mb-0.5 md:mr-0">
                                    <a
                                        class="flex items-center whitespace-nowrap rounded px-2.5 py-2"
                                        :class="isExactActive && 'bg-white'"
                                        :href="href"
                                        @click="navigate"
                                    >
                                        <font-awesome-icon
                                            fixed-width
                                            :icon="['fas', 'home']"
                                            class="mr-2 h-4 w-4 shrink-0 fill-current"
                                            :class="isExactActive ? 'text-indigo-400' : 'text-slate-400'"
                                        />
                                        <span
                                            class="text-sm font-medium"
                                            :class="isExactActive ? 'text-indigo-500' : 'text-slate-600'"
                                            >Overview</span
                                        >
                                    </a>
                                </li>
                            </router-link>

                            <router-link
                                v-if="checkPermission('feature.campaigns.manage')"
                                v-slot="{ href, navigate, isExactActive }"
                                :to="{
                                    name: 'supercampaign-settings',
                                }"
                                custom
                            >
                                <li class="mr-0.5 md:mb-0.5 md:mr-0">
                                    <a
                                        class="flex items-center whitespace-nowrap rounded px-2.5 py-2"
                                        :class="isExactActive && 'bg-white'"
                                        :href="href"
                                        @click="navigate"
                                    >
                                        <font-awesome-icon
                                            fixed-width
                                            :icon="['fas', 'gear']"
                                            class="mr-2 h-4 w-4 shrink-0 fill-current"
                                            :class="isExactActive ? 'text-indigo-400' : 'text-slate-400'"
                                        />
                                        <span
                                            class="text-sm font-medium"
                                            :class="isExactActive ? 'text-indigo-500' : 'text-slate-600'"
                                            >Settings</span
                                        >
                                    </a>
                                </li>
                            </router-link>
                        </ul>
                    </div>

                    <div class="relative flex h-8 justify-between border-b border-slate-200 pb-1.5">
                        <h4 class="my-auto text-sm font-semibold leading-none">Campaign Modules</h4>

                        <div v-if="checkPermission('feature.campaigns.manage')" class="flex">
                            <div
                                class="flex cursor-pointer rounded-sm bg-slate-200 p-1.5 text-slate-500 transition duration-200 hover:bg-slate-300 hover:text-slate-700"
                                @click="
                                    $store.commit('modal:setData', {
                                        modal_id: 'create_campaign_module',
                                        data: {
                                            is_open: true,
                                            supercampaign_id: supercampaign.id,
                                        },
                                    })
                                "
                            >
                                <font-awesome-icon :icon="['fas', 'plus']" class="m-auto h-3 w-3" />
                            </div>
                        </div>
                    </div>

                    <div class="mt-5 space-y-4">
                        <div
                            v-if="visibleCampaigns.length === 0"
                            class="group cursor-pointer rounded bg-slate-200 py-6 text-slate-500"
                            @click="
                                $store.commit('modal:setData', {
                                    modal_id: 'create_campaign_module',
                                    data: {
                                        is_open: true,
                                        supercampaign_id: supercampaign.id,
                                    },
                                })
                            "
                        >
                            <div class="mb-5 flex">
                                <div class="m-auto rounded-full bg-slate-300 p-3">
                                    <font-awesome-icon :icon="['fas', 'plus']" class="h-10 w-10" />
                                </div>
                            </div>
                            <div class="text-center">
                                <h5 class="text-lg font-semibold">Get Started</h5>
                                <p class="text-xs">
                                    Begin building out your campaign by adding your first campaign module
                                </p>
                            </div>
                            <div class="mt-4 flex">
                                <button class="btn btn-sm mx-auto bg-indigo-500 text-white hover:bg-indigo-600">
                                    Add Module
                                </button>
                            </div>
                        </div>

                        <!-- Group 2 -->
                        <div v-for="campaign in visibleCampaigns" :key="campaign.id">
                            <div class="mb-2 text-xs font-semibold uppercase text-slate-400">
                                {{ getFromDictionary(`campaign.type.${campaign.type}`, 'labelPlural') }}
                            </div>

                            <ul class="flex flex-col flex-nowrap">
                                <router-link
                                    v-if="
                                        ['game-sponsorship', 'key-distribution'].includes(campaign.type) &&
                                        checkPermission('feature.campaigns.manage')
                                    "
                                    v-slot="{ href, navigate, isExactActive }"
                                    :to="{
                                        name: 'campaign-targeting',
                                        params: {
                                            campaign_id: campaign.id,
                                        },
                                    }"
                                    custom
                                >
                                    <li class="mr-0.5 md:mb-0.5 md:mr-0">
                                        <a
                                            class="flex items-center whitespace-nowrap rounded px-2.5 py-2"
                                            :class="isExactActive && 'bg-white'"
                                            :href="href"
                                            @click="navigate"
                                        >
                                            <font-awesome-icon
                                                fixed-width
                                                :icon="['fas', 'bullseye-pointer']"
                                                class="mr-2 h-4 w-4 shrink-0 fill-current"
                                                :class="isExactActive ? 'text-indigo-400' : 'text-slate-400'"
                                            />
                                            <span
                                                class="text-sm font-medium"
                                                :class="isExactActive ? 'text-indigo-600' : 'text-slate-600'"
                                                >Targeting</span
                                            >
                                        </a>
                                    </li>
                                </router-link>

                                <router-link
                                    v-if="
                                        ['game-sponsorship'].includes(campaign.type) &&
                                        $moment(campaign.started_at).isBefore($moment('2023-07-01'))
                                    "
                                    v-slot="{ href, navigate, isExactActive }"
                                    :to="{
                                        name: 'campaign-approval',
                                        params: {
                                            campaign_id: campaign.id,
                                        },
                                    }"
                                    custom
                                >
                                    <li class="mr-0.5 md:mb-0.5 md:mr-0">
                                        <a
                                            class="flex items-center whitespace-nowrap rounded px-2.5 py-2"
                                            :class="isExactActive && 'bg-white'"
                                            :href="href"
                                            @click="navigate"
                                        >
                                            <font-awesome-icon
                                                fixed-width
                                                :icon="['fas', 'ballot-check']"
                                                class="mr-2 h-4 w-4 shrink-0 fill-current"
                                                :class="isExactActive ? 'text-indigo-400' : 'text-slate-400'"
                                            />
                                            <span
                                                class="text-sm font-medium"
                                                :class="isExactActive ? 'text-indigo-600' : 'text-slate-600'"
                                                >Approval</span
                                            >
                                        </a>
                                    </li>
                                </router-link>

                                <router-link
                                    v-if="['game-sponsorship', 'key-distribution'].includes(campaign.type)"
                                    v-slot="{ href, navigate, isExactActive }"
                                    :to="{
                                        name: 'campaign-outreach',
                                        params: {
                                            campaign_id: campaign.id,
                                        },
                                    }"
                                    custom
                                >
                                    <li class="mr-0.5 md:mb-0.5 md:mr-0">
                                        <a
                                            class="flex items-center whitespace-nowrap rounded px-2.5 py-2"
                                            :class="isExactActive && 'bg-white'"
                                            :href="href"
                                            @click="navigate"
                                        >
                                            <font-awesome-icon
                                                fixed-width
                                                :icon="['fas', 'envelope']"
                                                class="mr-2 h-4 w-4 shrink-0 fill-current"
                                                :class="isExactActive ? 'text-indigo-400' : 'text-slate-400'"
                                            />
                                            <span
                                                class="text-sm font-medium"
                                                :class="isExactActive ? 'text-indigo-600' : 'text-slate-600'"
                                                >Outreach</span
                                            >
                                        </a>
                                    </li>
                                </router-link>

                                <router-link
                                    v-if="
                                        (['game-sponsorship'].includes(campaign.type) &&
                                            $moment(campaign.started_at).isAfter($moment('2023-07-01'))) ||
                                        (isSfStaff && ['game-sponsorship', 'key-distribution'].includes(campaign.type))
                                    "
                                    v-slot="{ href, navigate, isExactActive }"
                                    :to="{
                                        name: 'campaign-bids',
                                        params: {
                                            campaign_id: campaign.id,
                                        },
                                    }"
                                    custom
                                >
                                    <li class="mr-0.5 md:mb-0.5 md:mr-0">
                                        <a
                                            class="flex items-center whitespace-nowrap rounded px-2.5 py-2"
                                            :class="isExactActive && 'bg-white'"
                                            :href="href"
                                            @click="navigate"
                                        >
                                            <font-awesome-icon
                                                fixed-width
                                                :icon="['fad', 'messages-dollar']"
                                                class="mr-2 h-4 w-4 shrink-0 fill-current"
                                                :class="isExactActive ? 'text-indigo-400' : 'text-slate-400'"
                                            />
                                            <span
                                                class="text-sm font-medium"
                                                :class="isExactActive ? 'text-indigo-600' : 'text-slate-600'"
                                                >Bids</span
                                            >
                                        </a>
                                    </li>
                                </router-link>

                                <router-link
                                    v-if="
                                        ['game-sponsorship'].includes(campaign.type) &&
                                        $moment(campaign.started_at).isBefore($moment('2023-07-01'))
                                    "
                                    v-slot="{ href, navigate, isExactActive }"
                                    :to="{
                                        name: 'campaign-creators',
                                        params: {
                                            campaign_id: campaign.id,
                                        },
                                    }"
                                    custom
                                >
                                    <li class="mr-0.5 md:mb-0.5 md:mr-0">
                                        <a
                                            class="flex items-center whitespace-nowrap rounded px-2.5 py-2"
                                            :class="isExactActive && 'bg-white'"
                                            :href="href"
                                            @click="navigate"
                                        >
                                            <font-awesome-icon
                                                fixed-width
                                                :icon="['fas', 'user-group']"
                                                class="mr-2 h-4 w-4 shrink-0 fill-current"
                                                :class="isExactActive ? 'text-indigo-400' : 'text-slate-400'"
                                            />
                                            <span
                                                class="text-sm font-medium"
                                                :class="isExactActive ? 'text-indigo-600' : 'text-slate-600'"
                                                >Creators</span
                                            >
                                        </a>
                                    </li>
                                </router-link>

                                <router-link
                                    v-if="
                                        ['game-sponsorship', 'key-distribution'].includes(campaign.type) &&
                                        checkPermission('feature.campaigns.manage')
                                    "
                                    v-slot="{ href, navigate, isExactActive }"
                                    :to="{
                                        name: 'campaign-workflow',
                                        params: {
                                            campaign_id: campaign.id,
                                        },
                                    }"
                                    custom
                                >
                                    <li class="mr-0.5 md:mb-0.5 md:mr-0">
                                        <a
                                            class="flex items-center whitespace-nowrap rounded px-2.5 py-2"
                                            :class="isExactActive && 'bg-white'"
                                            :href="href"
                                            @click="navigate"
                                        >
                                            <font-awesome-icon
                                                fixed-width
                                                :icon="['fad', 'rocket-launch']"
                                                class="mr-2 h-4 w-4 shrink-0 fill-current"
                                                :class="isExactActive ? 'text-indigo-400' : 'text-slate-400'"
                                            />
                                            <span
                                                class="text-sm font-medium"
                                                :class="isExactActive ? 'text-indigo-600' : 'text-slate-600'"
                                                >Workflow</span
                                            >
                                        </a>
                                    </li>
                                </router-link>

                                <router-link
                                    v-if="['game-sponsorship', 'key-distribution'].includes(campaign.type)"
                                    v-slot="{ href, navigate, isExactActive }"
                                    :to="{
                                        name: 'campaign-report',
                                        params: {
                                            campaign_id: campaign.id,
                                        },
                                    }"
                                    custom
                                >
                                    <li class="mr-0.5 md:mb-0.5 md:mr-0">
                                        <a
                                            class="flex items-center whitespace-nowrap rounded px-2.5 py-2"
                                            :class="isExactActive && 'bg-white'"
                                            :href="href"
                                            @click="navigate"
                                        >
                                            <font-awesome-icon
                                                fixed-width
                                                :icon="['fas', 'file-contract']"
                                                class="mr-2 h-4 w-4 shrink-0 fill-current"
                                                :class="isExactActive ? 'text-indigo-400' : 'text-slate-400'"
                                            />
                                            <span
                                                class="text-sm font-medium"
                                                :class="isExactActive ? 'text-indigo-600' : 'text-slate-600'"
                                                >Report</span
                                            >
                                        </a>
                                    </li>
                                </router-link>

                                <router-link
                                    v-if="
                                        ['game-sponsorship', 'key-distribution'].includes(campaign.type) &&
                                        isSfStaff &&
                                        isDevModeEnabled
                                    "
                                    v-slot="{ href, navigate, isExactActive }"
                                    :to="{
                                        name: 'campaign-finance',
                                        params: {
                                            campaign_id: campaign.id,
                                        },
                                    }"
                                    custom
                                >
                                    <li class="mr-0.5 md:mb-0.5 md:mr-0">
                                        <a
                                            class="flex items-center whitespace-nowrap rounded px-2.5 py-2"
                                            :class="isExactActive && 'bg-white'"
                                            :href="href"
                                            @click="navigate"
                                        >
                                            <font-awesome-icon
                                                fixed-width
                                                :icon="['fad', 'coins']"
                                                class="mr-2 h-4 w-4 shrink-0 fill-current"
                                                :class="isExactActive ? 'text-indigo-400' : 'text-slate-400'"
                                            />
                                            <span
                                                class="text-sm font-medium"
                                                :class="isExactActive ? 'text-indigo-600' : 'text-slate-600'"
                                                >Finance</span
                                            >
                                        </a>
                                    </li>
                                </router-link>

                                <router-link
                                    v-if="['steam-broadcast'].includes(campaign.type)"
                                    v-slot="{ href, navigate, isExactActive }"
                                    :to="{
                                        name: 'campaign-slots',
                                        params: {
                                            campaign_id: campaign.id,
                                        },
                                    }"
                                    custom
                                >
                                    <li class="mr-0.5 md:mb-0.5 md:mr-0">
                                        <a
                                            class="flex items-center whitespace-nowrap rounded px-2.5 py-2"
                                            :class="isExactActive && 'bg-white'"
                                            :href="href"
                                            @click="navigate"
                                        >
                                            <font-awesome-icon
                                                fixed-width
                                                :icon="['fas', 'calendar-circle-user']"
                                                class="mr-2 h-4 w-4 shrink-0 fill-current"
                                                :class="isExactActive ? 'text-indigo-400' : 'text-slate-400'"
                                            />
                                            <span
                                                class="text-sm font-medium"
                                                :class="isExactActive ? 'text-indigo-600' : 'text-slate-600'"
                                                >Schedule</span
                                            >
                                        </a>
                                    </li>
                                </router-link>

                                <router-link
                                    v-if="['steam-broadcast'].includes(campaign.type)"
                                    v-slot="{ href, navigate, isExactActive }"
                                    :to="{
                                        name: 'campaign-report-steam',
                                        params: {
                                            campaign_id: campaign.id,
                                        },
                                    }"
                                    custom
                                >
                                    <li class="mr-0.5 md:mb-0.5 md:mr-0">
                                        <a
                                            class="flex items-center whitespace-nowrap rounded px-2.5 py-2"
                                            :class="isExactActive && 'bg-white'"
                                            :href="href"
                                            @click="navigate"
                                        >
                                            <font-awesome-icon
                                                fixed-width
                                                :icon="['fas', 'file-contract']"
                                                class="mr-2 h-4 w-4 shrink-0 fill-current"
                                                :class="isExactActive ? 'text-indigo-400' : 'text-slate-400'"
                                            />
                                            <span
                                                class="text-sm font-medium"
                                                :class="isExactActive ? 'text-indigo-600' : 'text-slate-600'"
                                            >
                                                Report
                                            </span>
                                        </a>
                                    </li>
                                </router-link>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    name: 'CampaignSidebar',

    components: {},

    props: {
        supercampaign: {
            type: Object,
        },
        campaignsById: {
            type: Object,
        },
    },

    data() {
        return {}
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'isSfStaff', 'isDevModeEnabled', 'checkPermission']),

        visibleCampaigns() {
            if (!this.campaignsById || !_.size(this.campaignsById)) return []

            return _(this.campaignsById)
                .filter((campaign) =>
                    ['game-sponsorship', 'steam-broadcast', 'key-distribution'].includes(campaign.type),
                )
                .orderBy(['type'], ['asc'])
                .value()
        },
    },

    methods: {},
}
</script>
