<template>
    <div
        id="changelogfy-launcher"
        class="changelogfy-launcher flex h-8 cursor-pointer rounded bg-slate-100 px-3 py-1 font-semibold text-slate-600 transition duration-200 hover:bg-slate-200"
    >
        <font-awesome-icon :icon="['fas', 'bullhorn']" class="mt-1 h-3.5 w-3.5 opacity-75" />
        <span class="my-auto ml-2 hidden whitespace-nowrap text-sm leading-none xl:inline">Product Updates</span>
    </div>
</template>

<script setup>
import { onMounted } from 'vue'

const loadScript = async () => {
    const script = document.createElement('script')
    script.setAttribute('src', '/scripts/changelogfy.js')
    script.async = true
    document.head.appendChild(script)
}

const startChangelogfy = async () => {
    window.CLF_config = {
        app_id: import.meta.env.VITE_APP_CHANGELOGFY_ID,
    }
}

onMounted(async () => {
    await loadScript()
    await startChangelogfy()
})
</script>
