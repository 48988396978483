<template>
    <div class="flex">
        <div class="flex-1 md:mx-8 xl:mx-4 2xl:mx-8">
            <div v-if="!selectedCreator && !state.loading">
                <p>Please select a creator</p>
            </div>
            <div v-if="selectedCreator" class="bg-white">
                <ParticipantProfile
                    v-if="selectedCreator"
                    :participant="selectedCreator.participant"
                    :superview="selectedCreator.superview"
                    :affinity="selectedCreator.affinity"
                    :campaign="campaignsById[$route.params.campaign_id]"
                    :is-modal="false"
                    default-tab="deliverables"
                />
            </div>
        </div>

        <div class="hidden w-full xl:block xl:w-72">
            <div
                class="no-scrollbar lg:sticky lg:top-16 lg:h-[calc(100vh-64px)] lg:overflow-y-auto lg:overflow-x-visible"
            >
                <div class="">
                    <div class="relative">
                        <label class="sr-only">Search</label>
                        <input
                            v-model="searchText"
                            class="form-input w-full pl-9 focus:border-slate-300"
                            type="search"
                            placeholder="Search…"
                        />
                        <button class="group absolute inset-0 right-auto" type="submit" aria-label="Search">
                            <svg
                                class="ml-3 mr-2 h-4 w-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                                ></path>
                                <path
                                    d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                                ></path>
                            </svg>
                        </button>
                    </div>

                    <div class="group mt-4">
                        <ul class="mb-6">
                            <li
                                v-for="creator in filteredCreators"
                                :key="creator.participant.id"
                                @click="onCreatorClick(creator)"
                            >
                                <button
                                    class="flex w-full justify-between rounded p-2"
                                    :class="{
                                        ' bg-indigo-100':
                                            selectedCreator &&
                                            selectedCreator.participant.id === creator.participant.id,
                                    }"
                                >
                                    <div class="flex grow items-center">
                                        <div class="relative mr-2">
                                            <Avatar
                                                class="bg-slate-700 text-slate-50"
                                                :src="creator.participant.platform_user_logo"
                                                :username="creator.participant.platform_user_name"
                                                :size="32"
                                            />
                                        </div>
                                        <div class="flex truncate">
                                            <span class="text-sm font-medium text-slate-800">{{
                                                creator.participant.platform_user_name
                                            }}</span>
                                            <font-awesome-icon
                                                :icon="['fab', creator.participant.platform_id]"
                                                class="my-auto ml-2 h-4 w-4 shrink-0"
                                                :class="`text-${creator.participant.platform_id}`"
                                            />
                                        </div>
                                    </div>
                                    <div class="self-center">
                                        <font-awesome-icon
                                            v-if="getIncompleteTaskCount(creator) === 0"
                                            :icon="['fas', 'check']"
                                            class="h-4 w-4 shrink-0 text-emerald-500"
                                        />
                                        <span v-else class="text-primary font-weight-bold ml-auto">{{
                                            getIncompleteTaskCount(creator)
                                        }}</span>
                                    </div>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div v-if="searchText && creators && creators.length === 0" class="mt-12 text-center font-semibold">
                    <p>No creators found matching "{{ searchText }}"</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import Avatar from '@/components/Avatar.vue'
import ParticipantProfile from '@/partials/profiles/ParticipantProfile.vue'

export default {
    name: 'CampaignDeliverablesSubpage',

    components: {
        Avatar,
        ParticipantProfile,
    },

    props: {
        supercampaign: Object,
        campaignsById: Object,
    },

    data() {
        return {
            searchText: '',
            creators: [],
            selectedCreator: null,

            state: {
                backfill: {
                    link: '',
                },

                activeTaskId: null,
                activeTaskIndex: null,
                activeParticipantId: null,

                loading: true,
            },
        }
    },

    created() {
        let defaultSelectedParticipantId = null

        if (window.location.hash) {
            defaultSelectedParticipantId = window.location.hash.replace(/^#/, '')
        }

        this.getCreatorsWithTasks(defaultSelectedParticipantId)
    },

    computed: {
        ...mapGetters(['colors', 'getFromDictionary', 'getLanguageFromCode']),

        filteredCreators() {
            let filtered = this.creators
            if (this.searchText) {
                filtered = _.filter(this.creators, (creator) =>
                    creator.participant.platform_user_name.includes(this.searchText),
                )
            }

            return filtered
        },

        isAffinityEnabled() {
            if (!this.supercampaign) return false

            const isEnabled = _.get(this.supercampaign, 'payload.is_affinity_enabled')

            if (isEnabled) {
                if (this.campaign.type === 'game-sponsorship') {
                    return true
                }
            }

            return false
        },
    },

    methods: {
        setActiveCreator(id) {
            this.selectedCreator = _.find(this.creators, (c) => c.participant.id === id)
            window.history.replaceState('', '', `#${id}`)
        },

        onCreatorClick(creator) {
            this.setActiveCreator(creator.participant.id)
        },

        getIncompleteTaskCount(creator) {
            return creator.tasks.participant_tasks - creator.tasks.completed_tasks
        },

        getCreatorsWithTasks(selectedParticipantId) {
            this.state.loading = true
            this.$axios
                .get(
                    `/supercampaigns/${this.$route.params.supercampaign_id}/campaigns/${this.$route.params.campaign_id}/participants/tasks?isClientUI=true`,
                )
                .then(({ data }) => {
                    this.creators = data.payload.creators

                    if (selectedParticipantId) {
                        this.setActiveCreator(selectedParticipantId)
                    }
                    this.state.loading = false
                })
                .catch((error) => {
                    this.state.loading = false

                    if (error.is_cancelled) return

                    let errorMessage = `Error fetching campaign deliverables.`

                    if (_.get(error, 'response.data.message')) {
                        errorMessage = _.get(error, 'response.data.message')
                    }

                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Campaign',
                        text: errorMessage,
                        duration: 10000,
                        speed: 1000,
                    })
                })
        },
    },
}
</script>
