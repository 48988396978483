<script setup>
import { z } from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import { notify } from '@kyvg/vue3-notification'
import { watch, computed } from 'vue'
import { ShoppingCart, Earth, Gamepad2 } from 'lucide-vue-next'

import { axios } from '@/utils/axios'
import { cn } from '@/lib/utils'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'

import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { Separator } from '@/components/ui/separator'
import campaignKeysAPI from '@/utils/api/api.campaign.keys'

const props = defineProps({
    groups: {
        type: Array,
        required: true,
    },
    marketplaces: {
        type: Array,
        required: true,
    },
    platforms: {
        type: Array,
        required: true,
    },
    regions: {
        type: Array,
        required: true,
    },
    supercampaignId: {
        type: String,
        required: true,
    },
    organizationGameId: {
        type: String,
        required: true,
    },
    defaultGroupId: {
        type: String,
        required: false,
    },
})

const emit = defineEmits(['keysAdded'])
const formSchema = z.object({
    key_group: z.string().min(1, { message: 'Key group is required' }),
    platform_id: z.enum(props.platforms.map((platform) => platform.id)),
    marketplace_id: z.enum(props.marketplaces.map((marketplace) => marketplace.id)),
    region_id: z.enum(props.regions.map((region) => region.id)),
    game_keys: z
        .string()
        .min(1, { message: 'Please paste at least one key.' })
        .transform((value) => value.split('\n').filter((key) => key.trim() !== ''))
        .refine((keys) => new Set(keys).size === keys.length, {
            message: 'All keys must be unique.',
        }),
})

const validationSchema = toTypedSchema(formSchema)

const form = useForm({
    validationSchema,
})

const currentDefaultGroupId = computed(() => props.defaultGroupId)

watch(currentDefaultGroupId, (newDefaultGroupId) => {
    form.setFieldValue('key_group', newDefaultGroupId)
})

const onSubmit = form.handleSubmit((values) => {
    const keyGroup = props.groups.find((group) => group.id === values.key_group)
    if (!keyGroup) {
        console.log('No key group found')
    }

    if (keyGroup) {
        campaignKeysAPI
            .addGroupKeys(
                axios,
                props.supercampaignId,
                keyGroup.id,
                values.game_keys,
                keyGroup.type,
                values.platform_id,
                values.marketplace_id,
                values.region_id,
                props.organizationGameId,
            )
            .then((addGroupKeysResponse) => {
                if (addGroupKeysResponse.success) {
                    emit('keysAdded', addGroupKeysResponse.value.keys)
                    notify({
                        title: 'Game Keys Added',
                        text: `Your changes have been saved.`,
                        type: 'success',
                    })
                }
            })
    }
})
</script>

<template>
    <Dialog>
        <DialogContent>
            <DialogHeader>
                <DialogTitle class="pb-3">Add game keys</DialogTitle>
                <Separator class="mb-2" />
                <DialogDescription> Add game keys for your game to a key group. </DialogDescription>
            </DialogHeader>
            <form @submit="onSubmit">
                <FormField v-slot="{ componentField }" name="key_group">
                    <FormItem class="pb-4">
                        <FormLabel class="font-bold">Key group</FormLabel>
                        <Select v-bind="componentField">
                            <FormControl>
                                <SelectTrigger>
                                    <SelectValue placeholder="Select a key group..." />
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectItem v-for="group in props.groups" :key="group.title" :value="group.id">
                                        {{ group.title }}
                                    </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                            <FormMessage />
                        </Select>
                    </FormItem>
                </FormField>
                <FormField v-slot="{ componentField }" name="platform_id">
                    <FormItem class="pb-4">
                        <FormLabel class="font-bold">Platform</FormLabel>
                        <Select v-bind="componentField">
                            <FormControl>
                                <SelectTrigger>
                                    <Gamepad2 class="-ml-1 size-6 text-slate-400" />
                                    <SelectValue placeholder="Select a platform..." />
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectItem
                                        v-for="platform in props.platforms"
                                        :key="platform.id"
                                        :value="platform.id"
                                    >
                                        {{ platform.title }}
                                    </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                            <FormMessage />
                        </Select>
                    </FormItem>
                </FormField>
                <div class="flex gap-3">
                    <FormField v-slot="{ componentField }" name="marketplace_id">
                        <FormItem class="w-1/2 pb-4">
                            <FormLabel class="font-bold">Marketplace</FormLabel>
                            <Select v-bind="componentField">
                                <FormControl>
                                    <SelectTrigger>
                                        <ShoppingCart class="-ml-1 size-6 text-slate-400" />
                                        <SelectValue placeholder="Select a marketplace..." />
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectItem
                                            v-for="marketplace in props.marketplaces"
                                            :key="marketplace.id"
                                            :value="marketplace.id"
                                        >
                                            {{ marketplace.title }}
                                        </SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                                <FormMessage />
                            </Select>
                        </FormItem>
                    </FormField>
                    <FormField v-slot="{ componentField }" name="region_id">
                        <FormItem class="w-1/2 pb-4">
                            <FormLabel class="font-bold">Region</FormLabel>
                            <Select v-bind="componentField">
                                <FormControl>
                                    <SelectTrigger>
                                        <Earth class="-ml-1 size-6 text-slate-400" />
                                        <SelectValue placeholder="Select a region..." />
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectItem v-for="region in props.regions" :key="region.id" :value="region.id">
                                            {{ region.title }}
                                        </SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                                <FormMessage />
                            </Select>
                        </FormItem>
                    </FormField>
                </div>
                <FormField v-slot="{ componentField }" name="game_keys">
                    <FormItem class="pb-4">
                        <FormLabel class="font-bold">Game keys</FormLabel>
                        <FormControl>
                            <Textarea
                                :class="cn('h-60')"
                                placeholder="Paste keys one per line..."
                                v-bind="componentField"
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                </FormField>
                <Separator class="mb-3" />
                <DialogFooter class="sm:justify-end">
                    <DialogClose as-child>
                        <Button type="button" variant="secondary">Close</Button>
                    </DialogClose>
                    <Button type="submit"> Add game keys </Button>
                </DialogFooter>
            </form>
        </DialogContent>
    </Dialog>
</template>
