<template>
    <ModalBlankWideHigh
        id="participant-modal"
        :modal-open="modals.participant.is_open"
        @close-modal="$store.commit('modal:close', 'participant')"
    >
        <ParticipantProfile
            v-if="!!modals?.participant?.creator"
            :participant="modals?.participant?.creator?.participant"
            :superview="modals?.participant?.creator?.superview"
            :affinity="modals?.participant?.creator?.affinity"
            :campaign="modals?.participant?.campaign"
            :is-modal="true"
        />
    </ModalBlankWideHigh>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalBlankWideHigh from '@/components/ModalBlankWideHigh.vue'
import ParticipantProfile from '@/partials/profiles/ParticipantProfile.vue'

export default {
    components: {
        ModalBlankWideHigh,
        ParticipantProfile,
    },

    data() {
        return {}
    },
    computed: {
        ...mapGetters(['modals']),
    },

    methods: {},
}
</script>
