<template>
    <!-- Skeleton body -->
    <div class="col-span-full rounded-sm border border-slate-200 bg-white">
        <!-- youtube|twitch + search -->
        <div class="flex items-center justify-between border-b border-slate-100 px-3 py-3">
            <div class="h-8 w-1/4 animate-pulse rounded bg-slate-200" />
            <div class="h-7 w-1/5 animate-pulse rounded bg-slate-200" />
        </div>

        <!-- Skeleton table body -->
        <div class="p-3">
            <table class="w-full table-auto">
                <!-- Table Head-->
                <thead class="rounded-sm bg-slate-50 text-xs uppercase text-slate-400">
                    <tr>
                        <th class="whitespace-nowrap py-3.5 pl-12 pr-2">
                            <div class="mr-[30px] h-3 w-20 animate-pulse rounded bg-slate-200" />
                        </th>
                        <th class="whitespace-nowrap px-2 py-3.5">
                            <div class="h-3 w-8 animate-pulse rounded bg-slate-200" />
                        </th>
                        <th class="whitespace-nowrap px-2 py-3.5">
                            <div class="h-3 w-8 animate-pulse rounded bg-slate-200" />
                        </th>
                        <th class="whitespace-nowrap px-2 py-3.5">
                            <div class="h-3 w-8 animate-pulse rounded bg-slate-200" />
                        </th>

                        <th class="whitespace-nowrap px-2 py-3.5">
                            <div class="h-3 w-16 animate-pulse rounded bg-slate-200" />
                        </th>
                    </tr>
                </thead>

                <tbody class="divide-y divide-slate-200 border-b border-slate-200 text-sm">
                    <tr v-for="index in 20" :key="index">
                        <td class="whitespace-nowrap px-2 py-3.5">
                            <div class="flex items-center">
                                <div class="mr-2 shrink-0 sm:mr-3">
                                    <div class="h-7 w-7 animate-pulse rounded-full bg-slate-200" />
                                </div>

                                <div class="mr-[30px] h-3 w-20 animate-pulse rounded bg-slate-200" />
                            </div>
                        </td>

                        <td class="whitespace-nowrap px-2 py-3.5">
                            <div class="h-3 w-8 animate-pulse rounded bg-slate-200" />
                        </td>

                        <td class="whitespace-nowrap px-2 py-3.5">
                            <div class="h-3 w-8 animate-pulse rounded bg-slate-200" />
                        </td>

                        <td class="whitespace-nowrap px-2 py-3.5">
                            <div class="h-3 w-8 animate-pulse rounded bg-slate-200" />
                        </td>

                        <td class="whitespace-nowrap px-2 py-3.5">
                            <div class="h-3 w-16 animate-pulse rounded bg-slate-200" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkeletonListsTable',
}
</script>
