<template>
    <Dialog
        v-if="modals.add_creator_to_modal.is_open"
        id="add-creator-to-modal"
        :open="modals.add_creator_to_modal.is_open"
        @update:open="$store.commit('modal:close', 'add_creator_to_modal')"
    >
        <DialogContent :class="'px-1'">
            <DialogHeader>
                <DialogTitle :class="'pl-5'">Add creator to...</DialogTitle>
            </DialogHeader>
            <Separator />
            <AddCreatorMenu
                v-if="modals.add_creator_to_modal.is_open"
                :superviews="modals.add_creator_to_modal.superviews"
                :platform-id="modals.add_creator_to_modal.platform_id"
                :modal-open="modals.add_creator_to_modal.is_open"
            />
        </DialogContent>
    </Dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Separator } from '@/components/ui/separator'
import AddCreatorMenu from '../actions/AddCreatorMenu.vue'

export default {
    components: {
        // eslint-disable-next-line
        Dialog,
        DialogContent,
        DialogHeader,
        DialogTitle,
        Separator,
        AddCreatorMenu,
    },

    data() {
        return {}
    },

    computed: {
        ...mapGetters(['modals']),
    },

    methods: {},
}
</script>
