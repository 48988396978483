<template>
    <div class="mx-auto w-full max-w-9xl px-4 py-8 sm:px-6 md:py-0 lg:px-8">
        <div class="md:ml-8 md:py-8 xl:ml-4 2xl:ml-8">
            <header class="relative border-b border-slate-100 py-2 shadow-sm hover:shadow-sm focus:shadow-sm">
                <div class="flex items-center justify-between">
                    <div class="ml-auto hidden items-center sm:flex">
                        <!-- Right: Actions  -->
                        <div class="relative grid grid-flow-col justify-start gap-2 sm:auto-cols-max sm:justify-end">
                            <form class="relative my-auto" @submit.prevent>
                                <label for="action-search" class="sr-only">Search</label>
                                <input
                                    id="action-search"
                                    v-model="searchText"
                                    class="form-input pl-9 focus:border-slate-300"
                                    type="search"
                                    placeholder="Search..."
                                    @input="onSearch"
                                />
                                <div class="group absolute inset-0 right-auto" aria-label="Search">
                                    <svg
                                        class="ml-3 mr-2 mt-2.5 h-4 w-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                                        viewBox="0 0 16 16"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                                        />
                                        <path
                                            d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                                        />
                                    </svg>
                                </div>
                            </form>
                            <!-- Export button -->
                            <button class="btn bg-indigo-500 text-white hover:bg-indigo-600" @click="onExportCreators">
                                Export Creators
                            </button>
                        </div>
                    </div>
                </div>
            </header>

            <div class="hidden grid-cols-1 xl:grid">
                <div v-if="summary !== null" class="flex flex-wrap xl:h-12 xl:flex-nowrap">
                    <div
                        v-for="tab in tabs"
                        :key="tab.key"
                        class="group flex w-full cursor-pointer select-none border-b border-l border-t bg-white px-4 py-3 text-left text-xs font-medium uppercase text-slate-500 last:rounded-tr last:border-r"
                        :class="{
                            'border-slate-200 hover:bg-slate-50': !isTabSelected(tab.key),
                            'border-b-slate-400': !isTabSelected(tab.key) && activeTab.key !== 'everyone',
                            '!cursor-default border-r border-slate-400 border-b-slate-200 !bg-indigo-50': isTabSelected(
                                tab.key,
                            ),
                        }"
                        @click="setActiveTab(tab.key)"
                    >
                        <div class="flex grow justify-between">
                            <div class="my-auto flex">
                                <span
                                    class="my-auto block h-2 w-2 rounded-full"
                                    :class="getFromDictionary(`campaign.stages.${tab.key}.bgClass`)"
                                />
                                <span class="ml-1.5 leading-none">{{ tab.label }}</span>
                            </div>
                            <div class="my-auto">
                                {{ getTabRecordCount(tab.summaryPath) }}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else class="flex h-12 w-full">
                    <div
                        class="mr-[1px] grow rounded-br border-b border-l border-r bg-slate-50"
                        :class="{
                            'border-l-slate-400': activeTab.key === 'everyone',
                        }"
                    />
                </div>
            </div>

            <SkeletonListsTable v-if="!state.isCreated" />
            <ManageBidsTable
                v-if="state.isCreated"
                :creators="getBidCreators"
                :paging="paging"
                :show-bids="activeTab.key !== 'none'"
                :search-text="searchText"
                @bid-submitted="onBidSubmitted"
                @paging-changed="onPagingChange"
            />
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { json2csvAsync } from 'json-2-csv'
import SkeletonListsTable from '@/partials/skeletons/SkeletonListsTable.vue'
import ManageBidsTable from '../partials/tables/ManageBidsTable.vue'
import bidsAPI from '../utils/api/api.campaign.bids'

export default {
    name: 'BidsPage',

    metaInfo: {
        title: 'Bids',
    },

    components: {
        SkeletonListsTable,
        ManageBidsTable,
    },

    emits: ['change-sorting', 'change-paging'],

    data() {
        return {
            paging: {
                sort: {
                    sortBy: 'name',
                    sortDir: 'asc',
                },
                totalPages: 1,
                perPage: 10,
                currentPage: 1,
            },
            state: {
                isCreated: false,
                isLoading: true,
            },
            activeTab: null,
            tabs: {
                none: {
                    key: 'none',
                    summaryPath: 'none',
                    index: 1,
                    label: 'Available',
                    bgClass: 'bg-sky-500',
                    filters: [{ id: 'bid_status', value: 'none' }],
                },
                /* created: {
                    key: 'created',
                    summaryPath: 'created',
                    index: 2,
                    label: 'Created',
                    bgClass: 'bg-emerald-500',
                    filters: [{ id : 'bid_status', value : 'created' }],
                }, */
                sent: {
                    key: 'sent',
                    summaryPath: 'sent',
                    index: 3,
                    label: 'Sent',
                    bgClass: 'bg-emerald-500',
                    filters: [{ id: 'bid_status', value: 'sent' }],
                },
                accepted: {
                    key: 'accepted',
                    summaryPath: 'accepted',
                    index: 4,
                    label: 'Accepted',
                    bgClass: 'bg-emerald-500',
                    filters: [{ id: 'bid_status', value: 'accepted' }],
                },
                declined: {
                    key: 'declined',
                    summaryPath: 'declined',
                    index: 5,
                    label: 'Declined',
                    bgClass: 'bg-emerald-500',
                    filters: [{ id: 'bid_status', value: 'declined' }],
                },
            },
            summary: {},
            status: '',
            searchText: '',
            platformId: 'twitch',
        }
    },

    created() {
        this.setActiveTab('none')
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'selfOperator', 'selfActiveOrganizationId']),
        getBidCreators() {
            return this.summary.records
        },
    },

    methods: {
        getTabRecordCount(key) {
            const recordCount = _.get(this.summary, key)
            if (!recordCount) {
                return 0
            }
            return recordCount
        },

        getTab(key) {
            return _.get(this.tabs, key)
        },

        isTabSelected(key) {
            return this.activeTab.key === key || this.activeTab.key.split('.')[0] === key
        },

        setActiveTab(key) {
            const keyParts = key.split('.')

            if (keyParts.length === 1) {
                this.activeTab = _.get(this.tabs, key)
            } else {
                this.activeTab = _.find(this.tabs[keyParts[0]].tabs, (item) => item.key === key)
            }
            this.paging.currentPage = 1
            this.getBidSummary()
        },

        createFilters() {
            let filters = [{ id: 'platform_id', value: this.platformId }]
            filters = filters.concat(this.activeTab.filters)

            if (this.searchText) {
                filters.push({ id: 'name', value: this.searchText })
            }

            return filters
        },

        async getBidSummary() {
            this.state.isLoading = true

            const filters = this.createFilters()

            const bidsResponse = await bidsAPI.getRosterBids(
                this.$axios,
                this.selfActiveOrganizationId,
                this.paging,
                filters,
                false,
            )

            if (bidsResponse.success) {
                this.summary = bidsResponse.value
                this.paging.totalPages = Math.ceil(this.summary.paging.totalRecords / this.paging.perPage)
                this.paging.totalRecords = this.summary.paging.totalRecords
                this.state.isLoading = false
                this.state.isCreated = true
            } else {
                this.summary = {}
                this.paging.totalPages = 0
                this.state.isLoading = false
                this.state.isCreated = true
            }
        },

        async onPagingChange(updatedPaging) {
            _.assign(this.paging, updatedPaging)
            await this.getBidSummary()
        },

        async onExportCreators() {
            const filters = this.createFilters()
            const bidsResponse = await bidsAPI.getRosterBids(
                this.$axios,
                this.selfActiveOrganizationId,
                this.paging,
                filters,
                true,
            )
            if (bidsResponse.success) {
                const fileName = `Bids.csv`
                const link = document.createElement('a')
                const creators = bidsResponse.value.records

                const platformCreators = _.map(creators, (exportedCreator) => {
                    let exportedRow = null

                    if (exportedCreator.platform_id === 'twitch') {
                        exportedRow = {
                            'Creator Name': _.get(exportedCreator, 'superview.channel.display_name'),
                            'Connect Page': `https://streamforge.com/twitch/${_.get(
                                exportedCreator,
                                'superview.channel.display_name',
                            )}`,
                            Platform: 'Twitch',
                            Game: _.has(exportedCreator, 'campaign.game.name')
                                ? _.get(exportedCreator, 'campaign.game.name')
                                : '',
                            Starts: _.has(exportedCreator, 'campaign.started_at')
                                ? this.$filters.formatDateAndTime(exportedCreator?.campaign?.started_at)
                                : '',
                            Ends: _.has(exportedCreator, 'campaign.ended_at')
                                ? this.$filters.formatDateAndTime(exportedCreator?.campaign?.ended_at)
                                : '',
                            Status: _.has(exportedCreator, 'bid.status')
                                ? this.getFromDictionary(`campaign.bid.status.${exportedCreator?.bid?.status}`, 'label')
                                : '',
                        }
                    } else if (exportedCreator.platform_id === 'youtube') {
                        exportedRow = {
                            'Creator Name': _.get(exportedCreator, 'superview.channel.snippet.title'),
                            'Connect Page': `https://streamforge.com/youtube/${_.get(
                                exportedCreator,
                                'superview.channel.snippet.title',
                            )}`,
                            Platform: 'YouTube',
                            Game: _.has(exportedCreator, 'campaign.game.name')
                                ? _.get(exportedCreator, 'campaign.game.name')
                                : '',
                            Starts: _.has(exportedCreator, 'campaign.started_at')
                                ? this.$filters.formatDateAndTime(exportedCreator?.campaign?.started_at)
                                : '',
                            Ends: _.has(exportedCreator, 'campaign.ended_at')
                                ? this.$filters.formatDateAndTime(exportedCreator?.campaign?.ended_at)
                                : '',
                            Status: _.has(exportedCreator, 'bid.status')
                                ? this.getFromDictionary(`campaign.bid.status.${exportedCreator?.bid?.status}`, 'label')
                                : '',
                        }
                    }

                    return exportedRow
                })

                const csvResults = await json2csvAsync(platformCreators)

                link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(csvResults)}`)
                link.setAttribute('download', fileName)
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }
        },

        async onSearch() {
            this.bouncedSearch()
        },

        onBidSubmitted() {
            this.getBidSummary()
        },

        bouncedSearch: _.debounce(function () {
            this.paging.currentPage = 1
            this.getBidSummary()
        }, 100),
    },
}
</script>
