<template>
    <metainfo>
        <template #title="{ content }">{{ content }} - Streamforge</template>
    </metainfo>

    <notifications class="px-5 py-3" />

    <div class="relative overflow-hidden">
        <router-view />

        <CreatorModal />
        <CreatorAskAIModal />
        <ParticipantModal />
        <AddCreatorToModal />
    </div>
</template>

<script>
import _ from 'lodash'
import * as Sentry from '@sentry/vue'
import { z } from 'zod'
import { mapGetters } from 'vuex'
import ParticipantModal from '@/partials/modals/ParticipantModal.vue'
import CreatorModal from '@/partials/modals/CreatorModal.vue'
import AddCreatorToModal from '@/partials/modals/AddCreatorToModal.vue'
import CreatorAskAIModal from '@/partials/modals/CreatorAskAIModal/CreatorAskAIModal.vue'

export default {
    components: {
        CreatorAskAIModal,
        ParticipantModal,
        CreatorModal,
        AddCreatorToModal,
    },

    provide() {
        return {
            axios: this.$axios,
            filters: this.$filters,
        }
    },

    computed: {
        ...mapGetters(['tokens', 'isAuthenticated', 'self', 'selfActiveOrganization']),
        query() {
            return this.$route.query
        },
    },

    watch: {
        isAuthenticated(newValue) {
            if (newValue) {
                // Setup Axios
                this.bootstrapAxios()
                this.getSelf()
            } else {
                this?.$intercom?.shutdown()
            }
        },

        tokens(newValue, oldValue) {
            if (newValue.core !== oldValue.core) {
                // Setup Axios so we are sending auth header
                this.bootstrapAxios()
                this.getSelf()
            }
        },

        async selfActiveOrganization(newValue, oldValue) {
            if (!newValue || newValue?.id === oldValue?.id) return

            // console.log(`selfActiveOrganization`, { newValue, oldValue })
            this.bootstrapAxios()
            await this.getSelf()

            // I hate this but couldn't figure out a better way to do it
            // eslint-disable-next-line no-prototype-builtins
            if (this.$route.params.hasOwnProperty('organization_id')) {
                this.$router.push({
                    params: {
                        organization_id: newValue.id,
                    },
                })
            }

            // Setup Axios so we are sending active organization header
            // if (newValue && !oldValue || newValue.id !== oldValue.id) {
            //     console.log(`Bootstrapping axios`)
            //     this.bootstrapAxios()
            // }
        },

        // Watching query params for CreatorProfileModal
        query() {
            if (!this.query && _.size(this.query) <= 0) {
                return
            }

            this.$store.commit('modal:close', 'creator')

            const querySchema = z.object({
                modal: z.enum(['creator']),
                platform_id: z.string(),
                platform_user_id: z.string(),
            })

            const queryValidation = querySchema.safeParse(this.query)

            if (queryValidation.error) {
                return
            }

            this.$store.commit('modal:setData', {
                modal_id: 'creator',
                data: {
                    is_open: true,
                    platform_id: this.query.platform_id,
                    platform_user_id: this.query.platform_user_id,
                },
            })
        },
    },

    created() {
        if (this.isAuthenticated) {
            this.bootstrapAxios()
            this.getSelf()
        }

        // if (this.$route.hash) {
        //     const unhashedHash = this.$route.hash.slice(1)
        //     const allowedTypes = ['modal:creator']
        //     const hashParts = unhashedHash.split('|')

        //     if (hashParts && hashParts.length > 1) {
        //         const typeString = hashParts.shift()

        //         if (typeString && allowedTypes.includes(typeString)) {
        //             const [type, subtype] = typeString.split(':')

        //             const attributes = _.reduce(hashParts, (hashPart) => {
        //                 const [key, value] = hashPart.split(':')
        //                 result[key] = value
        //                 return result
        //             }, {})

        //             if (type === 'modal') {
        //                 this.$store.commit('modal:setData', {
        //                     modal_id: subtype,
        //                     data: {
        //                         is_open: true,
        //                         ...attributes
        //                     },
        //                 })
        //             }
        //         }
        //     }
        // }
    },

    methods: {
        async getSelf() {
            this?.$intercom?.shutdown()

            return this.$axios
                .get(`/me`, {
                    noCancel: true,
                })
                .then(async ({ data }) => {
                    this.$store.commit('self:operator', {
                        operator: data.payload.operator,
                    })

                    this.$store.commit('self:organizations', {
                        organizations: data.payload.organizations,
                    })

                    if (data.payload.operator && data.payload.organizations && data.payload.organizations.length) {
                        const isSfStaff =
                            !!_.find(data.payload.organizations, (organization) => organization.type === 'admin') ||
                            data.payload.operator.email.includes('@streamforge.com')

                        // Don't log sessions of staff
                        if (import.meta.env.MODE === 'production') {
                            if (!isSfStaff) {
                                console.log(`Logrocket initialized!`)
                                await this.$logRocket.init('cxu3yt/client-dashboard')
                                await this.$logRocket.identify(data.payload.operator.id, {
                                    name: `${data.payload.operator.first_name} ${data.payload.operator.last_name}`,
                                    email: data.payload.operator.email,
                                })

                                this.$openReplayTracker.start()
                                this.$openReplayTracker.setUserID(data.payload.operator.id)
                                this.$openReplayTracker.setMetadata('email', data.payload.operator.email)
                                this.$openReplayTracker.setMetadata('joined_at', data.payload.operator.joined_at)
                            }

                            this.$intercom.boot({
                                user_hash: data.payload.operatorHash,
                                user_id: data.payload.operator.id,
                                name: `${data.payload.operator.first_name} ${data.payload.operator.last_name}`,
                                email: data.payload.operator.email,
                                created_at: data.payload.operator.joined_at,
                            })

                            // this.$intercom.show()
                        }

                        Sentry.setUser({
                            id: data.payload.operator.id,
                            email: data.payload.operator.email,
                            name: `${data.payload.operator.first_name} ${data.payload.operator.last_name}`,
                            organizations: JSON.stringify(data.payload.organizations),
                        })

                        const hasExistingActiveOrganization = !!this.selfActiveOrganization

                        const isExistingActiveOrganizationPermitted =
                            hasExistingActiveOrganization &&
                            _.find(
                                data.payload.organizations,
                                (organization) => organization.id === this.selfActiveOrganization.id,
                            )

                        /* if (!hasExistingActiveOrganization) {
                            console.log(`No existing active organization, trigger set first`)
                        } else if (!isExistingActiveOrganizationPermitted) {
                            console.log(`Existing active orgnization is stale, triggering set first`)
                        } else {
                            console.log(`Existing active organization is fine, skip`)
                        } */

                        if (!hasExistingActiveOrganization || !isExistingActiveOrganizationPermitted) {
                            this.$store.commit('self:activeOrganizationId', {
                                id: _.first(data.payload.operator.organizations).id,
                            })
                        }
                    }

                    this.$store.commit('permissions:setup', data.payload.permissions)
                    this.$store.commit('permissions:isLoaded', true)

                    return true
                })
                .catch((error) => {
                    console.error(error)

                    let message = `Failed to find your account info.`
                    const apiMessage = _.get(error, 'response.data.payload.message')
                    if (apiMessage) message = apiMessage

                    this.$notify({
                        group: 'global',
                        type: 'alert',
                        title: 'Error - Account data not found',
                        text: message,
                        duration: 10000,
                        speed: 1000,
                    })
                })
        },

        bootstrapAxios() {
            this.$axios.defaults.headers.Authorization = `Bearer ${this.tokens.core}`

            if (this.selfActiveOrganization && this.selfActiveOrganization.id) {
                this.$axios.defaults.headers['x-active-organization-id'] = this.selfActiveOrganization.id

                // Make sure we remove this if no active organization is set
            } else {
                delete this.$axios.defaults.headers['x-active-organization-id']
            }

            this.$axios.interceptors.response.use(
                (response) => {
                    // If request using a bearer token fails, it has expired, and log them out
                    if (response.status === 401) {
                        // Save the URL the user was trying to access so we can redirect to it after
                        // So long as it is not an admin route
                        if (this.$route.fullpath) {
                            this.$store.commit('authentication:redirect', this.$route.fullPath)
                        } else {
                            this.$store.commit('authentication:redirect', null)
                        }

                        // Logout
                        this.$store.commit('authentication:logout')

                        // Clear user info
                        this.$store.commit('self:clear')

                        this.$router.push({ name: 'login' })
                    }

                    return response
                },

                (error) => Promise.reject(error),
            )
        },
    },
}
</script>
