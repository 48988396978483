import _ from 'lodash'
import api from './api'
import targetGroupTools from '../searching/targetgroups'

const apitargeting = {
    async getEntityTargetGroups(axios, entityType, entityId, defaults) {
        const endpoint = `/targeting/${entityType}/${entityId}`

        return axios
            .get(endpoint)
            .then(({ data }) => {
                const mappedGroups = _.map(data.payload.groups, (group) =>
                    targetGroupTools.mapTargetGroupFromFilters(group, data.payload.codes, defaults),
                )

                return api.createSuccessResponse(mappedGroups)
            })
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getAvailableTargetGroups(axios) {
        const endpoint = `/targeting/combined`

        return axios
            .get(endpoint)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apitargeting
