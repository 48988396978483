<template>
    <ModalBasic
        :title="
            modal.constraint.state.type === 'create'
                ? `Add Constraint`
                : `Edit Constraint - ${getFromDictionary(`task.type.${modal.constraint.state.taskType}`, 'label')}`
        "
        :modal-open="modal.constraint.state.isOpen"
        @close-modal="onConstraintModalClose"
    >
        <div v-if="modal.constraint.state.isOpen">
            <!-- Modal content -->
            <div class="px-5 py-6">
                <div class="grid gap-4 text-sm md:grid-cols-12">
                    <div v-if="modal.constraint.state.type === 'create'" class="col-span-12 md:col-span-6">
                        <label class="mb-2 block text-sm font-medium">Deliverable Type</label>
                        <select v-model="modal.constraint.state.taskType" class="form-select w-full">
                            <option
                                v-for="taskTypeId in getPlatformUncreatedTaskTypes(modal.constraint.state.platformId)"
                                :key="taskTypeId"
                                :value="taskTypeId"
                            >
                                {{ getFromDictionary(`task.type.${taskTypeId}`, 'label') }}
                            </option>
                        </select>
                    </div>

                    <!-- Spacer -->
                    <div v-if="modal.constraint.state.type === 'create'" class="col-span-12 md:col-span-6"></div>

                    <div
                        v-for="({ field, values, state }, index) in modal.constraint.values"
                        :key="index"
                        class="col-span-12"
                    >
                        <label :for="field.id" class="block text-sm font-medium text-gray-700">
                            {{ field.label }}
                        </label>
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <select v-model="state.operator" class="form-input mb-2 block w-full pr-12 sm:text-sm">
                                <option v-for="operator in field.operators" :key="operator" :value="operator">
                                    {{ getFromDictionary(`task.operators.${operator}`, 'label') }}
                                </option>
                            </select>

                            <template v-if="state.operator !== 'btwn'">
                                <template v-if="field.type === 'datetime'">
                                    <div class="relative">
                                        <flat-pickr
                                            v-model="values.eq"
                                            class="form-input w-full pl-9 font-medium text-slate-500 hover:text-slate-600 focus:border-slate-300"
                                            :config="configuration.flatPickr"
                                        />
                                        <div class="pointer-events-none absolute inset-0 right-auto flex items-center">
                                            <svg class="ml-3 h-4 w-4 fill-current text-slate-500" viewBox="0 0 16 16">
                                                <path
                                                    d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <input
                                        :id="field.name"
                                        v-model="values.eq"
                                        :name="field.name"
                                        :type="field.type"
                                        :placeholder="field.placeholder"
                                        class="form-input block w-full rounded-md sm:text-sm"
                                    />
                                </template>
                            </template>

                            <div v-else class="grid grid-cols-2 gap-4">
                                <template v-if="field.type === 'datetime'">
                                    <div class="relative">
                                        <flat-pickr
                                            v-model="values.min"
                                            class="form-input w-full pl-9 font-medium text-slate-500 hover:text-slate-600 focus:border-slate-300"
                                            :config="configuration.flatPickr"
                                        />
                                        <div class="pointer-events-none absolute inset-0 right-auto flex items-center">
                                            <svg class="ml-3 h-4 w-4 fill-current text-slate-500" viewBox="0 0 16 16">
                                                <path
                                                    d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z"
                                                />
                                            </svg>
                                        </div>
                                    </div>

                                    <div class="relative">
                                        <flat-pickr
                                            v-model="values.max"
                                            class="form-input w-full pl-9 font-medium text-slate-500 hover:text-slate-600 focus:border-slate-300"
                                            :config="configuration.flatPickr"
                                        />
                                        <div class="pointer-events-none absolute inset-0 right-auto flex items-center">
                                            <svg class="ml-3 h-4 w-4 fill-current text-slate-500" viewBox="0 0 16 16">
                                                <path
                                                    d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <input
                                        :id="field.name + 'Min'"
                                        v-model="values.min"
                                        :name="field.name + 'Min'"
                                        :type="field.type"
                                        :placeholder="'Min ' + field.placeholder"
                                        class="form-input block w-full sm:text-sm"
                                    />
                                    <input
                                        :id="field.name + 'Max'"
                                        v-model="values.max"
                                        :name="field.name + 'Max'"
                                        :type="field.type"
                                        :placeholder="'Max ' + field.placeholder"
                                        class="form-input block w-full sm:text-sm"
                                    />
                                </template>
                            </div>
                        </div>
                    </div>

                    <div v-if="modal.type === 'update'"></div>

                    <div v-if="modal.type === 'delete'">
                        <p>Are you sure you want to delete this constraint?</p>
                    </div>
                </div>
            </div>

            <!-- Modal footer -->
            <div class="flex border-t border-slate-200 px-5 py-4">
                <div v-if="modal.constraint.state.type !== 'create'" class="flex flex-wrap justify-start space-x-2">
                    <button
                        class="btn-sm bg-rose-500 text-white hover:bg-rose-600"
                        @click="onConstraintModalDelete(modal.constraint)"
                    >
                        Delete
                    </button>
                </div>
                <div class="ml-auto flex flex-wrap justify-end space-x-2">
                    <button
                        class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                        @click="modal.constraint.state.isOpen = false"
                    >
                        Cancel
                    </button>

                    <button
                        class="btn-sm text-white"
                        :class="['bg-indigo-500', 'hover:bg-indigo-600']"
                        @click="onConstraintModalSubmit(modal.constraint)"
                    >
                        {{ modal.constraint.state.type === 'create' ? `Create` : `Save` }}
                    </button>
                </div>
            </div>
        </div>
    </ModalBasic>

    <div class="col-span-12 space-y-16 md:col-span-8">
        <template v-for="platformId in platformIds" :key="platformId">
            <div>
                <div class="mb-5 flex space-x-2">
                    <div class="flex grow rounded bg-slate-100 px-4 py-1.5 text-sm font-semibold">
                        <font-awesome-icon
                            :icon="['fab', platformId]"
                            class="my-auto h-3.5 w-3.5"
                            :class="`text-${platformId}`"
                        />
                        <span class="ml-2 capitalize">{{ platformId }}</span>
                    </div>
                    <div
                        v-if="getPlatformUncreatedTaskTypes(platformId).length > 0"
                        class="flex w-12 cursor-pointer rounded bg-indigo-500 text-sm font-semibold text-white hover:bg-indigo-600"
                        @click="openCreateConstraintModel(platformId)"
                    >
                        <font-awesome-icon :icon="['fas', 'plus']" class="m-auto h-3.5 w-3.5 transition duration-200" />
                    </div>
                </div>

                <div class="grid gap-4 md:grid-cols-1">
                    <div v-if="task_constraints !== null">
                        <div
                            v-for="(constraint, constraintKey) in task_constraints?.[platformId]"
                            :key="constraintKey"
                            class="group rounded border border-slate-200 bg-white p-4"
                        >
                            <div class="relative flex grow text-sm">
                                <div class="grow pt-1">
                                    <div class="text truncate">
                                        <span class="font-medium capitalize text-slate-800">{{
                                            getFromDictionary(`task.type.${constraint.type}`, 'label')
                                        }}</span>
                                    </div>

                                    <div v-for="(field, fieldId) in constraint.fields" :key="fieldId" class="flex">
                                        <div class="w-44">{{ getTaskField(constraint.type, fieldId).label }}</div>

                                        <template v-if="getTaskField(constraint.type, fieldId).type === 'datetime'">
                                            <div v-if="field.state.operator === 'eq'">
                                                {{ $filters.formatDateAndTime(field.values.eq) }}
                                            </div>

                                            <div v-if="field.state.operator === 'btwn'">
                                                between {{ $filters.formatDateAndTime(field.values.min) }} and
                                                {{ $filters.formatDateAndTime(field.values.max) }}
                                            </div>
                                        </template>

                                        <template v-else>
                                            <div v-if="field.state.operator === 'eq'">
                                                {{ field.values.eq }}
                                            </div>

                                            <div v-if="field.state.operator === 'btwn'">
                                                between {{ field.values.min }} and {{ field.values.max }}
                                            </div>
                                        </template>
                                    </div>
                                </div>

                                <font-awesome-icon
                                    :icon="['fas', 'pen-to-square']"
                                    class="h-4 w-4 cursor-pointer text-slate-500 transition duration-200 hover:text-slate-600"
                                    @click="openEditConstraintModal(constraint, platformId)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-if="!hasAnyConstraint()">
            <div class="rounded-sm border border-rose-200 bg-rose-100 px-4 py-2 text-sm text-rose-600">
                <div class="flex w-full items-start justify-between">
                    <div class="flex">
                        <svg class="mr-3 mt-[3px] h-4 w-4 shrink-0 fill-current opacity-80" viewBox="0 0 16 16">
                            <path
                                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
                            ></path>
                        </svg>
                        <div>No constraints are set for the campaign.</div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import ModalBasic from '@/components/ModalBasic.vue'

export default {
    name: 'TasksConstraints',

    components: {
        ModalBasic,
        flatPickr,
    },

    props: {
        task_constraints: {
            type: Object,
            defaultValue: {},
        },

        platformIds: Array,
        supercampaignId: String,
        campaign: Object,
    },

    emits: ['constraint-updated'],

    data() {
        return {
            constraintsByTaskType: {
                'twitch-stream': {
                    fields: [
                        {
                            id: 'started_at',
                            label: 'Start At',
                            type: 'datetime',
                            placeholder: 'Enter stream start time',
                            operators: ['eq', 'btwn'],
                            defaultValue: () => ({
                                eq: moment(this.campaign.started_at).format(),
                                min: moment(this.campaign.started_at).format(),
                                max: moment(this.campaign.started_at).add(8, 'hours').format(),
                            }),
                        },
                        {
                            id: 'duration_hours',
                            label: 'Duration (hours)',
                            type: 'number',
                            placeholder: 'Enter stream duration',
                            operators: ['eq', 'btwn'],
                            defaultValue: () => ({
                                eq: 2,
                                min: 2,
                                max: 4,
                            }),
                        },
                    ],
                },

                'youtube-video': {
                    fields: [
                        {
                            id: 'started_at',
                            label: 'Start At',
                            type: 'datetime',
                            placeholder: 'Enter video post time',
                            operators: ['eq', 'btwn'],
                            defaultValue: () => ({
                                eq: moment(this.campaign.started_at).format(),
                                min: moment(this.campaign.started_at).format(),
                                max: moment(this.campaign.started_at).add(8, 'hours').format(),
                            }),
                        },
                    ],
                },

                'tiktok-video': {
                    fields: [
                        {
                            id: 'started_at',
                            label: 'Start At',
                            type: 'datetime',
                            placeholder: 'Enter video post time',
                            operators: ['eq', 'btwn'],
                            defaultValue: () => ({
                                eq: moment(this.campaign.started_at).format(),
                                min: moment(this.campaign.started_at).format(),
                                max: moment(this.campaign.started_at).add(8, 'hours').format(),
                            }),
                        },
                        {
                            id: 'video_count',
                            label: 'Video Count',
                            type: 'number',
                            placeholder: 'Enter how many videos you want to post',
                            operators: ['eq', 'btwn'],
                            defaultValue: () => ({
                                eq: 1,
                                min: 1,
                                max: 10,
                            }),
                        },
                    ],
                },

                'twitter-post': {
                    fields: [
                        {
                            id: 'started_at',
                            label: 'Start At',
                            type: 'datetime',
                            placeholder: 'Enter video post time',
                            operators: ['eq', 'btwn'],
                            defaultValue: () => ({
                                eq: moment(this.campaign.started_at).format(),
                                min: moment(this.campaign.started_at).format(),
                                max: moment(this.campaign.started_at).add(8, 'hours').format(),
                            }),
                        },
                    ],
                },

                'instagram-post': {
                    fields: [
                        {
                            id: 'started_at',
                            label: 'Start At',
                            type: 'datetime',
                            placeholder: 'Enter video post time',
                            operators: ['eq', 'btwn'],
                            defaultValue: () => ({
                                eq: moment(this.campaign.started_at).format(),
                                min: moment(this.campaign.started_at).format(),
                                max: moment(this.campaign.started_at).add(8, 'hours').format(),
                            }),
                        },
                    ],
                },
            },

            platformTaskTypes: {
                twitch: ['twitch-stream', 'twitch-panel'],
                youtube: ['youtube-video'],
                tiktok: ['tiktok-video'],
                twitter: ['twitter-post'],
                instagram: ['instagram-post'],
            },

            platformDefaultTask: {
                twitch: { type: 'twitch-stream' },
                youtube: { type: 'youtube-video' },
                tiktok: { type: 'tiktok-video' },
                twitter: { type: 'twitter-post' },
                instagram: { type: 'instagram-post' },
            },

            modal: {
                constraint: {
                    values: {},

                    state: {
                        isOpen: false,
                        type: 'create',
                        platformId: 'twitch',
                        taskType: 'twitch-stream',
                    },
                },
            },

            configuration: {
                flatPickr: {
                    enableTime: true,
                    dateFormat: 'Z',
                    altFormat: 'F j, Y h:i K',
                    altInput: true,
                },
            },
        }
    },

    watch: {
        'modal.constraint.state.taskType': function (newValue, oldValue) {
            if (newValue === oldValue || this.modal.constraint.state.type === 'edit') return
            this.setConstraintModalFields()
        },
    },

    computed: {
        ...mapGetters(['getFromDictionary']),
    },

    methods: {
        openCreateConstraintModel(platformId) {
            const constraintDefault = this.platformDefaultTask[platformId]
            this.openConstraintModal('create', constraintDefault, platformId)
        },

        openEditConstraintModal(constraint, platformId) {
            this.openConstraintModal('edit', constraint, platformId)
        },

        openConstraintModal(type, constraint, platformId) {
            this.modal.constraint.state.isOpen = true
            this.modal.constraint.state.type = type
            this.modal.constraint.state.platformId = platformId

            if (type === 'create') {
                this.modal.constraint.state.taskType = _.first(this.platformTaskTypes[platformId])
                this.setConstraintModalFields()
            } else if (type === 'edit') {
                this.modal.constraint.state.taskType = constraint.type
                this.setConstraintModalFields(constraint)
            }
        },

        setConstraintModalFields(constraint) {
            this.modal.constraint.values = {}

            if (constraint) {
                const fields = _.get(this.constraintsByTaskType[this.modal.constraint.state.taskType], 'fields')

                if (fields) {
                    _.forEach(fields, (taskField) => {
                        let state = { operator: _.first(taskField.operators) }
                        let values = taskField.defaultValue()

                        if (Object.hasOwn(constraint, 'fields') && Object.hasOwn(constraint.fields, taskField.id)) {
                            if (constraint.fields[taskField.id].state) {
                                state = constraint.fields[taskField.id].state
                            }
                            if (constraint.fields[taskField.id].values) {
                                values = constraint.fields[taskField.id].values
                            }
                        }

                        this.modal.constraint.values[taskField.id] = {
                            field: taskField,
                            state,
                            values,
                        }
                    })
                }

                // Use defaults
            } else {
                const fields = _.get(this.constraintsByTaskType[this.modal.constraint.state.taskType], 'fields')

                if (fields) {
                    _.forEach(fields, (taskField) => {
                        this.modal.constraint.values[taskField.id] = {
                            field: taskField,
                            state: {
                                operator: _.first(taskField.operators),
                            },
                            values: taskField.defaultValue(),
                        }
                    })

                    // If there is no config for that task type, wipe all values
                } else {
                    this.modal.constraint.values = {}
                }
            }
        },

        onConstraintModalClose() {
            this.modal.constraint.state.isOpen = false
        },

        hasAnyConstraint() {
            if (this.task_constraints) {
                // eslint-disable-next-line no-restricted-syntax
                for (const platform in this.platformTaskTypes) {
                    if (this.task_constraints[platform] && this.task_constraints[platform].length > 0) {
                        return true
                    }
                }
            }

            return false
        },

        getTaskField(taskType, fieldId) {
            const fields = _.get(this.constraintsByTaskType[taskType], 'fields')
            return _.find(fields, (field) => field.id === fieldId)
        },

        getPlatformUncreatedTaskTypes(platformId) {
            const platformTasks = this.platformTaskTypes[platformId]
            let existingTaskIds = []

            if (this.task_constraints && this.task_constraints[platformId]) {
                existingTaskIds = _.map(this.task_constraints[platformId], (task) => task.type)
            }

            return _.filter(platformTasks, (platformTask) => !_.includes(existingTaskIds, platformTask))
        },

        async onConstraintModalDelete() {
            const updatedConstraints = _.cloneDeep(this.task_constraints)

            if (updatedConstraints[this.modal.constraint.state.platformId]) {
                updatedConstraints[this.modal.constraint.state.platformId] = _.filter(
                    updatedConstraints[this.modal.constraint.state.platformId],
                    (constraint) => constraint.type !== this.modal.constraint.state.taskType,
                )
            }

            this.$emit('constraint-updated', this.campaign.id, updatedConstraints)
            this.modal.constraint.state.isOpen = false
        },

        async onConstraintModalSubmit() {
            const updatedConstraints = _.cloneDeep(this.task_constraints)

            const constraintToSave = {
                type: this.modal.constraint.state.taskType,
                fields: _.mapValues(this.modal.constraint.values, ({ state, values }) => ({
                    state,
                    values,
                })),
            }

            if (!updatedConstraints[this.modal.constraint.state.platformId]) {
                updatedConstraints[this.modal.constraint.state.platformId] = []
            } else {
                updatedConstraints[this.modal.constraint.state.platformId] = _.filter(
                    updatedConstraints[this.modal.constraint.state.platformId],
                    (constraint) => constraint.type !== this.modal.constraint.state.taskType,
                )
            }

            updatedConstraints[this.modal.constraint.state.platformId].push(constraintToSave)

            this.$emit('constraint-updated', this.campaign.id, updatedConstraints)
            this.modal.constraint.state.isOpen = false
        },
    },
}
</script>
