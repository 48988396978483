<template>
    <svg v-if="hasText" viewBox="0 0 1080 231" class="logo has-text w-full" :class="[colorSet, variant]">
        <path class="cls-1" d="M114.73,34.23a82,82,0,1,1,0,163.9H32.78V116.18A82,82,0,0,1,114.73,34.23Z" />
        <path class="cls-2" d="M114.73,67a49.17,49.17,0,1,1,0,98.34H65.56V116.18A49.23,49.23,0,0,1,114.73,67Z" />
        <path
            class="cls-3"
            d="M114.73,99.79h16.39a0,0,0,0,1,0,0v16.39a16.39,16.39,0,0,1-16.39,16.39h0a16.39,16.39,0,0,1-16.39-16.39v0A16.39,16.39,0,0,1,114.73,99.79Z"
            transform="translate(229.47 232.36) rotate(180)"
        />
        <path
            class="cls-4"
            d="M276.82,112.88c-1-4.81-5.3-8.76-12.51-8.76-6.43,0-11.49,2.92-11.44,7.4-.05,3.61,2.53,6,9.49,7.5l13.54,2.73c14.51,3,21.57,9.44,21.62,20.25,0,14.76-13.93,24.64-33.31,24.64-19.82,0-32-8.81-33.94-23.13l20.41-1.07c1.26,6.09,6.18,9.25,13.58,9.25s12.08-3.16,12.17-7.59c-.09-3.75-3.06-6.14-9.59-7.45l-12.95-2.58c-14.61-2.92-21.67-10.18-21.62-21.38-.05-14.41,12.56-23.27,31.7-23.27,18.89,0,30,8.52,31.84,22.3Z"
        />
        <path
            class="cls-4"
            d="M350.43,106H336.35V142.2c0,5.74,2.88,7.3,7,7.3a29,29,0,0,0,5.17-.68l3.26,15.44a40.26,40.26,0,0,1-11.25,1.94c-15,.64-25-6.62-24.93-21.27V106H305.39V90.39h10.22V72.47h20.74V90.39h14.08Z"
        />
        <path
            class="cls-4"
            d="M363.93,90.39H384v13h.78c2.73-9.25,9.59-14.12,18-14.12a31.09,31.09,0,0,1,6.53.73v18.4a35.14,35.14,0,0,0-8.77-1.11c-9.15,0-15.87,6.32-15.87,15.53v42.31H363.93Z"
        />
        <path
            class="cls-4"
            d="M413.48,128.13c0-23.08,14.26-38.71,36.27-38.71,19.77,0,35.16,12.56,35.16,38v5.7H434v0c0,11.15,6.57,18,16.94,18,6.92,0,12.27-3,14.36-8.67l19.19,1.26c-2.92,13.88-15.39,22.89-33.89,22.89C427.55,166.64,413.48,151.84,413.48,128.13Zm51.9-7.89c-.05-8.91-6.28-15.34-15.29-15.34A15.79,15.79,0,0,0,434,120.24Z"
        />
        <path
            class="cls-4"
            d="M495,144.29c0-16.7,13.29-21.67,28.1-23,13-1.27,18.11-1.9,18.11-6.72v-.29c0-6.14-4-9.69-11.1-9.69-7.45,0-11.88,3.65-13.3,8.62l-19.18-1.56c2.87-13.63,14.66-22.2,32.58-22.2,16.65,0,31.74,7.5,31.74,25.31v50.45H542.25V154.81h-.58c-3.65,7-10.81,11.79-21.87,11.79C505.54,166.6,495,159.09,495,144.29Zm46.36-5.84v-7.94c-2.49,1.66-9.89,2.73-14.42,3.36-7.3,1-12.12,3.9-12.12,9.74s4.57,8.67,11,8.67C534.85,152.28,541.33,146.29,541.33,138.45Z"
        />
        <path
            class="cls-4"
            d="M578.07,90.39h19.77v13.19h.88c3.11-8.81,10.76-14.16,20.84-14.16,10.27,0,17.91,5.5,20.3,14.16h.78c3.07-8.57,11.59-14.16,22.54-14.16,13.93,0,23.57,9.25,23.57,25.46v50.3H666.06V119c0-8.32-5-12.46-11.59-12.46-7.55,0-12,5.26-12,13v45.67H622.38V118.54c0-7.36-4.58-12-11.39-12s-12.18,5.5-12.18,13.78v44.89H578.07Z"
        />
        <path
            class="cls-4"
            d="M743.38,106H728.53v59.21H707.79V106H697.22V90.39h10.57V85c0-16.27,10.47-24.21,24.54-24.21a54.48,54.48,0,0,1,14.65,2l-3.65,15.58a22.8,22.8,0,0,0-6.67-1.07c-6.08,0-8.13,3-8.13,8v5h14.85Z"
        />
        <path
            class="cls-4"
            d="M750.41,128.08c0-23.18,14.12-38.66,36.81-38.66S824,104.9,824,128.08c0,23-14.12,38.56-36.82,38.56S750.41,151.11,750.41,128.08Zm52.54-.15c0-13.05-5.35-22.69-15.63-22.69-10.47,0-15.82,9.64-15.82,22.69s5.35,22.64,15.82,22.64C797.6,150.57,803,141,803,127.93Z"
        />
        <path
            class="cls-4"
            d="M837.6,90.39h20.12v13h.77c2.73-9.25,9.6-14.12,18-14.12a31,31,0,0,1,6.52.73v18.4a35,35,0,0,0-8.76-1.11c-9.15,0-15.87,6.32-15.87,15.53v42.31H837.6Z"
        />
        <path
            class="cls-4"
            d="M889.68,174l19.19-2.59c1.7,4,6.33,8,15.48,8s15.58-4,15.58-13.78V151.94h-.87c-2.73,6.18-9.4,12.17-21.33,12.17-16.85,0-30.34-11.59-30.34-36.47,0-25.46,13.88-38.22,30.29-38.22,12.51,0,18.6,7.45,21.38,13.53h.77V90.39h20.6v75.52c0,18.65-15.19,28.39-36.57,28.39C903.7,194.3,892.16,185.24,889.68,174ZM940,127.54c0-13.1-5.65-21.62-15.73-21.62-10.27,0-15.73,8.91-15.73,21.62,0,12.9,5.55,21,15.73,21C934.28,148.53,940,140.74,940,127.54Z"
        />
        <path
            class="cls-4"
            d="M974,128.13c0-23.08,14.27-38.71,36.28-38.71,19.77,0,35.15,12.56,35.15,38v5.7H994.5v0c0,11.15,6.58,18,17,18,6.91,0,12.26-3,14.36-8.67l19.18,1.26c-2.92,13.88-15.38,22.89-33.89,22.89C988.07,166.64,974,151.84,974,128.13Zm51.91-7.89c-.05-8.91-6.28-15.34-15.29-15.34a15.8,15.8,0,0,0-16.12,15.34Z"
        />
    </svg>

    <svg v-else viewBox="0 0 1080 1080" class="logo textless" :class="colorSet">
        <path
            class="cls-1"
            d="M540,154.29c212.69,0,385.71,173,385.71,385.71S752.69,925.71,540,925.71H154.29V540C154.29,327.31,327.31,154.29,540,154.29Z"
        />
        <path
            class="cls-2"
            d="M540,308.57c127.61,0,231.43,103.82,231.43,231.43S667.61,771.43,540,771.43H308.57V540C308.57,412.39,412.39,308.57,540,308.57Z"
        />
        <path
            class="cls-3"
            d="M540,462.86h77.14a0,0,0,0,1,0,0V540A77.14,77.14,0,0,1,540,617.14h0A77.14,77.14,0,0,1,462.86,540v0A77.14,77.14,0,0,1,540,462.86Z"
            transform="translate(1080 1080) rotate(180)"
        />
    </svg>
</template>

<script>
export default {
    name: 'Logo',

    props: {
        hasText: {
            type: Boolean,
            default: false,
        },

        variant: {
            type: String,
            default: 'dark',
        },

        colorSet: {
            type: String,
            default: 'brand',
        },
    },
}
</script>

<style lang="scss">
.logo {
    &.has-text {
        &.light {
            .cls-4 {
                fill: #fff;
            }
        }

        &.dark {
            .cls-4 {
                fill: rgb(30 41 59 / var(--tw-text-opacity));
            }
        }
    }

    &.brand {
        .cls-1 {
            fill: #ff005c;
        }

        .cls-2 {
            fill: #ff9171;
        }

        .cls-3 {
            fill: #fcd878;
        }
    }

    &.monotone {
        .cls-1 {
            opacity: 0.25;
        }

        .cls-2 {
            opacity: 0.5;
        }

        .cls-3 {
            opacity: 1;
        }
    }
}
</style>
