import api from './api'

const apicampaignfinance = {
    async findPayableParticipants(axios, campaignId, paging) {
        return axios
            .post(`/campaigns/${campaignId}/finance/payable`, paging)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async findPayments(axios, campaignId, paging) {
        return axios
            .post(`/campaigns/${campaignId}/finance/payments`, paging)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getFinanceSummary(axios, campaignId) {
        return axios
            .get(`/campaigns/${campaignId}/finance/summary`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getParticipantFinanceSummary(axios, participantId) {
        return axios
            .get(`/finance/${participantId}`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async assignPayee(axios, participantId, payeeId) {
        return axios
            .put(`/payee/participant/${participantId}/${payeeId}`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async unassignPayee(axios, participantId) {
        return axios
            .delete(`/payee/participant/${participantId}`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getParticipantAvailablePayees(axios, participantId) {
        return axios
            .get(`/payee/participant/${participantId}/available`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apicampaignfinance
