<template>
    <div class="relative">
        <div v-if="inputType === 'dropdown-code-single'">
            <SelectRoot v-model="localValue">
                <SelectTrigger class="h-16">
                    <SelectValue :placeholder="searchPlaceholder" />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem
                        v-for="option in localOptions"
                        :key="option.id"
                        :value="option.id"
                        class="px-2"
                        :is-checkless="true"
                    >
                        <div class="flex">
                            <div class="align-center text-md mr-1.5 flex w-6 items-center text-center">
                                {{ option.emoji }}
                            </div>
                            <div class="flex flex-col text-left">
                                <div class="my-auto font-medium leading-none leading-none text-slate-900">
                                    {{ option.name }}
                                </div>

                                <div
                                    v-if="
                                        displayOptions &&
                                        option?.metadata?.platforms?.[platform]?.metrics &&
                                        displayOptions
                                    "
                                    class="mt-1 text-xs leading-none text-slate-600"
                                >
                                    <template v-if="platform === 'twitch'">
                                        {{
                                            $filters.formatWholeNumberWithUnit(
                                                option?.metadata?.platforms?.[platform]?.metrics[
                                                    displayOptions.content_type
                                                ]?.[displayOptions.metrics_type],
                                            )
                                        }}
                                        channels
                                    </template>
                                    <template v-else-if="platform === 'youtube'">
                                        {{
                                            $filters.formatWholeNumberWithUnit(
                                                option?.metadata?.platforms?.[platform]?.metrics[
                                                    displayOptions.content_type
                                                ]?.[displayOptions.metrics_type],
                                            )
                                        }}
                                        channels
                                    </template>
                                    <template v-else-if="platform === 'tiktok'">
                                        {{
                                            $filters.formatWholeNumberWithUnit(
                                                option?.metadata?.platforms?.[platform]?.metrics[
                                                    displayOptions.content_type
                                                ]?.[displayOptions.metrics_type],
                                            )
                                        }}
                                        channels
                                    </template>
                                    <template v-else-if="platform === 'twitter'">
                                        {{
                                            $filters.formatWholeNumberWithUnit(
                                                option?.metadata?.platforms?.[platform]?.metrics[
                                                    displayOptions.content_type
                                                ]?.[displayOptions.metrics_type],
                                            )
                                        }}
                                        channels
                                    </template>
                                    <template v-else-if="platform === 'instagram'">
                                        {{
                                            $filters.formatWholeNumberWithUnit(
                                                option?.metadata?.platforms?.[platform]?.metrics[
                                                    displayOptions.content_type
                                                ]?.[displayOptions.metrics_type],
                                            )
                                        }}
                                        channels
                                    </template>
                                </div>
                            </div>
                        </div>
                    </SelectItem>
                </SelectContent>
            </SelectRoot>
        </div>

        <div v-if="inputType === 'select-code-multiple'">
            <div v-if="selectedItemsByNameAlphabetically?.length > 0" class="mb-1 flex flex-col">
                <div
                    v-for="selectedItem in selectedItemsByNameAlphabetically"
                    :key="codeType === 'game' ? `${selectedItem.id}-${selectedItem.metadata}` : selectedItem.id"
                    class="mb-1 flex justify-between overflow-hidden rounded"
                    :class="{
                        'bg-indigo-500': codeType !== 'game',
                        'relative overflow-hidden bg-slate-700': codeType === 'game',
                    }"
                >
                    <div
                        class="absolute z-0 h-full w-full bg-cover bg-center opacity-10"
                        :style="{
                            'background-image': `url(${`https://streamforge-etl-media.nyc3.cdn.digitaloceanspaces.com/${platform}/games/thumbnails/default/${selectedItem.id}`})`,
                        }"
                    />

                    <div class="z-10 my-auto px-2.5 py-1.5 text-sm font-medium text-white">
                        <span>{{ selectedItem.label }}</span>
                        <span v-if="selectedItem?.metadata?.release_year" class="ml-0.5"
                            >({{ selectedItem.metadata.release_year }})</span
                        >
                    </div>

                    <div
                        v-if="selectedItem.value2"
                        class="z-10 my-auto ml-auto line-clamp-1 px-2.5 py-1.5 text-sm font-medium text-white"
                    >
                        ({{ sliderOptions.tooltipFormatter(selectedItem.value2) }})
                    </div>

                    <div class="mr-1.5 flex">
                        <div
                            class="my-auto cursor-pointer rounded-sm p-0.5 text-white opacity-80 hover:bg-black hover:bg-opacity-20 hover:opacity-70"
                            @click="onRemoveSelection(selectedItem)"
                        >
                            <font-awesome-icon :icon="['fas', 'xmark']" class="h-4 w-4" />
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="sliderOptions" class="pb-8">
                <vue-slider
                    v-if="sliderOptions.type == 'slider-range'"
                    v-model="sliderValue"
                    :min="1"
                    :max="100"
                    :data="sliderOptions.data"
                    :marks="sliderOptions.marks"
                    :enable-cross="false"
                    :tooltip="'none'"
                    :tooltip-placement="'bottom'"
                    :interval="5"
                    :clickable="true"
                />

                <vue-slider
                    v-if="sliderOptions.type == 'slider-affinity'"
                    v-model="sliderValue"
                    :data="sliderOptions.data"
                    :marks="true"
                    :enable-cross="false"
                    :lazy="true"
                    :tooltip="'none'"
                    :absorb="true"
                    :clickable="false"
                    :interval="0.1"
                    :min-range="0.1"
                    :fixed="false"
                    :contained="false"
                    class="mb-3"
                >
                    <template #mark="{ pos, label, active }">
                        <div
                            class="absolute top-4 -translate-x-3 whitespace-nowrap text-xs"
                            :style="{
                                left: `${pos}%`,
                            }"
                        >
                            <div
                                class="cursor-pointer rounded p-2 px-2 py-0.5 uppercase"
                                :class="[
                                    getGradeAndColorsFromPercent(label).textColor,
                                    getGradeAndColorsFromPercent(label).bgPrimaryColor,
                                    {
                                        'opacity-25': !active,
                                    },
                                ]"
                            >
                                {{ getGradeAndColorsFromPercent(label).letter }}
                            </div>
                        </div>
                    </template>
                </vue-slider>
            </div>

            <MultiSelect
                v-if="!(selectMode === 'single' && selectedItemsByNameAlphabetically?.length > 0)"
                ref="multiselect"
                v-model="selectedItems"
                select-label=""
                mode="multiple"
                deselect-label=""
                :placeholder="searchPlaceholder"
                track-by="id"
                label="label"
                value-prop="id"
                :loading="isLoading"
                :object="true"
                :options="codesProvider"
                :classes="styling"
                :searchable="true"
                :min-chars="0"
                :delay="250"
                :clear-on-search="true"
                :clear-on-select="false"
                :close-on-select="closeOnSelect"
                :clear-on-blur="true"
                :resolve-on-load="true"
                :can-clear="false"
                :hide-selected="true"
                :no-results-text="noResultsText"
                :filter-results="false"
                :multiple-label="multipleLabelHandler"
                :block-keys="['Delete']"
                :caret="true"
                no-options-text="No matching results found"
                @change="onSelectionChange"
                @keydown="onCodeInputKeyDown"
            >
                <template #caret>
                    <div v-if="isLoading" class="absolute right-0 top-0 mr-3 flex h-full">
                        <font-awesome-icon
                            :icon="['fas', 'spinner-third']"
                            class="my-auto h-3.5 w-3.5 animate-spin text-slate-500"
                        />
                    </div>
                    <div v-else class="absolute right-0 top-0 mr-3 flex h-full">
                        <font-awesome-icon :icon="['fas', 'chevron-down']" class="my-auto h-3 w-3 text-slate-500" />
                    </div>
                </template>

                <!-- <template #spinner>
                    <div class="absolute right-0 top-0 mr-3 flex h-full">
                        <font-awesome-icon :icon="['fas', 'spinner-third']" class="my-auto h-3.5 w-3.5 text-slate-500 animate-spin" />
                    </div>
                </template> -->

                <template #option="{ option }">
                    <div class="relative flex w-full">
                        <template v-if="codeType === 'game'">
                            <div class="flex w-full border-b border-slate-200 px-3">
                                <div
                                    class="my-auto h-7 w-12 shrink-0 rounded bg-cover bg-center"
                                    :style="{
                                        'background-image': `url(${`https://streamforge-etl-media.nyc3.cdn.digitaloceanspaces.com/${platform}/games/thumbnails/${
                                            platform === 'igdb' ? 'small' : 'default'
                                        }/${option.id}`})`,
                                    }"
                                />
                                <div class="ml-2 py-2">
                                    <div class="mb-1 text-sm font-medium leading-none text-slate-900">
                                        <span>{{ option.label }}</span>
                                        <span
                                            v-if="option?.metadata?.release_year"
                                            class="text-xs leading-none text-slate-600"
                                        >
                                            ({{ option.metadata.release_year }})
                                        </span>
                                    </div>
                                    <div
                                        v-if="
                                            displayOptions &&
                                            option?.metadata?.platforms?.[displayOptions.platform_id]?.metrics &&
                                            displayOptions
                                        "
                                        class="text-xs leading-none text-slate-600"
                                    >
                                        <template v-if="displayOptions?.platform_id === 'twitch'">
                                            {{
                                                $filters.formatWholeNumberWithUnit(
                                                    option?.metadata?.platforms?.[displayOptions.platform_id]
                                                        ?.metrics?.[displayOptions.metrics_type],
                                                )
                                            }}
                                            channels
                                        </template>
                                        <template v-else-if="displayOptions?.platform_id === 'youtube'">
                                            {{
                                                $filters.formatWholeNumberWithUnit(
                                                    option?.metadata?.platforms?.[displayOptions.platform_id]?.metrics[
                                                        displayOptions.content_type
                                                    ]?.[displayOptions.metrics_type],
                                                )
                                            }}
                                            channels
                                        </template>
                                        <template v-else-if="displayOptions?.platform_id === 'twitter'">
                                            {{
                                                $filters.formatWholeNumberWithUnit(
                                                    option?.metadata?.platforms?.[displayOptions.platform_id]
                                                        ?.metrics?.[displayOptions.metrics_type],
                                                )
                                            }}
                                            channels
                                        </template>
                                        <template v-else-if="displayOptions?.platform_id === 'instagram'">
                                            {{
                                                $filters.formatWholeNumberWithUnit(
                                                    option?.metadata?.platforms?.[displayOptions.platform_id]
                                                        ?.metrics?.[displayOptions.metrics_type],
                                                )
                                            }}
                                            channels
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="flex w-full border-b border-slate-200 px-3">
                                <div class="my-auto mr-2 flex h-7 w-7 shrink-0 rounded bg-slate-200">
                                    <font-awesome-icon
                                        :icon="['fas', codeTypeToIcon[codeType] ?? 'tags']"
                                        class="m-auto h-3.5 w-3.5 text-slate-500"
                                    />
                                </div>
                                <div class="flex-col py-2">
                                    <div class="w-full font-medium text-slate-900">
                                        {{ option.label }}
                                    </div>
                                    <div
                                        v-if="
                                            displayOptions &&
                                            option?.metadata?.platforms?.[displayOptions.platform_id]?.metrics &&
                                            displayOptions
                                        "
                                        class="text-xs"
                                    >
                                        {{
                                            $filters.formatWholeNumberWithUnit(
                                                option.metadata?.platforms?.[displayOptions.platform_id]?.metrics[
                                                    displayOptions.content_type
                                                ]?.[displayOptions.metrics_type],
                                            )
                                        }}
                                        channels
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
            </MultiSelect>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'
import VueSlider from 'vue-slider-component'
import MultiSelect from '@vueform/multiselect'
import { getGradeAndColorsFromPercent } from '@/utils/affinity'
import { Select as SelectRoot, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'

import codeAPI from '../utils/api/api.codes'

export default {
    name: 'CodeSelect',

    components: {
        MultiSelect,
        VueSlider,
        SelectRoot,
        SelectContent,
        SelectItem,
        SelectTrigger,
        SelectValue,
    },

    props: {
        inputType: { type: String, default: 'select-code-multiple' },
        selectedOptions: {},
        title: { type: String },
        codeType: { type: String },
        platform: { type: String },
        preload: { type: Boolean },
        maxItems: { type: Number },
        searchPlaceholder: { type: String },
        multipleLabel: { type: String },
        selectMode: { type: String, default: 'multiple' },
        isCaretEnabled: { type: Boolean, default: false },
        noResultsText: { type: String, default: 'All matches selected' },
        sliderOptions: { type: Object },
        displayOptions: { type: Object },
        idField: {
            type: String,
            default: () => 'id',
        },
        currentFiltersContext: { type: Object },
    },

    emits: ['selection-change'],

    data() {
        return {
            selectedItems: [],

            codeTypeToIcon: {
                tag: 'hashtag',
                language: 'language',
                country: 'earth-americas',
                'device-type': 'computer',
                'operating-system': 'computer',
            },

            isLoading: false,

            styling: {
                container: `
                    form-select relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-slate-200
                    rounded bg-white text-sm leading-snug outline-none py-0 hover:border-slate-300
                `,
                containerDisabled: 'cursor-default bg-slate-100',
                containerOpen: 'rounded-b-none',
                containerOpenTop: 'rounded-t-none',
                containerActive: 'border-slate-400',
                singleLabel:
                    'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
                singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
                multipleLabel:
                    'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
                search: `
                    w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base
                    font-sans bg-white rounded pl-3.5 rtl:pl-0 rtl:pr-3.5 py-0
                `,
                tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2 rtl:pl-0 rtl:pr-2',
                tag: `
                    bg-indigo-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center
                    rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1
                `,
                tagDisabled: 'pr-2 opacity-50 rtl:pl-2',
                tagRemove:
                    'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
                tagRemoveIcon:
                    'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
                tagsSearchWrapper: 'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
                tagsSearch:
                    'absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-base font-sans box-border w-full',
                tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
                placeholder:
                    'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-slate-400 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
                caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5',
                caretOpen: 'rotate-180 pointer-events-auto',
                clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5',
                clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
                spinner:
                    'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 rtl:mr-0 rtl:ml-3.5',
                infinite: 'flex items-center justify-center w-full',
                infiniteSpinner:
                    'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 animate-spin flex-shrink-0 flex-grow-0 m-3.5',
                dropdown: `max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-slate-300 -mt-px
                    overflow-y-auto z-50 bg-white flex flex-col rounded-b
                     scrollbar scrollbar-track-slate-200 scrollbar-thumb-slate-400 scrollbar-w-2 scrollbar-h-2 hover:scrollbar-thumb-slate-500 active:scrollbar-thumb-slate-600"`,
                dropdownTop: '-translate-y-full top-px bottom-auto rounded-b-none rounded-t',
                dropdownHidden: 'hidden',
                options: 'flex flex-col p-0 m-0 list-none',
                optionsTop: '',
                group: 'p-0 m-0',
                groupLabel:
                    'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-slate-200 cursor-default leading-normal',
                groupLabelPointable: 'cursor-pointer',
                groupLabelPointed: 'bg-slate-300 text-slate-700',
                groupLabelSelected: 'bg-indigo-600 text-white',
                groupLabelDisabled: 'bg-slate-100 text-slate-300 cursor-not-allowed',
                groupLabelSelectedPointed: 'bg-indigo-600 text-white opacity-90',
                groupLabelSelectedDisabled: 'text-indigo-100 bg-indigo-600 bg-opacity-50 cursor-not-allowed',
                groupOptions: 'p-0 m-0',

                option: `
                    flex items-center justify-start box-border text-left cursor-pointer text-sm leading-snug
                `,
                optionPointed: 'text-slate-800 bg-slate-100',
                optionSelected: 'bg-slate-200',
                optionDisabled: 'text-slate-300 cursor-not-allowed',
                optionSelectedPointed: 'bg-slate-300',
                optionSelectedDisabled: 'text-indigo-100 bg-indigo-500 bg-opacity-50 cursor-not-allowed',
                noOptions: 'py-2 px-3 text-slate-600 bg-white text-left cursor-default',
                noResults: 'py-2 px-3 text-slate-600 bg-white text-left cursor-default',
                fakeInput:
                    'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
                spacer: 'h-9 py-px box-content',
            },
            getGradeAndColorsFromPercent,
            sliderValue: null,

            localOptions: [],
            localValue: null,
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary']),

        selectedItemsByNameAlphabetically() {
            let selectedItems = []

            if (this.selectedItems?.length > 0) {
                selectedItems = _(this.selectedItems)
                    .cloneDeep()
                    .map((item) =>
                        _.assign(item, {
                            // Sometimes older saved filters don't have the label field
                            label: item.label ? item.label : item.name,
                        }),
                    )
            }

            return _.orderBy(selectedItems, ['name'], ['asc'])
        },

        closeOnSelect() {
            return this.selectMode === 'single'
        },
    },

    watch: {
        selectedOptions(newValue) {
            // console.log('selectedOptions', this.codeType, this.inputType, newValue)

            if (this.inputType === 'dropdown-code-single') {
                if (newValue && newValue.length) {
                    this.localValue = newValue
                } else {
                    this.localValue = null
                }
            } else if (this.inputType === 'select-code-multiple') {
                if (newValue && newValue.length) {
                    this.selectedItems = newValue
                } else {
                    this.selectedItems = []
                }
            }
        },

        async platform(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                // console.log(`Platform updated`, newValue, this.codeType)

                if (this.inputType === 'dropdown-code-single') {
                    this.localValue = this.selectedOptions
                    this.localOptions = await this.codesProvider()
                } else if (this.inputType === 'select-code-multiple') {
                    this.$refs?.multiselect?.refreshOptions(() => {
                        // console.log(`Multiselect options refreshed`, val)
                    })
                }
            }
        },

        localValue(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.$emit('selection-change', newValue)
            }
        },

        'currentFiltersContext.industry': {
            async handler(newValue, oldValue) {
                if (this.codeType === 'industry_category' && newValue !== oldValue) {
                    if (this.inputType === 'dropdown-code-single') {
                        this.localValue = null
                        this.localOptions = await this.codesProvider()
                    } else if (this.inputType === 'select-code-multiple') {
                        this.localValue = null
                        this.$refs?.multiselect?.refreshOptions(() => {
                            // console.log(`Multiselect options refreshed`, val)
                        })
                    }
                }
            },
        },
    },

    async created() {
        if (this.inputType === 'dropdown-code-single') {
            this.localValue = this.selectedOptions
            this.localOptions = await this.codesProvider()
        } else if (this.inputType === 'select-code-multiple') {
            if (this.selectMode === 'single') {
                this.selectedItems = this.selectedOptions
            } else if (this.selectedOptions && this.selectedOptions.length) {
                this.selectedItems = this.selectedOptions
            }

            if (this.sliderOptions) {
                this.sliderValue = this.sliderOptions.defaultValue
            }
        }
    },

    methods: {
        async codesProvider(searchText) {
            await this.$store.dispatch('CANCEL_PENDING_REQUESTS_BY_ENDPOINT', `/metadata/search/${this.codeType}`)
            this.isLoading = true

            let sortBy = ''
            let sortDir = ''

            if (this?.displayOptions?.sort_by) {
                sortBy = this.displayOptions.sort_by
            }

            if (this?.displayOptions?.sort_dir) {
                sortDir = this.displayOptions.sort_dir
            }

            let codesResponse

            try {
                codesResponse = await codeAPI.findCodes(
                    this.$axios,
                    this.codeType,
                    searchText || '',
                    this.platform,
                    sortBy,
                    sortDir,
                    this.currentFiltersContext,
                )
            } catch (error) {
                console.error(`findCodes -> Error -> ${searchText}`, error)

                // As long as the request wasn't cancelled
                if (error.is_cancelled !== true) {
                    this.isLoading = false
                }

                return []
            }

            this.isLoading = false

            if (this.codeType === 'channelType') {
                return _.map(codesResponse.value, (code) => ({
                    id: code[this.idField ?? 'id'],
                    name: code.name,
                    label: code.name,
                    // label: this.getFromDictionary(`superview.type.${code.name}`, 'label'),
                    value: code.name,
                    metadata: {
                        platforms: code.platforms ? code.platforms : null,
                    },
                }))
            }

            if (this.codeType === 'game') {
                return _(codesResponse.value)
                    .map((code) => ({
                        id: code[this.idField ?? 'id'],
                        name: code.name,
                        label: code.name,
                        value: code.id,
                        metadata: {
                            release_year:
                                code.metadata && code.metadata.release_date
                                    ? moment.utc(code.metadata.release_date).format('YYYY')
                                    : null,
                            platforms: code.platforms ? code.platforms : null,
                        },
                    }))
                    .uniqBy((item) => `${item.id}`)
                    .value()
            }

            if (this.codeType === 'industry') {
                const allOption = {
                    id: null,
                    code: null,
                    name: 'All Industries',
                    emoji: '🏭',
                }

                codesResponse.value.unshift(allOption)
            }

            if (this.codeType === 'industry_category') {
                const allOption = {
                    id: null,
                    code: null,
                    name: 'All Categories',
                    emoji: '🏷️',
                }

                codesResponse.value.unshift(allOption)
            }

            return _.map(codesResponse.value, (code) => ({
                id: code[this.idField ?? 'id'],
                name: code.name,
                label: code.name,
                value: code.id,
                emoji: code.emoji,
                metadata: {
                    platforms: code.platforms ? code.platforms : null,
                },
            }))
        },

        onRemoveSelection(value) {
            // this.$refs.multiselect.deselect({ value })
            _.forEach(this.selectedItems, (item) => {
                if (item.id === value.id) {
                    item.value2 = null
                }
            })
            this.selectedItems = _.filter(this.selectedItems, (item) => item.id !== value.id)
            this.$emit('selection-change', this.selectedItems)
        },

        multipleLabelHandler() {
            if (this.multipleLabel) return this.multipleLabel
            return `Type to add more`
        },

        onSelectionChange(selectedValues) {
            // console.log('onSelectionChange', selectedValues)

            if (this.sliderValue) {
                _.forEach(selectedValues, (selectedVal) => {
                    if (!selectedVal.value2) {
                        selectedVal.value2 = this.sliderValue
                    }
                })
            }

            this.$emit('selection-change', selectedValues)
        },

        onCodeInputKeyDown(event, select) {
            if (event.keyCode === 8 && ((select.search && select.search.length === 0) || !select.search)) {
                select.search = ' '
            }
        },
    },
}
</script>
