<template>
    <input ref="inputRef" type="text" placeholder="$" class="form-input w-full" />
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'

export default {
    name: 'CurrencyInput',

    props: ['modelValue', 'options', 'label', 'help', 'error'],

    setup(props) {
        const { inputRef } = useCurrencyInput(props.options)
        return { inputRef }
    },

    watch: {
        modelValue: {
            handler(value) {
                this.inputRef.value = value || 0
            },
        },
    },
}
</script>
