<template>
    <div class="mx-auto w-full max-w-9xl p-3 pb-0 md:p-4 md:pb-0 lg:p-8 lg:pb-0">
        <div class="grid-cols-1 xl:grid">
            <div class="flex flex-wrap xl:h-12 xl:flex-nowrap">
                <div
                    v-for="tab in tabs"
                    :key="tab.key"
                    class="group flex w-full cursor-pointer select-none border bg-white px-4 py-3 text-left text-xs font-medium uppercase text-slate-500 first:rounded-bl first:rounded-tl first:border-l last:rounded-br last:rounded-tr last:border-r"
                    :class="{
                        'border-slate-200 hover:bg-slate-50': tab.key !== state.activeTab,
                        'border-b-slate-400': tab.key === state.activeTab,
                        '!cursor-default border-slate-400 border-b-slate-200 !bg-indigo-50':
                            tab.key === state.activeTab,
                    }"
                    @click="setActiveTab(tab.key)"
                >
                    <div class="flex grow justify-between">
                        <div class="my-auto flex">
                            <span class="leading-none">{{ tab.label }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <Messages v-if="state.activeTab === 'messages'"></Messages>
            <Inquiries v-if="state.activeTab === 'inquiries'"></Inquiries>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Inquiries from '@/partials/tables/InquiriesTable.vue'
import Messages from '@/partials/tables/Messages.vue'

export default {
    name: 'MessagesPage',

    metaInfo: {
        title: 'Messages',
    },

    components: {
        Inquiries,
        Messages,
    },

    data() {
        return {
            state: {
                activeTab: 'messages',
            },
            tabs: [
                { key: 'messages', label: 'Messages' },
                { key: 'inquiries', label: 'Outreach' },
            ],
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'selfOperator', 'selfActiveOrganizationId', 'modals']),
    },

    created() {},

    methods: {
        setActiveTab(tab) {
            this.state.activeTab = tab
        },

        getTabRecordCount(key) {
            return this.summary[key] ? this.summary[key] : 0
        },
    },
}
</script>
