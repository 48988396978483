import api from './api'

const apiorganizationrosters = {
    async getDefaultRoster(
        axios,
        organizationId,
        includeCreators,
        includeRatecards,
        platformId,
        paging,
        searchText,
        isExport,
    ) {
        let body = null
        if (includeCreators) {
            body = {
                paging: {
                    perPage: paging.perPage,
                    currentPage: paging.currentPage,
                    sortby: paging.sortby,
                    sortdir: paging.sortdir,
                    filters: [
                        {
                            id: 'platform_id',
                            value: platformId,
                        },
                    ],
                },
                export: isExport,
            }

            if (searchText) {
                body.paging.filters.push({
                    id: 'name',
                    value: searchText,
                })
            }
        }

        return axios
            .post(
                `/organizations/${organizationId}/roster/default?includecreators=${includeCreators}&includeratecards=${includeRatecards}`,
                body,
            )
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async addCreatorToRoster(axios, organizationId, rosterId, platformId, platformUserId, platformUserName) {
        const body = {
            platform_id: platformId,
            platform_user_id: platformUserId,
            platform_user_name: platformUserName,
        }
        return axios
            .put(`/organizations/${organizationId}/roster/${rosterId}/members`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async saveCreatorRates(axios, organizationId, rosterId, rosterCreatorId, rateItems) {
        const body = {
            items: rateItems,
        }
        return axios
            .put(`/organizations/${organizationId}/roster/${rosterId}/members/${rosterCreatorId}/ratecard/items`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async removeCreatorFromRoster(axios, organizationId, rosterId, memberId) {
        return axios
            .delete(`/organizations/${organizationId}/roster/${rosterId}/members/${memberId}`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getCreatorParticipant(axios, organizationId, rosterId, memberId, participantId) {
        return axios
            .get(
                `/organizations/${organizationId}/roster/${rosterId}/members/${memberId}/participants/${participantId}`,
                {
                    params: {
                        relations: ['stages', 'link', 'tasks', 'bids', 'slots', 'settings', 'chapters'],
                    },
                },
            )
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apiorganizationrosters
