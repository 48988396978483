<script setup>
import { DropdownMenuGroup } from 'radix-vue'

const props = defineProps({
    asChild: { type: Boolean, required: false },
    as: { type: null, required: false },
})
</script>

<template>
    <DropdownMenuGroup v-bind="props">
        <slot />
    </DropdownMenuGroup>
</template>
