import api from './api'

const apiverification = {
    async validateEmail(axios, email) {
        const body = {
            email,
        }

        return axios
            .post(`/onboard/validateemail`, body)
            .then(({ data }) => {
                return api.createSuccessResponse(data.payload)
            })
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apiverification
