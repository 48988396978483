import _ from 'lodash'

export const affinityGrades = [
    {
        letter: 'F',
        textColor: 'text-white',
        bgPrimaryColor: 'bg-slate-600',
        bgSecondaryColor: 'bg-slate-500',
        min: 0,
        max: 0.59999,
    },
    {
        letter: 'D',
        textColor: 'text-white',
        bgPrimaryColor: 'bg-rose-500',
        bgSecondaryColor: 'bg-rose-400',
        min: 0.6,
        max: 0.69999,
    },
    {
        letter: 'C',
        textColor: 'text-white',
        bgPrimaryColor: 'bg-amber-500',
        bgSecondaryColor: 'bg-amber-400',
        min: 0.7,
        max: 0.79999,
    },
    {
        letter: 'B',
        textColor: 'text-white',
        bgPrimaryColor: 'bg-blue-500',
        bgSecondaryColor: 'bg-blue-400',
        min: 0.8,
        max: 0.89999,
    },
    {
        letter: 'A',
        textColor: 'text-white',
        bgPrimaryColor: 'bg-emerald-500',
        bgSecondaryColor: 'bg-emerald-400',
        min: 0.9,
        max: 0.99999,
    },
    {
        letter: 'S',
        textColor: 'text-white',
        bgPrimaryColor: 'bg-indigo-500',
        bgSecondaryColor: 'bg-indigo-400',
        min: 1,
        max: 1000000,
    },
]
export const getGradeAndColorsFromPercent = (affinityPercent) => {
    const matchedGrade = _.find(affinityGrades, (grade) => affinityPercent >= grade.min && affinityPercent <= grade.max)

    if (matchedGrade) {
        return matchedGrade
    }

    return {
        letter: false,
        textColor: 'text-white',
        bgPrimaryColor: 'bg-slate-400',
        bgSecondaryColor: 'bg-slate-300',
    }
}
