<template>
    <div>
        <Popover>
            <PopoverTrigger>
                <slot name="trigger">
                    <Button variant="outline" size="sm" class="h-8 border-dashed">
                        <CirclePlus class="mr-2 size-4" />
                        {{ title }}
                        <template v-if="selectedOptions.length > 0">
                            <Separator orientation="vertical" class="mx-2 h-4" />
                            <Badge variant="secondary" class="rounded-sm px-1 font-normal lg:hidden">{{
                                selectedOptions.length
                            }}</Badge>
                            <div class="hidden space-x-1 lg:flex">
                                <template v-if="selectedOptions.length > 2">
                                    <Badge variant="secondary" class="rounded-sm px-1 font-normal"
                                        >{{ selectedOptions.length }} selected</Badge
                                    >
                                </template>
                                <template v-else>
                                    <Badge
                                        v-for="option in selectedOptions"
                                        :key="option.value"
                                        variant="secondary"
                                        class="rounded-sm px-1 font-normal"
                                        >{{ option.label }}</Badge
                                    >
                                </template>
                            </div>
                        </template>
                    </Button>
                </slot>
            </PopoverTrigger>

            <PopoverContent class="w-[12.5rem] p-0" align="start">
                <Command>
                    <CommandInput
                        :placeholder="`Search for ${title.toLowerCase()}`"
                        class="h-8 !border-0 p-0 focus-visible:ring-0"
                    />
                    <CommandList
                        class="scrollbar scrollbar-track-slate-200 scrollbar-thumb-slate-400 scrollbar-w-2 scrollbar-h-2 hover:scrollbar-thumb-slate-500 active:scrollbar-thumb-slate-600"
                    >
                        <CommandEmpty>No results found.</CommandEmpty>
                        <div v-if="!options.length" class="px-3 pt-2 text-sm">No available options</div>
                        <CommandGroup>
                            <CommandItem
                                v-for="option in options"
                                :key="option.value"
                                @select="toggleSelection(option.value)"
                                :value="option.value"
                            >
                                <div
                                    :class="[
                                        'mr-2 flex size-4 items-center justify-center rounded-sm border border-primary',
                                        isSelected(option.value)
                                            ? 'bg-primary text-primary-foreground'
                                            : 'opacity-50 [&_svg]:invisible',
                                    ]"
                                >
                                    <Check class="size-4" aria-hidden="true" />
                                </div>
                                <span>{{ option.label }}</span>
                                <span
                                    v-if="option.withCount && option.count"
                                    class="ml-auto flex size-4 items-center justify-center font-mono text-xs"
                                    >{{ option.count }}</span
                                >
                                <span
                                    v-else-if="option.withCount && column.getFacetedUniqueValues(option.value)"
                                    class="ml-auto flex size-4 items-center justify-center font-mono text-xs"
                                    >{{ column.getFacetedUniqueValues(option.value) }}</span
                                >
                            </CommandItem>
                        </CommandGroup>
                        <CommandSeparator v-if="selectedOptions.length > 0" />
                        <CommandGroup v-if="selectedOptions.length > 0">
                            <CommandItem @select="clearFilters" class="justify-center text-center" :value="undefined">
                                Clear filters
                            </CommandItem>
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
    Command,
    CommandDialog,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
    CommandShortcut,
} from '@/components/ui/command'

import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Separator } from '@/components/ui/separator'

import { Check, CirclePlus } from 'lucide-vue-next'

const props = defineProps({
    column: Object,
    title: String,
    options: Array,
})

const selectedOptions = computed(() => {
    const filterValues = props.column?.getFilterValue() || []
    return props.options.filter((option) => filterValues.includes(option.value))
})

function isSelected(value) {
    const filterValues = props.column?.getFilterValue() || []
    return filterValues.includes(value)
}

function toggleSelection(value) {
    let filterValues = props.column?.getFilterValue() || []

    console.log({ filterValues })

    if (filterValues.includes(value)) {
        filterValues = filterValues.filter((v) => v !== value)
    } else {
        filterValues.push(value)
    }
    props.column.setFilterValue(filterValues.length ? filterValues : undefined)
}

function clearFilters() {
    props.column.setFilterValue(undefined)
}
</script>
