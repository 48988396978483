<script setup>
import { computed } from 'vue'
import { SelectGroup } from 'radix-vue'
import { cn } from '@/lib/utils'

const props = defineProps({
    asChild: { type: Boolean, required: false },
    as: { type: null, required: false },
    class: { type: null, required: false },
})

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
})
</script>

<template>
    <SelectGroup :class="cn('w-full p-1', props.class)" v-bind="delegatedProps">
        <slot />
    </SelectGroup>
</template>
