import _ from 'lodash'

export default {
    state: {
        isLoaded: false,

        permissions: {
            all: [],
            granted: [],
            defaultLimits: {
                none: [
                    { key: 'lists.total', value: null },
                    { key: 'lists.size', value: null },
                ],
                small: [
                    { key: 'lists.total', value: 5 },
                    { key: 'lists.size', value: 100 },
                ],
                medium: [
                    { key: 'lists.total', value: 10 },
                    { key: 'lists.size', value: 500 },
                ],
                large: [
                    { key: 'lists.total', value: null },
                    { key: 'lists.size', value: 1000 },
                ],
            },
        },
    },

    /* eslint-disable no-param-reassign */
    mutations: {
        'permissions:isLoaded': function (state, isLoaded) {
            state.isLoaded = isLoaded
        },

        'permissions:setup': function (state, permissions) {
            state.permissions.all = permissions.all
            state.permissions.granted = permissions.granted
            state.permissions.customLimitations = permissions.limitations
            state.permissions.limitations = this.getters.getCombinedLimitations
        },
        /* eslint-enable no-param-reassign */
    },

    getters: {
        isPermissionsLoaded(state) {
            return state.isLoaded
        },

        grantedPermissions(state, getters, rootState) {
            const isSfStaff =
                rootState.self.isSfStaffDisabled === false &&
                !!_.find(rootState.self.organizations, (organization) => organization.type === 'admin')

            return _.filter(state.permissions.all, (permissionId) =>
                isSfStaff ? true : !!state?.permissions?.granted?.includes(permissionId),
            )
        },

        allPermissions(state) {
            return state.permissions.all
        },

        permissions(state, getters, rootState) {
            const isSfStaff =
                rootState.self.isSfStaffDisabled === false &&
                !!_.find(rootState.self.organizations, (organization) => organization.type === 'admin')

            return _.reduce(
                state.permissions.all,
                (result, permissionId) => {
                    result[permissionId] = isSfStaff ? true : !!state?.permissions?.granted?.includes(permissionId)

                    return result
                },
                {},
            )
        },

        checkPermission: (state, getters, rootState) => (permissionId) => {
            const isSfStaff =
                rootState.self.isSfStaffDisabled === false &&
                !!_.find(rootState.self.organizations, (organization) => organization.type === 'admin')

            if (permissionId === 'data.timerange.triennial') return true

            if (isSfStaff) return true

            return state.permissions?.granted?.includes(permissionId)
        },

        isClient(state) {
            return state && state.permissions.granted.includes(state.permissions.module.discovery.id)
        },

        defaultDataLimits(state) {
            return state && state.permissions.defaultLimits
        },

        getCombinedLimitations(state) {
            const limits = state.permissions.defaultLimits

            if (state && state.permissions.granted.includes('usage.tier.large')) {
                return limits.large
            }
            if (state && state.permissions.granted.includes('usage.tier.medium')) {
                return limits.medium
            }
            if (state && state.permissions.granted.includes('usage.tier.small')) {
                return limits.small
            }

            const limitations = _.map(limits.none, (limit) => {
                return { key: limit.key, value: limit.value }
            })

            if (state.permissions.customLimitations) {
                _.forEach(state.permissions.customLimitations, (limit) => {
                    const existing = _.find(limitations, (defaultLimit) => {
                        return defaultLimit.key === limit.key
                    })
                    if (existing) {
                        existing.value = limit.value
                    }
                })
            }

            return limitations
        },

        getLimitations(state) {
            return state && state.permissions.limitations
        },

        getLimitation: (state) => (key) => {
            if (state && state.permissions && state.permissions.limitations) {
                const matched = _.find(state.permissions.limitations, (limit) => {
                    return limit.key === key
                })

                if (matched) {
                    return matched.value
                }
            }

            return null
        },
    },
}
