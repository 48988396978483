<template>
    <div class="relative inline-flex">
        <button
            ref="trigger"
            class="btn h-8 justify-between bg-slate-100 text-slate-500 shadow-none hover:bg-slate-200 hover:text-slate-600"
            aria-label="Select platform"
            aria-haspopup="true"
            :aria-expanded="dropdownOpen"
            @click.prevent="dropdownOpen = !dropdownOpen"
        >
            <font-awesome-icon
                v-if="selectedPlatformId !== 'any'"
                class="my-auto mr-2 h-4 w-4"
                :class="`text-${selectedPlatformId}`"
                :icon="['fab', selectedPlatformId]"
            />
            <span>{{ getPlatformName(selectedPlatformId) }}</span>

            <svg class="my-auto ml-2 shrink-0 fill-current text-slate-400" width="11" height="7" viewBox="0 0 11 7">
                <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
            </svg>
        </button>

        <transition
            enter-active-class="transition ease-out duration-100 transform"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="dropdownOpen"
                ref="dropdown"
                class="absolute top-full mt-1 flex w-full flex-col divide-y divide-slate-200 overflow-hidden rounded border border-slate-200 bg-white shadow-xl"
            >
                <button
                    v-for="platform in platforms"
                    :key="platform.id"
                    class="group flex w-full rounded-none bg-slate-50 bg-white px-3 py-2 hover:bg-slate-100"
                    @click="onPlatformSelect(platform.id)"
                >
                    <font-awesome-icon
                        v-if="platform.id !== 'any'"
                        class="my-auto mr-2 h-4 w-4 shrink-0"
                        :class="`text-${platform.id}`"
                        :icon="['fab', platform.id]"
                    />
                    <span class="text-sm font-medium">{{ getPlatformName(platform.id) }}</span>
                    <font-awesome-icon
                        class="my-auto ml-auto h-3 w-3 shrink-0 text-slate-500 opacity-0 group-hover:text-slate-500"
                        :icon="['fas', 'check']"
                        :class="{
                            'opacity-100': platform.id === selectedPlatformId,
                        }"
                    />
                </button>
            </div>
        </transition>
    </div>
</template>

<script>
import _ from 'lodash'
import { ref, onMounted, onUnmounted } from 'vue'

export default {
    name: 'DropdownImportPlatform',

    props: {
        platforms: {
            type: Array,
            required: true,
        },

        selectedPlatformId: {
            type: String,
            required: true,
        },
    },
    emits: ['on-select'],

    setup() {
        const dropdownOpen = ref(false)
        const trigger = ref(null)
        const dropdown = ref(null)
        const selected = ref(0)

        // close on click outside
        const clickHandler = ({ target }) => {
            if (!dropdownOpen.value || dropdown.value.contains(target) || trigger.value.contains(target)) return
            dropdownOpen.value = false
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen.value || keyCode !== 27) return
            dropdownOpen.value = false
        }

        onMounted(() => {
            document.addEventListener('click', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        return {
            dropdownOpen,
            trigger,
            dropdown,
            selected,
        }
    },

    computed: {
        selectedPlatform() {
            const matchingPlatform = _.find(this.platforms, (item) => item.id === this.selectedPlatformId)
            return matchingPlatform
        },
    },

    methods: {
        onPlatformSelect(platformId) {
            this.dropdownOpen = false
            this.$emit('on-select', platformId)
        },
        getPlatformName(platformId) {
            const matchingPlatform = _.find(this.platforms, (item) => item.id === platformId)
            return matchingPlatform.name
        },
    },
}
</script>
