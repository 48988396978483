/* eslint-disable no-param-reassign */
import { createInput } from '@formkit/vue'
import _ from 'lodash'

/**
 * This is an input "feature" — a function that accepts a node and exposes
 * some additional functionality to an input. When using schemas, this can
 * take the place of a traditional "script" block in a Vue component. In this
 * example, we expose:
 *
 *   - An input handler `search`.
 *   - An input handler `selections`.
 *   - Commit middleware to place filtered options into the `matches` prop.
 *
 * Once written, input features are added via the input declaration.
 */
const searchFeature = (node) => {
    // Handle click outside
    const clickHandler = ({ target }) => {
        const element = document.querySelector(`div#${node.props.id}.formkit-brace`)
        console.log({ element })

        if (element && (!node.props.opened || element.contains(target))) {
            console.log(`Should do nothing`)
            return
        }

        console.log(`Should close`)
        node.props.opened = false
    }

    // We wait for our node to be fully  "created" before we start to add our
    // handlers to ensure the core Vue plugin has added its context object:
    node.on('created', () => {
        // Ensure our matches prop starts as an array.
        node.props.matches = []
        node.props.selection = ''
        node.props.opened = false
        node.props.displayValue = ''

        if (!node.props.emptyStateText) node.props.emptyStateText = 'No option selected'

        const toggleOpen = async () => {
            node.props.opened = !node.props.opened

            await new Promise((r) => {
                setTimeout(r, 50)
            }) // "next tick"
            if (document.querySelector(`div#${node.props.id} input`)) {
                document.querySelector(`div#${node.props.id} input`).focus()
            }
        }

        // When we actually have an value to set:
        const setValue = async (e) => {
            if (e && typeof e.preventDefault === 'function') e.preventDefault()
            const nodeValue = _.find(node.props.matches, (item) => String(item.id) === String(node.props.selection))

            if (!nodeValue) return

            node.input(nodeValue.data)
            node.props.displayValue = nodeValue.label

            // node.input(node.props.selection)
            node.props.selection = ''
            node.props.searchValue = ''

            node.props.opened = false
        }

        const clearValue = async (e) => {
            if (e && typeof e.preventDefault === 'function') e.preventDefault()
            node.input('')
            node.props.selection = ''
            node.props.searchValue = ''
            node.props.displayValue = ''

            node.props.opened = false
        }

        // Perform a soft selection, this is shown as a highlight in the dropdown
        const select = (delta) => {
            const available = node.props.matches
            let idx = available.indexOf(node.props.selection) + delta
            if (idx >= available.length) {
                idx = 0
            } else if (idx < 0) {
                idx = available.length - 1
            }
            node.props.selection = available[idx].id
        }

        // Add some new "handlers" for our autocomplete. The handlers object is
        // just a conventionally good place to put event handlers. Auto complete
        // inputs always have to deal with lots of keyboard events, so that logic
        // is registered here.
        Object.assign(node.context.handlers, {
            toggleOpen,
            setValue,
            clearValue,
            selection: (e) => {
                // This handler is called when entering data into the search input.
                switch (e.key) {
                    case 'Enter':
                        return setValue()
                    case 'ArrowDown':
                        e.preventDefault()
                        return select(1)
                    case 'ArrowUp':
                        e.preventDefault()
                        return select(-1)
                    default:
                        return undefined
                }
            },
            search(e) {
                node.props.searchValue = e.target.value
            },
            hover: (e) => {
                console.log('hover', e.target.dataset)
                node.props.selection = e.target.dataset.value
            },
            unhover: (e) => {
                if (e.target.textContent === node.props.selection) {
                    node.props.selection = ''
                }
            },
        })

        document.addEventListener('mousedown', clickHandler)
    })

    node.on('destroying', () => {
        console.log(`Destroying listener`)
        document.removeEventListener('mousedown', clickHandler)
    })

    async function onSearch({ payload: value }) {
        console.log(`onSearch triggered`, value)

        if (!node.props.provider) {
            console.warn('No provider function on autocomplete field')
            // eslint-disable-next-line no-param-reassign
            node.props.matches = []
        } else {
            // eslint-disable-next-line no-param-reassign
            node.props.matches = await node.props.provider({ node, value })
            console.log('result', node.props.matches)
        }
    }

    const debounceDelay = node.props.delay ? node.props.delay : 500

    node.on('prop:searchValue', _.debounce(onSearch, debounceDelay))
}

/**
 * This is our input schema responsible for rendering the inner “input”
 * section. In our example, we render an text input which will be used
 * to filter search results, and an unordered list that shows all remaining
 * matches.
 */
const schema = {
    $el: 'div',
    attrs: {
        id: '$id',
        class: '$classes.brace',
    },
    children: [
        {
            if: '$opened',
            then: [
                {
                    $el: 'input',
                    bind: '$attrs',
                    attrs: {
                        class: '$classes.input',
                        onKeydown: '$handlers.selection',
                        onInput: '$handlers.search',
                        value: '$searchValue',
                    },
                },
                {
                    $el: 'ul',
                    if: '$matches.length > 0',
                    attrs: {
                        class: '$classes.dropdown',
                    },
                    children: [
                        {
                            $el: 'li',
                            for: ['match', '$matches'],
                            attrs: {
                                'data-selected': {
                                    if: '$selection === $match.id',
                                    then: 'true',
                                    else: 'false',
                                },
                                'data-value': '$match.id',
                                class: '$classes.dropdownItem',
                                onClick: '$handlers.setValue',
                                onMouseenter: '$handlers.hover',
                                onMouseleave: '$handlers.unhover',
                            },
                            children: '$match.label',
                        },
                    ],
                },
            ],
            else: [
                {
                    $el: 'a',
                    attrs: {
                        class: '$classes.value',
                        onClick: '$handlers.toggleOpen',
                    },
                    children: [
                        {
                            if: '$value.label',
                            then: [
                                {
                                    $el: 'span',
                                    children: '$displayValue',
                                },
                                {
                                    $el: 'span',
                                    attrs: {
                                        class: '$classes.clearValue',
                                        onClick: '$handlers.clearValue',
                                    },
                                    children: 'x',
                                },
                            ],
                            else: [
                                {
                                    $el: 'span',
                                    children: '$emptyStateText',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
}

/**
 * Finally we create our actual input declaration by using `createInput` this
 * places our schema into a "standard" FormKit schema feature set with slots,
 * labels, help, messages etc. The return value of this function is a proper
 * input declaration.
 */
const autocomplete = createInput(schema, {
    props: ['options', 'matches', 'selection', 'searchValue', 'provider', 'opened', 'emptyStateText', 'displayValue'],
    features: [searchFeature],
})

export default autocomplete
