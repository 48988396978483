import _ from 'lodash'
import { createRouter, createWebHistory } from 'vue-router'

import DashboardView from '@/views/DashboardView.vue'

import PageNotFound from '@/pages/utility/PageNotFound.vue'

import Landing from '@/pages/Landing.vue'
import Login from '@/pages/Login.vue'
// import SignUp from './pages/SignUp.vue'
import ForgotPassword from '@/pages/ForgotPassword.vue'
import ResetPassword from '@/pages/ResetPassword.vue'
import NoAccess from '@/pages/NoAccess.vue'
// import Onboard from '@/pages/OnboardPage.vue'

import SupercampaignsList from '@/pages/SupercampaignsList.vue'
import SupercampaignPage from '@/pages/SupercampaignPage.vue'

import SupercampaignHomeSubpage from '@/subpages/SupercampaignHomeSubpage.vue'
import SupercampaignSettingsSubpage from '@/subpages/SupercampaignSettingsSubpage.vue'
import CampaignCreatorsSubpage from '@/subpages/CampaignCreatorsSubpage.vue'
import CampaignReportSubpage from '@/subpages/CampaignReportSubpage.vue'
import CampaignReportSteamSubpage from '@/subpages/CampaignReportSteamSubpage.vue'
import CampaignSlotsSubpage from '@/subpages/CampaignSlotsSubpage.vue'
import CampaignApprovalSubpage from '@/subpages/CampaignApprovalSubpage.vue'
import CampaignWorkflowSubpage from '@/subpages/CampaignWorkflowSubpage.vue'
import CampaignFinanceSubpage from '@/subpages/CampaignFinanceSubpage.vue'
import CampaignTargetingSubpage from '@/subpages/CampaignTargetingSubpage.vue'
import CampaignDeliverablesSubpage from '@/subpages/CampaignDeliverablesSubpage.vue'
import CampaignBidSubpage from '@/subpages/CampaignBidSubpage.vue'
import CampaignOutreachSubpage from '@/subpages/CampaignOutreachSubpage.vue'
import ListsPage from '@/pages/ListsPage.vue'
import DiscoverPage from '@/pages/DiscoverPage.vue'
import RosterPage from '@/pages/RosterPage.vue'
import BidsPage from '@/pages/BidsPage.vue'
import ReturnPage from '@/pages/ReturnPage.vue'
import MessagesPage from '@/pages/MessagesPage.vue'
import ReportsPage from '@/pages/ReportsPage.vue'

import GamesList from '@/pages/GamesList.vue'
import GamePage from '@/pages/GamePage.vue'
import GameFeedSubpage from '@/subpages/GameFeedSubpage.vue'
import GameKeySubpage from '@/subpages/GameKeySubpage.vue'

import OrganizationPage from '@/pages/OrganizationPage.vue'
import OrganizationFeedSubpage from '@/subpages/OrganizationFeedSubpage.vue'
import OrganizationDatasourcesSubpage from '@/subpages/OrganizationDatasourcesSubpage.vue'
import OrganizationManagementSubpage from '@/subpages/OrganizationManagementSubpage.vue'
import OrganizationPermissionsSubpage from '@/subpages/OrganizationPermissionsSubpage.vue'
import OrganizationSettingsSubpage from '@/subpages/OrganizationSettingsSubpage.vue'
import OrganizationSubscriptionsSubpage from '@/subpages/OrganizationSubscriptionsSubpage.vue'

const routerHistory = createWebHistory()

const router = createRouter({
    history: routerHistory,

    routes: [
        {
            name: 'landing',
            path: '/',
            component: Landing,
        },
        {
            name: 'login',
            path: '/login',
            component: Login,
        },
        {
            name: 'forgot-password',
            path: '/forgot-password',
            component: ForgotPassword,
        },
        {
            name: 'reset-password',
            path: '/reset-password',
            component: ResetPassword,
        },
        // {
        //     name: 'register',
        //     path: '/register',
        //     component: Onboard,
        // },
        {
            path: '/',
            component: DashboardView,
            meta: {
                has_authentication: true,
            },
            children: [
                {
                    name: 'campaigns',
                    path: 'campaigns',
                    component: SupercampaignsList,
                    meta: {
                        permissions: ['module.campaigns'],
                    },
                },
                {
                    name: 'supercampaign',
                    path: 'campaigns/:supercampaign_id',
                    component: SupercampaignPage,
                    meta: {
                        permissions: ['module.campaigns'],
                    },
                    children: [
                        {
                            name: 'supercampaign-home',
                            path: 'home',
                            component: SupercampaignHomeSubpage,
                        },
                        {
                            name: 'supercampaign-settings',
                            path: 'settings',
                            component: SupercampaignSettingsSubpage,
                        },
                        {
                            name: 'campaign-creators',
                            path: 'module/:campaign_id/creators',
                            component: CampaignCreatorsSubpage,
                        },
                        {
                            name: 'campaign-report',
                            path: 'module/:campaign_id/report',
                            component: CampaignReportSubpage,
                        },
                        {
                            name: 'campaign-report-steam',
                            path: 'module/:campaign_id/report-steam',
                            component: CampaignReportSteamSubpage,
                        },
                        {
                            name: 'campaign-slots',
                            path: 'module/:campaign_id/slots',
                            component: CampaignSlotsSubpage,
                        },
                        {
                            name: 'campaign-approval',
                            path: 'module/:campaign_id/approval',
                            component: CampaignApprovalSubpage,
                        },
                        {
                            name: 'campaign-workflow',
                            path: 'module/:campaign_id/workflow',
                            component: CampaignWorkflowSubpage,
                        },
                        {
                            name: 'campaign-deliverables',
                            path: 'module/:campaign_id/deliverables',
                            component: CampaignDeliverablesSubpage,
                        },
                        {
                            name: 'campaign-finance',
                            path: 'module/:campaign_id/finance',
                            component: CampaignFinanceSubpage,
                        },
                        {
                            name: 'campaign-targeting',
                            path: 'module/:campaign_id/targeting',
                            component: CampaignTargetingSubpage,
                        },
                        {
                            name: 'campaign-bids',
                            path: 'module/:campaign_id/bids',
                            component: CampaignBidSubpage,
                        },
                        {
                            name: 'campaign-outreach',
                            path: 'module/:campaign_id/outreach',
                            component: CampaignOutreachSubpage,
                        },
                    ],
                },
                {
                    name: 'games',
                    path: 'games',
                    component: GamesList,
                    meta: {
                        permissions: ['module.games'],
                    },
                },
                {
                    name: 'game',
                    path: 'games/:game_id',
                    component: GamePage,
                    meta: {
                        permissions: ['module.games'],
                    },
                    children: [
                        {
                            name: 'game-feed',
                            path: 'feed',
                            component: GameFeedSubpage,
                        },
                        {
                            name: 'game-keys',
                            path: 'keys',
                            component: GameKeySubpage,
                        },
                    ],
                },
                {
                    name: 'organization',
                    path: 'organizations/:organization_id',
                    component: OrganizationPage,
                    children: [
                        {
                            name: 'organization-feed',
                            path: 'feed',
                            component: OrganizationFeedSubpage,
                        },
                        {
                            name: 'organization-datasources',
                            path: 'datasources',
                            component: OrganizationDatasourcesSubpage,
                        },
                        {
                            name: 'organization-users',
                            path: 'users',
                            component: OrganizationManagementSubpage,
                        },
                        {
                            name: 'organization-permissions',
                            path: 'permissions',
                            component: OrganizationPermissionsSubpage,
                        },
                        {
                            name: 'organization-subscriptions',
                            path: 'subscriptions',
                            component: OrganizationSubscriptionsSubpage,
                        },
                        {
                            name: 'organization-settings',
                            path: 'settings',
                            component: OrganizationSettingsSubpage,
                        },
                    ],
                },
                {
                    name: 'lists',
                    path: 'lists',
                    component: ListsPage,
                    meta: {
                        permissions: ['module.lists'],
                    },
                },
                {
                    name: 'discovery',
                    path: 'discover',
                    component: DiscoverPage,
                    meta: {
                        permissions: ['module.discovery'],
                    },
                },
                {
                    name: 'roster',
                    path: 'roster',
                    component: RosterPage,
                },
                {
                    name: 'bids',
                    path: 'bids',
                    component: BidsPage,
                },
                {
                    name: 'messages',
                    path: 'messages',
                    component: MessagesPage,
                    meta: {
                        permissions: ['module.messages'],
                    },
                },
                {
                    name: 'reports',
                    path: 'reports',
                    component: ReportsPage,
                    meta: {
                        // permissions: ['module.reports'],
                    },
                },
                {
                    name: 'no-access',
                    path: '/no-access',
                    component: NoAccess,
                },
                {
                    name: 'return',
                    path: '/return',
                    component: ReturnPage,
                },
            ],
        },

        // Fallback
        {
            path: '/:pathMatch(.*)*',
            component: PageNotFound,
        },
    ],
})

const routerInit = (store) => {
    router.beforeEach(async (to, from, next) => {
        // We do this check to make sure we don't cancel requests if it's a hash change
        // Might be unintentional side-effects of this
        if (to.name !== from.name) {
            // console.log(`Route changed, cancelling requests`, { to, from })
            store.dispatch('CANCEL_PENDING_REQUESTS')
        }

        if (to.matched.some((m) => m.meta.has_authentication) && !store.state.authentication.is_authenticated) {
            /*
             * If the user is not authenticated and visits
             * a page that requires authentication, redirect to the login page
             * but save the attempted path to localstorage so we can redirect
             * the user there after login
             */
            store.commit('authentication:redirect', to.fullPath)

            next({
                name: 'login',
            })
        } else if (to.matched.some((m) => m.meta.guest) && store.state.authentication.is_authenticated) {
            /*
             * If the user is authenticated and visits
             * an guest page, redirect to the dashboard page
             */
            next({
                name: 'login',
            })
        } else {
            const isOperatorAuthed = store.state.authentication.is_authenticated
            const hasRouteWithPermissionsCheck = to.matched.some((matchedRoute) => matchedRoute?.meta?.permissions)
            const isHomepage = to?.path === '/'

            if (isOperatorAuthed && (hasRouteWithPermissionsCheck || isHomepage)) {
                const waitPerCycle = 50
                const waitTimeout = 5000
                let waited = 0

                while (store.getters.isPermissionsLoaded === false) {
                    // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
                    await new Promise((resolve) => setTimeout(resolve, waitPerCycle))
                    waited += waitPerCycle

                    if (waited >= waitTimeout) {
                        router.push({
                            name: 'no-access',
                        })

                        break
                    }
                }

                const isAllRoutesPermitted = to.matched.every((matchedRoute) => {
                    // console.log(matchedRoute, matchedRoute?.meta?.permissions)

                    if (matchedRoute?.meta?.permissions) {
                        return _.every(matchedRoute.meta.permissions, (permissionId) => {
                            return store.getters.checkPermission(permissionId)
                        })
                    }

                    return true
                })

                if (!isAllRoutesPermitted) {
                    // console.log(`No access triggered`)
                    next({
                        name: 'no-access',
                    })

                    // We passed the permissions checks
                } else {
                    // console.log(`We passed the permissions checks`)
                    next()
                }

                // There were never any permissions checks
            } else {
                // console.log(`There were never any permissions checks`)
                next()
            }
        }

        // eslint-disable-next-line no-cond-assign
        for (let matched = to.matched || [], i = matched.length; (i -= 1); ) {
            const route = matched[i]

            if (route.beforeEnter) {
                route.beforeEnter(to, from, next)
            }
        }
    })

    return router
}

export default routerInit
