<template>
    <ModalBasicOverflow
        id="create-campaign-modal"
        title="Create Campaign"
        :modal-open="modals.create_campaign.is_open"
        @close-modal="$store.commit('modal:close', 'create_campaign')"
    >
        <template v-if="modals.create_campaign.is_open">
            <div class="px-5 pt-4">
                <div>
                    <div class="mb-2">
                        <label class="text-sm font-semibold">Select a game</label>

                        <div class="mt-1">
                            <DropdownGameSelect v-if="!formData.game" @game-select="onGameSelect" />

                            <div v-if="formData.game" class="relative flex items-center">
                                <div class="form-input w-full focus:border-slate-200">
                                    <div
                                        class="absolute inset-0 h-full w-full rounded bg-cover bg-center opacity-5"
                                        :style="{
                                            'background-image': `url(${formData.game.cover_url_original})`,
                                        }"
                                    />

                                    <div class="font-semibold">{{ formData.game.name }}</div>
                                </div>

                                <div class="absolute right-0 z-50 pr-3">
                                    <font-awesome-icon
                                        :icon="['fas', 'xmark']"
                                        class="ml-6 h-4 w-4 cursor-pointer text-red-500"
                                        @click="removeCurrentGame"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-5">
                        <FormKit id="create-campaign" type="form" :actions="false" @submit="onFormSubmit">
                            <FormKitSchema :schema="formSchema['lifecycle-stage-form']" :data="formData" />

                            <div v-if="isSfStaff">
                                <FormKitSchema :schema="formSchema['org-form']" />
                            </div>
                        </FormKit>
                    </div>
                </div>
            </div>

            <!-- Modal Footer -->
            <div class="border-t border-slate-200 px-5 py-4">
                <div class="flex justify-between">
                    <div class="ml-auto flex flex-wrap space-x-2">
                        <button
                            class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                            @click.stop="$store.commit('modal:close', 'create_campaign')"
                        >
                            Cancel
                        </button>
                        <button
                            class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600"
                            @click="$formkit.submit('create-campaign')"
                        >
                            Create
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </ModalBasicOverflow>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalBasicOverflow from '@/components/ModalBasicOverflow.vue'
import DropdownGameSelect from '@/components/DropdownGameSelect.vue'

export default {
    name: 'CreateCampaignModal',

    components: {
        ModalBasicOverflow,
        DropdownGameSelect,
    },

    computed: {
        ...mapGetters(['modals', 'isSfStaff', 'selfOrganizations', 'selfActiveOrganizationId']),
    },

    data() {
        return {
            campaign: null,

            state: {
                isSubmitting: false,
            },

            formData: {
                game: null,
            },

            formSchema: {},
        }
    },

    created() {
        this.generateFormSchemas()
    },

    methods: {
        generateFormSchemas() {
            const organizationOptions = this.selfOrganizations.map((organization) => ({
                label: organization.name,
                value: organization.id,
            }))

            this.formSchema = {
                'lifecycle-stage-form': {
                    $formkit: 'select',
                    name: 'stage',
                    label: 'Lifecycle Stage',
                    help: "Let us know which point in the game's lifecycle this is for.",
                    options: [
                        { label: 'Closed Alpha', value: 'Closed Alpha' },
                        { label: 'Closed Beta', value: 'Closed Beta' },
                        { label: 'Open Alpha', value: 'Open Alpha' },
                        { label: 'Open Beta', value: 'Open Beta' },
                        { label: 'Release', value: 'Release' },
                        { label: 'Patch', value: 'Patch' },
                        { label: 'DLC', value: 'DLC' },
                        { label: 'Platform Release', value: 'Platform Release' },
                        { label: 'Early Access', value: 'Early Access' },
                    ],
                },

                'org-form': {
                    $formkit: 'select',
                    name: 'organization',
                    label: 'Organization',
                    options: organizationOptions,
                },
            }
        },

        onGameSelect({ game }) {
            this.formData.game = game
        },

        removeCurrentGame() {
            this.formData.game = ''
        },

        onFormSubmit(values) {
            if (!this.formData.game) {
                return
            }

            let orgId = this.selfActiveOrganizationId

            if (this.isSfStaff && values?.organization) {
                orgId = values.organization
            }

            const body = {
                supercampaign: {
                    stage: values.stage,
                    budget_amount: null,
                    title: `New ${this.formData.game.name} campaign`,
                    igdb_id: this.formData.game.igdb_id,
                    estimated_date: null,
                    game: this.formData.game,
                    organization_id: orgId,
                },
            }

            this.$axios
                .post(`/supercampaigns`, body)
                .then(({ data }) => {
                    this.resetModal()
                    this.$router.push({
                        name: 'supercampaign-home',
                        params: {
                            supercampaign_id: data.payload.supercampaign.id,
                        },
                    })
                })
                .catch((error) => {
                    console.error(`Error submitting create campaign form`, error)
                    console.log(error)
                })
        },

        resetModal() {
            this.$store.commit('modal:close', 'create_campaign')
            this.$formkit.reset('create-campaign')
            this.formData.game = ''
        },
    },
}
</script>
