<template>
    <!-- Modal backdrop -->
    <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-out duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-show="modalOpen"
            class="fixed inset-0 z-50 bg-slate-900 bg-opacity-30 transition-opacity"
            aria-hidden="true"
        ></div>
    </transition>
    <!-- Modal dialog -->
    <transition
        enter-active-class="transition ease-in-out duration-200"
        enter-from-class="opacity-0 translate-y-4"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in-out duration-200"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-4"
    >
        <div
            v-show="modalOpen"
            :id="id"
            class="fixed inset-0 z-50 mx-auto my-4 flex w-full transform items-center justify-center overflow-hidden px-4 sm:px-6"
            role="dialog"
            aria-modal="true"
        >
            <div ref="modalContent" class="h-3/5 max-w-8xl rounded bg-white shadow-lg">
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'

export default {
    name: 'ModalVideo',
    props: ['id', 'modalOpen'],
    emits: ['close-modal'],
    setup(props, { emit }) {
        const modalContent = ref(null)

        // close on click outside
        const clickHandler = ({ target }) => {
            // console.log({
            //     is_modal_closed: !props.modalOpen,
            //     is_click_in_modal: modalContent.value.contains(target),
            //     eval: !props.modalOpen || modalContent.value.contains(target),
            // })

            if (!props.modalOpen || modalContent.value.contains(target)) {
                return
            }

            if (target && target.className && target.className.indexOf('flatpickr') > -1) {
                return
            }

            emit('close-modal')
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (!props.modalOpen || keyCode !== 27) return
            emit('close-modal')
        }

        onMounted(() => {
            document.addEventListener('mousedown', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('mousedown', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        return {
            modalContent,
            class: props.class,
        }
    },
}
</script>
