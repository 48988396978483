export default {
    state: {
        drawer: {
            enabled: false,
            participant: null,
            platform_id: 'twitch',
            platform_user_id: null,
        },
    },

    /* eslint-disable no-param-reassign */
    mutations: {
        'drawer:set': function (state, drawer) {
            state.drawer = drawer
        },
    },
    /* eslint-enable no-param-reassign */

    getters: {
        drawer: (state) => state.drawer,
    },
}
