<script setup>
import { cn } from '@/lib/utils'

const props = defineProps({
    class: { type: null, required: false },
})
</script>

<template>
    <div
        class="relative w-full overflow-auto rounded-sm scrollbar scrollbar-track-slate-200 scrollbar-thumb-slate-300 scrollbar-corner-slate-100 scrollbar-w-2 scrollbar-h-2 hover:scrollbar-thumb-slate-400 active:scrollbar-thumb-slate-500"
    >
        <table :class="cn('w-full table-auto', props.class)">
            <slot />
        </table>
    </div>
</template>
