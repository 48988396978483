<script setup>
import { ref, watch } from 'vue'
import _ from 'lodash'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { useRoute } from 'vue-router'
import targetGroupTools from '@/utils/searching/targetgroups'
import TargetingFilters from '@/partials/options/TargetingFilters.vue'
import { axios } from '@/utils/axios'

const { platformId, targetGroups, isLoading } = defineProps({
    platformId: String,
    targetGroups: Array,
    isLoading: Boolean,
})

const route = useRoute()
const queryClient = useQueryClient()

const isFormOpen = ref(false)
const formValues = ref(undefined)
const selectedTargetGroup = ref(undefined)

const saveGroup = useMutation({
    mutationFn: async (data) => {
        const body = targetGroupTools.mapFiltersFromTargetGroup(data, formValues.value.options)
        try {
            return await axios.put(`/targeting/campaign/${route.params.campaign_id}/${platformId}`, body)
        } catch (error) {
            throw new Error(error.response?.data?.message || error.message)
        }
    },
    onSuccess: async () => {
        await queryClient.invalidateQueries('campaign-targeting')
        // eslint-disable-next-line no-use-before-define
        closeForm()
    },
})

const deleteGroup = useMutation({
    mutationFn: (targetingGroupId) =>
        axios.delete(`/targeting/campaign/${route.params.campaign_id}/${platformId}/${targetingGroupId}`),
    onSuccess: async () => {
        await queryClient.invalidateQueries('campaign-targeting')
        // eslint-disable-next-line no-use-before-define
        closeForm()
    },
})

const resetForm = () => {
    formValues.value = {
        ...targetGroupTools[`create${_.capitalize(platformId)}Options`](),
        title: '',
        current: targetGroupTools[`create${_.capitalize(platformId)}Options`]().default,
    }
    saveGroup.reset()
}

const closeForm = async () => {
    selectedTargetGroup.value = undefined
    isFormOpen.value = false
    resetForm()
}

const handleEdit = (targetGroup) => {
    isFormOpen.value = true
    selectedTargetGroup.value = targetGroup
    formValues.value = {
        ...targetGroupTools[`create${_.capitalize(platformId)}Options`](),
        title: targetGroup.title,
        current: _.cloneDeep(targetGroup),
    }
}

const handleAddClick = () => {
    isFormOpen.value = true
    selectedTargetGroup.value = undefined
    resetForm()
}

watch(selectedTargetGroup, () => {
    // Clears the error message if there's one
    saveGroup.reset()
})
</script>

<template>
    <div class="mb-4 w-full border-b border-slate-100 sm:mb-0">
        <div class="mb-2 inline-flex w-full rounded bg-slate-50 p-1">
            <div class="flex px-5 py-2.5 text-sm font-bold leading-tight text-slate-900">
                <font-awesome-icon
                    class="my-auto mr-2 h-4 w-4"
                    :icon="['fab', platformId]"
                    :class="`text-${platformId}`"
                />
                <span>{{ $voca.capitalize(platformId) }}</span>
            </div>
        </div>

        <div v-if="!targetGroups && !isFormOpen && !isLoading" class="m-auto border border-slate-100">
            <div class="px-4 py-8 text-center">
                <div
                    class="mb-4 inline-flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-t from-slate-200 to-slate-100"
                >
                    <svg class="h-6 w-5 fill-current" viewBox="0 0 20 24">
                        <path class="text-slate-500" d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z" />
                        <path class="text-slate-300" d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z" />
                        <path class="text-slate-400" d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z" />
                    </svg>
                </div>
                <h2 class="mb-2 text-2xl font-bold text-slate-800">Create target group</h2>
                <div class="mb-6">
                    Let's get started finding the perfect creators for your
                    {{ $voca.capitalize(platformId) }} campaign!
                </div>

                <button class="btn bg-indigo-500 text-white hover:bg-indigo-600" @click="handleAddClick">
                    <svg class="h-4 w-4 shrink-0 fill-current opacity-50" viewBox="0 0 16 16">
                        <path
                            d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"
                        />
                    </svg>
                    <span class="ml-2">Create</span>
                </button>
            </div>
        </div>

        <div v-if="isLoading">
            <div
                v-for="i in Array.from({ length: 3 })"
                :key="i"
                class="h-16 w-full items-center rounded border border-slate-100 p-3 sm:flex sm:justify-between"
            >
                <div class="h-6 w-32 animate-pulse bg-slate-200"></div>

                <div class="h-8 w-20 animate-pulse justify-self-end bg-slate-200"></div>
            </div>
        </div>

        <div v-if="targetGroups" class="space-y-2">
            <div v-for="targetGroup in targetGroups" :key="targetGroup.id" class="rounded border border-slate-100">
                <div class="w-full p-3 text-sm sm:flex sm:justify-between">
                    <div class="flex">
                        <span class="my-auto font-medium">{{ targetGroup.title }}</span>
                    </div>

                    <div v-if="!isFormOpen && selectedTargetGroup?.id !== targetGroup.id" class="justify-self-end">
                        <button
                            class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600"
                            @click="handleEdit(targetGroup)"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'edit']"
                                class="h-4 w-4 shrink-0 fill-current opacity-50"
                            />
                            <span class="ml-2 hidden xs:block">Edit</span>
                        </button>
                    </div>
                </div>

                <div
                    v-if="isFormOpen && selectedTargetGroup?.id === targetGroup.id"
                    class="w-full border-t border-slate-100 p-3"
                >
                    <div class="w-full">
                        <div class="flex-col">
                            <div class="mb-2.5 text-left text-sm font-medium text-slate-500">
                                <span>Targeting Group Name</span>
                            </div>
                            <div>
                                <input
                                    v-model="formValues.title"
                                    type="text"
                                    class="form-input w-3/4"
                                    maxlength="200"
                                />
                            </div>
                        </div>

                        <TargetingFilters
                            :filtering="formValues"
                            :platform-id="platformId"
                            @filter-change-single="(field) => _.set(formValues.current, field.key, field.value)"
                        />
                    </div>

                    <div class="h-10">
                        <div v-if="saveGroup.isError.value" class="my-2 text-xs text-red-500">
                            {{ saveGroup.error.value }}
                        </div>
                    </div>

                    <div class="w-full sm:flex sm:justify-between">
                        <div class="justify-start">
                            <button
                                v-if="selectedTargetGroup"
                                class="btn bg-rose-500 text-white hover:bg-rose-600"
                                :class="{
                                    'pointer-events-none cursor-not-allowed opacity-50': deleteGroup.isLoading.value,
                                }"
                                @click="deleteGroup.mutate(selectedTargetGroup.id)"
                            >
                                <span v-if="deleteGroup.isLoading.value">Deleting...</span>
                                <span v-else>Delete</span>
                            </button>
                        </div>

                        <div class="justify-end space-x-2">
                            <button
                                type="button"
                                class="btn border-slate-200 text-slate-600 hover:border-slate-300"
                                @click="closeForm"
                            >
                                <span>Cancel</span>
                            </button>

                            <button
                                v-if="!selectedTargetGroup"
                                type="button"
                                class="btn bg-indigo-500 text-white hover:bg-indigo-600"
                                :class="{
                                    'pointer-events-none cursor-not-allowed opacity-50': saveGroup.isLoading.value,
                                }"
                                @click="saveGroup.mutate({ ...formValues.current, ...formValues })"
                            >
                                <span v-if="saveGroup.isLoading.value">Creating...</span>
                                <span v-else>Create</span>
                            </button>

                            <button
                                v-if="selectedTargetGroup"
                                type="button"
                                class="btn ml-2 bg-indigo-500 text-white hover:bg-indigo-600"
                                :class="{
                                    'pointer-events-none cursor-not-allowed opacity-50': saveGroup.isLoading.value,
                                }"
                                @click="saveGroup.mutate({ ...formValues.current, ...formValues })"
                            >
                                <span v-if="saveGroup.isLoading.value">Saving...</span>
                                <span v-else>Save</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="targetGroups && !(isFormOpen && !selectedTargetGroup)">
            <button
                type="button"
                class="mt-2 flex h-14 w-full items-center justify-center rounded border-2 border-dashed border-gray-200 p-2 p-3 text-center text-slate-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 hover:border-gray-300 hover:text-slate-700"
                @click="handleAddClick"
            >
                <font-awesome-icon :icon="['fas', 'plus']" class="mr-2 h-3 w-3 shrink-0 fill-current" />
                <span class="block text-sm font-medium">Add new targeting group</span>
            </button>
        </div>

        <div v-if="isFormOpen && !selectedTargetGroup" class="mt-2 w-full border border-slate-100 p-3">
            <div class="w-full">
                <div class="flex-col">
                    <div class="mb-2 text-xl font-semibold">Create Target Group</div>
                    <div class="leadix #ng-5 mb-2.5 text-left text-sm font-medium text-slate-500">
                        <span>Targeting Group Name</span>
                    </div>
                    <div>
                        <input v-model="formValues.title" type="text" class="form-input w-1/2" maxlength="200" />
                    </div>
                </div>

                <TargetingFilters
                    :filtering="formValues"
                    :platform-id="platformId"
                    @filter-change-single="(field) => _.set(formValues.current, field.key, field.value)"
                />

                <div class="h-10">
                    <div v-if="saveGroup.isError.value" class="my-2 text-xs text-red-500">
                        {{ saveGroup.error.value }}
                    </div>
                </div>
            </div>

            <div class="w-full sm:flex sm:justify-between">
                <div class="justify-end space-x-2">
                    <button
                        type="button"
                        class="btn border-slate-200 text-slate-600 hover:border-slate-300"
                        @click="closeForm"
                    >
                        <span>Cancel</span>
                    </button>

                    <button
                        v-if="!selectedTargetGroup"
                        type="button"
                        class="btn bg-indigo-500 text-white hover:bg-indigo-600"
                        :class="{ 'pointer-events-none cursor-not-allowed opacity-50': saveGroup.isLoading.value }"
                        @click="saveGroup.mutate({ ...formValues.current, ...formValues })"
                    >
                        <span v-if="saveGroup.isLoading.value">Creating...</span>
                        <span v-else>Create</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
