<script setup>
import { chain } from 'lodash'
import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { groupBy } from 'lodash/collection'
import { useRoute } from 'vue-router'
import targetGroupTools from '@/utils/searching/targetgroups'
import { axios } from '@/utils/axios'
import TargetingPlatform from '@/partials/campaigns/TargetingPlatform.vue'

const route = useRoute()

const { isLoading, data } = useQuery({
    queryKey: ['campaign-targeting', route.params.supercampaign_id],
    queryFn: async () => {
        const response = await axios.get(`/targeting/campaign/${route.params.campaign_id}`)
        const { groups, codes } = response.data.payload

        const defaults = {
            twitch: targetGroupTools.createTwitchOptions().default,
            youtube: targetGroupTools.createYoutubeOptions().default,
        }

        return chain(groups)
            .map((group) => targetGroupTools.mapTargetGroupFromFilters(group, codes, defaults))
            .orderBy(['title'], [])
            .value()
    },
})

const targetGroupsByPlatform = computed(() => groupBy(data.value, 'platform'))
</script>

<template>
    <div class="col-span-full rounded-sm border border-slate-200 bg-white shadow-lg">
        <header class="border-b border-slate-100 px-5 py-4">
            <div class="sm:flex sm:items-center sm:justify-between">
                <div class="mb-4 sm:mb-0">
                    <h1 class="text-xl font-semibold">Targeting</h1>
                    <p class="w-2/3 text-sm">
                        Here you can build out the targeting groups for your campaign. Using the filters available,
                        define the exact creator you interested in hiring.
                    </p>
                </div>
            </div>
        </header>

        <div class="grid gap-8 p-3 md:grid-cols-12">
            <div v-for="platformId in ['twitch', 'youtube']" :key="platformId" class="col-span-6 space-y-6">
                <TargetingPlatform
                    :platform-id="platformId"
                    :target-groups="targetGroupsByPlatform[platformId]"
                    :is-loading="isLoading"
                />
            </div>
        </div>
    </div>
</template>
