<template>
    <div>
        <CampaignBudgetSection
            v-if="campaign && creators"
            :campaign="campaign"
            :creators="creators"
            :campaigns-by-id="campaignsById"
        />

        <div class="col-span-full rounded-sm border border-slate-200 bg-white shadow-lg">
            <header class="border-b border-slate-100 px-5 py-4">
                <div class="sm:flex sm:items-center sm:justify-between">
                    <!-- Left: Title -->
                    <div class="mb-4 sm:mb-0">
                        <!-- <h2 class="font-semibold text-slate-800">Creators</h2> -->

                        <div
                            class="inline-flex rounded bg-slate-200 p-1 shadow-sm hover:shadow-sm focus:shadow-sm"
                            role="group"
                        >
                            <button
                                class="flex rounded px-5 py-2.5 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                :class="{
                                    'bg-white': platformId === 'twitch',
                                }"
                                @click.prevent="onPlatformClick('twitch')"
                            >
                                <font-awesome-icon class="mr-2 h-4 w-4 text-twitch" :icon="['fab', 'twitch']" />
                                <span>Twitch</span>
                            </button>
                            <button
                                class="flex rounded px-5 py-2.5 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                :class="{
                                    'bg-white': platformId === 'youtube',
                                }"
                                @click.prevent="onPlatformClick('youtube')"
                            >
                                <font-awesome-icon class="mr-2 h-4 w-4 text-youtube" :icon="['fab', 'youtube']" />
                                <span>YouTube</span>
                            </button>
                        </div>
                    </div>

                    <!-- Right: Actions  -->
                    <div class="grid grid-flow-col justify-start gap-2 sm:auto-cols-max sm:justify-end">
                        <!-- Search form -->
                        <div class="hidden sm:block">
                            <form class="relative" @submit.prevent>
                                <label for="action-search" class="sr-only">Search</label>
                                <input
                                    id="action-search"
                                    v-model="searchText"
                                    class="form-input pl-9 focus:border-slate-300"
                                    type="search"
                                    placeholder="Search..."
                                />
                                <div class="group absolute inset-0 right-auto" aria-label="Search">
                                    <svg
                                        class="ml-3 mr-2 mt-2.5 h-4 w-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                                        viewBox="0 0 16 16"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                                        />
                                        <path
                                            d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                                        />
                                    </svg>
                                </div>
                            </form>
                        </div>

                        <!-- Export button -->
                        <button class="btn bg-indigo-500 text-white hover:bg-indigo-600" @click="exportCreators">
                            Export Creators
                        </button>
                    </div>
                </div>
            </header>

            <div class="p-3">
                <!-- Table -->
                <div class="overflow-x-auto">
                    <table class="w-full table-auto">
                        <!-- Table header -->
                        <thead class="rounded-sm bg-slate-50 text-xs uppercase text-slate-400">
                            <tr>
                                <th class="w-1/4 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                    <div class="text-left font-semibold">Creator</div>
                                </th>

                                <th
                                    v-if="platformId === 'twitch'"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div class="text-left font-semibold">Locale</div>
                                </th>
                                <th
                                    v-if="platformId === 'twitch'"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div class="flex text-left">
                                        <span class="mr-2 font-semibold">Avg CCV</span>

                                        <Tooltip>
                                            <template #trigger>
                                                <font-awesome-icon
                                                    class="h-3.5 w-3.5 hover:text-slate-500"
                                                    :icon="['fas', 'circle-info']"
                                                />
                                            </template>
                                            <template #default>
                                                <div class="p-3 font-bold">
                                                    <h5 class="text-xl text-slate-200">Average Concurrent Viewers</h5>
                                                    <p class="mb-3 text-xs text-slate-300">
                                                        The average number of viewers you would expect to see in this
                                                        creator's stream at any point in the last 90 days.
                                                    </p>
                                                    <p class="text-xs italic text-slate-300">
                                                        Stats shown here are from when the creator was added to the
                                                        campaign, and are not updated to current day.
                                                    </p>
                                                </div>
                                            </template>
                                        </Tooltip>
                                    </div>
                                </th>
                                <th
                                    v-if="platformId === 'twitch'"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div class="flex text-left">
                                        <span class="mr-2 font-semibold">Followers</span>

                                        <Tooltip>
                                            <template #trigger>
                                                <font-awesome-icon
                                                    class="h-3.5 w-3.5 hover:text-slate-500"
                                                    :icon="['fas', 'circle-info']"
                                                />
                                            </template>
                                            <template #default>
                                                <div class="p-3 font-bold">
                                                    <h5 class="text-xl text-slate-200">Follower Count</h5>
                                                    <p class="mb-3 text-xs text-slate-300">
                                                        The total number of users on Twitch that have chosen to follow
                                                        this creator.
                                                    </p>
                                                    <p class="text-xs italic text-slate-300">
                                                        Stats shown here are from when the creator was added to the
                                                        campaign, and are not updated to current day.
                                                    </p>
                                                </div>
                                            </template>
                                        </Tooltip>
                                    </div>
                                </th>

                                <th
                                    v-if="platformId === 'youtube'"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div class="text-left font-semibold">Country</div>
                                </th>
                                <th
                                    v-if="platformId === 'youtube'"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div class="flex text-left">
                                        <span class="mr-2 font-semibold">Avg Video Views</span>

                                        <Tooltip>
                                            <template #trigger>
                                                <font-awesome-icon
                                                    class="h-3.5 w-3.5 hover:text-slate-500"
                                                    :icon="['fas', 'circle-info']"
                                                />
                                            </template>
                                            <template #default>
                                                <div class="p-3 font-bold">
                                                    <h5 class="text-xl text-slate-200">Average Video Views</h5>
                                                    <p class="mb-3 text-xs text-slate-300">
                                                        The average number of views a YouTube video from the last 90
                                                        days made by this creator would get.
                                                    </p>
                                                    <p class="text-xs italic text-slate-300">
                                                        Stats shown here are from when the creator was added to the
                                                        campaign, and are not updated to current day.
                                                    </p>
                                                </div>
                                            </template>
                                        </Tooltip>
                                    </div>
                                </th>
                                <th
                                    v-if="platformId === 'youtube'"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div class="flex text-left">
                                        <span class="mr-2 font-semibold">Subscribers</span>

                                        <Tooltip>
                                            <template #trigger>
                                                <font-awesome-icon
                                                    class="h-3.5 w-3.5 hover:text-slate-500"
                                                    :icon="['fas', 'circle-info']"
                                                />
                                            </template>
                                            <template #default>
                                                <div class="p-3 font-bold">
                                                    <h5 class="text-xl text-slate-200">Subscriber Count</h5>
                                                    <p class="mb-3 text-xs text-slate-300">
                                                        The total number of users on YouTube that have chosen to
                                                        subscribe to this creator's channel.
                                                    </p>
                                                    <p class="text-xs italic text-slate-300">
                                                        Stats shown here are from when the creator was added to the
                                                        campaign, and are not updated to current day.
                                                    </p>
                                                </div>
                                            </template>
                                        </Tooltip>
                                    </div>
                                </th>
                                <th v-if="isAffinityEnabled" class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                    <div class="text-left font-semibold">Affinity</div>
                                </th>
                                <th v-if="isSpendShown" class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                    <div class="text-left font-semibold">Spend</div>
                                </th>
                                <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                    <div class="text-right font-semibold">Links</div>
                                </th>
                            </tr>
                        </thead>

                        <!-- Table body -->
                        <tbody class="divide-y divide-slate-200 border-b border-slate-200 text-sm">
                            <!-- Skeleton component -->
                            <SkeletonCreatorsSubpage v-if="!state.isLoaded" />

                            <!-- Real table -->
                            <tr
                                v-for="creator in filteredCreators"
                                v-else
                                :key="creator.participant.platform_user_id"
                                class="group"
                            >
                                <td class="flex whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">
                                    <div class="relative flex w-full items-center">
                                        <NamePlate
                                            class="my-auto"
                                            :logo="creator.participant.platform_user_logo"
                                            :display-name="creator.participant.platform_user_name"
                                            :platform-id="creator.participant.platform_id"
                                            :platform-user-id="creator.participant.platform_user_id"
                                            :is-drawer-enabled="true"
                                        />

                                        <div
                                            class="absolute right-0 top-0 hidden h-full cursor-pointer rounded bg-slate-100 px-2 text-xs font-semibold transition duration-200 group-hover:flex hover:bg-slate-200"
                                            @click="
                                                $store.commit('modal:setData', {
                                                    modal_id: 'creator',
                                                    data: {
                                                        is_open: true,
                                                        platform_id: creator.participant.platform_id,
                                                        platform_user_id: creator.superview._id,
                                                    },
                                                })
                                            "
                                        >
                                            <span class="my-auto flex uppercase">
                                                <font-awesome-icon
                                                    :icon="['fas', 'rectangle-list']"
                                                    class="my-auto mr-1 h-4 w-4"
                                                />
                                                <span class="my-auto leading-none">Open</span>
                                            </span>
                                        </div>
                                    </div>
                                </td>

                                <td
                                    v-if="creator.participant.platform_id === 'twitch'"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6"
                                >
                                    <div class="text-left">
                                        {{ creator.participant.superview.channel.language.toUpperCase() }}
                                    </div>
                                </td>

                                <td
                                    v-if="creator.participant.platform_id === 'twitch'"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6"
                                >
                                    <div class="text-left">
                                        {{
                                            $filters
                                                .numeral(
                                                    creator.participant.superview.stream.day_90.aggregation.viewers.avg,
                                                )
                                                .format('0,0')
                                        }}
                                    </div>
                                </td>

                                <td
                                    v-if="creator.participant.platform_id === 'twitch'"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6"
                                >
                                    <div class="text-left">
                                        {{
                                            $filters.formatWholeNumberWithUnit(
                                                creator.participant.superview.channel.followers,
                                            )
                                        }}
                                    </div>
                                </td>

                                <td
                                    v-if="creator.participant.platform_id === 'youtube'"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6"
                                >
                                    <div class="text-left">
                                        {{ creator.participant.superview.channel.snippet.country }}
                                    </div>
                                </td>

                                <td
                                    v-if="creator.participant.platform_id === 'youtube'"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6"
                                >
                                    <div class="text-left">
                                        {{
                                            $filters
                                                .numeral(
                                                    creator.participant.superview.video.day_90.aggregation.views.avg,
                                                )
                                                .format('0,0')
                                        }}
                                    </div>
                                </td>

                                <td
                                    v-if="creator.participant.platform_id === 'youtube'"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6"
                                >
                                    <div class="text-left">
                                        {{
                                            $filters.formatWholeNumberWithUnit(
                                                creator.participant.superview.channel.statistics.subscriberCount,
                                            )
                                        }}
                                    </div>
                                </td>

                                <td
                                    v-if="isAffinityEnabled"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6"
                                >
                                    <div v-if="supercampaign.payload.is_demo === true" class="text-left">
                                        {{ $filters.numeral(0.5 + Math.random() * 0.5).format('0,0%') }}
                                    </div>
                                    <div v-else-if="creator.affinity.combined.percent" class="text-left">
                                        {{ $filters.numeral(creator.affinity.combined.percent).format('0,0%') }}
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>

                                <td
                                    v-if="isSpendShown"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6"
                                >
                                    <div v-if="supercampaign.payload.is_demo === true" class="text-left">
                                        ${{ $filters.numeral(100 + Math.random() * 500).format('0,0') }}
                                    </div>
                                    <div v-else-if="creator.participant.payout_amount" class="text-left">
                                        ${{ $filters.numeral(creator.participant.payout_amount).format('0,0') }}
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>

                                <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/5">
                                    <div class="flex justify-end text-right">
                                        <div class="flex border-r-2 pr-1.5">
                                            <a
                                                :href="`https://streamforge.com/twitch/${creator.participant.superview.channel.name}`"
                                                target="_blank"
                                                class="flex h-5 w-5 fill-current text-slate-600"
                                            >
                                                <!-- <font-awesome-icon
                                                :icon="['fas', 'square-user']"
                                            /> -->

                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
                                                    <path
                                                        class="cls-1"
                                                        fill="#ff005c"
                                                        d="M540,0c297.76,0,540,242.24,540,540s-242.24,540-540,540H0V540C0,242.24,242.24,0,540,0Z"
                                                    />
                                                    <path
                                                        class="cls-2"
                                                        fill="#ff9171"
                                                        d="M540,216c178.65,0,324,145.35,324,324S718.65,864,540,864H216V540C216,361.35,361.35,216,540,216Z"
                                                    />
                                                    <path
                                                        class="cls-3"
                                                        fill="#fcd878"
                                                        d="M540,432H648a0,0,0,0,1,0,0V540A108,108,0,0,1,540,648h0A108,108,0,0,1,432,540v0A108,108,0,0,1,540,432Z"
                                                        transform="translate(1080 1080) rotate(-180)"
                                                    />
                                                </svg>
                                            </a>
                                        </div>

                                        <a
                                            v-if="creator.participant.platform_id === 'twitch'"
                                            :href="`https://twitch.tv/${creator.participant.superview.channel.name}`"
                                            target="_blank"
                                            class="ml-2 h-5 w-5 fill-current text-twitch"
                                        >
                                            <font-awesome-icon :icon="['fab', 'twitch']" />
                                        </a>

                                        <a
                                            v-if="creator.participant.platform_id === 'youtube'"
                                            :href="`https://youtube.com/channel/${creator.participant.platform_user_id}`"
                                            target="_blank"
                                            class="ml-2 flex h-5 w-5 fill-current text-youtube"
                                        >
                                            <font-awesome-icon class="my-auto" :icon="['fab', 'youtube']" />
                                        </a>
                                    </div>
                                </td>
                            </tr>

                            <tr v-if="!creators || !filteredCreators.length">
                                <td class="px-5 py-3 text-center" colspan="10">
                                    <p v-if="searchText">No creators found matching "{{ searchText }}"</p>
                                    <p v-else-if="!searchText && state.isLoaded">
                                        There are no approved creators for this platform
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import { json2csvAsync } from 'json-2-csv'
import Tooltip from '@/components/Tooltip.vue'
import NamePlate from '@/components/plates/NamePlate.vue'
import SkeletonCreatorsSubpage from '@/partials/skeletons/SkeletonCreatorsSubpage.vue'
import CampaignBudgetSection from '@/partials/widgets/CampaignBudgetSection.vue'

export default {
    name: 'CampaignCreatorsSubpage',

    components: {
        SkeletonCreatorsSubpage,
        Tooltip,
        NamePlate,
        CampaignBudgetSection,
    },

    props: {
        supercampaign: Object,
        campaignsById: Object,
    },

    data() {
        return {
            creators: null,

            searchText: '',

            platformId: 'twitch',

            state: {
                isLoaded: false,
            },
            tippyInstances: [],
        }
    },

    created() {
        this.getParticipants(this.$route.params.campaign_id)
    },

    computed: {
        ...mapGetters(['isSfStaff', 'getFromDictionary']),

        campaign() {
            if (!this.campaignsById) return null
            return this.campaignsById[this.$route.params.campaign_id]
        },

        filteredCreators() {
            const platformCreators = _.filter(this.creators, (item) => item.participant.platform_id === this.platformId)

            if (this.searchText) {
                return _.filter(platformCreators, (item) =>
                    item.participant.platform_user_name.includes(this.searchText.toLowerCase()),
                )
            }

            return platformCreators
        },

        isAffinityEnabled() {
            if (!this.supercampaign) return false

            const isEnabled = _.get(this.supercampaign, 'payload.is_affinity_enabled')

            if (isEnabled) {
                if (this.campaign.type === 'game-sponsorship') {
                    return true
                }
            }

            return false
        },

        isSpendShown() {
            const startedAt = moment.utc(this.campaign.started_at)
            const cutoffDate = moment.utc('2022-10-15')

            if (startedAt.isValid() && startedAt.isAfter(cutoffDate)) {
                return true
            }

            if (this.supercampaign.payload.is_demo === true) {
                return true
            }

            return false
        },
    },

    methods: {
        onPlatformClick(platformId) {
            this.platformId = platformId
        },

        getParticipants(campaignId) {
            this.$axios
                .get(`/supercampaigns/${this.supercampaign.id}/campaigns/${campaignId}/participants`, {
                    params: {
                        // platform_id: 'twitch',
                        relations: ['campaign_task'],
                    },
                })
                .then(({ data }) => {
                    this.creators = _.filter(
                        data.payload.creators,
                        (item) => item.participant.approved_status === 'approved',
                    )

                    _.forEach(this.creators, ({ participant }) => {
                        // eslint-disable-next-line no-param-reassign
                        participant.payout_amount = _.sumBy(participant.tasks, 'payout_amount')
                    })

                    this.state.isLoaded = true
                })
                .catch((error) => {
                    console.error(`Error fetching campaigns`, error)
                })
        },

        statusColor(status) {
            switch (status) {
                case 'pending':
                    return 'bg-slate-100 text-slate-500'
                case 'approved':
                    return 'bg-emerald-100 text-emerald-600'
                case 'denied':
                    return 'bg-rose-100 text-rose-500'
                default:
                    return 'bg-slate-100 text-slate-500'
            }
        },

        async exportCreators() {
            const link = document.createElement('a')

            const fileName = `${this.supercampaign.title} - ${this.getFromDictionary(
                `campaign.type.${this.campaign.type}`,
                'labelPlural',
            )} - ${this.platformId}- Creators.csv`

            let platformCreators

            if (this.platformId === 'twitch') {
                platformCreators = _.map(this.filteredCreators, (creator) => {
                    const defaultFields = {
                        // 'Logo': `=IMAGE("${_.get(creator, 'participant.platform_user_logo')}")`,
                        'Creator Name': _.get(creator, 'participant.platform_user_name'),
                        'Connect Page': `https://streamforge.com/${this.platformId}/${_.get(
                            creator,
                            'participant.platform_user_name',
                        )}`,
                        Locale: _.get(creator, 'participant.superview.channel.language'),
                        '90 day ACV': numeral(
                            _.get(creator, 'participant.superview.stream.day_90.aggregation.viewers.avg'),
                        ).format('0,0'),
                        Followers: numeral(_.get(creator, 'participant.superview.channel.followers')).format('0,0'),
                        // 'Affinity': numeral(_.get(creator, 'affinity.combined.percent')).format('0,0.0%'),
                    }

                    if (this.isSfStaff) {
                        return {
                            ...defaultFields,
                            Email: _.get(creator, 'participant.superview.normalized.email', 'no email'),
                        }
                    }

                    return defaultFields
                })
            }

            if (this.platformId === 'youtube') {
                platformCreators = _.map(this.filteredCreators, (creator) => {
                    const defaultFields = {
                        // 'Logo': `=IMAGE("${_.get(creator, 'participant.platform_user_logo')}")`,
                        'Creator Name': _.get(creator, 'participant.platform_user_name'),
                        // 'Connect Page': `https://streamforge.com/${this.platformId}/${_.get(creator, 'participant.platform_user_name')}`,
                        Country: _.get(creator, 'participant.superview.channel.snippet.country'),
                        'AVG Video Views': numeral(
                            _.get(creator, 'participant.superview.video.day_90.aggregation.views.avg'),
                        ).format('0,0'),
                        Subscribers: numeral(
                            _.get(creator, 'participant.superview.channel.statistics.subscriberCount'),
                        ).format('0,0'),
                        // 'Affinity': numeral(_.get(creator, 'affinity.combined.percent')).format('0,0.0%'),
                    }

                    if (this.isSfStaff) {
                        return {
                            ...defaultFields,
                            Email: _.get(creator, 'participant.superview.normalized.email', 'no email'),
                        }
                    }

                    return defaultFields
                })
            }

            const csvResults = await json2csvAsync(platformCreators)

            link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(csvResults)}`)
            link.setAttribute('download', fileName)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        },
    },
}
</script>
