import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

import LogRocket from 'logrocket'
import createPlugin from 'logrocket-vuex'

// Modules
import authentication from './authentication'
import colors from './colors'
import languages from './languages'
import requests from './requests'
import dictionary from './dictionary'
import self from './self'
import permissions from './permissions'
import modals from './modals'
import drawer from './drawer'
import countries from './countries'
import recentSearches from './recentSearches'
import recentCampaignOrListAdds from './recentCampaignOrListAdds'
import preferences from './preferences'
import backgroundActions from './backgroundActions'

// Only persist certain paths to localstorage
const plugins = [
    new VuexPersistence({
        modules: ['authentication', 'self', 'recentSearches', 'recentCampaignOrListAdds', 'preferences'],
    }).plugin,
    createPlugin(LogRocket),
]

export default createStore({
    /**
     * Assign the modules to the store
     */
    modules: {
        authentication,
        colors,
        countries,
        languages,
        requests,
        dictionary,
        self,
        permissions,
        modals,
        drawer,
        recentSearches,
        recentCampaignOrListAdds,
        preferences,
        backgroundActions,
    },

    /**
     * If strict mode should be enabled
     */
    strict: false,

    /**
     * Plugins used in the store
     */
    plugins,
})
