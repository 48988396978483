<template>
    <UIDialog
        v-if="modals.inquiry_preview.is_open"
        :open="modals.inquiry_preview.is_open"
        @update:open="$store.commit('modal:close', 'inquiry_preview')"
    >
        <DialogScrollContent v-if="modals?.inquiry_preview?.inquiry" :class="'max-w-2xl border-none p-0'">
            <DialogHeader class="border-b border-slate-100">
                <DialogTitle class="px-4 py-4 capitalize">Preview Inquiry</DialogTitle>
            </DialogHeader>
            <div>
                <div v-if="!modals?.inquiry_preview?.preview" class="p-3">Loading...</div>
                <div v-else class="max-h-[80vh] overflow-y-scroll" v-html="modals.inquiry_preview.preview.output"></div>
            </div>
        </DialogScrollContent>
    </UIDialog>

    <div>
        <div class="grid-cols-1 xl:grid">
            <div v-if="summary !== null" class="flex flex-wrap xl:h-12 xl:flex-nowrap">
                <div
                    v-for="tab in tabs"
                    :key="tab.key"
                    class="group flex w-full cursor-pointer select-none border bg-white px-4 py-3 text-left text-xs font-medium uppercase text-slate-500 first:rounded-bl first:rounded-tl first:border-l last:rounded-br last:rounded-tr last:border-r"
                    :class="{
                        'border-slate-200 hover:bg-slate-50': tab.key !== state.activeTab,
                        'border-b-slate-400': tab.key === state.activeTab,
                        '!cursor-default border-slate-400 border-b-slate-200 !bg-indigo-50':
                            tab.key === state.activeTab,
                    }"
                    @click="setActiveTab(tab.key)"
                >
                    <div class="flex grow justify-between">
                        <div class="my-auto flex">
                            <span class="leading-none">{{ tab.label }}</span>
                        </div>
                        <div class="my-auto">
                            {{ getTabRecordCount(tab.key) }}
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="flex h-12 w-full">
                <div class="mr-[1px] grow rounded-br border-b border-l border-r border-l-slate-400 bg-slate-50" />
            </div>
        </div>

        <div class="col-span-full mt-4 rounded-sm border border-slate-200 bg-white">
            <div class="p-3">
                <div class="">
                    <table v-if="!state.isLoading" class="w-full table-auto">
                        <thead class="rounded-sm bg-slate-50 text-left text-xs uppercase text-slate-400">
                            <tr>
                                <th class="w-7 whitespace-nowrap p-2 pl-3 pl-5">
                                    <div class="flex"></div>
                                </th>

                                <th
                                    class="cursor-pointer whitespace-nowrap p-2"
                                    @click="sortByField('participant.platform_user_name')"
                                >
                                    <div class="flex">
                                        <span class="font-semibold">Recipient</span>
                                    </div>
                                </th>

                                <th class="cursor-pointer whitespace-nowrap p-2" @click="sortByField('campaign.title')">
                                    <div class="flex">
                                        <span class="font-semibold">Campaign</span>
                                    </div>
                                </th>

                                <th class="cursor-pointer whitespace-nowrap p-2" @click="sortByField('created_at')">
                                    <div class="flex">
                                        <span class="font-semibold">Created</span>
                                    </div>
                                </th>

                                <th
                                    v-if="state.activeTab === 'total'"
                                    class="cursor-pointer whitespace-nowrap p-2"
                                    @click="sortByField('is_interested')"
                                >
                                    <div class="flex">
                                        <span class="font-semibold">Interested</span>
                                    </div>
                                </th>

                                <th class="w-7 pb-3 pl-2 pr-5 pt-3">
                                    <div
                                        class="flex w-full rounded bg-slate-100 text-slate-600 transition duration-200 hover:bg-slate-200"
                                    ></div>
                                </th>
                            </tr>
                        </thead>

                        <tbody
                            class="divide-y divide-slate-200 border-b border-slate-200 text-sm"
                            :class="{ 'pointer-events-none opacity-50': state.isBusy === true }"
                        >
                            <tr v-for="inquiry in inquiries" :key="inquiry.id" class="group">
                                <td class="whitespace-nowrap px-2 py-3.5 pl-5"></td>

                                <td class="whitespace-nowrap px-2 py-3.5">
                                    <div class="relative flex items-center">
                                        {{ inquiry.participant?.platform_user_name }}
                                    </div>
                                </td>

                                <td class="whitespace-nowrap px-2 py-3.5">
                                    <div class="relative flex items-center">
                                        {{ inquiry.campaign?.title }}
                                        <div
                                            class="absolute right-0 top-0 hidden h-full cursor-pointer rounded bg-slate-100 px-2 text-xs font-semibold transition duration-200 group-hover:flex hover:bg-slate-200"
                                            @click="onShowPreview(inquiry)"
                                        >
                                            <span class="my-auto flex uppercase">
                                                <font-awesome-icon
                                                    :icon="['fas', 'rectangle-list']"
                                                    class="my-auto mr-1 h-4 w-4"
                                                />
                                                <span class="my-auto leading-none">Open</span>
                                            </span>
                                        </div>
                                    </div>
                                </td>

                                <td class="whitespace-nowrap px-2 py-3.5">
                                    <div class="relative flex items-center">
                                        {{ $filters.formatDateAndTime(inquiry.created_at) }}
                                    </div>
                                </td>

                                <td v-if="state.activeTab === 'total'" class="whitespace-nowrap px-2 py-3.5">
                                    <div class="relative flex items-center">
                                        {{ getInterestedText(inquiry.is_interested) }}
                                    </div>
                                </td>

                                <td class="w-7 px-2 pr-5">
                                    <div
                                        class="flex w-full rounded bg-slate-50 transition duration-200 hover:bg-slate-200"
                                    ></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="!inquiries || (!inquiries.length && !state.isLoading)" class="w-full p-4 text-center">
                        <p class="text-sm font-medium">No inquiries to show</p>
                    </div>
                </div>
            </div>

            <div class="my-4 ml-4 mr-4">
                <DiscoveryPagination :paging="state.paging" record-name="messages" @paging-changed="onPagingChange" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Dialog, DialogHeader, DialogTitle, DialogScrollContent } from '@/components/ui/dialog'
import DiscoveryPagination from '@/partials/tables/discovery/DiscoveryPagination.vue'
import inquiryAPI from '@/utils/api/api.campaign.inquiry'

export default {
    name: 'InquiriesPage',

    metaInfo: {
        title: 'Inquiries',
    },

    components: {
        UIDialog: Dialog,
        DialogHeader,
        DialogTitle,
        DialogScrollContent,
        DiscoveryPagination,
    },

    props: {
        campaignId: {
            type: String,
            default: null,
            required: false,
        },
    },

    data() {
        return {
            state: {
                isLoading: false,
                isBusy: false,
                activeTab: 'total',
                paging: {
                    currentPage: 1,
                    totalRecords: 0,
                    perPage: 10,
                    sortby: 'created_at',
                    sortdir: 'desc',
                },
            },
            inquiries: [],
            summary: {
                all: 0,
                interested: 0,
                uninterested: 0,
            },
            tabs: [
                { key: 'total', label: 'All' },
                { key: 'interested', label: 'Interested' },
                { key: 'uninterested', label: 'Not Interested' },
            ],
            actions: [],
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'selfOperator', 'selfActiveOrganizationId', 'modals']),
    },

    created() {
        this.getInquiries()
    },

    methods: {
        async sortByField(column) {
            if (this.state.paging.sortby === column && this.state.paging.sortdir === 'desc') {
                this.state.paging.sortdir = 'asc'
            } else {
                this.state.paging.sortdir = 'desc'
            }
            this.state.paging.sortby = column
            this.getInquiries()
        },

        setActiveTab(tab) {
            this.state.loading = true
            this.state.activeTab = tab
            this.state.paging.currentPage = 1
            this.getInquiries()
        },

        getTabRecordCount(key) {
            return this.summary[key] ? this.summary[key] : 0
        },

        async getInquiries() {
            this.state.loading = true
            this.inquiries = []

            const inquiryResult = await inquiryAPI.getInquiries(
                this.$axios,
                this.campaignId,
                this.state.activeTab,
                this.state.paging.currentPage,
                this.state.paging.perPage,
                this.state.paging.sortby,
                this.state.paging.sortdir,
            )

            if (inquiryResult.success) {
                this.state.paging.totalRecords = inquiryResult.value.paging.totalRecords
                this.state.paging.totalPages = Math.ceil(this.state.paging.totalRecords / this.state.paging.perPage)
                this.inquiries = inquiryResult.value.records
                this.summary = inquiryResult.value.summary
            }

            this.state.loading = false
        },

        onPagingChange(paging) {
            this.state.paging.currentPage = paging.currentPage ? paging.currentPage : this.state.paging.currentPage
            this.state.paging.perPage = paging.perPage ? paging.perPage : this.state.paging.perPage
            this.state.paging.totalPages = paging.totalPages ? paging.totalPages : this.state.paging.totalPages
            this.getInquiries()
        },

        async onReload() {
            await this.getInquiries()
        },

        async getInquriyPreview(id) {
            const inquiryResult = await inquiryAPI.getInquiryPreview(this.$axios, id)

            if (inquiryResult.success) {
                this.$store.commit('modal:setData', {
                    modal_id: 'inquiry_preview',
                    data: {
                        preview: inquiryResult.value,
                    },
                })
            }
        },

        async onShowPreview(inquiry) {
            this.$store.commit('modal:setData', {
                modal_id: 'inquiry_preview',
                data: {
                    is_open: true,
                    inquiry,
                    preview: null,
                },
            })
            this.getInquriyPreview(inquiry.id)
        },

        getInterestedText(isInterested) {
            if (isInterested === true) {
                return 'Yes'
            }
            if (isInterested === false) {
                return 'No'
            }
            return ''
        },
    },
}
</script>
