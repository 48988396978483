<script setup>
import moment from 'moment'

const props = defineProps({
    platformId: {
        type: String,
    },
    data: {
        type: Object,
        required: true,
    },
})
</script>

<template>
    <div class="relative flex whitespace-nowrap pr-20">
        <div class="mr-1.5 flex w-10">
            <div class="m-auto rounded bg-slate-100 p-2 leading-none text-slate-500">
                <font-awesome-icon
                    :icon="['fas', props?.data?.types?.includes('keyword') ? 'tags' : 'hashtag']"
                    class="h-4 w-4"
                />
            </div>
        </div>
        <div class="flex flex-col leading-none">
            <div class="font-semibold">
                {{ props.data.tag }}
            </div>
            <div class="mt-0.5 text-xs">Last used on {{ moment(props.data.last_content_at).format('MMM Do, YY') }}</div>
        </div>
        <router-link
            class="absolute right-0 top-0 ml-4 flex h-full"
            :to="{
                path: $route.path,
                query: {
                    ...$route.query,
                    ['profile.tabs.secondary']: 'all_content',
                    ['profile.tabs.secondary.filters']: `tags:${encodeURIComponent(props.data.tag)}`,
                },
            }"
        >
            <div
                class="my-auto ml-auto hidden cursor-pointer rounded bg-slate-100 px-2 py-1.5 text-xs font-semibold transition duration-200 group-hover:flex hover:bg-slate-200"
            >
                <span class="my-auto flex uppercase">
                    <font-awesome-icon :icon="['fas', 'rectangle-list']" class="my-auto mr-1 h-4 w-4" />
                    <span class="my-auto leading-none">Open</span>
                </span>
            </div>
        </router-link>
    </div>
</template>
