import _ from 'lodash'

export default {
    state: {
        recentSearches: [],
        maxSearches: 25,
    },

    /* eslint-disable no-param-reassign */
    mutations: {
        'recentSearches:add': function (state, search) {
            if (!_.includes(state.recentSearches, search)) {
                if (state.recentSearches.length === state.maxSearches) {
                    state.recentSearches.pop()
                }
                state.recentSearches.unshift(search)
            }
        },
        'recentSearches:remove': function (state, search) {
            state.recentSearches = _.filter(state.recentSearches, (recentSearch) => recentSearch !== search)
        },
        'recentSearches:clear': function (state) {
            state.recentSearches = []
        },
    },
    /* eslint-enable no-param-reassign */

    getters: {
        recentSearches: (state) => state.recentSearches,
    },
}
