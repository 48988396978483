<template>
    <div>
        <!-- <p class="mb-2 text-sm">
            Control who has access to <span class="font-semibold">{{ currentList.name }}</span>
        </p> -->

        <div role="group" class="mb-4 mt-2 inline-flex rounded bg-slate-200 p-1">
            <button
                class="flex rounded px-4 py-2 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                :class="{
                    'bg-white': filter.type === 'members',
                }"
                @click.prevent="onAccessClick('members')"
            >
                <span>Members</span>
            </button>

            <button
                class="flex rounded px-4 py-2 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                :class="{
                    'bg-white': filter.type === 'organizations',
                }"
                @click.prevent="onAccessClick('organizations')"
            >
                <span>Organizations</span>
            </button>
        </div>

        <form class="relative my-auto" @submit.prevent>
            <label for="action-search" class="sr-only">Search</label>
            <input
                id="action-search"
                v-model="filter.search"
                class="form-input w-full pl-9"
                type="search"
                :placeholder="filter.type === 'members' ? `Type a user\'s name...` : 'Type an organization name...'"
            />

            <div class="group absolute inset-0 right-auto" aria-label="Search">
                <svg
                    class="ml-3 mr-2 mt-2.5 h-4 w-4 shrink-0 fill-current text-slate-400 transition duration-200 group-hover:text-slate-500"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                    />
                    <path
                        d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                    />
                </svg>
            </div>
        </form>

        <div
            class="mt-4 h-72 overflow-auto rounded border border-slate-200 text-sm md:scrollbar md:scrollbar-thin md:scrollbar-track-transparent md:scrollbar-thumb-slate-300"
        >
            <div class="w-full">
                <div v-if="filter.type === 'organizations'" class="divide-y divide-slate-200">
                    <div
                        v-for="organization in filteredAccess"
                        :key="organization.id"
                        class="flex items-center justify-between px-3 py-2 transition duration-200 hover:bg-slate-50"
                    >
                        <div class="flex">
                            <div class="flex h-7 w-7 rounded-full bg-slate-200">
                                <font-awesome-icon
                                    fixed-width
                                    :icon="['fad', 'building']"
                                    class="m-auto h-4 w-4 shrink-0"
                                    :path-classes="['text-slate-600', 'text-slate-400']"
                                />
                            </div>

                            <span class="my-auto ml-2.5 font-semibold">{{ organization.name }}</span>
                        </div>

                        <div>
                            <button
                                v-if="isShared(currentList, { organizationId: organization.id })"
                                class="btn btn-xs my-auto h-8 bg-rose-500 text-white hover:bg-rose-600"
                                @click="
                                    onListShareClick(currentList, {
                                        type: 'remove',
                                        organization_id: organization.id,
                                        name: organization.name,
                                    })
                                "
                            >
                                <font-awesome-icon class="my-auto mr-1 h-4 w-4" :icon="['fas', 'xmark']" />
                                <span>Remove</span>
                            </button>

                            <button
                                v-else
                                class="btn btn-xs my-auto h-8 bg-emerald-500 text-white hover:bg-emerald-600"
                                @click="
                                    onListShareClick(currentList, {
                                        type: 'add',
                                        organization_id: organization.id,
                                        name: organization.name,
                                    })
                                "
                            >
                                <font-awesome-icon class="my-auto mr-1 h-4 w-4" :icon="['fas', 'plus']" />
                                <span>Share</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div v-else-if="filter.type === 'members'">
                    <div v-if="operators && operators.length > 0" class="divide-y divide-slate-200">
                        <div
                            v-for="operator in filteredAccess"
                            :key="operator.id"
                            class="flex h-12 px-3 py-2 transition duration-200 hover:bg-slate-50"
                        >
                            <div class="flex grow items-center">
                                <div class="flex items-center">
                                    <Avatar
                                        class="bg-slate-700 text-slate-50"
                                        :username="`${operator.first_name} ${operator.last_name}`"
                                        :size="28"
                                    />
                                    <div class="flex flex-col pl-2.5">
                                        <div class="pr-2 font-semibold leading-none">
                                            {{ operator.first_name }} {{ operator.last_name }}
                                        </div>
                                        <div class="text-xs leading-none">
                                            {{
                                                operator.organizations
                                                    .map((organization) => organization.name)
                                                    .join(', ')
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <button
                                    v-if="isShared(currentList, { operatorId: operator.id })"
                                    class="btn btn-xs my-auto h-8 bg-rose-500 text-white hover:bg-rose-600"
                                    @click="
                                        onListShareClick(currentList, {
                                            type: 'remove',
                                            operator_id: operator.id,
                                            name: `${operator.first_name} ${operator.last_name}`,
                                        })
                                    "
                                >
                                    <font-awesome-icon class="my-auto mr-1 h-4 w-4" :icon="['fas', 'xmark']" />
                                    <span>Remove</span>
                                </button>

                                <button
                                    v-else
                                    class="btn btn-xs my-auto h-8 bg-emerald-500 text-white hover:bg-emerald-600"
                                    @click="
                                        onListShareClick(currentList, {
                                            type: 'add',
                                            operator_id: operator.id,
                                            name: `${operator.first_name} ${operator.last_name}`,
                                        })
                                    "
                                >
                                    <font-awesome-icon class="my-auto mr-1 h-4 w-4" :icon="['fas', 'plus']" />
                                    <span>Share</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div v-if="operators && operators.length === 0" class="flex flex-col bg-slate-100 px-6 py-6">
                        <div class="mx-auto flex h-16 w-16 rounded-full bg-slate-200">
                            <font-awesome-icon
                                fixed-width
                                :icon="['fas', 'user-group']"
                                class="m-auto h-8 w-8 shrink-0"
                                :path-classes="['text-slate-600', 'text-slate-400']"
                            />
                        </div>

                        <p class="mt-4 text-center text-sm">
                            There are no users in the organizations you belong to that you can share this list with.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import Avatar from '@/components/Avatar.vue'

export default {
    name: 'ListAccessControls',

    components: {
        Avatar,
    },

    props: {
        currentList: {
            type: Object,
        },
        operators: {
            type: Object,
        },
        organizations: {
            type: Object,
        },
    },

    emits: ['on-list-access'],

    data() {
        return {
            filter: {
                type: 'members',
                search: '',
            },
            allOrganizations: null,
        }
    },

    computed: {
        ...mapGetters(['isSfStaff', 'selfOrganizations']),

        localOrganizations() {
            if (!this.isSfStaff) {
                return this.organizations
            }

            return this.selfOrganizations
        },

        filteredAccess() {
            const searchKeyword = this.filter.search.toLowerCase().trim()

            if (this.filter.type === 'members') {
                if (!this.filter.search) return this.operators

                return _.filter(this.operators, (item) => {
                    const fullName = `${item.first_name} ${item.last_name}`
                    return fullName.toLowerCase().includes(searchKeyword)
                })
            }

            if (this.filter.type === 'organizations') {
                if (!this.filter.search) return this.localOrganizations

                return _.filter(this.localOrganizations, (item) => item.name.toLowerCase().includes(searchKeyword))
            }

            throw new Error(`Invalid filter type: ${this.filter.type}`)
        },
    },

    methods: {
        onAccessClick(type) {
            this.filter.type = type
        },

        isShared(list, { operatorId, organizationId }) {
            if (list && list.sharedTo) {
                if (operatorId && list.sharedTo.operators && list.sharedTo.operators.length) {
                    return _.includes(list.sharedTo.operators, operatorId)
                }

                if (organizationId && list.sharedTo.organizations && list.sharedTo.organizations.length) {
                    return _.includes(list.sharedTo.organizations, organizationId)
                }
            }

            return false
        },

        // eslint-disable-next-line camelcase
        onListShareClick(list, { type, organization_id: organizationId, operator_id: operatorId, name }) {
            let sharedOperator = null
            let sharedOrganization = null

            if (organizationId) {
                sharedOrganization = {
                    id: organizationId,
                    name,
                }
            } else if (operatorId) {
                sharedOperator = {
                    id: operatorId,
                    name,
                }
            }

            this.$emit('on-list-access', {
                list,
                type,
                sharedOperator,
                sharedOrganization,
            })
        },
    },
}
</script>
