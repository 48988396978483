import api from './api'

const apicampaigntasks = {
    async insertTask(axios, supercampaignId, campaignId, task, participants) {
        const body = {
            task,
            participants,
        }

        return axios
            .post(`/supercampaigns/${supercampaignId}/campaigns/${campaignId}/tasks`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload.tasks))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async updateTask(axios, supercampaignId, campaignId, task) {
        const body = {
            task,
        }

        return axios
            .put(`/supercampaigns/${supercampaignId}/campaigns/${campaignId}/tasks/${task.id}`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async deleteTask(axios, supercampaignId, campaignId, taskId) {
        return axios
            .delete(`/supercampaigns/${supercampaignId}/campaigns/${campaignId}/tasks/${taskId}`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getCampaignDefaultTasks(axios, supercampaignId, campaignId) {
        return axios
            .get(`/supercampaigns/${supercampaignId}/campaigns/${campaignId}/tasks/default`)
            .then(({ data }) => api.createSuccessResponse(data.payload.tasks))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getCampaignParticipantTasks(axios, supercampaignId, campaignId, participantId, relations = []) {
        return axios
            .get(`/supercampaigns/${supercampaignId}/campaigns/${campaignId}/participant/${participantId}/tasks`, {
                params: {
                    relations,
                },
            })
            .then(({ data }) => api.createSuccessResponse(data.payload.tasks))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getTaskDeliverables(axios, supercampaignId, campaignId, taskId) {
        const body = {
            task_id: taskId,
        }

        return axios
            .get(`/supercampaigns/${supercampaignId}/campaigns/${campaignId}/tasks/${taskId}/deliverables`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload.deliverable))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apicampaigntasks
