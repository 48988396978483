<template>
    <EditSavedSearchModal
        v-if="Boolean(selectedSavedSearch)"
        :is-open="true"
        :on-cancel="() => (selectedSavedSearch = undefined)"
        :on-save="() => {}"
        :on-close="() => (selectedSavedSearch = undefined)"
        :saved-search="selectedSavedSearch"
        :codes="props.codes"
    />

    <Popover>
        <PopoverTrigger as-child>
            <slot />
        </PopoverTrigger>

        <PopoverContent class="p-0" side="bottom" align="center">
            <Tabs :default-value="defaultTabValue" class="w-full">
                <TabsList
                    class="grid w-full"
                    :class="[checkPermission('module.campaigns') ? 'grid-cols-2' : 'grid-cols-1']"
                >
                    <TabsTrigger v-if="checkPermission('module.campaigns')" value="targeting"> Campaigns </TabsTrigger>
                    <TabsTrigger value="saved"> Discovery Searches </TabsTrigger>
                </TabsList>

                <TabsContent v-if="checkPermission('module.campaigns')" value="targeting" class="border-none p-0">
                    <div class="bg-muted px-3 py-1 text-sm font-medium">Campaign Targeting Groups</div>
                    <ScrollArea class="max-h-96 overflow-y-scroll">
                        <div
                            v-for="group in campaignTargetingGroups"
                            :key="group.id"
                            class="cursor-pointer border-b px-3 py-3 transition-colors hover:bg-accent/50"
                            @click="onSelect(group)"
                        >
                            <span class="block text-sm font-medium" :title="group.title">
                                {{ group.title }}
                            </span>
                        </div>
                    </ScrollArea>
                </TabsContent>

                <TabsContent value="saved" class="border-none p-0">
                    <div class="bg-muted px-3 py-1 text-sm font-medium">
                        {{ selectedOrganization.name }} Discovery Groups
                    </div>
                    <ScrollArea class="max-h-96 overflow-y-scroll">
                        <div
                            v-if="!discoveryGroups.length"
                            class="flex flex-col items-center justify-center space-y-2 px-2 py-8 text-center"
                        >
                            <Search class="h-8 w-8 text-muted-foreground" />
                            <p class="text-muted-foreground">No discovery groups</p>
                        </div>

                        <div
                            v-for="savedSearch in discoveryGroups"
                            v-else
                            :key="savedSearch.id"
                            class="group cursor-pointer border-b px-3 py-1.5 transition-colors hover:bg-accent/50"
                            @click="onSavedSearchSelected(savedSearch, savedSearches.data.value?.codes)"
                        >
                            <div class="flex w-full items-center justify-between">
                                <span class="block text-sm font-medium" :title="savedSearch.title">
                                    {{ savedSearch.title }}
                                </span>
                                <Button
                                    variant="outline"
                                    size="sm"
                                    class="invisible group-hover:visible"
                                    @click.stop="selectedSavedSearch = savedSearch"
                                >
                                    Edit
                                </Button>
                            </div>
                        </div>
                    </ScrollArea>
                </TabsContent>
            </Tabs>
        </PopoverContent>
    </Popover>
</template>

<script setup>
import { computed, ref } from 'vue'
import { orderBy } from 'lodash'
import { useQuery } from '@tanstack/vue-query'
import { useStore } from 'vuex'
import { Search } from 'lucide-vue-next'
import { useOrganization } from '@/hooks/useOrganization'
import { axios } from '@/utils/axios'
import EditSavedSearchModal from '@/partials/modals/EditSavedSearchModal.vue'

// shadcn-vue components
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs'
import { ScrollArea } from '@/components/ui/scroll-area'

const props = defineProps({
    targetingGroups: {
        type: Array,
        default: () => [],
    },
    platform: {
        type: String,
        required: true,
    },
    onSavedSearchSelected: {
        type: Function,
        required: true,
    },
    codes: {
        type: Array,
        required: true,
    },
})

const store = useStore()
const checkPermission = computed(() => store.getters.checkPermission)
const platformId = computed(() => props.platform)
const selectedSavedSearch = ref(undefined)
const selectedOrganization = useOrganization()

// Compute default tab based on permissions
const defaultTabValue = computed(() => (checkPermission.value('module.campaigns') ? 'targeting' : 'saved'))

const savedSearches = useQuery({
    queryKey: ['target-group-by-organization-platform', selectedOrganization.value.id, platformId],
    queryFn: async () => {
        const search = await axios.get(`/organizations/${selectedOrganization.value.id}/targeting`)
        return {
            platformGroups: search.data.payload.groups.filter((group) => group.platform === platformId.value),
            codes: search.data.payload.codes,
        }
    },
    enabled: Boolean(selectedOrganization.value),
})

// Filter groups by entityType
const campaignTargetingGroups = computed(() => {
    if (!savedSearches.data.value?.platformGroups) return []
    return orderBy(
        savedSearches.data.value.platformGroups.filter((group) => group.entityType === 'campaign'),
        ['title'],
        ['asc'],
    )
})

const discoveryGroups = computed(() => {
    if (!savedSearches.data.value?.platformGroups) return []
    return orderBy(
        savedSearches.data.value.platformGroups.filter((group) => group.entityType !== 'campaign'),
        ['title'],
        ['asc'],
    )
})

const emit = defineEmits(['change'])

const onSelect = (group) => {
    emit('change', group, savedSearches.data.value?.codes)
}
</script>
