<template>
    <div class="mx-auto w-full max-w-9xl px-4 py-8 sm:px-6 lg:px-8">
        <!-- Page header -->
        <div class="mb-6 sm:flex sm:items-center sm:justify-between">
            <!-- Left: Title -->
            <div class="mb-4 sm:mb-0">
                <h1 class="text-2xl font-bold text-slate-800 md:text-3xl">Campaigns</h1>
            </div>

            <!-- Right: Actions  -->
            <div class="grid grid-flow-col justify-start gap-2 sm:auto-cols-max sm:justify-end">
                <!-- Search form -->
                <div class="relative">
                    <label for="action-search" class="sr-only">Search</label>
                    <input
                        v-model="state.searchText"
                        class="form-input pl-9 focus:border-slate-300"
                        type="search"
                        placeholder="Search campaigns..."
                    />
                    <div class="group absolute inset-0 right-auto" aria-label="Search">
                        <svg
                            class="ml-3 mr-2 mt-2.5 h-4 w-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                            />
                            <path
                                d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>

        <!-- Cards -->
        <div v-if="!state.isLoaded" class="grid grid-cols-12 gap-6">
            <SkeletonSupercampaignCard v-for="index in 8" :key="index" />
        </div>

        <div v-else-if="values.supercampaigns && values.supercampaigns.length" class="grid grid-cols-12 gap-6">
            <SupercampaignCard
                v-for="supercampaign in visibleSupercampaigns"
                :key="supercampaign.id"
                :supercampaign="supercampaign"
            />
        </div>

        <div
            v-if="
                state.isLoaded &&
                !visibleSupercampaigns.length &&
                values.supercampaigns !== 0 &&
                state.searchText &&
                state.searchText.length > 0
            "
            class="mt-4 text-center"
        >
            <h1 class="text-lg font-semibold">No Campaigns Found Matching "{{ state.searchText }}"</h1>
        </div>

        <div
            v-if="state.isLoaded && (!values.supercampaigns || !values.supercampaigns.length)"
            class="m-auto mt-16 max-w-2xl"
        >
            <div class="px-4 text-center">
                <div
                    class="mb-4 inline-flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-t from-slate-200 to-slate-100"
                >
                    <svg class="h-6 w-5 fill-current" viewBox="0 0 20 24">
                        <path class="text-slate-500" d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z" />
                        <path class="text-slate-300" d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z" />
                        <path class="text-slate-400" d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z" />
                    </svg>
                </div>
                <h2 class="mb-2 text-2xl font-bold text-slate-800">Create a campaign</h2>
                <div class="mb-6">
                    Looks like you don't have any campaigns yet! Please reach out to your account manager or contact the
                    Streamforge team using the chat bubble in the bottom right to get one started!
                </div>

                <!-- <button
                    class="btn bg-indigo-500 text-white hover:bg-indigo-600"
                    @click.prevent="$store.commit('modal:open', 'create_campaign')"
                >
                    <svg class="h-4 w-4 shrink-0 fill-current opacity-50" viewBox="0 0 16 16">
                        <path
                            d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"
                        />
                    </svg>
                    <span class="ml-2">Create Campaign</span>
                </button> -->
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import SupercampaignCard from '@/partials/cards/SupercampaignCard.vue'
import SkeletonSupercampaignCard from '@/partials/skeletons/SkeletonSupercampaignCard.vue'

export default {
    name: 'Campaigns',

    metaInfo: {
        title: 'Campaigns',
    },

    components: {
        SupercampaignCard,
        SkeletonSupercampaignCard,
    },

    data() {
        return {
            state: {
                isLoaded: false,
                searchText: '',
            },

            values: {
                supercampaigns: null,
                superviewsById: null,
            },
        }
    },

    created() {
        this.getSupercampaigns()
    },

    computed: {
        ...mapGetters(['selfActiveOrganization']),

        visibleSupercampaigns() {
            if (!this.state.isLoaded || !this.values.supercampaigns || !this.values.supercampaigns.length) return []

            if (this.state.searchText) {
                return _.filter(
                    this.values.supercampaigns,
                    (supercampaign) =>
                        supercampaign.title &&
                        supercampaign.title.toLowerCase().includes(this.state.searchText.toLowerCase()),
                )
            }

            return this.values.supercampaigns
        },
    },

    methods: {
        getSupercampaigns() {
            this.$axios
                .get(`/supercampaigns`, {
                    params: {
                        relations: ['games', 'campaigns', 'participants.contracted'],
                    },
                })
                .then(({ data }) => {
                    this.values.supercampaigns = _(data.payload.supercampaigns)
                        .orderBy(['created_at'], ['desc'])
                        .value()

                    this.state.isLoaded = true
                })
                .catch((error) => {
                    if (error.is_cancelled) return

                    this.$errorHandler(error, {
                        title: `Error - Campaigns`,
                        message: `Sorry, there was an issue retrieving your campaigns.`,
                    })
                })
        },
    },
}
</script>
