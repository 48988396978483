import api from './api'

const apicampaign = {
    async getCampaign(axios, supercampaignId, campaignId) {
        return axios
            .get(`/supercampaigns/${supercampaignId}/campaigns/${campaignId}`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async saveCampaign(axios, supercampaignId, campaign) {
        const body = {
            campaign,
        }

        return axios
            .put(`/supercampaigns/${supercampaignId}/campaigns/${campaign.id}`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async createCampaign(axios, supercampaignId, supercampaignOrgId, type) {
        const body = {
            campaign: {
                type,
                parent_id: supercampaignId,
                organization_id: supercampaignOrgId,
            },
        }

        return axios
            .post(`/campaigns`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apicampaign
