<template>
    <table class="w-full">
        <!-- Skeleton table body -->
        <tr v-for="index in 5" :key="index">
            <td class="whitespace-nowrap px-2 py-1 first:pl-5 last:pr-5 md:w-1/3">
                <div class="flex items-center">
                    <div class="mr-2 shrink-0 sm:mr-3">
                        <div class="h-7 w-7 animate-pulse rounded-full bg-slate-200" />
                    </div>

                    <div class="mr-[30px] h-3 w-full animate-pulse rounded bg-slate-200" />
                </div>
            </td>

            <td class="whitespace-nowrap px-2 py-1 first:pl-5 last:pr-5 md:w-1/4">
                <div class="h-3 w-full animate-pulse rounded bg-slate-200" />
            </td>

            <td class="whitespace-nowrap px-2 py-1 first:pl-5 last:pr-5 md:w-1/5">
                <div class="h-3 w-full animate-pulse rounded bg-slate-200" />
            </td>
        </tr>
    </table>
</template>

<script>
export default {
    name: 'SkeletonOperatorTable',
}
</script>
