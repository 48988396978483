<template>
    <div>
        <ModalBasic
            :title="
                modals.task.state.type === 'create'
                    ? `Add Deliverable`
                    : `Edit Deliverable - ${getTaskTypeName(modals.task.values.type)}`
            "
            :modal-open="modals.task.state.isOpen"
            @close-modal="onTaskModalClose"
        >
            <!-- Modal content -->
            <div class="mt-6 px-5 py-6">
                <div class="grid gap-4 text-sm md:grid-cols-12">
                    <div v-if="modals.task.state.type === 'create'" class="col-span-12 md:col-span-12">
                        <label class="mb-2 block text-sm font-medium">Deliverable Type</label>
                        <select v-model="modals.task.values.type" class="form-select w-full">
                            <option
                                v-for="taskTypeId in platformTaskTypes[modals.task.state.platformId]"
                                :key="taskTypeId"
                                :value="taskTypeId"
                            >
                                {{ getFromDictionary(`task.type.${taskTypeId}`, 'label') }}
                            </option>
                        </select>
                    </div>

                    <div v-if="['twitch-stream'].includes(modals.task.values.type)" class="col-span-12 md:col-span-6">
                        <label class="mb-2 block text-sm font-medium">Hours</label>
                        <input
                            id="normal"
                            v-model="modals.task.values.duration_hours"
                            class="form-input w-full"
                            type="number"
                        />
                    </div>

                    <div v-if="['twitch-stream'].includes(modals.task.values.type)" class="col-span-12 md:col-span-6">
                        <label class="mb-2 block text-sm font-medium">Minutes</label>
                        <input
                            id="normal"
                            v-model="modals.task.values.duration_mins"
                            class="form-input w-full"
                            type="number"
                        />
                    </div>

                    <div v-if="['twitch-stream'].includes(modals.task.values.type)" class="col-span-12 md:col-span-6">
                        <label class="mb-2 block text-sm font-medium">Starting at</label>
                        <div class="relative">
                            <flat-pickr
                                v-model="modals.task.values.due_at"
                                class="form-input w-full pl-9 font-medium text-slate-500 hover:text-slate-600 focus:border-slate-300"
                                :config="pickerConfig"
                            />
                            <div class="pointer-events-none absolute inset-0 right-auto flex items-center">
                                <svg class="ml-3 h-4 w-4 fill-current text-slate-500" viewBox="0 0 16 16">
                                    <path
                                        d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <!--<div v-if="['twitch-stream', 'twitch-panel'].includes(modals.task.values.type)" class="mb-4">
                    <label class="block text-sm font-medium mb-2">Ending at</label>
                    <div class="relative">
                        <flat-pickr
                            class="form-input pl-9 text-slate-500 hover:text-slate-600 font-medium focus:border-slate-300 w-full"
                            :config="pickerConfig"
                            v-model="modals.task.values.end_at"
                        />
                        <div class="absolute inset-0 right-auto flex items-center pointer-events-none">
                            <svg class="w-4 h-4 fill-current text-slate-500 ml-3" viewBox="0 0 16 16">
                                <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
                            </svg>
                        </div>
                    </div>
                </div> -->

                    <div v-if="modals.task.values.payout_amount" class="col-span-12 md:col-span-6">
                        <label class="mb-2 block text-sm font-medium">Payout</label>
                        <CurrencyInput
                            v-model="modals.task.values.payout_amount"
                            class="w-full"
                            :options="{ currency: 'USD' }"
                        />
                    </div>

                    <div v-if="['twitch-panel'].includes(modals.task.values.type)" class="col-span-12 md:col-span-6">
                        <label class="mb-2 block text-sm font-medium">Panel url</label>
                        <input
                            id="normal"
                            v-model="modals.task.values.image_url"
                            class="form-input w-full"
                            type="text"
                        />
                    </div>

                    <div v-if="['twitch-panel'].includes(modals.task.values.type)" class="col-span-12 md:col-span-6">
                        <label class="mb-2 block text-sm font-medium">Click url</label>
                        <input
                            id="normal"
                            v-model="modals.task.values.click_url"
                            class="form-input w-full"
                            type="text"
                        />
                    </div>

                    <div v-if="['youtube-video'].includes(modals.task.values.type)" class="col-span-12 md:col-span-6">
                        <label class="mb-2 block text-sm font-medium">Starting at</label>
                        <div class="relative">
                            <flat-pickr
                                v-model="modals.task.values.due_at"
                                class="form-input w-60 pl-9 font-medium text-slate-500 hover:text-slate-600 focus:border-slate-300"
                                :config="pickerConfig"
                            ></flat-pickr>
                            <div class="pointer-events-none absolute inset-0 right-auto flex items-center">
                                <svg class="ml-3 h-4 w-4 fill-current text-slate-500" viewBox="0 0 16 16">
                                    <path
                                        d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div v-if="['retrieve-key'].includes(modals.task.values.type)" class="col-span-12 md:col-span-12">
                        <label class="mb-2 block text-sm font-medium">Key group</label>
                        <div class="relative">
                            <select
                                v-model="modals.task.values.group_id"
                                class="form-select w-full"
                                :disabled="modals.task.values.payload?.key_id"
                            >
                                <option :value="null" selected>
                                    Campaign default ({{ defaultCampaignKeyGroupName(modals.task.values) }})
                                </option>
                                <option v-for="group in supercampaignKeyGroups" :key="group.id" :value="group.id">
                                    {{ group.title }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-span-12 md:col-span-6">
                        <label class="mb-2 block text-sm font-medium">Completed?</label>
                        <input
                            v-model="modals.task.values.is_complete"
                            type="checkbox"
                            class="form-checkbox"
                            @change="onTaskCompleteChange(modals.task.values)"
                        />
                    </div>

                    <div
                        v-if="modals.task.state.validationMessage && modals.task.state.type === 'create'"
                        class="col-span-12 text-red-800 md:col-span-12"
                    >
                        {{ modals.task.state.validationMessage }}
                    </div>

                    <div v-if="modals.task.state.type === 'update'"></div>

                    <div v-if="modals.task.state.type === 'delete'">
                        <p>
                            Are you sure you want to delete <span class="font-semibold">{{ currentList.name }}?</span>
                        </p>
                    </div>
                </div>
            </div>

            <!-- Modal footer -->
            <div class="mt-8 flex border-t border-slate-200 px-5 py-4">
                <div v-if="modals.task.state.type !== 'create'" class="flex flex-wrap justify-start space-x-2">
                    <button
                        class="btn-sm bg-rose-500 text-white hover:bg-rose-600"
                        @click="onTaskModalDelete(modals.task)"
                    >
                        Delete
                    </button>
                </div>
                <div class="ml-auto flex flex-wrap justify-end space-x-2">
                    <button
                        class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                        @click="modals.task.state.isOpen = false"
                    >
                        Cancel
                    </button>

                    <button
                        class="btn-sm text-white"
                        :class="
                            modals.task.state.type === 'delete'
                                ? ['bg-rose-500', 'hover:bg-rose-600']
                                : ['bg-indigo-500', 'hover:bg-indigo-600']
                        "
                        @click="onTaskModalSubmit(modals.task)"
                    >
                        {{ modals.task.state.type === 'create' ? `Create` : `Save` }}
                    </button>
                </div>
            </div>
        </ModalBasic>

        <ModalBasic
            title="Link deliverable"
            :modal-open="modals.linkDeliverable.state.isOpen"
            @close-modal="closeDeliverableTask"
        >
            <template v-if="modals.linkDeliverable.task">
                <div v-if="modals.linkDeliverable.task.type === 'twitch-stream'" class="p-4">
                    <div>
                        <div class="flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                            <div>
                                <span class="font-semibold text-slate-700">Twitch Streams</span>
                            </div>
                        </div>

                        <p class="mt-1 p-1">
                            Below is a list of all streams from the content creator 1 week before and after the
                            campaign. Outliers are included to cover edge cases. Use the filters to narrow down the
                            streams shown.
                        </p>

                        <div class="mb-2 mt-1 p-1">
                            <div class="flex items-center">
                                <div class="form-switch">
                                    <input
                                        id="filter_campaign_window_only"
                                        v-model="modals.linkDeliverable.filters.isCampaignWindow"
                                        type="checkbox"
                                        class="sr-only"
                                    />
                                    <label class="bg-slate-400" for="filter_campaign_window_only">
                                        <span class="bg-white shadow-sm" aria-hidden="true"></span>
                                        <span class="sr-only">Only streams during campaign window</span>
                                    </label>
                                </div>

                                <div
                                    class="ml-2 text-sm"
                                    :class="
                                        modals.linkDeliverable.filters.isCampaignWindow
                                            ? 'text-slate-500'
                                            : 'italic text-slate-400'
                                    "
                                >
                                    {{
                                        modals.linkDeliverable.filters.isCampaignWindow
                                            ? 'Only streams during campaign window'
                                            : 'Streams outside campaign window'
                                    }}
                                </div>
                            </div>

                            <div class="mt-2 flex items-center">
                                <div class="form-switch">
                                    <input
                                        id="filter_campaign_game_only"
                                        v-model="modals.linkDeliverable.filters.isCampaignGame"
                                        type="checkbox"
                                        class="sr-only"
                                    />
                                    <label class="bg-slate-400" for="filter_campaign_game_only">
                                        <span class="bg-white shadow-sm" aria-hidden="true"></span>
                                        <span class="sr-only">Only streams of campaign game</span>
                                    </label>
                                </div>

                                <div
                                    class="ml-2 text-sm"
                                    :class="
                                        modals.linkDeliverable.filters.isCampaignGame
                                            ? 'text-slate-500'
                                            : 'italic text-slate-400'
                                    "
                                >
                                    {{
                                        modals.linkDeliverable.filters.isCampaignGame
                                            ? 'Only streams of campaign game'
                                            : 'Streams of any game'
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                            <div>
                                <span class="font-semibold text-slate-700">Matched Streams</span>
                            </div>
                        </div>

                        <div v-if="modals.linkDeliverable.state.loading === true">
                            <p class="mt-1 p-1">Loading...</p>
                        </div>

                        <div
                            v-if="
                                (!filteredDeliverableOptions || filteredDeliverableOptions.length === 0) &&
                                modals.linkDeliverable.state.loading === false
                            "
                        >
                            <p class="mt-1 p-2">No matching streams found</p>
                        </div>

                        <div
                            v-if="
                                filteredDeliverableOptions &&
                                filteredDeliverableOptions.length &&
                                modals.linkDeliverable.state.loading === false
                            "
                        >
                            <div v-for="chapter in filteredDeliverableOptions" :key="chapter.index" class="mb-3">
                                <div class="ml-2 mr-2 mt-3 border p-3">
                                    <div class="flex flex-col flex-nowrap text-xs">
                                        <div class="mb-1 flex">
                                            <p class="w-24">Started at</p>
                                            <p class="w-80">{{ $filters.formatDateAndTime(chapter.started_at) }}</p>
                                        </div>
                                        <div class="mb-1 flex">
                                            <p class="w-24">Ended at</p>
                                            <p class="w-80">{{ $filters.formatDateAndTime(chapter.ended_at) }}</p>
                                        </div>
                                        <div class="mb-1 flex">
                                            <p class="w-24">Duration</p>
                                            <p class="w-80">
                                                {{ $filters.humanizeDuration(chapter.duration_seconds * 1000, true) }}
                                            </p>
                                        </div>
                                        <div class="mb-1 flex">
                                            <p class="w-24">Order in stream</p>
                                            <!-- eslint-disable-next-line vue/no-deprecated-filter -->
                                            <p class="w-80">{{ (chapter.index + 1) | $numeral('0o') }}</p>
                                        </div>
                                        <div class="mb-1 flex">
                                            <p class="w-24">Game</p>
                                            <p class="w-80">{{ chapter.game_name }}</p>
                                        </div>
                                        <div class="mb-1 flex">
                                            <p class="w-24">Title</p>
                                            <p class="w-80">{{ chapter.title }}</p>
                                        </div>
                                    </div>

                                    <div class="d-flex mt-2 text-xs">
                                        <a
                                            v-if="chapter.video"
                                            class="mr-3 cursor-pointer text-indigo-500"
                                            target="_BLANK"
                                            :href="getVodUrl(modals.linkDeliverable.task, chapter.video, chapter)"
                                        >
                                            View VOD
                                        </a>

                                        <a
                                            v-if="!isDeliverableSelected(modals.linkDeliverable.task, chapter._id)"
                                            class="mr-3 cursor-pointer text-indigo-500"
                                            @click="onSelectDeliverable(modals.linkDeliverable.task, chapter, 'add')"
                                        >
                                            Select
                                        </a>

                                        <a
                                            v-if="isDeliverableSelected(modals.linkDeliverable.task, chapter._id)"
                                            class="cursor-pointer text-indigo-500"
                                            @click="onSelectDeliverable(modals.linkDeliverable.task, chapter, 'remove')"
                                        >
                                            Remove
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                            <div>
                                <span class="font-semibold text-slate-700">Stream Backfill</span>
                            </div>
                        </div>

                        <p class="mt-1 p-1">
                            In the rare event that we missed a stream you can paste a SullyGnome link below to import it
                            into the ETL system.
                        </p>

                        <div class="d-flex mb-3 ml-2 mr-2 mt-3">
                            <label class="sr-only">Backfill</label>
                            <input
                                v-model="modals.linkDeliverable.state.backfillURL"
                                class="form-input mb-3 w-full focus:border-slate-300"
                                type="search"
                                placeholder="https://sullygnome.com/channel/jorbs/365/stream/40005508091"
                                @keyup.enter="backfillStream"
                            />
                            <a class="ml-1 cursor-pointer text-xs text-indigo-500" @click="backfillStream"> Import </a>
                        </div>
                    </div>
                </div>

                <div v-if="modals.linkDeliverable.task.type === 'youtube-video'" class="p-4">
                    <div>
                        <div class="flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                            <div>
                                <span class="font-semibold text-slate-700">YouTube Videos</span>
                            </div>
                        </div>

                        <p class="mt-1 p-1">
                            Below is a list of all videos from the content creator 1 week before and after the campaign.
                            Outliers are included to cover edge cases. Use the filters to narrow down the videos shown.
                        </p>

                        <div class="mb-2 mt-1 p-1">
                            <div class="flex items-center">
                                <div class="form-switch">
                                    <input
                                        id="filter_campaign_window_only"
                                        v-model="modals.linkDeliverable.filters.isCampaignWindow"
                                        type="checkbox"
                                        class="sr-only"
                                    />
                                    <label class="bg-slate-400" for="filter_campaign_window_only">
                                        <span class="bg-white shadow-sm" aria-hidden="true"></span>
                                        <span class="sr-only">Only videos during campaign window</span>
                                    </label>
                                </div>

                                <div
                                    class="ml-2 text-sm"
                                    :class="
                                        modals.linkDeliverable.filters.isCampaignWindow
                                            ? 'text-slate-500'
                                            : 'italic text-slate-400'
                                    "
                                >
                                    {{
                                        modals.linkDeliverable.filters.isCampaignWindow
                                            ? 'Only videos during campaign window'
                                            : 'Videos outside campaign window'
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                            <div>
                                <span class="font-semibold text-slate-700">Matched Videos</span>
                            </div>
                        </div>

                        <div v-if="modals.linkDeliverable.state.loading === true">
                            <p class="mt-1 p-1">Loading...</p>
                        </div>

                        <div
                            v-if="
                                (!filteredDeliverableOptions || filteredDeliverableOptions.length === 0) &&
                                modals.linkDeliverable.state.loading === false
                            "
                        >
                            <p class="mt-1 p-2">No matching videos found</p>
                        </div>

                        <div
                            v-if="
                                filteredDeliverableOptions &&
                                filteredDeliverableOptions.length &&
                                modals.linkDeliverable.state.loading === false
                            "
                        >
                            <div v-for="video in filteredDeliverableOptions" :key="video.id" class="mb-3">
                                <div class="ml-2 mr-2 mt-3 border p-3">
                                    <div class="flex flex-col flex-nowrap text-xs">
                                        <div class="mb-4 flex">
                                            <a
                                                :href="`https://youtube.com/watch?v=${video.id}`"
                                                target="_BLANK"
                                                class="ml-auto mr-auto w-9/12"
                                            >
                                                <img
                                                    :src="video.snippet.thumbnails.medium.url"
                                                    class="w-full rounded"
                                                />
                                            </a>
                                        </div>
                                        <div class="mb-1 flex">
                                            <p class="w-24">Published at</p>
                                            <p class="w-80">
                                                {{
                                                    $moment.utc(video.snippet.publishedAt).format('MMM Do, YYYY h:mm A')
                                                }}
                                            </p>
                                        </div>
                                        <div class="mb-1 flex">
                                            <p class="w-24">Duration</p>
                                            <p class="w-80">
                                                {{
                                                    $filters.humanizeDuration(
                                                        $moment
                                                            .duration(video.contentDetails.duration, $moment.ISO_8601)
                                                            .asMilliseconds(),
                                                        true,
                                                    )
                                                }}
                                            </p>
                                        </div>
                                        <div class="mb-1 flex">
                                            <p class="w-24">Video title</p>
                                            <p class="w-80">{{ video.snippet.title }}</p>
                                        </div>
                                    </div>

                                    <div class="d-flex mt-3 text-xs">
                                        <a
                                            v-if="video.id"
                                            class="mr-3 cursor-pointer text-indigo-500"
                                            :href="`https://youtube.com/watch?v=${video.id}`"
                                            target="_BLANK"
                                        >
                                            View video
                                        </a>

                                        <a
                                            v-if="!isDeliverableSelected(modals.linkDeliverable.task, video._id)"
                                            class="mr-3 cursor-pointer text-indigo-500"
                                            @click="onSelectDeliverable(modals.linkDeliverable.task, video, 'add')"
                                        >
                                            Select
                                        </a>

                                        <a
                                            v-if="isDeliverableSelected(modals.linkDeliverable.task, video._id)"
                                            class="cursor-pointer text-indigo-500"
                                            @click="onSelectDeliverable(modals.linkDeliverable.task, video, 'remove')"
                                        >
                                            Remove
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </ModalBasic>

        <div class="col-span-12 md:col-span-8">
            <div v-if="!loading">
                <template v-for="platformId in platforms" :key="platformId">
                    <div v-if="platformTasks(platformId).length > 0 || !hideEmptyPlatform">
                        <div class="mb-5 flex space-x-2">
                            <div class="flex grow rounded bg-slate-100 px-4 py-1.5 text-sm font-semibold">
                                <font-awesome-icon
                                    :icon="['fab', platformId]"
                                    class="my-auto h-3.5 w-3.5"
                                    :class="`text-${platformId}`"
                                />
                                <span class="ml-2">{{ $voca.capitalize(platformId) }}</span>
                            </div>
                            <div
                                v-if="allowAdd"
                                class="flex w-12 cursor-pointer rounded bg-indigo-500 text-sm font-semibold text-white hover:bg-indigo-600"
                                @click="openCreateTaskModel(platformId)"
                            >
                                <font-awesome-icon
                                    :icon="['fas', 'plus']"
                                    class="m-auto h-3.5 w-3.5 transition duration-200"
                                />
                            </div>
                        </div>

                        <div class="grid gap-4">
                            <div
                                v-for="task in platformTasks(platformId)"
                                :key="task.id"
                                class="group rounded border border-slate-200 bg-white p-4"
                            >
                                <div class="relative flex grow">
                                    <div class="grow pt-1">
                                        <div class="text mb-2 truncate">
                                            <span class="font-medium capitalize text-slate-800">{{
                                                getTaskTypeName(task.type)
                                            }}</span>
                                        </div>

                                        <div v-if="platformId == 'twitch'" class="flex flex-col flex-nowrap text-xs">
                                            <div v-if="task.due_at" class="mb-1 flex">
                                                <p class="w-24">Start at</p>
                                                <p class="w-80">
                                                    {{ $moment.utc(task.due_at).local().format('MMM Do, YYYY h:mm A') }}
                                                </p>
                                            </div>
                                            <div v-if="task.payload && task.payload.duration_seconds" class="mb-1 flex">
                                                <p class="w-24">Duration</p>
                                                <p class="w-80">
                                                    {{
                                                        $filters.humanizeDuration(task.payload.duration_seconds * 1000)
                                                    }}
                                                </p>
                                            </div>
                                            <div v-if="task.payout_amount" class="mb-1 flex">
                                                <p class="w-24">Payout</p>
                                                <p class="w-80">${{ $numeral(task.payout_amount).format('0,0.00') }}</p>
                                            </div>
                                            <div v-if="task.image_url"><a :href="task.image_url">Panel image</a></div>
                                            <div v-if="task.click_url"><a :href="task.click_url">Click url</a></div>
                                            <div class="mb-1 flex">
                                                <p class="w-24">Status</p>
                                                <p v-if="task.completed_at === null" class="w-80">Incomplete</p>
                                                <p v-else class="w-80">Completed</p>
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                task.type === 'twitch-stream' &&
                                                task.payload &&
                                                task.payload.deliverables &&
                                                task.payload.deliverables.length > 0
                                            "
                                            class="mt-2 p-2"
                                        >
                                            <div
                                                v-for="deliverable in task.payload.deliverables"
                                                :key="deliverable.id"
                                                class="mb-2 flex flex-col flex-nowrap rounded border bg-slate-50 p-2 text-xs"
                                            >
                                                <div v-if="deliverable.started_at" class="mb-1 flex">
                                                    <p class="w-24">Started at</p>
                                                    <p class="w-80">
                                                        {{
                                                            $moment
                                                                .utc(deliverable.started_at)
                                                                .format('MMM Do, YYYY h:mm A')
                                                        }}
                                                    </p>
                                                </div>
                                                <div v-if="deliverable.ended_at" class="mb-1 flex">
                                                    <p class="w-24">Ended at</p>
                                                    <p class="w-80">
                                                        {{
                                                            $moment
                                                                .utc(deliverable.ended_at)
                                                                .format('MMM Do, YYYY h:mm A')
                                                        }}
                                                    </p>
                                                </div>
                                                <div v-if="deliverable.duration_seconds" class="mb-1 flex">
                                                    <p class="w-24">Duration</p>
                                                    <p class="w-80">
                                                        {{
                                                            $filters.humanizeDuration(
                                                                deliverable.duration_seconds * 1000,
                                                                true,
                                                            )
                                                        }}
                                                    </p>
                                                </div>
                                                <div v-if="deliverable.index" class="mb-1 flex">
                                                    <p class="w-80">Order in stream</p>
                                                    <p>{{ $filters.numeral(deliverable.index + 1).format('0,0') }}</p>
                                                </div>
                                                <div v-if="deliverable.game_name" class="mb-1 flex">
                                                    <p class="w-24">Game</p>
                                                    <p class="w-80">{{ deliverable.game_name }}</p>
                                                </div>
                                                <div v-if="deliverable.title" class="mb-1 flex">
                                                    <p class="w-24">Title</p>
                                                    <p class="w-80">{{ deliverable.title }}</p>
                                                </div>

                                                <div class="flex">
                                                    <a
                                                        v-if="deliverable.video"
                                                        class="mr-5 cursor-pointer text-indigo-500"
                                                        target="_blank"
                                                        :href="getVodUrl(task, deliverable.video, deliverable)"
                                                    >
                                                        View VOD
                                                    </a>
                                                    <a
                                                        class="cursor-pointer text-indigo-500"
                                                        @click="onSelectDeliverable(task, deliverable, 'remove')"
                                                    >
                                                        Remove
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="platformId == 'youtube'" class="flex flex-col flex-nowrap text-xs">
                                            <div v-if="task.due_at" class="mb-1 flex">
                                                <p class="w-24">Publish at</p>
                                                <p class="w-80">{{ $filters.formatDateAndTime(task.due_at) }}</p>
                                            </div>
                                            <div class="mb-1 flex">
                                                <p class="w-24">Status</p>
                                                <p v-if="task.completed_at === null" class="w-80">Incomplete</p>
                                                <p v-else class="w-80">Completed</p>
                                            </div>
                                        </div>

                                        <div v-if="platformId == 'youtube'" class="flex flex-col flex-nowrap text-xs">
                                            <div v-if="task.due_at" class="mb-1 flex">
                                                <p class="w-24">Publish at</p>
                                                <p class="w-80">{{ $filters.formatDateAndTime(task.due_at) }}</p>
                                            </div>
                                            <div class="mb-1 flex">
                                                <p class="w-24">Status</p>
                                                <p v-if="task.completed_at === null" class="w-80">Incomplete</p>
                                                <p v-else class="w-80">Completed</p>
                                            </div>
                                        </div>

                                        <div
                                            v-if="task.type === 'retrieve-key'"
                                            class="flex flex-col flex-nowrap text-xs"
                                        >
                                            <div class="mb-1 flex">
                                                <p class="w-24">Key group</p>
                                                <p v-if="!task.payload?.group_id" class="w-80">
                                                    Campaign default ({{ defaultCampaignKeyGroupName(task) }})
                                                </p>
                                                <p v-if="task.payload?.group_id" class="w-80">
                                                    {{ getKeyGroupTitle(task.payload?.group_id) }}
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            v-if="task.type === 'retrieve-key'"
                                            class="flex flex-col flex-nowrap text-xs"
                                        >
                                            <div class="mb-1 flex">
                                                <p class="w-24">Key retrieved</p>
                                                <p v-if="!task.payload?.key_id" class="w-80">No</p>
                                                <p v-if="task.payload?.key_id" class="w-80">Yes</p>
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                (task.type === 'twitch-stream' || task.type === 'youtube-video') &&
                                                (!task.payload ||
                                                    !task.payload.deliverables ||
                                                    task.payload.deliverables.length == 0)
                                            "
                                            class="mt-2"
                                        >
                                            <a
                                                class="cursor-pointer pt-3 text-xs text-indigo-500"
                                                @click="openLinkDeliverable(task)"
                                            >
                                                Link deliverable
                                            </a>
                                        </div>
                                    </div>

                                    <font-awesome-icon
                                        v-if="allowEdit"
                                        :icon="['fas', 'pen-to-square']"
                                        class="h-4 w-4 cursor-pointer text-slate-500 transition duration-200 hover:text-slate-600"
                                        @click="openTaskModal('edit', task)"
                                    />
                                </div>
                                <template v-if="task.bids">
                                    <div v-for="bid in task.bids" :key="bid.id" class="mt-2.5 flex gap-2">
                                        <div class="flex grow gap-8 rounded bg-slate-100 p-3">
                                            <div>
                                                <div class="text-xs font-semibold">Payout Amount</div>
                                                <span class="text-md"
                                                    >${{ $numeral(bid.payout_amount).format('0,0.00') }}</span
                                                >
                                            </div>
                                            <div>
                                                <div class="text-xs font-semibold">Source</div>
                                                <span class="text-md">Creator Bid</span>
                                            </div>
                                            <div>
                                                <div class="text-xs font-semibold">Submitted At</div>
                                                <span class="text-md">{{ $moment(bid.created_at).calendar() }}</span>
                                            </div>
                                        </div>
                                        <!-- <button
                                        class="
                                            text-sm font-semibold cursor-pointer flex rounded bg-emerald-500
                                            hover:bg-emerald-600 text-white p-3
                                        "
                                    >
                                        <span class="m-auto">Accept Rate</span>

                                    </button> -->
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <template v-if="showEmptyWarning && tasks.length === 0 && !loading">
                <div class="rounded-sm border border-rose-200 bg-rose-100 px-4 py-2 text-sm text-rose-600">
                    <div class="flex w-full items-start justify-between">
                        <div class="flex">
                            <svg class="mr-3 mt-[3px] h-4 w-4 shrink-0 fill-current opacity-80" viewBox="0 0 16 16">
                                <path
                                    d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
                                ></path>
                            </svg>
                            <div>No default deliverables are set for the campaign.</div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import 'moment-timezone'
import { mapGetters } from 'vuex'

import flatPickr from 'vue-flatpickr-component'
import ModalBasic from '@/components/ModalBasic.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'

import campaignTaskAPI from '@/utils/api/api.campaign.tasks'
import campaignSettingsAPI from '@/utils/api/api.campaign.settings'
import campaignKeysAPI from '@/utils/api/api.campaign.keys'

export default {
    name: 'Tasks',

    components: {
        ModalBasic,
        flatPickr,
        CurrencyInput,
    },

    props: {
        platforms: Array,
        supercampaignId: String,
        campaign: Object,
        participantId: String,
        allowAdd: {
            type: Boolean,
            default: true,
        },
        allowEdit: {
            type: Boolean,
            default: true,
        },
        hideEmptyPlatform: {
            type: Boolean,
            default: false,
        },
        showEmptyWarning: {
            type: Boolean,
            default: false,
        },
        isBidsShown: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            tasks: [],

            taskTypes: [
                { id: 'twitch-stream', name: 'Sponsored Stream' },
                { id: 'twitch-panel', name: 'Panel w/ Tracking Link' },
                { id: 'youtube-video', name: 'Sponsored YouTube Video' },
                { id: 'retrieve-key', name: 'Retrieve game key' },
                { id: 'twitter-post', name: 'Twitter Post' },
                { id: 'tiktok-video', name: 'Tiktok Video' },
                { id: 'instagram-post', name: 'Instagram Post' },
            ],

            platformTaskTypes: {
                twitch: ['twitch-stream', 'twitch-panel', 'retrieve-key'],
                youtube: ['youtube-video', 'retrieve-key'],
                tiktok: ['tiktok-video', 'retrieve-key'],
                twitter: ['twitter-post', 'retrieve-key'],
                instagram: ['instagram-post', 'retrieve-key'],
            },

            platformDefaultTask: {
                twitch: { type: 'twitch-stream' },
                youtube: { type: 'youtube-video' },
                tiktok: { type: 'tiktok-video' },
                twitter: { type: 'twitter-post' },
                instagram: { type: 'instagram-post' },
            },

            modals: {
                task: {
                    platformId: 'twitch',
                    values: {
                        due_at: null,
                        end_at: null,
                        completed_at: null,
                        group_id: null,
                    },
                    default: {
                        type: 'twitch-stream',
                        payload: {
                            duration_seconds: null,
                        },
                        group_id: null,
                    },
                    state: {
                        isOpen: false,
                        type: 'create',
                        validationMessage: '',
                    },
                },
                linkDeliverable: {
                    task: null,
                    state: {
                        isOpen: false,
                        backfillURL: '',
                        loading: true,
                    },
                    filters: {
                        isCampaignWindow: true,
                        isCampaignGame: true,
                    },
                },
            },

            pickerConfig: {
                enableTime: true,
                dateFormat: 'Z',
                altFormat: 'F j, Y h:i K',
                altInput: true,
                static: true,
            },

            prospectSettings: null,
            supercampaignKeyGroups: [],

            loading: true,
        }
    },

    watch: {
        participantId() {
            this.reset()
        },
        campaign() {
            this.reset()
        },
    },

    created() {
        this.reset()
    },

    computed: {
        ...mapGetters(['getFromDictionary']),
        filteredDeliverableOptions() {
            if (!this.modals.linkDeliverable.task) return []

            if (this.modals.linkDeliverable.task.type === 'twitch-stream') {
                return _.filter(_.get(this.modals.linkDeliverable.task, 'deliverable.values.chapters'), (chapter) => {
                    if (this.modals.linkDeliverable.filters.isCampaignWindow === true) {
                        const isStartWithinWindow = moment
                            .utc(chapter.started_at)
                            .isBetween(moment.utc(this.campaign.started_at), moment.utc(this.campaign.ended_at))
                        const isEndWithinWindow = moment
                            .utc(chapter.ended_at)
                            .isBetween(moment.utc(this.campaign.started_at), moment.utc(this.campaign.ended_at))
                        if (!isStartWithinWindow && !isEndWithinWindow) return false
                    }

                    if (this.modals.linkDeliverable.filters.isCampaignGame === true) {
                        if (!chapter.isCampaignGame) return false
                    }

                    return true
                })
            }

            if (this.modals.linkDeliverable.task.type === 'youtube-video') {
                return _.filter(_.get(this.modals.linkDeliverable.task, 'deliverable.values.videos'), (video) => {
                    if (this.modals.linkDeliverable.filters.isCampaignWindow === true) {
                        if (
                            !moment
                                .utc(video.snippet.publishedAt)
                                .isBetween(moment.utc(this.campaign.started_at), moment.utc(this.campaign.ended_at))
                        ) {
                            return false
                        }
                    }

                    return true
                })
            }

            return undefined
        },
    },

    methods: {
        async reset() {
            this.loading = true
            this.tasks = []

            if (this.participantId !== null) {
                this.tasks = await this.getParticipantTasks()
                await this.getKeyGroupSettings()
            } else {
                this.tasks = await this.getCampaignDefaultTasks()
            }
        },

        platformTasks(platformId) {
            let matchedPlatformTasks = []
            const tasktypes = this.platformTaskTypes[platformId]

            const taskedGroupedByType = _(this.tasks)
                .groupBy((x) => x.type)
                .value()

            _.forEach(tasktypes, (taskType) => {
                if (taskedGroupedByType[taskType]) {
                    matchedPlatformTasks = _.concat(matchedPlatformTasks, taskedGroupedByType[taskType])
                }
            })

            return _.sortBy(matchedPlatformTasks, (task) => task.due_at)
        },

        async insertTask(task, participants) {
            const response = await campaignTaskAPI.insertTask(
                this.$axios,
                this.supercampaignId,
                this.campaign.id,
                task,
                participants,
            )
            if (response.success) {
                this.tasks = _.concat(this.tasks, response.value)
                return true
            }
            return false
        },

        async updateTask(task) {
            const resp = await campaignTaskAPI.updateTask(this.$axios, this.supercampaignId, this.campaign.id, task)
            if (resp.success) {
                const existingTask = _.find(this.tasks, (t) => t.id === resp.value.task.id)
                _.assign(existingTask, resp.value.task)
            }
        },

        async deleteTask(task) {
            const response = await campaignTaskAPI.deleteTask(
                this.$axios,
                this.supercampaignId,
                this.campaign.id,
                task.id,
            )
            if (response.success) {
                _.remove(this.tasks, { id: task.id })
            }
        },

        async getCampaignDefaultTasks() {
            const response = await campaignTaskAPI.getCampaignDefaultTasks(
                this.$axios,
                this.supercampaignId,
                this.campaign.id,
            )
            this.loading = false

            if (response.success) {
                return response.value
            }

            return undefined
        },

        async getParticipantTasks() {
            const response = await campaignTaskAPI.getCampaignParticipantTasks(
                this.$axios,
                this.supercampaignId,
                this.campaign.id,
                this.participantId,
                [], // ['bids'],
            )
            this.loading = false

            if (response.success) {
                return response.value
            }

            return undefined
        },

        async getKeyGroupSettings() {
            this.supercampaignKeyGroups = []
            this.prospectSettings = null

            const keyGroupsResponse = await campaignKeysAPI.getSuperCampaignKeyGroups(this.$axios, this.supercampaignId)

            if (keyGroupsResponse.success) {
                this.supercampaignKeyGroups = keyGroupsResponse.value.groups
            }

            const settingsResponse = await campaignSettingsAPI.getCampaignStageSettings(
                this.$axios,
                this.campaign.id,
                'prospect',
            )

            if (settingsResponse.success) {
                this.prospectSettings = settingsResponse.value.stage_settings.prospect
            }
        },

        getKeyGroupTitle(groupId) {
            const matchedGroup = _.find(this.supercampaignKeyGroups, (group) => group.id === groupId)

            if (matchedGroup) {
                return matchedGroup.title
            }

            return ''
        },

        defaultCampaignKeyGroupName(task) {
            let name = 'Not default assigned'

            if (this.prospectSettings) {
                let defaultGroupId = null

                if (task.payload?.group_source === 'primary' && this.prospectSettings.primary_retrieve_key_group_id) {
                    defaultGroupId = this.prospectSettings.primary_retrieve_key_group_id
                } else if (
                    task.payload?.group_source === 'secondary' &&
                    this.prospectSettings.secondary_retrieve_key_group_id
                ) {
                    defaultGroupId = this.prospectSettings.secondary_retrieve_key_group_id
                } else if (
                    task.payload?.group_source === 'tertiary' &&
                    this.prospectSettings.tertiary_retrieve_key_group_id
                ) {
                    defaultGroupId = this.prospectSettings.tertiary_retrieve_key_group_id
                }

                const matchedGroup = _.find(this.supercampaignKeyGroups, (group) => group.id === defaultGroupId)

                if (matchedGroup) {
                    name = matchedGroup.title
                }
            }

            return name
        },

        openCreateTaskModel(platformId) {
            const taskDefault = this.platformDefaultTask[platformId]
            this.openTaskModal('create', taskDefault, platformId)
        },

        openTaskModal(type, task, platformId) {
            this.modals.task.state.isOpen = true
            this.modals.task.state.type = type
            this.modals.task.state.platformId = platformId
            this.modals.task.state.validationMessage = ''

            if (type === 'create') {
                this.modals.task.values = _.clone(this.modals.task.default)

                this.modals.task.values.due_at = this.campaign.started_at
                this.modals.task.values.end_at = this.campaign.ended_at
                this.modals.task.values.platform_id = platformId
                this.modals.task.values.is_complete = false

                if (task) {
                    this.modals.task.values.type = task.type
                }
            }

            if (type === 'edit') {
                this.modals.task.values = _.clone(task)

                if (this.modals.task.values.completed_at) {
                    this.modals.task.values.is_complete = true
                } else {
                    this.modals.task.values.is_complete = false
                }
                this.modals.task.values.group_id = null

                if (this.modals.task.values.payload) {
                    if (this.modals.task.values.payload.duration_seconds) {
                        const totalMins = Math.floor(this.modals.task.values.payload.duration_seconds / 60)
                        const totalHours = Math.floor(totalMins / 60)
                        this.modals.task.values.duration_hours = totalHours
                        this.modals.task.values.duration_mins = totalMins - totalHours * 60
                    }

                    if (this.modals.task.values.payload.group_id) {
                        this.modals.task.values.group_id = this.modals.task.values.payload.group_id
                    }
                }
            }
        },

        onTaskModalClose() {
            this.modals.task.state.isOpen = false
            this.modals.task.state.validationMessage = ''
        },

        getTaskTypeName(taskTypeId) {
            const matchedTaskTypes = _.find(this.taskTypes, (taskType) => taskType.id === taskTypeId)
            return matchedTaskTypes ? matchedTaskTypes.name : ''
        },

        async onTaskModalSubmit(task) {
            let durationSeconds = null

            if (task.values.duration_hours || task.values.duration_mins) {
                durationSeconds = task.values.duration_hours ? task.values.duration_hours * 60 * 60 : 0
                durationSeconds += task.values.duration_mins ? task.values.duration_mins * 60 : 0
            }

            if (task.state.type === 'create') {
                let participants = null

                const newTask = {
                    campaign_id: this.campaignId,
                    type: task.values.type,
                    due_at: task.values.due_at,
                    is_default: !this.participantId,
                    image_url: task.values.image_url,
                    click_url: task.values.click_url,
                    platform_id: task.values.platform_id ? task.values.platform_id : 'any',
                    payload: {},
                }

                if (task.values.type === 'retrieve-key' && task.values.group_id) {
                    newTask.payload.group_id = task.values.group_id
                }

                if (task.values.type === 'twitch-stream') {
                    newTask.payload.duration_seconds = durationSeconds
                    newTask.payload.end_at = durationSeconds
                        ? moment.utc(task.values.due_at).add(durationSeconds, 'seconds').format()
                        : task.values.end_at
                }

                if (!newTask.is_default) {
                    participants = []
                    participants.push(this.participantId)
                }

                await this.insertTask(newTask, participants)
            } else if (task.state.type === 'edit') {
                if (task.values.type === 'twitch-stream') {
                    // eslint-disable-next-line no-param-reassign
                    task.values.payload.duration_seconds = durationSeconds
                    // eslint-disable-next-line no-param-reassign
                    task.values.end_at = durationSeconds
                        ? moment.utc(task.values.due_at).add(durationSeconds, 'seconds').format()
                        : task.values.end_at
                }
                if (task.values.type === 'retrieve-key') {
                    if (!task.values.payload) {
                        task.values.payload = {}
                    }
                    task.values.payload.group_id = task.values.group_id
                }
                await this.updateTask(task.values)
            }

            this.modals.task.state.isOpen = false
        },

        async onTaskModalDelete(task) {
            await this.deleteTask(task.values)
            this.modals.task.state.isOpen = false
        },

        onTaskCompleteChange(taskValues) {
            if (taskValues.is_complete) {
                if (!taskValues.completed_at) {
                    // eslint-disable-next-line no-param-reassign
                    taskValues.completed_at = moment.utc()
                }
            } else {
                // eslint-disable-next-line no-param-reassign
                taskValues.completed_at = null
            }
        },

        getVodUrl(task, video, chapter) {
            let vodUrl = `https://www.twitch.tv/videos/${video.id}`

            let secondsOffset = 0

            // If the chapter is not the first in the stream, we need to determine it's offset by summing previous chapters
            if (chapter.index > 0) {
                const otherChaptersFromStream = _.filter(
                    _.get(task, 'deliverable.values.chapters'),
                    (item) => item.stream_id === chapter.stream_id,
                )
                const otherChaptersBeforeCurrent = _.slice(otherChaptersFromStream, 0, chapter.index)
                secondsOffset = _.sumBy(otherChaptersBeforeCurrent, 'duration_seconds')
            }

            if (secondsOffset > 0) {
                vodUrl = `${vodUrl}?t=${secondsOffset}`
            }

            return vodUrl
        },

        onSelectDeliverable(task, chapter, action = 'add') {
            let { deliverables } = task.payload
            if (!deliverables) deliverables = []

            if (action === 'add') {
                deliverables.push(chapter)
            } else if (action === 'remove') {
                deliverables = _.filter(deliverables, (item) => item.id !== chapter.id)
            }

            const updateTask = {
                id: task.id,
                approved_at: moment.utc().format(),
                payload: {
                    deliverables,
                },
            }

            this.updateTask(updateTask)
            this.closeDeliverableTask()
        },

        openLinkDeliverable(task) {
            this.modals.linkDeliverable.state.isOpen = true
            this.modals.linkDeliverable.task = task
            this.getParticipantDeliverables()
        },

        closeDeliverableTask() {
            this.modals.linkDeliverable.state.isOpen = false
            this.modals.linkDeliverable.task = null
        },

        async getParticipantDeliverables() {
            this.modals.linkDeliverable.state.loading = true
            const taskDeliverable = await campaignTaskAPI.getTaskDeliverables(
                this.$axios,
                this.$route.params.supercampaign_id,
                this.$route.params.campaign_id,
                this.modals.linkDeliverable.task.id,
            )
            this.modals.linkDeliverable.task.deliverable = taskDeliverable.value
            this.modals.linkDeliverable.state.loading = false
        },

        isDeliverableSelected(task, chapterId) {
            if (!task.payload.deliverables) return false
            // eslint-disable-next-line no-underscore-dangle
            return !!_.find(task.payload.deliverables, (item) => item._id === chapterId)
        },

        backfillStream() {
            if (!this.modals.linkDeliverable.state.backfillURL) {
                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Stream Backfill',
                    text: `Please provide a valid sullygnome link.`,
                    duration: 10000,
                    speed: 1000,
                })

                return
            }

            this.$axios
                .post(`/backfill/stream/sullygnome`, {
                    sullygnome_url: this.modals.linkDeliverable.state.backfillURL,
                })
                .then(() => {
                    this.reset()
                })
                .catch((error) => {
                    if (error.is_cancelled) return
                    const responseErrorMessage = _.get(error.response.data.message)
                    let message = `Error backfilling that stream from Sullygnome.`
                    if (responseErrorMessage) message = responseErrorMessage

                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Stream Backfill',
                        text: message,
                        duration: 10000,
                        speed: 1000,
                    })
                })
        },
    },
}
</script>

<style scoped>
::v-deep(.flatpickr-calendar) {
    position: absolute !important;
    top: -210px !important;
}
</style>
