<template>
    <div class="relative">
        <button
            ref="trigger"
            class="flex w-full rounded border border-slate-300 bg-white px-3 py-4 text-left hover:border-slate-400"
            aria-label="Lists"
            aria-haspopup="true"
            :aria-expanded="dropdownOpen"
            @click.prevent="dropdownOpen = !dropdownOpen"
        >
            <div class="flex">
                <font-awesome-icon
                    :icon="selectedSource?.icon"
                    class="my-auto mr-3 h-4 w-4 shrink-0"
                    :class="selectedSource?.iconClass"
                />
                <div class="flex flex-col">
                    <div class="text-sm font-semibold leading-none">{{ selectedSource?.name }}</div>
                    <div class="text-xs leading-none mt-1 text-slate-600">
                        <span v-if="counts?.tracked[selectedSource?.id]">
                            Platform ⸱
                            {{ $filters.formatWholeNumberWithUnit(counts?.tracked[selectedSource?.id]) }} creators
                        </span>
                        <span v-else>Platform</span>
                    </div>
                </div>
            </div>

            <font-awesome-icon :icon="['fas', 'chevron-down']" class="my-auto ml-auto mr-1 h-3.5 w-3.5 shrink-0" />
        </button>

        <transition
            enter-active-class="transition ease-out duration-100 transform"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="dropdownOpen"
                class="no-scrollbar absolute left-0 top-full z-50 mt-1 max-h-96 w-full overflow-y-auto rounded border border-slate-200 bg-white shadow-xl"
            >
                <div ref="dropdown">
                    <div
                        v-for="source in visiblePlatforms"
                        :key="source.id"
                        class="text-md group flex cursor-pointer border-b border-slate-200 px-3 py-3.5 hover:bg-slate-50"
                        @click="onSelect('platform', source)"
                    >
                        <div class="flex">
                            <font-awesome-icon
                                :icon="source.icon"
                                class="my-auto mr-3 h-4 w-4 shrink-0"
                                :class="source.iconClass"
                            />
                            <div class="flex flex-col">
                                <div class="text-sm font-semibold leading-none">{{ source?.name }}</div>
                                <div class="text-xs text-slate-600 leading-none mt-1">
                                    <span v-if="source?.platform_creator_count">
                                        {{ $filters.formatWholeNumberWithUnit(source?.platform_creator_count) }}
                                        creators
                                    </span>
                                    <span v-else>Platform</span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="my-auto ml-auto flex">
                            <div
                                v-if="source.is_beta"
                                class="rounded bg-slate-100 px-1.5 py-0.5 text-xs font-medium text-slate-600 group-hover:bg-slate-200"
                            >
                                Beta
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ref, onMounted, onUnmounted } from 'vue'
import _ from 'lodash'

export default {
    name: 'DropdownDiscoverPlatform',

    props: {
        initialSelectedSourceId: {
            type: String,
            default: null,
        },
        platforms: {
            type: Array,
            required: true,
        },
        counts: {
            type: Object,
        },
    },

    emits: ['change'],

    setup() {
        const dropdownOpen = ref(false)
        const dropdown = ref(null)
        const trigger = ref(null)

        // close on click outside
        const clickHandler = ({ target }) => {
            if (!dropdownOpen.value || dropdown.value.contains(target) || trigger.value.contains(target)) return
            dropdownOpen.value = false
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen.value || keyCode !== 27) return
            dropdownOpen.value = false
        }

        onMounted(() => {
            document.addEventListener('click', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        return {
            dropdownOpen,
            dropdown,
            trigger,
        }
    },

    data() {
        return {
            selectedSourceId: null,

            availablePlatforms: {
                youtube: {
                    id: 'youtube',
                    name: 'YouTube',
                    icon: ['fab', 'youtube'],
                    iconClass: 'text-youtube',
                    description: 'You are searching on our database of creators on YouTube.',
                    is_beta: false,
                },
                twitch: {
                    id: 'twitch',
                    name: 'Twitch',
                    icon: ['fab', 'twitch'],
                    iconClass: 'text-twitch',
                    description: 'You are searching on our database of creators on Twitch.',
                    is_beta: false,
                },
                tiktok: {
                    id: 'tiktok',
                    name: 'TikTok',
                    icon: ['fab', 'tiktok'],
                    iconClass: 'text-tiktok',
                    description: 'You are searching on our database of creators on TikTok.',
                    is_beta: true,
                },
                twitter: {
                    id: 'twitter',
                    name: 'Twitter',
                    icon: ['fab', 'twitter'],
                    iconClass: 'text-twitter',
                    description: 'You are searching on our database of creators on Twitter.',
                    is_beta: true,
                },
                instagram: {
                    id: 'instagram',
                    name: 'Instagram',
                    icon: ['fab', 'instagram'],
                    iconClass: 'text-instagram',
                    description: 'You are searching on our database of creators on Instagram.',
                    is_beta: true,
                },
            },
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary']),

        selectedSource() {
            return this.availablePlatforms[this.selectedSourceId]
        },

        visiblePlatforms() {
            return _.reduce(
                this.availablePlatforms,
                (result, availablePlatform, platformId) => {
                    if (this.platforms.includes(platformId)) {
                        result.push({
                            ...availablePlatform,
                            platform_creator_count: this?.counts?.tracked[platformId],
                        })
                    }

                    return result
                },
                [],
            )
        },
    },

    created() {
        this.selectedSourceId = this.initialSelectedSourceId
    },

    methods: {
        onSelect(type, source) {
            this.selectedSourceId = source.id

            this.$emit('change', {
                type,
                value: source,
                name: source.name,
            })

            this.dropdownOpen = false
        },
    },
}
</script>
