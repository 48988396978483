<template>
    <div>
        <div class="flex justify-center">
            <nav class="flex" role="navigation" aria-label="Navigation">
                <div class="mr-2 cursor-pointer" @click="onClickPreviousPage">
                    <span
                        class="inline-flex items-center justify-center rounded border border-slate-200 bg-white px-2.5 py-2 leading-5 text-slate-300"
                    >
                        <span class="sr-only">Previous</span><wbr />
                        <svg :class="{ 'fill-current': currentPage === 1 }" class="h-4 w-4" viewBox="0 0 16 16">
                            <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
                        </svg>
                    </span>
                </div>

                <ul v-if="totalPages" class="inline-flex cursor-pointer -space-x-px text-sm font-medium shadow-sm">
                    <li
                        v-if="startMiddlePage > 1"
                        :class="{
                            'inline-flex items-center justify-center rounded-l border border-slate-200 bg-white px-3.5 py-2 leading-5 text-indigo-500':
                                currentPage === 1,
                        }"
                    >
                        <a
                            :class="{
                                'inline-flex items-center justify-center border border-slate-200 bg-white px-3.5 py-2 leading-5 text-slate-600 hover:bg-indigo-500 hover:text-white':
                                    currentPage !== 1,
                            }"
                            @click="onClickPage(1)"
                        >
                            1
                        </a>
                    </li>

                    <li v-if="startMiddlePage > 2">
                        <span
                            class="inline-flex items-center justify-center border border-slate-200 bg-white px-3.5 py-2 leading-5 text-slate-400"
                            >…</span
                        >
                    </li>
                    <li
                        v-for="page in middlePages"
                        :key="page.name"
                        :class="{
                            'inline-flex items-center justify-center rounded-l border border-slate-200 bg-white px-3.5 py-2 leading-5 text-indigo-500':
                                currentPage === page.name,
                        }"
                    >
                        <a
                            :class="{
                                'inline-flex items-center justify-center border border-slate-200 bg-white px-3.5 py-2 leading-5 text-slate-600 hover:bg-indigo-500 hover:text-white':
                                    currentPage !== page.name,
                            }"
                            @click="onClickPage(page.name)"
                        >
                            {{ page.name }}
                        </a>
                    </li>
                    <li v-if="endMiddlePage < totalPages - 1">
                        <span
                            class="inline-flex items-center justify-center border border-slate-200 bg-white px-3.5 py-2 leading-5 text-slate-400"
                            >…</span
                        >
                    </li>

                    <li
                        v-if="endMiddlePage < totalPages"
                        :class="{
                            'inline-flex items-center justify-center rounded-l border border-slate-200 bg-white px-3.5 py-2 leading-5 text-indigo-500':
                                currentPage === totalPages,
                        }"
                    >
                        <a
                            :class="{
                                'inline-flex items-center justify-center border border-slate-200 bg-white px-3.5 py-2 leading-5 text-slate-600 hover:bg-indigo-500 hover:text-white':
                                    currentPage !== totalPages,
                            }"
                            @click="onClickPage(totalPages)"
                        >
                            {{ $numeral(totalPages).format('0,0') }}
                        </a>
                    </li>
                </ul>

                <ul v-else class="inline-flex -space-x-px text-sm font-medium shadow-sm">
                    <li>
                        <span
                            class="inline-flex items-center justify-center border border-slate-200 bg-white px-3.5 py-2 leading-5 text-slate-600"
                            >{{ $numeral(currentPage).format('0,0') }}</span
                        >
                    </li>
                </ul>

                <div class="ml-2 cursor-pointer" @click="onClickNextPage">
                    <span
                        class="inline-flex items-center justify-center rounded border border-slate-200 bg-white px-2.5 py-2 leading-5 text-slate-300"
                    >
                        <span class="sr-only">Next</span><wbr />
                        <svg
                            :class="{ 'fill-current': currentPage === totalPages }"
                            class="h-4 w-4"
                            viewBox="0 0 16 16"
                        >
                            <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
                        </svg>
                    </span>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PaginationDiscover',

    props: {
        maxVisibleButtons: {
            type: Number,
            default: 3,
        },
        totalPages: {
            type: Number,
        },
        perPage: {
            type: Number,
        },
        currentPage: {
            type: Number,
        },
    },

    emits: ['pagechanged'],

    computed: {
        startMiddlePage() {
            if (this.currentPage === 1) {
                return 1
            }

            if (this.currentPage === this.totalPages) {
                return Math.max(this.totalPages - this.maxVisibleButtons, 1)
            }

            return this.currentPage - 1
        },

        endMiddlePage() {
            return Math.min(this.startMiddlePage + this.maxVisibleButtons - 1, this.totalPages - 1)
        },

        middlePages() {
            const range = []

            for (let i = this.startMiddlePage; i <= this.endMiddlePage; i += 1) {
                range.push({
                    name: i,
                    isDisabled: i === this.currentPage,
                })
            }

            return range
        },
    },

    methods: {
        onClickPreviousPage() {
            if (this.currentPage === 1) {
                return
            }

            this.changePage(this.currentPage - 1)
        },

        onClickPage(page) {
            if (this.currentPage === page) return
            this.changePage(page)
        },

        onClickNextPage() {
            if (this.currentPage === this.totalPages) {
                return
            }

            this.changePage(this.currentPage + 1)
        },

        changePage(page) {
            if (page < 1) {
                // eslint-disable-next-line no-param-reassign
                page = 1
            }

            this.$emit('pagechanged', page)
        },
    },
}
</script>
