import api from './api'

const apiPermissions = {
    async getOrganizationPermissions(axios, organizationId) {
        return axios
            .get(`/organizations/${organizationId}/permissions`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async denyPermission(axios, organizationId, permission) {
        const body = {
            permission,
        }
        return axios
            .put(`/organizations/${organizationId}/permissions/deny`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async grantPermission(axios, organizationId, permission) {
        const body = {
            permission,
        }
        return axios
            .put(`/organizations/${organizationId}/permissions/grant`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async setPermissions(axios, organizationId, permissions) {
        const body = {
            permissions,
        }
        return axios
            .post(`/organizations/${organizationId}/permissions/set`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getPermissionSets(axios, organizationId) {
        return axios
            .get(`/organizations/${organizationId}/permissions/sets`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async saveLimitations(axios, organizationId, limitations) {
        const body = {
            limitations,
        }
        return axios
            .post(`/organizations/${organizationId}/permissions/limitations`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apiPermissions
