import api from './api'

const apisupercampaign = {
    async getSuperCampaign(axios, id, includeGame, includeOrganization) {
        const relations = []

        if (includeGame === true) {
            relations.push('game')
        }

        if (includeOrganization === true) {
            relations.push('organization')
        }

        return axios
            .get(`/supercampaigns/${id}?relations=${relations}`)
            .then(({ data }) => api.createSuccessResponse(data.payload.supercampaign))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async saveSuperCampaign(axios, supercampaign) {
        const body = {
            supercampaign,
        }

        return axios
            .put(`/supercampaigns/${supercampaign.id}`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async addOrganisationGame(axios, organisationId, igdbId) {
        const body = {
            game: {
                igdb_id: igdbId,
            },
        }

        return axios
            .put(`/organizations/${organisationId}/games`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apisupercampaign
