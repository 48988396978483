<script setup>
import Tooltip from '@/components/Tooltip.vue'

const props = defineProps({
    selectedPlatformId: {
        type: String,
        required: true,
    },
    gameInfo: {
        type: Object,
        required: true,
    },
})
</script>

<template>
    <div class="relative flex whitespace-nowrap pr-20">
        <div
            class="relative flex h-8 w-16 shrink-0 overflow-hidden rounded bg-slate-200 bg-cover bg-center"
            :style="{
                'background-image': `url(${`https://streamforge-etl-media.nyc3.cdn.digitaloceanspaces.com/${props.selectedPlatformId}/games/thumbnails/default/${props.gameInfo.game_id}`})`,
            }"
        >
            <Tooltip v-if="props.gameInfo.sponsored_count > 0" placement="top" max-width="400" class="h-full w-full">
                <template #trigger>
                    <div
                        class="absolute right-0 top-0 flex h-full w-full bg-gradient-to-b from-transparent to-slate-900 pb-1"
                    >
                        <font-awesome-icon
                            :icon="['fas', 'dollar-sign']"
                            class="mx-auto my-auto h-4 w-4 text-green-300 drop-shadow-lg"
                        />
                    </div>

                    <div class="absolute bottom-0 left-0 h-1 w-full rounded-bl rounded-br bg-green-300"></div>
                </template>
                <template #default>
                    <div class="whitespace-nowrap text-xs">
                        Creator had a
                        <span class="font-semibold text-white">#sponsored</span>
                        indicator in their title
                        <span class="font-semibold text-white">{{ props.gameInfo.sponsored_count }} times</span>
                    </div>
                </template>
            </Tooltip>
        </div>

        <div class="my-auto ml-3 flex-col text-sm leading-none">
            <div class="text-md line-clamp-1 whitespace-normal font-semibold" :title="props.gameInfo.name">
                {{ props.gameInfo.name }}
            </div>
            <div v-if="selectedPlatformId === 'twitch'" class="flex text-xs">
                <div>
                    Last streamed
                    {{ $filters.moment(props.gameInfo.last_stream_ended_at).format('MMM Do, YYYY') }}
                </div>
            </div>
            <div v-else-if="selectedPlatformId === 'youtube'" class="flex text-xs">
                <div>
                    Last video posted
                    {{ $filters.moment(props.gameInfo.last_video_at).format('MMM Do, YYYY') }}
                </div>
            </div>
        </div>

        <router-link
            class="absolute right-0 top-0 ml-4 flex h-full"
            :to="{
                path: $route.path,
                query: {
                    ...$route.query,
                    ['profile.tabs.secondary']: 'all_content',
                    ['profile.tabs.secondary.filters']: `games:${encodeURIComponent(props.gameInfo.game_id)}`,
                },
            }"
        >
            <div
                class="my-auto ml-auto hidden cursor-pointer rounded bg-slate-100 px-2 py-1.5 text-xs font-semibold transition duration-200 group-hover:flex hover:bg-slate-200"
            >
                <span class="my-auto flex uppercase">
                    <font-awesome-icon :icon="['fas', 'rectangle-list']" class="my-auto mr-1 h-4 w-4" />
                    <span class="my-auto leading-none">Open</span>
                </span>
            </div>
        </router-link>
    </div>
</template>
