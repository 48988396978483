<script setup lang="ts">
import type { Table } from '@tanstack/vue-table'
import { computed } from 'vue'

import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

const props = defineProps({
    table: {
        type: Object,
        required: true,
    },
})

const columns = computed(() =>
    props.table.getAllColumns().filter((column) => typeof column.accessorFn !== 'undefined' && column.getCanHide()),
)

const visibleColumnsCount = computed(
    () =>
        props.table
            .getAllColumns()
            .filter(
                (column) =>
                    typeof column.accessorFn !== 'undefined' && column.getCanHide() && column.getIsVisible() === true,
            ).length,
)
</script>

<template>
    <DropdownMenu>
        <DropdownMenuTrigger as-child>
            <Button variant="outline" size="sm" class="hidden h-8 lg:flex">
                <!-- <MixerHorizontalIcon class="w-4 h-4 mr-2" /> -->
                <font-awesome-icon :icon="['fas', 'line-columns']" class="mr-2 h-3 w-3" />
                <span class="my-auto text-xs font-semibold uppercase leading-none"
                    >{{ visibleColumnsCount }} Columns</span
                >
            </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
            <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
            <DropdownMenuSeparator />

            <DropdownMenuCheckboxItem
                v-for="column in columns"
                :key="column.id"
                class="capitalize"
                :checked="column.getIsVisible()"
                @update:checked="(value) => column.toggleVisibility(!!value)"
                @select="$event.preventDefault()"
            >
                {{ column?.columnDef?.meta?.title ?? column.id }}
            </DropdownMenuCheckboxItem>
        </DropdownMenuContent>
    </DropdownMenu>
</template>
