<template>
    <div></div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    name: 'Landing',

    computed: {
        ...mapGetters(['tokens', 'isAuthenticated', 'self', 'selfActiveOrganization', 'grantedPermissions']),
    },

    created() {
        if (this.isAuthenticated) {
            // Default Route for logged-in

            const enabledModules = _.filter(this.grantedPermissions, (permissionId) =>
                permissionId.startsWith('module'),
            )

            if (enabledModules?.length > 0) {
                try {
                    const firstModule = _.first(enabledModules)
                    const firstModulePage = firstModule.split('.')[1]

                    this.$router.push({ name: firstModulePage })
                } catch (error) {
                    console.error(`Error redirecting to default page`, error)
                }
            } else {
                this.$router.push({ name: 'no-access' })
            }
        } else {
            this.$router.push({
                name: 'login',
            })
        }
    },
}
</script>
