<template>
    <ModalBasic
        title="Accept Creator Bid"
        :modal-open="state.modals.isAcceptModalOpen"
        @close-modal="state.modals.isAcceptModalOpen = false"
    >
        <div class="relative flex flex-col">
            <!-- Modal content -->
            <div class="px-5 py-4">
                <div class="text-sm">
                    <div class="mb-3">
                        <p class="text-md text-slate-800">
                            Are you sure you want to accept {{ selectedCreator?.participant?.platform_user_name }}'s
                            bid?
                        </p>
                        <p v-if="campaign.budget_amount > 0" class="text-md text-slate-800">
                            The budget will increase to
                            <span class="font-semibold text-emerald-500"
                                >${{
                                    $filters
                                        .numeral(
                                            calculateBudgetIncrease(selectedCreator?.payout_amount)
                                                .totalAllocatedBudget,
                                        )
                                        .format('0,0')
                                }}</span
                            >
                            allocated of
                            <span class="font-semibold text-emerald-500"
                                >${{
                                    $filters
                                        .numeral(
                                            calculateBudgetIncrease(selectedCreator?.payout_amount)
                                                .maxSupercampaignBudget,
                                        )
                                        .format('0,0')
                                }}</span
                            >.
                        </p>
                        <p class="text-md mt-3 font-bold text-slate-800">This action cannot be undone.</p>
                    </div>
                </div>
            </div>

            <!-- Modal footer -->
            <div class="border-t border-slate-200 px-5 py-4">
                <div class="flex flex-wrap justify-end space-x-2">
                    <button
                        class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                        @click="state.modals.isAcceptModalOpen = false"
                    >
                        Cancel
                    </button>

                    <button class="btn-sm bg-emerald-500 text-white hover:bg-emerald-600" @click="onSubmitAccept">
                        Accept
                    </button>
                </div>
            </div>
        </div>
    </ModalBasic>

    <ModalBasic
        title="Decline Creator Bid"
        :modal-open="state.modals.isDeclineModalOpen"
        @close-modal="state.modals.isDeclineModalOpen = false"
    >
        <div class="relative flex flex-col">
            <!-- Modal content -->
            <div class="px-5 py-4">
                <div class="text-sm">
                    <div class="mb-3">
                        <p class="text-md text-slate-800">
                            Are you sure you want to decline
                            {{ selectedCreator?.participant?.platform_user_name }}'s bid?
                        </p>
                        <p class="text-md mt-2 font-bold text-slate-800">This action cannot be undone.</p>
                    </div>
                </div>
            </div>

            <!-- Modal footer -->
            <div class="border-t border-slate-200 px-5 py-4">
                <div class="flex flex-wrap justify-end space-x-2">
                    <button
                        class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                        @click="state.modals.isDeclineModalOpen = false"
                    >
                        Cancel
                    </button>

                    <button class="btn-sm bg-rose-500 text-white hover:bg-rose-600" @click="onSubmitDecline">
                        Decline
                    </button>
                </div>
            </div>
        </div>
    </ModalBasic>

    <div class="grid grid-cols-[1fr,285px] gap-4" style="grid-template-columns: 1fr 285px">
        <div class="flex-1 overflow-auto">
            <CampaignBudgetSectionBids
                v-if="campaign && creators"
                :campaign="campaign"
                :creators="creators"
                :campaigns-by-id="campaignsById"
            />

            <!-- Skeleton for profile card -->
            <SkeletonApprovalSubpage v-if="state.isLoading" />

            <div v-else-if="creators && creators.length === 0" class="rounded bg-white px-5 py-10">
                <div class="px-4 text-center">
                    <div
                        class="mb-4 inline-flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-t from-slate-200 to-slate-100"
                    >
                        <svg class="h-6 w-5 fill-current" viewBox="0 0 20 24">
                            <path class="text-slate-500" d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z" />
                            <path class="text-slate-300" d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z" />
                            <path
                                class="text-slate-400"
                                d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"
                            />
                        </svg>
                    </div>
                    <h2 class="mb-2 text-2xl font-bold text-slate-800">No Pending Bids</h2>
                    <div class="mb-6">
                        Looks like there aren't any bids that need your review yet! We'll let you know when there is.
                    </div>
                </div>
            </div>
            <div
                v-else
                class="flex grow translate-x-0 transform flex-col rounded border border-slate-200 shadow-lg transition-transform duration-300 ease-in-out md:translate-x-0"
            >
                <!-- Content -->
                <div v-if="selectedCreator && selectedCreatorItems">
                    <CreatorProfile
                        class="rounded"
                        :platform-id="selectedCreator.participant.platform_id"
                        :platform-user-id="selectedCreator.participant.platform_user_id"
                        :targeting-groups="targetingGroups"
                        :participant="selectedCreator.participant"
                        :campaign="campaign"
                        :bid_payout_amount="selectedCreator.payout_amount"
                        :items="selectedCreatorItems"
                    />

                    <div
                        v-if="
                            (selectedCreator.status !== 'accepted' && selectedCreator.status !== 'declined') ||
                            isSfStaff
                        "
                        class="flex justify-end space-x-2 border-t border-slate-200 bg-white px-4 py-4"
                    >
                        <button
                            :disabled="selectedCreator.status === 'declined'"
                            class="btn text-white enabled:bg-rose-500 enabled:hover:bg-rose-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                            @click="state.modals.isDeclineModalOpen = true"
                        >
                            <font-awesome-icon :icon="['fas', 'xmark']" class="h-4 w-4 shrink-0 fill-current" />
                            <span class="ml-2">Decline</span>
                        </button>
                        <button
                            :disabled="selectedCreator.status === 'considered'"
                            class="btn text-white enabled:bg-yellow-500 enabled:hover:bg-yellow-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                            @click="onTriageStatusClick(selectedCreator, 'consider')"
                        >
                            <font-awesome-icon :icon="['fas', 'question']" class="h-4 w-4 shrink-0 fill-current" />
                            <span class="ml-2">Set as backup</span>
                        </button>

                        <button
                            :disabled="selectedCreator.status === 'accepted'"
                            class="btn text-white enabled:bg-emerald-500 enabled:hover:bg-emerald-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                            @click="state.modals.isAcceptModalOpen = true"
                        >
                            <font-awesome-icon :icon="['fas', 'check']" class="h-4 w-4 shrink-0 fill-current" />
                            <span class="ml-2">Accept</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-full">
            <div
                class="md:sticky md:overflow-y-auto md:overflow-x-hidden md:scrollbar md:scrollbar-thin md:scrollbar-track-transparent md:scrollbar-thumb-slate-200"
            >
                <div v-if="!state.isLoading" class="">
                    <div class="w-full">
                        <div class="space-y-2 text-sm text-white">
                            <button
                                class="flex w-full items-center justify-between rounded bg-indigo-500 px-4 py-2 transition duration-200 hover:bg-indigo-600"
                                @click="onTriageBucketClick('all')"
                            >
                                <span>All</span>
                                <span class="text-sm"
                                    >${{
                                        $numeral(applyBudgetScaling(totalPayoutByTriageStatus['all'])).format('0,0.00')
                                    }}</span
                                >
                            </button>

                            <button
                                class="flex w-full items-center justify-between rounded bg-blue-500 px-4 py-2 transition duration-200 hover:bg-blue-600"
                                @click="onTriageBucketClick('sent')"
                            >
                                <span>Pending</span>
                                <span class="text-sm"
                                    >${{
                                        $numeral(applyBudgetScaling(totalPayoutByTriageStatus['sent'])).format('0,0.00')
                                    }}</span
                                >
                            </button>

                            <button
                                class="flex w-full items-center justify-between rounded bg-emerald-500 px-4 py-2 transition duration-200 hover:bg-emerald-600"
                                @click="onTriageBucketClick('accepted')"
                            >
                                <span>Accepted</span>
                                <span class="text-sm"
                                    >${{
                                        $numeral(applyBudgetScaling(totalPayoutByTriageStatus['accepted'])).format(
                                            '0,0.00',
                                        )
                                    }}</span
                                >
                            </button>

                            <button
                                class="flex w-full items-center justify-between rounded bg-yellow-500 px-4 py-2 transition duration-200 hover:bg-yellow-600"
                                @click="onTriageBucketClick('considered')"
                            >
                                <span>Backup</span>
                                <span class="text-sm"
                                    >${{
                                        $numeral(applyBudgetScaling(totalPayoutByTriageStatus['considered'])).format(
                                            '0,0.00',
                                        )
                                    }}</span
                                >
                            </button>

                            <button
                                class="flex w-full items-center justify-between rounded bg-rose-500 px-4 py-2 transition duration-200 hover:bg-rose-600"
                                @click="onTriageBucketClick('declined')"
                            >
                                <span>Declined</span>
                                <span class="text-sm"
                                    >${{
                                        $numeral(applyBudgetScaling(totalPayoutByTriageStatus['declined'])).format(
                                            '0,0.00',
                                        )
                                    }}</span
                                >
                            </button>
                        </div>
                    </div>

                    <!-- Search form -->
                    <div class="relative mt-5">
                        <label class="sr-only">Search</label>
                        <input
                            v-model="searchText"
                            class="form-input w-full pl-9 focus:border-slate-300"
                            type="search"
                            placeholder="Search…"
                        />
                        <button class="group absolute inset-0 right-auto" type="submit" aria-label="Search">
                            <svg
                                class="ml-3 mr-2 h-4 w-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                                ></path>
                                <path
                                    d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                                ></path>
                            </svg>
                        </button>
                    </div>

                    <!-- Skeleton for triage sidebar -->
                    <SkeletonTriageSidebar v-if="state.isLoading" />

                    <div
                        v-for="(triageGroup, index) in creatorsByTriageStatus"
                        :key="triageGroup.name"
                        class="group mt-5"
                    >
                        <div class="mb-3 flex items-center justify-between text-xs font-semibold uppercase">
                            <div class="text-slate-400">
                                {{ getFromDictionary(`campaign.bid.status.${triageGroup.name}`, 'label') }} ({{
                                    triageGroup.size
                                }})
                            </div>

                            <div v-if="index === 0 && triageGroup.creators.length > 1" class="flex items-center">
                                <select
                                    v-model="state.activeSortOption"
                                    class="form-select h-auto max-w-xs py-1.5 text-xs text-slate-500"
                                >
                                    <option
                                        v-for="(bidSortOption, bidSortKey) in options.bidSort"
                                        :key="bidSortKey"
                                        :value="bidSortKey"
                                    >
                                        {{ getFromDictionary(`campaign.bid.sort_options.${bidSortKey}`, 'label') }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <ul class="mb-6 space-y-1">
                            <li
                                v-for="creator in triageGroup.creators"
                                :key="creator.id"
                                @click="onCreatorClick(creator)"
                            >
                                <div
                                    class="flex w-full justify-between rounded border border-transparent px-3 py-2 transition duration-200"
                                    :class="{
                                        'bg-white shadow-sm': selectedBidId === creator.id,
                                        'cursor-pointer border-slate-200 hover:border-slate-300':
                                            selectedBidId !== creator.id,
                                    }"
                                >
                                    <div class="relative flex grow items-center">
                                        <div class="relative mr-2.5">
                                            <Avatar
                                                class="bg-slate-700 text-slate-50"
                                                :src="creator.participant.platform_user_logo"
                                                :username="creator.participant.platform_user_name"
                                                :size="32"
                                            />

                                            <div
                                                v-if="creator.participant.platform_id === 'twitch'"
                                                class="absolute -bottom-2 -right-1.5 z-10 flex rounded-full"
                                            >
                                                <svg
                                                    width="22"
                                                    height="22"
                                                    fill="none"
                                                    class="flex-shrink-0 fill-current"
                                                >
                                                    <path
                                                        fill="#fff"
                                                        d="M7.95243 3.33337 4.3999 6.8859v11.3999h2.85715v5.2093l5.20935-5.2093h1.9048l5.4573-5.4573V3.33337H7.95243Z"
                                                    />
                                                    <path
                                                        fill="#9147FF"
                                                        d="M8.78086 5.33337 6.3999 7.71433v8.57147h2.85714v2.3809l2.38096-2.3809h1.9048L17.8285 12V5.33337H8.78086Z"
                                                    />
                                                    <path
                                                        fill="#fff"
                                                        d="m14.9711 13.4286 1.9048-1.9048V6.28574H9.25684v7.14286h1.90476v1.9048l1.9048-1.9048h1.9047Z"
                                                    />
                                                    <path
                                                        fill="#9147FF"
                                                        d="M12.1143 8.19037h.9523v2.85713h-.9523V8.19037Zm3.3333 0v2.85713h-.9524V8.19037h.9524Z"
                                                    />
                                                </svg>
                                            </div>

                                            <div
                                                v-if="creator.participant.platform_id === 'youtube'"
                                                class="absolute -bottom-0.5 -right-1 z-10 flex rounded-full"
                                            >
                                                <svg height="16" width="16" viewBox="0 0 490 490">
                                                    <g>
                                                        <g>
                                                            <path
                                                                fill="#EA4640"
                                                                d="M480,180v130c0,55.195-44.805,100-100,100H110c-55.195,0-100-44.805-100-100V180
                                                            c0-55.199,44.805-100,100-100h270C435.195,80,480,124.801,480,180z"
                                                            />
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <polygon
                                                                    style="fill: #ffffff"
                                                                    points="320,245 200,295 200,195 "
                                                                />
                                                            </g>
                                                            <g>
                                                                <path
                                                                    fill="white"
                                                                    d="M380,70H110C49.346,70,0,119.346,0,180v130c0,60.654,49.346,110,110,110h270
                                                                c60.654,0,110-49.346,110-110V180C490,119.346,440.654,70,380,70z M470,310c0,49.626-40.374,90-90,90H110
                                                                c-49.626,0-90-40.374-90-90V180c0-49.626,40.374-90,90-90h270c49.626,0,90,40.374,90,90V310z"
                                                                />
                                                                <path
                                                                    fill="white"
                                                                    d="M323.846,235.769l-120-50c-3.085-1.286-6.611-0.945-9.393,0.911
                                                                c-2.782,1.854-4.453,4.977-4.453,8.32v100c0,3.344,1.671,6.466,4.453,8.32c1.667,1.112,3.601,1.68,5.548,1.68
                                                                c1.301,0,2.608-0.254,3.845-0.769l120-50c3.727-1.553,6.154-5.194,6.154-9.231S327.572,237.322,323.846,235.769z M210,280v-70
                                                                l84,35L210,280z"
                                                                />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="flex flex-col">
                                            <div class="text-left text-sm font-medium text-slate-800">
                                                {{ creator.participant.platform_user_name }}
                                            </div>
                                            <div class="text-left text-xs font-medium text-slate-400">
                                                ${{ $numeral(creator.payout_amount).format('0,0.00') }}
                                            </div>
                                        </div>
                                        <div
                                            v-if="isRecentlyAdded(creator)"
                                            class="absolute right-0 top-0 flex h-full text-right"
                                        >
                                            <span
                                                class="my-auto rounded bg-emerald-100 px-2 py-1 text-xs font-semibold text-emerald-500"
                                                >New!</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div
                    v-if="searchText && creators && creatorsByTriageStatus.length === 0"
                    class="mt-12 text-center font-semibold"
                >
                    <p>No creators found matching "{{ searchText }}"</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { nextTick } from 'vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import moment from 'moment'
import targetGroupAPI from '@/utils/api/api.targeting'
import targetGroupTools from '@/utils/searching/targetgroups'
import Avatar from '@/components/Avatar.vue'
import ModalBasic from '@/components/ModalBasic.vue'
import SkeletonApprovalSubpage from '@/partials/skeletons/SkeletonApprovalSubpage.vue'
import SkeletonTriageSidebar from '@/partials/skeletons/SkeletonTriageSidebar.vue'
import CreatorProfile from '@/partials/profiles/CreatorProfile/CreatorProfile.vue'
import CampaignBudgetSectionBids from '@/partials/widgets/CampaignBudgetSectionBids.vue'
import campaignBidsAPI from '../utils/api/api.campaign.bids'

export default {
    name: 'CampaignBidSubpage',

    components: {
        Avatar,
        SkeletonApprovalSubpage,
        SkeletonTriageSidebar,
        ModalBasic,
        CreatorProfile,
        CampaignBudgetSectionBids,
    },

    props: {
        supercampaign: Object,
        campaignsById: Object,
    },
    emits: ['submit', 'close-modal', 'onSupercampaignEdit'],

    data() {
        return {
            creators: null,
            creatorsByBidId: {},

            targetingGroups: [],

            searchText: '',

            selectedBidId: null,

            bidStatus: {
                Created: 'created',
                Sent: 'sent',
                Declined: 'declined',
                Accepted: 'accepted',
                Considered: 'considered',
            },

            triageStatuses: ['sent', 'accepted', 'declined', 'considered'],

            currentTriageStatus: 'all',

            options: {
                bidSort: {
                    date: {
                        field: ['created_at'],
                        order: ['asc'],
                    },
                    price: {
                        field: ['payout_amount'],
                        order: ['asc'],
                    },
                    name: {
                        field: [(user) => user.participant.platform_user_name.toLowerCase()],
                        order: ['asc'],
                    },
                },
            },

            state: {
                modals: {
                    isAcceptModalOpen: false,
                    isDeclineModalOpen: false,
                },

                isLoading: false,

                activeSortOption: 'date',
            },

            selectedCreatorItems: null,
        }
    },

    computed: {
        ...mapGetters([
            'getFromDictionary',
            'isDevModeEnabled',
            'getCountryFromCode',
            'isSfStaff',
            'getLanguageFromCode',
            'getColor',
            'modals',
        ]),

        campaign() {
            if (!this.campaignsById) return null
            return this.campaignsById[this.$route.params.campaign_id]
        },

        selectedCreator() {
            if (!_.size(this.creators) || !this.selectedBidId) return null
            const creator = this.creatorsByBidId[this.selectedBidId]

            return creator
        },

        creatorsByTriageStatus() {
            const customSort = (item) => _.findIndex(this.triageStatuses, (needle) => needle === item.name)

            let filteredCreators = this.creators

            if (this.searchText) {
                const searchTextLower = this.searchText.toLowerCase()

                filteredCreators = _.filter(this.creators, (creator) => {
                    const name = _.get(creator, 'participant.platform_user_name', '').toLowerCase()
                    return name.includes(searchTextLower)
                })
            }

            if (this.currentTriageStatus !== 'all') {
                filteredCreators = _.filter(filteredCreators, (creator) => creator.status === this.currentTriageStatus)
            }

            const sortingOption = this.options.bidSort[this.state.activeSortOption]

            return _(filteredCreators)
                .groupBy('status')
                .map((creators, status) => ({
                    name: status,
                    size: _.size(creators),
                    creators: _.orderBy(creators, sortingOption.field, sortingOption.order),
                    // need to add approved_at support for bids
                    // creators: _.orderBy(
                    //     creators,
                    //     ['participant.approved_at', 'participant.platform_user_name'],
                    //     ['desc', 'asc']
                    // ),
                }))
                .orderBy([customSort], ['asc'])
                .value()
        },

        totalPayoutByTriageStatus() {
            const results = _.reduce(
                this.triageStatuses,
                (result, triageStatus) => {
                    // eslint-disable-next-line no-param-reassign
                    result[triageStatus] = _.sumBy(this.creators, (creator) =>
                        creator.status === triageStatus ? creator.payout_amount : 0,
                    )

                    return result
                },
                {},
            )

            results.all = _.sum(_.values(results))

            return results
        },
    },

    async created() {
        let defaultSelectedBidId = null

        if (window.location.hash) {
            defaultSelectedBidId = window.location.hash.replace(/^#/, '')
        }

        await this.getCampaignBids(this.$route.params.campaign_id, defaultSelectedBidId)
        await this.getTargetingGroups()
    },

    methods: {
        applyBudgetScaling(input) {
            return input * (1 + this.campaign.commission_rate)
        },

        async getCampaignBids(campaignId, defaultSelectedBidId) {
            const result = await campaignBidsAPI.findBids(this.$axios, campaignId, {
                // params: {
                //     relations: ['bid_items'],
                // },
            })

            if (result.success) {
                const bidResult = result.value.bids

                this.creators = _.orderBy(bidResult, ['participant.platform_user_name'], ['asc'])
                this.creatorsByBidId = _.keyBy(bidResult, 'id')

                nextTick(() => {
                    this.resetCreatorSelection(defaultSelectedBidId)
                    this.getSelectedCreatorItems()
                })
            }
        },

        async getSelectedCreatorItems() {
            const creator = this.selectedCreator
            const result = await campaignBidsAPI.getBid(
                this.$axios,
                this.campaign.id,
                creator?.participant?.id,
                creator.id,
            )

            if (result.success) {
                const bidResult = result.value.bid
                this.selectedCreatorItems = bidResult.items

                if (_.has(bidResult.items, '[0].tasks')) {
                    this.selectedCreator.participant.tasks = _.get(bidResult.items[0], 'tasks')
                }

                // this should be used instead because a bid can have multiple items
                // _.forEach(bidResult.items, (item) => {
                //     if (_.has(item, 'tasks') && item.tasks.length > 0) {
                //         this.selectedCreator.participant.tasks = item.tasks
                //     }
                // })
            }
        },

        isRecentlyAdded(creator) {
            const addedTime = moment(creator.created_at)
            const currentTime = moment()
            const duration = moment.duration(currentTime.diff(addedTime))
            const daysDiff = duration.asDays()

            return daysDiff <= 2
        },

        calculateBudgetIncrease(creatorPayout) {
            let allocatedCreatorsBudget = _.sumBy(this.creators, (creator) => {
                let sum = 0

                if (creator.status === 'accepted') {
                    sum += creator.payout_amount
                }
                return sum
            })

            if (_.isNumber(creatorPayout)) {
                allocatedCreatorsBudget += creatorPayout
            }

            const foundSteamCampaign = _.find(this.campaignsById, (campaign) => campaign.type === 'steam-broadcast')

            const campaignBudget = this.campaign.budget_amount

            let maxSupercampaignBudget = campaignBudget
            let steamCampaignBudget = 0

            if (foundSteamCampaign) {
                maxSupercampaignBudget += foundSteamCampaign.budget_amount
                steamCampaignBudget = foundSteamCampaign.budget_amount
            }

            let maxCampaignBudgetOperationalCost
            let maxAllocatedCreatorsBudget
            let allocatedOperationalCost

            if (this.campaign.id === '47abc662-2dd0-4c4a-9615-f9f5a575dfd8') {
                allocatedOperationalCost = allocatedCreatorsBudget * this.campaign.commission_rate
            } else {
                maxCampaignBudgetOperationalCost = campaignBudget * this.campaign.commission_rate
                maxAllocatedCreatorsBudget = campaignBudget - maxCampaignBudgetOperationalCost

                // Scale the current campaign operational cost with allocated budget
                allocatedOperationalCost =
                    maxCampaignBudgetOperationalCost * (allocatedCreatorsBudget / maxAllocatedCreatorsBudget)
            }

            const totalAllocatedBudget = allocatedCreatorsBudget + allocatedOperationalCost + steamCampaignBudget

            return {
                totalAllocatedBudget,
                maxSupercampaignBudget,
            }
        },

        onSubmitAccept() {
            this.onTriageStatusClick(this.selectedCreator, 'accept')
            this.state.modals.isAcceptModalOpen = false
        },

        onSubmitDecline() {
            this.onTriageStatusClick(this.selectedCreator, 'decline')
            this.state.modals.isDeclineModalOpen = false
        },

        onTriageBucketClick(triageStatus) {
            this.currentTriageStatus = triageStatus
        },

        onCreatorClick(creator) {
            this.selectedBidId = creator.id

            window.history.replaceState('', '', `#${_.get(creator, 'id')}`)

            this.getSelectedCreatorItems()
        },

        onTriageStatusClick(creator, newStatus) {
            const participant = [
                {
                    participant_id: creator.participant_id,
                    bid_id: creator.id,
                },
            ]

            this.updateParticipantStatus(participant, newStatus)
        },

        updateParticipantStatus(participants, newStatus) {
            const tempParticipantId = participants[0].participant_id
            const tempBidId = participants[0].bid_id

            this.$axios
                .put(`/participants/${tempParticipantId}/bids/${tempBidId}/${newStatus}`)
                .then(({ data }) => {
                    if (newStatus === 'decline') {
                        this.creatorsByBidId[tempBidId].status = 'declined'
                    } else {
                        const changedBid = data.payload.bid
                        _.assign(
                            this.creatorsByBidId[changedBid.id],
                            { items: changedBid.items },
                            { status: changedBid.status },
                        )
                    }

                    this.resetCreatorSelection()
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        async getTargetingGroups() {
            const filtering = {
                twitch: targetGroupTools.createTwitchOptions(),
                youtube: targetGroupTools.createYoutubeOptions(),
            }

            const defaults = {
                twitch: filtering.twitch.default,
                youtube: filtering.youtube.default,
            }

            const result = await targetGroupAPI.getEntityTargetGroups(
                this.$axios,
                'campaign',
                this.$route.params.campaign_id,
                defaults,
            )

            this.targetingGroups = _.map(result.value, (filters) => ({
                name: filters.title,
                filters,
            }))
        },

        resetCreatorSelection(selectedBidId) {
            if (_.size(this.creators) > 0) {
                if (selectedBidId) {
                    const matchedCreator = this.creatorsByBidId[selectedBidId]

                    if (matchedCreator) {
                        this.onCreatorClick(matchedCreator)
                        return
                    }
                }

                const pendingCreatorsGroup = _.find(this.creatorsByTriageStatus, (item) => item.name === 'sent')

                if (pendingCreatorsGroup && _.size(pendingCreatorsGroup.creators) > 0) {
                    this.onCreatorClick(_.first(pendingCreatorsGroup.creators))
                } else {
                    const firstGroupFound = _.first(this.creatorsByTriageStatus)
                    this.onCreatorClick(_.first(firstGroupFound.creators))
                }
            }
        },
    },
}
</script>
