<template>
    <div class="mx-auto w-full max-w-9xl px-4 py-8 sm:px-6 md:py-0 lg:px-8">
        <div v-if="selfActiveOrganization" class="xl:flex">
            <!-- Left + Middle content -->
            <div class="flex-1 md:flex">
                <OrganizationSidebar :organization="selfActiveOrganization" />

                <div class="flex-1 md:ml-8 xl:mx-4 2xl:mx-8">
                    <div class="md:py-8">
                        <router-view :organization="selfActiveOrganization" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import OrganizationSidebar from '@/partials/sidebars/OrganizationSidebar.vue'

export default {
    name: 'OrganizationPage',

    components: {
        OrganizationSidebar,
    },

    data() {
        return {
            organization: null,
        }
    },

    created() {
        // this.getOrganization()
    },

    computed: {
        ...mapGetters(['selfActiveOrganization']),
    },

    methods: {
        // getOrganization() {
        //     const organizations = this.selfOrganizations
        //     if (organizations && organizations.length) {
        //         const organization = _.find(organizations, item => item.id === this.$route.params.organization_id)
        //         console.log(organizations)
        //         if (!organization) {
        //             return this.$router.push({
        //                 name: 'home',
        //             })
        //         }
        //         this.organization = organization
        //     }
        // },
    },
}
</script>
