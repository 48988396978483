<template>
    <!-- Skeleton component -->
    <div
        class="flex grow translate-x-0 transform flex-col transition-transform duration-300 ease-in-out md:translate-x-0"
    >
        <div class="relative h-56 animate-pulse bg-slate-200" />

        <div class="relative border-t border-slate-200 bg-white px-4 pb-8 sm:px-6">
            <!-- Pre-header -->
            <div class="-mt-16 mb-6 sm:mb-3">
                <div class="flex flex-col items-center sm:flex-row sm:items-end sm:justify-between">
                    <div class="-ml-1 -mt-1 mb-4 inline-flex sm:mb-0">
                        <div class="h-32 w-32 rounded-full border-4 border-white bg-slate-200" />
                    </div>

                    <div class="flex space-x-2 sm:mb-2">
                        <div class="btn-sm group h-[30px] w-8 shrink-0 animate-pulse bg-slate-200 fill-current" />
                        <div class="btn-sm group h-[30px] w-8 shrink-0 animate-pulse bg-slate-200 fill-current" />
                        <div class="btn-sm group h-[30px] w-8 shrink-0 animate-pulse bg-slate-200 fill-current" />
                        <div class="btn-sm group h-[30px] w-8 shrink-0 animate-pulse bg-slate-200 fill-current" />
                        <div class="btn-sm group h-[30px] w-32 shrink-0 animate-pulse bg-slate-200 fill-current" />
                    </div>
                </div>
            </div>

            <!-- Header -->
            <div class="mb-12 mt-4">
                <div class="mb-2 inline-flex items-start">
                    <div class="h-5 w-36 animate-pulse rounded bg-slate-200" />
                </div>

                <div class="mb-3 mt-3 h-3 w-1/2 animate-pulse rounded bg-slate-200" />

                <div class="mt-5 flex flex-wrap justify-center space-x-4 sm:justify-start">
                    <div class="flex h-4 w-20 animate-pulse items-center rounded bg-slate-200" />
                    <div class="ml-5 flex h-4 w-24 animate-pulse items-center rounded bg-slate-200" />
                </div>
            </div>

            <!-- Stats -->
            <div class="mb-12">
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 h-[16px] animate-pulse rounded-sm bg-slate-200 p-4 shadow-sm" />
                    <div class="col-span-3 h-[92px] animate-pulse rounded-sm bg-slate-200 p-4 shadow-sm" />
                    <div class="col-span-3 h-[92px] animate-pulse rounded-sm bg-slate-200 p-4 shadow-sm" />
                    <div class="col-span-3 h-[92px] animate-pulse rounded-sm bg-slate-200 p-4 shadow-sm" />
                    <div class="col-span-3 h-[92px] animate-pulse rounded-sm bg-slate-200 p-4 shadow-sm" />
                </div>
            </div>

            <!-- Deliverables -->
            <div class="mb-12">
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 h-[16px] animate-pulse rounded-sm bg-slate-200 p-4 shadow-sm" />
                    <div class="col-span-3 h-[92px] animate-pulse rounded-sm bg-slate-200 p-4 shadow-sm" />
                    <div class="col-span-3 h-[92px] animate-pulse rounded-sm bg-slate-200 p-4 shadow-sm" />
                </div>
            </div>

            <!-- Table -->
            <div class="mb-12">
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 h-[16px] animate-pulse rounded-sm bg-slate-200 p-4 shadow-sm" />
                    <div class="col-span-12 h-[16px] animate-pulse rounded-sm bg-slate-200 p-4 shadow-sm" />
                    <div class="col-span-12 h-[16px] animate-pulse rounded-sm bg-slate-200 p-4 shadow-sm" />
                    <div class="col-span-12 h-[16px] animate-pulse rounded-sm bg-slate-200 p-4 shadow-sm" />
                    <div class="col-span-12 h-[16px] animate-pulse rounded-sm bg-slate-200 p-4 shadow-sm" />
                    <div class="col-span-12 h-[16px] animate-pulse rounded-sm bg-slate-200 p-4 shadow-sm" />
                    <div class="col-span-12 h-[16px] animate-pulse rounded-sm bg-slate-200 p-4 shadow-sm" />
                    <div class="col-span-12 h-[16px] animate-pulse rounded-sm bg-slate-200 p-4 shadow-sm" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkeletonApprovalSubpage',
}
</script>
