<template>
    <div>
        <div class="mb-4 flex justify-between">
            <div>
                <h2 class="font-semibold text-slate-800">Notes</h2>
                <p class="text-sm">These are the notes associated with the profile.</p>
            </div>

            <div v-if="!add.isAdding" class="flex justify-end">
                <button class="btn-sm mt-auto bg-indigo-500 text-white hover:bg-indigo-600" @click="onNoteAddStart()">
                    <font-awesome-icon :icon="['fas', 'plus']" class="mr-1 h-3.5 w-3.5 text-slate-300" />
                    Add Note
                </button>
            </div>
        </div>

        <div v-if="add.isAdding" class="mb-4">
            <textarea
                v-model="add.text"
                class="h-32 w-full resize-y rounded-lg rounded-sm border border-slate-300 bg-white p-2 text-sm"
                placeholder="Please enter a note"
            ></textarea>
            <div class="mt-2 flex justify-end space-x-2">
                <button
                    class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                    @click="onCancelNoteAdd()"
                >
                    Cancel
                </button>
                <button class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600" @click="onNoteAdd()">Save</button>
            </div>
        </div>

        <div class="max-h-96 overflow-y-auto">
            <div v-for="note in notes" :key="note.id" class="mb-4 border bg-slate-50 p-4">
                <template v-if="!(edit.isEditing && edit.id === note.id)">
                    <div class="flex">
                        <div class="text-xs">
                            {{ note.updated_by }}
                        </div>
                        <div class="ml-auto text-xs">
                            {{ $filters.formatDateAndTime(note.updated_at) }}
                        </div>
                    </div>
                    <div class="mt-3 grow whitespace-pre-line">
                        {{ note.text }}
                    </div>
                    <div class="flex-col">
                        <div class="mt-2 flex justify-end">
                            <a
                                class="ml-auto cursor-pointer text-sm text-indigo-500 hover:text-indigo-600"
                                @click="onNoteEditStart(note)"
                            >
                                Edit
                            </a>
                        </div>
                    </div>
                </template>

                <template v-if="edit.isEditing && edit.id === note.id">
                    <div>
                        <textarea
                            v-model="edit.text"
                            class="h-32 w-full resize-y rounded-lg rounded-sm border border-slate-300 bg-white p-2 text-sm"
                        ></textarea>
                        <div class="mt-3 flex">
                            <div>
                                <button
                                    class="btn-sm bg-red-500 text-white hover:bg-red-600"
                                    @click="onNoteDelete(note)"
                                >
                                    Delete
                                </button>
                            </div>
                            <div class="ml-auto">
                                <button
                                    class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                                    @click="onNoteEditCancel(note)"
                                >
                                    Cancel
                                </button>
                                <button
                                    class="btn-sm ml-3 bg-indigo-500 text-white hover:bg-indigo-600"
                                    @click="onNoteEditSave(note)"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div
            v-if="!add.isLoading && !isLoading && notes.length === 0 && !add.isAdding"
            class="rounded border bg-slate-50 p-4 text-center text-sm"
        >
            No notes have been added yet
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import notesAPI from '@/utils/api/api.notes'

export default {
    name: 'Notes',

    components: {},

    props: {
        entityType: {
            type: String,
        },
        entityId: {
            type: String,
        },
        related: {
            type: Array,
        },
    },

    data() {
        return {
            isLoading: false,
            edit: {
                isEditing: false,
                id: null,
                text: null,
            },
            add: {
                isAdding: false,
                text: '',
            },
            notes: [],
        }
    },

    watch: {
        entityId() {
            this.loadNotes()
        },
        entityType() {
            this.loadNotes()
        },
    },

    created() {
        this.loadNotes()
    },

    methods: {
        async loadNotes() {
            this.isLoading = true

            this.notes = ''
            if (this.entityType && this.entityId) {
                const existingNotes = await notesAPI.getNotes(this.$axios, this.entityType, this.entityId, this.related)

                if (existingNotes.success) {
                    this.notes = existingNotes.value
                }
            }

            this.isLoading = false
        },

        onNoteAddStart() {
            this.add.isAdding = true
        },

        async onNoteAdd() {
            if (this.isLoading) {
                return
            }
            this.isLoading = true

            const newNote = await notesAPI.addNote(this.$axios, this.entityType, this.entityId, this.add.text)

            if (newNote.success) {
                this.notes.unshift(newNote.value)
            }

            this.isLoading = false

            this.onCancelNoteAdd()
        },

        onCancelNoteAdd() {
            this.add.text = ''
            this.add.isAdding = false
        },

        async onNoteDelete(note) {
            if (this.isLoading) {
                return
            }

            this.isLoading = true

            const result = await notesAPI.deleteNote(this.$axios, note.id)

            if (result.success) {
                this.notes = _.filter(this.notes, (keptNote) => {
                    return keptNote.id !== note.id
                })
            }

            this.isLoading = false
        },

        onNoteEditStart(note) {
            if (this.isLoading) {
                return
            }

            this.edit.isEditing = true
            this.edit.id = note.id
            this.edit.text = note.text
        },

        async onNoteEditSave() {
            if (this.isLoading) {
                return
            }

            this.isLoading = true

            const result = await notesAPI.updateNote(this.$axios, this.edit.id, this.edit.text)

            if (result.success) {
                const existingNote = _.find(this.notes, (note) => {
                    return note.id === this.edit.id
                })
                existingNote.text = this.edit.text
                existingNote.updated_at = Date.now()
            }

            this.isLoading = false

            this.onNoteEditCancel()
        },

        onNoteEditCancel() {
            this.edit.isEditing = false
            this.edit.id = null
            this.edit.text = null
        },
    },

    computed: {
        ...mapGetters(['getFromDictionary']),
    },
}
</script>
