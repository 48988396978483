<template>
    <main class="bg-white">
        <div class="relative flex">
            <!-- Content -->
            <div class="w-full md:w-1/2">
                <div class="flex h-full min-h-screen flex-col after:flex-1">
                    <div class="flex-1">
                        <div class="flex h-16 items-center justify-between px-4 sm:px-6 lg:px-8">
                            <!-- Logo -->
                            <router-link :to="{ path: '/' }">
                                <Logo variant="dark" class="w-44" :has-text="true" />
                            </router-link>
                        </div>
                    </div>

                    <div class="mx-auto max-w-sm px-4 py-8">
                        <h1 class="mb-6 text-3xl font-bold text-slate-800">
                            <div v-if="type === 'register'">
                                <span v-if="is_success">Account setup ✔️</span>
                                <span v-else>Setup your account</span>
                            </div>
                            <div v-else>
                                <span v-if="is_success">Password reset ✔️</span>
                                <span v-else>Reset your password</span>
                            </div>
                        </h1>

                        <div v-if="is_code_valid === false" class="">
                            <p v-if="type === 'register'">
                                This account setup link has expired. Please ask the Streamforge staff to provide a new
                                account setup link.
                            </p>
                            <p v-else>
                                This password reset request has expired. Please click
                                <router-link class="font-semibold text-indigo-500" :to="{ name: 'forgot-password' }"
                                    >here</router-link
                                >
                                to request a new password reset.
                            </p>
                        </div>

                        <div v-else-if="!is_success">
                            <Banner v-if="errorMessage" class="mb-6" type="error">
                                {{ errorMessage }}

                                <ul v-if="errorList && errorList.length" class="list-disc pl-5">
                                    <li v-for="error in errorList" :key="error">{{ error }}</li>
                                </ul>
                            </Banner>

                            <!-- Form -->
                            <form>
                                <div class="space-y-4">
                                    <div>
                                        <label class="mb-1 block text-sm font-medium" for="password">Password</label>
                                        <input
                                            id="password"
                                            v-model="password"
                                            class="form-input w-full"
                                            type="password"
                                            autoComplete="on"
                                        />
                                    </div>
                                    <div>
                                        <label class="mb-1 block text-sm font-medium" for="confirmPassword"
                                            >Confirm password</label
                                        >
                                        <input
                                            id="confirmPassword"
                                            v-model="confirmPassword"
                                            class="form-input w-full"
                                            type="password"
                                            autoComplete="on"
                                        />
                                    </div>
                                </div>
                                <div class="mt-6 flex items-center justify-end">
                                    <button
                                        class="btn bg-indigo-500 text-white disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 hover:bg-indigo-600"
                                        :class="{
                                            disabled: !password && !confirmPassword,
                                        }"
                                        :disabled="password && confirmPassword ? false : true"
                                        @click.prevent="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div v-else>
                            <p>
                                Click
                                <router-link class="font-semibold text-indigo-500" :to="{ name: 'login' }"
                                    >here</router-link
                                >
                                to log in with your new password.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Image -->
            <div class="absolute bottom-0 right-0 top-0 hidden md:block md:w-1/2" aria-hidden="true">
                <img
                    class="h-full w-full object-cover object-center"
                    src="@/images/auth-image.png"
                    width="760"
                    height="1024"
                    alt="Authentication"
                />
                <img
                    class="absolute left-0 top-1/4 ml-8 hidden -translate-x-1/2 transform lg:block"
                    src="@/images/auth-decoration.png"
                    width="218"
                    height="224"
                    alt="Authentication decoration"
                />
            </div>
        </div>
    </main>
</template>

<script>
import Banner from '@/components/BannerLight.vue'
import Logo from '@/components/Logo.vue'

export default {
    name: 'ResetPassword',

    components: {
        Banner,
        Logo,
    },

    data() {
        return {
            password: '',
            confirmPassword: '',
            is_code_valid: null,
            errorMessage: null,
            errorList: null,
            is_success: false,

            type: 'default',
            types: ['default', 'register'],
        }
    },

    created() {
        // First check that the provided code is valid
        this.checkCodeValidity()

        if (this.$route.query.type && this.types.includes(this.$route.query.type)) {
            this.type = this.$route.query.type
        }
    },

    methods: {
        submit() {
            this.$axios
                .post(`/operators/password`, {
                    code: this.$route.query.code,
                    newPassword: this.password,
                    confirmPassword: this.confirmPassword,
                    type: this.type === 'register' ? 'register' : 'forgot',
                })
                .then(() => {
                    this.is_success = true
                })
                .catch(({ response }) => {
                    if (response.data.message === 'This code has expired') {
                        this.is_code_valid = false
                    } else {
                        this.errorMessage = response.data.message
                        this.errorList = response.data.errors
                    }
                })
        },

        checkCodeValidity() {
            if (!this.$route.query.code) {
                this.is_code_valid = false
                return
            }

            this.$axios
                .post(`/operators/password/check`, {
                    code: this.$route.query.code,
                })
                .then(() => {
                    this.is_code_valid = true
                })
                .catch(() => {
                    this.is_code_valid = false
                })
        },
    },
}
</script>
