<template>
    <!-- Profile background -->
    <div
        class="relative h-56 bg-slate-200 bg-cover bg-center"
        :style="{ 'background-image': `url(${superview?.metadata?.shell?.banner_image_url})` }"
    />

    <!-- Content -->
    <div class="relative px-4 pb-8 sm:px-6">
        <!-- Pre-header -->
        <div class="-mt-16 mb-6 sm:mb-3">
            <div class="flex flex-col items-center sm:flex-row sm:items-end sm:justify-between">
                <!-- Avatar -->
                <div class="-ml-1 -mt-1 mb-4 inline-flex sm:mb-0">
                    <Avatar
                        v-if="superview.channel.logo"
                        class="border-2 border-white bg-slate-700 text-slate-50"
                        :src="superview.channel.logo"
                        :username="participant.platform_user_name"
                        :size="128"
                    />
                </div>

                <!-- Actions -->
                <div class="flex space-x-2 sm:mb-2">
                    <!--
                            You might be asking yourself, why the hell did Nick hard code each and every one
                            of these social icons that could have easily been achieved using a FOR loop. Well
                            my sweet child, for whatever reason Tailwind bg custom colors are not working when
                            I bind them to the class property. Tried everything I could think of, might be a bug
                            with Tailwind...
                        -->
                    <a
                        v-for="(socialUrl, socialKey) in superview?.metadata?.socials"
                        :key="socialKey"
                        class="btn-sm group text-slate-100 hover:opacity-80"
                        :class="`bg-${socialKey}`"
                        :href="socialUrl"
                        target="_BLANK"
                    >
                        <font-awesome-icon
                            :icon="['fab', 'instagram']"
                            class="h-4 w-4 shrink-0 fill-current group-hover:text-white"
                        />
                    </a>

                    <a
                        v-if="superview?.metadata?.socials?.twitter"
                        class="btn-sm group bg-twitter text-slate-100 hover:opacity-80"
                        :href="superview?.metadata?.socials?.twitter"
                        target="_BLANK"
                    >
                        <font-awesome-icon
                            :icon="['fab', 'twitter']"
                            class="h-4 w-4 shrink-0 fill-current group-hover:text-white"
                        />
                    </a>

                    <a
                        v-if="superview?.metadata?.socials?.tiktok"
                        class="btn-sm group bg-tiktok text-slate-100 hover:opacity-80"
                        :href="superview?.metadata?.socials?.tiktok"
                        target="_BLANK"
                    >
                        <font-awesome-icon
                            :icon="['fab', 'tiktok']"
                            class="h-4 w-4 shrink-0 fill-current group-hover:text-white"
                        />
                    </a>

                    <a
                        v-if="superview?.metadata?.socials?.youtube"
                        class="btn-sm group bg-youtube text-slate-100 hover:opacity-80"
                        :href="superview?.metadata?.socials?.youtube"
                        target="_BLANK"
                    >
                        <font-awesome-icon
                            :icon="['fab', 'youtube']"
                            class="h-4 w-4 shrink-0 fill-current group-hover:text-white"
                        />
                    </a>

                    <a
                        v-if="superview?.metadata?.socials?.twitch"
                        class="btn-sm group bg-twitch text-slate-100 hover:opacity-80"
                        :href="superview?.metadata?.socials?.twitch"
                        target="_BLANK"
                    >
                        <font-awesome-icon
                            :icon="['fab', 'twitch']"
                            class="h-4 w-4 shrink-0 fill-current group-hover:text-white"
                        />
                    </a>

                    <div class="ml-2 border-l border-slate-200" />

                    <div v-if="participant.platform_id === 'twitch'">
                        <a
                            class="btn-sm group relative overflow-hidden bg-slate-800 text-white"
                            :href="`https://streamforge.com/${participant.platform_id}/${participant.platform_user_name}`"
                            target="_BLANK"
                        >
                            <Logo
                                variant="light"
                                :has-text="false"
                                class="absolute -bottom-5 -left-5 z-10 mr-1 h-12 w-12 opacity-50 transition-all group-hover:h-16 group-hover:w-16 group-hover:opacity-90"
                            />
                            <span class="z-20 [text-shadow:0_4px_8px_rgba(0,0,0,1)]">Connect Page</span>
                            <font-awesome-icon
                                :icon="['fas', 'arrow-up-right-from-square']"
                                class="z-20 ml-1 h-3 w-3 shrink-0 fill-current text-slate-300 group-hover:text-white"
                            />
                        </a>
                    </div>

                    <div v-if="participant.platform_id === 'youtube'">
                        <Tooltip>
                            <template #trigger>
                                <a
                                    class="btn-sm group relative overflow-hidden bg-slate-800 text-white opacity-75"
                                    :href="null"
                                    target="_BLANK"
                                >
                                    <Logo
                                        variant="light"
                                        :has-text="false"
                                        class="absolute -bottom-5 -left-5 z-10 mr-1 h-12 w-12 opacity-50 transition-all group-hover:h-16 group-hover:w-16 group-hover:opacity-90"
                                    />
                                    <span class="z-20 [text-shadow:0_4px_8px_rgba(0,0,0,1)]">Connect Page</span>
                                    <font-awesome-icon
                                        :icon="['fas', 'arrow-up-right-from-square']"
                                        class="z-20 ml-1 h-3 w-3 shrink-0 fill-current text-slate-300 group-hover:text-white"
                                    />
                                </a>
                            </template>
                            <template #default>
                                <div class="whitespace-nowrap text-xs">
                                    Connect pages for YouTube aren't <br />ready quite yet.
                                </div>
                            </template>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>

        <!-- Header -->
        <header class="mb-6 text-center sm:text-left">
            <!-- Name -->
            <div class="mb-2 inline-flex items-start">
                <h1 class="text-2xl font-bold text-slate-800">{{ superview.channel.name }}</h1>
            </div>

            <!-- Bio -->
            <div class="mb-3 text-sm">{{ superview.channel.description }}</div>

            <!-- Meta -->
            <div class="flex flex-wrap justify-center space-x-4 sm:justify-start">
                <Tooltip v-if="participant.platform_id === 'twitch'" max-width="400">
                    <template #trigger>
                        <div class="flex items-center">
                            <font-awesome-icon :icon="['fas', 'language']" class="h-6 w-6 shrink-0" />
                            <span class="ml-2 whitespace-nowrap text-sm font-medium">{{
                                getLanguageFromCode(superview.channel.language)
                            }}</span>
                        </div>
                    </template>
                    <template #default>
                        <div class="whitespace-nowrap text-xs">
                            This data is specified by the creator on the platform.
                        </div>
                    </template>
                </Tooltip>

                <Tooltip v-if="superview?.metadata?.location" max-width="400">
                    <template #trigger>
                        <div class="flex items-center">
                            <svg class="h-4 w-4 shrink-0 fill-current text-slate-400" viewBox="0 0 16 16">
                                <path
                                    d="M8 8.992a2 2 0 1 1-.002-3.998A2 2 0 0 1 8 8.992Zm-.7 6.694c-.1-.1-4.2-3.696-4.2-3.796C1.7 10.69 1 8.892 1 6.994 1 3.097 4.1 0 8 0s7 3.097 7 6.994c0 1.898-.7 3.697-2.1 4.996-.1.1-4.1 3.696-4.2 3.796-.4.3-1 .3-1.4-.1Zm-2.7-4.995L8 13.688l3.4-2.997c1-1 1.6-2.198 1.6-3.597 0-2.798-2.2-4.996-5-4.996S3 4.196 3 6.994c0 1.399.6 2.698 1.6 3.697 0-.1 0-.1 0 0Z"
                                ></path>
                            </svg>
                            <span class="ml-2 whitespace-nowrap text-sm font-medium text-slate-500">
                                {{ superview?.metadata?.location }}
                            </span>
                        </div>
                    </template>
                    <template #default>
                        <div class="whitespace-nowrap text-xs">
                            This data is estimated by our algorithms and may be inaccurate.
                        </div>
                    </template>
                </Tooltip>
            </div>
        </header>

        <div class="mb-8">
            <div v-if="participant.platform_id === 'twitch'" class="grid grid-cols-10 gap-4">
                <div class="col-span-3 rounded-sm border border-slate-200 p-4 shadow-sm">
                    <h3 class="mb-1 text-sm uppercase text-slate-800">Avg Viewers</h3>
                    <div class="text-2xl font-bold text-slate-800">
                        {{ $filters.numeral(superview?.stream?.day_90?.aggregation?.viewers?.avg).format('0,0') }}
                    </div>
                </div>

                <div class="col-span-3 rounded-sm border border-slate-200 p-4 shadow-sm">
                    <h3 class="mb-1 text-sm uppercase text-slate-800">Peak Viewers</h3>
                    <div class="text-2xl font-bold text-slate-800">
                        {{
                            $filters.numeral(superview?.stream?.day_90?.aggregation?.viewers?.peak?.value).format('0,0')
                        }}
                    </div>
                </div>

                <!-- <div class="col-span-3 p-4 border border-slate-200 rounded-sm shadow-sm">
                    <h3 class="text-sm text-slate-800 uppercase mb-1">Affinity</h3>
                    <div class="text-2xl font-bold text-slate-800">
                        <span v-if="supercampaign.payload.is_demo === true">
                            {{ $filters.numeral(0.5 + Math.random() * 0.5).format('0,0.0%') }}
                        </span>
                        <span v-else-if="affinity?.combined?.percent">
                            {{ $filters.numeral(affinity?.combined?.percent).format('0,0.0%') }}
                        </span>
                        <span v-else>-</span>
                    </div>
                </div> -->

                <div class="col-span-3 rounded-sm border border-slate-200 p-4 shadow-sm">
                    <h3 class="mb-1 text-sm uppercase text-slate-800">Followers</h3>
                    <div class="text-2xl font-bold text-slate-800">
                        {{ $filters.numeral(superview?.channel?.followers).format('0,0') }}
                    </div>
                </div>

                <div class="col-span-1">
                    <div class="flex">
                        <div class="text-md flex w-full flex-col items-center">
                            <div
                                class="w-full rounded-tl-md rounded-tr-md bg-emerald-400 px-2 py-4 text-center text-3xl leading-none text-white"
                            >
                                A
                            </div>
                            <div
                                class="w-full rounded-bl-md rounded-br-md bg-emerald-300 px-3 py-2 text-center leading-none text-white"
                            >
                                80%
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="participant.platform_id === 'youtube'" class="grid grid-cols-12 gap-4">
                <div class="col-span-3 rounded-sm border border-slate-200 p-4 shadow-sm">
                    <h3 class="mb-1 text-sm uppercase text-slate-800">Avg Views per Video</h3>
                    <div class="text-2xl font-bold text-slate-800">
                        {{ $filters.numeral(superview?.custom?.avg_views).format('0,0') }}
                    </div>
                </div>

                <div class="col-span-3 rounded-sm border border-slate-200 p-4 shadow-sm">
                    <h3 class="mb-1 text-sm uppercase text-slate-800">Peak Video Views</h3>
                    <div class="text-2xl font-bold text-slate-800">
                        {{ $filters.numeral(superview?.custom?.peak_views).format('0,0') }}
                    </div>
                </div>

                <div class="col-span-3">
                    <div class="text-md flex items-center">
                        <div class="rounded-bl-md rounded-tl-md bg-emerald-400 px-2 py-1 leading-none text-white">
                            A
                        </div>
                        <div class="rounded-br-md rounded-tr-md bg-emerald-300 px-2 py-1 leading-none text-white">
                            80%
                        </div>
                    </div>
                </div>

                <div class="col-span-3 rounded-sm border border-slate-200 p-4 shadow-sm">
                    <h3 class="mb-1 text-sm uppercase text-slate-800">Subscribers</h3>
                    <div class="text-2xl font-bold text-slate-800">
                        {{ $filters.numeral(superview?.custom?.subscriber_count).format('0,0a') }}
                    </div>
                </div>
            </div>
        </div>

        <!-- Tabs -->
        <div class="relative mb-5">
            <div class="absolute bottom-0 h-px w-full bg-slate-200" aria-hidden="true"></div>
            <ul
                class="no-scrollbar relative -mx-4 flex flex-nowrap overflow-x-scroll text-sm font-medium sm:-mx-6 lg:-mx-8"
            >
                <li v-for="stage in stages" :key="stage" class="mr-6 lg:first:pl-8">
                    <a
                        class="block cursor-pointer whitespace-nowrap py-3"
                        :class="{
                            'border-b-2 border-indigo-500 text-indigo-500': activeTab === stage,
                        }"
                        @click="activeTab = stage"
                        >{{ $voca.capitalize(stage) }}</a
                    >
                </li>

                <li class="ml-8 mr-6">
                    <a
                        class="block cursor-pointer whitespace-nowrap py-3"
                        :class="{
                            'border-b-2 border-indigo-500 text-indigo-500': activeTab === 'deliverables',
                        }"
                        @click="activeTab = 'deliverables'"
                        >Deliverables</a
                    >
                </li>

                <li
                    class="ml-auto mr-6 flex items-center first:pl-4 last:mr-0 last:pr-4 sm:first:pl-6 sm:last:pr-6 lg:first:pl-8 lg:last:pr-8"
                >
                    <button v-if="isModal" class="btn btn-sm bg-rose-500 text-xs text-white hover:bg-rose-600">
                        Exit Creator
                    </button>
                </li>
            </ul>
        </div>

        <div v-if="activeTab === 'prospect'" class="grid gap-8 xl:grid-cols-12">
            <div class="col-span-12 md:col-span-8">
                <div>
                    <h2 class="mb-2 font-semibold text-slate-800">Base Deliverables</h2>
                    <div class="space-y-2 text-sm">
                        <p>Let's determine what we want the creator to do for the campaign.</p>
                    </div>

                    <!-- Cards -->
                    <div class="mt-5 grid gap-4 xl:grid-cols-2">
                        <!-- Card -->
                        <div class="group rounded-sm border border-slate-200 bg-white p-4">
                            <!-- Card header -->
                            <div class="flex grow items-center justify-between truncate">
                                <div class="flex items-center">
                                    <div
                                        class="mr-2 flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-slate-100"
                                    >
                                        <font-awesome-icon :icon="['fab', 'twitch']" class="h-4 w-4 text-twitch" />
                                    </div>
                                    <div class="truncate text-sm">
                                        <span class="font-medium text-slate-800">Twitch Stream</span>
                                        <span class="px-1.5 text-slate-400">·</span>
                                        <span>$2,400</span>
                                    </div>
                                </div>

                                <div>
                                    <font-awesome-icon
                                        :icon="['fas', 'pen-to-square']"
                                        class="h-4 w-4 cursor-pointer text-slate-500 opacity-0 transition duration-200 group-hover:opacity-100 hover:text-slate-600"
                                    />
                                </div>
                            </div>

                            <!-- Card content -->
                            <div class="ml-10 flex flex-col flex-nowrap text-xs">
                                <div>Duration · 2 hours</div>
                                <div>Publish at · Apr 4th, 2020 5:30PM EST</div>
                            </div>
                        </div>

                        <!-- Card -->
                        <div class="group rounded-sm border border-slate-200 bg-white p-4">
                            <!-- Card header -->
                            <div class="flex grow items-center justify-between truncate">
                                <div class="flex items-center">
                                    <div
                                        class="mr-2 flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-slate-100"
                                    >
                                        <font-awesome-icon :icon="['fab', 'youtube']" class="h-4 w-4 text-youtube" />
                                    </div>
                                    <div class="truncate text-sm">
                                        <span class="font-medium text-slate-800">YouTube Video</span>
                                        <span class="px-1.5 text-slate-400">·</span>
                                        <span>$3,600</span>
                                    </div>
                                </div>

                                <div>
                                    <font-awesome-icon
                                        :icon="['fas', 'pen-to-square']"
                                        class="h-4 w-4 cursor-pointer text-slate-500 opacity-0 transition duration-200 group-hover:opacity-100 hover:text-slate-600"
                                    />
                                </div>
                            </div>

                            <!-- Card content -->
                            <div class="ml-10 flex flex-col flex-nowrap text-xs">
                                <div>Duration · 2 hours</div>
                                <div>Publish at · Apr 4th, 2020 5:30PM EST</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-span-12 md:col-span-4">
                <h2 class="mb-2 font-semibold text-slate-800">Approval</h2>
                <div class="space-y-2 text-sm">
                    <p>Does this creator pass brand safety?</p>
                </div>

                <div class="mt-5 flex flex-col space-y-2 text-sm">
                    <div
                        class="group flex w-full items-center justify-between rounded-sm border border-slate-200 bg-white px-4 py-2"
                    >
                        <div class="flex items-center">
                            <div class="h-5 w-5 rounded-full bg-slate-200"></div>
                            <span class="ml-2">Xavier Constantin</span>
                        </div>
                        <font-awesome-icon :icon="['fas', 'check']" class="h-4 w-4 text-emerald-500" />
                    </div>
                    <div
                        class="group flex items-center justify-between rounded-sm border border-slate-200 bg-white px-4 py-2"
                    >
                        <div class="flex items-center">
                            <div class="h-5 w-5 rounded-full bg-slate-200"></div>
                            <span class="ml-2">David Boyd</span>
                        </div>
                        <font-awesome-icon :icon="['fas', 'check']" class="h-4 w-4 text-emerald-500" />
                    </div>
                </div>
            </div>

            <div class="col-span-12 md:col-span-8">
                <h2 class="mb-2 font-semibold text-slate-800">Affinity</h2>
                <div class="space-y-2 text-sm">
                    <p>How well does this creator fit with your targeting?</p>
                </div>

                <table class="mt-5 w-full table-auto">
                    <!-- Table header -->
                    <thead class="rounded-sm bg-slate-50 text-xs uppercase text-slate-400">
                        <tr>
                            <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left font-semibold">Game</div>
                            </th>
                            <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left font-semibold">Hours Streamed</div>
                            </th>
                            <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left font-semibold">Audience Watched</div>
                            </th>
                            <th class="whitespace-nowrap px-2 py-3 text-left first:pl-5 last:pr-5">
                                <div class="font-semibold">Affinity</div>
                            </th>
                        </tr>
                    </thead>

                    <tbody class="divide-y divide-slate-200 border-b border-slate-200 text-sm">
                        <tr class="group">
                            <td class="flex items-center whitespace-nowrap px-2 py-2 first:pl-5 last:pr-5">
                                <div
                                    class="h-8 w-16 shrink-0 rounded bg-slate-200 bg-cover bg-center"
                                    :style="{
                                        'background-image': `url(https://static-cdn.jtvnw.net/ttv-boxart/510590_IGDB-144x192.jpg)`,
                                    }"
                                />

                                <div class="text-md ml-3 font-semibold">Hades</div>
                            </td>
                            <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">2 hours</td>
                            <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">456 hours</td>
                            <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">
                                <div class="text-md flex items-center">
                                    <div
                                        class="rounded-bl-md rounded-tl-md bg-indigo-400 px-2 py-1 leading-none text-white"
                                    >
                                        S
                                    </div>
                                    <div
                                        class="rounded-br-md rounded-tr-md bg-indigo-300 px-2 py-1 leading-none text-white"
                                    >
                                        92%
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="group">
                            <td class="flex items-center whitespace-nowrap px-2 py-2 first:pl-5 last:pr-5">
                                <div
                                    class="h-8 w-16 shrink-0 rounded bg-slate-200 bg-cover bg-center"
                                    :style="{
                                        'background-image': `url(https://static-cdn.jtvnw.net/ttv-boxart/493597_IGDB-144x192.jpg)`,
                                    }"
                                />

                                <div class="text-md ml-3 font-semibold">New World</div>
                            </td>
                            <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">3 hours</td>
                            <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">122 hours</td>
                            <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">
                                <div class="text-md flex items-center">
                                    <div
                                        class="rounded-bl-md rounded-tl-md bg-emerald-400 px-2 py-1 leading-none text-white"
                                    >
                                        A
                                    </div>
                                    <div
                                        class="rounded-br-md rounded-tr-md bg-emerald-300 px-2 py-1 leading-none text-white"
                                    >
                                        80%
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="group">
                            <td class="flex items-center whitespace-nowrap px-2 py-2 first:pl-5 last:pr-5">
                                <div
                                    class="h-8 w-16 shrink-0 rounded bg-slate-200 bg-cover bg-center"
                                    :style="{
                                        'background-image': `url(https://static-cdn.jtvnw.net/ttv-boxart/491931_IGDB-144x192.jpg)`,
                                    }"
                                />

                                <div class="text-md ml-3 font-semibold">Escape from Tarkov</div>
                            </td>
                            <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">2 hours</td>
                            <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">55 hours</td>
                            <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">
                                <div class="text-md flex items-center">
                                    <div
                                        class="rounded-bl-md rounded-tl-md bg-amber-400 px-2 py-1 leading-none text-white"
                                    >
                                        B
                                    </div>
                                    <div
                                        class="rounded-br-md rounded-tr-md bg-amber-300 px-2 py-1 leading-none text-white"
                                    >
                                        40%
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="group">
                            <td class="flex items-center whitespace-nowrap px-2 py-2 first:pl-5 last:pr-5">
                                <div
                                    class="h-8 w-16 shrink-0 rounded bg-slate-200 bg-cover bg-center"
                                    :style="{
                                        'background-image': `url(https://static-cdn.jtvnw.net/ttv-boxart/71375_IGDB-144x192.jpg)`,
                                    }"
                                />

                                <div class="text-md ml-3 font-semibold">Star Citizen</div>
                            </td>
                            <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">1 hours</td>
                            <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">13 hours</td>
                            <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5">
                                <div class="text-md flex items-center">
                                    <div
                                        class="rounded-bl-md rounded-tl-md bg-rose-400 px-2 py-1 leading-none text-white"
                                    >
                                        D
                                    </div>
                                    <div
                                        class="rounded-br-md rounded-tr-md bg-rose-300 px-2 py-1 leading-none text-white"
                                    >
                                        0%
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-span-12 md:col-span-4">
                <h2 class="mb-2 font-semibold text-slate-800">Outreach</h2>
                <div class="space-y-2 text-sm">
                    <p>Let's inform the creator of this sponsorship opportunity.</p>
                    <button class="btn btn-sm bg-indigo-500 text-xs text-white hover:bg-indigo-600">
                        Send Outreach
                    </button>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'contracting'" class="flex flex-col divide-y">
            <h2 class="font-semibold text-slate-800">Contracting</h2>
            <template v-if="loadingStages === false">
                <Contracting
                    :supercampaign="supercampaign"
                    :campaign="campaign"
                    :participant-id="participant.id"
                    :participant="participant"
                    :superview="superview"
                    @contract-change="onContractChange"
                ></Contracting>
            </template>
        </div>

        <div v-if="activeTab === 'deliverables'" class="flex flex-col divide-y">
            <div class="grid gap-4 pt-5 md:grid-cols-12">
                <div class="col-span-12 md:col-span-4">
                    <h2 class="font-semibold text-slate-800">Deliverables</h2>
                    <div class="space-y-2 text-sm">
                        <p>Let's determine what we want the creator to do for the campaign.</p>

                        <div v-if="canChangeDeliverableType" class="mt-5 flex items-center">
                            <div class="form-switch">
                                <!-- eslint-disable vue/no-mutating-props -->
                                <input
                                    id="custom_tasks_switch"
                                    v-model="participant.stages.prospect.custom_deliverables"
                                    type="checkbox"
                                    class="sr-only"
                                    :disabled="loadingStages"
                                />
                                <!-- eslint-enable vue/no-mutating-props -->
                                <label class="bg-slate-400" for="custom_tasks_switch">
                                    <span class="bg-white shadow-sm" aria-hidden="true"></span>
                                    <span class="sr-only">Toggle</span>
                                </label>
                            </div>

                            <div
                                class="ml-2 text-sm"
                                :class="
                                    participant?.stages?.prospect?.custom_deliverables
                                        ? 'text-slate-500'
                                        : 'italic text-slate-400'
                                "
                            >
                                {{
                                    participant?.stages?.prospect?.custom_deliverables
                                        ? 'Using custom deliverables'
                                        : 'Using default deliverables'
                                }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-span-12 md:col-span-8">
                    <div class="grid gap-4 md:grid-cols-2">
                        <template
                            v-if="
                                canChangeDeliverableType &&
                                loadingStages === false &&
                                !participant?.stages?.prospect?.custom_deliverables
                            "
                        >
                            <Tasks
                                :platforms="enabledPlatforms"
                                :supercampaign-id="campaign.parent_id"
                                :campaign="campaign"
                                :allow-add="false"
                                :allow-edit="false"
                                :hide-empty-platform="true"
                                :show-empty-warning="true"
                            >
                            </Tasks>
                        </template>

                        <template
                            v-if="
                                !canChangeDeliverableType ||
                                (loadingStages === false && participant?.stages?.prospect?.custom_deliverables)
                            "
                        >
                            <Tasks
                                :platforms="enabledPlatforms"
                                :supercampaign-id="campaign.parent_id"
                                :campaign="campaign"
                                :participant-id="participant.id"
                            ></Tasks>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import Logo from '@/components/Logo.vue'
import Tooltip from '@/components/Tooltip.vue'
import Avatar from '@/components/Avatar.vue'
import Tasks from '@/partials/campaigns/Tasks.vue'
import Contracting from '@/partials/campaigns/Contracting.vue'
import participantStagesAPI from '@/utils/api/api.campaign.participant.stages'
import supercampaignAPI from '@/utils/api/api.supercampaign'

export default {
    name: 'ParticipantProfile',

    components: {
        Logo,
        Tooltip,
        Avatar,
        Tasks,
        Contracting,
    },

    props: {
        participant: {
            type: Object,
        },
        superview: {
            type: Object,
        },
        affinity: {
            type: Object,
        },
        campaign: {
            type: Object,
        },
        isModal: {
            type: Boolean,
        },
        defaultTab: {
            type: String,
        },
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'getLanguageFromCode', 'getColor']),

        canChangeDeliverableType() {
            return this.participant.stage === 'prospect' && this.participant.stageStatus === 'started'
        },
    },

    watch: {
        participant: {
            handler() {
                this.reset()
            },
        },

        'participant.stages.prospect.custom_deliverables': {
            handler() {
                if (!this.loadingStages && this.loadedParticipantId === this.participant.id) {
                    this.saveCustomDeliverables()
                }
            },
        },
    },

    async created() {
        this.reset()
    },

    data() {
        return {
            campaignId: null,

            enabledPlatforms: ['twitch', 'youtube'],

            stages: ['contracting'],

            activeTab: this.defaultTab !== null ? this.defaultTab : 'deliverables',

            supercampaign: null,

            loadedParticipantId: null,
            loadingStages: true,
        }
    },

    methods: {
        async reset() {
            this.loadingStages = true

            if (!this.participant.stages) {
                // eslint-disable-next-line vue/no-mutating-props
                this.participant.stages = {}
                // eslint-disable-next-line vue/no-mutating-props
                this.participant.stages.prospect = {}
                await this.resetStages()
            }

            if (this.supercampaign === null || this.campaign.parent_id !== this.supercampaign.id) {
                this.supercampaign = (
                    await supercampaignAPI.getSuperCampaign(this.$axios, this.campaign.parent_id)
                ).value
            }

            this.$nextTick(() => {
                this.loadedParticipantId = this.participant.id
                this.loadingStages = false
            })
        },

        async resetStages() {
            const stagesResp = await participantStagesAPI.getStages(this.$axios, this.participant.id)
            // eslint-disable-next-line vue/no-mutating-props
            this.participant.stages = stagesResp.value.allStages
        },

        saveCustomDeliverables: _.debounce(function () {
            return participantStagesAPI.updateProspectStage(this.$axios, this.participant.id, {
                custom_deliverables: this.participant.stages.prospect.custom_deliverables,
            })
        }, 1000),

        onContractChange() {
            this.resetStages()
        },
    },
}
</script>
