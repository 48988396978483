<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

import { Share, FileDown, FileUp, Plus } from 'lucide-vue-next'

import Tooltip from '@/components/Tooltip.vue'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

const store = useStore()
const { checkPermission } = store.getters

const open = ref(false)

const isExportEnabled = computed(() => checkPermission('feature.lists.export'))

const emit = defineEmits(['export-creators', 'import-creators', 'share', 'add-creators'])
</script>

<template>
    <div class="flex w-full flex-col items-start justify-between sm:flex-row sm:items-center">
        <DropdownMenu v-model:open="open">
            <DropdownMenuTrigger>
                <button
                    class="btn flex cursor-pointer overflow-hidden rounded border-slate-200 bg-slate-50 uppercase shadow-none hover:border-slate-300 hover:bg-slate-100 active:border-slate-300 active:bg-slate-100"
                >
                    <font-awesome-icon :icon="['fas', 'ellipsis']" class="h-4 w-4" />
                </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" class="w-[200px]">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuGroup>
                    <DropdownMenuItem @click="emit('add-creators')">
                        <Plus class="mr-2 h-4 w-4" />
                        Add creators
                    </DropdownMenuItem>
                    <DropdownMenuItem @click="emit('import-creators')">
                        <FileDown class="mr-2 h-4 w-4" />
                        Import list
                    </DropdownMenuItem>
                    <DropdownMenuItem v-if="isExportEnabled" @click="emit('export-creators')">
                        <FileUp class="mr-2 h-4 w-4" />
                        Export creators
                    </DropdownMenuItem>
                    <Tooltip v-else placement="left">
                        <template #trigger>
                            <DropdownMenuItem :disabled="!isExportEnabled" @click="emit('export-creators')">
                                <FileUp class="mr-2 h-4 w-4" />
                                Export creators
                            </DropdownMenuItem>
                        </template>
                        <template #default>
                            <div class="whitespace-normal text-xs">
                                Feature Unavailable - Account upgrade required for access to list exports.
                            </div>
                        </template>
                    </Tooltip>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem @click="emit('share')">
                        <Share class="mr-2 h-4 w-4" />
                        Share
                    </DropdownMenuItem>
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    </div>
</template>
