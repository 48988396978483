<template>
    <div class="flex h-screen flex-col">
        <!-- Content area -->
        <div class="my-auto">
            <div class="mx-auto w-full max-w-9xl px-4 py-8 sm:px-6 lg:px-8">
                <div class="m-auto max-w-2xl">
                    <div class="mb-16 px-4 text-center">
                        <div class="mb-8 inline-flex">
                            <img src="@/images/404-illustration.svg" width="176" height="176" alt="404 illustration" />
                        </div>
                        <div class="mb-6">Hmm...this page doesn’t exist. Try searching for something else!</div>

                        <router-link :to="{ path: '/' }" class="btn bg-indigo-500 text-white hover:bg-indigo-600"
                            >Back To Dashboard</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageNotFound',
    created() {
        console.log(`Just checking`)
    },
}
</script>
