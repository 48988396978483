<script setup>
const props = defineProps({
    link: {
        type: String,
    },
})
</script>

<template>
    <div class="flex">
        <div class="my-auto leading-none">
            <a
                class="block cursor-pointer rounded bg-slate-100 px-2 py-1 text-xs font-semibold uppercase leading-none transition duration-200 hover:bg-slate-200 hover:text-slate-600"
                :href="props.link"
                target="blank"
            >
                <font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']" class="h-3 w-3" />
            </a>
        </div>
    </div>
</template>
