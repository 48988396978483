<template>
    <!-- Modal backdrop -->
    <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-out duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-show="modalOpen"
            class="fixed inset-0 z-50 bg-slate-900 bg-opacity-60 transition-opacity"
            aria-hidden="true"
        ></div>
    </transition>

    <!-- Modal dialog -->
    <transition
        enter-active-class="transition ease-in-out duration-200"
        enter-from-class="opacity-0 translate-y-4"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in-out duration-200"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-4"
    >
        <div
            v-show="modalOpen"
            role="dialog"
            aria-modal="true"
            class="fixed inset-0 top-[12%] z-50 flex h-fit items-start justify-center px-4 will-change-auto sm:px-6 lg:top-[25%]"
        >
            <div ref="modalContent" class="no-scrollbar relative max-h-full w-full max-w-2xl rounded py-12 lg:px-10">
                <div class="absolute right-0 top-0" @click="onCloseClick">
                    <font-awesome-icon
                        class="h-5 w-5 cursor-pointer text-slate-200 hover:text-white"
                        :icon="['fas', 'xmark']"
                    />
                </div>

                <!-- Search form -->
                <div class="flex gap-2">
                    <DropdownPlatform
                        :selected-platform-id="search.platformId"
                        :platforms="search.platforms"
                        @on-select="onPlatformSelect"
                    />

                    <div class="relative flex grow">
                        <label class="sr-only">Search</label>

                        <input
                            ref="searchInput"
                            v-model="search.text"
                            class="mr-2 w-full appearance-none rounded border-0 py-2 pl-10 pr-4 text-sm placeholder-slate-400 placeholder:text-sm focus:ring-transparent"
                            type="search"
                            placeholder="Search creator…"
                            @keyup="debounceSearch"
                        />

                        <button class="group absolute inset-0 right-auto" disabled aria-label="Search">
                            <svg
                                class="ml-4 mr-2 h-4 w-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                                />
                                <path
                                    d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                                />
                            </svg>
                        </button>

                        <!-- Loading state on button -->
                        <Tooltip
                            :show-tooltip="
                                getLimitation('lists.size') !== null &&
                                currentListSize + $filters.lodash.size(selected.creators) > getLimitation('lists.size')
                            "
                            max-width="500"
                        >
                            <template #trigger>
                                <button
                                    class="btn shadow-none enabled:bg-indigo-500 enabled:text-white enabled:hover:bg-indigo-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                                    :disabled="
                                        !selected.creators ||
                                        !selected.creators.length ||
                                        (getLimitation('lists.size') !== null &&
                                            currentListSize + $filters.lodash.size(selected.creators) >
                                                getLimitation('lists.size'))
                                    "
                                    @click="submit"
                                >
                                    <span class="whitespace-nowrap">{{ actionText ? actionText : 'Add' }}</span>
                                    <span v-if="selected.creators && selected.creators.length" class="ml-1"
                                        >({{ $filters.lodash.size(selected.creators) }})</span
                                    >
                                </button>
                            </template>
                            <template #default>
                                <div class="whitespace-nowrap text-xs">
                                    {{
                                        getFromDictionary(`usage.lists.size`).replace(
                                            '[LIMIT]',
                                            getLimitation('lists.size'),
                                        )
                                    }}
                                </div>
                            </template>
                        </Tooltip>
                    </div>
                </div>

                <!--Results  -->
                <div v-if="results.isLoading" class="mt-2.5">
                    <SkeletonListSearch />
                </div>

                <div
                    v-if="!results.isLoading && results.creators && results.creators.length"
                    class="mt-2.5 max-h-[350px] overflow-auto rounded md:scrollbar md:scrollbar-thin md:scrollbar-track-transparent md:scrollbar-thumb-slate-300"
                >
                    <div class="flex flex-col divide-y divide-slate-200 rounded border-b border-slate-200 bg-white">
                        <div
                            v-for="creator in creatorList"
                            :key="creator.id"
                            class="group flex h-[50px] cursor-pointer px-4 py-2 text-sm transition duration-200 hover:bg-slate-100"
                            @click="onCreatorSelect(creator)"
                        >
                            <div class="grow">
                                <div class="flex">
                                    <div class="flex items-center">
                                        <div class="relative mr-2 shrink-0 sm:mr-3">
                                            <Avatar
                                                class="bg-slate-700 text-slate-50"
                                                :srcs="[creator.superview.normalized.avatar_url]"
                                                :username="creator.superview.normalized.name"
                                                :size="32"
                                            />

                                            <div
                                                class="absolute z-10 flex rounded-full"
                                                :class="
                                                    ['twitch'].includes(creator.superview.platform_id)
                                                        ? '-bottom-2 -right-1.5'
                                                        : '-bottom-1 -right-1'
                                                "
                                            >
                                                <PlatformIcon :platform-id="creator.superview.platform_id" />
                                            </div>
                                        </div>
                                        <div class="flex flex-col space-y-0.5">
                                            <span class="font-semibold leading-none text-slate-600">{{
                                                creator.superview.normalized.display_name
                                            }}</span>
                                            <span class="-my-1 text-xs leading-none text-slate-500">
                                                @{{ creator.superview.normalized.name }}
                                            </span>
                                        </div>

                                        <template v-if="creator.superview.normalized.followers > 0">
                                            <div class="mx-1.5 mb-[1px] leading-none">•</div>
                                            <span class="my-auto text-xs leading-none text-slate-600">
                                                {{
                                                    $filters.formatWholeNumberWithUnit(
                                                        creator.superview.normalized.followers,
                                                    )
                                                }}
                                                followers
                                            </span>
                                        </template>
                                    </div>
                                </div>
                            </div>

                            <div class="ml-1 flex px-2">
                                <div v-if="creator.is_selected === true" class="flex text-indigo-500">
                                    <span class="my-auto mr-2 text-xs font-medium">Selected</span>

                                    <font-awesome-icon :icon="['fas', 'check']" class="m-auto h-4 w-4" />
                                </div>

                                <font-awesome-icon
                                    v-else
                                    :icon="['fas', 'plus']"
                                    class="m-auto h-4 w-4 text-slate-400 transition duration-200 group-hover:text-slate-600"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="selected.creators && selected.creators.length" class="-mx-1 mt-1.5 flex flex-wrap">
                    <div
                        v-for="creator in selected.creators"
                        :key="creator.id"
                        class="m-1 inline-flex cursor-pointer select-none rounded-full bg-white px-2.5 py-1 text-center text-sm font-medium hover:bg-slate-100 hover:text-slate-700"
                        @click="onCreatorSelect(creator)"
                    >
                        <div class="flex">
                            <font-awesome-icon
                                :icon="['fab', creator.superview.platform_id]"
                                class="my-auto mr-2 h-3 w-3"
                                :class="`text-${creator.superview.platform_id}`"
                            />
                            <span>{{ creator.superview.normalized.name }}</span>
                        </div>

                        <font-awesome-icon :icon="['fas', 'xmark']" class="my-auto ml-1.5 h-3 w-3" />
                    </div>
                </div>
            </div>
        </div>
    </transition>

    <ModalBlank
        :modal-open="modals.close.isOpen"
        fg-classes="flex items-center my-4 justify-center"
        bg-classes="z-50 bg-opacity-75"
    >
        <div class="p-5">
            <div class="flex space-x-4">
                <!-- Icon -->
                <div class="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-indigo-100">
                    <svg class="h-4 w-4 shrink-0 fill-current text-indigo-500" viewBox="0 0 16 16">
                        <path
                            d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
                        />
                    </svg>
                </div>

                <!-- Content -->
                <div class="grow">
                    <!-- Modal header -->
                    <div class="mb-2">
                        <div class="text-lg font-semibold text-slate-800">Are you sure?</div>
                    </div>

                    <!-- Modal content -->
                    <div class="mb-10 text-sm">
                        <div class="space-y-2">
                            <p>This action will reset all channels you have selected.</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal footer -->
            <div class="flex flex-wrap justify-end space-x-2">
                <button
                    class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                    @click.stop="modals.close.isOpen = false"
                >
                    Cancel
                </button>
                <button class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600" @click.stop="onCloseModalConfirm">
                    Close &amp; Reset
                </button>
            </div>
        </div>
    </ModalBlank>
</template>

<script>
import _ from 'lodash'
import { ref, watch, nextTick } from 'vue'
import { mapGetters } from 'vuex'

import ModalBlank from '@/components/ModalBlank.vue'
import DropdownPlatform from '@/components/DropdownPlatform.vue'
import SkeletonListSearch from '@/partials/skeletons/SkeletonListSearch.vue'
import Avatar from '@/components/Avatar.vue'
import Tooltip from '@/components/Tooltip.vue'
import PlatformIcon from '@/components/PlatformIcon.vue'

export default {
    name: 'CreatorSearchModal',

    components: {
        ModalBlank,
        Avatar,
        DropdownPlatform,
        SkeletonListSearch,
        Tooltip,
        PlatformIcon,
    },

    props: ['modalOpen', 'actionText', 'defaultPlatformId', 'currentListSize'],

    emits: ['close-modal', 'submit'],

    setup(props) {
        const modalContent = ref(null)
        const searchInput = ref(null)

        watch(
            () => props.modalOpen,
            (open) => {
                if (open) {
                    nextTick(() => searchInput.value.focus())
                }
            },
        )

        return {
            modalContent,
            searchInput,
        }
    },

    data() {
        return {
            results: {
                creators: null,
                isLoading: false,
            },

            selected: {
                creators: [],
            },

            search: {
                text: '',
                platformId: 'youtube',

                platforms: [
                    {
                        id: 'youtube',
                        name: 'YouTube',
                    },
                    {
                        id: 'twitch',
                        name: 'Twitch',
                    },
                    {
                        id: 'tiktok',
                        name: 'TikTok',
                    },
                    {
                        id: 'twitter',
                        name: 'Twitter',
                    },
                    {
                        id: 'instagram',
                        name: 'Instagram',
                    },
                ],
            },

            modals: {
                close: {
                    isOpen: false,
                },
            },
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'getLimitation']),
        isInputValid() {
            if (!this.search.text) return false
            if (typeof this.search.text !== 'string') return false

            const trimmedSearchText = this.search.text.trim()

            if (!trimmedSearchText) return false
            if (trimmedSearchText.length < 2) return false

            return true
        },

        creatorList() {
            if (!this.results.creators) return []

            return _.map(this.results.creators, (creator) => {
                const isAlreadyAdded = _.find(
                    this.selected.creators,
                    (selectedCreator) =>
                        // eslint-disable-next-line no-underscore-dangle
                        creator.superview._id === selectedCreator.superview._id &&
                        creator.superview.platform_id === selectedCreator.superview.platform_id,
                )

                return {
                    superview: creator.superview,
                    is_selected: !!isAlreadyAdded,
                }
            })
        },
    },

    watch: {
        modalOpen(open) {
            if (open) {
                this.search.text = ''
                this.search.platformId = this.defaultPlatformId
            }
        },
    },

    methods: {
        debounceSearch: _.debounce(function () {
            nextTick(() => {
                if (this.isInputValid) {
                    this.getCreators({
                        searchText: this.search.text.trim(),
                    })
                } else {
                    this.results.creators = []
                    this.results.isLoading = false
                }
            })
        }, 500),

        onCreatorSelect(selectedCreator) {
            const matchingCreatorIndex = _.findIndex(
                this.selected.creators,
                (creator) =>
                    // eslint-disable-next-line no-underscore-dangle
                    creator.superview._id === selectedCreator.superview._id &&
                    creator.superview.platform_id === selectedCreator.superview.platform_id,
            )

            if (matchingCreatorIndex !== -1) {
                this.selected.creators.splice(matchingCreatorIndex, 1)
            } else {
                this.selected.creators.push(selectedCreator)
            }
        },

        onPlatformSelect(platformId) {
            this.results.creators = []
            this.search.text = ''
            this.results.isLoading = false
            this.search.platformId = platformId
        },

        resetState() {
            this.search.platformId = 'twitch'
            this.search.text = ''
            this.results.creators = []
            this.results.isLoading = false
            this.selected.creators = []
        },

        onCloseClick() {
            if (this.selected.creators.length > 0) {
                this.modals.close.isOpen = true
            } else {
                this.onCloseModalConfirm()
            }
        },

        onCloseModalConfirm() {
            this.modals.close.isOpen = false
            this.$emit('close-modal')
            this.resetState()
        },

        submit() {
            this.$emit('submit', this.selected.creators)
            this.resetState()
        },

        getCreators({ searchText }) {
            this.results.creators = []
            this.results.isLoading = true

            const endpoint = `/discover/${this.search.platformId}/name`

            const body = {
                name: searchText,
                platform: this.search.platformId,
            }

            this.$axios
                .post(endpoint, body)
                .then(({ data }) => {
                    this.results.creators = _.orderBy(
                        data.payload.creators,
                        [
                            (item) => {
                                const followers = _.get(item, 'superview.normalized.followers')
                                return followers || 0
                            },
                        ],
                        ['desc'],
                    )

                    _.forEach(this.results.creators, ({ superview }) => {
                        if (superview.platform_id === 'instagram') {
                            superview.normalized.avatar_url = `${
                                this.$axios.defaults.baseURL
                            }/avatars?url=${encodeURIComponent(superview.normalized.avatar_url)}`
                        }
                    })

                    this.results.isLoading = false
                })
                .catch((error) => {
                    this.$errorHandler(error, {
                        title: 'Creator Search',
                        message: 'Error while serching creators.',
                    })
                })
        },
    },
}
</script>
