import api from './api'

const apiorganization = {
    async performOrganizationAction(axios, organizationId, actionId, entityIds, options, entityType) {
        const body = {
            action: {
                id: actionId,
                entity: entityType,
                entity_ids: entityIds,
                options,
            },
        }

        const url = `/organizations/${organizationId}/action`

        return axios
            .post(url, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async validateOrganizationAction(axios, organizationId, actionId, entityIds, options, entityType) {
        const body = {
            action: {
                id: actionId,
                entity: entityType,
                entity_ids: entityIds,
                options,
            },
        }

        const url = `/organizations/${organizationId}/action/validate`

        return axios
            .post(url, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getOrganizationSettings(axios, organizationId) {
        const url = `/organizations/${organizationId}/settings`

        return axios
            .get(url)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async saveOrganizationSettings(axios, organizationId, updates) {
        const body = {
            settings: updates,
        }
        const url = `/organizations/${organizationId}/settings`

        return axios
            .post(url, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apiorganization
