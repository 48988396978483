<script setup>
const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
})
</script>

<template>
    <div class="flex">
        <div class="mr-3 flex text-2xl">
            <div class="m-auto w-8 text-center">{{ props.data.icon }}</div>
        </div>
        <div class="flex flex-col leading-none">
            <div class="font-semibold">
                {{
                    props.data.type === 'primary'
                        ? props.data.interest
                        : `${props.data.parent} -> ${props.data.interest}`
                }}
            </div>
            <!-- <div class="mt-0.5 text-xs capitalize">{{ props.data.type }} interest</div> -->
            <div class="mt-0.5 text-xs">{{ props.data.reason }}</div>
        </div>
    </div>
</template>
