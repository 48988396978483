<template>
    <div>
        <div
            v-if="isSfStaff || ['admin', 'superadmin'].includes(selfActiveOrganization?.organization_operator?.role)"
            class="flex-col divide-y rounded-sm border border-slate-100 border-slate-200 bg-white px-6 py-6"
        >
            <!-- Page Header -->
            <div class="flex justify-between pb-12 pt-6">
                <div>
                    <h2 class="text-1xl font-bold text-slate-800 md:text-2xl">Subscription Management</h2>
                    <div class="text-sm">
                        <p>Manage your organization's subscription.</p>
                    </div>
                </div>
            </div>

            <div
                v-if="!state.isSubscriptionsLoading && subscriptions.subscribed && subscriptions.subscribed.length > 0"
                class="grid grid-cols-12 gap-x-12 py-12"
            >
                <div class="col-span-12 md:col-span-4">
                    <p class="font-semibold text-slate-800">
                        {{ getFromDictionary(`subscriptions.current`, 'label') }}
                    </p>
                    <div class="space-y-2 text-sm">
                        <p>
                            {{ getFromDictionary(`subscriptions.current`, 'description') }}
                        </p>
                    </div>
                </div>

                <div class="col-span-12 flex md:col-span-8">
                    <SubscriptionsTable
                        :is-subscriptions-loading="state.isSubscriptionsLoading"
                        :subscriptions="subscriptions.subscribed"
                        subscription-type="current"
                        @manage-subscription="onManageSubscription"
                    />
                </div>
            </div>

            <div
                v-if="!state.isSubscriptionsLoading && subscriptions.available && subscriptions.available.length > 0"
                class="grid grid-cols-12 gap-x-12 py-12"
            >
                <div class="col-span-12 md:col-span-4">
                    <p class="font-semibold text-slate-800">
                        {{ getFromDictionary(`subscriptions.available`, 'label') }}
                    </p>
                    <div class="space-y-2 text-sm">
                        <p>
                            {{ getFromDictionary(`subscriptions.available`, 'description') }}
                        </p>
                    </div>
                </div>

                <div class="col-span-12 flex md:col-span-8">
                    <SubscriptionsTable
                        :is-subscriptions-loading="state.isSubscriptionsLoading"
                        :subscriptions="subscriptions.available"
                        subscription-type="available"
                        @purchase-subscription="onPurchaseSubscription"
                    />
                </div>
            </div>

            <div
                v-if="
                    !state.isSubscriptionsLoading &&
                    (!subscriptions.subscribed || subscriptions.subscribed.length === 0) &&
                    (!subscriptions.available || subscriptions.available.length === 0)
                "
                class="flex"
            >
                <div class="ml-auto mr-auto mt-6">
                    <p>No subscriptions currently available</p>
                </div>
            </div>

            <div v-if="state.isSubscriptionsLoading" class="flex">
                <div class="ml-auto mr-auto mt-6">
                    <p>Retrieving subscriptions...</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SubscriptionsTable from '../partials/tables/SubscriptionsTable.vue'
import subscriptionsAPI from '../utils/api/api.subscriptions'

export default {
    name: 'OrganizationSubscriptionsSubpage',

    components: {
        SubscriptionsTable,
    },

    props: {
        organization: {
            type: Object,
        },
    },

    data() {
        return {
            subscriptions: {
                available: [],
                subscribed: [],
            },

            state: {
                isSubscriptionsLoading: true,
            },
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'isSfStaff', 'selfActiveOrganization']),
    },

    created() {
        this.state.isSubscriptionsLoading = true
        this.getOrganizationSubscriptions()
    },

    methods: {
        async getOrganizationSubscriptions() {
            const availableResult = await subscriptionsAPI.getOrganizationAvailable(this.$axios)
            const subscribedResult = await subscriptionsAPI.getOrganizationSubscriptions(this.$axios)

            this.subscriptions.available = []
            this.subscriptions.subscribed = []

            if (availableResult.success) {
                this.subscriptions.available = availableResult.value
            }

            if (subscribedResult.success) {
                this.subscriptions.subscribed = subscribedResult.value
            }

            this.state.isSubscriptionsLoading = false
        },

        async onManageSubscription(subscription) {
            const manageUrl = await subscriptionsAPI.getManageUrl(this.$axios, subscription.provider)
            if (manageUrl.success && manageUrl.value) {
                window.location.href = manageUrl.value
            }
        },

        async onPurchaseSubscription(subscription) {
            const purchaseUrl = await subscriptionsAPI.getPurchaseUrl(this.$axios, subscription.id)
            if (purchaseUrl.success && purchaseUrl.value) {
                window.location.href = purchaseUrl.value
            }
        },
    },
}
</script>
