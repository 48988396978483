<template>
    <div class="flex items-center text-xs">
        <div
            class="flex h-[22px] rounded rounded-bl-md rounded-tl-md px-2 py-1 leading-none"
            :class="`${audienceReception.bgClass}`"
        >
            <span class="m-auto font-medium" :class="`${audienceReception.fgClass}`">
                {{ audienceReception.label }}
            </span>
        </div>
        <div
            v-if="isDevModeEnabled"
            class="flex h-[22px] rounded-br-md rounded-tr-md px-2 py-1 leading-none"
            :class="`${audienceReception.secondaryBgClass}`"
        >
            <span class="m-auto" :class="`${audienceReception.secondaryFgClass}`">
                {{ $filters.numeral(viewershipChange).format('+0,0%') }}
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'AudienceReceptionPlate',

    props: {
        viewershipChange: {
            type: Number,
        },
    },

    data() {
        return {
            audienceReception: {
                label: '',
                fgClass: '',
            },
        }
    },

    computed: {
        ...mapGetters(['isDevModeEnabled']),
    },

    watch: {
        viewershipChange(newValue, oldValue) {
            if (newValue === oldValue) return
            this.setAudienceReception(newValue)
        },
    },

    created() {
        this.setAudienceReception(this.viewershipChange)
    },

    methods: {
        setAudienceReception(changeDecimal) {
            this.audienceReception = this.getAudienceReception(changeDecimal)
        },

        getAudienceReception(changeDecimal) {
            // if (changeDecimal >= 0.50) {
            //     return {
            //         label: 'Extremely Positive',
            //         fgClass: 'text-emerald-600',
            //         secondaryFgClass: 'text-emerald-500',
            //         bgClass: 'bg-emerald-100',
            //         secondaryBgClass: 'bg-emerald-50',
            //     }
            // }

            if (changeDecimal >= 0.3) {
                return {
                    label: 'Very Positive',
                    fgClass: 'text-emerald-600',
                    secondaryFgClass: 'text-emerald-500',
                    bgClass: 'bg-emerald-100',
                    secondaryBgClass: 'bg-emerald-50',
                }
            }

            if (changeDecimal >= 0.1) {
                return {
                    label: 'Positive',
                    fgClass: 'text-emerald-600',
                    secondaryFgClass: 'text-emerald-500',
                    bgClass: 'bg-emerald-100',
                    secondaryBgClass: 'bg-emerald-50',
                }
            }

            // if (changeDecimal >= 0.10) {
            //     return {
            //         label: 'Slightly Positive',
            //         fgClass: 'text-emerald-600',
            //         secondaryFgClass: 'text-emerald-500',
            //         bgClass: 'bg-emerald-100',
            //         secondaryBgClass: 'bg-emerald-50',
            //     }
            // }

            // if (changeDecimal <= -0.50) {
            //     return {
            //         label: 'Extremely Negative',
            //         fgClass: 'text-rose-600',
            //         secondaryFgClass: 'text-rose-500',
            //         bgClass: 'bg-rose-100',
            //         secondaryBgClass: 'bg-rose-50',
            //     }
            // }

            // if (changeDecimal <= -0.30) {
            //     return {
            //         label: 'Very Negative',
            //         fgClass: 'text-rose-600',
            //         secondaryFgClass: 'text-rose-500',
            //         bgClass: 'bg-rose-100',
            //         secondaryBgClass: 'bg-rose-50',
            //     }
            // }

            if (changeDecimal <= -0.3) {
                return {
                    label: 'Negative',
                    fgClass: 'text-rose-600',
                    secondaryFgClass: 'text-rose-500',
                    bgClass: 'bg-rose-100',
                    secondaryBgClass: 'bg-rose-50',
                }
            }

            // if (changeDecimal <= -0.10) {
            //     return {
            //         label: 'Slightly Negative',
            //         fgClass: 'text-rose-600',
            //         secondaryFgClass: 'text-rose-500',
            //         bgClass: 'bg-rose-100',
            //         secondaryBgClass: 'bg-rose-50',
            //     }
            // }

            return {
                label: 'Neutral',
                fgClass: 'text-slate-500',
                secondaryFgClass: 'text-slate-400',
                bgClass: 'bg-slate-200',
                secondaryBgClass: 'bg-slate-100',
            }
        },
    },
}
</script>
