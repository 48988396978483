<template>
    <div class="relative inline-flex">
        <button
            ref="trigger"
            class="group inline-flex items-center justify-center"
            aria-haspopup="true"
            :aria-expanded="dropdownOpen"
            @click.prevent="dropdownOpen = !dropdownOpen"
        >
            <div class="flex h-8 w-8 rounded-full bg-slate-700" width="32" height="32">
                <!-- <font-awesome-icon
                fixed-width
                :icon="['fad', 'user']"
                class="shrink-0 h-4 w-4 m-auto"
                :pathClasses="['text-slate-400', 'text-slate-200']"
            /> -->

                <Avatar
                    v-if="self.operator"
                    class="h-4 w-4 shrink-0 text-slate-200"
                    :size="32"
                    :username="self.operator.first_name"
                />
            </div>

            <div class="hidden items-center truncate md:flex">
                <span v-if="self.operator" class="ml-2 truncate text-sm font-medium group-hover:text-slate-800">
                    {{ self.operator.first_name }} {{ self.operator.last_name }}
                </span>
                <svg class="ml-1 h-3 w-3 shrink-0 fill-current text-slate-400" viewBox="0 0 12 12">
                    <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                </svg>
            </div>
        </button>

        <transition
            enter-active-class="transition ease-out duration-200 transform"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="dropdownOpen"
                class="absolute top-full z-10 mt-1 min-w-44 origin-top-right overflow-hidden rounded border border-slate-200 bg-white py-1.5 shadow-lg"
                :class="align === 'right' ? 'right-0' : 'left-0'"
            >
                <div v-if="self.operator" class="mb-1 border-b border-slate-200 px-3 pb-2 pt-0.5">
                    <div class="font-medium text-slate-800">
                        {{ self.operator.first_name }} {{ self.operator.last_name }}
                    </div>

                    <div
                        v-if="selfActiveOrganization && selfActiveOrganization.organization_operator"
                        class="text-xs italic text-slate-500"
                    >
                        {{ $voca.capitalize(selfActiveOrganization.organization_operator.role) }}
                    </div>

                    <div
                        v-if="isSfStaffDefinitive === true"
                        class="mt-1"
                        @click.stop="$store.commit('self:toggleDevMode')"
                    >
                        <span
                            v-if="isDevModeEnabled"
                            class="cursor-pointer rounded bg-amber-100 px-3 py-1 text-xs text-amber-600 hover:bg-amber-200"
                        >
                            Dev Mode Enabled
                        </span>
                        <span
                            v-else
                            class="cursor-pointer rounded bg-indigo-100 px-3 py-1 text-xs text-indigo-600 hover:bg-indigo-200"
                        >
                            Dev Mode Disabled
                        </span>
                    </div>

                    <div
                        v-if="isSfStaffDefinitive === true"
                        class="mt-1"
                        @click.stop="$store.commit('self:toggleStaffMode')"
                    >
                        <span
                            v-if="!isSfStaffDisabled"
                            class="cursor-pointer rounded bg-amber-100 px-3 py-1 text-xs text-amber-600 hover:bg-amber-200"
                        >
                            Staff Mode Enabled
                        </span>
                        <span
                            v-else
                            class="cursor-pointer rounded bg-indigo-100 px-3 py-1 text-xs text-indigo-600 hover:bg-indigo-200"
                        >
                            Staff Mode Disabled
                        </span>
                    </div>
                </div>
                <ul ref="dropdown" @focusin="dropdownOpen = true" @focusout="dropdownOpen = false">
                    <!-- <li>
                    <router-link
                        class="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3"
                        to="/settings/account"
                        @click="dropdownOpen = false"
                    >Settings</router-link>
                </li> -->
                    <li v-if="selfActiveOrganization">
                        <router-link
                            class="flex items-center px-3 py-1 text-sm font-medium text-indigo-500 hover:text-indigo-600"
                            :to="{
                                name: 'organization-users',
                                params: { organization_id: selfActiveOrganization?.id },
                            }"
                            @click="dropdownOpen = false"
                            >Your Organization</router-link
                        >
                    </li>
                    <li>
                        <router-link
                            class="flex items-center px-3 py-1 text-sm font-medium text-indigo-500 hover:text-indigo-600"
                            :to="{ name: 'login' }"
                            @click="dropdownOpen = false"
                            >Sign Out</router-link
                        >
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import { mapGetters } from 'vuex'
import Avatar from '@/components/Avatar.vue'

export default {
    name: 'DropdownProfile',

    components: {
        Avatar,
    },
    props: ['align'],

    computed: {
        ...mapGetters([
            'self',
            'selfActiveOrganization',
            'isSfStaffDefinitive',
            'isDevModeEnabled',
            'isSfStaffDisabled',
        ]),
    },
    setup() {
        const dropdownOpen = ref(false)
        const trigger = ref(null)
        const dropdown = ref(null)

        // close on click outside
        const clickHandler = ({ target }) => {
            if (!dropdownOpen.value || dropdown.value.contains(target) || trigger.value.contains(target)) return
            dropdownOpen.value = false
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen.value || keyCode !== 27) return
            dropdownOpen.value = false
        }

        onMounted(() => {
            document.addEventListener('click', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        return {
            dropdownOpen,
            trigger,
            dropdown,
        }
    },
}
</script>
