<template>
    <div class="relative ml-auto mr-auto normal-case">
        <div
            ref="trigger"
            class="cursor-pointer p-2"
            :aria-expanded="isDropdownOpen"
            aria-label="Perform action"
            aria-haspopup="true"
            @click="onTriggerClick"
        >
            <div class="mb-0.5 flex">
                <font-awesome-icon :icon="['fas', 'chevron-down']" class="my-auto h-3 w-3" />
            </div>
        </div>

        <transition
            enter-active-class="transition ease-out duration-200 transform"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-if="isDropdownOpen"
                ref="dropdown"
                class="absolute right-0 top-full z-10 mt-1 rounded border border-slate-200 bg-white py-1.5"
                @focusin="isDropdownOpen = true"
                @focusout="isDropdownOpen = false"
            >
                <div v-if="messages && messages.length === 0" class="w-56 cursor-default p-3 font-medium">
                    <h4 class="text-lg font-semibold text-slate-600">No Messages Selected</h4>
                    <p>
                        There are no messages selected, use the check boxes on the left side of the table to perform
                        bulk actions on messages.
                    </p>
                </div>

                <div v-else-if="availableActions.length === 0" class="w-56 cursor-default p-3 font-medium">
                    <h4 class="text-lg font-semibold text-slate-600">No Actions</h4>
                    <p>
                        There are no actions that can be performed on all of the messages you selected based on the
                        stage they are each at.
                    </p>
                </div>

                <div v-else class="text-sm font-medium text-slate-600">
                    <button
                        v-for="action in availableActions"
                        :key="action.key"
                        class="flex w-full cursor-pointer items-center px-3 py-1.5 hover:bg-slate-50"
                        @click="onTriggerAction(action)"
                    >
                        <span :class="iconTypeToIcon?.[action.iconType]?.bgClass" class="rounded-sm p-1">
                            <font-awesome-icon
                                class="h-3 w-3"
                                :icon="iconTypeToIcon[action.iconType].icon"
                                :class="iconTypeToIcon[action.iconType].fgClass"
                            />
                        </span>
                        <span class="ml-2.5 whitespace-nowrap"
                            >{{ action.label }}
                            <template v-if="showCount === true">({{ messages.length }})</template>
                        </span>
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import _ from 'lodash'

export default {
    name: 'MessageAction',

    props: {
        actions: Array,
        messages: Array,
        showCount: Boolean,
    },

    emits: ['perform-action'],

    setup() {
        const isDropdownOpen = ref(false)
        const trigger = ref(null)
        const dropdown = ref(null)

        const clickHandler = ({ target }) => {
            if (!isDropdownOpen.value || dropdown?.value?.contains(target) || trigger?.value?.contains(target)) return
            isDropdownOpen.value = false
        }

        const keyHandler = ({ keyCode }) => {
            if (!isDropdownOpen.value || keyCode !== 27) return
            isDropdownOpen.value = false
        }

        onMounted(() => {
            document.addEventListener('click', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        return {
            isDropdownOpen,
            trigger,
            dropdown,
        }
    },

    data() {
        return {
            iconTypeToIcon: {
                check: {
                    icon: ['fas', 'check'],
                    bgClass: 'bg-emerald-100',
                    fgClass: 'text-emerald-600',
                },
                forward: {
                    icon: ['fas', 'arrow-right'],
                    bgClass: 'bg-emerald-100',
                    fgClass: 'text-emerald-600',
                },
                backward: {
                    icon: ['fas', 'arrow-left'],
                    bgClass: 'bg-amber-100',
                    fgClass: 'text-amber-600',
                },
                static: {
                    icon: ['fas', 'pen-to-square'],
                    bgClass: 'bg-slate-100',
                    fgClass: 'text-slate-600',
                },
                repeat: {
                    icon: ['fas', 'repeat'],
                    bgClass: 'bg-sky-100',
                    fgClass: 'text-sky-600',
                },
                exit: {
                    icon: ['fas', 'xmark'],
                    bgClass: 'bg-rose-100',
                    fgClass: 'text-rose-600',
                },
            },
        }
    },

    computed: {
        availableActions() {
            if (this.messages.length === 0) {
                return []
            }

            let allowedActions = this.filterActions(this.actions, this.messages[0])

            for (let index = 1; index < this.messages.length; index += 1) {
                if (allowedActions.length === 0) {
                    return []
                }

                allowedActions = this.filterActions(allowedActions, this.messages[index])
            }

            return allowedActions
        },
    },

    methods: {
        onTriggerAction(action) {
            this.isDropdownOpen = false
            this.$emit('perform-action', action, this.messages)
        },

        onTriggerClick() {
            this.isDropdownOpen = !this.isDropdownOpen
        },

        filterActions(actions, message) {
            return _.filter(actions, (action) => {
                return action.status.includes(message.status)
            })
        },
    },
}
</script>
