<template>
    <div class="relative">
        <button
            ref="trigger"
            class="w-full rounded border border-slate-300 bg-white px-3 py-4 hover:border-slate-400"
            aria-label="Lists"
            aria-haspopup="true"
            :aria-expanded="dropdownOpen"
            @click.prevent="dropdownOpen = !dropdownOpen"
        >
            <div class="flex flex-col text-left text-sm">
                <div class="flex">
                    <font-awesome-icon
                        :icon="['fab', platformId]"
                        class="my-auto mr-3 h-4 w-4 shrink-0"
                        :class="`text-${platformId}`"
                    />

                    <div class="flex flex-col">
                        <span class="line-clamp-1 font-semibold leading-none">{{
                            selectedSource?.value?.name ?? selectedSource?.name
                        }}</span>
                        <div class="text-xs leading-none mt-1 text-slate-600">
                            {{ $voca.capitalize(selectedSource.type) }}
                            <span
                                v-if="
                                    selectedSource.value &&
                                    selectedSource.value.totals &&
                                    selectedSource.value.totals[platformId]
                                "
                            >
                                ⸱
                                {{ $filters.formatWholeNumberWithUnit(selectedSource.value.totals[platformId]) }}
                                creators</span
                            >
                            <span v-else>
                                ⸱
                                {{ $filters.formatWholeNumberWithUnit(selectedSource.value.platform_creator_count) }}
                                creators
                            </span>
                        </div>
                    </div>

                    <font-awesome-icon
                        :icon="['fas', 'chevron-down']"
                        class="my-auto ml-auto mr-1 h-3.5 w-3.5 shrink-0"
                    />
                </div>

                <!-- <p class="text-xs text-left mt-2" v-if="selectedSource?.value?.description">
                    {{ selectedSource.value.description }}
                </p> -->
            </div>
        </button>

        <transition
            enter-active-class="transition ease-out duration-100 transform"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="dropdownOpen"
                class="absolute left-0 top-full z-50 mt-1 max-h-96 w-full overflow-y-auto rounded border border-slate-200 bg-white shadow-xl scrollbar scrollbar-track-slate-200 scrollbar-thumb-slate-400 scrollbar-w-2 scrollbar-h-2 hover:scrollbar-thumb-slate-500 active:scrollbar-thumb-slate-600"
            >
                <div ref="dropdown">
                    <div
                        class="sticky left-0 top-0 z-10 border-b border-slate-200 bg-slate-100 px-3 py-1.5 text-sm font-semibold"
                    >
                        Platform
                    </div>
                    <div
                        v-for="source in allSources.platform"
                        :key="source.id"
                        class="text-md flex cursor-pointer border-b border-slate-200 px-3 py-2.5 hover:bg-slate-50"
                        @click="onSelect('platform', source)"
                    >
                        <font-awesome-icon
                            :icon="source.icon"
                            class="my-auto mr-2 h-4 w-4 shrink-0"
                            :class="source.iconClass"
                        />

                        <div class="ml-1.5 flex flex-col">
                            <span class="line-clamp-1 block text-sm font-medium leading-none" :title="source.name">{{
                                source.name
                            }}</span>
                            <span class="block text-xs text-slate-600 leading-none mt-1"
                                >{{ $filters.formatWholeNumberWithUnit(source.platform_creator_count) }} creators</span
                            >
                        </div>
                    </div>

                    <div
                        class="sticky left-0 top-0 z-10 border-b border-slate-200 bg-slate-100 px-3 py-1.5 text-sm font-semibold"
                    >
                        Lists
                    </div>
                    <div
                        v-for="list in allSources.list"
                        :key="list.id"
                        class="flex cursor-pointer border-b border-slate-200 px-3 py-2.5 hover:bg-slate-50"
                        @click="onSelect('list', list)"
                    >
                        <font-awesome-icon
                            :icon="['fab', platformId]"
                            class="my-auto mr-2 h-4 w-4 shrink-0"
                            :class="`text-${platformId}`"
                        />

                        <div class="ml-1.5 flex flex-col">
                            <span class="line-clamp-1 block text-sm font-medium leading-none" :title="list.name">{{
                                list.name
                            }}</span>
                            <span class="block text-xs text-slate-600 mt-1 leading-none"
                                >{{ $filters.numeral(list.totals[`${platformId}`]).format('0,0') }} creators</span
                            >
                        </div>
                    </div>
                    <div v-if="!allSources?.list?.length" class="border-b border-slate-200 px-3 py-2 text-xs">
                        <p class="text-center">You have no lists</p>
                    </div>

                    <div
                        class="sticky left-0 top-0 z-10 border-b border-slate-200 bg-slate-100 px-3 py-1.5 text-sm font-semibold"
                    >
                        Campaigns
                    </div>
                    <div
                        v-for="campaign in allSources.campaign"
                        :key="campaign.id"
                        class="flex cursor-pointer border-b border-slate-200 px-3 py-2.5 hover:bg-slate-50"
                        @click="onSelect('campaign', campaign)"
                    >
                        <font-awesome-icon
                            :icon="['fab', platformId]"
                            class="my-auto mr-2 h-4 w-4 shrink-0"
                            :class="`text-${platformId}`"
                        />

                        <div class="ml-1.5 flex flex-col">
                            <span class="line-clamp-1 text-sm block font-medium leading-none" :title="campaign.title">{{
                                campaign.title
                            }}</span>
                            <span class="block text-xs text-slate-600 leading-none mt-1"
                                >{{ $filters.formatWholeNumberWithUnit(campaign.totals[`${platformId}`]) }} creators ⸱
                                {{ getFromDictionary(`campaign.type.${campaign.type}`, 'label') }}</span
                            >
                        </div>
                    </div>
                    <div v-if="!allSources?.campaign?.length" class="border-b border-slate-200 px-3 py-2 text-xs">
                        <p class="text-center">You have no campaigns</p>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ref, onMounted, onUnmounted } from 'vue'
import _ from 'lodash'

export default {
    name: 'DropdownDiscoverSource',

    props: {
        listsByPlatformId: {
            type: Object,
        },
        campaignsByPlatformId: {
            type: Object,
        },
        platformId: {
            type: String,
            default: 'twitch',
        },
        initialSelectedSource: {
            type: Object,
        },
        counts: {
            type: Object,
        },
    },

    emits: ['change'],

    data() {
        return {
            selectedSource: null,

            platforms: {
                twitch: {
                    icon: ['fab', 'twitch'],
                    iconClass: 'text-twitch',
                    name: 'Active Creators',
                    id: 'twitch',
                    description: 'You are searching on our database of creators on Twitch.',
                },
                youtube: {
                    icon: ['fab', 'youtube'],
                    iconClass: 'text-youtube',
                    name: 'Active Creators',
                    id: 'youtube',
                    description: 'You are searching on our database of creators on YouTube.',
                },
                tiktok: {
                    icon: ['fab', 'tiktok'],
                    iconClass: 'text-tiktok',
                    name: 'Active Creators',
                    id: 'tiktok',
                    description: 'You are searching on our database of creators on TikTok.',
                },
                twitter: {
                    icon: ['fab', 'twitter'],
                    iconClass: 'text-twitter',
                    name: 'Active Creators',
                    id: 'twitter',
                    description: 'You are searching on our database of creators on Twitter.',
                },
                instagram: {
                    icon: ['fab', 'instagram'],
                    iconClass: 'text-instagram',
                    name: 'Active Creators',
                    id: 'instagram',
                    description: 'You are searching on our database of creators on Instagram.',
                },
            },
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary']),

        allSources() {
            return {
                platform: _.filter(this.platforms, (platform) => platform.id === this.platformId),
                list: this.listsByPlatformId?.[this.platformId] ?? [],
                campaign: _.orderBy(this.campaignsByPlatformId?.[this.platformId], ['started_at'], ['desc']) ?? [],
            }
        },
    },

    watch: {
        platformId(newValue, oldValue) {
            if (newValue !== oldValue) this.onSelect('platform', this.platforms[newValue], true)
        },

        listsByPlatformId: {
            handler() {
                const matchingSource = _.find(
                    this.allSources[this.initialSelectedSource.type],
                    (item) => item.id === this.initialSelectedSource.input,
                )

                if (matchingSource) {
                    this.selectedSource = _.assign(_.clone(this.initialSelectedSource), {
                        value: matchingSource,
                    })
                }
            },
            deep: true,
        },

        counts(newCounts) {
            _.forEach(newCounts?.discovery, (count, platformId) => {
                this.platforms[platformId].platform_creator_count = count
            })
        },
    },

    created() {
        this.onSelect('platform', this.platforms[this.platformId], true)
    },

    methods: {
        onSelect(type, source, isInitial = false) {
            this.selectedSource = {
                type,
                value: source,
                name: '',
            }

            if (['platform', 'list'].includes(type)) {
                this.selectedSource.name = source.name
            } else if (['campaign'].includes(type)) {
                this.selectedSource.name = source.title
            }

            // We don't want to trigger upstream affects when initializing
            if (!isInitial) {
                this.$emit('change', this.selectedSource)
                this.dropdownOpen = false
            }
        },
    },

    setup() {
        const dropdownOpen = ref(false)
        const dropdown = ref(null)
        const trigger = ref(null)

        // close on click outside
        const clickHandler = ({ target }) => {
            if (!dropdownOpen.value || dropdown.value.contains(target) || trigger.value.contains(target)) return
            dropdownOpen.value = false
        }

        // close if the esc key is pressed
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen.value || keyCode !== 27) return
            dropdownOpen.value = false
        }

        onMounted(() => {
            document.addEventListener('click', clickHandler)
            document.addEventListener('keydown', keyHandler)
        })

        onUnmounted(() => {
            document.removeEventListener('click', clickHandler)
            document.removeEventListener('keydown', keyHandler)
        })

        return {
            dropdownOpen,
            dropdown,
            trigger,
        }
    },
}
</script>
