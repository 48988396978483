import api from './api'

const apicampaignsettings = {
    async getCampaignSettings(axios, campaignId) {
        return axios
            .get(`/campaigns/${campaignId}/settings/all`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getCampaignStageSettings(axios, campaignId, stage) {
        return axios
            .get(`/campaigns/${campaignId}/settings/${stage}`)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async saveCampaignSettings(axios, campaignId, settings) {
        const body = {
            settings,
        }

        return axios
            .put(`/campaigns/${campaignId}/settings`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async saveCampaignStageSettings(axios, campaignId, stageId, stageSettings) {
        const body = {
            settings: stageSettings,
        }

        return axios
            .put(`/campaigns/${campaignId}/settings/${stageId}`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apicampaignsettings
