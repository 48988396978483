<template>
    <div class="mb-8 w-full md:mb-0 md:w-64">
        <div class="no-scrollbar md:sticky md:top-16 md:h-[calc(100vh-64px)] md:overflow-y-auto md:overflow-x-hidden">
            <div>
                <!-- Title -->
                <header class="mb-6">
                    <h1 class="mb-1 text-2xl font-bold text-slate-800 md:text-3xl">{{ game.name }}</h1>
                    <!-- <div class="text-xs inline-flex font-medium bg-slate-700 text-slate-100 rounded-full text-center px-2.5 py-1">Stage</div> -->
                    <p class="text-sm font-medium">Created {{ creationDate }}</p>
                </header>

                <!-- Links -->
                <div
                    class="no-scrollbar -mx-4 flex flex-nowrap overflow-x-scroll px-4 md:block md:space-y-3 md:overflow-auto"
                >
                    <!-- Group 1 -->
                    <!--
                <div>
                    <div class="text-xs font-semibold text-slate-400 uppercase mb-3 md:sr-only">Menu</div>
                    <ul class="flex flex-nowrap md:block mr-3 md:mr-0">

                        <router-link
                            :to="{
                                name: 'game-feed',
                            }"
                            custom
                            v-slot="{ href, navigate, isExactActive }"
                        >
                            <li class="mr-0.5 md:mr-0 md:mb-0.5">
                                <a class="flex items-center px-2.5 py-2 rounded whitespace-nowrap" :class="isExactActive && 'bg-white'" :href="href" @click="navigate">
                                    <svg class="w-4 h-4 shrink-0 fill-current mr-2" :class="isExactActive ? 'text-indigo-500' : 'text-slate-400'" viewBox="0 0 16 16">
                                        <path
                                            d="M10 16h4c.6 0 1-.4 1-.998V6.016c0-.3-.1-.6-.4-.8L8.6.226c-.4-.3-.9-.3-1.3 0l-6 4.992c-.2.2-.3.5-.3.799v8.986C1 15.6 1.4 16 2 16h4c.6 0 1-.4 1-.998v-2.996h2v2.996c0 .599.4.998 1 .998Zm-4-5.99c-.6 0-1 .399-1 .998v2.995H3V6.515L8 2.32l5 4.194v7.488h-2v-2.995c0-.6-.4-.999-1-.999H6Z" />
                                    </svg>
                                    <span class="text-sm font-medium" :class="isExactActive ? 'text-indigo-500' : 'text-slate-600'">Feed</span>
                                </a>
                            </li>
                        </router-link>

                        <router-link
                            :to="{
                                name: 'game-keys',
                            }"
                            custom
                            v-slot="{ href, navigate, isExactActive }"
                        >
                            <li class="mr-0.5 md:mr-0 md:mb-0.5">
                                <a class="flex items-center px-2.5 py-2 rounded whitespace-nowrap" :class="isExactActive && 'bg-white'" :href="href" @click="navigate">
                                    <font-awesome-icon
                                        fixed-width
                                        :icon="['fas', 'key']"
                                        class="w-4 h-4 shrink-0 fill-current mr-2"
                                        :class="isExactActive ? 'text-indigo-400' : 'text-slate-400'"
                                    />
                                    <span class="text-sm font-medium" :class="isExactActive ? 'text-indigo-500' : 'text-slate-600'">Keys</span>
                                </a>
                            </li>
                        </router-link>

                    </ul>
                </div>
                !-->
                </div>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'GameSidebar',

    props: {
        game: {
            type: Object,
        },
    },

    computed: {
        ...mapGetters(['getFromDictionary']),
        creationDate() {
            return moment(this.game.creation_at).format('MMMM Do YYYY')
        },
    },
}
</script>
