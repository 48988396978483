<template>
    <div
        class="col-span-full overflow-hidden rounded border border-slate-200 bg-white shadow-lg @container sm:col-span-6 xl:col-span-6"
    >
        <div class="flex h-full flex-col @sm:flex-row">
            <router-link
                class="relative block h-48 w-full shrink-0 bg-slate-600 @sm:h-auto @sm:w-40"
                :to="{
                    name: 'supercampaign-home',
                    params: {
                        supercampaign_id: supercampaign.id,
                    },
                }"
            >
                <img
                    v-if="supercampaign.game"
                    class="absolute h-full w-full object-cover object-center"
                    :src="`https://static-cdn.jtvnw.net/ttv-boxart/${supercampaign.game.twitch_id}_IGDB-288x384.jpg`"
                />

                <img
                    v-if="supercampaign?.payload?.cover_url"
                    class="absolute h-full w-full object-cover object-center"
                    :src="supercampaign.payload.cover_url"
                />

                <div v-else class="flex h-full">
                    <p class="m-auto font-medium text-slate-100">No Box Art</p>
                </div>
            </router-link>

            <div class="flex grow flex-col px-5 pb-4 pt-5 @container">
                <div class="grow">
                    <div class="mb-0.5 flex items-start justify-between">
                        <router-link
                            class="inline-flex text-slate-800 hover:text-slate-900"
                            :to="{
                                name: 'supercampaign-home',
                                params: {
                                    supercampaign_id: supercampaign.id,
                                },
                            }"
                        >
                            <h2 class="line-clamp-2 text-xl font-semibold leading-snug">
                                <span v-if="supercampaign.game">{{ supercampaign.game.name }}</span>
                                <span v-else-if="supercampaign.intake_form && supercampaign.intake_form.game_name">{{
                                    supercampaign.intake_form.game_name
                                }}</span>
                                <span v-else>{{ supercampaign.title }}</span>
                            </h2>
                        </router-link>
                        <div class="-ml-1 flex whitespace-nowrap">
                            <div
                                class="inline-flex rounded-full px-2.5 py-1 text-center text-xs font-medium"
                                :class="typeColor(supercampaign.stage)"
                            >
                                {{ supercampaign.stage }}
                            </div>
                        </div>
                    </div>

                    <div
                        class="flex flex-col-reverse items-start space-y-2 space-y-reverse @sm:flex-row @sm:items-center @sm:space-y-0"
                    >
                        <div class="mr-3.5 flex">
                            <font-awesome-icon
                                v-if="dateRange.started_at"
                                fixed-width
                                :icon="['fad', 'calendar']"
                                class="m-auto mr-1.5 h-4 w-4 shrink-0"
                                :path-classes="['text-slate-400', 'text-slate-600']"
                            />
                            <div v-if="dateRange.started_at" class="text-sm font-medium text-slate-500">
                                {{ $filters.moment(dateRange.started_at).format('MMM Do, YYYY') }}
                            </div>
                        </div>
                        <div class="mr-3.5 flex">
                            <font-awesome-icon
                                v-if="participantCount"
                                fixed-width
                                :icon="['fad', participantCount === 1 ? 'user' : 'users']"
                                class="m-auto mr-1.5 h-4 w-4 shrink-0"
                                :path-classes="['text-slate-400', 'text-slate-600']"
                            />
                            <div v-if="participantCount" class="text-sm font-medium text-slate-500">
                                {{ $numeral(participantCount).format('0,0') }}
                                {{ $pluralize('creator', participantCount) }}
                            </div>
                        </div>
                    </div>

                    <div v-if="supercampaign.description" class="mt-2 line-clamp-3 text-sm">
                        {{ supercampaign.description }}
                    </div>

                    <div v-else class="mt-2">
                        <div class="h-3 w-full rounded bg-slate-200" />
                        <div class="mt-2 h-3 w-3/4 rounded bg-slate-200" />
                        <div class="mt-2 h-3 w-1/2 rounded bg-slate-200" />
                    </div>
                </div>

                <footer class="mt-5 space-y-2">
                    <div class="flex items-end justify-between @sm:items-center">
                        <div
                            class="flex flex-col-reverse items-start space-y-2 space-y-reverse @sm:flex-row @sm:items-center @sm:space-y-0"
                        >
                            <div class="-mx-0.5">
                                <div
                                    v-for="campaign in $lodash.orderBy(supercampaign.campaigns, ['type'], ['asc'])"
                                    :key="campaign.id"
                                    class="mb-1 mr-1 inline-flex items-center overflow-hidden rounded-full bg-slate-100 text-xs font-medium tracking-tight text-slate-500"
                                >
                                    <div class="h-full p-1.5 @sm:rounded-full @sm:bg-slate-200 @sm:py-1">
                                        <font-awesome-icon
                                            :icon="getFromDictionary(`campaign.type.${campaign.type}`, 'icon')"
                                            class="h-3 w-3"
                                        />
                                    </div>
                                    <div class="hidden @sm:block @sm:pl-1 @sm:pr-2">
                                        {{ getFromDictionary(`campaign.type.${campaign.type}`, 'label') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-1">
                            <router-link
                                class="whitespace-nowrap text-sm font-medium text-indigo-500 hover:text-indigo-600"
                                :to="{
                                    name: 'supercampaign-home',
                                    params: {
                                        supercampaign_id: supercampaign.id,
                                    },
                                }"
                                >View -&gt;</router-link
                            >
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
    name: 'SupercampaignCard',

    props: ['supercampaign'],

    computed: {
        ...mapGetters(['getFromDictionary', 'findFromDictionary']),

        dateRange() {
            if (!this.supercampaign || !this.supercampaign.campaigns || !this.supercampaign.campaigns.length) {
                return {
                    started_at: null,
                    ended_at: null,
                }
            }

            // Find the earliest, and latest start/end dates amongst all the campaigns within the supercampaign
            return _.reduce(
                this.supercampaign.campaigns,
                (result, campaign) => {
                    if (!result.started_at || moment.utc(campaign.started_at).isBefore(moment.utc(result.started_at))) {
                        // eslint-disable-next-line no-param-reassign
                        result.started_at = campaign.started_at
                    }

                    if (!result.ended_at || moment.utc(campaign.ended_at).isAfter(moment.utc(result.ended_at))) {
                        // eslint-disable-next-line no-param-reassign
                        result.ended_at = campaign.ended_at
                    }

                    return result
                },
                {
                    started_at: null,
                    ended_at: null,
                },
            )
        },

        participantCount() {
            if (!this.supercampaign?.campaigns) return 0

            const countedParticipants = _.reduce(
                this.supercampaign.campaigns,
                (results, { participants }) => {
                    _.forEach(participants, (participant) => {
                        if (['contracting', 'preparation', 'delivery'].includes(participant.stage)) {
                            results.push(participant)
                        }
                    })

                    return results
                },
                [],
            )

            return _.size(countedParticipants)
        },
    },

    methods: {
        typeColor(type) {
            switch (type) {
                case 'Release':
                    return 'bg-indigo-100 text-indigo-600'
                case 'DLC':
                    return 'bg-violet-100 text-violet-600'
                case 'Closed Alpha':
                    return 'bg-pink-100 text-pink-600'
                case 'Closed Beta':
                    return 'bg-purple-100 text-purple-600'
                case 'Open Beta':
                    return 'bg-amber-100 text-amber-600'
                case 'Early Access':
                    return 'bg-teal-100 text-teal-600'
                case 'Major Patch':
                    return 'bg-cyan-100 text-cyan-600'
                case 'Pre-Release':
                    return 'bg-fuchsia-100 text-fuchsia-600'
                case 'Post Release':
                    return 'bg-sky-100 text-sky-600'
                case 'Platform Release':
                    return 'bg-orange-100 text-orange-600'
                default:
                    return 'bg-slate-100 text-slate-500'
            }
        },
    },
}
</script>
