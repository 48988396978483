<template>
    <Inquiries :campaign-id="getCampaignId"></Inquiries>
</template>

<script>
import { mapGetters } from 'vuex'
import Inquiries from '@/partials/tables/InquiriesTable.vue'

export default {
    name: 'CampaignOutreachSubpage',

    components: {
        Inquiries,
    },

    props: {
        supercampaign: Object,
        campaignsById: Object,
    },

    data() {
        return {}
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'isSfStaff', 'modals']),

        getCampaignId() {
            return this.$route.params.campaign_id
        },
    },
}
</script>
