<script setup>
const props = defineProps({
    socialLinks: {
        type: Array,
    },
})

const getBackgroundColor = (platformId) => {
    switch (platformId) {
        case 'kick':
            return 'bg-[#53fc18]'
        default:
            return `bg-${platformId}`
    }
}

const getTextColor = (platformId) => {
    switch (platformId) {
        case 'kick':
            return 'text-black'
        case 'patreon':
            return 'text-slate-950'
        default:
            return `text-slate-100`
    }
}
</script>

<template>
    <a
        v-for="socialLink in props.socialLinks"
        :key="socialLink._id"
        :class="`
            btn-sm group relative h-[30px]
            ${getBackgroundColor(socialLink.tpid)}
            ${getTextColor(socialLink.tpid)}
            hover:opacity-80
        `"
        :href="socialLink.link"
        target="_blank"
    >
        <div class="group-hover:opacity-0">
            <font-awesome-icon
                v-if="socialLink.tpid !== 'kick'"
                :icon="['fab', socialLink.tpid === 'twitter' ? 'twitter' : socialLink.tpid]"
                class="h-4 w-4 shrink-0 fill-current group-hover:text-white"
            />
            <div v-else class="font-bold">
                <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
                    <path
                        d="M3 2h3.75v3H8V3.714h1.25V2H13v3.857h-1.25v1.286H10.5v1.714h1.25v1.286H13V14H9.25v-1.714H8V11H6.75v3H3V2z"
                    ></path>
                </svg>
            </div>
        </div>
        <div class="absolute opacity-0 group-hover:opacity-100">
            <font-awesome-icon
                :icon="['fas', 'arrow-up-right-from-square']"
                :class="`h-3 w-3 shrink-0 fill-current text-slate-300 group-hover:text-white`"
            />
        </div>
    </a>
</template>
