<template>
    <div class="divide-y">
        <div v-for="index in 10" :key="index">
            <div class="whitespace-nowrap px-3 py-2">
                <div class="flex items-center">
                    <div class="shrink-0">
                        <div class="h-10 w-16 animate-pulse rounded bg-slate-200" />
                    </div>
                    <div class="my-auto ml-3 w-full flex-col space-y-2">
                        <div class="h-3 w-full animate-pulse rounded bg-slate-200" />
                        <div class="h-3 w-1/2 animate-pulse rounded bg-slate-200" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkeletonGameSearch',
}
</script>
