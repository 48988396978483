<script setup>
import { cn } from '@/lib/utils'

const props = defineProps({
    class: { type: null, required: false },
})
</script>

<template>
    <thead :class="cn('rounded-sm bg-slate-50 text-xs uppercase text-slate-400 *:border-none', props.class)">
        <slot />
    </thead>
</template>
