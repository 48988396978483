<script setup>
import _ from 'lodash'
import { ref, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { axios } from '@/utils/axios'
import AffinityPlate from '@/components/plates/AffinityPlate.vue'
import Tooltip from '@/components/Tooltip.vue'

const store = useStore()

const props = defineProps({
    platformId: {
        type: String,
        required: true,
    },
    platformUserId: {
        type: String,
        required: true,
    },
    superview: {
        type: Object,
        required: true,
    },
})

const isDemographicsLoaded = ref(false)
const demographics = ref(null)
const conversionDemographics = ref(null)
const isInsightsLoaded = ref(false)
const insights = ref(null)

const demographicsLoadProgress = ref(0)
const insightsLoadProgress = ref(0)
let demographicsProgressInterval
let insightsProgressInterval

const incrementProgress = (progressRef) => {
    progressRef.value = Math.min(progressRef.value + 100 / 90, 100) // Increment by 1.67% every second (100% / 60 seconds), capped at 100%

    if (progressRef.value >= 100) {
        clearInterval(
            progressRef === demographicsLoadProgress.value ? demographicsProgressInterval : insightsProgressInterval,
        )
    }
}

const startProgressBar = (progressRef, intervalRef) => {
    progressRef.value = 0
    intervalRef = setInterval(() => incrementProgress(progressRef), 1000)
    return intervalRef
}

const stopProgressBar = (progressRef, intervalRef) => {
    clearInterval(intervalRef)
    progressRef.value = 100
}

onMounted(() => {
    demographicsProgressInterval = startProgressBar(demographicsLoadProgress, demographicsProgressInterval)
    insightsProgressInterval = startProgressBar(insightsLoadProgress, insightsProgressInterval)
})

onUnmounted(() => {
    clearInterval(demographicsProgressInterval)
    clearInterval(insightsProgressInterval)
})

const fetchConversionDemographics = () => {
    axios
        .get(`/platforms/${props.platformId}/${props.platformUserId}/demographics`)
        .then(({ data }) => {
            conversionDemographics.value = data.payload.analysis

            if (conversionDemographics.value?.byOperatingSystem) {
                conversionDemographics.value.byOperatingSystem = _.map(
                    conversionDemographics.value.byOperatingSystem,
                    (item) => {
                        if (item.key === 'macos') item.label = 'MacOS'
                        if (item.key === 'ios') item.label = 'iOS'
                        if (item.key === 'unknown') item.label = 'Unknown'
                        return item
                    },
                )
            }
        })
        .catch((error) => {
            console.error(`Error fetching related creators`, error)
        })
}

const fetchInsights = (isRefresh = false) => {
    insights.value = null
    isInsightsLoaded.value = false
    insightsProgressInterval = startProgressBar(insightsLoadProgress, insightsProgressInterval)

    return axios
        .get(`/platforms/${props.platformId}/${props.platformUserId}/audience/insights`, {
            params: {
                refresh: isRefresh,
            },
        })
        .then(({ data }) => {
            isInsightsLoaded.value = true
            stopProgressBar(insightsLoadProgress, insightsProgressInterval)

            if (data.payload.insights) {
                insights.value = data.payload.insights

                const interestsLookup = _.keyBy(data.payload.codes.interests, 'key')
                const industriesLookup = _.keyBy(data.payload.codes.industry_category, 'code')

                _.forEach(insights.value.interests, (item) => {
                    item.code = interestsLookup[item.key]
                })

                _.forEach(insights.value.classifications, (item) => {
                    item.code = industriesLookup[item.key]
                })
            } else {
                insights.value = false
            }
        })
}

const fetchDemographics = (isRefresh = false) => {
    demographics.value = null
    isDemographicsLoaded.value = false
    demographicsProgressInterval = startProgressBar(demographicsLoadProgress, demographicsProgressInterval)

    return axios
        .get(`/platforms/${props.platformId}/${props.platformUserId}/audience/analysis`, {
            params: {
                refresh: isRefresh,
            },
        })
        .then(({ data }) => {
            isDemographicsLoaded.value = true
            demographics.value = data.payload.analysis
            stopProgressBar(demographicsLoadProgress, demographicsProgressInterval)

            if (demographics.value) {
                demographics.value.countries = _.orderBy(demographics.value?.countries, ['percentage'], ['desc']).slice(
                    0,
                    10,
                )
                demographics.value.languages = _.orderBy(demographics.value?.languages, ['percentage'], ['desc']).slice(
                    0,
                    10,
                )
                demographics.value.age_ranges = _.orderBy(demographics.value?.age_ranges, ['range'], ['asc'])
            }

            if (demographics.value?.age_ranges) {
                const under18Index = _.findIndex(demographics.value.age_ranges, (item) => item.range === 'Under 18')

                if (under18Index !== -1) {
                    const poppedItem = demographics.value.age_ranges.splice(under18Index, 1)
                    if (poppedItem && poppedItem.length === 1) demographics.value.age_ranges.unshift(poppedItem[0])
                }
            }

            fetchInsights()
        })
}

const fetchAudienceData = (isRefresh) => {
    return Promise.all([fetchDemographics(isRefresh), fetchConversionDemographics()])
}

fetchAudienceData(false)
</script>

<template>
    <div class="mt-8">
        <div class="flex">
            <h2 class="font-semibold text-slate-800">Audience Demographics</h2>
            <div class="ml-2">
                <Tooltip v-if="store.getters.isSfStaff === true">
                    <template #trigger>
                        <button
                            class="btn flex border-slate-200 bg-white p-1 text-slate-700 hover:border-slate-300"
                            @click="fetchAudienceData(true)"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'arrows-rotate']"
                                class="my-auto h-3 w-3 shrink-0 fill-current text-slate-500"
                            />
                            <!-- <span class="ml-2 hidden xs:block">Impersonate User</span> -->
                        </button>
                    </template>
                    <template #default>
                        <div class="whitespace-normal text-xs">
                            Clicking this will trigger a manual refresh of the audience analysis.
                        </div>
                    </template>
                </Tooltip>
            </div>
        </div>

        <p class="text-sm">
            Here you can learn more about {{ superview.normalized.display_name }}'s audience using AI analysis. Our AI
            goes through each member of {{ superview.normalized.display_name }}'s audience and learns more about who
            they are and what they are interested in.
        </p>
    </div>

    <div v-if="isDemographicsLoaded === false" class="mt-4">
        <div class="flex flex-col rounded border border-slate-100 px-10 py-32">
            <div class="text-center text-sm">
                <p>Analyzing audience demographics, this could take up to 2 minutes...</p>
            </div>
            <div class="mt-3 h-2.5 w-full rounded bg-gray-200 dark:bg-gray-700">
                <div class="h-2.5 rounded bg-indigo-500" :style="{ width: `${demographicsLoadProgress}%` }"></div>
            </div>
        </div>
    </div>

    <div v-else>
        <!--  Demographics -->
        <div v-if="demographics && isDemographicsLoaded === true" class="mt-4">
            <div class="grid grid-cols-12 gap-6">
                <!-- Country -->
                <div
                    v-if="demographics.countries"
                    class="col-span-full flex flex-col rounded-sm border border-slate-200 xl:col-span-4"
                >
                    <header class="border-b border-slate-100 px-5 py-4">
                        <h2 class="font-semibold text-slate-800">Countries</h2>
                    </header>

                    <div class="grow p-3">
                        <div class="flex h-full flex-col">
                            <div class="grow">
                                <ul
                                    class="flex justify-between space-x-2 px-2 text-xs font-semibold uppercase text-slate-400"
                                >
                                    <li>Country</li>
                                    <li>Percent</li>
                                </ul>
                                <ul class="mb-4 mt-3 space-y-1 text-sm text-slate-800">
                                    <!-- Item -->
                                    <li
                                        v-for="item in demographics.countries"
                                        :key="item.key"
                                        class="relative px-2 py-1"
                                    >
                                        <template v-if="item.key !== 'undefined'">
                                            <div
                                                class="absolute inset-0 bg-indigo-100"
                                                aria-hidden="true"
                                                :style="{
                                                    width: `${$numeral(item.percentage / 100).format('0%')}`,
                                                }"
                                            />
                                            <div class="relative flex justify-between space-x-2">
                                                <div>
                                                    <span v-if="item.key !== 'OTHER'" class="font-semibold uppercase">{{
                                                        item.country_code
                                                    }}</span>
                                                    <span class="ml-1">
                                                        {{ store.getters.getCountryFromCode(item.country_code) }}
                                                    </span>
                                                </div>
                                                <div class="font-medium">
                                                    {{ $numeral(item.percentage / 100).format('0.0%') }}
                                                </div>
                                            </div>
                                        </template>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Language -->
                <div
                    v-if="demographics?.languages"
                    class="col-span-full flex flex-col rounded-sm border border-slate-200 xl:col-span-4"
                >
                    <header class="border-b border-slate-100 px-5 py-4">
                        <h2 class="font-semibold text-slate-800">Languages</h2>
                    </header>

                    <div class="grow p-3">
                        <div class="flex h-full flex-col">
                            <div class="grow">
                                <ul
                                    class="flex justify-between space-x-2 px-2 text-xs font-semibold uppercase text-slate-400"
                                >
                                    <li>Language</li>
                                    <li>Percent</li>
                                </ul>
                                <ul class="mb-4 mt-3 space-y-1 text-sm text-slate-800">
                                    <!-- Item -->
                                    <li
                                        v-for="item in demographics.languages"
                                        :key="item.key"
                                        class="relative px-2 py-1"
                                    >
                                        <div
                                            class="absolute inset-0 bg-emerald-100"
                                            aria-hidden="true"
                                            :style="{
                                                width: `${$numeral(item.percentage / 100).format('0%')}`,
                                            }"
                                        />
                                        <div class="relative flex justify-between space-x-2">
                                            <div>
                                                <span v-if="item.key !== 'OTHER'" class="font-semibold uppercase">{{
                                                    item.language_code
                                                }}</span>
                                                <span class="ml-1">{{
                                                    store.getters.getLanguageFromCode(item.language_code)
                                                }}</span>
                                            </div>
                                            <div class="font-medium">
                                                {{ $numeral(item.percentage / 100).format('0.0%') }}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Gender Distribution -->
                <div
                    v-if="demographics?.gender_distribution"
                    class="col-span-full flex flex-col rounded-sm border border-slate-200 xl:col-span-4"
                >
                    <header class="border-b border-slate-100 px-5 py-4">
                        <h2 class="font-semibold text-slate-800">Gender Distribution</h2>
                    </header>

                    <div class="grow p-3">
                        <div class="flex flex-col">
                            <div class="grow">
                                <ul
                                    class="flex justify-between space-x-2 px-2 text-xs font-semibold uppercase text-slate-400"
                                >
                                    <li>Gender</li>
                                    <li>Percent</li>
                                </ul>
                                <ul class="mb-4 mt-3 space-y-1 text-sm text-slate-800">
                                    <!-- Item -->
                                    <li
                                        v-for="(item, key) in demographics.gender_distribution"
                                        :key="key"
                                        class="relative px-2 py-1"
                                    >
                                        <div
                                            class="absolute inset-0 bg-emerald-100"
                                            aria-hidden="true"
                                            :style="{
                                                width: `${$numeral(item.percentage / 100).format('0%')}`,
                                            }"
                                        />
                                        <div class="relative flex justify-between space-x-2">
                                            <div>
                                                <span class="capitalize">{{
                                                    key === 'non_binary' ? 'Non Binary' : key
                                                }}</span>
                                            </div>
                                            <div class="font-medium">
                                                {{ $numeral(item.percentage / 100).format('0%') }}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Age Distribution -->
                <div
                    v-if="demographics?.age_ranges"
                    class="col-span-full flex flex-col rounded-sm border border-slate-200 xl:col-span-4"
                >
                    <header class="border-b border-slate-100 px-5 py-4">
                        <h2 class="font-semibold text-slate-800">Age Distribution</h2>
                    </header>

                    <div class="grow p-3">
                        <div class="flex flex-col">
                            <div class="grow">
                                <ul
                                    class="flex justify-between space-x-2 px-2 text-xs font-semibold uppercase text-slate-400"
                                >
                                    <li>Age Range</li>
                                    <li>Percent</li>
                                </ul>
                                <ul class="mb-4 mt-3 space-y-1 text-sm text-slate-800">
                                    <!-- Item -->
                                    <li
                                        v-for="item in demographics.age_ranges"
                                        :key="item.range"
                                        class="relative px-2 py-1"
                                    >
                                        <div
                                            class="absolute inset-0 bg-sky-100"
                                            aria-hidden="true"
                                            :style="{
                                                width: `${$numeral(item.percentage / 100).format('0%')}`,
                                            }"
                                        />
                                        <div class="relative flex justify-between space-x-2">
                                            <div>
                                                <span class="ml-1 capitalize">{{ item.range }}</span>
                                            </div>
                                            <div class="font-medium">
                                                {{ $numeral(item.percentage / 100).format('0%') }}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Operating System -->
                <div
                    v-if="conversionDemographics?.byOperatingSystem"
                    class="col-span-full flex flex-col rounded-sm border border-slate-200 xl:col-span-4"
                >
                    <header class="border-b border-slate-100 px-5 py-4">
                        <h2 class="font-semibold text-slate-800">Operating System</h2>
                    </header>

                    <div class="grow p-3">
                        <div class="flex flex-col">
                            <div class="grow">
                                <ul
                                    class="flex justify-between space-x-2 px-2 text-xs font-semibold uppercase text-slate-400"
                                >
                                    <li>OS</li>
                                    <li>Percent</li>
                                </ul>
                                <ul class="mb-4 mt-3 space-y-1 text-sm text-slate-800">
                                    <!-- Item -->
                                    <li
                                        v-for="item in conversionDemographics.byOperatingSystem"
                                        :key="item.key"
                                        class="relative px-2 py-1"
                                    >
                                        <div
                                            class="absolute inset-0 bg-sky-100"
                                            aria-hidden="true"
                                            :style="{
                                                width: `${$numeral(item.percent / 100).format('0%')}`,
                                            }"
                                        />
                                        <div class="relative flex justify-between space-x-2">
                                            <div>
                                                <span class="ml-1">{{ item.label }}</span>
                                            </div>
                                            <div class="font-medium">
                                                {{ $numeral(item.percent / 100).format('0%') }}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Operating System -->
                <div
                    v-if="conversionDemographics?.byDeviceType"
                    class="col-span-full flex flex-col rounded-sm border border-slate-200 xl:col-span-4"
                >
                    <header class="border-b border-slate-100 px-5 py-4">
                        <h2 class="font-semibold text-slate-800">Devices</h2>
                    </header>

                    <div class="grow p-3">
                        <div class="flex flex-col">
                            <div class="grow">
                                <ul
                                    class="flex justify-between space-x-2 px-2 text-xs font-semibold uppercase text-slate-400"
                                >
                                    <li>Device</li>
                                    <li>Percent</li>
                                </ul>
                                <ul class="mb-4 mt-3 space-y-1 text-sm text-slate-800">
                                    <!-- Item -->
                                    <li
                                        v-for="item in conversionDemographics.byDeviceType"
                                        :key="item.key"
                                        class="relative px-2 py-1"
                                    >
                                        <div
                                            class="absolute inset-0 bg-sky-100"
                                            aria-hidden="true"
                                            :style="{
                                                width: `${$numeral(item.percent / 100).format('0%')}`,
                                            }"
                                        />
                                        <div class="relative flex justify-between space-x-2">
                                            <div>
                                                <span class="ml-1 capitalize">{{ item.label }}</span>
                                            </div>
                                            <div class="font-medium">
                                                {{ $numeral(item.percent / 100).format('0%') }}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="demographics === false && isDemographicsLoaded === true" class="mt-4">
            <div class="rounded border border-slate-100 px-3 py-5">
                <div class="text-md flex">
                    <div class="m-auto text-sm">
                        It seems we don't have enough data to generate an demographics analysis for
                        {{ props.superview.normalized.name }}.
                    </div>
                </div>
            </div>
        </div>

        <template v-if="store.getters.checkPermission('feature.audience.interests')">
            <div class="mt-8">
                <h2 class="font-semibold text-slate-800">Audience Industry Affinities</h2>
                <p class="text-sm">
                    In what other industries does {{ superview.normalized.name }}'s audience spend time watching
                    content?
                </p>

                <div v-if="!isInsightsLoaded && insights === null" class="mt-8">
                    <div class="flex flex-col rounded border border-slate-100 px-10 py-32">
                        <div class="text-center text-sm">
                            <p>Analyzing industry affinities, this could take up to 60 seconds...</p>
                        </div>
                        <div class="mt-3 h-2.5 w-full rounded bg-gray-200 dark:bg-gray-700">
                            <div
                                class="h-2.5 rounded bg-indigo-500"
                                :style="{ width: `${insightsLoadProgress}%` }"
                            ></div>
                        </div>
                    </div>
                </div>

                <div v-else-if="isInsightsLoaded === true && insights" class="mt-4 grid grid-cols-2">
                    <div
                        v-for="{ topic, category, key, percentage, code } in insights.classifications"
                        :key="key"
                        class="mb-2 mr-2 flex flex-col rounded border border-slate-100 px-4 py-3 text-sm"
                    >
                        <div class="flex grow">
                            <div v-if="code?.emoji" class="m-auto mr-3 shrink text-2xl">
                                {{ code.emoji }}
                            </div>
                            <div class="grow leading-none">
                                <div class="font-semibold capitalize">
                                    {{ category }}
                                </div>
                                <div class="text-xs capitalize">{{ topic }}</div>
                            </div>
                            <div class="my-auto ml-4 shrink">
                                <div class="flex flex-col overflow-hidden rounded text-center text-xs">
                                    <AffinityPlate :affinity-percent="0.75 + percentage / 100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else-if="insights === false && isInsightsLoaded === true" class="mt-4">
                    <div class="rounded border border-slate-100 px-3 py-5">
                        <div class="text-md flex">
                            <div class="m-auto text-sm">
                                It seems we don't have enough data to generate audience insights for
                                {{ props.superview.normalized.name }}.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-8">
                <h2 class="font-semibold text-slate-800">Audience Interests</h2>
                <p class="text-sm">What is {{ superview.normalized.name }}'s audience interested in?</p>

                <div v-if="!isInsightsLoaded && insights === null" class="mt-8">
                    <div class="flex flex-col rounded border border-slate-100 px-10 py-32">
                        <div class="text-center text-sm">
                            <p>Analyzing audience interests, this could take up to 60 seconds...</p>
                        </div>
                        <div class="mt-3 h-2.5 w-full rounded bg-gray-200 dark:bg-gray-700">
                            <div
                                class="h-2.5 rounded bg-indigo-500"
                                :style="{ width: `${insightsLoadProgress}%` }"
                            ></div>
                        </div>
                    </div>
                </div>

                <div v-else-if="isInsightsLoaded === true && insights" class="mt-4 grid grid-cols-2">
                    <div
                        v-for="{ name, key, relevance_score, parent_interest, emoji } in insights.interests"
                        :key="key"
                        class="mb-2 mr-2 flex flex-col rounded border border-slate-100 px-4 py-3 text-sm"
                    >
                        <div class="flex grow">
                            <div class="m-auto mr-3 shrink text-2xl">
                                {{ emoji }}
                            </div>
                            <div class="grow leading-none">
                                <div class="font-semibold capitalize">
                                    {{ name }}
                                </div>
                                <div class="text-xs capitalize">{{ parent_interest }}</div>
                            </div>
                            <div class="my-auto ml-4 shrink">
                                <div class="flex flex-col overflow-hidden rounded text-center text-xs">
                                    <AffinityPlate :affinity-percent="0.5 + relevance_score" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else-if="insights === false && isInsightsLoaded === true" class="mt-4">
                    <div class="rounded border border-slate-100 px-3 py-5">
                        <div class="text-md flex">
                            <div class="m-auto text-sm">
                                It seems we don't have enough data to generate audience insights for
                                {{ props.superview.normalized.name }}.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
