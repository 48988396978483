<template>
    <!-- Skeleton for triage sidebar -->
    <div class="group mt-4">
        <div class="mb-3 flex justify-between">
            <div class="h-3 w-20 animate-pulse rounded bg-slate-200" />
        </div>

        <ul class="mb-6 mt-4">
            <li v-for="index in 20" :key="index">
                <div
                    class="flex w-full animate-pulse justify-between rounded p-2"
                    :class="{ 'bg-slate-300': index === 1 }"
                >
                    <div class="flex grow items-center">
                        <div class="relative mr-2">
                            <div class="h-8 w-8 animate-pulse rounded-full bg-slate-200" />
                        </div>

                        <div class="truncate">
                            <div class="h-3 w-28 animate-pulse rounded bg-slate-200" />
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'SkeletonTriageSidebar',
}
</script>
