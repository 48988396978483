<template>
    <div class="mx-auto w-full max-w-9xl px-4 py-8 sm:px-6 md:py-0 lg:px-8">
        <div class="md:ml-8 md:py-8 xl:ml-4 2xl:ml-8">
            <header>
                <div class="ml-auto mr-auto mt-72">
                    <div v-if="returnType === 'stripe-subscription-create'" class="flex flex-col items-center">
                        <h1 class="mb-6 text-3xl font-bold text-slate-800">
                            <div>
                                <span>Thank you for subscribing!</span>
                            </div>
                        </h1>
                        <div>
                            <p>We are setting up your account and will redirect you shortly...</p>
                        </div>
                    </div>

                    <div v-if="returnType === 'stripe-subscription-update'" class="flex flex-col items-center">
                        <h1 class="mb-6 text-3xl font-bold text-slate-800">
                            <div>
                                <span>Thank you for updating your subscription</span>
                            </div>
                        </h1>
                        <div>
                            <p>Redirecting you back to subscription settings...</p>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import subscriptionsAPI from '../utils/api/api.subscriptions'

export default {
    name: 'ReturnPage',

    components: {},

    data() {
        return {
            returnType: '',
        }
    },

    computed: {
        ...mapGetters(['selfActiveOrganization']),
    },

    created() {
        if (this.$route.query.returntype) {
            this.returnType = this.$route.query.returntype

            if (this.returnType === 'stripe-subscription-create') {
                this.onStripeSubscriptionCreate()
            } else if (this.returnType === 'stripe-subscription-update') {
                this.onStripeSubscriptionUpdate()
            }
        } else {
            this.$router.push({ name: 'landing' })
        }
    },

    methods: {
        async onStripeSubscriptionUpdate() {
            this.$router.push({
                name: 'organization-subscriptions',
                params: {
                    organization_id: this.selfActiveOrganization.id,
                },
            })
        },

        async onStripeSubscriptionCreate() {
            if (!this.$route.query.checkout_session_id) {
                this.$router.push({ name: 'landing' })
                return
            }

            await subscriptionsAPI.syncSubscription(this.$axios, 'stripe', this.$route.query.checkout_session_id)
            this.$router.push({
                name: 'organization-subscriptions',
                params: {
                    organization_id: this.selfActiveOrganization.id,
                },
            })
        },
    },
}
</script>
