import _ from 'lodash'

export default {
    state: {
        twitch: [
            {
                value: 'en',
                text: 'English',
            },
            {
                value: 'zh',
                text: 'Chinese',
            },
            {
                value: 'ja',
                text: 'Japanese',
            },
            {
                value: 'ko',
                text: 'Korean',
            },
            {
                value: 'es',
                text: 'Spanish',
            },
            {
                value: 'fr',
                text: 'French',
            },
            {
                value: 'de',
                text: 'German',
            },
            {
                value: 'it',
                text: 'Italian',
            },
            {
                value: 'pt',
                text: 'Portuguese',
            },
            {
                value: 'sv',
                text: 'Swedish',
            },
            {
                value: 'no',
                text: 'Norwegian',
            },
            {
                value: 'da',
                text: 'Danish',
            },
            {
                value: 'nl',
                text: 'Dutch',
            },
            {
                value: 'fi',
                text: 'Finnish',
            },
            {
                value: 'pl',
                text: 'Polish',
            },
            {
                value: 'el',
                text: 'Greek',
            },
            {
                value: 'ru',
                text: 'Russian',
            },
            {
                value: 'tr',
                text: 'Turkish',
            },
            {
                value: 'cs',
                text: 'Czech',
            },
            {
                value: 'sk',
                text: 'Slovak',
            },
            {
                value: 'hu',
                text: 'Hungarian',
            },
            {
                value: 'ar',
                text: 'Arabic',
            },
            {
                value: 'bg',
                text: 'Bulgarian',
            },
            {
                value: 'th',
                text: 'Thai',
            },
            {
                value: 'vi',
                text: 'Vietnamese',
            },
            {
                value: 'asl',
                text: 'American Sign Language',
            },
            {
                value: 'other',
                text: 'Other',
            },
        ],
    },

    getters: {
        getLanguageFromCode: () => (localeCode) => {
            try {
                const lookup = new Intl.DisplayNames(['en'], { type: 'language' })

                const displayName = lookup.of(localeCode)

                if (displayName === localeCode) {
                    throw new Error(`Language not found`)
                }

                return displayName
            } catch (error) {
                // console.log(error, localeCode)
                return 'Unknown'
            }
        },

        getCodeFromLanguage: (state) => (languageName) => {
            const match = _.find(state.twitch, ({ text }) => text?.toLowerCase() === languageName?.toLowerCase())

            if (match) return match.value
            return `??`
        },

        twitchLanguages: (state) => state.twitch,

        languages: (state) => state,
    },
}
