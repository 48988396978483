import api from './api'

const apimessages = {
    async getMessages(axios, organizationId, status, page, pageSize, sortBy, sortDir) {
        const body = {
            filters: [
                {
                    id: 'provider',
                    value: 'sendgrid',
                },
            ],
            paging: {
                perPage: pageSize,
                currentPage: page,
                sortby: sortBy,
                sortdir: sortDir,
            },
        }

        if (status) {
            body.filters.push({ id: 'status', value: status })
        }

        return axios
            .post(`/messages/${organizationId}/find`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async sendMessages(axios, organizationId, messages) {
        const body = {
            messages,
        }

        return axios
            .post(`/messages/${organizationId}`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async cancelMessages(axios, organizationId, messageIds) {
        const body = {
            messages: messageIds,
        }

        return axios
            .post(`/messages/${organizationId}/cancel`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getRecipientMessageSummary(axios, organizationId, platforms) {
        const body = {
            platforms,
        }

        return axios
            .post(`/messages/${organizationId}/recipientsummary`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apimessages
