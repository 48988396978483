<template>
    <ModalBasic
        id="confirm-remove-payee"
        title="Confirm unassign payee"
        :modal-open="modals.confirm.is_open"
        @close-modal="onCloseConfirmRemovePayee"
    >
        <div class="relative">
            <div class="px-5 py-4">
                <div class="text-sm">
                    <div class="mb-3 font-medium text-slate-800">Are you sure you wish to unassign the payee?</div>
                    <div>Existing unpaid payments will not be payable until a payee is reassigned.</div>
                </div>
            </div>

            <div class="border-t border-slate-200 px-5 py-4">
                <div class="flex flex-wrap justify-end space-x-2">
                    <button
                        class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                        @click="onCloseConfirmRemovePayee()"
                    >
                        Cancel
                    </button>

                    <button class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600" @click="onConfirmRemovePayee()">
                        Remove
                    </button>
                </div>
            </div>
        </div>
    </ModalBasic>

    <div class="col-span-12 mt-3 md:col-span-8">
        <h2 class="mb-3 font-semibold text-slate-800">Finance summary</h2>

        <template v-if="!loading">
            <div class="grid grid-cols-5 gap-3">
                <div v-for="metric in metrics" :key="metric.title" class="relative rounded bg-slate-50 px-4 py-3">
                    <div class="text-xs font-medium text-slate-500">{{ metric.title }}</div>
                    <div class="text-xl font-semibold text-slate-700">
                        {{ metric.formattedValue }}
                    </div>
                </div>
            </div>

            <div v-if="finance && finance.payee" class="mt-6">
                <div class="mb-4 flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                    <div>
                        <span class="font-semibold text-slate-700">Assigned payee</span>
                    </div>
                </div>

                <div class="grid grid-cols-9 gap-3 px-3">
                    <div class="text-sm font-bold">Provider</div>
                    <div>
                        {{ finance.payee.provider }}
                    </div>
                    <div class="col-span-2">
                        <template v-if="finance.payee.provider_id"> ({{ finance.payee.provider_id }}) </template>
                    </div>
                    <div class="text-sm font-bold">
                        {{ getFromDictionary(`entity.${finance.payee.entity_type}`) }}
                    </div>
                    <div>{{ finance.payee.entity_name }}</div>
                    <div class="text-sm font-bold">Status</div>
                    <div>
                        {{ getFromDictionary(`finance.payee.status.${finance.payee.status}`, 'title') }}
                    </div>

                    <div>
                        <button class="cursor-pointer text-indigo-500" @click="onOpenConfirmRemovePayee()">
                            Unassign
                        </button>
                    </div>
                </div>
            </div>

            <div v-if="finance && !finance.payee" class="mt-6">
                <div class="mb-4 flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                    <div>
                        <span class="font-semibold text-slate-700">Available payees</span>
                    </div>
                </div>

                <div v-if="availablePayees.length > 0">
                    <div v-for="payee in availablePayees" :key="payee.id" class="mt-3 grid grid-cols-9 gap-3 px-3">
                        <div class="text-sm font-bold">Provider</div>
                        <div>
                            {{ payee.provider }}
                        </div>
                        <div class="col-span-2">
                            <template v-if="payee.provider_id"> ({{ payee.provider_id }}) </template>
                        </div>
                        <div class="text-sm font-bold">
                            {{ getFromDictionary(`entity.${payee.entity_type}`) }}
                        </div>
                        <div>{{ payee.entity_name }}</div>
                        <div class="text-sm font-bold">Status</div>
                        <div>
                            {{ getFromDictionary(`finance.payee.status.${payee.status}`, 'title') }}
                        </div>

                        <div>
                            <button class="cursor-pointer text-indigo-500" @click="onAssignPayee(payee.id)">
                                Assign
                            </button>
                        </div>
                    </div>
                </div>

                <div v-if="availablePayees.length === 0" class="ml-2">No payees available</div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import campaignFinanceAPI from '@/utils/api/api.campaign.finance'
import ModalBasic from '@/components/ModalBasic.vue'

export default {
    name: 'Finance',

    components: {
        ModalBasic,
    },

    props: {
        supercampaign: Object,
        campaign: Object,
        participant: Object,
        superview: Object,
    },

    emits: [],

    data() {
        return {
            loading: false,
            finance: null,
            availablePayees: [],
            metrics: [],
            modals: {
                confirm: {
                    is_open: false,
                },
            },
        }
    },

    watch: {
        participant() {
            this.reset()
        },
        campaign() {
            this.reset()
        },
    },

    async created() {
        this.reset()
    },

    computed: {
        ...mapGetters(['getFromDictionary']),
    },

    methods: {
        async reset() {
            this.loading = true
            this.finance = null
            this.availablePayees = []
            this.metrics = []
            await this.getFinanceSummary()
        },

        async getFinanceSummary() {
            const result = await campaignFinanceAPI.getParticipantFinanceSummary(this.$axios, this.participant.id)
            if (result.success) {
                this.finance = result.value.financeSummary

                this.metrics = []
                this.metrics.push({
                    title: 'Payout amount',
                    formattedValue: this.$filters.formatCurrency(this.finance?.tasks?.total_amount),
                })
                this.metrics.push({
                    title: 'Completed amount',
                    formattedValue: this.$filters.formatCurrency(this.finance?.tasks?.completed_amount),
                })
                this.metrics.push({
                    title: 'Unscheduled amount',
                    formattedValue: this.$filters.formatCurrency(this.finance.payments?.unscheduled_completed),
                })
                this.metrics.push({
                    title: 'Scheduled amount',
                    formattedValue: this.$filters.formatCurrency(this.finance?.payments?.scheduled),
                })
                this.metrics.push({
                    title: 'Paid amount',
                    formattedValue: this.$filters.formatCurrency(this.finance?.payments?.paid),
                })

                if (!this.finance.payee) {
                    await this.getAvailablePayees()
                }
            }
            this.loading = false
        },

        async getAvailablePayees() {
            const result = await campaignFinanceAPI.getParticipantAvailablePayees(this.$axios, this.participant.id)
            if (result.success) {
                this.availablePayees = result.value.payees
            }
        },

        onOpenConfirmRemovePayee() {
            this.modals.confirm.is_open = true
        },

        onCloseConfirmRemovePayee() {
            this.modals.confirm.is_open = false
        },

        onConfirmRemovePayee() {
            this.modals.confirm.is_open = false
            this.unassignPayee()
        },

        onAssignPayee(payeeId) {
            this.assignPayee(payeeId)
        },

        async assignPayee(payeeId) {
            const result = await campaignFinanceAPI.assignPayee(this.$axios, this.participant.id, payeeId)
            if (result.success) {
                await this.getFinanceSummary()
            }
        },

        async unassignPayee() {
            const result = await campaignFinanceAPI.unassignPayee(this.$axios, this.participant.id)
            if (result.success) {
                await this.getFinanceSummary()
            }
        },
    },
}
</script>
