<template>
    <!-- Skeleton Loader -->
    <div class="grid grid-cols-12 gap-6">
        <!-- Country -->
        <div class="col-span-full flex flex-col rounded-sm border border-slate-200 xl:col-span-4">
            <header class="border-b border-slate-100 px-5 py-4">
                <h2 class="font-semibold text-slate-800">Countries</h2>
            </header>

            <div class="grow p-3">
                <div class="flex h-full flex-col">
                    <div class="grow">
                        <ul class="flex justify-between space-x-2 px-2 text-xs font-semibold uppercase text-slate-400">
                            <li>Country</li>
                            <li>Percent</li>
                        </ul>
                        <ul class="mb-4 mt-3 space-y-1 text-sm text-slate-800">
                            <!-- Item -->
                            <li
                                v-for="i in 7"
                                :key="i"
                                class="relative h-8 w-full animate-pulse rounded bg-slate-200 px-2 py-1"
                            ></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- City -->
        <div class="col-span-full flex flex-col rounded-sm border border-slate-200 xl:col-span-4">
            <header class="border-b border-slate-100 px-5 py-4">
                <h2 class="font-semibold text-slate-800">Cities</h2>
            </header>

            <div class="grow p-3">
                <div class="flex h-full flex-col">
                    <div class="grow">
                        <ul class="flex justify-between space-x-2 px-2 text-xs font-semibold uppercase text-slate-400">
                            <li>City</li>
                            <li>Percent</li>
                        </ul>
                        <ul class="mb-4 mt-3 space-y-1 text-sm text-slate-800">
                            <!-- Item -->
                            <li
                                v-for="i in 7"
                                :key="i"
                                class="relative h-8 w-full animate-pulse rounded bg-slate-200 px-2 py-1"
                            ></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Language -->
        <div class="col-span-full flex flex-col rounded-sm border border-slate-200 xl:col-span-4">
            <header class="border-b border-slate-100 px-5 py-4">
                <h2 class="font-semibold text-slate-800">Languages</h2>
            </header>

            <div class="grow p-3">
                <div class="flex h-full flex-col">
                    <div class="grow">
                        <ul class="flex justify-between space-x-2 px-2 text-xs font-semibold uppercase text-slate-400">
                            <li>Language</li>
                            <li>Percent</li>
                        </ul>
                        <ul class="mb-4 mt-3 space-y-1 text-sm text-slate-800">
                            <!-- Item -->
                            <li
                                v-for="i in 7"
                                :key="i"
                                class="relative h-8 w-full animate-pulse rounded bg-slate-200 px-2 py-1"
                            ></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- OS -->
        <div class="col-span-full flex flex-col rounded-sm border border-slate-200 xl:col-span-4">
            <header class="border-b border-slate-100 px-5 py-4">
                <h2 class="font-semibold text-slate-800">Operating System</h2>
            </header>

            <div class="grow p-3">
                <div class="flex flex-col">
                    <div class="grow">
                        <ul class="flex justify-between space-x-2 px-2 text-xs font-semibold uppercase text-slate-400">
                            <li>OS</li>
                            <li>Percent</li>
                        </ul>
                        <ul class="mb-4 mt-3 space-y-1 text-sm text-slate-800">
                            <!-- Item -->
                            <li
                                v-for="i in 5"
                                :key="i"
                                class="relative h-8 w-full animate-pulse rounded bg-slate-200 px-2 py-1"
                            ></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Device -->
        <div class="col-span-full flex flex-col rounded-sm border border-slate-200 xl:col-span-4">
            <header class="border-b border-slate-100 px-5 py-4">
                <h2 class="font-semibold text-slate-800">Devices</h2>
            </header>

            <div class="grow p-3">
                <div class="flex flex-col">
                    <div class="grow">
                        <ul class="flex justify-between space-x-2 px-2 text-xs font-semibold uppercase text-slate-400">
                            <li>Device</li>
                            <li>Percent</li>
                        </ul>
                        <ul class="mb-4 mt-3 space-y-1 text-sm text-slate-800">
                            <!-- Item -->
                            <li
                                v-for="i in 3"
                                :key="i"
                                class="relative h-8 w-full animate-pulse rounded bg-slate-200 px-2 py-1"
                            ></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkeletonCreatorProfileDemographics',
}
</script>
