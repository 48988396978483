// We'll create some re-useable definitions
// because many input types are identical
// in how we want to style them.
const textClassification = {
    label: 'block mb-1 font-bold text-sm formkit-invalid:text-red-500',
    inner: `
        flex
        border
        border-gray-200
        formkit-invalid:border-red-500
        rounded mb-1
        overflow-hidden
        focus-within:border-blue-500
    `,
    prefixIcon: 'w-12 h-10 py-2 px-3 border-r border-gray-200 bg-slate-50',
    input: 'w-full h-10 px-3 border-none text-sm text-gray-700 placeholder-gray-400',
}

const boxClassification = {
    fieldset: 'border border-gray-200 px-2 pb-1',
    legend: 'font-bold text-sm',
    wrapper: 'flex items-center mb-1 cursor-pointer',
    help: 'mb-2',
    input: 'form-checkbox appearance-none mr-2 focus:outline-none focus:ring-0 transition duration-200',
    label: 'text-sm text-gray-700 mt-1',
}

const buttonClassification = {
    wrapper: 'mb-1',
    input: 'bg-blue-500 hover:bg-blue-700 text-white text-sm font-normal py-3 px-5 rounded',
}

const autocompleteClassification = {
    label: 'block mb-1 font-bold text-sm formkit-invalid:text-red-500',
    inner: `
        formkit-invalid:border-red-500
        mb-1
        focus-within:border-blue-500
        relative
    `,
    brace: `
        flex
        flex-col
    `,
    clearValue: `cursor-pointer hover:text-indigo-700 font-bold`,
    input: 'form-input w-full px-3 h-10 text-base text-gray-700 placeholder-gray-400 outline-0 placeholder:text-base',
    value: 'form-input w-full px-3 h-10 py-2 flex justify-between cursor-pointer',
    dropdown:
        'z-10 absolute top-full left-0 w-full bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 font-medium text-sm text-slate-600 divide-y divide-slate-200',
    dropdownItem: 'w-full hover:bg-slate-50 py-2 px-3 cursor-pointer',
}

// export our definitions using our above
// templates and declare one-offs and
// overrides as needed.
export default {
    // the global key will apply to all inputs
    global: {
        outer: 'mb-5 formkit-disabled:opacity-50',
        help: 'text-xs text-gray-500',
        messages: 'list-none p-0 mt-1 mb-0',
        message: 'text-red-500 mb-1 text-xs',
    },
    button: buttonClassification,
    color: {
        label: 'block mb-1 font-bold text-sm',
        input: 'w-16 h-8 appearance-none cursor-pointer border border-gray-300 rounded-md mb-2 p-1',
    },
    date: textClassification,
    'datetime-local': textClassification,
    checkbox: boxClassification,
    email: textClassification,
    file: {
        label: 'block mb-1 font-bold text-sm',
        inner: 'cursor-pointer',
        input: 'text-gray-600 text-sm mb-1 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:bg-blue-500 file:text-white hover:file:bg-blue-600',
        noFiles: 'block text-gray-800 text-sm mb-1',
        fileItem: 'block flex text-gray-800 text-sm mb-1',
        removeFiles: 'ml-auto text-blue-500 text-sm',
    },
    month: textClassification,
    number: textClassification,
    password: textClassification,
    radio: {
        // if we want to override a given sectionKey
        // we can do a spread of the default value
        // along with a new definition for our target.
        ...boxClassification,
        input: boxClassification.input.replace('rounded-sm', 'rounded-full'),
    },
    range: {
        input: 'form-range appearance-none w-full h-2 p-0 bg-gray-200 rounded-full focus:outline-none focus:ring-0 focus:shadow-none',
    },
    search: textClassification,
    autocomplete: autocompleteClassification,
    select: textClassification,
    submit: buttonClassification,
    tel: textClassification,
    text: textClassification,
    textarea: {
        ...textClassification,
        input: 'block w-full h-32 px-3 border-none text-base text-gray-700 placeholder-gray-400 focus:shadow-outline',
    },
    time: textClassification,
    url: textClassification,
    week: textClassification,
}
