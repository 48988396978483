<template>
    <!-- Skeleton recent activity -->
    <div>
        <div v-for="index in 5" :key="index">
            <div class="rounded-sm bg-white px-3 py-2">
                <div class="my-[3px] h-3 w-24 animate-pulse rounded bg-slate-200" />
            </div>

            <ul class="my-1">
                <li class="flex px-2">
                    <div class="my-2 mr-3 flex h-9 w-9 shrink-0 animate-pulse rounded-full bg-slate-200" />
                    <div class="my-5 h-3 w-1/4 animate-pulse rounded bg-slate-200" />
                </li>

                <li class="flex px-2">
                    <div class="my-2 mr-3 flex h-9 w-9 shrink-0 animate-pulse rounded-full bg-slate-200" />
                    <div class="mb-8 mt-5 flex h-3 w-2/5 animate-pulse rounded bg-slate-200" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkeletonCampaignHome',
}
</script>
