<template>
    <div class="mx-auto w-full max-w-9xl px-4 py-8 sm:px-6 md:py-0 lg:px-8">
        <div v-if="game" class="xl:flex">
            <!-- Left + Middle content -->
            <div class="flex-1 md:flex">
                <div class="flex-1">
                    <div class="md:py-8">
                        <router-view :key="$route.path" :game="game" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GamePage',

    components: {},

    data() {
        return {
            game: null,
        }
    },

    computed: {},

    created() {
        this.getGame()
    },

    methods: {
        getGame() {
            this.$axios
                .get(`/games/${this.$route.params.game_id}`, {
                    params: {
                        relations: ['game', 'campaigns'],
                    },
                })
                .then(({ data }) => {
                    this.game = data.payload.game
                })
                .catch((error) => {
                    console.error(`Error fetching campaigns`, error)
                })
        },
    },
}
</script>
