<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :class="[
            $props.class,
            {
                'fa-duotone': icon[0] && icon[0] === 'fad',
            },
        ]"
        :viewBox="`0 0 ${width} ${height}`"
    >
        <path
            v-for="(path, index) in svgPaths"
            :key="index"
            fill="currentColor"
            :d="path"
            :class="pathClasses && pathClasses[index] ? pathClasses[index] : ''"
        />
    </svg>
</template>

<script>
import { defineComponent } from 'vue'
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core'

export default defineComponent({
    name: 'FontAwesomeIcon',

    props: {
        icon: {
            type: Array,
            required: true,
        },
        class: String,
        pathClasses: {
            type: Array,
            required: false,
        },
    },

    data() {
        return {
            isFailed: false,
            width: 10,
            height: 10,
            svgPaths: [],
        }
    },

    watch: {
        icon(oldValue, newValue) {
            if (oldValue !== newValue) {
                this.initialize()
            }
        },
    },

    created() {
        this.initialize()
    },

    methods: {
        initialize() {
            if (!this.icon) {
                console.warn(`Failed to find Font Awesome icon`)
                return
            }

            const inputs = {
                prefix: this.icon[0],
                iconName: this.icon[1],
            }

            const definition = findIconDefinition(inputs)

            if (!definition || !definition.icon || !definition.icon.length) {
                console.warn(`Failed to find Font Awesome icon`, inputs)
                return
            }

            const [width, height] = definition.icon
            this.width = width
            this.height = height

            const svgPath = definition.icon[4]

            if (svgPath instanceof Array) {
                this.svgPaths = svgPath
            } else {
                this.svgPaths = [svgPath]
            }
        },
    },

    // setup(props) {
    //     const definition = computed(() => findIconDefinition({
    //         prefix: props.icon[0],
    //         iconName: props.icon[1],
    //     }))
    //
    //     if (!definition)
    //
    //     const width = computed(() => {
    //         return _.get(definition, 'value.icon[0]') ? _.get(definition, 'value.icon[0]') : false
    //     })
    //     const height = computed(() => definition.value.icon[1])
    //     const svgPaths = computed(() => {
    //         const svgPath = definition.value.icon[4]
    //
    //         if (svgPath instanceof Array) return svgPath
    //         return [svgPath]
    //     })
    //
    //     return {
    //         props,
    //         width,
    //         height,
    //         svgPaths,
    //     }
    // }
})
</script>
