<template>
    <div class="col-span-full h-52 rounded-sm border border-slate-200 bg-white shadow-lg sm:col-span-4 xl:col-span-4">
        <div class="flex h-full">
            <router-link
                class="relative block w-24 shrink-0 sm:w-40 xl:sidebar-expanded:w-40 2xl:sidebar-expanded:w-40"
                :to="{
                    name: 'game-keys',
                    params: {
                        game_id: game.id,
                    },
                }"
            >
                <img
                    class="absolute h-full w-full object-cover object-center"
                    :src="`https://static-cdn.jtvnw.net/ttv-boxart/${game.twitch_id}_IGDB-288x384.jpg`"
                    alt="Meetup 01"
                />
            </router-link>

            <div class="flex grow flex-col p-5">
                <header>
                    <div class="flex items-center justify-between">
                        <!-- <div
                        class="text-xs inline-flex font-medium rounded-full text-center px-2.5 py-1"
                    >
                        Stage
                    </div> -->

                        <!-- <div class="flex items-center space-x-2">
                        <div class="flex -space-x-3 -ml-0.5">
                            <img class="rounded-full border-2 border-white box-content" src="../../images/avatar-01.jpg" width="28" height="28" alt="User 01" />
                            <img class="rounded-full border-2 border-white box-content" src="../../images/avatar-04.jpg" width="28" height="28" alt="User 04" />
                            <img class="rounded-full border-2 border-white box-content" src="../../images/avatar-05.jpg" width="28" height="28" alt="User 05" />
                        </div>
                        <div class="text-xs font-medium text-slate-400 italic">+{{ participantCount }}</div>
                    </div> -->
                    </div>
                </header>

                <div class="grow">
                    <router-link
                        class="mb-1 inline-flex text-slate-800 hover:text-slate-900"
                        :to="{
                            name: 'game-keys',
                            params: {
                                game_id: game.id,
                            },
                        }"
                    >
                        <h2 class="text-xl font-semibold leading-snug">{{ game.name }}</h2>
                    </router-link>
                    <div class="line-clamp-3 text-sm">{{ game?.igdb?.summary }}</div>
                </div>

                <footer class="mt-5">
                    <div class="flex items-center justify-between">
                        <!-- <div>
                        <div class="text-sm font-medium text-slate-500">{{ $filters.moment(dateRange.started_at).format('MMM DD') }} <span class="text-slate-400">-&gt;</span> {{ $filters.moment(dateRange.ended_at).format('MMM DD') }}</div>
                    </div> -->
                        <div>
                            <router-link
                                class="text-sm font-medium text-indigo-500 hover:text-indigo-600"
                                :to="{
                                    name: 'game-keys',
                                    params: {
                                        game_id: game.id,
                                    },
                                }"
                                >View -&gt;</router-link
                            >
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GameCard',
    props: ['game'],

    computed: {},
}
</script>
