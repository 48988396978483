<script setup>
import { capitalize, isEmpty } from 'lodash'

import { computed, toRefs } from 'vue'
import { affinityGrades } from '@/utils/affinity'

const props = defineProps({
    filters: {
        type: Object,
        required: true,
    },
})

const { filters } = toRefs(props)

const affinityRange = computed(() => {
    if (filters?.value?.affinity?.game) {
        const minGrade = affinityGrades.find(
            (grade) => filters.value.affinity.game.min >= grade.min && filters.value.affinity.game.min <= grade.max,
        )
        const maxGrade = affinityGrades.find((grade) => grade.min === filters.value.affinity.game.max)
        return { min: minGrade?.letter, max: maxGrade?.letter }
    }
    return undefined
})
</script>

<template>
    <div class="pb-1 pt-4 text-sm font-semibold">Applied Filters</div>
    <ul class="list-inside list-disc text-xs">
        <li v-if="filters.content_count?.min">
            Must have made at least <b>{{ filters.content_count.min.toLocaleString() }}</b> pieces of content
        </li>
        <li v-if="filters.games?.values?.length > 0">
            <template v-if="filters?.games?.values?.length === 1">
                Must have played <b>{{ filters.games.values[0].name }}</b>
            </template>
            <template v-else-if="!filters.games.conjunction">
                Must have played at least <b>{{ filters.matched_games?.min || 1 }}</b> of
                <b>{{ filters.games.values.map(({ name }) => name).join(', ') }}</b>
            </template>
            <template v-else>
                Must have played all of
                <b>{{ filters.games.values.map(({ name }) => name).join(', ') }}</b>
            </template>
        </li>
        <li v-if="filters.not_games?.values?.length > 0">
            <template v-if="filters?.games?.values?.length === 1">
                Must not have played <b>{{ filters.not_games.values[0].name }}</b>
            </template>
            <template v-else>
                Must not have played {{ filters.not_games.conjunction === '$nin' ? 'all' : 'any' }} of
                <b>{{ filters.not_games.values.map(({ name }) => name).join(', ') }}</b>
            </template>
        </li>
        <li v-if="affinityRange">
            Must have a game affinity
            <template v-if="affinityRange.min && affinityRange.max">
                between <b>{{ affinityRange.min }}</b> and <b>{{ affinityRange.max }}</b>
            </template>
            <template v-else-if="affinityRange.min">
                above <b>{{ affinityRange.min }}</b>
            </template>
            <template v-else-if="affinityRange.max">
                below <b>{{ affinityRange.max }}</b>
            </template>
        </li>
        <li v-if="filters.avg_viewers?.min">
            Minimum Average Viewers must be above <b>{{ filters.avg_viewers.min.toLocaleString() }}</b>
        </li>
        <li v-if="filters.avg_viewers?.max">
            Maximum Average Viewers must be below <b>{{ filters.avg_viewers.max.toLocaleString() }}</b>
        </li>
        <li v-if="filters.avg_views?.min">
            Minimum Average Views must be above <b>{{ filters.avg_views.min.toLocaleString() }}</b>
        </li>
        <li v-if="filters.avg_views?.max">
            Maximum Average Views must be below <b>{{ filters.avg_views.max.toLocaleString() }}</b>
        </li>
        <li v-if="filters.subscribers?.min">
            Minimum amount of subscribers must be above <b>{{ filters.subscribers.min.toLocaleString() }}</b>
        </li>
        <li v-if="filters.subscribers?.max">
            Maximum amount of subscribers must be below <b>{{ filters.subscribers.max.toLocaleString() }}</b>
        </li>
        <li v-if="filters.games?.min">
            Minimum amount of games played must be above <b>{{ filters.games.min.toLocaleString() }}</b>
        </li>
        <li v-if="filters.languages?.length > 0">
            Languages must include <b>{{ filters.languages.map(({ name }) => name).join(', ') }}</b>
        </li>
        <li v-if="(filters.metadata?.type?.filter((type) => !isEmpty(type)) || []).length > 0">
            Channel type must include
            <b>{{ filters.metadata.type.map(({ label }) => label).join(', ') }}</b>
        </li>
        <li v-if="filters.content_type">
            Content type must be <b>{{ capitalize(filters.content_type) }}</b>
        </li>
        <li v-if="filters.countries?.length > 0">
            Countries must include <b>{{ filters.countries.map(({ name }) => name).join(', ') }}</b>
        </li>
        <li v-if="filters?.tags?.values?.length > 0">
            Tags must include <b>{{ filters.tags.values.map(({ name }) => name).join(', ') }}</b>
        </li>
        <li v-if="filters.metadata?.email">Channel must have an email address</li>
        <li v-if="filters.metadata?.location">Channel must have a location</li>
        <li v-if="filters?.demographics?.os?.values?.length > 0">
            Audience operating system <b>{{ filters.demographics.os.values.map(({ name }) => name).join(', ') }}</b>
        </li>
        <li v-if="filters?.demographics?.device?.values?.length > 0">
            Audience devices <b>{{ filters.demographics.device.values.map(({ name }) => name).join(', ') }}</b>
        </li>
        <li v-if="filters?.demographics?.country?.values?.length > 0">
            Audience country <b>{{ filters.demographics.country.values.map(({ name }) => name).join(', ') }}</b>
        </li>
        <li v-if="filters?.demographics?.language?.values?.length > 0">
            Audience language <b>{{ filters.demographics.language.values.map(({ name }) => name).join(', ') }}</b>
        </li>
    </ul>
</template>
