<template>
    <div class="flex h-screen overflow-hidden">
        <!-- Sidebar -->
        <Sidebar :sidebar-open="state.is_sidebar_open" @close-sidebar="state.is_sidebar_open = false" />

        <!-- Content area -->
        <div id="dashboard-content-area" class="relative flex flex-1 flex-col overflow-x-hidden overflow-y-scroll">
            <CreateCampaignModal />
            <CreateOrgModal />
            <SendMessagesModal />
            <BackgroundActionToast />

            <!-- Site header -->
            <AppHeader
                :sidebar-open="state.is_sidebar_open"
                @toggle-sidebar="state.is_sidebar_open = !state.is_sidebar_open"
            />

            <main>
                <router-view :key="selfActiveOrganization ? `${selfActiveOrganization.id}` : `all-organizations`" />
            </main>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Sidebar from '@/partials/Sidebar.vue'
import CreateCampaignModal from '@/partials/modals/CreateCampaignModal.vue'
import SendMessagesModal from '@/partials/modals/SendMessagesModal.vue'
import AppHeader from '@/partials/AppHeader.vue'
import BackgroundActionToast from '@/components/toasts/BackgroundActionToast.vue'
import CreateOrgModal from '../partials/modals/CreateOrgModal.vue'

export default {
    name: 'DashboardView',

    components: {
        AppHeader,
        Sidebar,
        CreateCampaignModal,
        CreateOrgModal,
        SendMessagesModal,
        BackgroundActionToast,
    },

    data() {
        return {
            state: {
                is_sidebar_open: false,
            },
        }
    },

    computed: {
        ...mapGetters(['selfActiveOrganization', 'checkPermission']),
    },
}
</script>
