<template>
    <div class="mx-auto w-full max-w-9xl px-4 py-8 sm:px-6 md:py-0 lg:px-8">
        <div class="xl:flex">
            <div class="flex-1 md:flex">
                <div class="flex-1">
                    <div class="md:ml-8 md:py-8 xl:ml-4 2xl:ml-8">
                        <SkeletonListsTable v-if="!state.isCreated" />
                        <RosterTable
                            v-if="state.isCreated"
                            :creators="getRosterCreators"
                            :paging="paging"
                            :platform-id="platformId"
                            :is-loading="state.isLoading"
                            @switch-platform="onSwitchPlatform"
                            @paging-change="onPagingChange"
                            @add-to-roster="onAddToRoster"
                            @perform-search="onPerformSearch"
                            @remove-creator="onRemoveCreator"
                            @edit-creator-rates="onEditCreatorRates"
                            @export-creators="onExportCreators"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { json2csvAsync } from 'json-2-csv'
import RosterTable from '@/partials/tables/RosterTable.vue'
import SkeletonListsTable from '@/partials/skeletons/SkeletonListsTable.vue'
import rosterAPI from '../utils/api/api.organization.rosters'

export default {
    name: 'RosterPage',

    metaInfo: {
        title: 'Roster',
    },

    components: {
        SkeletonListsTable,
        RosterTable,
    },

    data() {
        return {
            roster: null,
            platformId: 'twitch',
            paging: {
                sortby: 'channel.name',
                sortdir: 'asc',
                totalPages: 1,
                perPage: 15,
                currentPage: 1,
            },
            searchText: '',
            state: {
                isCreated: false,
                isLoading: false,
            },
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'selfActiveOrganizationId', 'getLanguageFromCode', 'getCountryFromCode']),
        getRosterCreators() {
            if (this.roster) {
                return this.roster.creators.items
            }
            return []
        },
    },

    async created() {
        this.state.isCreated = false
        await this.getDefaultRoster()
        this.state.isCreated = true
    },

    methods: {
        async getDefaultRoster() {
            const rosterResult = await rosterAPI.getDefaultRoster(
                this.$axios,
                this.selfActiveOrganizationId,
                true,
                true,
                this.platformId,
                this.paging,
                this.searchText,
                false,
            )

            if (rosterResult.success) {
                this.roster = rosterResult.value
                this.paging.totalRecords = this.roster.creators.pager.totalRecords
                this.paging.totalPages = Math.ceil(this.paging.totalRecords / this.paging.perPage)
            }
        },

        async onAddToRoster(creators) {
            const createRequests = []

            for (let i = 0; i < creators.length; i += 1) {
                const creatorToAdd = creators[i]
                if (creatorToAdd.platform_id === 'any') {
                    createRequests.push(
                        rosterAPI.addCreatorToRoster(
                            this.$axios,
                            this.selfActiveOrganizationId,
                            this.roster.id,
                            'twitch',
                            creatorToAdd.platform_user_id,
                            creatorToAdd.platform_user_name,
                        ),
                    )
                    createRequests.push(
                        rosterAPI.addCreatorToRoster(
                            this.$axios,
                            this.selfActiveOrganizationId,
                            this.roster.id,
                            'youtube',
                            creatorToAdd.platform_user_id,
                            creatorToAdd.platform_user_name,
                        ),
                    )
                } else {
                    createRequests.push(
                        rosterAPI.addCreatorToRoster(
                            this.$axios,
                            this.selfActiveOrganizationId,
                            this.roster.id,
                            creatorToAdd.platform_id,
                            creatorToAdd.platform_user_id,
                            creatorToAdd.platform_user_name,
                        ),
                    )
                }
            }

            await Promise.all(createRequests)

            this.getDefaultRoster()
            this.$successHandler('Successfully added creators.')
        },

        async onSwitchPlatform(platformId) {
            this.state.isLoading = true
            this.roster = null
            this.platformId = platformId
            this.paging.currentPage = 1
            await this.getDefaultRoster()
            this.state.isLoading = false
        },

        async onPagingChange(updatedPaging) {
            _.assign(this.paging, updatedPaging)
            await this.getDefaultRoster()
        },

        async onPerformSearch(text) {
            this.searchText = text
            this.bouncedSearch()
        },

        bouncedSearch: _.debounce(function () {
            this.paging.currentPage = 1
            this.getDefaultRoster()
        }, 100),

        async onRemoveCreator(rosterCreatorId) {
            await rosterAPI.removeCreatorFromRoster(
                this.$axios,
                this.selfActiveOrganizationId,
                this.roster.id,
                rosterCreatorId,
            )
            await this.getDefaultRoster()
        },

        async onEditCreatorRates(rosterCreatorId, rates) {
            const result = await rosterAPI.saveCreatorRates(
                this.$axios,
                this.selfActiveOrganizationId,
                this.roster.id,
                rosterCreatorId,
                rates,
            )

            if (result.success) {
                const updatedRatecard = result.value
                const updatedCreator = _.find(
                    this.roster.creators.items,
                    (creator) => creator.id === updatedRatecard.owner_entity_id,
                )
                updatedCreator.ratecard = updatedRatecard
            }
        },

        async onExportCreators() {
            const allCreatorsResult = await rosterAPI.getDefaultRoster(
                this.$axios,
                this.selfActiveOrganizationId,
                true,
                true,
                this.platformId,
                this.paging,
                this.searchText,
                true,
            )

            if (allCreatorsResult.success) {
                const fileName = `Roster.csv`
                const link = document.createElement('a')
                const creators = allCreatorsResult.value.creators.items

                const exportedRateTypes = {
                    twitch: ['twitch-cpavph', 'twitch-cpavph2', 'twitch-cpavph4'],
                    youtube: ['youtube-cpm'],
                }

                const platformCreators = _.map(creators, (exportedCreator) => {
                    let exportedRow = null
                    if (this.platformId === 'twitch') {
                        exportedRow = {
                            'Creator Name': _.get(exportedCreator, 'superview.channel.display_name'),
                            'Connect Page': `https://streamforge.com/${this.platformId}/${_.get(
                                exportedCreator,
                                'superview.channel.display_name',
                            )}`,
                            'AVG CCV': _.has(exportedCreator, 'superview.stream.day_90.aggregation.viewers.avg')
                                ? this.$filters
                                      .numeral(exportedCreator.superview?.stream?.day_90?.aggregation?.viewers?.avg)
                                      .format('0,0')
                                : '',
                            Followers: _.has(exportedCreator, 'superview.channel.followers')
                                ? this.$filters
                                      .numeral(_.get(exportedCreator, 'superview.channel.followers'))
                                      .format('0,0')
                                : '',
                            Language: _.has(exportedCreator, 'superview.channel.language')
                                ? this.getLanguageFromCode(exportedCreator?.superview?.channel?.language)
                                : '',
                        }
                    } else if (this.platformId === 'youtube') {
                        exportedRow = {
                            'Creator Name': _.get(exportedCreator, 'superview.channel.snippet.title'),
                            'Connect Page': `https://streamforge.com/${this.platformId}/${_.get(
                                exportedCreator,
                                'superview.channel.snippet.title',
                            )}`,
                            'AVG Video Views': _.has(exportedCreator, 'summary.avg_viewers')
                                ? this.$filters.numeral(_.get(exportedCreator, 'summary.avg_viewers')).format('0,0')
                                : '',
                            Subscribers: _.has(exportedCreator, 'superview.channel.statistics.subscriberCount')
                                ? this.$filters
                                      .numeral(exportedCreator?.superview?.channel?.statistics?.subscriberCount)
                                      .format('0,0')
                                : '',
                            Country: _.has(exportedCreator, 'superview.channel.snippet.country')
                                ? this.getCountryFromCode(exportedCreator.superview?.channel?.snippet?.country)
                                : '',
                        }
                    }

                    _.forEach(exportedRateTypes[this.platformId], (rateType) => {
                        const foundItem = _.find(exportedCreator?.ratecard?.items, (item) => item.type_id === rateType)
                        const rateTypeTitle = this.getFromDictionary(`finance.rate_types.${rateType}`, 'title_short')

                        if (foundItem && foundItem.value) {
                            exportedRow[rateTypeTitle] = this.$filters.formatCurrency(foundItem.value)
                        } else {
                            exportedRow[rateTypeTitle] = ''
                        }
                    })

                    return exportedRow
                })

                const csvResults = await json2csvAsync(platformCreators)

                link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(csvResults)}`)
                link.setAttribute('download', fileName)
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }
        },
    },
}
</script>
