<script setup>
const props = defineProps({
    platformId: {
        type: String,
    },
    platformUserId: {
        type: String,
    },
    platformUserName: {
        type: String,
    },
    link: {
        type: String,
    },
})
</script>

<template>
    <div class="flex">
        <div class="my-auto leading-none">
            <a
                class="flex cursor-pointer items-center rounded px-2.5 py-1.5 text-xs font-semibold leading-none text-white opacity-80 transition duration-200 hover:opacity-100"
                :class="`bg-${props.platformId}`"
                :href="props.link"
                target="blank"
            >
                <font-awesome-icon :icon="['fab', props.platformId]" class="mr-1.5 h-3.5 w-3.5" />

                <div>/{{ props.platformUserName }}</div>

                <font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']" class="ml-2 h-3 w-3" />
            </a>
        </div>
    </div>
</template>
