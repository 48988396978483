<template>
    <CreatorActionModel ref="actionModal" EntityType="Participant" @reload="onReload" />

    <div class="col-span-full rounded-sm border border-slate-200 bg-white shadow-lg">
        <div class="p-3">
            <div class="">
                <table v-if="!isLoading" class="w-full table-auto">
                    <thead class="rounded-sm bg-slate-50 text-left text-xs uppercase text-slate-400">
                        <tr>
                            <th class="w-7 whitespace-nowrap p-2 pl-3 pl-5">
                                <div class="flex">
                                    <input
                                        v-model="allSelected"
                                        type="checkbox"
                                        class="form-checkbox"
                                        @change="onToggleSelectAll()"
                                    />
                                </div>
                            </th>

                            <th class="whitespace-nowrap p-2" @click="sortByField('creator')">
                                <div class="flex">
                                    <span class="font-semibold">Creator</span>
                                </div>
                            </th>

                            <th class="whitespace-nowrap p-2" @click="sortByField('payee_linked')">
                                <div class="flex">
                                    <span class="font-semibold">Payee Assigned</span>
                                </div>
                            </th>

                            <th class="whitespace-nowrap p-2" @click="sortByField('payee_linked')">
                                <div class="flex">
                                    <span class="font-semibold">Payee Status</span>
                                </div>
                            </th>

                            <th class="whitespace-nowrap p-2" @click="sortByField('task_total_amount')">
                                <div class="flex">
                                    <span class="font-semibold">Payout</span>
                                </div>
                            </th>

                            <th class="whitespace-nowrap p-2" @click="sortByField('task_completed_amount')">
                                <div class="flex">
                                    <span class="font-semibold">Completed</span>
                                </div>
                            </th>

                            <th class="whitespace-nowrap p-2" @click="sortByField('scheduled_amount')">
                                <div class="flex">
                                    <span class="font-semibold">Scheduled</span>
                                </div>
                            </th>

                            <th class="whitespace-nowrap p-2" @click="sortByField('paid_amount')">
                                <div class="flex">
                                    <span class="font-semibold">Paid</span>
                                </div>
                            </th>

                            <th class="w-7 pl-2 pr-5">
                                <div
                                    class="flex w-full rounded bg-slate-100 text-slate-600 transition duration-200 hover:bg-slate-200"
                                >
                                    <ParticipantAction
                                        :actions="actions"
                                        :creators="selectedCreators"
                                        :show-count="true"
                                        @perform-action="onActionModalOpen"
                                    />
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <tbody
                        class="divide-y divide-slate-200 border-b border-slate-200 text-sm"
                        :class="{ 'pointer-events-none opacity-50': isBusy === true }"
                    >
                        <tr v-for="creator in creators" :key="creator.superview._id" class="group">
                            <td class="whitespace-nowrap px-2 py-3.5 pl-5">
                                <input
                                    v-model="selectedCreators"
                                    type="checkbox"
                                    class="form-checkbox"
                                    :value="creator"
                                />
                            </td>

                            <td class="whitespace-nowrap px-2 py-3.5">
                                <div class="relative flex items-center">
                                    <NamePlate
                                        class="my-auto"
                                        :logo="creator.participant.platform_user_logo"
                                        :name="creator.participant.platform_user_name"
                                        :display-name="creator.participant.platform_user_name"
                                        :platform-id="creator.participant.platform_id"
                                        :platform-user-id="creator.participant.platform_user_id"
                                        :is-drawer-enabled="true"
                                    />

                                    <div
                                        class="absolute right-0 top-0 hidden h-full cursor-pointer rounded bg-slate-100 px-2 text-xs font-semibold transition duration-200 group-hover:flex hover:bg-slate-200"
                                        @click="
                                            $store.commit('modal:setData', {
                                                modal_id: 'creator',
                                                data: {
                                                    is_open: true,
                                                    platform_id: creator.participant.platform_id,
                                                    platform_user_id: creator.participant.platform_user_id,
                                                    supercampaign_id: campaign.parent_id,
                                                    campaign_id: campaign.id,
                                                    campaign: campaign,
                                                    participant_id: creator.participant.id,
                                                    participant: creator.participant,
                                                },
                                            })
                                        "
                                    >
                                        <span class="my-auto flex uppercase">
                                            <font-awesome-icon
                                                :icon="['fas', 'rectangle-list']"
                                                class="my-auto mr-1 h-4 w-4"
                                            />
                                            <span class="my-auto leading-none">Open</span>
                                        </span>
                                    </div>
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2">
                                <div class="text-left">
                                    <span v-if="creator.finance.payee && creator.finance.payee.id">
                                        {{ getFromDictionary(`finance.payee.assignment.assigned`, 'title') }}
                                    </span>
                                    <span v-else>
                                        {{ getFromDictionary(`finance.payee.assignment.unassigned`, 'title') }}
                                    </span>
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2">
                                <div class="text-left">
                                    <span v-if="creator.finance.payee && creator.finance.payee.id">
                                        {{
                                            getFromDictionary(
                                                `finance.payee.status.${creator.finance.payee.status}`,
                                                'title',
                                            )
                                        }}
                                    </span>
                                    <span v-else>
                                        {{
                                            getFromDictionary(
                                                `finance.payee.availability.${creator.finance.payeeAvailability}`,
                                                'title',
                                            )
                                        }}
                                    </span>
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2">
                                <div v-if="creator.finance.tasks.total_amount" class="text-left">
                                    ${{ $numeral(creator.finance.tasks.total_amount).format('0,0.00') }}
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2">
                                <div v-if="creator.finance.tasks.completed_amount" class="text-left">
                                    ${{ $numeral(creator.finance.tasks.completed_amount).format('0,0.00') }}
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2">
                                <div v-if="creator.finance.payments.scheduled" class="text-left">
                                    ${{ $numeral(creator.finance.payments.scheduled).format('0,0.00') }}
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2">
                                <div v-if="creator.finance.payments.paid" class="text-left">
                                    ${{ $numeral(creator.finance.payments.paid).format('0,0.00') }}
                                </div>
                            </td>

                            <td class="w-7 px-2 pr-5">
                                <div class="flex w-full rounded bg-slate-50 transition duration-200 hover:bg-slate-200">
                                    <ParticipantAction
                                        :actions="actions"
                                        :creators="[creator]"
                                        :show-count="false"
                                        @perform-action="onActionModalOpen"
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div v-if="!creators || (!creators.length && !isLoading)" class="w-full p-4 text-center">
                    <p class="text-sm font-medium">No creators to show</p>
                </div>
            </div>
        </div>

        <div class="my-4 ml-4 mr-4">
            <DiscoveryPagination :paging="paging" @paging-changed="onPagingChange" />
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

import NamePlate from '@/components/plates/NamePlate.vue'
import DiscoveryPagination from '@/partials/tables/discovery/DiscoveryPagination.vue'
import ParticipantAction from '@/components/ParticipantAction.vue'
import CreatorActionModel from '@/partials/modals/CreatorActionModal.vue'

export default {
    name: 'ParticipantFinanceTable',

    components: {
        NamePlate,
        DiscoveryPagination,
        ParticipantAction,
        CreatorActionModel,
    },

    props: {
        creators: {
            type: Array,
        },
        isLoading: {
            type: Boolean,
        },
        paging: {
            type: Object,
        },
        actions: {
            type: Array,
        },
        campaign: {
            type: Object,
        },
        activeParentTab: {
            type: Object,
        },
        activeChildTab: {
            type: Object,
        },
        activeTab: {
            type: Object,
        },
        tabs: {
            type: Object,
        },
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'getLanguageFromCode', 'getCountryFromCode']),
    },

    emits: ['paging-change', 'reload'],

    data() {
        return {
            selectedCreators: [],
            isBusy: false,
            allSelected: false,
            modal: {
                isOpen: false,
                action: null,
                creators: null,
                validation: null,
            },
        }
    },

    watch: {
        selectedCreators() {
            this.allSelected = this.areAllSelected()
        },
        creators() {
            const selectedIds = _.map(this.selectedCreators, (creator) => creator.participant.id)
            this.reselect(selectedIds)
        },
    },

    methods: {
        onToggleSelectAll() {
            if (this.creators === 0) {
                return
            }

            const allSelected = this.areAllSelected()

            if (allSelected) {
                this.selectedCreators = []
            } else {
                this.selectedCreators = this.creators
            }
        },

        areAllSelected() {
            const selectedIds = _.map(this.selectedCreators, (creator) => creator.participant.id)
            const unselectedCreators = _.filter(
                this.creators,
                (creator) => selectedIds.indexOf(creator.participant.id) === -1,
            )
            return this.creators.length > 0 && unselectedCreators.length === 0
        },

        sortByField(column) {
            const updatedPaging = {
                sortby: '',
                sortdir: '',
            }
            if (this.paging.sortby === column && this.paging.sortdir === 'desc') {
                updatedPaging.sortdir = 'asc'
            } else {
                updatedPaging.sortdir = 'desc'
            }

            updatedPaging.sortby = column
            this.$emit('paging-change', updatedPaging)
        },

        onPagingChange(updatedPaging) {
            this.$emit('paging-change', updatedPaging)
        },

        onActionModalOpen(action, creators) {
            this.$refs.actionModal.onActionModalOpen(action, creators)
        },

        onReload() {
            const selectedIds = _.map(this.selectedCreators, (creator) => creator.participant.id)
            this.$emit('reload')
            this.reselect(selectedIds)
        },

        reselect(selectedCreatorIds) {
            this.selectedCreators = _.filter(
                this.creators,
                (creator) => selectedCreatorIds.indexOf(creator.participant.id) !== -1,
            )
        },
    },
}
</script>
