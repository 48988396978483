<template>
    <ModalBasic id="save-filters-modal" :modal-open="isOpen" title="Create Saved Search" @close-modal="handleClose">
        <div class="relative p-4">
            <div></div>
            <div>
                <div v-if="existingSearch" class="text-sm font-semibold text-yellow-700">
                    A saved search ({{ existingSearch.title }}) with these filters already exists.
                </div>
                <div v-else>
                    <label class="block text-sm font-medium text-gray-700">Saved Search Name</label>
                    <div class="mt-1">
                        <input
                            ref="titleInput"
                            v-model="title"
                            :disabled="existingSearch"
                            placeholder='e.g. "MMORPG Players"'
                            type="text"
                            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-100 sm:text-sm"
                            :class="{ 'border-red-500 ring-red-500': createSavedSearch.error?.value?.response?.data }"
                            @input="createSavedSearch.reset()"
                        />
                        <div v-if="createSavedSearch.error?.value?.response?.data" class="p-1 text-xs text-red-700">
                            {{ createSavedSearch.error?.value?.response?.data?.message }}
                        </div>
                    </div>
                </div>
                <AppliedFilters :filters="filters" />
                <div class="flex justify-end space-x-2 pt-4">
                    <button
                        type="button"
                        class="btn border-slate-200 text-slate-600 hover:border-slate-300"
                        @click="handleCancel"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        class="btn bg-indigo-500 text-white enabled:hover:bg-indigo-600 disabled:pointer-events-none disabled:bg-indigo-300 disabled:text-white"
                        :disabled="!title"
                        @click="handleSave()"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    </ModalBasic>
</template>

<script setup>
import { computed, nextTick, ref, watch } from 'vue'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { isEqual, omit, orderBy } from 'lodash'
import { notify } from '@kyvg/vue3-notification'
import { useStore } from 'vuex'
import ModalBasic from '@/components/ModalBasic.vue'
import { difference } from '@/utils/Utils'
import { axios } from '@/utils/axios'
import AppliedFilters from '@/partials/modals/CreateSavedSearchModal/AppliedFilters.vue'
import targetGroupTools from '@/utils/searching/targetgroups'

const props = defineProps({
    isOpen: {
        type: Boolean,
        required: true,
    },
    onClose: {
        type: Function,
        required: true,
    },
    onSave: {
        type: Function,
        required: true,
    },
    onCancel: {
        type: Function,
        required: true,
    },
    filtering: {
        type: Object,
        required: true,
    },
    platform: {
        type: String,
        required: true,
    },
})

const titleInput = ref(null)

const store = useStore()

const selectedOrganization = computed(() => store.getters.selfActiveOrganization)
const savedSearches = useQuery({
    queryKey: ['target-group-by-organization-platform', selectedOrganization.value.id, props.platform],
    queryFn: async () => {
        const search = await axios.get(`/organizations/${selectedOrganization.value.id}/targeting`)
        return {
            platformGroups: search.data.payload.groups.filter((group) => group.platform === props.platform),
            codes: search.data.payload.codes,
        }
    },
    enabled: Boolean(selectedOrganization.value),
})
const queryClient = useQueryClient()

const createSavedSearch = useMutation({
    mutationFn: (data) => {
        const body = targetGroupTools.mapFiltersFromTargetGroup(
            omit({ ...data.filters, title: data.title }, 'id'),
            props.filtering.current,
        )
        return axios.put(`/targeting/organization/${selectedOrganization.value.id}/${props.platform}`, body)
    },
    onSuccess: ({ data }) => {
        notify({
            title: 'Saved Search Created',
            text: `Your saved search "${data.payload.group.title}" has been created.`,
            type: 'success',
        })
        queryClient.invalidateQueries('target-group-by-organization-platform')
    },
})

const title = ref('')

const handleClose = () => {
    title.value = ''
    createSavedSearch.reset()
    props.onClose()
}

const handleSave = async () => {
    await createSavedSearch.mutateAsync({
        title: title.value,
        filters: { ...props.filtering.current, platform: props.platform },
    })
    props.onSave()
    handleClose()
}

const handleCancel = () => {
    handleClose()
}

const filters = computed(() => difference(props.filtering.current, props.filtering.default))

const existingSearch = ref(undefined)
watch(
    [() => props.platform, () => props.filtering, savedSearches.data, () => props.isOpen],
    () => {
        existingSearch.value = savedSearches.data.value?.platformGroups.find((savedSearch) => {
            const savedFiltersSorted = savedSearch.filters !== null ? savedSearch.filters : []
            const compareToFiltersSorted =
                props.filtering.current !== null
                    ? targetGroupTools.mapFiltersFromTargetGroup(props.filtering.current, props.filtering.options)
                          .targetGroup.filters
                    : []
            orderBy(savedFiltersSorted, ['id'])
            orderBy(compareToFiltersSorted, ['id'])
            return isEqual(savedFiltersSorted, compareToFiltersSorted) && savedSearch.platform === props.platform
        })
    },
    { immediate: true },
)

watch(
    () => props.isOpen,
    async (newValue) => {
        if (newValue && titleInput.value) {
            await nextTick()
            titleInput?.value?.focus()
        }
    },
    { immediate: true },
)
</script>
