<template>
    <div class="flex items-center text-sm">
        <div
            class="h-[22px] w-[25px] rounded-bl-md rounded-tl-md px-2 py-1 leading-none text-white"
            :class="`${affinityWithGrade.bgPrimaryColor} ${affinityWithGrade.textColor}`"
        >
            <span v-if="affinityWithGrade.letter">{{ affinityWithGrade.letter }}</span>
        </div>
        <div
            class="flex h-[22px] w-[50px] rounded-br-md rounded-tr-md px-2 py-1 leading-none text-white"
            :class="`${affinityWithGrade.bgSecondaryColor} ${affinityWithGrade.textColor}`"
        >
            <span v-if="affinityPercent > 0" class="mx-auto"
                >{{ $filters.numeral(Math.floor(Math.min(affinityPercent, 1) * 100)).format('0,0') }}%</span
            >
        </div>
    </div>
</template>

<script>
import { getGradeAndColorsFromPercent } from '@/utils/affinity'

export default {
    name: 'AffinityPlate',

    props: {
        affinityPercent: {
            type: Number,
        },
        affinity: {
            type: Object,
        },
        discoverEntry: {
            type: Object,
        },
    },

    computed: {
        affinityWithGrade() {
            return getGradeAndColorsFromPercent(this.affinityPercent)
        },
    },
}
</script>
