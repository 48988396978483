<template>
    <div class="w-full overflow-x-auto">
        <table class="w-full table-auto">
            <thead class="rounded-sm bg-slate-100 text-xs uppercase text-slate-400">
                <tr>
                    <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                        <div class="text-left font-semibold">Subscription</div>
                    </th>
                    <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"></th>
                    <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                        <div class="text-right font-semibold">Actions</div>
                    </th>
                </tr>
            </thead>

            <tbody v-if="isSubscriptionsLoading === true" class="divide-y divide-slate-200 text-sm">
                <tr v-for="index in 5" :key="index">
                    <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/3">
                        <div class="h-3 w-full animate-pulse rounded bg-slate-200" />
                    </td>
                    <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/3">
                        <div class="h-3 w-full animate-pulse rounded bg-slate-200" />
                    </td>
                    <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/3">
                        <div class="h-3 w-full animate-pulse rounded bg-slate-200" />
                    </td>
                </tr>
            </tbody>

            <tbody v-else class="divide-y divide-slate-200 text-sm">
                <tr v-for="subscription in subscriptions" :key="subscription.id">
                    <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/3">
                        <div class="flex grow items-center">
                            <div class="flex items-center">
                                {{ subscription.name }}
                                <template
                                    v-if="subscriptionType === 'current' && subscription.subscriber.status === 'ending'"
                                >
                                    <b class="ml-5"
                                        >(Canceled, expires
                                        {{ $filters.formatDate(subscription.subscriber.expiresAt) }})</b
                                    >
                                </template>
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/3">
                        <div class="flex items-center justify-start">
                            {{ subscription.description }}
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/3">
                        <div class="flex items-center justify-end">
                            <div class="flex items-center pt-4">
                                <div class="mb-1">
                                    <button
                                        v-if="subscriptionType === 'current'"
                                        class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600"
                                        @click="onManageSubscription(subscription)"
                                    >
                                        Manage subscription
                                    </button>

                                    <button
                                        v-if="subscriptionType === 'available'"
                                        class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600"
                                        @click="onPurchaseSubscription(subscription)"
                                    >
                                        Subscribe
                                    </button>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'SubscriptionsTable',
    props: {
        subscriptions: {
            type: Object,
        },
        isSubscriptionsLoading: {
            type: Boolean,
        },
        subscriptionType: {
            type: String,
        },
    },

    emits: ['cancel-subscription', 'manage-subscription', 'purchase-subscription'],

    computed: {
        ...mapGetters(['getFromDictionary']),
    },

    methods: {
        onCancelSubscription(id) {
            this.$emit('cancel-subscription', id)
        },
        onManageSubscription(subscription) {
            this.$emit('manage-subscription', subscription)
        },
        onPurchaseSubscription(subscription) {
            this.$emit('purchase-subscription', subscription)
        },
    },
}
</script>
