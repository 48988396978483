<template>
    <div class="space-y-4">
        <div class="h-6 w-3/4 rounded bg-slate-200"></div>
        <div class="grid grid-cols-2 gap-4">
            <div class="space-y-2">
                <div class="h-4 w-1/4 animate-pulse rounded bg-slate-200"></div>
                <div class="h-10 animate-pulse rounded bg-slate-200"></div>
            </div>
            <div class="space-y-2">
                <div class="h-4 w-1/4 animate-pulse rounded bg-slate-200"></div>
                <div class="h-10 animate-pulse rounded bg-slate-200"></div>
            </div>
        </div>
        <div class="space-y-2">
            <div class="h-4 w-1/4 animate-pulse rounded bg-slate-200"></div>
            <div class="h-10 animate-pulse rounded bg-slate-200"></div>
        </div>
        <div class="space-y-2">
            <div class="h-4 w-1/4 animate-pulse rounded bg-slate-200"></div>
            <div class="h-40 animate-pulse rounded bg-slate-200"></div>
        </div>
        <div class="flex justify-end space-x-2">
            <div class="h-10 w-24 animate-pulse rounded bg-slate-200"></div>
            <div class="h-10 w-24 animate-pulse rounded bg-slate-200"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkeletonSendMessagesModal',
}
</script>
