<template>
    <div class="relative">
        <div
            ref="trigger"
            aria-label="Lists"
            aria-haspopup="true"
            :aria-expanded="dropdownOpen"
            @click.prevent="dropdownOpen = !dropdownOpen"
        >
            <slot />
        </div>

        <transition
            enter-active-class="transition ease-out duration-100 transform"
            enter-from-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="dropdownOpen"
                class="no-scrollbar absolute left-0 top-full z-50 mt-1 max-h-96 rounded border border-slate-200 bg-white shadow-xl"
            >
                <div ref="dropdown">
                    <div class="flex-col px-3.5 py-2">
                        <div v-show="$lodash.size(internalSorts) > 0" class="mb-2 mt-1 space-y-2">
                            <div
                                v-for="(sortEntry, sortOrderIndex) in internalSorts"
                                :key="`sort-field-${sortOrderIndex}`"
                            >
                                <div class="flex space-x-2">
                                    <div>
                                        <DropdownClassic
                                            ref="dropdownSort"
                                            :options="sortableColumns"
                                            id-field="id"
                                            text-field="header.label"
                                            :initial-selected-id="sortEntry.field"
                                            btn-classes="whitespace-nowrap"
                                            @selected="
                                                (selectedValue) =>
                                                    onSortFieldSelect('field', sortOrderIndex, selectedValue)
                                            "
                                        />
                                    </div>
                                    <div>
                                        <DropdownClassic
                                            ref="dropdownSort"
                                            :options="sortDirections"
                                            id-field="id"
                                            text-field="text"
                                            :initial-selected-id="sortEntry.direction"
                                            btn-classes="whitespace-nowrap w-36"
                                            @selected="
                                                (selectedValue) =>
                                                    onSortFieldSelect('direction', sortOrderIndex, selectedValue)
                                            "
                                        />
                                    </div>
                                    <div class="flex">
                                        <div
                                            class="group m-auto cursor-pointer rounded p-1.5 hover:bg-slate-50"
                                            @click.stop="onSortFieldSelect('direction', sortOrderIndex, false)"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'xmark']"
                                                class="h-4 w-4 text-slate-500 group-hover:text-slate-700"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div
                                v-show="$lodash.size(unsortedColumns) > 0"
                                class="flex cursor-pointer rounded px-4 py-1.5 hover:bg-slate-50"
                                @click="addSort"
                            >
                                <font-awesome-icon :icon="['fas', 'plus']" class="my-auto mr-2 h-4 w-4" />
                                <span class="whitespace-nowrap text-sm font-medium">Add sort level</span>
                            </div>

                            <div
                                v-show="$lodash.size(internalSorts) > 0"
                                class="flex cursor-pointer rounded px-4 py-1.5 hover:bg-slate-50 hover:text-rose-600"
                                @click="clearSort"
                            >
                                <font-awesome-icon :icon="['fas', 'trash-can']" class="my-auto mr-2 h-4 w-4" />
                                <span class="text-sm font-medium">Delete all sort levels</span>
                            </div>

                            <!-- <div
                                @click="applySorts"
                                class="flex py-1.5 px-4 rounded hover:bg-slate-100 cursor-pointer hover:text-emerald-500"
                            >
                                <font-awesome-icon :icon="['fas', 'floppy-disk-circle-arrow-right']" class="w-4 h-4 my-auto mr-2" />
                                <span class="text-sm font-medium whitespace-nowrap">Apply sorts</span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import _ from 'lodash'
import DropdownClassic from '@/components/dropdowns/DropdownClassic.vue'

export default {
    name: 'DropdownMultiSort',

    components: {
        DropdownClassic,
    },

    props: {
        sorts: {
            type: Array,
        },
        columns: {
            type: Array,
        },
    },

    emits: ['new-sorts'],

    data() {
        return {
            sortDirections: [
                {
                    id: 'asc',
                    text: 'Ascending',
                },
                {
                    id: 'desc',
                    text: 'Descending',
                },
            ],

            dropdownOpen: false,

            internalSorts: [],
        }
    },

    computed: {
        sortableColumns() {
            return _.filter(this.columns, (item) => item.header.is_sortable === true)
        },
        unsortedColumns() {
            const sortLookup = _.keyBy(this.internalSorts, 'field')

            return _.filter(this.columns, (item) => item.header.is_sortable === true && !sortLookup[item.id])
        },
    },

    watch: {
        sorts: {
            handler() {
                this.internalSorts = _.cloneDeep(this.sorts)
            },
            deep: true,
        },
    },

    created() {
        this.internalSorts = _.cloneDeep(this.sorts)
        // this.internalSorts = JSON.parse(JSON.stringify(this.sorts))
    },

    mounted() {
        document.addEventListener('click', this.documentClickHandler)
        document.addEventListener('keydown', this.documentKeyHandler)
    },

    unmounted() {
        document.removeEventListener('click', this.documentClickHandler)
        document.removeEventListener('keydown', this.documentKeyHandler)
    },

    methods: {
        clearSort() {
            // this.$emit('new-sorts', [])
            this.internalSorts = []

            this.$emit('new-sorts', this.internalSorts)
        },

        addSort() {
            if (_.size(this.unsortedColumns) === 0) {
                return
            }

            const nextSortableColumn = this.unsortedColumns.shift()

            this.internalSorts.push({
                field: nextSortableColumn.id,
                direction: 'asc',
                source: 'multisort-add',
            })

            this.$emit('new-sorts', this.internalSorts)
        },

        onSortFieldSelect(type, sortOrderIndex, selectedValue) {
            const sortEntry = this.internalSorts[sortOrderIndex]

            if (type === 'field') {
                sortEntry.field = selectedValue
                sortEntry.source = 'user'
            } else if (type === 'direction') {
                if (selectedValue) {
                    sortEntry.direction = selectedValue
                    sortEntry.source = 'user'

                    // If false or null is passed in as the sort order, remove that sort level
                } else {
                    // this.internalSorts.splice(sortOrderIndex, 1)

                    this.internalSorts = _.filter(this.internalSorts, (item, index) => index !== sortOrderIndex)
                }
            }

            this.$emit('new-sorts', this.internalSorts)
        },

        applySorts() {
            this.$emit('new-sorts', this.internalSorts)
            // this.dropdownOpen = false
        },

        // I was stuck on a bug for hours where the dropdown closes when switching sort fields, or clicking the X.
        // This was ultimately solved by adding the event modifier .stop to the @click on the sort clear (the X) and
        // removing the :key on the v-for that iterates over the sorts, also have to duplicate sorts internally
        documentClickHandler({ target }) {
            const isWithinChildDropdowns = _.some(this.$refs.dropdownSort, (childRef) => childRef.$el.contains(target))

            if (
                !this.dropdownOpen ||
                this.$refs.dropdown.contains(target) ||
                this.$refs.trigger.contains(target) ||
                isWithinChildDropdowns
            )
                return
            this.dropdownOpen = false
        },

        documentKeyHandler({ keyCode }) {
            if (!this.dropdownOpen || keyCode !== 27) return
            this.dropdownOpen = false
        },
    },
}
</script>
