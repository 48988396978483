<template>
    <div class="flex flex-col divide-y divide-slate-200">
        <template v-if="items.length">
            <button
                v-for="(item, index) in items"
                :key="index"
                data-item-prevent-modal-close="true"
                class="tippy-mention-item bg-slate-50 px-2.5 py-2 text-left text-sm leading-none text-slate-500 first:rounded-tl first:rounded-tr last:rounded-bl last:rounded-br hover:bg-slate-200 hover:text-slate-800"
                :class="{ 'is-selected': index === selectedIndex }"
                @click="selectItem(index)"
            >
                {{ item }}
            </button>
        </template>
        <div v-else class="item">No items found</div>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true,
        },

        command: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            selectedIndex: 0,
        }
    },

    watch: {
        items() {
            this.selectedIndex = 0
        },
    },

    methods: {
        onKeyDown({ event }) {
            if (event.key === 'ArrowUp') {
                this.upHandler()
                return true
            }

            if (event.key === 'ArrowDown') {
                this.downHandler()
                return true
            }

            if (event.key === 'Enter') {
                this.enterHandler()
                return true
            }

            return false
        },

        upHandler() {
            this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length
        },

        downHandler() {
            this.selectedIndex = (this.selectedIndex + 1) % this.items.length
        },

        enterHandler() {
            this.selectItem(this.selectedIndex)
        },

        selectItem(index) {
            const item = this.items[index]

            if (item) {
                this.command({ id: item })
            }
        },
    },
}
</script>
