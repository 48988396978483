<template>
    <div
        :class="{
            'cursor-pointer': isDrawerEnabled,
        }"
        class="inline-block rounded-full bg-slate-100 transition duration-200 hover:bg-slate-200"
        @click="onClick"
    >
        <div class="flex">
            <div class="relative">
                <Avatar
                    class="bg-slate-700 text-slate-50"
                    :srcs="
                        [
                            platformId !== 'tiktok' ? logo?.replace('300x300', '70x70').replace('s240', 's88') : null,
                            getDefaultAvatarUrl(),
                        ].filter((item) => item)
                    "
                    :alt="`Avatar for ${isDisplayNameEnglish ? displayName : name}`"
                    :size="26"
                />
                <!-- <Avatar
                    v-else
                    class="bg-slate-700 text-slate-50"
                    :src="logo"
                    :username="isDisplayNameEnglish ? displayName : name"
                    :size="26"
                /> -->

                <div v-if="platformId === 'twitch'" class="absolute -bottom-2 -right-1.5 z-10 flex rounded-full">
                    <svg class="flex-shrink-0 fill-current" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path
                            fill="white"
                            d="M7.95243 3.33337L4.3999 6.8859V18.2858H7.25705L7.25705 23.4951L12.4664 18.2858H14.3712L19.8285 12.8285L19.8285 3.33337H7.95243Z"
                        ></path>
                        <path
                            fill="#9147FF"
                            d="M8.78086 5.33337L6.3999 7.71433L6.3999 16.2858L9.25704 16.2858L9.25704 18.6667L11.638 16.2858L13.5428 16.2858L17.8285 12L17.8285 5.33337L8.78086 5.33337Z"
                        ></path>
                        <path
                            fill="white"
                            d="M14.9711 13.4286L16.8759 11.5238L16.8759 6.28574L9.25684 6.28574L9.25684 13.4286L11.1616 13.4286L11.1616 15.3334L13.0664 13.4286L14.9711 13.4286Z"
                        ></path>
                        <path
                            fill="#9147FF"
                            d="M12.1143 8.19037L13.0666 8.19037L13.0666 11.0475L12.1143 11.0475L12.1143 8.19037ZM15.4476 8.19037L15.4476 11.0475L14.4952 11.0475L14.4952 8.19037L15.4476 8.19037Z"
                        ></path>
                    </svg>
                </div>

                <div v-else-if="platformId === 'youtube'" class="absolute -bottom-0.5 -right-1 z-10 flex rounded-full">
                    <svg height="16" width="16" viewBox="0 0 490 490">
                        <g>
                            <g>
                                <path
                                    fill="#EA4640"
                                    d="M480,180v130c0,55.195-44.805,100-100,100H110c-55.195,0-100-44.805-100-100V180
                                c0-55.199,44.805-100,100-100h270C435.195,80,480,124.801,480,180z"
                                />
                            </g>
                            <g>
                                <g>
                                    <polygon style="fill: #ffffff" points="320,245 200,295 200,195 " />
                                </g>
                                <g>
                                    <path
                                        fill="white"
                                        d="M380,70H110C49.346,70,0,119.346,0,180v130c0,60.654,49.346,110,110,110h270
                                    c60.654,0,110-49.346,110-110V180C490,119.346,440.654,70,380,70z M470,310c0,49.626-40.374,90-90,90H110
                                    c-49.626,0-90-40.374-90-90V180c0-49.626,40.374-90,90-90h270c49.626,0,90,40.374,90,90V310z"
                                    />
                                    <path
                                        fill="white"
                                        d="M323.846,235.769l-120-50c-3.085-1.286-6.611-0.945-9.393,0.911
                                    c-2.782,1.854-4.453,4.977-4.453,8.32v100c0,3.344,1.671,6.466,4.453,8.32c1.667,1.112,3.601,1.68,5.548,1.68
                                    c1.301,0,2.608-0.254,3.845-0.769l120-50c3.727-1.553,6.154-5.194,6.154-9.231S327.572,237.322,323.846,235.769z M210,280v-70
                                    l84,35L210,280z"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <div v-else-if="platformId === 'tiktok'" class="absolute -bottom-0.5 -right-1 z-10 flex rounded-full">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        width="16"
                        height="16"
                        viewBox="0 0 1100 1100"
                        xml:space="preserve"
                    >
                        <g transform="matrix(1 0 0 1 540 540)"></g>
                        <g transform="matrix(1 0 0 1 540 540)">
                            <rect
                                style="
                                    stroke: none;
                                    stroke-width: 1;
                                    stroke-dasharray: none;
                                    stroke-linecap: butt;
                                    stroke-dashoffset: 0;
                                    stroke-linejoin: miter;
                                    stroke-miterlimit: 4;
                                    fill: rgb(255, 255, 255);
                                    fill-rule: nonzero;
                                    opacity: 1;
                                    visibility: hidden;
                                "
                                vector-effect="non-scaling-stroke"
                                x="-540"
                                y="-540"
                                rx="0"
                                ry="0"
                                width="1080"
                                height="1080"
                            />
                        </g>
                        <g transform="matrix(12.1 0 0 12.1 411.4 735.64)">
                            <circle
                                style="
                                    stroke: rgb(0, 0, 0);
                                    stroke-width: 0;
                                    stroke-dasharray: none;
                                    stroke-linecap: butt;
                                    stroke-dashoffset: 0;
                                    stroke-linejoin: miter;
                                    stroke-miterlimit: 4;
                                    fill: rgb(255, 255, 255);
                                    fill-rule: nonzero;
                                    opacity: 1;
                                "
                                vector-effect="non-scaling-stroke"
                                cx="0"
                                cy="0"
                                r="35"
                            />
                        </g>
                        <g transform="matrix(4.01 0 0 8.25 658.85 304.56)">
                            <rect
                                style="
                                    stroke: rgb(0, 0, 0);
                                    stroke-width: 0;
                                    stroke-dasharray: none;
                                    stroke-linecap: butt;
                                    stroke-dashoffset: 0;
                                    stroke-linejoin: miter;
                                    stroke-miterlimit: 4;
                                    fill: rgb(255, 255, 255);
                                    fill-rule: nonzero;
                                    opacity: 1;
                                "
                                vector-effect="non-scaling-stroke"
                                x="-37.165"
                                y="-37.165"
                                rx="0"
                                ry="0"
                                width="74.33"
                                height="74.33"
                            />
                        </g>
                        <g transform="matrix(-10.65 0 0 -10.28 782.24 257.02)">
                            <path
                                style="
                                    stroke: none;
                                    stroke-width: 1;
                                    stroke-dasharray: none;
                                    stroke-linecap: butt;
                                    stroke-dashoffset: 0;
                                    stroke-linejoin: miter;
                                    stroke-miterlimit: 4;
                                    fill: rgb(255, 255, 255);
                                    fill-rule: nonzero;
                                    opacity: 1;
                                "
                                transform=" translate(-75, -25)"
                                d="M 50 50 L 50 0 C 77.61423749153967 0 100 22.385762508460314 100 49.999999999999986 L 50 50 L 70 50 C 70 38.954305003384135 61.045694996615865 30 50 30 Z"
                                stroke-linecap="round"
                            />
                        </g>
                        <g transform="matrix(2.08 0 0 2.08 540 540)">
                            <path
                                style="
                                    stroke: none;
                                    stroke-width: 0;
                                    stroke-dasharray: none;
                                    stroke-linecap: butt;
                                    stroke-dashoffset: 0;
                                    stroke-linejoin: miter;
                                    stroke-miterlimit: 4;
                                    fill: rgb(255, 0, 80);
                                    fill-rule: nonzero;
                                    opacity: 1;
                                "
                                transform=" translate(-224.06, -255.94)"
                                d="M 448 209.91 C 403.9747074818164 210.0153068205864 361.0290825083251 196.28543975037763 325.23 170.66000000000003 L 325.23 349.38 C 325.205110310029 417.16285084147677 283.121531703057 477.8148958683954 219.63583454602826 501.565301005584 C 156.15013738899955 525.3157061427726 84.59087451654875 507.17817457235094 40.082647919784904 456.05544721058277 C -4.425578676978944 404.9327198488146 -12.538455611947256 331.5577964610889 19.726193033202776 271.94648934994115 C 51.99084167835281 212.33518223879344 117.85918977560198 179.002632482494 185 188.31 L 185 278.2 C 154.2237787564885 268.5194256837196 120.71162487667556 279.7141900500884 101.9328533413549 305.94865312343575 C 83.15408180603424 332.1831161967831 83.36218845280106 367.51502668158923 102.44869083152687 393.5264614761077 C 121.53519321025269 419.5378962706263 155.1768927416553 430.337121220668 185.83694450341216 420.2946843107847 C 216.496996265169 410.2522474009014 237.23072859418446 381.64281625326197 237.23 349.38 L 237.23 0 L 325.23 0 C 325.1688743517641 7.431400257995884 325.79149768223414 14.852668664726849 327.09000000000003 22.16999999999998 L 327.09000000000003 22.17 C 333.27012616159857 55.183165414633535 352.7678108717884 84.19641467175519 381 102.38999999999997 C 400.873894850574 115.53092075818924 424.1744786030224 122.5350066801939 447.99999999999994 122.53 Z"
                                stroke-linecap="round"
                            />
                        </g>
                    </svg>
                </div>
                <div
                    v-else-if="platformId === 'twitter'"
                    class="absolute -bottom-[2px] -right-[3.5px] z-10 flex rounded-full"
                >
                    <font-awesome-icon :icon="['fab', 'twitter']" class="h-3 w-3 text-twitter" />
                </div>
                <div
                    v-else-if="platformId === 'instagram'"
                    class="absolute -bottom-[2px] -right-[3.5px] z-10 flex rounded-full"
                >
                    <font-awesome-icon :icon="['fab', 'instagram']" class="h-3 w-3 text-instagram" />
                </div>
            </div>

            <div v-if="displayName || name" class="ml-2 flex pr-3">
                <div class="my-auto flex text-sm font-medium text-slate-800">
                    {{ localDisplayName }}
                </div>
            </div>

            <Tooltip v-else class="flex">
                <template #trigger>
                    <div class="ml-2 flex pr-3">
                        <div class="my-auto font-medium text-slate-800">Unknown Creator</div>
                    </div>
                </template>
                <template #default>
                    <div class="whitespace-nowrap text-xs">Our system does not have data on this creator.</div>
                </template>
            </Tooltip>
        </div>
    </div>
</template>

<script>
import Avatar from '@/components/Avatar.vue'
import Tooltip from '@/components/Tooltip.vue'

export default {
    name: 'NamePlate',
    components: {
        Avatar,
        Tooltip,
    },
    props: {
        logo: {
            type: String,
            default: null,
        },
        displayName: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        platformId: {
            type: String,
            default: null,
        },
        platformUserId: {
            type: String,
            default: null,
        },
        isDrawerEnabled: {
            type: Boolean,
            default: false,
        },
        participant: {
            type: Object,
            default: null,
        },
        campaign: {
            type: Object,
            default: null,
        },
        isInWorkflow: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isDisplayNameEnglish: true,

            platformToAvatarSize: {
                twitch: 'small',
                youtube: 'small',
                tiktok: 'medium',
                twitter: 'small',
                instagram: 'small',
            },

            fallbackAvatarUrl: null,

            localDisplayName: null,
        }
    },
    computed: {
        avatarUrl() {
            return this.logo.replace('300x300', '70x70') || this.fallbackAvatarUrl
        },
    },
    created() {
        this.fallbackAvatarUrl = this.getDefaultAvatarUrl()
        this.checkDisplayName()
    },
    methods: {
        getDefaultAvatarUrl() {
            return `https://streamforge-etl-media.nyc3.cdn.digitaloceanspaces.com/${this.platformId}/avatars/${
                this.platformToAvatarSize[this.platformId]
            }/${this.platformUserId}`
        },
        handleAvatarError(event) {
            event.target.src = this.defaultAvatarUrl
        },
        checkDisplayName() {
            if (this.name && this.displayName) {
                // eslint-disable-next-line prefer-regex-literals,no-control-regex
                // const regex = new RegExp('[^\x00-\x7F]+')
                this.isDisplayNameEnglish = !/[^a-zA-Z0-9\s,.-]/.test(this.displayName)

                if (this.isDisplayNameEnglish) {
                    const cleanDisplayName = this.displayName?.toLowerCase().replace(/\s/g, '')
                    const cleanName = this.name?.replace('@', '')?.toLowerCase()

                    if (cleanDisplayName === cleanName) {
                        this.localDisplayName = this.displayName?.replace(/\s/g, '')
                    } else {
                        this.localDisplayName = this.name?.replace('@', '')
                    }
                } else {
                    this.localDisplayName = this.name?.replace('@', '')
                }
            }
        },
        onClick() {
            if (this.isDrawerEnabled) {
                this.$store.commit('modal:setData', {
                    modal_id: 'creator',
                    data: {
                        is_open: true,
                        platform_id: this.platformId,
                        platform_user_id: this.platformUserId,
                        participant: this.participant,
                        campaign: this.campaign,
                        is_in_workflow: this.isInWorkflow,
                    },
                })
            }
        },
    },
}
</script>
