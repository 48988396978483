<template>
    <div v-if="campaign?.payload?.slots?.state === 'locked'" class="md:ml-8 xl:ml-4 2xl:ml-8">
        <div v-for="({ date, slots }, index) in slotGroups" :key="index" class="slots-group-wrapper mb-10">
            <h1 class="mb-5 border-b border-slate-200 pb-2 text-2xl font-bold text-slate-800">
                {{ $filters.moment(date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY') }}
            </h1>

            <div class="grid grid-cols-12 gap-6">
                <div
                    v-for="(slot, index) in slots"
                    :key="index"
                    class="col-span-full overflow-hidden rounded-sm border border-slate-200 bg-white p-3 sm:col-span-6 xl:col-span-3"
                >
                    <div v-if="slot.participant && slot.participant.superview" class="mb-2 flex items-center">
                        <NamePlate
                            :logo="slot.participant.platform_user_logo"
                            :display-name="slot.participant.platform_user_name"
                            :platform-id="slot.participant.platform_id"
                            :platform-user-id="slot.participant.platform_user_id"
                            :is-drawer-enabled="true"
                        />
                    </div>

                    <span
                        >{{ $filters.moment(slot.start_at).format('hh:mm A') }} ->
                        {{ $filters.moment(slot.end_at).format('hh:mm A') }}</span
                    >
                </div>
            </div>
        </div>
    </div>

    <div v-else class="m-auto mt-16 max-w-2xl">
        <div class="px-4 text-center">
            <div
                class="mb-4 inline-flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-t from-slate-200 to-slate-100"
            >
                <svg class="h-6 w-5 fill-current" viewBox="0 0 20 24">
                    <path class="text-slate-500" d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z" />
                    <path class="text-slate-300" d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z" />
                    <path class="text-slate-400" d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z" />
                </svg>
            </div>
            <h2 class="mb-2 text-2xl font-bold text-slate-800">Scheduling Incomplete</h2>
            <div class="mb-6">
                Looks like we are still in the process of scheduling creators for your Steam broadcast. Please check
                back later!
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'
import NamePlate from '@/components/plates/NamePlate.vue'

export default {
    name: 'CampaignSlotsSubpage',

    components: {
        NamePlate,
    },

    props: {
        supercampaign: Object,
        campaignsById: Object,
    },

    data() {
        return {
            slots: null,
            slotGroups: null,
        }
    },

    created() {
        this.getSlots(this.$route.params.campaign_id)
    },

    computed: {
        ...mapGetters(['getFromDictionary']),

        campaign() {
            if (!this.campaignsById) return null
            return this.campaignsById[this.$route.params.campaign_id]
        },
    },

    methods: {
        getSlots(campaignId) {
            this.$axios
                .get(`/supercampaigns/${this.supercampaign.id}/campaigns/${campaignId}/slots`, {
                    params: {
                        relations: ['participant'],
                    },
                })
                .then(({ data }) => {
                    this.slots = data.payload.slots
                    this.slotGroups = _(data.payload.slots)
                        .groupBy((slot) => moment(slot.start_at).format('YYYY-MM-DD'))
                        .map((items, key) => ({
                            date: key,
                            slots: _.orderBy(items, ['start_at'], ['asc']),
                        }))
                        .orderBy(['date'], ['asc'])
                        .value()
                })
                .catch((error) => {
                    if (error.is_cancelled) return

                    this.$errorHandler(error, {
                        title: `Error - Campaign Schedule`,
                        message: `There was an issue retrieving campaign schedule.`,
                    })
                })
        },

        onCreatorClick(item) {
            this.$store.commit('drawer:set', {
                enabled: true,
                participant: item.participant,
                platform_user_id: item.participant.platform_user_id,
                platform_id: item.participant.platform_id,
            })
        },
    },
}
</script>
